import { RawData } from "@ternary/api-lib/analytics/types";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { useEffect, useState } from "react";
import { CSVDownload } from "react-csv";

interface Props {
  children: string | JSX.Element;
  data: RawData[];
  fileName: string;
  headers: { label: string; key: string }[];
  isLoading: boolean;
  onClick: () => void;
}

export default function AsyncCSVDownloader(props: Props): JSX.Element {
  const [isMounted, setIsMounted] = useState(false);
  const [mountCSV, setMountCSV] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (
      !isMounted ||
      (!props.isLoading && props.data.length === 0) ||
      props.isLoading
    ) {
      return;
    }

    setMountCSV(true);

    const timeoutID = setTimeout(() => {
      setMountCSV(false);
    }, 1000);

    return () => clearTimeout(timeoutID);
  }, [props.isLoading]);

  if (!mountCSV) {
    return <Box onClick={props.onClick}>{props.children}</Box>;
  }

  return (
    <Box>
      {props.children}
      <CSVDownload
        data={props.data}
        fileName={props.fileName}
        headers={props.headers}
        target="_self"
      />
    </Box>
  );
}
