import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import { isBefore } from "date-fns";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import FeedbackBar, {
  FeedbackBarVariants,
} from "../../../components/FeedbackBar";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import { getMonthNameForMonthNumber } from "../../../utils/dates";
import getCn from "../../../utils/getCn";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { Budget, BudgetPeriodVersion, UpdatedPeriodVersion } from "../types";

const COMPONENT_NAME = "BudgetPeriodVersionsHistory";
const cn = getCn(COMPONENT_NAME);

const StyledDiv = styled.div`
  color: ${({ theme }) => theme.text_color};
  width: 100%;

  h2 {
    display: flex;
    align-items: center;

    span {
      margin: 0 0.5rem;

      :first-of-type {
        color: ${(props) => props.theme.primary_color_text};
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0;
      }

      :nth-of-type(2) {
        font-size: 1rem;
      }
    }
  }
  .${cn("footer")}, h3 {
    margin-top: 3rem;
  }

  .${cn("version-table")} {
    width: 100%;
    margin-top: 0.5rem;

    td {
      padding: 0.5rem;
    }

    td:first-of-type {
      font-weight: bold;
    }

    th:nth-of-type(4),
    td:nth-of-type(4) {
      text-align: right;
    }

    .${cn("none-option")} {
      margin: 0 0.5rem;
    }
  }
`;

interface Props {
  budget?: Budget;
  loading: boolean;
  month: string;
  onClose: () => void;
  onUpdatePeriodVersions: (
    budgetID: string,
    updatedPeriodVersions: UpdatedPeriodVersion[]
  ) => void;
}

interface State {
  showFeedbackError: boolean;
  showFeedbackSuccess: boolean;
  versions: BudgetPeriodVersion[];
}

export default function BudgetPeriodVersionsHistory(
  props: Props
): JSX.Element | null {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    showFeedbackError: false,
    showFeedbackSuccess: false,
    versions: [],
  });
  const mergeState = getMergeState(setState);

  const dateArr = props.month.split("-");
  const month = getMonthNameForMonthNumber(Number(dateArr[1]));
  const year = dateArr[0];

  useEffect(() => {
    if (!props.budget) return;

    const sortedVersions = props.budget.periodVersions
      .filter((version) => version.period.month === props.month)
      .sort((a, b) => {
        if (
          isBefore(
            new Date(a.creationTime as string),
            new Date(b.creationTime as string)
          )
        ) {
          return 1;
        }
        return -1;
      });

    mergeState({
      versions: sortedVersions,
      showFeedbackError: false,
      showFeedbackSuccess: false,
    });
  }, [props.budget, props.month]);

  if (!props.budget) return null;

  function handleChangeHighlighted(versionId: string | null) {
    const newVersions = state.versions.map((v) => ({
      ...v,
      highlighted: v.id === versionId ? true : false,
    }));

    mergeState({ versions: newVersions });
  }

  function handleSubmit(e) {
    e.preventDefault();
    mergeState({ showFeedbackError: false, showFeedbackSuccess: false });

    if (!props.budget) return;

    const monthVersionIds = props.budget.periodVersions
      .filter(
        (version) => version.period.month === props.month && version.id !== ""
      )
      .map(({ id }) => id);

    const propVersionsKeyedById = keyBy(props.budget.periodVersions, "id");
    const stateVersionsKeyedById = keyBy(state.versions, "id");

    const updates: UpdatedPeriodVersion[] = monthVersionIds
      .filter(
        (id) =>
          propVersionsKeyedById[id] &&
          stateVersionsKeyedById[id] &&
          propVersionsKeyedById[id].highlighted !==
            stateVersionsKeyedById[id].highlighted
      )
      .map((id) => {
        const updated = stateVersionsKeyedById[id];

        return {
          id: updated.id,
          highlighted: updated.highlighted,
          month: updated.period.month,
        };
      });

    props.onUpdatePeriodVersions(props.budget.id, updates);
  }

  function canSave() {
    const stateId = state.versions.find((v) => v.highlighted)?.id;
    const propsId = (props.budget ? props.budget.periodVersions : [])
      .filter((version) => version.period.month === props.month)
      .find((v) => v.highlighted)?.id;

    if (stateId !== propsId) {
      return true;
    }
  }

  return (
    <StyledDiv>
      <h2>
        <span onClick={props.onClose}>{props.budget.name}</span>
        <span>{">"}</span>
        <span>{`${month} ${year}`}</span>
      </h2>
      <h3>
        {copyText.budgetsHistoryTitle} ({state.versions.length})
      </h3>
      <table className={cn("version-table")}>
        <thead>
          <tr>
            <th>{copyText.budgetsHistoryTableHeaderAmount}</th>
            <th>{copyText.budgetsHistoryTableHeaderCreatedAt}</th>
            <th>{copyText.budgetsHistoryTableHeaderCreatedBy}</th>
            <th>{copyText.budgetsHistoryTableHeaderHighlighted}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td />
            <td />
            <td>
              <Flex justifyContent="flex-end">
                <span className={cn("none-option")}>none</span>
                <Switch
                  height={20}
                  width={40}
                  onColor={theme.switch_color_on}
                  checked={
                    state.versions.length > 0 &&
                    state.versions.find((v) => v.highlighted) === undefined
                  }
                  onChange={() => handleChangeHighlighted(null)}
                />
              </Flex>
            </td>
          </tr>
          {state.versions.map((version) => (
            <tr key={version.id}>
              <td>
                {formatCurrencyRounded({
                  number: version.amount,
                })}
              </td>
              <td>
                {formatDate(new Date(version.creationTime), "yyyy/MM/dd")}
              </td>
              <td>{version.author}</td>
              <td>
                {
                  <Switch
                    disabled={!version.id}
                    height={20}
                    width={40}
                    onColor={theme.switch_color_on}
                    checked={version.highlighted}
                    onChange={() =>
                      handleChangeHighlighted(
                        version.highlighted ? null : version.id
                      )
                    }
                  />
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Flex className={cn("footer")} justifyContent="flex-end">
        <Button
          disabled={props.loading}
          secondary
          width={100}
          onClick={props.onClose}
        >
          {copyText.actionBack}
        </Button>
        <Button
          disabled={!canSave() || props.loading}
          marginLeft={theme.space_md}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.loading ? <LoadingSpinner /> : copyText.actionSubmit}
        </Button>
      </Flex>
      {state.showFeedbackSuccess && (
        <FeedbackBar
          variant={FeedbackBarVariants.success}
          text={copyText.budgetsHistoryFeedbackSuccess}
          title={copyText.successBarTitle}
          margin={"1rem 0"}
        />
      )}
      {state.showFeedbackError && (
        <FeedbackBar
          variant={FeedbackBarVariants.error}
          text={copyText.errorBarDescriptionShort}
          title={copyText.errorBarTitle}
          margin={"1rem 0"}
        />
      )}
    </StyledDiv>
  );
}
