import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  autoPlacement,
  autoUpdate,
  useFloating,
  UseFloatingOptions,
} from "@floating-ui/react";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop } from "lodash";
import React, {
  ReactElement,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import copyText from "../copyText";
import { isReactElement } from "../utils/children";

type Placement = "auto" | UseFloatingOptions["placement"];

const DropdownRoot = styled("div")({
  display: "flex",
  position: "relative",
});

const DropdownContent = styled("div")<{
  placement: Placement;
  width?: number | string;
}>(({ placement, theme, width }) => ({
  backgroundColor: theme.elevated_background_color,
  borderRadius: theme.borderRadius_2,
  boxShadow: `0 4px 8px ${theme.box_shadow}`,
  margin: placement === "auto" ? `-${theme.space_md}` : theme.space_md,
  maxHeight: 280,
  minWidth: 150,
  overflowY: "auto",
  padding: `${theme.space_xs} ${theme.space_xxs}`,
  textAlign: "left",
  zIndex: theme.zIndex_1600,
  width: width ?? undefined,
}));

const DropdownItem = styled("div")<{ isSelected: boolean; locked?: boolean }>(
  ({ isSelected, locked, theme }) => ({
    backgroundColor: isSelected
      ? theme.primary_color_background
      : "transparent",
    borderRadius: theme.borderRadius_2,
    cursor: (() => {
      if (locked) return "not-allowed";
      if (isSelected) return "default";
      return "pointer";
    })(),
    marginBottom: theme.space_xxs,
    marginTop: theme.space_xxs,
    paddingBottom: theme.space_xxs,
    paddingLeft: theme.space_sm,
    paddingRight: theme.space_sm,
    paddingTop: theme.space_xxs,
    transition: "color 0s, background-color 0s",

    "&:hover": {
      backgroundColor: isSelected
        ? theme.primary_color_background
        : theme.secondary_color_background,
    },
  })
);

export type Option = {
  label: string | JSX.Element;
  locked?: boolean;
  tooltipText?: string;
  value?: string;
  onClick?: (value: string) => void;
};

type Props = React.PropsWithChildren<{
  defaultSelectedOption?: Option;
  disabled?: boolean;
  isOpen?: boolean;
  options: Option[];
  placement?: "auto" | UseFloatingOptions["placement"];
  selectedOption?: Option;
  setSelectedOption?: false;
  truncate?: boolean;
  width?: number | string;
  onClose?: () => void;
  onOpen?: () => void;
}>;

let openID: string | null = null;

export default function Dropdown(props: Props): JSX.Element {
  const theme = useTheme();

  const [isOpenState, setIsOpen] = useState(false);

  const [selectedOptionValue, setSelectedOptionValue] = useState(
    props.defaultSelectedOption?.value ?? ""
  );

  const placementOption =
    !props.placement || props.placement === "auto"
      ? { middleware: [autoPlacement()] }
      : { placement: props.placement };

  const { refs, floatingStyles } = useFloating({
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    ...placementOption,
  });

  useEffect(() => {
    if (
      !props.selectedOption ||
      !props.selectedOption.value ||
      props.setSelectedOption === false
    ) {
      return;
    }

    setSelectedOptionValue(props.selectedOption.value);
  }, [props.selectedOption]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const intervalToClose = useRef<NodeJS.Timeout | null>(null);
  const openIDRef = useRef<string | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (typeof intervalToClose.current === "number") {
        clearInterval(intervalToClose.current);
      }
      if (typeof timeout.current === "number") {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) return;
      setIsOpen(false);

      if (props.onClose) {
        props.onClose();
      }
    },
    [ref]
  );

  function handleClickDropdown(event: ReactMouseEvent): void {
    if (props.disabled) return;

    event.stopPropagation();

    if (isOpen) {
      setIsOpen(false);
      if (props.onClose) {
        props.onClose();
      }
      return;
    }

    const openIDString = uuidv4();
    openID = openIDString;
    openIDRef.current = openIDString;

    setIsOpen(true);
    handleSetCloseInterval();

    if (props.onOpen) {
      props.onOpen();
    }
  }

  function handleSetCloseInterval(): void {
    intervalToClose.current = setInterval(() => {
      if (openID !== openIDRef.current) {
        setIsOpen(false);

        if (props.onClose) {
          props.onClose();
        }

        if (typeof intervalToClose.current === "number") {
          clearInterval(intervalToClose.current);
        }
      }
    }, 100);
  }

  function handleSelectOption(event: ReactMouseEvent, option: Option): void {
    setIsOpen(false);

    if (props.onClose) props.onClose();

    if (option.onClick) option.onClick(option.value ?? "");

    if (props.setSelectedOption === false || option.value === undefined) return;

    setSelectedOptionValue(option.value);
  }

  const isOpen = props.isOpen !== undefined ? props.isOpen : isOpenState;

  const content = (
    <DropdownContent
      placement={props.placement}
      ref={refs.setFloating}
      style={{ ...floatingStyles }}
      width={props.width}
    >
      {props.options.length > 0 ? (
        props.options.map((option, i) => {
          const isSelected =
            option.value !== undefined
              ? option.value === selectedOptionValue
              : false;

          const itemProps = {
            isSelected,
            locked: option.locked,
            onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation();

              return isSelected || option.locked
                ? noop
                : handleSelectOption(event, option);
            },
          };

          const item = (
            <DropdownItem key={i} {...itemProps}>
              {typeof option.label === "string" ? (
                <Text
                  color={
                    isSelected ? theme.text_color_inverse : theme.text_color
                  }
                  truncate={props.truncate}
                  whiteSpace="nowrap"
                >
                  {option.label}
                </Text>
              ) : (
                option.label
              )}
            </DropdownItem>
          );

          return option.tooltipText ? (
            <Tooltip key={i} content={option.tooltipText}>
              {item}
            </Tooltip>
          ) : (
            item
          );
        })
      ) : (
        <Box padding={theme.space_md}>
          <Text color={theme.text_color_disabled} whiteSpace="nowrap">
            {copyText.dropdownNoOptionsPlaceholder}
          </Text>
        </Box>
      )}
    </DropdownContent>
  );

  const children = React.cloneElement(props.children as ReactElement, {
    disabled: props.disabled,
    onClick: handleClickDropdown,
  });

  return (
    <DropdownRoot ref={ref}>
      {isReactElement(children) && (
        <div style={{ width: "100%" }} ref={refs.setReference}>
          {children}
        </div>
      )}
      {isOpen && content}
    </DropdownRoot>
  );
}
