import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../lib/react-query";

type TenantEntity = Awaited<ReturnType<CoreAPIClient["getTenantByID"]>>;

type Options = UseQueryOptions<TenantEntity, CoreAPIClientError>;
type Result = UseQueryResult<TenantEntity, CoreAPIClientError>;

export function useGetTenantByID(tenantID: string, options?: Options): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["tenants", tenantID],
    queryFn: () => client.getTenantByID(tenantID),
    ...options,
  });
}

export default useGetTenantByID;
