import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type GrantEntity = Awaited<ReturnType<CoreAPIClient["getGrantsByTenantID"]>>[0];

export default function useGetGrantsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<GrantEntity[], CoreAPIClientError>
): UseQueryResult<GrantEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["grants", tenantID],
    queryFn: () => client.getGrantsByTenantID(tenantID),
    ...options,
  });
}
