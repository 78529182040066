import { AuthenticatedUserEntity } from "@/api/core/types";
import { useTheme } from "@emotion/react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { pki } from "node-forge";
import React from "react";
import copyText from "../copyText";

type DownloadBoxProps = {
  text: string;
  user: AuthenticatedUserEntity;
};

export function DownloadOCIFormField(props: DownloadBoxProps) {
  const theme = useTheme();

  function handleDownloadOCIFile() {
    const element = document.createElement("a");
    const keyPem = pki.publicKeyToPem(
      pki.certificateFromPem(props.user.tenant.azureCertificatePEM).publicKey
    );
    element.href = URL.createObjectURL(new Blob([keyPem]));
    element.download = copyText.cloudOCIKeyFileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (
    <Box position="relative">
      <Box
        backgroundColor={theme.background_color_disabled}
        borderRadius={theme.borderRadius_2}
        paddingHorizontal={theme.space_xs}
        height="38px"
        display="flex"
      >
        <Flex
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          paddingVertical={theme.space_xs}
        >
          <Box>
            <Text color={theme.text_color_secondary}>{props.text}</Text>
          </Box>
          <Box>
            <Button
              type="button"
              iconEnd={
                <Icon
                  clickable
                  color={theme.text_color_secondary}
                  icon={faDownload}
                />
              }
              size="tiny"
              secondary
              onClick={handleDownloadOCIFile}
            >
              {copyText.downloadButtonLabel}
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
