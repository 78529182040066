import styled from "@emotion/styled";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React, { InputHTMLAttributes } from "react";
import IconCheck from "../icons/IconCheck";

const checkboxTheme = (baseTheme: Theme) => ({
  Checkbox_backgroundColor_hover: baseTheme.primary_color_hover,
  Checkbox_borderColor_hover: baseTheme.primary_color_border,
  Checkbox_borderColor: baseTheme.secondary_color_border,
  ...baseTheme,
});

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  dashed?: boolean;
  hideLabel?: boolean;
  label?: string;
}

interface StyleProps {
  checked?: Props["checked"];
  disabled?: Props["disabled"];
}

const Root = styled("label")<StyleProps>(({ disabled }) => ({
  alignItems: "center",
  cursor: disabled ? "default" : "pointer",
  display: "flex",
  position: "relative",
}));

const Input = styled("input")({
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  whiteSpace: "nowrap",
  width: "1px",
});

const Control = styled("span")<StyleProps>(({
  checked,
  disabled,
  theme: baseTheme,
}) => {
  const theme = checkboxTheme(baseTheme);

  return {
    backgroundColor: (() => {
      if (disabled) return theme.background_color_disabled;
      return checked
        ? theme.primary_color_background
        : theme.panel_backgroundColor;
    })(),
    borderColor: checked ? "transparent" : theme.Checkbox_borderColor,
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "16px",
    width: "16px",
    color: disabled ? theme.text_color_secondary : theme.text_color,

    "&:hover": {
      backgroundColor:
        disabled || !checked ? undefined : theme.Checkbox_backgroundColor_hover,
      borderColor: disabled ? undefined : theme.Checkbox_borderColor_hover,
    },
  };
});

const Text = styled("span")<StyleProps>(({ disabled, theme }) => ({
  color: disabled ? theme.text_color_disabled : theme.text_color,
  fontSize: theme.h6_fontSize,
  marginLeft: theme.space_xs,
}));

export default function Checkbox({
  checked,
  dashed,
  disabled,
  hideLabel,
  label,
  ...restProps
}: Props): JSX.Element {
  const rootProps = { disabled };
  const textProps = { disabled };

  const inputProps = {
    checked,
    type: "checkbox",
    ...restProps,
  };

  const controlProps = {
    checked,
    children: checked ? (
      <IconCheck clickable disabled={disabled} />
    ) : dashed ? (
      <Icon clickable disabled={disabled} icon={faMinus} />
    ) : undefined,
    disabled,
  };

  return (
    <Root {...rootProps}>
      <Input {...inputProps} />
      <Control {...controlProps} />
      {!hideLabel && label && <Text {...textProps}>{label}</Text>}
    </Root>
  );
}
