import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import { UpdateAlertRuleParameters } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { alertRuleID: string } & UpdateAlertRuleParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { alertRuleID: string } & UpdateAlertRuleParameters
>;

export default function useUpdateAlertRule(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ alertRuleID, ...params }) =>
      client.updateAlertRule(alertRuleID, params),
    ...options,
  });
}
