import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type MeasurePreferenceEntity = Awaited<
  ReturnType<CoreAPIClient["getMeasurePreferencesByTenantID"]>
>[0];

type Options = UseQueryOptions<MeasurePreferenceEntity[], CoreAPIClientError>;
type Result = UseQueryResult<MeasurePreferenceEntity[], CoreAPIClientError>;

export default function useGetMeasurePreferencesByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["measurePreferences"],
    queryFn: () => client.getMeasurePreferencesByTenantID(tenantID),
    ...options,
  });
}
