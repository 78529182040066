import { Option } from "@/ui-lib/components/SelectDropdown";
import { useTheme } from "@emotion/react";
import { faEdit, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LabelGroupingRuleEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { LabelMap } from "../../../api/core/types";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import SelectCheckbox from "../../../ui-lib/components/SelectCheckbox";
import TextInput from "../../../ui-lib/components/TextInput";
import copyText from "../copyText";

interface Props {
  billingLabelMap: LabelMap | undefined;
  canSubmit: boolean;
  index: number | undefined;
  isCreatingLabelGroupingRule: boolean;
  isDeletingLabelGroupingRule: boolean;
  isProcessing: boolean;
  isProcessingRuleIndex: number | undefined;
  isUpdatingLabelGroupingRule: boolean;
  labelGroupingRules: LabelGroupingRuleEntity[];
  outputKey: string;
  selectedDimensions: string[];
  selectedRuleIndex: number | undefined;
  onClickEditIcon?: () => void;
  onCloseTextInput?: () => void;
  onDeleteGrouping?: (index: number, id: string) => void;
  onSubmit: (id: string | undefined) => void;
  onUpdateName: (index: number | undefined, name: string) => void;
  onUpdateSelectedDimensions: (
    index: number | undefined,
    dimensions: string[]
  ) => void;
}

export default function LabelGroupingRuleRow(props: Props): JSX.Element {
  const theme = useTheme();

  const availableDimensions = props.billingLabelMap
    ? Object.keys(props.billingLabelMap)
    : [];

  const dimensionOptions = availableDimensions
    .sort((a, b) => (a > b ? 1 : -1))
    .reduce(
      (accum: Option[], availableDimension) => [
        ...accum,
        { label: availableDimension, value: availableDimension },
      ],
      []
    );

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={100}
      marginBottom={theme.space_lg}
    >
      <Flex
        backgroundColor={theme.background_color}
        borderRadius={theme.borderRadius_2}
        marginVertical={theme.space_lg}
        marginHorizontal={theme.space_md}
        minWidth={50}
        padding={theme.space_xs}
      >
        <Text fontSize={theme.h3_fontSize}>
          {props.index === undefined
            ? props.labelGroupingRules.length + 1
            : `# ${props.index + 1}`}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        backgroundColor={theme.background_color}
        borderRadius={theme.borderRadius_2}
        justifyContent="space-between"
        marginRight={theme.space_md}
        marginVertical={theme.space_lg}
        padding={theme.space_xs}
        minWidth={400}
      >
        <Box>
          <Text fontSize={theme.h3_fontSize}>{copyText.groupNameLabel}</Text>
        </Box>
        {props.selectedRuleIndex === props.index ? (
          <Box width="60%">
            <TextInput
              iconEnd={
                props.index === undefined ? undefined : (
                  <Icon
                    clickable
                    color={theme.overlay_background_color}
                    icon={faTimes}
                    onClick={props.onCloseTextInput}
                  />
                )
              }
              placeholder={copyText.nameInputPlaceholder}
              value={props.outputKey}
              onChange={(event) => {
                props.onUpdateName(props.index, event.target.value);
              }}
            />
          </Box>
        ) : (
          <Flex
            alignItems="center"
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            justifyContent="space-between"
            padding={theme.space_xs}
            width="60%"
          >
            <Text truncate={150}>{props.outputKey}</Text>
            <Box cursor="pointer">
              <Icon
                clickable
                color={theme.overlay_background_color}
                icon={faEdit}
                onClick={props.onClickEditIcon}
              />
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex
        alignItems="center"
        backgroundColor={theme.background_color}
        borderRadius={theme.borderRadius_2}
        marginVertical={theme.space_lg}
        flexGrow={2}
        marginRight={theme.space_md}
        padding={theme.space_xs}
      >
        <Text fontSize={theme.h3_fontSize}>{copyText.labelsLabel}</Text>
        <Box marginHorizontal={theme.space_md} width="100%">
          <SelectCheckbox
            options={dimensionOptions}
            selectedValues={props.selectedDimensions}
            onChange={(selectedDimensions) =>
              props.onUpdateSelectedDimensions(props.index, selectedDimensions)
            }
          />
        </Box>
      </Flex>
      <Box marginRight={theme.space_md} width={100}>
        <Button
          disabled={
            props.isCreatingLabelGroupingRule ||
            props.isDeletingLabelGroupingRule ||
            props.isUpdatingLabelGroupingRule ||
            (props.index === undefined && !props.canSubmit)
          }
          iconStart={
            props.canSubmit ||
            props.index ===
              undefined ? undefined : props.isProcessingRuleIndex ===
                props.index && props.isDeletingLabelGroupingRule ? undefined : (
              <Icon icon={faTrash} />
            )
          }
          primary={props.canSubmit}
          width="100%"
          onClick={() =>
            props.canSubmit || props.index === undefined
              ? props.onSubmit(
                  props.index === undefined
                    ? undefined
                    : props.labelGroupingRules[props.index].id
                )
              : props.onDeleteGrouping &&
                props.onDeleteGrouping(
                  props.index,
                  props.labelGroupingRules[props.index].id
                )
          }
        >
          {getLabelGroupingButtonLabel(
            props.canSubmit,
            props.index,
            props.isProcessing,
            props.isProcessingRuleIndex
          )}
        </Button>
      </Box>
    </Flex>
  );
}

function getLabelGroupingButtonLabel(
  canSubmit: boolean,
  index: number | undefined,
  isProcessing: boolean,
  isProcessingLabelGroupingRuleIndex: number | undefined
) {
  if (
    canSubmit &&
    !isProcessing &&
    (isProcessingLabelGroupingRuleIndex === undefined || index === undefined)
  ) {
    return copyText.submitButtonLabel;
  }

  if (
    isProcessing &&
    (isProcessingLabelGroupingRuleIndex === index || index === undefined)
  ) {
    return <LoadingSpinner />;
  }

  return "";
}
