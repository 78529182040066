import { DataSource, DurationType, TimeGranularity } from "../constants/enums";
import { configureFiscalDateCubeParams } from "./fiscalDateUtils";
import {
  CubeFilter,
  CubeTimeDimension,
  LabelMap,
  Order,
  QueryFilter,
} from "./types";
import {
  getCubeGranularity,
  getDateRange,
  getSchemaFromDataSource,
  isStringTuple,
  transformFilter,
} from "./utils";

export interface Properties {
  dataSource: DataSource;
  dateRange: string | [string, string] | Date[];
  dimensions?: string[];
  durationType?: DurationType;
  fiscalPeriodMap?: Record<string, string> | null;
  granularity?: TimeGranularity;
  isComparisonMode?: boolean;
  isFiscalMode?: boolean;
  labelMap: LabelMap;
  limit?: number;
  measures?: string[];
  offset?: number;
  order?: Order;
  queryFilters?: QueryFilter[];
}

export default class CubeQuery {
  public dimensions?: string[];
  public filters?: CubeFilter[];
  public limit?: null | number;
  public measures?: string[];
  public offset?: number;
  public order?: Order;
  public timeDimensions?: CubeTimeDimension[];

  constructor(props: Properties) {
    // Configure props for fiscal mode if enabled
    if (props.isFiscalMode && props.fiscalPeriodMap) {
      props = configureFiscalDateCubeParams(props);
    }

    let dateRange: string | [string, string];

    if (typeof props.dateRange === "string" || isStringTuple(props.dateRange)) {
      dateRange = props.dateRange;
    } else {
      dateRange = getDateRange(props.dateRange);
    }

    const schemaName = getSchemaFromDataSource(props.dataSource);

    if (props.dimensions) {
      this.dimensions = props.dimensions.map((dimension) => {
        dimension = props.labelMap[dimension]
          ? props.labelMap[dimension]
          : dimension;

        return `${schemaName}.${dimension}`;
      });
    }

    if (props.queryFilters) {
      const transformedFilters = props.queryFilters.map((filter) =>
        transformFilter(filter, props.labelMap, schemaName)
      );

      this.filters = [...(this.filters ?? []), ...transformedFilters];
    }

    this.limit = props.limit ?? 50_000;

    if (props.measures) {
      this.measures = props.measures.map(
        (measure) => `${schemaName}.${measure}`
      );
    }

    if (props.order) {
      this.order = Object.entries(props.order).reduce(
        (accum: Order, [key, value]) => {
          accum[`${schemaName}.${key}`] = value;
          return accum;
        },
        {}
      );
    }

    this.timeDimensions = [
      {
        dateRange,
        dimension: `${schemaName}.timestamp`,
        ...(props.granularity
          ? { granularity: getCubeGranularity(props.granularity) }
          : {}),
      },
    ];

    this.offset = props.offset ?? 0;
  }
}
