import paths from "@/constants/paths";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useMatchPath } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import {
  faGripHorizontal,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  ResourceType,
  TimeGranularity,
  WidgetType,
} from "@ternary/api-lib/constants/enums";
import { BudgetEntity } from "@ternary/api-lib/core/types";
import { BudgetsCurrentDailyGraph } from "@ternary/api-lib/ui-lib/charts/BudgetsCurrentDailyGraph";
import BudgetsHistoricalDailyGraph from "@ternary/api-lib/ui-lib/charts/BudgetsHistoricalDailyGraph";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import ResourceSelector from "@ternary/api-lib/ui-lib/components/ResourceSelector";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { startOfHour } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateHelper } from "../../../lib/dates";
import getMergeState from "../../../utils/getMergeState";
import useGetBudgetSpendByGranularity from "../../budget-management/hooks/useGetBudgetSpendByGranularity";
import copyText from "../copyText";

interface Props {
  budget: BudgetEntity;
  budgetType:
    | typeof WidgetType.BUDGET_CURRENT_MONTH
    | typeof WidgetType.BUDGET_DAILY_TRENDS;
  dashboardID: string;
  index: number;
  isEditLayout?: boolean;
  onInteraction: (interaction: BudgetViewContainer.Interaction) => void;
}

interface State {
  csvBudget: BudgetEntity | null;
  budget: BudgetEntity;
}

export function BudgetViewContainer(props: Props): JSX.Element {
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const now = new DateHelper();

  //
  // State
  //

  const [state, setState] = useState<State>({
    csvBudget: null,
    budget: props.budget,
  });
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: dailySpend = [], isLoading: isLoadingDailyBudgetSpend } =
    useGetBudgetSpendByGranularity(
      {
        budget: props.budget,
        dateRange: [
          startOfHour(now.nMonthsAgoFirstOfMonth(6)),
          startOfHour(now.date),
        ],
        granularity: TimeGranularity.DAY,
      },
      { enabled: !!props.budget }
    );

  //
  // Side Effects
  //

  useEffect(() => mergeState({ budget: props.budget }), [props.budget]);

  //
  // Render
  //

  const showGripIcon = currentPath === paths._dashboard && props.isEditLayout;
  const showDeleteButton = currentPath === paths._dashboard;

  return (
    <ResourceSelector
      resourceID={props.budget.id}
      resourceName={props.budget.name}
      resourceType={ResourceType.BUDGET}
    >
      <Box height="100%">
        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          height="100%"
          paddingBottom={theme.space_md}
          paddingHorizontal={theme.space_md}
          width="100%"
        >
          {showGripIcon && (
            <Flex
              alignItems="center"
              justifyContent="center"
              paddingTop={theme.space_xxs}
            >
              <Flex marginBottom={`-${theme.space_sm}`}>
                <Icon
                  color={theme.report_display_grip_color}
                  draggable
                  icon={faGripHorizontal}
                  size="lg"
                />
                <Box marginLeft={1}>
                  <Icon
                    color={theme.report_display_grip_color}
                    draggable
                    icon={faGripHorizontal}
                    size="lg"
                  />
                </Box>
              </Flex>
            </Flex>
          )}
          <Box height="100%" width="100%">
            <Box paddingVertical={theme.space_sm}>
              <Flex alignItems="center" justifyContent="space-between">
                <Box
                  maxHeight={`calc(${theme.h4_fontSize} * 1.5)`}
                  overflow="hidden"
                >
                  <Text
                    appearance="link"
                    fontSize={theme.h4_fontSize}
                    onClick={() =>
                      props.onInteraction({
                        type: BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED,
                        budgetID: props.budget.id,
                        budget: state.budget,
                      })
                    }
                  >
                    {props.budget.name}
                  </Text>
                </Box>
                <Flex alignItems="center">
                  {props.budgetType === WidgetType.BUDGET_CURRENT_MONTH ? (
                    <Tooltip
                      content={copyText.budgetsCurrentDailyGraphTooltip}
                      width={"12rem"}
                    >
                      <Flex alignItems="center">
                        <Text
                          color={theme.text_color_secondary}
                          whiteSpace="nowrap"
                          marginRight={theme.space_xs}
                        >
                          {copyText.budgetLabelCurrentMonth}
                        </Text>

                        <Icon
                          icon={faInfoCircle}
                          color={theme.text_color_secondary}
                        />
                      </Flex>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      content={copyText.budgetsHistoricalDailyGraphTooltip}
                      width={"12rem"}
                    >
                      <Flex alignItems="center">
                        <Text
                          color={theme.text_color_secondary}
                          whiteSpace="nowrap"
                          marginRight={theme.space_xs}
                        >
                          {copyText.budgetLabelDailyTrends}
                        </Text>

                        <Icon
                          icon={faInfoCircle}
                          color={theme.text_color_secondary}
                        />
                      </Flex>
                    </Tooltip>
                  )}

                  {showDeleteButton && (
                    <Button
                      disabled={
                        !gatekeeper.canUpdateDashboard(props.dashboardID)
                      }
                      iconStart={<Icon icon={faTrashAlt} />}
                      marginLeft={theme.space_xs}
                      size="small"
                      onClick={() =>
                        props.onInteraction({
                          type: BudgetViewContainer.INTERACTION_DELETE_BUTTON_CLICKED,
                          index: props.index,
                        })
                      }
                    />
                  )}
                </Flex>
              </Flex>
            </Box>
            <Box height="85%" paddingBottom={theme.space_md}>
              {props.budgetType === WidgetType.BUDGET_CURRENT_MONTH && (
                <BudgetsCurrentDailyGraph
                  budget={props.budget || null}
                  data={dailySpend}
                  height="100%"
                  hideTitle
                  loading={isLoadingDailyBudgetSpend}
                />
              )}
              {props.budgetType === WidgetType.BUDGET_DAILY_TRENDS && (
                <BudgetsHistoricalDailyGraph
                  budget={props.budget || null}
                  data={dailySpend}
                  height="100%"
                  hideTitle
                  loading={isLoadingDailyBudgetSpend}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </ResourceSelector>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BudgetViewContainer {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `BudgetViewContainer.INTERACTION_DELETE_BUTTON_CLICKED`;
  export const INTERACTION_BUDGET_NAME_CLICKED = `BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED`;

  interface InteractionDeleteButtonClicked {
    type: typeof BudgetViewContainer.INTERACTION_DELETE_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionBudgetNameClicked {
    type: typeof BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED;
    budgetID: string;
    budget: BudgetEntity;
  }

  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionBudgetNameClicked;
}

export default BudgetViewContainer;
