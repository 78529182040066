import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { cloudSpannerDimensions, cloudSpannerInstanceMeasures } from "../types";

type CloudSpannerInstanceSummary = {
  projectId: string | null;
  region: string | null;
  instanceConfig: string | null;
  instanceId: string | null;
  avgCpuUtilization: number | null;
  backupUsedB: number | null;
  maxCpuUtilization: number | null;
  processingUnits: number | null;
  storageLimitB: number | null;
  storageUsedB: number | null;
  totalBackupGibHour: number | null;
  totalComputeHours: number | null;
  totalEgressGib: number | null;
  totalStorageGibHour: number | null;
};

type CloudSpannerInstanceSummaryEntity = {
  [cloudSpannerDimensions.instanceConfig]: string | null;
  [cloudSpannerDimensions.instanceID]: string | null;
  [cloudSpannerDimensions.projectID]: string | null;
  [cloudSpannerDimensions.region]: string | null;
  [cloudSpannerInstanceMeasures.avgCpuUtilization]: number;
  [cloudSpannerInstanceMeasures.backupUsedB]: number;
  [cloudSpannerInstanceMeasures.maxCpuUtilization]: number;
  [cloudSpannerInstanceMeasures.storageLimitB]: number;
  [cloudSpannerInstanceMeasures.storageUsedB]: number;
  [cloudSpannerInstanceMeasures.processingUnits]: number;
  [cloudSpannerInstanceMeasures.totalBackupGibHour]: number;
  [cloudSpannerInstanceMeasures.totalComputeHours]: number;
  [cloudSpannerInstanceMeasures.totalEgressGib]: number;
  [cloudSpannerInstanceMeasures.totalStorageGibHour]: number;
};

export interface Params {
  dateRange: Date[];
}

export default function useGetCloudSpannerInstanceSummary(
  params: Params,
  options?: UseQueryOptions<CloudSpannerInstanceSummaryEntity[], UError>
): UseQueryResult<CloudSpannerInstanceSummaryEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["cloudSpannerInstanceSummary", params],
    queryFn: async () => {
      const measures = [
        cloudSpannerInstanceMeasures.avgCpuUtilization,
        cloudSpannerInstanceMeasures.backupUsedB,
        cloudSpannerInstanceMeasures.maxCpuUtilization,
        cloudSpannerInstanceMeasures.storageLimitB,
        cloudSpannerInstanceMeasures.storageUsedB,
        cloudSpannerInstanceMeasures.processingUnits,
        cloudSpannerInstanceMeasures.totalBackupGibHour,
        cloudSpannerInstanceMeasures.totalComputeHours,
        cloudSpannerInstanceMeasures.totalEgressGib,
        cloudSpannerInstanceMeasures.totalStorageGibHour,
      ];

      const dimensions = [
        cloudSpannerDimensions.instanceConfig,
        cloudSpannerDimensions.instanceID,
        cloudSpannerDimensions.projectID,
        cloudSpannerDimensions.region,
      ];

      const query = new DataQuery({
        dataSource: DataSource.CLOUD_SPANNER_USAGE,
        dateRange: params.dateRange,
        dimensions,
        measures,
      });
      const result = await client.loadData<CloudSpannerInstanceSummary>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        [cloudSpannerDimensions.instanceConfig]: summary.instanceConfig,
        [cloudSpannerDimensions.instanceID]: summary.instanceId,
        [cloudSpannerDimensions.projectID]: summary.projectId,
        [cloudSpannerDimensions.region]: summary.region,
        [cloudSpannerInstanceMeasures.avgCpuUtilization]:
          summary.avgCpuUtilization ?? 0,
        [cloudSpannerInstanceMeasures.backupUsedB]: summary.backupUsedB ?? 0,
        [cloudSpannerInstanceMeasures.maxCpuUtilization]:
          summary.maxCpuUtilization ?? 0,
        [cloudSpannerInstanceMeasures.storageLimitB]:
          summary.storageLimitB ?? 0,
        [cloudSpannerInstanceMeasures.storageUsedB]: summary.storageUsedB ?? 0,
        [cloudSpannerInstanceMeasures.processingUnits]:
          summary.processingUnits ?? 0,
        [cloudSpannerInstanceMeasures.totalBackupGibHour]:
          summary.totalBackupGibHour ?? 0,
        [cloudSpannerInstanceMeasures.totalComputeHours]:
          summary.totalComputeHours ?? 0,
        [cloudSpannerInstanceMeasures.totalEgressGib]:
          summary.totalEgressGib ?? 0,
        [cloudSpannerInstanceMeasures.totalStorageGibHour]:
          summary.totalStorageGibHour ?? 0,
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasGCPIntegration,
    ...options,
  });
}
