import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { FunctionComponent } from "react";

interface Props {
  children: React.ReactNode;
  locked: boolean;
}

const LockedWrapper: FunctionComponent<Props> = (props) => {
  return (
    <Flex alignItems="center">
      {props.children}
      {props.locked && (
        <Box alignSelf="flex-start">
          <FontAwesomeIcon icon={faLock} size="xs" />
        </Box>
      )}
    </Flex>
  );
};

export default LockedWrapper;
