import { DataSource, Operator } from "@ternary/api-lib/constants/enums";
import {
  BaseScopedView,
  getFiltersForAllDataSources,
} from "@ternary/api-lib/utils/ReportUtils";
import React, { ReactNode, createContext, useContext, useState } from "react";

type Props = { children: ReactNode };

type ScopedViewFilter = {
  dataSource: DataSource;
  name: string;
  operator: Operator;
  values: string[] | null;
};

type ScopedViewAndFilter = {
  and: ScopedViewFilter[];
};

type ScopedViewOrFilter = {
  or: ScopedViewAndFilter[] | ScopedViewFilter[];
};

type State = {
  customFilters: ScopedViewFilter[];
  isInitialized: boolean;
  scopedViews: BaseScopedView[];
};

const initialState: State = {
  customFilters: [],
  isInitialized: false,
  scopedViews: [],
};

export type FilterStore = {
  availableScopedViewIDs: string[];
  customFilters: ScopedViewFilter[];
  initialize: (state: Partial<State>) => void;
  isInitialized: boolean;
  queryFilters: (ScopedViewFilter | ScopedViewOrFilter)[];
  scopedViews: BaseScopedView[];
  set: (state: Partial<State>) => void;
};

const context = createContext<FilterStore | undefined>(undefined);

export default function FilterStoreProvider({ children }: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);

  const initialize = (state: Partial<State>) =>
    setState({
      ...initialState,
      ...state,
      isInitialized: true,
    });

  const set = (state: Partial<State>) =>
    setState((currentState) => ({ ...currentState, ...state }));

  const store: FilterStore = {
    availableScopedViewIDs: state.scopedViews.map(({ id }) => id),
    customFilters: state.customFilters,
    initialize,
    isInitialized: state.isInitialized,
    queryFilters: getFiltersForAllDataSources(
      state.scopedViews,
      state.customFilters
    ),
    scopedViews: state.scopedViews,
    set,
  };

  return <context.Provider value={store}>{children}</context.Provider>;
}

export function useFilterStore(): FilterStore {
  const store = useContext(context);

  if (!store) {
    throw new Error("useFilterStore must be wrapped by FilterStoreProvider");
  }

  return store;
}
