import { useQuery } from "@tanstack/react-query";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import CoreAPIClient from "../CoreAPIClient";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";

type CustomLabelEntity = Awaited<
  ReturnType<CoreAPIClient["getCustomLabelsByTenantID"]>
>[0];

type Options = UseQueryOptions<CustomLabelEntity[], CoreAPIClientError>;
type Result = UseQueryResult<CustomLabelEntity[], CoreAPIClientError>;

export default function useGetCustomLabelsByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["customLabels"],
    queryFn: () => client.getCustomLabelsByTenantID(tenantID),
    enabled: gatekeeper.canListCustomLabels,
    ...options,
  });
}
