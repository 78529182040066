import { MeasureWithUnit } from "@/constants";
import { useQueryClient } from "@tanstack/react-query";
import {
  availableMeasuresMap,
  awsBillingMeasures,
} from "@ternary/api-lib/constants/analytics";
import { CloudCapability, DataSource } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import { Measure } from "@ternary/api-lib/ui-lib/charts/types";
import queryKeys from "../features/admin/hooks/queryKeys";
import { isAWSIntegration } from "../utils/typeGuards";
import useGatekeeper from "./useGatekeeper";

const customCostMeasures = ["customNetCost", "customNetAmortizedCost"];

export default function useAvailableMeasuresByDataSource(
  dataSource: DataSource
): MeasureWithUnit[] {
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();

  let defaultMeasures = isDataSourceWithMeasure(dataSource)
    ? availableMeasuresMap[dataSource]
    : [];

  if (dataSource === DataSource.BILLING && !gatekeeper.isMspParentTenant) {
    defaultMeasures = defaultMeasures.filter(
      (measure) => measure.name !== "absoluteCreditsResellerMargin"
    );
  }

  const integrations = queryClient.getQueryData<DataIntegrationEntity[]>(
    queryKeys.dataIntegrations
  );

  if (
    dataSource === DataSource.BILLING &&
    integrations &&
    hasAWSCloudWithCURAccess(integrations)
  ) {
    return [...defaultMeasures, ...awsBillingMeasures];
  }

  return defaultMeasures.filter((measure: Measure) => {
    return (
      // Include all measures if custom pricing is enabled, and if it's not, filter out
      // the measures in `customCostMeasures`.
      gatekeeper.canApplyCustomPricing ||
      customCostMeasures.indexOf(measure.name) === -1
    );
  });
}

function isDataSourceWithMeasure(
  dataSource: DataSource
): dataSource is keyof typeof availableMeasuresMap {
  return Boolean(availableMeasuresMap[dataSource]);
}

function hasAWSCloudWithCURAccess(integrations: DataIntegrationEntity[]) {
  const awsCloudsWithCUR = integrations.filter((integration) => {
    if (!isAWSIntegration(integration)) return false;

    if (
      !integration.validations.find(
        (validation) =>
          validation.name === CloudCapability.BILL_DATA_AWS &&
          validation.success
      )
    ) {
      return false;
    }

    return true;
  });

  return awsCloudsWithCUR.length > 0;
}
