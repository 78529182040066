import { ChartType, DataSource, TimeGranularity } from "../constants/enums";
import { DatadogIntegrationEntity } from "../core/types";
import { Measure } from "../ui-lib/charts/types";
import { DEFAULT_X_AXIS_KEY } from "../ui-lib/charts/utils";
import copyText from "../ui-lib/copyText";
import { getIsInvoiceMonthMode } from "../utils/ReportUtils";
import { ReportDataConfig } from "./types";
import { addComparisonToReportMeasures } from "./utils";

const DEFAULT_FORMULA_ALIAS = copyText.unitEconomicsFormulaPlaceHolder;

type GetReportChartConfigurationParams = {
  integration: Pick<DatadogIntegrationEntity, "metrics"> | null;
  report: ReportDataConfig;
};

export function getReportChartConfiguration(
  params: GetReportChartConfigurationParams
) {
  //
  // COMPARISON MODE
  //

  const comparisonMode = !!params.report.compareDurationType;

  //
  // DIMENSIONS
  //

  const dimensions = params.report.dimensions.map((dimension) => ({
    name: dimension,
    isDate: false,
  }));

  //
  // IMPACT MODE
  //

  const impactMode = params.report.dataSource === DataSource.CARBON_FOOTPRINT;

  //
  // IS INVOICE MONTH MODE
  //

  const isInvoiceMonthMode = Boolean(
    getIsInvoiceMonthMode(params.report) &&
      params.report.xAxisKey === DEFAULT_X_AXIS_KEY
  );

  //
  // MEASURES
  //

  const showUnitEconomics = !!params.report.formula || !!params.report.metric;

  const selectedMetric =
    params.integration?.metrics && params.report.metric
      ? params.integration.metrics[params.report.metric]
      : undefined;

  const selectedMetricName = selectedMetric ? selectedMetric.name : undefined;

  const measuresWithCompare = addComparisonToReportMeasures(params.report);

  let measures: Measure[] = [
    ...measuresWithCompare.filter(
      (measure) =>
        !params.report.hiddenMeasures.some(
          (hiddenMeasure) => hiddenMeasure === measure.name
        )
    ),
  ];

  if (
    (params.report.metric ?? "").length > 0 &&
    selectedMetricName &&
    !params.report.isMetricHidden &&
    showUnitEconomics &&
    params.report.dimensions.length === 0
  ) {
    measures.push({ name: selectedMetricName });
  }

  if (!params.report.isFormulaHidden && params.report.formula) {
    measures.push({
      name:
        params.report.formulaAlias && params.report.formulaAlias.length > 0
          ? params.report.formulaAlias
          : copyText.unitEconomicsFormulaPlaceHolder,
    });
  }

  if (params.report.chartType === ChartType.KPI) {
    measures = getKPIMeasures(params.report, measures, params.integration);
  }

  //
  // SHOULD APPLY GRANULARITY
  //

  const shouldApplyGranularity =
    params.report.chartType !== ChartType.PIE &&
    params.report.chartType !== ChartType.TABLE &&
    (params.report.xAxisKey === DEFAULT_X_AXIS_KEY || !params.report.xAxisKey);

  //
  // TIME GRANULARITY
  //

  const timeGranularity = params.report.timeGranularity ?? TimeGranularity.DAY;

  //
  // X AXIS KEY
  //

  const xAxisKey = isInvoiceMonthMode
    ? "invoiceMonth"
    : (params.report.xAxisKey ?? DEFAULT_X_AXIS_KEY);

  return {
    comparisonMode,
    dimensions,
    impactMode,
    isInvoiceMonthMode,
    measures,
    shouldApplyGranularity,
    timeGranularity,
    xAxisKey,
  };
}

function getKPIMeasures(
  report: ReportDataConfig,
  existingMeasures: Measure[],
  integration: Pick<DatadogIntegrationEntity, "metrics"> | null
) {
  const formulaAlias = report.formulaAlias ?? DEFAULT_FORMULA_ALIAS;

  const formulaMeasure = existingMeasures.find(
    (measure) => measure.name === formulaAlias
  );

  if (formulaMeasure) {
    return [formulaMeasure];
  }

  const reportMetricName =
    report.metric && (integration?.metrics ?? {})[report.metric]?.name;

  const metricMeasure = existingMeasures.find(
    (measure) => reportMetricName && measure.name === reportMetricName
  );

  if (metricMeasure) {
    return [metricMeasure];
  }

  return existingMeasures;
}
