import { locationStateKeyedByPath } from "@/constants/paths";
import { useFilterStore } from "@/context/FilterStoreProvider";
import { useMatchPath } from "@/lib/react-router";
import { FilterDataJSONParam } from "@/lib/use-query-params";
import {
  DurationType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewEntity } from "@ternary/api-lib/core/types";
import { useEffect } from "react";
import { DateParam, createEnumParam, useQueryParams } from "use-query-params";
import { AuthenticatedUserEntity } from "../api/core/types";
import useGetLabelMapsByTenantID from "../api/core/useGetLabelMapsByTenantID";
import useGetScopedViewFiltersByIDs from "../api/core/useGetScopedViewFiltersByIDs";
import useGetScopedViewsByTenantID from "../features/user-settings/hooks/useGetScopedViewsByTenantID";

const durationEnum = createEnumParam(Object.values(DurationType));

export default function useInitFilterStore(
  authenticatedUser?: AuthenticatedUserEntity
): void {
  const currentPath = useMatchPath();
  const filterStore = useFilterStore();

  const [searchParamState, setSearchParamState] = useQueryParams({
    filter_data: FilterDataJSONParam,
    global_date_range_end: DateParam,
    global_date_range_start: DateParam,
    duration: durationEnum,
  });

  const authID = searchParamState.filter_data?.auth_id ?? "";
  const filters = searchParamState.filter_data?.filters ?? [];
  const scopedViewIDs = searchParamState.filter_data?.sv_ids ?? [];

  const isSharedView =
    !!authID && !!authenticatedUser && authID !== authenticatedUser.id;

  const locationState = locationStateKeyedByPath[currentPath];

  const enableGlobalFiltering = locationState?.enableGlobalFiltering ?? false;
  const enableGlobalDate = locationState?.enableGlobalDate ?? false;

  const { isFetched: hasFetchedLabelMaps } = useGetLabelMapsByTenantID(
    authenticatedUser?.tenant.fsDocID ?? "",
    { enabled: !!authenticatedUser }
  );

  const { data: scopedViews = [], isFetched: hasFetchedScopedViews } =
    useGetScopedViewsByTenantID(authenticatedUser?.tenant.fsDocID ?? "", {
      enabled: !!authenticatedUser,
    });

  const { data: _filters = [], isFetched: hasFetchedFilters } =
    useGetScopedViewFiltersByIDs(scopedViewIDs, { enabled: isSharedView });

  useEffect(() => {
    if (
      !authenticatedUser ||
      !hasFetchedLabelMaps ||
      !hasFetchedScopedViews ||
      (isSharedView && !hasFetchedFilters)
    ) {
      return;
    }

    // Personal View Path
    if (authID === authenticatedUser.id) {
      const enabledScopedViews = scopedViews.reduce(
        (accum: ScopedViewEntity[], scopedView) =>
          scopedViewIDs.includes(scopedView.id)
            ? [...accum, scopedView]
            : accum,
        []
      );

      filterStore.initialize({
        customFilters: filters,
        scopedViews: enabledScopedViews,
      });

      return;
    }

    // Shared View Path

    if (isSharedView) {
      filterStore.initialize({
        customFilters: [..._filters, ...filters],
      });

      return;
    }

    // No search params path
    const availableScopedViews = scopedViews.reduce(
      (accum: ScopedViewEntity[], scopedView) =>
        scopedView.userConfigs.some(
          (userConfig) =>
            userConfig.userID === authenticatedUser.id &&
            (userConfig.status === UserConfigStatus.ENABLED ||
              userConfig.status === UserConfigStatus.ENFORCED ||
              userConfig.status === UserConfigStatus.ENABLED_STRICT)
        )
          ? [...accum, scopedView]
          : accum,
      []
    );

    const availableScopedViewIDs = availableScopedViews.map(
      (scopedView) => scopedView.id
    );

    const data = {
      auth_id: authenticatedUser.id,
      ...(availableScopedViewIDs.length > 0
        ? { sv_ids: availableScopedViewIDs }
        : {}),
    };

    if (enableGlobalFiltering) {
      setSearchParamState(
        {
          filter_data: data,
          ...(enableGlobalDate &&
          searchParamState.global_date_range_start &&
          searchParamState.global_date_range_end
            ? {
                global_date_range_end: searchParamState.global_date_range_end,
                global_date_range_start:
                  searchParamState.global_date_range_start,
                duration: searchParamState.duration,
              }
            : {}),
        },
        "replace"
      );
    }

    filterStore.initialize({
      scopedViews: availableScopedViews,
    });
  }, [hasFetchedFilters, hasFetchedLabelMaps, hasFetchedScopedViews]);
}
