// Balkan Brothers Color Names in Comments
// 60 is primary

export default {
  aqua: {
    [10]: "#E8F7FE", // Aqua 10
    [20]: "#B6E5FA", // Aqua 20
    [60]: "#14B3F2", // Aqua
    [70]: "#159DD4", // Hover Aqua
    [100]: "#005E85", // Dark Aqua
  },
  blue: {
    [10]: "#E9F3FF", // Blue 10
    [20]: "#B5D7FF", // Blue 20
    [60]: "#1E87FF", // Sky Blue
    [70]: "#1E78DF", // Hover Blue
    [100]: "#003282", // Dark Blue
  },
  green: {
    [10]: "#F2F9EF", // Green 10
    [20]: "#E0F5D8", // Green 20
    [60]: "#7BC75B", // Green
    [70]: "#6DAE53", // Hover Green
    [100]: "#5A8A46", // Dark Green
  },
  grey: {
    [10]: "#EEF1F2", // Cloudy
    [20]: "#E2E6E8", // Rainy
    [30]: "#D0D7D9",
    [50]: "#BAC0C2", // Foggy
    [60]: "#A0A7AB", // Thunder
    [90]: "#787E82", // Storm
    [100]: "#464B50", // Smog
  },
  orange: {
    [10]: "#FFF5ED", // Mango 10
    [20]: "#FFDBBF", // Mango 20
    [60]: "#FF9A4A", // Mango
    [70]: "#DD8845", // Hover Mango
    [100]: "#9E561C", // Dark Mango
  },
  purple: {
    [10]: "#FAEFF8", // Purple 10
    [20]: "#F5BFE9", // Purple 20
    [60]: "#CC60B5", // Purple
    [70]: "#B257A0", // Hover Purple
    [100]: "#73205F", // Dark Purple
  },
  red: {
    [10]: "#FFEFF0", // Rose 10
    [20]: "#FFC7CA", // Rose 20
    [60]: "#FA5F69", // Rose
    [70]: "#D9565F", // Hover Rose
    [100]: "#8C2428", // Dark Rose
  },
  teal: {
    [10]: "#EEFAF9", // Teal 10
    [20]: "#C0F0E8", // Teal 20
    [60]: "#52D1BF", // Teal
    [70]: "#4AB7A8", // Hover Teal
    [100]: "#1D7A68", // Dark Teal
  },
  violet: {
    [10]: "#F5EFFB", // Violet 10
    [20]: "#DAC4F2", // Violet 20
    [60]: "#9D61D9", // Violet
    [70]: "#8A58BE", // Hover Violet
    [100]: "#47236B", // Dark Violet
  },
  yellow: {
    [10]: "#FFFAEE", // Yellow 10
    [20]: "#FFF1C4", // Yellow 20
    [60]: "#FAD155", // Yellow
    [70]: "#D9B74E", // Hover Yellow
    [100]: "#A38423", // Dark Yellow
  },
  offBlack: "#1E2328",
  black: "#000000",
  white: "#FFFFFF",
};
