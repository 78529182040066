export const billingDimensions = {
  ancestryIDs: "ancestryIds",
  ancestryNames: "ancestryNames",
  billingAccountID: "billingAccountId",
  billingAccountName: "billingAccountName",
  billingEntity: "billingEntity",
  category: "category",
  cloudID: "cloudId",
  cloudName: "cloudName",
  commitmentPaymentModel: "commitmentPaymentModel",
  dataSource: "dataSource",
  databaseEngine: "databaseEngine",
  invoiceMonth: "invoiceMonth",
  lineItemType: "lineItemType",
  location: "location",
  operatingSystem: "operatingSystem",
  parentID: "parentId",
  parentName: "parentName",
  pricingModel: "pricingModel",
  pricingUnit: "pricingUnit",
  projectID: "projectId",
  projectName: "projectName",
  projectNumber: "projectNumber",
  region: "region",
  sellingEntity: "sellingEntity",
  serviceDescription: "serviceDescription",
  serviceID: "serviceId",
  skuDescription: "skuDescription",
  skuID: "skuId",
  tenancy: "tenancy",
  usageUnit: "usageUnit",
  zone: "zone",
  vendor: "vendor",
  customReason: "customReason",
} as const;
