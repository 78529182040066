import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import React, { useMemo } from "react";
import copyText from "../copyText";

type User = {
  tenantID: string;
  createdAt: string;
  email: string;
  loginCount: number;
  lastLogin: string | null;
  name: string;
  tenantName: string;
};

const columnHelper = createColumnHelper<User>();

interface Props {
  isLoading: boolean;
  users: User[];
}

export function MspChildUserAccessTable(props: Props) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", { header: copyText.tableHeaderName }),
      columnHelper.accessor("email", { header: copyText.tableHeaderEmail }),
      columnHelper.accessor("tenantName", {
        header: copyText.tableHeaderTenantName,
      }),
      columnHelper.accessor("tenantID", {
        header: copyText.tableHeaderTenantID,
      }),
    ],
    [props.users]
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.users}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

export default MspChildUserAccessTable;
