import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const GCPComputeInstanceStruct = {
  cpuUtilization: nullable(number()),
  diskUtilization: nullable(number()),
  family: nullable(string()),
  familyType: nullable(string()),
  instanceId: nullable(string()),
  instanceName: nullable(string()),
  instanceType: nullable(string()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  projectId: nullable(string()),
  provisionedDiskBytes: nullable(number()),
  ramUtilization: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const GCPComputeInstanceGroupStruct = {
  family: nullable(string()),
  familyType: nullable(string()),
  instanceIDCount: nullable(number()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  projectId: nullable(string()),
  provisionedDiskBytes: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
};

export type GCPComputeInstance = NotNullable<
  Infer<typeof GCPComputeInstanceStruct>
>;
export type GCPComputeInstanceGroup = NotNullable<
  Infer<typeof GCPComputeInstanceGroupStruct>
>;

export type GCPComputeInstanceGroupFilters = {
  family: string | null;
  familyType: string | null;
  projectId: string | null;
  region: string | null;
};
