/* prettier-ignore */
export default {
  budgetsCurrentDailyGraphTitle: "Current Month",
  budgetsCurrentDailyGraphTooltip: "See your current month spend and the forecast for the remainder of the month.",
  budgetsHistoricalDailyGraphTitle: "Daily Trends",
  budgetsHistoricalDailyGraphTooltip: "See your daily spend, overlayed over your previous months' spend on the same days to reveal daily trends.",
  chartEmptyPlaceholderText: "No Usage Data to Display",
  dataAttributeMeasure: "Measure",
  dimensionOtherNotShown: "Other (not shown)",
  dataTableColumnLimitReached: "Column limit reached. To see the full result, you may want to export the dataset as a CSV",
  dataTableRowLimitReached: "Row limit reached. To see the full result, you may want to export the dataset as a CSV",
  datePickerModalDays: "Days",
  datePickerModalIncludeCurrentLabel: "Include Current",
  datePickerModalMonths: "Months",
  dataTableTotalsHeader: "Totals",
  dataTableTotalsDeltasHeader: "Totals Deltas",
  durationTypeCustomCaption: "Custom",
  durationTypeFiscalLastNinetyDaysCaption: "Prev. Quarter",
  durationTypeFiscalLastSevenDaysCaption: "Prev. Week",
  durationTypeFiscalLastThirtyDaysCaption: "Prev. Month",
  durationTypeFiscalMonthToDateDaysCaption: "Fiscal MTD",
  durationTypeFiscalQuarterToDateCaption: "Fiscal QTD",
  durationTypeFiscalYearToDateCaption: "Fiscal YTD",
  durationTypeInvoiceCaption: "Invoice",
  durationTypeLastMonthCaption: "Last Month",
  durationTypeLastNinetyDaysCaption: "Last 90 Days",
  durationTypeLastSevenDaysCaption: "Last 7 Days",
  durationTypeLastThirtyDaysCaption: "Last 30 Days",
  durationTypeMonthToDateDaysCaption: "Month to Date",
  durationTypeQuarterToDateCaption: "Quarter to Date",
  durationTypeTodayCaption: "Today",
  durationTypeYearToDateCaption: "Year to Date",
  durationTypeYesterdayCaption: "Yesterday",
  moreOptionsLabel: "More...",
  noDataPlaceholderMessage: "No Data To Display",
  numRowsHidden: "%COUNT% More...",
  otherColumnMessage: "Limit reached for non-timeseries data",
  paginationExplanation: "Showing %start% to %end% out of %total%",
  paginationStatusLabelOutOf: "out of",
  paginationStatusLabelRange: "%start% to %end%",
  paginationStatusLabelShowing: "Showing",
  reportTimeLastUpdatedCaption: "Updated %distance% ago",
  unitEconomicsFormulaPlaceHolder: "Formula",
};
