import {
  AlertRuleConditionType,
  AlertRuleDirection,
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";

export const LEGACY_BIG_QUERY_RULE_ID = "LEGACY_BIGQUERY_ANOMALY_ML";
export const LEGACY_BILLING_RULE_ID = "LEGACY_BILLING_ANOMALY_ML";

export const legacyBillingAlertRule = {
  id: LEGACY_BILLING_RULE_ID,
  condition: {
    direction: AlertRuleDirection.INCREASE_ONLY,
    lookbackDays: 90,
    minimumDelta: 200,
    sensitivity: 0.5,
    type: AlertRuleConditionType.ANOMALY_DETECTION,
  },
  dataSource: DataSource.BILLING,
  dimensions: ["category", "projectId"],
  measure: "cost",
  name: "Legacy Billing Anomaly Alert",
  timeGranularity: TimeGranularity.DAY,
};

export const legacyBigQueryAlertRule = {
  id: LEGACY_BIG_QUERY_RULE_ID,
  condition: {
    direction: AlertRuleDirection.INCREASE_ONLY,
    lookbackDays: 90,
    minimumDelta: 500,
    sensitivity: 0.5,
    type: AlertRuleConditionType.ANOMALY_DETECTION,
  },
  dataSource: DataSource.BIGQUERY_USAGE,
  dimensions: ["projectId"],
  filters: [
    { name: "jobType", operator: Operator.NOT_EQUALS, values: ["LOAD"] },
    { name: "reservationId", operator: Operator.NOT_SET },
  ],
  measure: "onDemandCost",
  name: "Legacy BigQuery Anomaly Alert",
  timeGranularity: TimeGranularity.HOUR,
};
