import { useTheme } from "@emotion/react";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  CloudProviderType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { startOfDay, sub } from "date-fns";
import { fromZonedTime } from "date-fns-tz";
import { groupBy, isEqual, uniq } from "lodash";
import React, { useMemo } from "react";
import {
  DecodedValueMap,
  StringParam,
  createEnumParam,
  useQueryParams,
} from "use-query-params";
import { z } from "zod";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import { DateHelper } from "../../../../lib/dates";
import { createStructParam } from "../../../../lib/use-query-params";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import copyText from "../../copyText";
import useGetAWSCommitmentInventory from "../hooks/useGetAWSCommitmentInventory";
import useGetAWSCommitmentUsage from "../hooks/useGetAWSCommitmentUsage";
import {
  AWSCommitmentInventoryDatum,
  AWSCommitmentInventoryFilter,
  AWSCommitmentUsageDatum,
  AWSCommittedUseLookbackPeriod,
  AWSCommittedUseType,
} from "../types";
import AWSCommittedUseVisibilityChart from "./AWSCommittedUseVisibilityChart";
import AWSCommittedUseVisibilityControls from "./AWSCommittedUseVisibilityControls";
import AWSCommittedUseVisibilityMeters from "./AWSCommittedUseVisibilityMeters";
import AWSCommitmentInventoryTable from "./AWSCommittedUseVisibilityTable";
import AWSCommittedUseVisibilityTableControls from "./AWSCommittedUseVisibilityTableControls";

type CustomMeasure = {
  key: string;
  label: string;
  color: string;
};

type Interaction = AWSCommittedUseVisibilityControls.Interaction;

const tableFilterParam = createStructParam(
  z.object({
    type: z.nullable(z.string()),
    family: z.nullable(z.string()),
    instanceSize: z.nullable(z.string()),
    region: z.nullable(z.string()),
    operatingSystem: z.nullable(z.string()),
    commitmentId: z.nullable(z.string()),
    status: z.nullable(z.string()),
  })
);

const queryParamConfigMap = {
  lookback_p: StringParam,
  cloud_id: StringParam,
  rec_payer_id: StringParam,
  t_filters: tableFilterParam,
  type: createEnumParam(Object.values(AWSCommittedUseType)),
};

export default function AWSCommittedUseVisibilityContainer() {
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = getQueryParamState(queryParams);
  const dateRange = getDateRangeFromLookback(queryParamState.lookbackPeriod);
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const { data: integrations = [], isLoading: isLoadingIntegrations } =
    useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id);

  const integrationNamesKeyedByIntegrationID = Object.fromEntries(
    integrations.map((integration) => [integration.id, integration.name])
  );

  const allAWSCloudIDs = integrations
    .filter((integration) => integration.providerType === CloudProviderType.AWS)
    .map((integration) => integration.id);

  const cloudIDFilter = queryParamState.cloudID
    ? [
        {
          name: "cloudId",
          operator: Operator.EQUALS,
          values: [queryParamState.cloudID],
        },
      ]
    : [];

  const inventoryTypeFilter =
    queryParamState.type === null
      ? []
      : [
          {
            name: "type",
            operator: Operator.EQUALS,
            values: [
              queryParamState.type === AWSCommittedUseType.RI
                ? "Reserved Instance"
                : "Savings Plan",
            ],
          },
        ];

  const usageTypeFilter: QueryFilter[] =
    queryParamState.type === null
      ? []
      : [
          {
            name:
              queryParamState.type === AWSCommittedUseType.RI
                ? "savingsPlanARN"
                : "reservedInstanceARN",
            operator: Operator.NOT_SET,
          },
        ];

  const {
    data: awsCommitmentInventoryData = [],
    isLoading: isLoadingAWSCommitmentInventoryData,
  } = useGetAWSCommitmentInventory({
    dateRange,
    queryFilters: [...cloudIDFilter, ...inventoryTypeFilter],
  });

  const {
    data: awsCommitmentInventoryTotals = [],
    isLoading: isLoadingAWSCommitmentInventoryTotals,
  } = useGetAWSCommitmentInventory({
    dateRange,
    isTotals: true,
    queryFilters: [...cloudIDFilter, ...inventoryTypeFilter],
  });

  const {
    data: dailyAWSCommitmentUsageData = [],
    isLoading: isLoadingDailyAWSCommitmentUsageData,
  } = useGetAWSCommitmentUsage({
    dateRange: dateRange,
    granularity: TimeGranularity.DAY,
    queryFilters: [...cloudIDFilter, ...usageTypeFilter],
  });

  const {
    data: awsCommitmentUsageTotals = [],
    isLoading: isLoadingAWSCommitmentUsageTotals,
  } = useGetAWSCommitmentUsage({
    dateRange: dateRange,
    queryFilters: [...cloudIDFilter, ...usageTypeFilter],
  });

  const coverage = useMemo(() => {
    const coverage = awsCommitmentUsageTotals.reduce(
      (accum, datum) => ({
        both:
          accum.both + datum.riCoveragePercentage + datum.spCoveragePercentage,
        ri: accum.ri + datum.riCoveragePercentage,
        sp: accum.sp + datum.spCoveragePercentage,
      }),
      { both: 0, ri: 0, sp: 0 }
    );

    if (awsCommitmentUsageTotals.length > 0) {
      coverage.ri /= awsCommitmentUsageTotals.length;
      coverage.sp /= awsCommitmentUsageTotals.length;
      coverage.both /= awsCommitmentUsageTotals.length;
    }

    return coverage;
  }, [awsCommitmentUsageTotals]);

  const coveragePercentage =
    queryParamState.type === AWSCommittedUseType.RI
      ? coverage.ri
      : queryParamState.type === AWSCommittedUseType.SP
        ? coverage.sp
        : coverage.both;

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_LOOKBACK_PERIOD:
        setQueryParams({ lookback_p: interaction.lookbackPeriod });
        break;
      case AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_CLOUD_ID:
        setQueryParams({
          cloud_id: interaction.cloudID,
          ...(interaction.cloudID !== null
            ? { rec_payer_id: interaction.cloudID }
            : {}),
        });
        break;
      case AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_TYPE:
        setQueryParams({ type: interaction.committedUseType });
        break;
      default:
        break;
    }
  }

  function updateTableFilter(
    filterKey: keyof AWSCommitmentInventoryFilter,
    value: string | null
  ) {
    const nextFilter = {
      ...queryParamState.tableFilters,
      [filterKey]: value,
    };

    setQueryParams({
      t_filters: isEqual(nextFilter, defaultTableFilters) ? null : nextFilter,
    });
  }

  const isLoading =
    isLoadingAWSCommitmentInventoryData ||
    isLoadingAWSCommitmentInventoryTotals ||
    isLoadingAWSCommitmentUsageTotals ||
    isLoadingIntegrations ||
    isLoadingDailyAWSCommitmentUsageData;

  const filteredInventoryData = filterInventoryData(
    queryParamState.tableFilters,
    awsCommitmentInventoryData
  );

  const csvInventoryData = getCSVData(filteredInventoryData);

  const coverageChartCustomMeasures = useMemo(() => {
    const customMeasures: CustomMeasure[] = [];
    const hideSPMeasures = queryParamState.type === AWSCommittedUseType.RI;
    const hideRIMeasures = queryParamState.type === AWSCommittedUseType.SP;

    if (!hideSPMeasures) {
      customMeasures.push({
        key: "spCoveredUsageCost",
        color: theme.aws_cud_color_vis_0_sp_cost,
        label: copyText.awsReadableKey_spDailyCost,
      });
    }

    if (!hideRIMeasures) {
      customMeasures.push({
        key: "riCoveredUsageCost",
        color: theme.aws_cud_color_vis_1_ri_cost,
        label: copyText.awsReadableKey_riDailyCost,
      });
    }

    customMeasures.push({
      key: "onDemandCost",
      color: theme.aws_cud_color_vis_2_on_demand_cost,
      label: copyText.awsReadableKey_onDemandCost,
    });

    if (!hideRIMeasures) {
      customMeasures.push({
        key: "riUnusedCommitmentCost",
        color: theme.aws_cud_color_vis_3_unused_cost,
        label: copyText.awsReadableKey_riUnusedCommitmentCost,
      });
    }

    return customMeasures;
  }, [queryParamState.type]);

  const savingsChartData = useMemo(
    () => getSavingsChartData(dailyAWSCommitmentUsageData),
    [dailyAWSCommitmentUsageData]
  );

  const savingsChartCustomMeasures = [
    {
      key: "lost",
      color: theme.aws_cud_color_vis_lost,
      label: copyText.awsReadableKey_lost,
    },
    {
      key: "saved",
      color: theme.aws_cud_color_vis_saved,
      label: copyText.awsReadableKey_saved,
    },
  ];

  return (
    <Box>
      <Box marginBottom={theme.space_lg}>
        <AWSCommittedUseVisibilityControls
          allCloudIDs={allAWSCloudIDs}
          cloudNamesKeyedByCloudID={integrationNamesKeyedByIntegrationID}
          committedUseType={queryParamState.type}
          isLoading={isLoading}
          lookbackPeriod={queryParamState.lookbackPeriod}
          cloudID={queryParamState.cloudID}
          onInteraction={handleInteraction}
        />
      </Box>

      <Box marginBottom={theme.space_lg}>
        <AWSCommittedUseVisibilityMeters
          commitmentCount={awsCommitmentInventoryData.length}
          coveragePercentage={coveragePercentage}
          inventoryTotals={awsCommitmentInventoryTotals?.[0] ?? null}
          isLoading={isLoading}
        />
      </Box>

      <Flex height={500} marginBottom={theme.space_lg}>
        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleUsage}
          </Text>
          <Box flex="1 0 0">
            <AWSCommittedUseVisibilityChart
              data={dailyAWSCommitmentUsageData}
              customMeasures={coverageChartCustomMeasures}
              isLoading={isLoading}
            />
          </Box>
        </Flex>

        <Box width={theme.space_lg} />

        <Flex
          direction="column"
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          paddingBottom={theme.space_md}
          width={`calc(50% - (${theme.space_lg} / 2))`}
        >
          <Text fontSize={theme.h3_fontSize} marginBottom={theme.space_sm}>
            {copyText.awsChartTitleCost}
          </Text>
          <Box flex="1 0 0">
            <AWSCommittedUseVisibilityChart
              data={savingsChartData}
              customMeasures={savingsChartCustomMeasures}
              isLoading={isLoading}
            />
          </Box>
        </Flex>
      </Flex>

      <Box marginBottom={theme.space_lg}>
        <Box
          padding={theme.space_sm}
          borderRadius={theme.borderRadius_1}
          backgroundColor={theme.panel_backgroundColor}
          marginBottom={theme.space_md}
        >
          <AWSCommittedUseVisibilityTableControls
            csvData={csvInventoryData}
            filters={queryParamState.tableFilters}
            onRemoveFilter={(key) => updateTableFilter(key, null)}
          />
        </Box>

        <Box overflowX="auto">
          <AWSCommitmentInventoryTable
            inventory={filteredInventoryData}
            isLoading={isLoading}
            onAddFilter={(key, value) => updateTableFilter(key, value)}
          />
        </Box>
      </Box>
    </Box>
  );
}

function getDateRangeFromLookback(lookback: string) {
  const utcDate = fromZonedTime(startOfDay(new DateHelper().date), "UTC");
  const now = sub(utcDate, { days: 1 });

  switch (lookback) {
    case AWSCommittedUseLookbackPeriod.SIXTY_DAYS:
      return [sub(now, { days: 60 }), now];
    case AWSCommittedUseLookbackPeriod.THIRTY_DAYS:
      return [sub(now, { days: 30 }), now];
    case AWSCommittedUseLookbackPeriod.FOURTEEN_DAYS:
      return [sub(now, { days: 14 }), now];
    case AWSCommittedUseLookbackPeriod.SEVEN_DAYS:
      return [sub(now, { days: 7 }), now];
    default:
      return [sub(now, { days: 30 }), now];
  }
}

const defaultTableFilters = {
  type: null,
  family: null,
  instanceSize: null,
  region: null,
  operatingSystem: null,
  commitmentId: null,
  status: null,
};

function getQueryParamState(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  return {
    type: params.type ?? null,
    cloudID: params.cloud_id ?? null,
    lookbackPeriod:
      params.lookback_p ?? AWSCommittedUseLookbackPeriod.THIRTY_DAYS,
    tableFilters: params.t_filters ?? defaultTableFilters,
  };
}

function filterInventoryData(
  filters: AWSCommitmentInventoryFilter,
  data: AWSCommitmentInventoryDatum[]
) {
  return data.filter((datum) => {
    if (filters.type !== null && filters.type !== datum.type) {
      return false;
    }
    if (filters.family !== null && filters.family !== datum.family) {
      return false;
    }
    if (
      filters.instanceSize !== null &&
      filters.instanceSize !== datum.instanceSize
    ) {
      return false;
    }
    if (filters.region !== null && filters.region !== datum.region) {
      return false;
    }
    if (
      filters.operatingSystem !== null &&
      filters.operatingSystem !== datum.operatingSystem
    ) {
      return false;
    }
    if (
      filters.commitmentId !== null &&
      filters.commitmentId !== datum.commitmentId
    ) {
      return false;
    }
    if (filters.status !== null && filters.status !== datum.state) {
      return false;
    }
    return true;
  });
}

/* prettier-ignore */
const csvAccessors = [
  ["type", "awsInventoryTableHeader_00_type"],
  ["family", "awsInventoryTableHeader_01_family"],
  ["instanceSize", "awsInventoryTableHeader_02_instanceSize"],
  ["region", "awsInventoryTableHeader_03_region"],
  ["operatingSystem", "awsInventoryTableHeader_04_operatingSystem"],
  ["instanceCount", "awsInventoryTableHeader_05_instanceCount"],
  ["commitmentId", "awsInventoryTableHeader_06_commitmentId"],
  ["realizedSavings", "awsInventoryTableHeader_07_realizedSavings"],
  ["unrealizedSavings", "awsInventoryTableHeader_08_unrealizedSavings"],
  ["utilizationPercentage", "awsInventoryTableHeader_09_utilizationPercentage"],
  ["status", "awsInventoryTableHeader_10_status"],
  ["purchaseDate", "awsInventoryTableHeader_11_purchaseDate"],
  ["expirationDate", "awsInventoryTableHeader_12_expirationDate"],
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: AWSCommitmentInventoryDatum[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    type: datum.type,
    family: datum.family,
    instanceSize: datum.instanceSize,
    region: datum.region,
    operatingSystem: datum.operatingSystem,
    instanceCount: datum.instanceCount,
    commitmentId: datum.commitmentId,
    realizedSavings: datum.realizedSavings,
    unrealizedSavings: datum.unrealizedSavings,
    utilizationPercentage: datum.utilizationPercentage,
    status: datum.state,
    purchaseDate: datum.purchaseDate,
    expirationDate: datum.expirationDate,
  }));

  const headers = csvAccessors.map(([_dataKey, _copyTextKey]) => {
    // ensure rows has a value for each accessor
    const dataKey: keyof (typeof rows)[number] = _dataKey;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = _copyTextKey;
    const label = copyText[copyTextKey];

    return { key: dataKey, label };
  });

  return { headers, rows };
}

function getSavingsChartData(usageData: AWSCommitmentUsageDatum[]) {
  const timestamps = uniq(usageData.map((datum) => datum.timestamp));
  const dataGroupedByTimestamp = groupBy(usageData, "timestamp");

  const savingsChartData = timestamps.map((timestamp) => {
    const usageOnTimestamp = dataGroupedByTimestamp[timestamp];

    const totalSaved = usageOnTimestamp.reduce(
      (total, datum) => total + datum.realizedSavings,
      0
    );

    return totalSaved < 0
      ? { lost: totalSaved, saved: 0, timestamp }
      : { lost: 0, saved: totalSaved, timestamp };
  });

  return savingsChartData;
}
