import { useTheme } from "@emotion/react";
import { faInfoCircle, faList } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import copyText from "../copyText";
import { CreditBreakdown } from "../types";

// https://cloud.google.com/billing/docs/how-to/export-data-bigquery-tables#data-schema
export const creditsByType = {
  COMMITTED_USAGE_DISCOUNT_DOLLAR_BASE: {
    text: copyText.creditNameCUDDollarBase,
    description: copyText.creditDescriptionCUDDollarBase,
  },
  COMMITTED_USAGE_DISCOUNT: {
    text: copyText.creditNameCUD,
    description: copyText.creditDescriptionCUD,
  },
  DISCOUNT: {
    text: copyText.creditNameDiscount,
    description: copyText.creditDescriptionDiscount,
  },
  FREE_TIER: {
    text: copyText.creditNameFreeTier,
    description: copyText.creditDescriptionFreeTier,
  },
  PROMOTION: {
    text: copyText.creditNamePromotion,
    description: copyText.creditDescriptionPromotion,
  },
  SUBSCRIPTION_BENEFIT: {
    text: copyText.creditNameSubscriptionBenefit,
    description: copyText.creditDescriptionSubscriptionBenefit,
  },
  SUSTAINED_USAGE_DISCOUNT: {
    text: copyText.creditNameSUD,
    description: copyText.creditDescriptionSUD,
  },
};

interface Props {
  credits: CreditBreakdown[];
  loading: boolean;
}

export default function CreditsBreakdownTable(props: Props): JSX.Element {
  const theme = useTheme();

  if (props.credits.length === 0 || props.loading) {
    return (
      <EmptyPlaceholder
        icon={faList}
        loading={props.loading}
        skeletonVariant="rows"
        text={copyText.creditBreakdownTableEmptyPlaceholderText}
      />
    );
  }

  const totalCredits = props.credits.reduce((accum, b) => accum + b.total, 0);

  const sortedCredits = props.credits
    .filter((credit) => credit.total > 0)
    .sort((a, b) => b.total - a.total);

  return (
    <Flex direction="column" padding={theme.space_sm}>
      {sortedCredits.map((credit) => (
        <Flex
          key={`${credit.total}-${credit.type}`}
          justifyContent="space-between"
          marginBottom={theme.space_xs}
        >
          <Flex justifyContent="space-evenly" alignItems="center">
            <Tooltip
              content={
                creditsByType[credit.type]
                  ? creditsByType[credit.type].description
                  : credit.type
              }
              icon={faInfoCircle}
              width="15rem"
            />
            <Text
              fontSize={theme.fontSize_base}
              fontWeight={theme.fontWeight_semiBold}
            >
              {creditsByType[credit.type]
                ? creditsByType[credit.type].text
                : credit.type}
            </Text>
          </Flex>
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
          >
            {formatCurrencyRounded({
              accounting: true,
              number: credit.total ?? 0,
            })}
          </Text>
        </Flex>
      ))}

      <Flex
        backgroundColor={theme.secondary_color_background}
        justifyContent="space-between"
        padding={theme.space_xxs}
      >
        <Text fontSize={theme.fontSize_base} fontWeight={theme.fontWeight_bold}>
          {copyText.accountingTotalCredits}
        </Text>
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {formatCurrencyRounded({
            accounting: true,
            number: totalCredits,
          })}
        </Text>
      </Flex>
    </Flex>
  );
}
