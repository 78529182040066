export type AWSDatabaseMemoryDBDimension =
  (typeof awsDatabaseMemoryDBDimensions)[keyof typeof awsDatabaseMemoryDBDimensions];

export type AWSDatabaseMemoryDBMeasure =
  (typeof awsDatabaseMemoryDBMeasures)[keyof typeof awsDatabaseMemoryDBMeasures];

export const awsDatabaseMemoryDBDimensions = {
  clusterArn: "clusterArn",
  clusterId: "clusterId",
  dataWriteCost: "dataWriteCost",
  instanceFamily: "instanceFamily",
  instanceMemory: "instanceMemory",
  instanceNetworkPerformance: "instanceNetworkPerformance",
  instanceType: "instanceType",
  instanceVcpu: "instanceVcpu",
  nodeCost: "nodeCost",
  payerAccountId: "payerAccountId",
  region: "region",
  usageAccountId: "usageAccountId",
} as const;

export const awsDatabaseMemoryDBMeasures = {
  cost: "cost",
  nodeCost: "nodeCost",
  dataWriteCost: "dataWriteCost",
  backupStorageCost: "backupStorageCost",
  nodeUsage: "nodeUsage",
  dataWriteUsage: "dataWriteUsage",
  backupStorageUsage: "backupStorageUsage",
} as const;

export type AWSDatabaseMemoryDBDatum = {
  payerAccountId: string;
  usageAccountId: string;
  clusterArn: string;
  clusterId: string;
  instanceType: string;
  instanceMemory: string;
  instanceVcpu: string;
  instanceNetworkPerformance: string;
  instanceFamily: string;
  region: string;
  cost: number;
  nodeCost: number;
  dataWriteCost: number;
  backupStorageCost: number;
  nodeUsage: number;
  dataWriteUsage: number;
  backupStorageUsage: number;
};

export type AWSDatabaseMemoryDBTableFilters = {
  clusterId: string | null;
  dataWriteCost: number | null;
  instanceFamily: string | null;
  instanceMemory: string | null;
  instanceNetworkPerformance: string | null;
  instanceType: string | null;
  instanceVcpu: string | null;
  nodeCost: number | null;
  payerAccountId: string | null;
  region: string | null;
  usageAccountId: string | null;
};
