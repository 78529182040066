import styled from "@emotion/styled";
import * as CSS from "csstype";
import React from "react";

type Direction = "horizontal" | "vertical";

const _Divider = styled("div")<Props>(
  ({ border, color, direction, margin, minHeight, theme }) => ({
    ...(direction === "horizontal"
      ? {
          borderBottom: `${border ?? "1px"} solid ${
            color ?? theme.divider_color
          }`,
          marginBottom: margin ?? theme.space_lg,
          marginTop: margin ?? theme.space_lg,
          width: "100%",
        }
      : {
          borderLeft: `${border ?? "1px"} solid ${
            color ?? theme.divider_color
          }`,
          marginLeft: margin ?? theme.space_lg,
          marginRight: margin ?? theme.space_lg,
          ...(minHeight ? { minHeight } : { height: "100%" }),
        }),
  })
);

interface Props {
  border?: CSS.Property.Border;
  color?: CSS.Property.Color;
  direction?: Direction;
  margin?: CSS.Property.Margin;
  minHeight?: number | string;
}

export default function Divider({
  direction = "horizontal",
  ...props
}: Props): JSX.Element {
  return <_Divider direction={direction} {...props} />;
}
