import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CUDGates = ReturnType<typeof cudGates>;

export default function cudGates(params: GateGetterParameters) {
  return {
    isConfiguredCUDs: () => {
      return params.integrations.length > 0;
    },
    canListCudInventory: params._permissionsIncludeAllOf([
      Permission.READ_CUD_INVENTORY,
    ]),
    canListCudRecommendations: params._permissionsIncludeAllOf([
      Permission.READ_CUD_RECOMMENDATIONS,
    ]),
  };
}
