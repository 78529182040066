import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faClose,
  faPlay,
  faPlus,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { partition } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import copyText from "../copyText";
import TextInput from "./TextInput";

type Option = {
  label: string;
  value: string;
};

type Props = {
  isLoading: boolean;
  options: Option[];
  selectedOptions: string[];
  variant?: "success" | "error";
  onChange: (selectedOptions: string[]) => void;
};

export default function DualListbox(props: Props): JSX.Element {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState("");
  const [selectedOptionLabels, setSelectedOptionLabels] = useState(
    props.selectedOptions
  );

  useEffect(() => {
    setSelectedOptionLabels(props.selectedOptions);
  }, [props.selectedOptions]);

  const filteredOptions = props.options.filter((option) => {
    if (searchText.length === 0) return true;
    const label = `${option.label}`;
    return label.toLowerCase().includes(searchText.toLowerCase());
  });

  const filteredOptionValues = new Set(
    filteredOptions.map(({ value }) => value)
  );

  const [selectedOptions, unselectedOptions] = partition(
    props.options,
    (option) => props.selectedOptions.includes(option.value)
  );

  const filteredSelectedOptions = selectedOptions.filter((option) => {
    if (searchText.length === 0) return true;
    const label = `${option.label}`;
    return label.toLowerCase().includes(searchText.toLowerCase());
  });

  const filteredSelectedOptionValues = new Set(
    filteredSelectedOptions.map(({ value }) => value)
  );

  function handleSelect(option: string) {
    const nextSelectedOptions = [...selectedOptionLabels, option];
    setSelectedOptionLabels(nextSelectedOptions);
    props.onChange(nextSelectedOptions);
    inputRef.current?.focus();
  }

  function handleUnselect(value: string) {
    const nextSelectedOptions = selectedOptionLabels.filter(
      (option) => option !== value
    );

    setSelectedOptionLabels(nextSelectedOptions);
    props.onChange(nextSelectedOptions);
    inputRef.current?.focus();
  }

  function handleSelectAll() {
    const allOptions = props.options.reduce((accum: string[], option) => {
      if (
        filteredOptionValues.has(option.value) ||
        selectedOptionLabels.includes(option.value)
      ) {
        accum.push(option.value);
      }
      return accum;
    }, []);

    setSelectedOptionLabels(allOptions);
    props.onChange(allOptions);
    inputRef.current?.focus();
  }

  function handleUnselectAll() {
    const allOptions = selectedOptions.reduce((accum: string[], option) => {
      if (!filteredSelectedOptionValues.has(option.value)) {
        accum.push(option.value);
      }
      return accum;
    }, []);

    setSelectedOptionLabels(allOptions);
    props.onChange(allOptions);
    inputRef.current?.focus();
  }

  function getButtonCopyText(options: {
    filteredOptionsLength: number;
    optionsLength: number;
    remove: boolean;
  }) {
    const { filteredOptionsLength, optionsLength, remove } = options;
    if (filteredOptionsLength === 0) {
      return remove ? copyText.removeButtonLabel : copyText.addButtonLabel;
    } else if (filteredOptionsLength === optionsLength) {
      return remove
        ? copyText.removeAllButtonLabel
        : copyText.addAllButtonLabel;
    } else if (filteredOptionsLength === 1) {
      return remove
        ? copyText.removeOneButtonLabel
        : copyText.addOneButtonLabel;
    } else {
      return remove
        ? copyText.removeManyButtonLabel.replace(
            "%count%",
            `${filteredOptionsLength}`
          )
        : copyText.addManyButtonLabel.replace(
            "%count%",
            `${filteredOptionsLength}`
          );
    }
  }

  const unselectedFilteredOptions = unselectedOptions.filter(({ value }) =>
    filteredOptionValues.has(value)
  );

  return (
    <Box height={"100%"} width={"100%"}>
      {/* Search Input */}
      <Box marginBottom={theme.space_sm} width={"47%"}>
        <TextInput
          iconEnd={
            <Icon
              color={theme.text_color_secondary}
              clickable
              icon={searchText.length > 0 ? faClose : faSearch}
              onClick={() => {
                setSearchText("");
                inputRef.current?.focus();
              }}
            />
          }
          inputRef={inputRef}
          placeholder={copyText.searchInputPlaceholder}
          size="small"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSelectAll();
              setSearchText("");
            }
          }}
        />
      </Box>

      <Flex direction="row" height={"90%"} justifyContent="space-between">
        <Flex direction="column" width={"47%"}>
          {/* Add All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={unselectedFilteredOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleSelectAll()}
            >
              {getButtonCopyText({
                filteredOptionsLength: unselectedFilteredOptions.length,
                optionsLength: unselectedOptions.length,
                remove: false,
              })}
            </Button>
          </Box>

          {/* Can Add List */}
          <Box
            border={`2px solid ${
              props.variant === "error"
                ? theme.feedback_negative_outline
                : theme.secondary_color_border
            }`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            overflowY="auto"
            padding={theme.space_xxs}
          >
            {props.isLoading ? (
              <EmptyPlaceholder loading={props.isLoading} />
            ) : (
              unselectedFilteredOptions.map((option) => (
                <Option
                  key={option.value}
                  isSelected={false}
                  user={{
                    label: option.label,
                  }}
                  onClick={() => handleSelect(option.value)}
                />
              ))
            )}
          </Box>
        </Flex>

        {/* Arrow Right */}
        <Flex
          alignItems="center"
          justifyContent="center"
          paddingTop={theme.space_sm}
          width={"5%"}
        >
          <Icon color={theme.text_color_secondary} icon={faPlay} />
        </Flex>

        <Flex direction="column" width={"47%"}>
          {/* Remove All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={filteredSelectedOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleUnselectAll()}
            >
              {getButtonCopyText({
                filteredOptionsLength: filteredSelectedOptions.length,
                optionsLength: selectedOptions.length,
                remove: true,
              })}
            </Button>
          </Box>

          {/* Can Remove List */}
          <Box
            border={`2px solid ${
              props.variant === "error"
                ? theme.feedback_negative_outline
                : theme.secondary_color_border
            }`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            overflowY="auto"
            padding={theme.space_xxs}
          >
            {props.isLoading ? (
              <EmptyPlaceholder loading={props.isLoading} />
            ) : (
              filteredSelectedOptions.map((option) => (
                <Option
                  key={option.value}
                  isSelected
                  user={{ label: option.value }}
                  onClick={() => handleUnselect(option.value)}
                />
              ))
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

type OptionProps = {
  user: { label: string };
  isSelected: boolean;
  onClick: () => void;
};

const StyledButton = styled(Button)(() => ({
  "& > span": { justifyContent: "space-between" },
  "& > span > span": { minWidth: 0 },
}));

function Option(props: OptionProps) {
  return (
    <StyledButton
      fullWidth
      iconEnd={
        <Box flex="0 0 auto">
          <Icon icon={props.isSelected ? faXmark : faPlus} />
        </Box>
      }
      size="small"
      type="button"
      onClick={props.onClick}
    >
      <Text truncate>{props.user.label}</Text>
    </StyledButton>
  );
}
