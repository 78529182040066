type User = {
  firstName: string;
  lastName: string;
};

export function getFullName(user?: User | null): string {
  if (!user) return "";

  return `${user.firstName} ${user.lastName}`;
}

export default {
  getFullName,
};
