import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type ReportEntity = Awaited<ReturnType<CoreAPIClient["getReportByID"]>>;

type Options = UseQueryOptions<ReportEntity, CoreAPIClientError>;
type Result = UseQueryResult<ReportEntity, CoreAPIClientError>;

export default function useGetReportByID(
  reportID: string,
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["reports", reportID],
    queryFn: () => client.getReportByID(reportID, tenantID),
    meta: { errorMessage: copyText.errorLoadingReportMessage },
    ...options,
  });
}
