import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AzureAKSClustersGroupEntity,
  AzureAKSDimensions,
  AzureAKSMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export default function useGetAzureAKSClusterGroups(
  params: Params,
  options?: UseQueryOptions<AzureAKSClustersGroupEntity[], UError>
): UseQueryResult<AzureAKSClustersGroupEntity[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    AzureAKSDimensions.cloudId,
    AzureAKSDimensions.clusterName,
    AzureAKSDimensions.resourceGroupName,
    AzureAKSDimensions.subscriptionName,
  ];

  const measures = [
    AzureAKSMeasures.instanceHours,
    AzureAKSMeasures.cost,
    AzureAKSMeasures.upperBoundCpuCoreHoursUsed,
    AzureAKSMeasures.estimatedCpuCoreHoursAvailable,
    AzureAKSMeasures.upperBoundRssByteHoursUsed,
    AzureAKSMeasures.estimatedRssByteHoursAvailable,
  ];

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AZURE_KUBERNETES_NODE_USAGE,
    dateRange: params.dateRange,
    measures,
    dimensions,
  });

  return useQuery({
    queryKey: ["AzureAKSClustersGroups", params],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const clusterGroups = result.response.map((datum) => {
        return {
          // DIMENSIONS
          cloudId: datum.cloudId ?? "",
          clusterName: datum.clusterName ?? "",
          resourceGroupName: datum.resourceGroupName ?? "",
          subscriptionName: datum.subscriptionName ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          estimatedCpuCoreHoursAvailable:
            datum.estimatedCpuCoreHoursAvailable ?? 0,
          estimatedRssByteHoursAvailable:
            datum.estimatedRssByteHoursAvailable ?? 0,
          instanceHours: datum.instanceHours ?? 0,
          upperBoundCpuCoreHoursUsed: datum.upperBoundCpuCoreHoursUsed ?? 0,
          upperBoundRssByteHoursUsed: datum.upperBoundRssByteHoursUsed ?? 0,
        } as AzureAKSClustersGroupEntity;
      });

      return clusterGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
