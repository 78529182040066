import { TableLegacy } from "@/ui-lib/components/Table";
import IconExport from "@/ui-lib/icons/IconExport";
import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEquals,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { Column, Row } from "react-table";
import copyText from "../copyText";

type Annotations = {
  coverageSummary: {
    sourceCostConverted: number;
    sourceCostNormalized: number;
    sourceCostUnconverted: number;
    totalSourceCost: number;
    totalTargetCost: number;
  };
  pivotConverted: {
    byServiceCategory: {
      [key: string]: {
        sourcePlatformCost: number;
        targetPlatformCost: number;
      };
    };
  };
};

type TableData = {
  description: string;
  aws: number;
  gcp: number;
  change: number;
};

interface Props {
  annotations: Annotations;
  billName: string;
}

export default function SavingsBreakdownTable(props: Props): JSX.Element {
  const theme = useTheme();

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "description",
        disableFilters: true,
        Header: copyText.savingsBreakdownTableHeaderDescription,
        sortType: customSort,
        width: 100,
      },
      {
        accessor: "aws",
        Header: copyText.savingsBreakdownTableHeaderAWS,
        width: 100,
        Cell: ({ row }) => (
          <>{formatCurrencyRounded({ number: row.values.aws })}</>
        ),
        sortType: customSort,
      },
      {
        accessor: "gcp",
        Cell: ({ row }) => (
          <>{formatCurrencyRounded({ number: row.values.gcp })}</>
        ),
        sortType: customSort,
        Header: copyText.savingsBreakdownTableHeaderGCP,
        width: 100,
      },
      {
        accessor: "change",
        Header: copyText.savingsBreakdownTableHeaderChange,
        width: 100,
        sortType: customSort,
        Cell: ({ row }) => {
          const changeIconAttributes = getChangeIconAttributes(
            row.values.aws,
            row.values.gcp
          );

          return (
            <Flex justifyContent="center" width="100%">
              <Flex justifyContent="center" width="50%">
                <Text marginRight={theme.space_sm}>
                  {row.values.change > 0 && "+"}
                  {formatPercentage(row.values.change)}
                </Text>
              </Flex>
              <Icon
                color={changeIconAttributes.color}
                icon={changeIconAttributes.icon}
              />
            </Flex>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    return [
      ...Object.entries(props.annotations.pivotConverted.byServiceCategory).map(
        ([key, value]) => ({
          description: key,
          aws: value.sourcePlatformCost,
          gcp: value.targetPlatformCost,
          change:
            value.sourcePlatformCost === 0 || value.sourcePlatformCost === 0
              ? 0
              : (value.targetPlatformCost - value.sourcePlatformCost) /
                value.sourcePlatformCost,
        })
      ),
      {
        description: copyText.savingsBreakdownTableDescriptionSubtotal,
        aws: props.annotations.coverageSummary.sourceCostConverted,
        gcp: props.annotations.coverageSummary.totalTargetCost,
        change:
          (props.annotations.coverageSummary.totalTargetCost -
            props.annotations.coverageSummary.sourceCostConverted) /
          props.annotations.coverageSummary.sourceCostConverted,
      },
      {
        description: copyText.savingsBreakdownTableDescriptionUnconverted,
        aws: props.annotations.coverageSummary.sourceCostUnconverted,
        gcp: props.annotations.coverageSummary.sourceCostUnconverted,
        change: 0,
      },
      {
        description: copyText.savingsBreakdownTableDescriptionTotal,
        aws: props.annotations.coverageSummary.totalSourceCost,
        gcp:
          props.annotations.coverageSummary.totalTargetCost +
          props.annotations.coverageSummary.sourceCostUnconverted,
        change:
          (props.annotations.coverageSummary.totalTargetCost +
            props.annotations.coverageSummary.sourceCostUnconverted -
            props.annotations.coverageSummary.totalSourceCost) /
          props.annotations.coverageSummary.totalSourceCost,
        opitons: {
          disableFilters: true,
        },
      },
    ];
  }, [props.annotations]);

  const csvData = getCSVDataFromEntries(data);

  return (
    <>
      <TableLegacy
        columns={columns}
        compact
        data={data}
        highlightedRows={["Subtotal", "Total"]}
        sortable
      />
      <Box width="25%">
        <CSVLink
          data={csvData.data}
          filename={`savingsBreakdown${props.billName.replace(
            / /g,
            ""
          )}-${formatDate(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<IconExport />}
            marginTop={theme.space_sm}
            width={"100%"}
            primary
          >
            {copyText.exportAsCSVButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </>
  );

  function customSort(
    a: Row<TableData>,
    b: Row<TableData>,
    id: string,
    descending: boolean | undefined
  ) {
    if (
      a.values.description === copyText.savingsBreakdownTableDescriptionSubtotal
    ) {
      return descending ? -1 : 1;
    }
    if (
      a.values.description ===
      copyText.savingsBreakdownTableDescriptionUnconverted
    ) {
      return descending ? -2 : 2;
    }
    if (
      a.values.description === copyText.savingsBreakdownTableDescriptionTotal
    ) {
      return descending ? -3 : 3;
    }

    if (a.values[id] > b.values[id]) return 1;
    if (a.values[id] < b.values[id]) return -1;

    return 0;
  }

  function getChangeIconAttributes(
    sourceValue: number,
    targetValue: number
  ): { color: string; icon: IconDefinition } {
    if (Math.abs(sourceValue - targetValue) < 0.9) {
      return {
        color: "",
        icon: faEquals,
      };
    }

    if (targetValue - sourceValue > 0) {
      return {
        color: theme.feedback_negative,
        icon: faArrowUp,
      };
    }

    return {
      color: theme.feedback_positive,
      icon: faArrowDown,
    };
  }

  type CSVData = {
    data: { [key: string]: string | number | string[] }[];
  };

  type SavingsBreakdown = {
    description: string;
    aws: number;
    gcp: number;
    change: number;
  };

  function getCSVDataFromEntries(entries: SavingsBreakdown[]): CSVData {
    if (!entries) return { data: [] };

    const data: { [key: string]: string | number }[] = entries.map((entry) => {
      return {
        description: entry.description,
        aws: entry.aws,
        gcp: entry.gcp,
        change: entry.change,
      };
    });

    return { data };
  }
}
