export const AzureAKSMeasures = {
  instanceHours: "instanceHours",
  cost: "cost",
  upperBoundCpuCoreHoursUsed: "upperBoundCpuCoreHoursUsed",
  estimatedCpuCoreHoursAvailable: "estimatedCpuCoreHoursAvailable",
  upperBoundRssByteHoursUsed: "upperBoundRssByteHoursUsed",
  estimatedRssByteHoursAvailable: "estimatedRssByteHoursAvailable",
} as const;

export const AzureAKSDimensions = {
  subscriptionName: "subscriptionName",
  resourceGroupName: "resourceGroupName",
  instanceType: "instanceType",
  region: "region",
  clusterName: "clusterName",
  nodepool: "nodepool",
  cloudId: "cloudId",
} as const;

export type AzureAKSClustersGroupEntity = {
  cloudId: string;
  clusterName: string;
  cost: number;
  estimatedCpuCoreHoursAvailable: number;
  estimatedRssByteHoursAvailable: number;
  instanceHours: number;
  resourceGroupName: string;
  subscriptionName: string;
  upperBoundCpuCoreHoursUsed: number;
  upperBoundRssByteHoursUsed: number;
};

export type AzureAKSClustersEntity = {
  cloudId: string;
  clusterName: string;
  cost: number;
  estimatedCpuCoreHoursAvailable: number;
  estimatedRssByteHoursAvailable: number;
  instanceHours: number;
  instanceType: string;
  nodepool: string;
  resourceGroupName: string;
  subscriptionName: string;
  upperBoundCpuCoreHoursUsed: number;
  upperBoundRssByteHoursUsed: number;
};

export type AzureAKSClusterGroupFilters = {
  cloudId: string | null;
  clusterName: string | null;
  resourceGroupName: string | null;
  subscriptionName: string | null;
};

export type AzureAKSUsageDatum = {
  instanceHours: number;
  cost: number;
  upperBoundCpuCoreHoursUsed: number;
  estimatedCpuCoreHoursAvailable: number;
  upperBoundRssByteHoursUsed: number;
  estimatedRssByteHoursAvailable: number;
  timestamp: string;
};
