import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown, { Option } from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import {
  CloudCapability,
  DataSource,
  DataStatusType,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import IconExclamationDiamond from "../../../ui-lib/icons/IconExclamationDiamond";
import IconSnowflake from "../../../ui-lib/icons/IconSnowflake";
import copyText from "../copyText";
import DataStatusTooltip from "./DataStatusTooltip";

type Integration = {
  id: string;
  config: {
    accounts: {
      name: string;
      passwordPreview: string;
      role: string;
      username: string;
      warehouse: string;
    }[];
    orgName: string;
  };
  dataStatus: {
    [DataSource.BILLING]: {
      latestRefresh: string | null;
      latestUpstreamCheck: string | null;
      latestUpstreamTimestamp: string | null;
      performanceMode: DataStatusType;
    };
  };
  name: string;
  validations: { error: string; name: string; success: boolean }[];
};

interface Props {
  integration: Integration;
  isProcessing: boolean;
  onInteraction: (interaction: SnowflakeIntegrationCard.Interaction) => void;
}

export function SnowflakeIntegrationCard(props: Props): JSX.Element | null {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  const validation = props.integration.validations.find(
    (validation) => validation.name === CloudCapability.BILL_DATA_SNOWFLAKE
  );
  const error = validation?.error ? validation?.error : null;

  const options: Option[] = [];

  if (gatekeeper.canUpdateDataIntegration) {
    options.push(
      {
        label: copyText.actionMenuItemEditCloud,
        onClick: () =>
          props.onInteraction({
            type: SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      },
      {
        label: props.isProcessing ? (
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{copyText.actionMenuItemValidateCloud}</Text>
            <LoadingSpinner />
          </Flex>
        ) : (
          copyText.actionMenuItemValidateCloud
        ),
        locked: props.isProcessing,
        onClick: () =>
          props.onInteraction({
            type: SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED,
            integrationID: props.integration.id,
          }),
      }
    );
  }

  if (gatekeeper.canDeleteDataIntegration) {
    options.push({
      label: copyText.actionMenuItemDeleteCloud,
      onClick: () =>
        props.onInteraction({
          type: SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED,
          integrationID: props.integration.id,
        }),
    });
  }

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      height={360}
      justifyContent="space-between"
      marginBottom={theme.space_lg}
      marginRight={theme.space_lg}
      padding={theme.space_md}
      scrollable
      width={360}
    >
      <Box>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          marginBottom={theme.space_sm}
        >
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              backgroundColor="white"
              border={`1px solid ${theme.border_color}`}
              borderRadius="50%"
              height={48}
              justifyContent="center"
              marginRight={theme.space_sm}
              width={48}
            >
              <IconSnowflake size={36} />
            </Flex>
            <Box maxWidth={200}>
              <Text
                fontSize={theme.h4_fontSize}
                marginRight={theme.space_xxs}
                overflowWrap="break-word"
              >
                {props.integration.name}
              </Text>
            </Box>
            <DataStatusTooltip
              latestRefresh={props.integration.dataStatus.BILLING.latestRefresh}
              latestUpstream={
                props.integration.dataStatus.BILLING.latestUpstreamTimestamp
              }
            />
            {error && (
              <Box marginLeft={theme.space_xxs}>
                <Tooltip content={error} width="300px">
                  <IconExclamationDiamond size="13px" />
                </Tooltip>
              </Box>
            )}
          </Flex>
          <Dropdown options={options} placement="bottom-end">
            <Button iconEnd={<Icon icon={faEllipsisV} />} />
          </Dropdown>
        </Flex>
        <Box>
          <Box marginBottom={theme.space_md}>
            <Text bold>{copyText.snowflakeIntegrationCardOrgName}</Text>
            <Text>{props.integration.config.orgName}</Text>
          </Box>

          <Box marginBottom={theme.space_md}>
            <Text bold>
              {copyText.snowflakeIntegrationCardConnectedAccounts}
            </Text>
            <Text>{props.integration.config.accounts.length}</Text>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED =
  `SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED` as const;

SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED =
  `SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED =
  `SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionEditButtonClicked {
  type: typeof SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionRefreshButtonClicked {
  type: typeof SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionDeleteButtonClicked {
  type: typeof SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED;
  integrationID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SnowflakeIntegrationCard {
  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionDeleteButtonClicked;
}

export default SnowflakeIntegrationCard;
