import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSDatabaseUsageStruct = {
  productMemoryBytes: nullable(number()),
  usedMemoryBytes: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSDatabaseSpendSummaryStruct = {
  totalCost: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSDatabaseInstanceGroupStruct = {
  databaseType: nullable(string()),
  family: nullable(string()),
  familyType: nullable(string()),
  instanceIDCount: nullable(number()),
  instanceUnits: nullable(number()),
  lineItemUsageAccountId: nullable(string()),
  networkBytes: nullable(number()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
  usedDiskBytes: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSDatabaseInstanceStruct = {
  cpuUtilization: nullable(number()),
  databaseConnections: nullable(number()),
  databaseType: nullable(string()),
  family: nullable(string()),
  familyType: nullable(string()),
  instanceId: nullable(string()),
  instanceType: nullable(string()),
  lineItemUsageAccountId: nullable(string()),
  networkBytes: nullable(number()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  ramUtilization: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
  usedDiskBytes: nullable(number()),
};

export type AWSDatabaseInstance = NotNullable<
  Infer<typeof AWSDatabaseInstanceStruct>
>;

export type AWSDatabaseInstanceGroup = NotNullable<
  Infer<typeof AWSDatabaseInstanceGroupStruct>
>;

export type AWSDatabaseInstanceGroupFilters = {
  databaseType: string | null;
  family: string | null;
  familyType: string | null;
  lineItemUsageAccountId: string | null;
  region: string | null;
};

export type AWSDatabaseSpendSummaryEntity = {
  totalCost: number;
};

export type AWSDatabaseUsageDatum = NotNullable<
  Infer<typeof AWSDatabaseUsageStruct>
>;
