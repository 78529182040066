import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useMspStore } from "../../../lib/zustand";
import copyText from "../copyText";
import MspCostAlertViewContainer from "./MspCostAlertViewContainer";
import MspDataIntegrationViewContainer from "./MspDataIntegrationViewContainer";
import MspRecommendationViewContainer from "./MspRecommendationViewContainer";
import MspReportingContainer from "./MspReportingContainer";
import MspUserViewContainer from "./MspUserViewContainer";

export function MspDashboardPage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "reporting"),
  });

  //
  // State
  //

  const mspStore = useMspStore();

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  // parentTenantID will always be populated at this point.
  const parentTenantID = mspStore.selectedParentTenantID as string;

  const containers = [
    {
      component: <MspReportingContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelReporting,
      value: "reporting",
    },
    {
      component: <MspCostAlertViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelAnomalies,
      value: "anomalies",
    },
    {
      component: (
        <MspRecommendationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelSavings,
      value: "savings",
    },
    {
      component: (
        <MspDataIntegrationViewContainer parentTenantID={parentTenantID} />
      ),
      label: copyText.tabLabelClouds,
      value: "clouds",
    },
    {
      component: <MspUserViewContainer parentTenantID={parentTenantID} />,
      label: copyText.tabLabelUsers,
      value: "users",
    },
  ];

  return (
    <ErrorBoundary boundaryName="MspAdminPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default MspDashboardPage;
