import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { ScopedViewEntity } from "@ternary/api-lib/core/types";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import scopedViewKeys from "./queryKeys";

export default function useGetScopedViewsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<ScopedViewEntity[], CoreAPIClientError>
): UseQueryResult<ScopedViewEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryFn: () => client.getScopedViewsByTenantID(tenantID),
    queryKey: scopedViewKeys.all,
    ...options,
  });
}
