import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import IconExport from "@/ui-lib/icons/IconExport";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { RawData } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  CloudProviderType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/api-lib/ui-lib/charts/StackedBarChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import {
  JsonParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetSpendSummaries from "../../../../api/analytics/useGetSpendSummaries";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import TextInput from "../../../../ui-lib/components/TextInput";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import copyText from "../../copyText";
import useGetSnowflakeCreditSummary from "../hooks/useGetSnowflakeCreditSummary";
import useGetSnowflakeWarehouseSummary from "../hooks/useGetSnowflakeWarehouseSummary";
import SnowflakeWarehouseMeters from "./SnowflakeWarehouseMeters";
import SnowflakeWarehouseResourceTable from "./SnowflakeWarehouseResourceTable";
import SnowflakeWarehouseSubTable from "./SnowflakeWarehouseSubTable";

type Tags = {
  [key: string]: string;
};

type Warehouse = {
  id: number;
  cloudServicesCredits: number;
  computeCredits: number;
  name: string;
  queryCount: number;
  tags: Tags[];
  totalCreditsUsed: number;
  totalRunTime: number;
};

type WarehouseCSVData = {
  accountName: string;
  warehouseName: string;
  size: string;
  type: string;
  totalRunTime: number;
  computeCredits: number;
  cloudServicesCredits: number;
  totalCreditsUsed: string;
};

interface State {
  searchText: string;
  selectedWarehouseID: string;
  selectedWarehouse: Warehouse | undefined;
  showSubTable: boolean;
}

const initialState: State = {
  searchText: "",
  selectedWarehouseID: "",
  selectedWarehouse: undefined,
  showSubTable: false,
};

type Interaction = SnowflakeWarehouseResourceTable.Interaction;

export default function SnowflakeWarehouseContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const globalDate = useAvailableGlobalDate();
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const durationEnum = createEnumParam(Object.values(DurationType));

  const [searchParamState, setSearchParamState] = useQueryParams({
    date_range: withDefault(JsonParam, []),
    duration_type: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
    id: StringParam,
  });

  const dateRange = globalDate.date
    ? globalDate.date
    : getCubeDateRangeFromDurationType(searchParamState.duration_type);

  //
  // Queries
  //

  const creditSummary = useGetSnowflakeCreditSummary({
    dataSource: DataSource.SNOWFLAKE_WAREHOUSE_USAGE,
  });

  const [{ data: currentMTDCredit }, { data: lastMonthTotalCredit }] =
    creditSummary;

  const isLoadingCreditSummary = creditSummary.some(
    (summary) => summary.isFetching
  );

  const { data: integrations = [] } = useGetDataIntegrationsByTenantID(
    authenticatedUser.tenant.id
  );

  const snowflakeIDs = integrations.reduce((accum: string[], integration) => {
    if (integration.providerType === CloudProviderType.SNOWFLAKE) {
      accum.push(integration.id);
    }
    return accum;
  }, []);

  const snowflakeSpendSummary = useGetSpendSummaries(
    {
      dataSource: DataSource.BILLING,
      queryFilters: [
        {
          name: "cloudId",
          operator: Operator.EQUALS,
          values: snowflakeIDs,
        },
        {
          name: "skuDescription",
          operator: Operator.CONTAINS,
          values: ["compute", "cloud services"],
        },
      ],
    },
    { enabled: snowflakeIDs.length > 0 }
  );

  const { data: currentSnowflakeMtdSpend, isLoading: isLoadingSnowflakeSpend } =
    snowflakeSpendSummary[0];

  const {
    data: lastMonthSnowflakeSpend,
    isLoading: isLoadingLastMonthSnowflakeSpend,
  } = snowflakeSpendSummary[1];

  const {
    data: lastSnowflakeMtdSpend,
    isLoading: isLoadingSnowflakeMonthlySpend,
  } = snowflakeSpendSummary[2];

  const isLoadingSnowflakeSpendSummary =
    isLoadingSnowflakeSpend ||
    isLoadingSnowflakeMonthlySpend ||
    isLoadingLastMonthSnowflakeSpend;

  const { data: creditData = [], isFetching: isLoadingCreditData } =
    useGetRawData({
      dataSource: DataSource.SNOWFLAKE_WAREHOUSE_USAGE,
      dateRange,
      dimensions: ["warehouseSize"],
      granularity: TimeGranularity.DAY,
      measures: ["totalCreditsUsed"],
    });

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.SNOWFLAKE_WAREHOUSE_USAGE,
      dateRange,
      dimensions: [],
      granularity: TimeGranularity.DAY,
      measures: ["computeCreditsUsed"],
    });

  const { data: warehouseData = [], isFetching: isLoadingWarehouseData } =
    useGetSnowflakeWarehouseSummary({
      dateRange,
    });

  const mappedWarehouseData = warehouseData.map((warehouse) => ({
    ...warehouse,
    warehouse: {
      cloudServicesCredits: warehouse.cloudServicesCreditsUsed,
      computeCredits: warehouse.computeCreditsUsed,
      id: warehouse.warehouseID,
      name: warehouse.warehouseName,
      queryCount: warehouse.queryCount,
      tags: warehouse.tags !== "null" ? JSON.parse(warehouse.tags) : [],
      totalCreditsUsed: warehouse.totalCreditsUsed,
      totalRunTime: warehouse.queryElapsedTime,
    },
  }));

  const filteredTableData = mappedWarehouseData.filter((warehouse) => {
    if (state.searchText.length === 0) return true;

    const searchText = state.searchText.toLowerCase();

    return (
      warehouse.accountName.toLowerCase().includes(searchText) ||
      warehouse.warehouseName.toLowerCase().includes(searchText) ||
      warehouse.warehouseSize.toLowerCase().includes(searchText) ||
      warehouse.warehouseType.toLowerCase().includes(searchText)
    );
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case SnowflakeWarehouseResourceTable.INTERACTION_SELECT_WAREHOUSE_CLICKED: {
        mergeState({
          selectedWarehouse: interaction.selectedWarehouse,
          showSubTable: true,
        });
        return;
      }
    }
  }

  //
  // Render
  //

  const usageKey = {
    computeCreditsUsed: copyText.snowflakeWarehouseComputeCreditsUsedKey,
  };

  const mappedCreditData = creditData.reduce((accum: RawData[], credit) => {
    if (typeof credit === "object") {
      if (credit.warehouseSize === null) {
        accum.push({ ...credit, warehouseSize: "other" });
      } else {
        accum.push(credit);
      }
    }
    return accum;
  }, []);

  return (
    <Box paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={200}
        marginBottom={theme.space_lg}
        padding={theme.space_lg}
      >
        <SnowflakeWarehouseMeters
          currentMTDCredit={currentMTDCredit?.totalCreditsUsed ?? 0}
          isLoadingSpend={
            isLoadingCreditSummary || isLoadingSnowflakeSpendSummary
          }
          lastMonthSpend={lastMonthSnowflakeSpend?.grossCost ?? 0}
          lastMonthTotalCredit={lastMonthTotalCredit?.totalCreditsUsed ?? 0}
          lastMTDSpend={lastSnowflakeMtdSpend?.grossCost ?? 0}
          thisMTDSpend={currentSnowflakeMtdSpend?.grossCost ?? 0}
        />
      </Flex>
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={searchParamState.duration_type}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          onChangeDateRange={(durationType, dateRange) => {
            setSearchParamState({
              duration_type: durationType,
              date_range: [dateRange?.[0] ?? null, dateRange?.[1] ?? null],
            });
            return;
          }}
        />
      </Flex>

      <Flex height={500} marginBottom={theme.space_lg}>
        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flex={1}
          height="100%"
          marginRight={theme.space_lg}
          padding={theme.space_md}
          width="50%"
        >
          <Flex marginBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.snowflakeWarehouseAggregateCreditChartTitle}
            </Text>
          </Flex>
          <Box height={400} paddingVertical={theme.space_md}>
            <StackedBarChart
              data={mappedCreditData}
              disableDrilldown
              dimensions={[{ name: "warehouseSize" }]}
              limit={10}
              isLoading={isLoadingCreditData}
              measures={[{ name: "totalCreditsUsed", unit: UnitType.STANDARD }]}
              showLegend
              showTooltip
              timeSeriesGranularity={TimeGranularity.DAY}
              emptyPlaceholderText={
                copyText.snowflakeWarehouseAggregateChartNoData
              }
            />
          </Box>
        </Box>

        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flex={1}
          height="100%"
          padding={theme.space_md}
          width="50%"
        >
          <Flex marginBottom={theme.space_md}>
            <Text fontSize={theme.h3_fontSize}>
              {copyText.snowflakeWarehoueComputeCreditsChartTitle}
            </Text>
          </Flex>

          <Box height={400} paddingVertical={theme.space_md}>
            <AreaChart
              data={usageData}
              disableDrilldown
              hideTotal
              isLoading={isLoadingUsageData}
              measures={[
                {
                  name: "computeCreditsUsed",
                  unit: UnitType.STANDARD,
                },
              ]}
              limit={10}
              readableKeys={usageKey}
              showLegend
              showTooltip
              stacked
              timeSeriesGranularity={TimeGranularity.DAY}
            />
          </Box>
        </Box>
      </Flex>

      <Flex
        justifyContent="flex-end"
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box width={300}>
            <TextInput
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) =>
                mergeState({ searchText: e.target.value.toLowerCase() })
              }
            />
          </Box>
          <CSVLink
            data={flattenDataToCSV(mappedWarehouseData)}
            filename={`${copyText.snowflakeCSVExportTitle}-${formatDate(
              new Date(),
              "MM-dd-yyyy"
            )}`}
          >
            <Button
              iconStart={<IconExport />}
              primary
              size="small"
              marginLeft={theme.space_md}
            >
              {copyText.exportButtonLabel}
            </Button>
          </CSVLink>
        </Flex>
      </Flex>

      <Box>
        {state.showSubTable && state.selectedWarehouse !== undefined ? (
          <SnowflakeWarehouseSubTable
            warehouse={state.selectedWarehouse}
            onClose={() => mergeState({ showSubTable: false })}
          />
        ) : null}
        <SnowflakeWarehouseResourceTable
          isLoading={isLoadingWarehouseData}
          resources={filteredTableData}
          onInteraction={handleInteraction}
        />
      </Box>
    </Box>
  );
}

function flattenDataToCSV(data): WarehouseCSVData[] {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    accountName: datum.accountName,
    warehouseName: datum.warehouseName,
    size: datum.warehouseSize,
    type: datum.warehouseType,
    computeCredits: datum.computeCreditsUsed,
    cloudServicesCredits: datum.cloudServicesCreditsUsed,
    totalCreditsUsed: datum.totalCreditsUsed,
  }));
}
