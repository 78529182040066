import { Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { noop } from "lodash";
import React, { Fragment } from "react";

export interface Item {
  clickable?: boolean;
  label: string;
  onClick?: () => void;
}

interface Props {
  items: Item[];
}

const StyledText = styled(Text)<{ clickable: boolean }>((props) => ({
  whiteSpace: "nowrap",
  ...(props.clickable
    ? {
        color: props.theme.link_color,
        cursor: "pointer",
        textDecoration: "underline",
      }
    : {}),
}));

const separator = (theme: Theme) => (
  <Box marginHorizontal={theme.space_xs}>
    <FontAwesomeIcon
      color={theme.text_color_secondary}
      icon={faChevronRight}
      size="xs"
    />
  </Box>
);

export default function Breadcrumb({ items }: Props): JSX.Element {
  const theme = useTheme();
  const length = items.length;

  return (
    <Flex alignItems="center" overflowX="auto">
      {items.map((item, i) => (
        <Fragment key={i}>
          <StyledText
            bold={length === i + 1}
            clickable={Boolean(item.clickable)}
            color={
              length === i + 1 ? theme.text_color : theme.text_color_secondary
            }
            fontWeight={theme.fontWeight_regular}
            onClick={item.onClick ?? noop}
          >
            {item.label}
          </StyledText>
          {length === i + 1 ? null : separator(theme)}
        </Fragment>
      ))}
    </Flex>
  );
}
