import { useTheme } from "@emotion/react";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";

const UNICODE_BULLET = "\u2022";

type Integration = {
  apiTokenPreview: string;
  email: string;
  instanceURL: string;
  projectID: string;
};

interface Props {
  integration?: Integration;
  onInteraction: (interaction: JiraIntegrationCard.Interaction) => void;
}

function JiraIntegrationCard(props: Props) {
  const theme = useTheme();

  if (!props.integration) {
    return (
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height={300}
        marginHorizontal={theme.space_sm}
        marginVertical={theme.space_xs}
        padding={theme.space_md}
        width={300}
      >
        <Flex
          alignItems="center"
          direction="column"
          height="100%"
          justifyContent="space-between"
        >
          <Flex justifyContent="flex-start" width="100%">
            <Text appearance="h2" marginRight={theme.space_xs}>
              {copyText.jiraIntegrationTitle}
            </Text>
          </Flex>
          <Flex>
            <Text align="center">
              {copyText.jiraIntegrationNotConfiguredMessage}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end" width="100%">
            <Button
              secondary
              width={140}
              onClick={() =>
                props.onInteraction({
                  type: JiraIntegrationCard.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED,
                })
              }
            >
              {copyText.configureButtonLabel}
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }

  const options = [
    {
      label: copyText.optionLabelUpdateIntegration,
      onClick: () =>
        props.onInteraction({
          type: JiraIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED,
        }),
    },
    {
      label: copyText.optionLabelDeleteIntegration,
      onClick: () =>
        props.onInteraction({
          type: JiraIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED,
        }),
    },
  ];

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={300}
      marginHorizontal={theme.space_sm}
      marginVertical={theme.space_xs}
      padding={theme.space_md}
      width={300}
    >
      <Flex height="100%" direction="column" width="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex>
            <Text appearance="h2" marginRight={theme.space_xs}>
              {copyText.jiraIntegrationTitle}
            </Text>
          </Flex>
          <Dropdown options={options} placement="bottom-end">
            <Button
              iconStart={<Icon icon={faEllipsisVertical} />}
              size="small"
            />
          </Dropdown>
        </Flex>
        <Box>
          <Text bold>{copyText.jiraIntegrationInstanceUrlLabel}</Text>
          <Text marginBottom={theme.space_md}>
            {props.integration.instanceURL}
          </Text>
          <Text bold>{copyText.jiraIntegrationProjectIDLabel}</Text>
          <Text marginBottom={theme.space_md}>
            {props.integration.projectID}
          </Text>
          <Text bold>{copyText.jiraIntegrationEmailLabel}</Text>
          <Text marginBottom={theme.space_md}>{props.integration.email}</Text>
          <Text bold>{copyText.jiraIntegrationApiTokenLabel}</Text>
          <Text marginBottom={theme.space_md}>
            {formatSecretPreview(props.integration.apiTokenPreview)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

function formatSecretPreview(text: string): string {
  const [start, _, end] = text.split(":");

  return `${start}${UNICODE_BULLET.repeat(32)}${end}`;
}

JiraIntegrationCard.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED =
  "JiraIntegrationCard.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED" as const;

JiraIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED =
  "JiraIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED" as const;

JiraIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED =
  "JiraIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED" as const;

type InteractionConfigureIntegrationButtonClicked = {
  type: typeof JiraIntegrationCard.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED;
};

type InteractionDeleteIntegrationButtonClicked = {
  type: typeof JiraIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED;
};

type InteractionUpdateIntegrationButtonClicked = {
  type: typeof JiraIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace JiraIntegrationCard {
  export type Interaction =
    | InteractionConfigureIntegrationButtonClicked
    | InteractionDeleteIntegrationButtonClicked
    | InteractionUpdateIntegrationButtonClicked;
}

export default JiraIntegrationCard;
