import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { noop } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useRefFn from "../../hooks/useRefFn";

type Placement = "bottom" | "bottom-end" | "bottom-start";

const DropdownRoot = styled("div")<{ placement?: Placement }>(({
  placement,
}) => {
  return {
    display: "flex",
    justifyContent: (() => {
      switch (placement) {
        case "bottom-end":
          return "flex-end";
        case "bottom-start":
          return "flex-start";
        default:
          return "center";
      }
    })(),
    position: "relative",
  };
});

const DropdownContent = styled("div")<{
  height?: number | string;
  maxHeight?: number;
  width?: number | string;
}>(({ height, maxHeight, theme, width }) => ({
  backgroundColor: theme.elevated_background_color,
  borderRadius: theme.borderRadius_2,
  boxShadow: `0 4px 8px ${theme.box_shadow}`,
  height: height ?? undefined,
  marginTop: theme.space_xxl,
  maxHeight: maxHeight ?? 250,
  minWidth: 150,
  overflowY: "auto",
  padding: `${theme.space_xs} ${theme.space_xxs}`,
  position: "absolute",
  textAlign: "left",
  width: width ?? undefined,
  zIndex: theme.zIndex_1600,
}));

type Props = React.PropsWithChildren<{
  button?: JSX.Element;
  disabled?: boolean;
  height?: number | string;
  label?: string;
  maxHeight?: number;
  placement?: Placement;
  width?: string | number;
  onClose?: () => void;
}>;

export default function DropdownButton(props: Props): JSX.Element {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const onClose = useRefFn(props.onClose ?? noop);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && ref.current.contains(event.target as Node)) return;
    setIsOpen(false);
    onClose();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const handleClickButton = () => {
    if (isOpen) onClose();
    setIsOpen(!isOpen);
  };

  const contentProps = {
    height: props.height,
    maxHeight: props.maxHeight,
    width: props.width,
  };

  const button = props.button ?? (
    <Button
      iconEnd={<Icon icon={faChevronDown} />}
      marginRight={theme.space_sm}
      secondary
      size="small"
    >
      {props.label}
    </Button>
  );

  return (
    <DropdownRoot placement={props.placement} ref={ref}>
      {React.cloneElement(button, {
        onClick: handleClickButton,
        disabled: props.disabled,
      })}
      {isOpen && !props.disabled && (
        <DropdownContent {...contentProps}>{props.children}</DropdownContent>
      )}
    </DropdownRoot>
  );
}
