import { useEventReporter } from "@/context/EventReporterProvider";
import { EventReporter } from "@ternary/api-lib/telemetry";
import { add, differenceInDays, Duration, format, getDay, sub } from "date-fns";
import { useMemo } from "react";
import UError from "unilib-error";
import copyText from "../copyText";
import {
  DayOfWeek,
  DayOfWeekMap,
  EditedFiscalCalendar,
  FiscalMonth,
  FiscalQuarter,
  FiscalYear,
  QuarterPattern,
} from "../types";

type PeriodsKeyedByYear = {
  [year: string]: Periods;
};

type Periods = {
  // ["M01-Q1-Y2022"]: "2022-01-31",
  // ["M02-Q1-Y2022"]: "2022-03-07",
  // ...

  [key: string]: string;
};

type FiscalYearMap = {
  [year: string]: FiscalYear;
};

export type FiscalYearsData = {
  yearKeys: string[];
  yearMap: FiscalYearMap;
};

const FAILED_PARSING_FISCAL_YEAR = "FAILED_PARSING_FISCAL_YEAR";

export default function useFiscalYearsData(
  calendar: EditedFiscalCalendar | null
): FiscalYearsData {
  const eventReporter = useEventReporter();

  return useMemo(() => {
    return createFiscalYearsData({ calendar, eventReporter });
  }, [calendar]);
}

type CreateFiscalYearsParams = {
  calendar: EditedFiscalCalendar | null;
  eventReporter?: EventReporter;
};

export function createFiscalYearsData({
  calendar,
  eventReporter,
}: CreateFiscalYearsParams): FiscalYearsData {
  try {
    if (calendar === null) {
      return {
        yearKeys: [],
        yearMap: {},
      };
    }

    const periodsKeyedByYear: PeriodsKeyedByYear = Object.entries(
      calendar.periods
    ).reduce((accum: PeriodsKeyedByYear, [period, dateString]) => {
      const thisYear = getMQY(period).year;
      const prevPeriodsThisAtYear = accum[thisYear] ?? {};

      return {
        ...accum,
        [thisYear]: {
          ...prevPeriodsThisAtYear,
          [period]: dateString,
        },
      };
    }, {});

    const yearKeys = Object.keys(periodsKeyedByYear).sort(
      (a, b) => Number(a) - Number(b)
    );

    const yearMap = yearKeys.reduceRight(
      (accum: FiscalYearMap, yearKey, yearIndex) => {
        let endDate: string;

        if (yearIndex === yearKeys.length - 1) {
          endDate = calendar.endDate;
        } else {
          const nextYearKey = yearKeys[yearIndex + 1];
          const nextYear = accum[nextYearKey];

          endDate = dayBefore(nextYear.startDate);
        }

        return {
          ...accum,
          [yearKey]: createYear(periodsKeyedByYear[yearKey], endDate),
        };
      },
      {}
    );

    return {
      yearKeys,
      yearMap,
    };
  } catch (error) {
    if (error instanceof UError) {
      const contextWithCalendar = {
        ...(error.context ?? {}),
        calendar,
      };
      error.context = contextWithCalendar;
      eventReporter?.reportError(error);
    }

    return {
      yearKeys: [],
      yearMap: {},
    };
  }
}

//
// Fiscal Year
//

function createYear(periods: Periods, endDate: string): FiscalYear {
  const Q4Periods = getPeriodsSubsetFromMatch(periods, /M\d{2}-Q4-Y\d{4}/);
  const Q4 = createQuarter({
    periods: Q4Periods,
    monthNumbers: ["10", "11", "12"],
    endDate: endDate,
  });

  const Q3Periods = getPeriodsSubsetFromMatch(periods, /M\d{2}-Q3-Y\d{4}/);
  const Q3 = createQuarter({
    periods: Q3Periods,
    monthNumbers: ["07", "08", "09"],
    endDate: dayBefore(Q4.startDate),
  });

  const Q2Periods = getPeriodsSubsetFromMatch(periods, /M\d{2}-Q2-Y\d{4}/);
  const Q2 = createQuarter({
    periods: Q2Periods,
    monthNumbers: ["04", "05", "06"],
    endDate: dayBefore(Q3.startDate),
  });

  const Q1Periods = getPeriodsSubsetFromMatch(periods, /M\d{2}-Q1-Y\d{4}/);
  const Q1 = createQuarter({
    periods: Q1Periods,
    monthNumbers: ["01", "02", "03"],
    endDate: dayBefore(Q2.startDate),
  });

  const earliestPeriodKey = getEarliestPeriodKey(periods);
  const startDate = periods[earliestPeriodKey];

  const startDayOfWeek = getDayOfWeek(startDate);

  const [weekCount] = getNumberOfWeeks(startDate, endDate);

  let quarterPattern: QuarterPattern;

  if (
    Q1.quarterPattern === Q2.quarterPattern &&
    Q2.quarterPattern === Q3.quarterPattern &&
    (Q3.quarterPattern === Q4.quarterPattern ||
      Q4.quarterPattern === QuarterPattern.OTHER)
  ) {
    quarterPattern = Q1.quarterPattern;
  } else {
    quarterPattern = QuarterPattern.OTHER;
  }

  setWeekNumbers([Q1, Q2, Q3, Q4]);

  return {
    endDate,
    isExactWeeks: [Q1, Q2, Q3, Q4].every((Q) => Q.isExactWeeks),
    Q1,
    Q2,
    Q3,
    Q4,
    quarterPattern,
    startDate,
    startDayOfWeek,
    weekCount,
    yearNumber: getMQY(earliestPeriodKey).year,
  };
}

//
// Fiscal Quarter
//

type CreateQuarterParams = {
  periods: Periods;
  monthNumbers: string[];
  endDate: string;
};

function createQuarter({
  periods,
  monthNumbers,
  endDate,
}: CreateQuarterParams): FiscalQuarter {
  const earliestPeriodKey = getEarliestPeriodKey(periods);
  const startDate = periods[earliestPeriodKey];

  const M3 = createMonth({
    startDate: getPeriodValueAtMonth(periods, monthNumbers[2]),
    endDate: endDate,
    monthNumber: monthNumbers[2],
  });

  const M2 = createMonth({
    startDate: getPeriodValueAtMonth(periods, monthNumbers[1]),
    endDate: dayBefore(M3.startDate),
    monthNumber: monthNumbers[1],
  });

  const M1 = createMonth({
    startDate: getPeriodValueAtMonth(periods, monthNumbers[0]),
    endDate: dayBefore(M2.startDate),
    monthNumber: monthNumbers[0],
  });

  const [weekCount] = getNumberOfWeeks(startDate, endDate);

  let quarterPattern: QuarterPattern;

  const generatedQuarterPattern = `${M1.weekCount}-${M2.weekCount}-${M3.weekCount}`;
  switch (generatedQuarterPattern) {
    case QuarterPattern.FIVE_FOUR_FOUR:
      quarterPattern = QuarterPattern.FIVE_FOUR_FOUR;
      break;
    case QuarterPattern.FOUR_FIVE_FOUR:
      quarterPattern = QuarterPattern.FOUR_FIVE_FOUR;
      break;
    case QuarterPattern.FOUR_FOUR_FIVE:
      quarterPattern = QuarterPattern.FOUR_FOUR_FIVE;
      break;
    default:
      quarterPattern = QuarterPattern.OTHER;
      break;
  }

  return {
    endDate,
    firstWeekNumber: -1,
    isExactWeeks: [M1, M2, M3].every((M) => M.isExactWeeks),
    lastWeekNumber: -1,
    months: [M1, M2, M3],
    quarterNumber: getMQY(earliestPeriodKey).quarter,
    quarterPattern,
    startDate,
    weekCount,
  };
}

//
// Fiscal Month
//

type CreateMonthParams = {
  startDate: string;
  endDate: string;
  monthNumber: string;
};

function createMonth({
  startDate,
  endDate,
  monthNumber,
}: CreateMonthParams): FiscalMonth {
  const [weekCount, isExactWeeks] = getNumberOfWeeks(startDate, endDate);

  return {
    endDate,
    firstWeekNumber: -1,
    isExactWeeks,
    lastWeekNumber: -1,
    monthNumber,
    startDate,
    weekCount,
  };
}

//
// Helpers
//

type FourQuarters = [
  FiscalQuarter,
  FiscalQuarter,
  FiscalQuarter,
  FiscalQuarter,
];

function setWeekNumbers(quarters: FourQuarters) {
  let prevQuarterEnd = 0;
  for (const quarter of quarters) {
    // Set week numbers for each quarter

    quarter.firstWeekNumber = prevQuarterEnd + 1;
    quarter.lastWeekNumber = quarter.firstWeekNumber + (quarter.weekCount - 1);

    let prevMonthEnd = prevQuarterEnd;
    for (const month of quarter.months) {
      // Set week numbers for each month in the quarter

      month.firstWeekNumber = prevMonthEnd + 1;
      month.lastWeekNumber = month.firstWeekNumber + (month.weekCount - 1);

      prevMonthEnd = month.lastWeekNumber;
    }

    prevQuarterEnd = quarter.lastWeekNumber;
  }
}

function getPeriodsSubsetFromMatch(periods: Periods, exp: RegExp) {
  return Object.keys(periods).reduce((accum: Periods, periodKey) => {
    if (!periodKey.match(exp)) return accum;

    return { ...accum, [periodKey]: periods[periodKey] };
  }, {});
}

function getPeriodValueAtMonth(periods: Periods, month: string) {
  const key = Object.keys(periods).find((key) => key.includes(`M${month}-`));
  if (!key) {
    throw new UError(FAILED_PARSING_FISCAL_YEAR, {
      context: {
        cause: "month number not in periods",
        monthNumber: month,
        periods,
      },
    });
  }
  return periods[key];
}

function getEarliestPeriodKey(periods: Periods) {
  if (Object.keys(periods).length === 0) {
    throw new UError(FAILED_PARSING_FISCAL_YEAR, {
      context: {
        cause: "empty periods object",
      },
    });
  }

  return Object.keys(periods).reduce((lowestKey, currentKey) => {
    const lowestMQY = getMQY(lowestKey);
    const currentMQY = getMQY(currentKey);

    if (currentMQY.year < lowestMQY.year) return currentKey;
    if (currentMQY.quarter < lowestMQY.quarter) return currentKey;
    if (currentMQY.month < lowestMQY.month) return currentKey;

    return lowestKey;
  });
}

function getNumberOfWeeks(
  firstDay: string,
  lastDay: string
): [number, boolean] {
  const firstDate = stringToDate(firstDay);
  const lastDate = add(stringToDate(lastDay), { days: 1 });
  const numberOfDays = differenceInDays(lastDate, firstDate);

  return [Math.abs(Math.floor(numberOfDays / 7)), numberOfDays % 7 === 0];
}

function getMQY(periodKey: string) {
  const match = periodKey.match(/M(\d{2})-Q(\d{1})-Y(\d{4})/);

  if (!match) {
    throw new UError(FAILED_PARSING_FISCAL_YEAR, {
      context: {
        cause: "invalid key",
        key: periodKey,
      },
    });
  }

  const [month, quarter, year] = match.slice(1);

  return { month, quarter, year };
}

function _add(dateString: string, duration: Duration) {
  return dateToString(add(stringToDate(dateString), duration));
}

function dateToString(date: Date) {
  return format(date, "yyyy-MM-dd");
}

function dayBefore(dateString: string) {
  return dateToString(sub(stringToDate(dateString), { days: 1 }));
}

function dayAfter(dateString: string) {
  return dateToString(add(stringToDate(dateString), { days: 1 }));
}

function diffDays(a: string, b: string) {
  return differenceInDays(stringToDate(a), stringToDate(b));
}

function getDayOfWeek(dateString: string) {
  const dayOfWeekIndex = getDay(stringToDate(dateString));
  return DayOfWeekMap[dayOfWeekIndex];
}

function getDayOfWeekCopyText(dayOfWeek: DayOfWeek) {
  return copyText[`fiscalCalendarDOW_${dayOfWeek}`];
}

function getRelativeDayOfWeek(dayOfWeek: DayOfWeek, diff: number) {
  if (diff < 0) {
    diff %= 7;
    diff += 7;
  }

  const index = DayOfWeekMap.indexOf(dayOfWeek);
  return DayOfWeekMap[(index + diff) % 7];
}

function stringToDate(dateString: string) {
  const [year, month, date] = dateString.split("-");
  return new Date(Number(year), Number(month) - 1, Number(date));
}

function _sub(dateString: string, duration: Duration) {
  return dateToString(sub(stringToDate(dateString), duration));
}

export const fiscalDateFns = {
  add: _add,
  dateToString,
  dayAfter,
  dayBefore,
  diffDays,
  getDayOfWeek,
  getDayOfWeekCopyText,
  getRelativeDayOfWeek,
  stringToDate,
  sub: _sub,
};
