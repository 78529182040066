import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useEventReporter } from "@/context/EventReporterProvider";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect } from "react";
import copyText from "../common.copyText";

interface Props {
  error?: CoreAPIClientError;
  reason: string;
}

export default function ErrorPage(props: Props): JSX.Element {
  const eventReporter = useEventReporter();
  const theme = useTheme();

  useEffect(() => {
    eventReporter.reportMessage("ERROR_INITIALIZING_APP", {
      context: { error: props.error, reason: props.reason },
      tags: {
        ignore:
          props.reason !== "UNEXPECTED" &&
          props.reason !== "NO_ACCESS_TO_TENANTS",
      },
    });
  }, []);

  return (
    <Flex
      alignItems="center"
      height="100vh"
      justifyContent="center"
      width="100vw"
    >
      <Flex alignItems="center" direction="column" width={480}>
        <Box marginBottom={theme.space_xxl} width={240}>
          <img alt="ternary" src="/assets/img/logo-standard.svg" />
        </Box>
        <Text appearance="h3" marginBottom={theme.space_md}>
          {copyText[`REASON_${props.reason}_title`]}
        </Text>
        <Text align="center">{copyText[`REASON_${props.reason}_message`]}</Text>
      </Flex>
    </Flex>
  );
}
