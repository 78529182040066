import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import SimpleChartTooltip from "@ternary/api-lib/ui-lib/charts/SimpleChartTooltip";
import { formatMeasureValueWithUnit } from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import {
  barStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/web-ui-lib/charts/styles";
import { groupBy, uniq } from "lodash";
import React, { useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";
import {
  AWSAllocationChartDatum,
  AWSCommitmentAllocationDatum,
} from "../types";

type Props = {
  data: AWSCommitmentAllocationDatum[];
  isLoading: boolean;
};

export default function AWSAllocationChart(props: Props) {
  const theme = useTheme();

  const customColors = {
    effectiveCost: theme.aws_cud_color_covered,
    netCost: theme.aws_cud_color_on_demand,
  };

  const formatYAxisValue = (value: number) =>
    formatMeasureValueWithUnit({
      unit: UnitType.CURRENCY,
      value: value,
    });

  const chartData = useMemo(() => getChartData(props.data), [props.data]);

  if (props.isLoading || props.data.length === 0) {
    return (
      <Flex justifyContent="center">
        <Box height={440} maxWidth={1000}>
          <EmptyPlaceholder
            loading={props.isLoading}
            icon={faChartBar}
            skeletonVariant="cartesian"
            text={copyText.chartEmptyPlaceholderText}
          />
        </Box>
      </Flex>
    );
  }

  return (
    <StyledBox>
      <ResponsiveContainer debounce={1} height="100%" width="100%">
        <ComposedChart data={chartData} margin={{ left: 25, right: 25 }}>
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />

          <XAxis
            {...xAxisStyleProps}
            dataKey="accountID"
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
          />

          <YAxis
            {...yAxisStyleProps}
            type="number"
            domain={[0, "auto"]}
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.chart_axis_text,
              fontWeight: 100,
              fontSize: "0.8rem",
            }}
            tickCount={8}
            tickFormatter={formatYAxisValue}
          />

          <Tooltip
            content={(tooltipProps) => (
              <SimpleChartTooltip
                customColorsKeyedByGrouping={customColors}
                entry={tooltipProps.payload?.[0]?.payload}
                excludedGroupings={[]}
                hideTotal
                label={tooltipProps.label}
                reverseSortedGroupings={["effectiveCost", "netCost"]}
                formatter={formatYAxisValue}
              />
            )}
            wrapperStyle={{ outline: "none" }}
          />

          <Legend iconType="square" verticalAlign="bottom" />

          <Bar
            {...barStyleProps}
            barSize={20}
            dataKey="effectiveCost"
            fill={customColors.effectiveCost}
            fillOpacity={theme.chart_fill_opacity}
          />

          <Bar
            {...barStyleProps}
            barSize={20}
            dataKey="netCost"
            fill={customColors.netCost}
            fillOpacity={theme.chart_fill_opacity}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBox>
  );
}

function getChartData(
  allocationData: AWSCommitmentAllocationDatum[]
): AWSAllocationChartDatum[] {
  const accountIDs = uniq(
    allocationData.map((datum) => datum.lineItemUsageAccountId)
  );

  const groupedByAccountID = groupBy(allocationData, "lineItemUsageAccountId");

  return accountIDs.map((accountID) => {
    const chartDatum: AWSAllocationChartDatum = {
      accountID: accountID.length > 0 ? accountID : "All Accounts",
      netCost: 0,
      effectiveCost: 0,
    };

    const group = groupedByAccountID[accountID];

    group.forEach((datum) => {
      chartDatum.netCost += datum.netEffectiveCost;
      chartDatum.effectiveCost += datum.effectiveCost;
    });

    return chartDatum;
  });
}

const StyledBox = styled(Box)`
  height: 100%;

  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  .recharts-text.recharts-label {
    color: ${(props) => props.theme.text_color} !important;
    font-size: ${({ theme }) => theme.fontSize_ui};
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;
