import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPComputeEngine(props: IconProps): JSX.Element {
  const parentViewboxSize = 24;
  const addedSize = 8;
  const offset = -addedSize / 2;

  return (
    <Icon {...props}>
      <svg
        width={parentViewboxSize + addedSize}
        height={parentViewboxSize + addedSize}
        x={offset}
        y={offset}
        viewBox="-19.20015 -28.483 166.4013 170.898"
      >
        <g transform="translate(0 -7.034)">
          <linearGradient
            y2="120.789"
            x2="64"
            y1="7.034"
            x1="64"
            gradientUnits="userSpaceOnUse"
            id="a"
          >
            <stop offset="0" stopColor="#4387fd" />
            <stop offset="1" stopColor="#4683ea" />
          </linearGradient>
          <path
            d="M27.79 115.217L1.54 69.749a11.499 11.499 0 010-11.499l26.25-45.467a11.5 11.5 0 019.96-5.75h52.5a11.5 11.5 0 019.959 5.75l26.25 45.467a11.499 11.499 0 010 11.5l-26.25 45.466a11.5 11.5 0 01-9.959 5.75h-52.5a11.499 11.499 0 01-9.96-5.75z"
            fill="url(#a)"
          />
        </g>
        <g transform="translate(0 -7.034)">
          <defs>
            <path
              d="M27.791 115.217L1.541 69.749a11.499 11.499 0 010-11.499l26.25-45.467a11.499 11.499 0 019.959-5.75h52.5a11.5 11.5 0 019.96 5.75l26.25 45.467a11.499 11.499 0 010 11.5l-26.25 45.466a11.5 11.5 0 01-9.96 5.75h-52.5a11.499 11.499 0 01-9.959-5.75z"
              id="b"
            />
          </defs>
          <clipPath id="c">
            <use height="100%" width="100%" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#c)"
            opacity=".07"
            d="M72.531 46.25l-6.75-6.75-3.125 6.687-6.671-6.671-1.641 8.088-5.938 1.02-1.232 5.282-7.683 1.835 6.884 6.884-6.81 3.033 6.81 6.81-6.854 2.928 46.682 46.682 11.464-.037 21.552-38.926L75.635 39.53z"
          />
        </g>
        <g fill="#fff">
          <path d="M69.831 50.062H58.422c-.668 0-1.215.547-1.215 1.214l.001 11.407a1.22 1.22 0 001.214 1.215H69.83a1.22 1.22 0 001.215-1.215V51.276c0-.667-.546-1.214-1.213-1.214" />
          <path d="M87.89 49.142c.6 0 1.09-.492 1.09-1.09v-1.776c0-.598-.49-1.09-1.09-1.09h-5.55V40a1.22 1.22 0 00-1.213-1.213h-5.209l.001-5.57c0-.597-.49-1.089-1.089-1.089l-1.777.001c-.6 0-1.09.491-1.09 1.089l.001 5.568h-5.86v-5.57c0-.596-.49-1.088-1.088-1.088l-1.779.001c-.599 0-1.089.491-1.089 1.089l.001 5.568-5.86.001v-5.571c0-.596-.49-1.088-1.088-1.088h-1.778c-.598 0-1.09.492-1.09 1.09l.002 5.569h-5.207A1.22 1.22 0 0045.912 40v5.187h-5.549c-.597 0-1.088.492-1.088 1.09l.001 1.778c0 .598.49 1.088 1.088 1.088h5.548l.002 5.86h-5.55c-.598 0-1.089.49-1.089 1.088l.001 1.777c0 .6.49 1.09 1.088 1.09h5.55v5.86l-5.55-.001c-.598 0-1.089.491-1.089 1.089l.001 1.777c0 .6.49 1.089 1.088 1.089h5.55v5.19c0 .665.547 1.213 1.213 1.213h5.208v5.567c0 .6.49 1.09 1.089 1.09H55.2c.598 0 1.088-.49 1.088-1.09l.001-5.567h5.859l.001 5.567c0 .6.49 1.09 1.09 1.09h1.776c.598 0 1.088-.49 1.088-1.09l.001-5.567h5.859l.001 5.567c0 .6.49 1.09 1.09 1.09h1.775c.599 0 1.09-.49 1.09-1.09v-5.567h5.206a1.22 1.22 0 001.214-1.214l.001-5.189 5.55-.001c.599 0 1.089-.489 1.089-1.088v-1.775c0-.6-.49-1.09-1.09-1.09h-5.548v-5.86l5.549-.002c.6 0 1.09-.488 1.09-1.088v-1.775c0-.6-.49-1.09-1.09-1.09h-5.55v-5.86h5.55zm-9.647 21.955H50.009V42.862l28.236-.001z" />
        </g>
      </svg>
    </Icon>
  );
}
