export const awsOpenSearchMeasures = {
  cost: "cost",
  dataTransferCost: "dataTransferCost",
  dataTransferAwsInboundCost: "dataTransferAwsInboundCost",
  dataTransferAwsOutboundCost: "dataTransferAwsOutboundCost",
  dataTransferInterRegionInboundCost: "dataTransferInterRegionInboundCost",
  dataTransferInterRegionOutboundCost: "dataTransferInterRegionOutboundCost",
  serverlessManagedStorageCost: "serverlessManagedStorageCost",
  blockStorageCost: "blockStorageCost",
  instanceCost: "instanceCost",
  serverlessComputeCost: "serverlessComputeCost",
  dataTransfer: "dataTransfer",
  dataTransferAwsInbound: "dataTransferAwsInbound",
  dataTransferAwsOutbound: "dataTransferAwsOutbound",
  dataTransferInterRegionInbound: "dataTransferInterRegionInbound",
  dataTransferInterRegionOutbound: "dataTransferInterRegionOutbound",
  nodeUsage: "nodeUsage",
} as const;

export const awsOpenSearchDimensions = {
  payerAccountId: "payerAccountId",
  usageAccountId: "usageAccountId",
  domainArn: "domainArn",
  domainId: "domainId",
  instanceFamily: "instanceFamily",
  instanceType: "instanceType",
  instanceMemory: "instanceMemory",
  instanceVcpu: "instanceVcpu",
  region: "region",
} as const;

export type AWSOpenSearchSpendSummaryEntity = {
  totalCost: number;
};

export type AWSOpenSearchInstanceGroup = {
  blockStorageCost: number;
  dataTransferAwsInbound: number;
  dataTransferAwsOutbound: number;
  dataTransferCost: number;
  dataTransferInterRegionInbound: number;
  dataTransferInterRegionOutbound: number;
  domainId: string | null;
  instanceCost: number;
  region: string | null;
  usageAccountId: string | null;
};

export type AWSOpenSearchInstance = {
  domainId: string | null;
  instanceCost: number;
  instanceMemory: string | null;
  instanceType: string | null;
  instanceVcpu: string;
  nodeUsage: number;
  usageAccountId: string | null;
};
