import useGatekeeper from "@/hooks/useGatekeeper";
import Modal from "@/ui-lib/components/Modal";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faLock, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import { Column } from "react-table";
import copyText from "../copyText";
import { MAX_ALLOWED_WIDGETS } from "./DashboardViewContainer";

type Dashboard = {
  id: string;
  name: string;
  createdAt: string;
  createdByEmail: string | null;
  createdByID: string;
  widgetSpecs: WidgetSpec[];
  updatedAt: string | null;
};

// only care about spec count
type WidgetSpec = {
  type: string;
};

interface Props {
  dashboards: Dashboard[];
  isLoading: boolean;
  saveAsCopy?: boolean;
  title?: string;
  onClose?: () => void;
  onInteraction: (interaction: DashboardListModal.Interaction) => void;
}

interface TableData {
  id: string;
  createdBy: string;
  createdByID: string;
  name: string;
  timeLastModified: string;
  widgetSpecs: WidgetSpec[];
}

interface State {
  createdByFilters: string[];
  searchText: string;
}

const initialState = {
  createdByFilters: [],
  searchText: "",
};

export function DashboardListModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const mergeState = getMergeState(setState);

  if (!gatekeeper.canListDashboards) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function handleClickCreateDashboard() {
    props.onInteraction({
      type: DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED,
    });
  }

  function handleClickRow(dashboardID: string): void {
    props.onInteraction({
      type: DashboardListModal.INTERACTION_ROW_CLICKED,
      dashboardID,
    });
  }

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "name",
        Header: copyText.tableHeaderName,
        width: 200,
      },
      {
        accessor: "createdBy",
        Header: copyText.tableHeaderCreatedBy,
        width: 200,
      },
      {
        accessor: "timeLastModified",
        Cell: ({ value }) => (
          <>{formatDate(new Date(value ? value : 0), "MM/dd/yyyy hh:mm a")}</>
        ),
        Header: copyText.tableHeaderTimeLastModified,
        width: 200,
      },
      {
        accessor: "widgetSpecs",
        Header: copyText.tableHeaderNumberOfReports,
        Cell: ({ value }) => (
          <>{`${value.length} ${
            value.length >= MAX_ALLOWED_WIDGETS
              ? copyText.maximumNumberOfReports
              : ""
          }`}</>
        ),
        sortType: (rowA, rowB) => {
          if (
            rowA.values?.widgetSpecs?.length > rowB.values?.widgetSpecs?.length
          ) {
            return 1;
          }
          if (
            rowA.values?.widgetSpecs?.length < rowB.values?.widgetSpecs?.length
          ) {
            return -1;
          } else {
            return 0;
          }
        },
        width: 200,
      },
    ],
    []
  );

  const data = useMemo(() => {
    let filteredData = props.dashboards
      .map((dashboard) => ({
        id: dashboard.id,
        name: dashboard.name,
        widgetSpecs: dashboard.widgetSpecs,
        timeLastModified: dashboard.updatedAt ?? dashboard.createdAt,
        createdBy: dashboard.createdByEmail ?? "--",
        createdByID: dashboard.createdByID,
      }))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.createdByFilters.length > 0) {
      filteredData = filteredData.filter((dashboard) => {
        return state.createdByFilters.includes(dashboard.createdByID ?? "");
      });
    }

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((dashboard) => {
        return dashboard.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }

    return filteredData;
  }, [props.dashboards, state.createdByFilters, state.searchText]);

  const createdByFilterOptions: Option[] = uniqBy(
    props.dashboards,
    "createdByEmail"
  )
    .reduce((accum: Option[], dashboard: Dashboard) => {
      if (!dashboard.createdByEmail) return accum;

      return [
        ...accum,
        {
          label: dashboard.createdByEmail,
          value: dashboard.createdByID,
        },
      ];
    }, [])
    .sort((a, b) =>
      a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
          ? 1
          : 0
    );

  return (
    <Modal isOpen={true} showCloseButton onClose={props.onClose} minWidth={750}>
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <Flex
          justifyContent="space-between"
          marginBottom={theme.space_md}
          marginTop={-10}
        >
          <Flex>
            <Box marginRight={theme.space_md} width="12rem">
              <TextInput
                iconStart={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchReportsDashboardsPlaceholder}
                onChange={(event) =>
                  mergeState({ searchText: event.target.value })
                }
              />
            </Box>
            <SelectCheckbox
              options={createdByFilterOptions}
              placeholder={copyText.filterReportsDashboardsPlaceholder}
              selectedValues={state.createdByFilters}
              width="12rem"
              onChange={(event) => mergeState({ createdByFilters: event })}
            />
          </Flex>
          <Button
            iconStart={<Icon icon={faPlus} />}
            locked={!gatekeeper.canCreateDashboards}
            marginLeft={theme.space_md}
            primary
            onClick={handleClickCreateDashboard}
          >
            {copyText.createDashboardButtonLabel}
          </Button>
        </Flex>
        <TableLegacy
          clickableRows
          columns={columns}
          compact
          data={data}
          initialState={{
            pageSize: 10,
            sortBy: [{ id: "widgetSpecs" }],
          }}
          isLoading={props.isLoading}
          showPagination
          sortable
          disableRow={(row) => {
            return row.values?.widgetSpecs?.length >= MAX_ALLOWED_WIDGETS;
          }}
          onClick={(row) => handleClickRow(row.id)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button secondary width={70} onClick={props.onClose}>
          {copyText.cancelButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DashboardListModal {
  export const INTERACTION_ROW_CLICKED = `DashboardListModal.INTERACTION_ROW_CLICKED`;
  export const INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED = `DashboardList.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED`;

  interface InteractionRowClicked {
    type: typeof DashboardListModal.INTERACTION_ROW_CLICKED;
    dashboardID: string;
  }

  interface InteractionCreateDashboardButtonClicked {
    type: typeof DashboardListModal.INTERACTION_CREATE_DASHBOARD_BUTTON_CLICKED;
  }

  export type Interaction =
    | InteractionRowClicked
    | InteractionCreateDashboardButtonClicked;
}

export default DashboardListModal;
