import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";
import {
  isAWSIntegration,
  isAzureIntegration,
  isGCPIntegration,
  isSnowflakeIntegration,
} from "../typeGuards";

export type InsightsGates = ReturnType<typeof insightsGates>;

export default function insightsGates(params: GateGetterParameters) {
  const hasAWSIntegration = params.integrations.some(isAWSIntegration);
  const hasAzureIntegration = params.integrations.some(isAzureIntegration);
  const hasGCPIntegration = params.integrations.some(isGCPIntegration);
  const hasSnowflakeIntegration = params.integrations.some(
    isSnowflakeIntegration
  );

  const hasCloudIntergation =
    hasAWSIntegration ||
    hasAzureIntegration ||
    hasGCPIntegration ||
    hasSnowflakeIntegration;

  return {
    canListRecommendations: params._permissionsIncludeAllOf([
      Permission.READ_RECOMMENDATIONS,
    ]),
    canUpdateRecommendations: params._permissionsIncludeAllOf([
      Permission.UPDATE_RECOMMENDATION,
    ]),
    hasCloudIntergation,
    hasAWSIntegration,
    hasAzureIntegration,
    hasGCPIntegration,
    hasSnowflakeIntegration,
  };
}
