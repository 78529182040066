import { Storage } from "@/utils/window";

const LOCAL_STORAGE_KEY_APP_STATE = "ternary-app-state";
const TIMEOUT = 10000;

interface Config {
  authBaseURL: string;
  storage: Storage;
}

export default class AuthHandler {
  readonly _className = "AuthHandler";

  private readonly _authBaseURL: string;
  private readonly _storage: Storage;

  constructor({ authBaseURL, storage }: Config) {
    this._authBaseURL = authBaseURL;
    this._storage = storage;

    if (window.location.search.includes("code=")) {
      this.handleRedirectCallback();
    }
  }

  public handleRedirectCallback(): void {
    const appState = this._storage.getItem(LOCAL_STORAGE_KEY_APP_STATE);

    if (appState) {
      this._storage.remove(LOCAL_STORAGE_KEY_APP_STATE);
      window.location.search = appState;
    }
  }

  public logout(): void {
    window.location.href = `${this._authBaseURL}/logout`;
  }

  public async redirectOnAuthError(): Promise<void> {
    const searchParams = window.location.search;

    if (searchParams.length > 0) {
      this._storage.setItem(LOCAL_STORAGE_KEY_APP_STATE, searchParams);
    }

    window.location.replace(this._buildAuthURL());

    /*
     * NOTE:
     *   The timer below is included by design. It gives the browser time
     *   to process the window.location update and redirect. Without it,
     *   React would update state and re-render, resulting in an unwanted
     *   error message flashing on the screen for a split second before
     *   the redirects actually happens.
     *
     *   Worth noting, an alternative to this would be to throw an uncaught
     *   error, which would effectively prevent React from updating state.
     *   This works as well, but felt unclean and more hacky than this.
     */

    return new Promise((resolve) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        resolve();
      }, TIMEOUT);
    });
  }

  private _buildAuthURL(): string {
    const returnURL = `${window.location.origin}${window.location.pathname}`;

    return `${this._authBaseURL}/initiate?return_url=${encodeURIComponent(
      returnURL
    )}`;
  }
}
