import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAWSRedshift(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="100%"
            y2="0%"
            id="Arch_Amazon-Redshift_32_svg__a"
          >
            <stop stopColor="#4D27A8" offset="0%"></stop>
            <stop stopColor="#A166FF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h40v40H0z" fill="url(#Arch_Amazon-Redshift_32_svg__a)" />
          <path
            d="M25.912 17.587a.96.96 0 01-.959-.957.96.96 0 011.919 0 .96.96 0 01-.96.957m-2.433 5.826a.96.96 0 01-.959-.958.96.96 0 011.919 0 .96.96 0 01-.96.958m-5.838-.97a.959.959 0 11.001-1.913.959.959 0 01-.001 1.912m-2.431 5.34a.96.96 0 01-.96-.957.96.96 0 011.919 0 .96.96 0 01-.959.957m10.702-13.108a1.96 1.96 0 00-1.959 1.956c0 .66.332 1.243.837 1.598l-.913 2.312a1.953 1.953 0 00-2.243 1.288l-2.045-.464a1.954 1.954 0 00-3.906.12c0 .504.197.96.511 1.306l-.892 2.09c-.032-.003-.061-.01-.092-.01a1.96 1.96 0 00-1.96 1.955 1.96 1.96 0 003.919 0c0-.692-.364-1.297-.908-1.645l.787-1.842c.188.06.385.102.593.102.771 0 1.432-.45 1.752-1.097l2.165.491a1.96 1.96 0 003.881-.38c0-.582-.261-1.1-.669-1.458l.959-2.43c.062.006.121.019.183.019a1.96 1.96 0 001.96-1.955 1.96 1.96 0 00-1.96-1.956M20.5 33.002c-4.865 0-8.5-1.46-8.5-2.765V11.59c1.663 1.338 5.153 2.049 8.5 2.049 3.347 0 6.837-.71 8.5-2.05v18.648c0 1.305-3.635 2.765-8.5 2.765m0-26.004c5.009 0 8.5 1.486 8.5 2.821 0 1.335-3.491 2.822-8.5 2.822S12 11.154 12 9.819s3.491-2.821 8.5-2.821M30 9.819C30 7.34 25.105 6 20.5 6 15.894 6 11 7.338 11 9.82l.002.011H11v20.406C11 32.68 15.894 34 20.5 34c4.605 0 9.5-1.32 9.5-3.763V9.83h-.002L30 9.82"
            fill="#FFF"
          />
        </g>
      </svg>
    </Icon>
  );
}
