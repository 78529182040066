import { useQueryClient } from "@tanstack/react-query";
import { AuthenticatedUserEntity } from "../api/core/types";

export default function useAuthenticatedUser(): AuthenticatedUserEntity {
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData<AuthenticatedUserEntity>([
    "authenticatedUser",
  ]);

  if (!user) {
    throw new Error("ERROR_UNEXPECTED");
  }

  return user;
}
