import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

type CudRecommendationEntity = Awaited<
  ReturnType<CoreAPIClient["getCudRecommendationsByTenantID"]>
>[0];

export default function useGetCudRecommendationsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<CudRecommendationEntity[], CoreAPIClientError>
): UseQueryResult<CudRecommendationEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["cudRecommendations"],
    queryFn: () => client.getCudRecommendationsByTenantID(tenantID),
    enabled: gatekeeper.canListRecommendations,
    ...options,
  });
}
