import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UpdateBudgetParameters } from "../../../api/core/types";

type Params = {
  budgetID: string;
  params: UpdateBudgetParameters;
};

type Options = UseMutationOptions<string, CoreAPIClientError, Params>;

type Result = UseMutationResult<string, CoreAPIClientError, Params>;

export default function useUpdateBudget(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ budgetID, params }) => client.updateBudget(budgetID, params),
    ...options,
  });
}
