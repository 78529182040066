import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type CustomMetricEntity = Awaited<
  ReturnType<CoreAPIClient["getCustomMetricsByTenantID"]>
>[0];

export default function useGetCustomMetricsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<CustomMetricEntity[], CoreAPIClientError>
): UseQueryResult<CustomMetricEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["customMetrics"],
    queryFn: () => client.getCustomMetricsByTenantID(tenantID),
    enabled: gatekeeper.canListCustomMetrics,
    meta: { errorMessage: copyText.errorLoadingCustomMetricsMessage },
    ...options,
  });
}
