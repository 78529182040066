export const awsRedshiftMeasures = {
  backupStorageCost: "backupStorageCost",
  backupStorageUsage: "backupStorageUsage",
  cost: "cost",
  dataScanCost: "dataScanCost",
  dataScanUsage: "dataScanUsage",
  nodeCost: "nodeCost",
  nodeUsage: "nodeUsage",
  serverlessComputeCost: "serverlessComputeCost",
  serverlessComputeUsage: "serverlessComputeUsage",
  storageCost: "storageCost",
  storageUsage: "storageUsage",
} as const;

export const awsRedshiftDimensions = {
  payerAccountId: "payerAccountId",
  clusterArn: "clusterArn",
  clusterId: "clusterId",
  instanceMemory: "instanceMemory",
  instanceType: "instanceType",
  instanceVcpu: "instanceVcpu",
  productFamily: "productFamily",
  io: "io",
  region: "region",
  storage: "storage",
  usageAccountId: "usageAccountId",
} as const;

export type AWSRedshiftSpendSummaryEntity = {
  totalCost: number;
};

export type AWSRedshiftResourceGroupEntity = {
  clusterId: string;
  cost: number;
  dataScanCost: number;
  nodeCost: number;
  region: string;
  storage: string;
  storageCost: number;
  usageAccountId: string;
};

export type AWSRedshiftResourcesEntity = {
  clusterArn: string;
  clusterId: string;
  productFamily: string;
  instanceMemory: string;
  instanceType: string;
  instanceVcpu: string;
  io: string;
  payerAccountId: string;
  region: string;
  storage: string;
  usageAccountId: string;

  cost: number;
  dataScanCost: number;
  nodeCost: number;
  storageCost: number;
  serverlessComputeCost: number;
  backupStorageCost: number;
  nodeUsage: number;
  dataScanUsage: number;
  storageUsage: number;
  serverlessComputeUsage: number;
  backupStorageUsage: number;
};

export type AWSRedshiftGroupFilters = {
  clusterId: string | null;
  region: string | null;
  usageAccountId: string | null;
};

export type AwsRedshiftUsageDatum = {
  cost: number;
  nodeCost: number;
  dataScanCost: number;
  storageCost: number;
  serverlessComputeCost: number;
  backupStorageCost: number;
  nodeUsage: number;
  dataScanUsage: number;
  storageUsage: number;
  serverlessComputeUsage: number;
  backupStorageUsage: number;
  timestamp: string;
};
