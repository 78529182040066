import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { useEffect, useState } from "react";
import { useConfig } from "../../../context/ConfigProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import TextArea from "../../../ui-lib/components/TextArea";
import copyText from "../copyText";

const IMAGE_PLACEHOLDER_REGEX = /(\{\{.+?\}\})/;
const FILE_TYPE_IMAGE_PNG = "image/png";

interface Props {
  imageID: string | null;
  isProcessing: boolean;
  onInteraction: (interaction: CreateCaseCommentForm.Interaction) => void;
}

export function CreateCaseCommentForm(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const config = useConfig();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [comment, setCommment] = useState("");

  useEffect(() => {
    if (!props.imageID) return;

    const imageURL = `${config.CORE_API_BASE_URL}/file/${props.imageID}?tenantID=${authenticatedUser.tenant.id}`;

    setCommment((currentComment) => {
      const replacedComment = currentComment.replace(
        IMAGE_PLACEHOLDER_REGEX,
        ""
      );

      return `${replacedComment} \n ![Image](${imageURL})`;
    });

    props.onInteraction({
      type: CreateCaseCommentForm.INTERACTION_IMAGE_LINK_SET,
    });
  }, [props.imageID]);

  function handlePasteImage(event: React.ClipboardEvent<HTMLTextAreaElement>) {
    const file = event.clipboardData.files.item(0);

    if (file && file.type === FILE_TYPE_IMAGE_PNG) {
      setCommment((currentComment) => `${currentComment} {{${file.name}}}`);

      props.onInteraction({
        type: CreateCaseCommentForm.INTERACTION_IMAGE_PASTED,
        file,
      });
    }
  }

  function handleSubmit() {
    props.onInteraction({
      type: CreateCaseCommentForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      comment,
    });
    setCommment("");
  }

  const canSubmit = comment.length > 0;

  return (
    <Box marginTop={theme.space_lg} flex="0 0 auto">
      <FormField
        name="comment"
        input={TextArea}
        placeholder={copyText.activityCommentPlaceholderText}
        resizeable
        rows={5}
        value={comment}
        onChange={(event) => setCommment(event.target.value)}
        onPaste={handlePasteImage}
      />
      <Flex justifyContent="flex-end">
        <Button
          disabled={props.isProcessing || !canSubmit}
          locked={!gatekeeper.canCreateCaseComment}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Box>
  );
}

CreateCaseCommentForm.INTERACTION_IMAGE_LINK_SET =
  `CreateCaseActivityForm.INTERACTION_IMAGE_LINK_SET` as const;
CreateCaseCommentForm.INTERACTION_IMAGE_PASTED =
  `CreateCaseActivityForm.INTERACTION_IMAGE_PASTED` as const;
CreateCaseCommentForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  `CreateCaseActivityForm.INTERACTION_SUBMIT_BUTTON_CLICKED` as const;

interface InteractionImageLinkSet {
  type: typeof CreateCaseCommentForm.INTERACTION_IMAGE_LINK_SET;
}

interface InteractionImagePasted {
  type: typeof CreateCaseCommentForm.INTERACTION_IMAGE_PASTED;
  file: File;
}

interface InteractionSubmitButtonClicked {
  type: typeof CreateCaseCommentForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  comment: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CreateCaseCommentForm {
  export type Interaction =
    | InteractionImageLinkSet
    | InteractionImagePasted
    | InteractionSubmitButtonClicked;
}

export default CreateCaseCommentForm;
