import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { QueryClient, useQuery } from "@tanstack/react-query";
import getReportData, {
  Dependencies,
  ReportDataResult,
} from "@ternary/api-lib/analytics/getReportData";
import {
  ChartType,
  DataSource,
  DurationType,
} from "@ternary/api-lib/constants/enums";
import { getDataSourceFilters } from "@ternary/api-lib/utils/ReportUtils";
import { keyBy } from "lodash";
import registry from "unilib-registry/instance";
import { LabelMapsEntity } from "../../../api/core/types";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import { useFilterStore } from "../../../context/FilterStoreProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { ReportDataConfig } from "../types";
import useGetCustomMetricsByTenantID from "./useGetCustomMetricsByTenantID";
import useGetDatadogIntegrationByTenantID from "./useGetDatadogIntegrationByTenantID";

type Options = UseQueryOptions<ReportDataResult, CoreAPIClientError>;
type Result = UseQueryResult<ReportDataResult, CoreAPIClientError>;

const REPORT_STALE_TIME_MS = 2 * 60 * 1000;

const defaultReport: ReportDataConfig = {
  id: "",
  compareEndDate: null,
  compareStartDate: null,
  compareDurationType: null,
  chartType: ChartType.STACKED_AREA,
  dataSource: DataSource.BILLING,
  dimensions: [],
  durationType: DurationType.LAST_THIRTY_DAYS,
  endDate: null,
  excludedCreditTypes: [],
  excludeNegativeNumbers: false,
  excludeOther: false,
  filters: [],
  formula: null,
  formulaAlias: null,
  hiddenMeasures: [],
  invoiceMonthEnd: null,
  invoiceMonthStart: null,
  isCumulative: false,
  isFormulaHidden: false,
  isMetricHidden: false,
  limit: null,
  measures: [],
  metric: null,
  metricAggregate: null,
  metricFilters: [],
  nLookback: null,
  reverse: false,
  sortRule: null,
  startDate: null,
  timeGranularity: null,
  xAxisKey: null,
};

const defaultCustomMetrics = [];

export default function useGetReportData(
  report?: ReportDataConfig,
  options?: Options
): Result {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const filterStore = useFilterStore();

  const hasReport = !!report;

  report ??= defaultReport;

  const globalFilters = getDataSourceFilters(
    filterStore.queryFilters,
    report.dataSource
  );

  const { data: integration } = useGetDatadogIntegrationByTenantID(
    authenticatedUser.tenant.fsDocID
  );

  const { data: customMetrics = defaultCustomMetrics } =
    useGetCustomMetricsByTenantID(authenticatedUser.tenant.id);

  const customMetricMap = keyBy(customMetrics, "name");

  const fiscalPeriodMap =
    authenticatedUser.tenant.fiscalCalendar?.periods ?? {};

  const isFiscalMode = authenticatedUser.settings.fiscalMode;

  const integrationMetricMap = {
    ...customMetricMap,
    ...integration?.metrics,
  };

  const queryClient = registry.get<QueryClient>("ReactQueryClient");

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  if (!labelMaps) {
    throw new Error("ERROR_MAPS_UNDEFINED");
  }

  const labelMap = labelMaps[report.dataSource] ?? {};

  const dependenciesForCache: Omit<Dependencies, "client"> = {
    fiscalPeriodMap,
    globalFilters,
    integrationMetricMap,
    isFiscalMode,
    labelMap,
  };

  const reportForCache = {
    ...report,

    // NOTE: Changes between time-series chart types should be ignored by the cache.
    chartType:
      report.chartType === ChartType.TABLE ||
      report.chartType === ChartType.PIE ||
      report.chartType === ChartType.KPI
        ? report.chartType
        : "TIME_SERIES",

    // NOTE: Changes in these fields should not require refetching data
    id: null,
    tenantID: null,
    createdAt: null,
    createdBy: null,
    name: null,
    scope: null,
    type: null,
    updatedAt: null,
    updatedBy: null,
  };

  return useQuery({
    queryFn: async () => {
      const dataResult = await getReportData(
        {
          ...dependenciesForCache,
          client,
        },
        {
          report,
        }
      );

      return dataResult;
    },
    queryKey: [
      "reports",
      {
        ...dependenciesForCache,
        report: reportForCache,
      },
    ],
    // NOTE: Don't refetch on new hook instances for X milliseconds
    staleTime: REPORT_STALE_TIME_MS,
    ...options,
    ...(!hasReport ? { enabled: false } : {}),
  });
}
