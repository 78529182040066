import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

export function CartesianLoader() {
  const theme = useTheme();

  return (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 1000 400"
      preserveAspectRatio="none"
      style={{ width: "100%", height: "100%" }}
    >
      {/* Axis */}
      <rect x="60" y="10" width="4" height="290" />
      <rect x="60" y="300" width="900" height="7" />

      {/* Cartesian Grid Lines */}
      <rect x="100" y="60" width="825" height="2" />
      <rect x="100" y="120" width="825" height="2" />
      <rect x="100" y="180" width="825" height="2" />
      <rect x="100" y="240" width="825" height="2" />

      {/* Y Axis Labels */}
      <rect
        x="0"
        y="50"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="0"
        y="110"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="0"
        y="170"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="0"
        y="230"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />

      {/* X Axis Labels */}
      <rect
        x="100"
        y="325"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="300"
        y="325"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="500"
        y="325"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="700"
        y="325"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
      <rect
        x="900"
        y="325"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="50"
        height="20"
      />
    </ContentLoader>
  );
}
