import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { ChangeEvent, useState } from "react";
import isEmail from "validator/lib/isEmail";
import externalLinks from "../../../constants/externalLinks";
import { Input } from "../../../types";
import { FormField } from "../../../ui-lib/components/Form";
import Modal from "../../../ui-lib/components/Modal";
import TextInput from "../../../ui-lib/components/TextInput";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

const INPUT_API_TOKEN = "apiToken";
const INPUT_EMAIL = "email";
const INPUT_PROJECT_ID = "projectID";
const INPUT_CUSTOM_TRANSITION_ID = "customTransitionID";

interface Props {
  onInteraction: (
    interaction: CreateJiraIntegrationModalForm.Interaction
  ) => void;
}

interface State {
  apiTokenInput: Input<string>;
  emailInput: Input<string>;
  projectIDInput: Input<string>;
  customTransitionIDInput: Input<string>;
}

const initialState: State = {
  apiTokenInput: { value: "", isValid: false },
  emailInput: { value: "", isValid: false },
  projectIDInput: { value: "", isValid: false },
  customTransitionIDInput: { value: "", isValid: true },
};

function CreateJiraIntegrationModalForm(props: Props) {
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let isValid = false;

    switch (name) {
      case INPUT_API_TOKEN:
        isValid = value.length > 0;
        break;
      case INPUT_EMAIL:
        isValid = isEmail(value);
        break;
      case INPUT_PROJECT_ID:
        isValid = value.length > 0;
        break;
      case INPUT_CUSTOM_TRANSITION_ID:
        isValid = true;
        break;
    }

    mergeState({ [`${name}Input`]: { value, isValid } });
  }

  function handleSubmit() {
    props.onInteraction({
      type: CreateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      apiToken: state.apiTokenInput.value,
      email: state.emailInput.value,
      projectID: state.projectIDInput.value,
      ...(state.customTransitionIDInput.value.length > 0
        ? { customTransitionID: state.customTransitionIDInput.value }
        : {}),
    });
  }

  //
  // Render
  //

  const canSubmit = Object.values(state).every((input) => input.isValid);

  // TODO: Get docs for this
  const externalLinkCaption = (
    <Flex alignItems="center">
      <Text whiteSpace="pre">{copyText.issueTransitionIDInputCaption}</Text>
      {/* <a
        href={externalLinks.readmeJiraIntegrationDocumentation}
        rel="noreferrer"
        target="_blank"
      >
        {copyText.issueTransitionIDInputCaptionLink}
      </a> */}
    </Flex>
  );

  return (
    <Modal
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h3">{copyText.createJiraIntegrationFormTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          name={INPUT_PROJECT_ID}
          autoFocus
          input={TextInput}
          label={copyText.projectIDInputLabel}
          required
          onChange={handleChange}
        />
        <FormField
          name={INPUT_EMAIL}
          input={TextInput}
          label={copyText.emailInputLabel}
          required
          onChange={handleChange}
        />
        <FormField
          name={INPUT_API_TOKEN}
          input={TextInput}
          label={copyText.apiTokenInputLabel}
          required
          onChange={handleChange}
        />
        <FormField
          name={INPUT_CUSTOM_TRANSITION_ID}
          caption={externalLinkCaption}
          input={TextInput}
          label={copyText.issueTransitionIDInputLabel}
          onChange={handleChange}
        />
        <Flex alignItems="center">
          <Text whiteSpace="pre">{copyText.jiraDocumentationCaption}</Text>
          <a
            href={externalLinks.readmeJiraIntegrationDocumentation}
            rel="noreferrer"
            target="_blank"
          >
            {"here."}
          </a>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          secondary
          width={100}
          onClick={() =>
            props.onInteraction({
              type: CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
            })
          }
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {copyText.configureButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
CreateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "CreateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof CreateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  apiToken: string;
  customTransitionID?: string;
  email: string;
  projectID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CreateJiraIntegrationModalForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default CreateJiraIntegrationModalForm;
