import { isEqual } from "lodash";
import { useRef } from "react";

export default function useRefIfEqual<T>(value: T) {
  const ref = useRef(value);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}
