import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CostReportGates = ReturnType<typeof costReportGates>;

export default function costReportGates(params: GateGetterParameters) {
  return {
    canViewCostReports: params._permissionsIncludeAllOf([
      Permission.READ_CUSTOM_LABELS,
      Permission.READ_DATA_INTEGRATIONS,
    ]),
  };
}
