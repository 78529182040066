import * as Sentry from "@sentry/react";
import { TenantType } from "@ternary/api-lib/constants/enums";
import {
  EventReporterConfig as Config,
  EventReporter,
} from "@ternary/api-lib/telemetry";
import { get } from "lodash";
import mixPanel, { Dict } from "mixpanel-browser";
import { getFullName } from "./UserUtils";

type Context = Record<string, unknown>;

type Tags = { slack?: "cs" | "eng" | "silent" } & Record<
  string,
  boolean | string
>;

type User = {
  email: string;
  firstName: string;
  lastName: string;
  tenant: {
    id: string;
    name: string;
    type: TenantType;
  };
};

/**
 * Contains methods for reporting errors and events to our 3rd party services.
 *
 * [Mixpanel](https://developer.mixpanel.com/docs) is only enabled in "production" mode.
 *
 * [Sentry](https://docs.sentry.io/) is enabled in both "test" and "production" modes.
 */

export default class EventReporterImpl implements EventReporter {
  private readonly _devMode: boolean;
  private readonly _enableMixpanel: boolean;
  private readonly _enableSentry: boolean;

  constructor({ devMode, enableMixpanel, enableSentry }: Config) {
    this._devMode = devMode;
    this._enableMixpanel = enableMixpanel;
    this._enableSentry = enableSentry;
  }

  public registerUser(user: User): void {
    if (this._devMode) return;

    if (this._enableMixpanel) {
      mixPanel.identify(user.email);

      mixPanel.people.set({
        $email: user.email, // NOTE: $email is a special property for users, as exposed by mixpanel
        name: getFullName(user),
        tenantId: user.tenant.id,
        tenantName: user.tenant.name,
        tenantType: user.tenant.type,
      });
    }

    if (this._enableSentry) {
      const scope = Sentry.getCurrentScope();

      scope.setUser({ email: user.email });

      scope.setContext("tenant", {
        id: user.tenant.id,
        name: user.tenant.name,
      });
    }
  }

  public reportError(error: unknown) {
    const context = get(error, "context");
    const tags = get(error, "tags");

    if (this._devMode) {
      console.log(error, context, tags);
      return;
    }

    if (!this._enableSentry) return;

    Sentry.withScope((scope) => {
      if (context && typeof context === "object") {
        scope.setContext("context", this._stringifyContextValues(context));
      }

      if (tags && typeof tags === "object") {
        scope.setTags(tags);
      }

      Sentry.captureException(error);
    });
  }

  public reportMessage(
    message: string,
    options?: { context?: Context; tags?: Tags }
  ) {
    if (this._devMode) {
      console.log(message, options);
      return;
    }

    if (!this._enableSentry) return;

    Sentry.withScope((scope) => {
      if (options?.context) {
        scope.setContext(
          "context",
          this._stringifyContextValues(options.context)
        );
      }

      if (options?.tags) {
        scope.setTags(options.tags);
      }

      Sentry.captureMessage(message);
    });
  }

  public reportEvent(name: string, properties?: Dict) {
    if (!this._enableMixpanel) return;

    mixPanel.track(name, properties);
  }

  private _stringifyContextValues(
    context: Context
  ): Record<string, string | unknown> {
    const stringifiedObject = Object.entries(context).reduce(
      (accum: Record<string, string | unknown>, [key, value]) => ({
        ...accum,
        [key]: JSON.stringify(value, null, 2),
      }),
      {}
    );

    if (context.error && stringifiedObject.error) {
      stringifiedObject.rawError = context.error;
    }

    return stringifiedObject;
  }
}
