import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSDatabaseInstanceGroup,
  AWSDatabaseInstanceGroupStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
}

export interface Params {
  dateRange: Date[];
}

export default function useGetAWSDatabaseInstanceGroups(
  params: Params,
  options?: UseQueryOptions<AWSDatabaseInstanceGroup[], UError>
): UseQueryResult<AWSDatabaseInstanceGroup[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsDatabaseInstanceGroups", params],
    queryFn: async () => {
      const visibilityDimensions = [
        "databaseType",
        "family",
        "familyType",
        "lineItemUsageAccountId",
        "region",
      ];

      const visibilityMeasures = [
        "instanceIDCount",
        "instanceUnits",
        "networkBytes",
        "productMemoryBytes",
        "productVCPU",
        "totalCost",
        "usedDiskBytes",
      ];

      const visibilityResult = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_DATABASE_INSTANCES,
          dateRange: params.dateRange,
          dimensions: visibilityDimensions,
          measures: visibilityMeasures,
        })
      );

      const validatedInstanceGroup = visibilityResult.map(
        (datum): AWSDatabaseInstanceGroup => {
          const [error, validData] = validate(
            datum,
            AWSDatabaseInstanceGroupStruct
          );

          if (error) {
            throw new UError("INVALID_AWS_DATABASE_VISIBILITY_DATUM", {
              context: { error, result: datum },
            });
          }

          return {
            // DIMENSIONS
            databaseType: validData.databaseType ?? "",
            family: validData.family ?? "",
            familyType: validData.familyType ?? "",
            lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
            region: validData.region ?? "",

            // MEASURES
            instanceIDCount: validData.instanceIDCount ?? 0,
            instanceUnits: validData.instanceUnits ?? 0,
            networkBytes: validData.networkBytes ?? 0,
            productMemoryBytes: validData.productMemoryBytes ?? 0,
            productVCPU: validData.productVCPU ?? 0,
            totalCost: validData.totalCost ?? 0,
            usedDiskBytes: validData.usedDiskBytes ?? 0,
          };
        }
      );

      return validatedInstanceGroup;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
