import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Placement } from "@popperjs/core";
import React, {
  FunctionComponent,
  LegacyRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { usePopper } from "react-popper";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TOOLTIP_HIDE_TIME = 50;

interface PopperContainerProps {
  hasIcon: boolean;
  width: string | undefined;
}

const StyledTarget = styled.div<{ extendedTop: boolean }>`
  margin-top: ${(props) => (props.extendedTop ? "-1rem" : "unset")};
  padding-top: ${(props) => (props.extendedTop ? "1rem" : "unset")};
`;

const PopperContainer = styled.div<PopperContainerProps>`
  font-size: ${(props) => props.theme.fontSize_ui};
  font-weight: normal;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: ${(props) => props.theme.tooltip_background_color};
  padding: 0.5rem;
  text-align: center;
  min-width: ${(props) => (props.width ? props.width : "unset")};
  max-width: ${(props) => (props.width ? props.width : "unset")};
  color: ${(props) => props.theme.text_color_inverse};
  z-index: 100;
  text-transform: none;
`;

type Props = React.PropsWithChildren<{
  content: JSX.Element | string;
  delayHide?: number;
  extendedTop?: boolean;
  hide?: boolean;
  icon?: IconProp;
  iconColor?: string;
  placement?: Placement;
  width?: string;
}>;

let openID: string | null = null;

const Tooltip: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [showPopper, setShowPopper] = useState(false);

  const targetRef = useRef(null);
  const popperRef = useRef(null);
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null);

  const { styles, attributes } = usePopper(
    targetRef.current,
    popperRef.current,
    {
      placement: props.placement ?? "top",
      modifiers: [
        {
          name: "computeStyles",
          options: {
            adaptive: false, // true by default
          },
        },
        {
          name: "arrow",
          options: {
            element: arrowRef,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );

  const intervalToClose = useRef<NodeJS.Timeout | null>(null);
  const openIDRef = useRef<string | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  // We want to clear the interval any time we set show to false - no longer needed
  function clearIntervalToClose() {
    if (typeof intervalToClose.current === "number") {
      clearInterval(intervalToClose.current);
    }
  }

  function handleMouseEnter() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    setShowPopper(true);

    const openIDString = uuidv4();
    openID = openIDString;
    openIDRef.current = openIDString;
  }

  function handleMouseLeave() {
    timeout.current = setTimeout(() => {
      setShowPopper(false);
      clearIntervalToClose();
    }, props.delayHide ?? DEFAULT_TOOLTIP_HIDE_TIME);
    intervalToClose.current = setInterval(() => {
      if (showPopper && openID !== openIDRef.current) {
        setShowPopper(false);
        clearIntervalToClose();
      }
    }, 100);
  }

  useEffect(() => {
    return () => {
      if (typeof intervalToClose.current === "number") {
        clearInterval(intervalToClose.current);
      }
      if (typeof timeout.current === "number") {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <>
      <StyledTarget
        extendedTop={Boolean(props.extendedTop)}
        ref={targetRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.icon ? (
          <FontAwesomeIcon
            color={props.iconColor ?? theme.text_color}
            icon={props.icon}
            style={{ margin: "0 0.5rem" }}
            onMouseOver={handleMouseEnter}
          />
        ) : (
          props.children
        )}
      </StyledTarget>
      {showPopper && !props.hide ? (
        <PopperContainer
          hasIcon={props.icon !== undefined}
          ref={popperRef}
          style={styles.popper}
          width={props.width}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...attributes.popper}
        >
          <div
            ref={setArrowRef as LegacyRef<HTMLDivElement>}
            style={styles.arrow}
            id="arrow"
          />
          {props.content}
        </PopperContainer>
      ) : null}
    </>
  );
};

export default Tooltip;
