import { useQueryClient } from "@tanstack/react-query";
import { defaultDimensionsMap } from "@ternary/api-lib/constants/analytics";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "../api/core/types";

/**
 * Custom hook that derives all available dimensions by combining default dimensions
 * with custom dimensions for a given DataSource.
 * @param {DataSource} dataSource
 */

export default function useAvailableDimensionsByDataSource(
  dataSource: DataSource
): string[] {
  const queryClient = useQueryClient();

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  const defaultDimensions: string[] = defaultDimensionsMap[dataSource];

  if (!labelMaps) return defaultDimensions;

  if (!labelMaps[dataSource]) return defaultDimensions;

  const labelsKeyedByDimension = labelMaps[dataSource];

  const customDimensions = Object.keys(labelsKeyedByDimension);

  return defaultDimensions
    .concat(customDimensions)
    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
}
