import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { numberSort, stringSort } from "../../utils";
import { GCPComputeInstance } from "../types";

type TableData = {
  cpuUtilization: number;
  diskUtilization: number;
  instanceId: string;
  instanceName: string;
  instanceType: string;
  productMemoryBytes: number;
  productVCPU: number;
  provisionedDiskBytes: number;
  ramUtilization: number;
  totalCost: number;
};

type Props = {
  instances: GCPComputeInstance[];
  isLoadingInstances: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

export default function GCPComputeInstanceTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    () => getTableData(props.instances),
    [props.instances]
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "instanceName",
        align: "left",
        Cell: ({ value: instanceId }) => (
          <>{instanceId || copyText.gcpComputeTableNull}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_instanceName,
        sortType: stringSort,
        width: 180,
      },
      {
        accessor: "instanceId",
        align: "left",
        Cell: ({ value: instanceId }) => (
          <>{instanceId || copyText.gcpComputeTableNull}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_instanceId,
        sortType: stringSort,
        width: 180,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "instanceType",
        align: "right",
        Cell: ({ value: instanceType }) => (
          <>{instanceType || copyText.gcpComputeTableNull}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_instanceType,
        sortType: stringSort,
        width: 125,
      },
      {
        accessor: "productVCPU",
        align: "right",
        Header: copyText.gcpComputeTableInstancesHeader_productVCPU,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "cpuUtilization",
        align: "right",
        Cell: ({ value: cpuUtilization }) => (
          <>{formatPercentage(cpuUtilization)}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_cpuUtilization,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productMemoryBytes",
        align: "right",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_productMemoryBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "ramUtilization",
        align: "right",
        Cell: ({ value: ramUtilization }) => (
          <>{formatPercentage(ramUtilization)}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_ramUtilization,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "provisionedDiskBytes",
        align: "right",
        Cell: ({ value: provisionedDiskBytes }) => (
          <>{prettyBytes(provisionedDiskBytes)}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_provisionedDiskBytes,
        sortDescFirst: true,
        sortType: numberSort,
        width: 125,
      },
      {
        accessor: "diskUtilization",
        align: "right",
        Cell: ({ value: diskUtilization }) => (
          <>{formatPercentage(diskUtilization)}</>
        ),
        Header: copyText.gcpComputeTableInstancesHeader_diskUtilization,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
    ],
    []
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingInstances}
      showPagination
      sortable
      truncateRows
      onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
    />
  );
}

function getTableData(instances: GCPComputeInstance[]): TableData[] {
  return instances.map((instance) => ({
    cpuUtilization: instance.cpuUtilization,
    diskUtilization: instance.diskUtilization,
    instanceId: instance.instanceId,
    instanceName: instance.instanceName,
    instanceType: instance.instanceType,
    productMemoryBytes: instance.productMemoryBytes,
    productVCPU: instance.productVCPU,
    provisionedDiskBytes: instance.provisionedDiskBytes,
    ramUtilization: instance.ramUtilization,
    totalCost: instance.totalCost,
  }));
}
