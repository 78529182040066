import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { S3BucketGroup, S3BucketGroupFilters } from "../types";

type TableData = {
  accountID: string;
  bucketCount: number;
  groupID: string;
  networkCost: number;
  operationsCost: number;
  region: string;
  storageCost: number;
  totalCost: number;
};

type Props = {
  bucketGroups: S3BucketGroup[];
  isLoadingBucketGroups: boolean;
  onInteraction: (interaction: AWSStorageS3GroupTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function AWSStorageS3GroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "buckets_group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "storageCost",
    })
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "groupID",
        Cell: ({ value: groupID }) => {
          return (
            <Tooltip content={copyText.storageSubTableViewBuckets}>
              <LinkWithSearchParams
                searchParams={{ selected_group_id: groupID }}
              >
                <Button
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        Header: "",
        width: 50,
      },
      {
        accessor: "accountID",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "accountID",
                filterValue: value,
              })
            }
          >
            {value || copyText.s3TableNull}
          </Text>
        ),
        Header: copyText.s3TableBucketGroupHeader_accountID,
        sortType: stringSort,
        width: 60,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value || copyText.s3TableNull}
          </Text>
        ),
        Header: copyText.s3TableBucketGroupHeader_region,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "bucketCount",
        align: "center",
        Header: copyText.s3TableBucketGroupHeader_bucketCount,
        sortDescFirst: true,
        sortType: numberSort,
        width: 60,
      },
      {
        accessor: "storageCost",
        align: "center",
        Cell: ({ value: storageCost }) => (
          <>{formatCurrency({ number: storageCost })}</>
        ),
        Header: copyText.s3TableBucketGroupHeader_storageCost,
        sortDescFirst: false,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "networkCost",
        align: "center",
        Cell: ({ value: networkCost }) => (
          <>{formatCurrency({ number: networkCost })}</>
        ),
        Header: copyText.s3TableBucketGroupHeader_networkCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "operationsCost",
        align: "center",
        Cell: ({ value: operationsCost }) => (
          <>{formatCurrency({ number: operationsCost })}</>
        ),
        Header: copyText.s3TableBucketGroupHeader_operationsCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.s3TableBucketGroupHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
    ],
    [props.bucketGroups]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.bucketGroups.map((bucketsGroup) => ({
      accountID: bucketsGroup.accountID,
      bucketCount: bucketsGroup.buckets.length,
      groupID: bucketsGroup.groupID,
      networkCost: bucketsGroup.networkCost,
      operationsCost: bucketsGroup.operationsCost,
      region: bucketsGroup.region,
      storageCost: bucketsGroup.storageCost,
      totalCost: bucketsGroup.cost,
    }));
    return tableData;
  }, [props.bucketGroups]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingBucketGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKey: (keyof TableData)[] = [
    "accountID",
    "bucketCount",
    "groupID",
    "networkCost",
    "operationsCost",
    "region",
    "storageCost",
  ];
  return tableDataKey.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED =
  `AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSStorageS3GroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof S3BucketGroupFilters;
  filterValue: S3BucketGroupFilters[keyof S3BucketGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSStorageS3GroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSStorageS3GroupTable;
