import styled from "@emotion/styled";
import {
  formatCurrency,
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useEffect, useState } from "react";
import getMergeState from "../../utils/getMergeState";
import TextInput, { Size } from "./TextInput";

type Format = "currency" | "currency-rounded" | "percent" | "percent-divided";

const _NumberInput = styled(TextInput)({
  textAlign: "right",
});

export interface Props {
  disabled?: boolean;
  max?: number;
  size?: Size;
  variant?: "danger" | "success";
  value: number;
  formatter?: Format | ((number: number) => string);
  onChange: (number: number) => void;
}

export default function NumberInput(props: Props): JSX.Element {
  const [state, setState] = useState({
    isFocused: false,
    value: props.value.toString(),
  });

  const mergeState = getMergeState(setState);

  function handleOnFocus() {
    mergeState({ isFocused: true });
  }

  function handleOnBlur() {
    let value = state.value;
    if (!isNaN(Number(state.value))) {
      if (props.max !== undefined && +state.value > props.max) {
        value = props.max.toString();
      }
      props.onChange(Number(value));
    }

    mergeState({ isFocused: false, value: props.value.toString() });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    mergeState({ value: e.target.value });
  }

  useEffect(() => {
    mergeState({ value: props.value.toString() });
  }, [props.value]);

  function formatValue(val: number): string {
    if (!props.formatter) return "invalid";

    switch (props.formatter) {
      case "currency":
        return formatCurrency({ number: val });
      case "currency-rounded":
        return formatCurrencyRounded({ number: val });
      case "percent":
        return formatPercentage(val);
      case "percent-divided":
        return formatPercentage(val / 100);
      default:
        return props.formatter(val);
    }
  }

  function getTextFromLiteralValue(): string {
    if (state.isFocused) {
      return state.value;
    }

    if (!isNaN(Number(state.value))) {
      return props.formatter ? formatValue(Number(state.value)) : state.value;
    } else {
      return props.value.toString();
    }
  }

  return (
    <_NumberInput
      disabled={props.disabled}
      size={props.size}
      variant={props.variant}
      value={getTextFromLiteralValue()}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={handleChange}
    />
  );
}
