import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChartLine,
  faGripHorizontal,
  faHashtag,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import {
  differenceInCalendarMonths,
  isAfter,
  isBefore,
  isSameMonth,
} from "date-fns";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../../../components/Card";
import Legend from "../../../components/Legend";
import { DateHelper } from "../../../lib/dates";
import { getUTCMonthString } from "../../../utils/dates";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import {
  CommitmentPeriod,
  NetCostByDate,
  NetCostByDateDimensional,
  NetCostByRampPlanID,
  RampPlanBreakpoint,
} from "../types";
import {
  applyOffetsToActualSpend,
  getApplicableEntries,
  getCumulativeActualSpend,
  getLatestVersion,
  getOverflowSpendForPeriod,
  getProjectionsForRampPlan,
  getRampPlanMonths,
} from "../utils/rampPlans";
import RampPlanCommitmentChart from "./RampPlanCommitmentChart";
import RampPlanDimensialSpendChart from "./RampPlanDimensionalSpendChart";
import { renderVariance } from "./RampPlansTable";

export const StyledCarousel = styled(Carousel)`
  .react-multiple-carousel__arrow {
    background: ${(props) => props.theme.primary_color_background};
    opacity: 0.8;
  }

  .react-multiple-carousel__arrow--left {
    left: 1%;
    top: 85%;
  }
  .react-multiple-carousel__arrow--right {
    right: 1%;
    top: 85%;
  }
`;

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface Props {
  actualSpend: NetCostByDate[];
  actualSpendDimensional?: NetCostByDateDimensional[];
  hideDimensionalAnalysis?: boolean;
  hideTopMeter?: boolean;
  loading: boolean;
  loadingDimensional?: boolean;
  previousNetCostByRampPlanID?: NetCostByRampPlanID;
  rampPlan: RampPlan | undefined;
  selectedMonthForDimensionalChart?: string;
  onInteraction: (interaction: RampPlanDimensialSpendChart.Interaction) => void;
}

interface State {
  firstControlComplete: boolean;
  showCount: number;
}

const HEIGHT = 400;

export default function RampPlanChartSection(props: Props): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    firstControlComplete: false,
    showCount: 1,
  });
  const mergeState = getMergeState(setState);
  const now = new DateHelper();

  const rampPlanMonths = useMemo(() => {
    if (!props.rampPlan) return [];

    return getRampPlanMonths(props.rampPlan);
  }, [props.rampPlan]);

  const latestBreakpointVersions = useMemo(() => {
    if (!props.rampPlan) return [];

    return props.rampPlan.breakpoints.map(getLatestVersion);
  }, [props.rampPlan]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: state.showCount > 1 ? state.showCount : 1,
      partialVisibilityGutter: state.showCount > 1 ? 100 : 0,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: state.showCount,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 25,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  };

  const car = useRef<Carousel | null>(null);

  useEffect(() => {
    if (car.current === null) return;
    setTimeout(() => {
      if (car.current === null) return;
      car.current.setItemsToShow(Boolean(state.showCount));
    }, 100);
  }, [state.showCount]);

  useEffect(() => {
    if (car.current === null) return;
    setTimeout(() => {
      const currentIndex = (
        props.rampPlan ? props.rampPlan.commitments : []
      ).findIndex((c) => {
        const now = new DateHelper();

        if (
          isAfter(new Date(c.start), now.date) ||
          isBefore(new Date(c.end), now.date)
        ) {
          return false;
        }

        return true;
      });

      if (
        car.current === null ||
        currentIndex === -1 ||
        currentIndex < car.current.state.slidesToShow - 1
      )
        return;

      const slideWithCurrentPeriod =
        (currentIndex + 1) / car.current.state.slidesToShow - 1;

      car.current.goToSlide(slideWithCurrentPeriod);
    }, 100);
  }, [state.showCount]);

  const headerInfo = useMemo(() => {
    if (!props.rampPlan || !props.previousNetCostByRampPlanID) return {};

    const totalPeriods = props.rampPlan.commitments.length;
    const currentPeriodIndex = props.rampPlan.commitments.findIndex(
      (commitment) => {
        return (
          !isAfter(new Date(commitment.start), now.date) &&
          !isBefore(new Date(commitment.end), now.date)
        );
      }
    );

    const isNotCurrent = currentPeriodIndex === -1;

    const commitmentPeriodPosition = isNotCurrent
      ? copyText.notAvailable
      : `${currentPeriodIndex + 1} / ${totalPeriods}`;

    const monthsRemainingInCurrentPeriod = isNotCurrent
      ? copyText.notAvailable
      : differenceInCalendarMonths(
          now.date,
          new Date(props.rampPlan.commitments[currentPeriodIndex].end)
        );

    const commitmentPeriodAmount = isNotCurrent
      ? copyText.notAvailable
      : props.rampPlan.commitments[currentPeriodIndex].amount;

    const { projectedVarianceAbsolute, projectedVariancePercentage } =
      isNotCurrent || props.hideTopMeter
        ? {
            projectedVarianceAbsolute: copyText.notAvailable,
            projectedVariancePercentage: copyText.notAvailable,
          }
        : getProjectionsForRampPlan(
            props.rampPlan,
            applyOffetsToActualSpend(
              props.previousNetCostByRampPlanID[props.rampPlan.id],
              props.rampPlan.nonExportOffsetByMonth,
              props.rampPlan.nonExportOffsetRecurring
            )
          );

    return {
      billingAccountIDs: props.rampPlan.billingAccountIDs,
      commitmentPeriodAmount,
      commitmentPeriodPosition,
      monthsRemainingInCurrentPeriod,
      projectedVarianceAbsolute,
      projectedVariancePercentage,
    };
  }, [props.rampPlan, props.previousNetCostByRampPlanID]);

  const commitmentStartMonth = props.rampPlan?.commitments[0].start
    ? getUTCMonthString(props.rampPlan.commitments[0].start)
    : "";

  const commitmentEndMonth = props.rampPlan?.commitments[
    props.rampPlan?.commitments.length - 1
  ].end
    ? getUTCMonthString(
        props.rampPlan.commitments[props.rampPlan?.commitments.length - 1].end
      )
    : "";

  const modifiedActualSpend = applyOffetsToActualSpend(
    props.actualSpend,
    props.rampPlan?.nonExportOffsetByMonth ?? {},
    props.rampPlan?.nonExportOffsetRecurring ?? {}
  );

  const applicableActualSpend = modifiedActualSpend.filter((entry) =>
    getApplicableEntries(
      entry,
      commitmentStartMonth,
      commitmentEndMonth,
      "date"
    )
  );

  const cumulativeActualSpend: {
    date: string;
    actualNetCost: number;
  }[] = getCumulativeActualSpend(applicableActualSpend, 0);

  const noActuals = cumulativeActualSpend.length === 0;

  const projectedRampPlanBasedOnActuals = (() => {
    const startingPoint =
      cumulativeActualSpend[cumulativeActualSpend.length - 1] ||
      rampPlanMonths[0];

    const remainingMonths = rampPlanMonths.filter(
      (month) =>
        (noActuals &&
          isSameMonth(new Date(startingPoint.date), new Date(month.date))) ||
        isBefore(new Date(startingPoint.date), new Date(month.date))
    );

    if (remainingMonths.length <= 1) {
      return [];
    }

    const output: {
      date: string;
      derivedProjection: number;
    }[] = noActuals
      ? []
      : [
          {
            date: startingPoint.date,
            derivedProjection: startingPoint.actualNetCost,
          },
        ];

    remainingMonths.forEach((month) => {
      const previous = output[output.length - 1]?.derivedProjection ?? 0;

      output.push({
        date: month.date,
        derivedProjection:
          month.dimensions.reduce((total, dimension) => {
            return dimension.netCost + total;
          }, 0) + previous,
      });
    });

    return output;
  })();

  const fullCumulativeSpend = uniqBy(
    [...cumulativeActualSpend, ...projectedRampPlanBasedOnActuals],
    "date"
  ).map((entry) => {
    let netCost = 0;

    if ("actualNetCost" in entry) {
      netCost = entry.actualNetCost;
    }

    if ("derivedProjection" in entry) {
      netCost = entry.derivedProjection;
    }

    return { date: entry.date, netCost };
  });

  const commitments = props.rampPlan?.commitments ?? [];

  const overflowByPeriod = props.rampPlan?.commitments.map(
    (currentCommitmentPeriod, index) => {
      const previousCommitmentEndDate =
        commitments[
          commitments.findIndex((commitment) => {
            return commitment._id === currentCommitmentPeriod._id;
          }) - 1
        ]?.end;

      const commitmentStartDate = new Date(currentCommitmentPeriod.start);

      // NOTE: Prevents counting the same month twice in overlapping commitment periods
      const commitmentStartMonth = getUTCMonthString(
        index === 0 ||
          getUTCMonthString(currentCommitmentPeriod.start) !==
            getUTCMonthString(previousCommitmentEndDate)
          ? currentCommitmentPeriod.start
          : formatDate(
              commitmentStartDate.setMonth(commitmentStartDate.getMonth() + 1),
              "yyyy-MM"
            )
      );

      return getOverflowSpendForPeriod(
        fullCumulativeSpend,
        commitmentStartMonth,
        commitments
      );
    }
  );

  const topBarMetrics = [
    {
      label: copyText.chartSectionCurrentCommitment,
      value: (
        <Text align="right">
          {typeof headerInfo.commitmentPeriodAmount === "string"
            ? headerInfo.commitmentPeriodAmount
            : formatCurrencyRounded({
                number: headerInfo.commitmentPeriodAmount ?? 0,
              })}
        </Text>
      ),
    },
    {
      label: copyText.chartSectionProjectedVariance,
      value: (
        <Flex justifyContent="space-between" width="100%">
          {typeof headerInfo.projectedVarianceAbsolute === "string" ||
          typeof headerInfo.projectedVariancePercentage === "string" ? (
            <>
              <Text>{headerInfo.projectedVarianceAbsolute}</Text>
              <Text>{headerInfo.projectedVariancePercentage}</Text>
            </>
          ) : (
            (renderVariance({
              value: headerInfo.projectedVarianceAbsolute ?? 0,
              formattedValue: formatCurrencyRounded({
                number: headerInfo.projectedVarianceAbsolute ?? 0,
              }),
              theme,
            }),
            renderVariance({
              value: headerInfo.projectedVariancePercentage ?? 0,
              formattedValue: formatPercentage(
                headerInfo.projectedVariancePercentage ?? 0
              ),
              theme,
            }))
          )}
        </Flex>
      ),
    },
    {
      label: copyText.chartSectionCommitmentPeriod,
      value: <Text align="right">{headerInfo.commitmentPeriodPosition}</Text>,
    },
    {
      label: copyText.chartSectionMonthsLeft,
      value: (
        <Text align="right">{headerInfo.monthsRemainingInCurrentPeriod}</Text>
      ),
    },
  ];

  return (
    <Box width="100%" marginBottom={theme.space_md}>
      {!props.hideTopMeter && (
        <Card marginBottom={theme.space_md} position="relative">
          <Flex justifyContent="space-between">
            <Flex
              flexGrow={1}
              justifyContent="space-between"
              marginRight={theme.space_lg}
            >
              {topBarMetrics.map((metric) => {
                return (
                  <Box key={metric.label} marginRight={theme.space_lg}>
                    <Text bold>{metric.label}</Text>
                    {props.loading ? (
                      renderSmallLoadingBar(theme)
                    ) : (
                      <>{metric.value}</>
                    )}
                  </Box>
                );
              })}
            </Flex>
            <Flex>
              <Button
                primary={state.showCount === 1}
                secondary={state.showCount !== 1}
                onClick={(e) => {
                  e.preventDefault();
                  mergeState({ showCount: 1 });
                }}
              >
                <FontAwesomeIcon icon={faSquare} />
              </Button>
              <Button
                primary={state.showCount > 1}
                secondary={state.showCount === 1}
                marginLeft={theme.space_md}
                onClick={(e) => {
                  e.preventDefault();
                  mergeState({ showCount: 2 });
                }}
              >
                <Flex>
                  <FontAwesomeIcon icon={faGripHorizontal} />
                </Flex>
              </Button>
            </Flex>
          </Flex>
        </Card>
      )}
      <Flex alignItems="stretch">
        <Card
          backgroundColor={theme.panel_backgroundColor}
          position="relative"
          transition={"width 0.5s"}
          width={
            props.hideDimensionalAnalysis
              ? "100%"
              : state.showCount === 1
                ? "75%"
                : "85%"
          }
        >
          {!props.rampPlan || props.loading ? (
            <Card height={`${HEIGHT - 50}px`} width={"99%"} marginLeft={"0.5%"}>
              <EmptyPlaceholder
                icon={faChartLine}
                loading={props.loading}
                height={`${props.hideTopMeter ? HEIGHT - 50 : HEIGHT + 50}px`}
                skeletonVariant="cartesian"
                text={copyText.noDataPlaceholderMessage}
              />
            </Card>
          ) : (
            <>
              <StyledCarousel
                draggable={false}
                partialVisible={true}
                responsive={responsive}
                renderButtonGroupOutside={true}
                renderDotsOutside={true}
                ref={(el) => {
                  car.current = el;
                }}
                showDots={true}
                transitionDuration={250}
              >
                {props.rampPlan.commitments.map((commitment, i) => {
                  return (
                    <Card
                      height={`${HEIGHT + 25}px`}
                      key={commitment._id}
                      width={"99%"}
                      marginLeft={"0.5%"}
                    >
                      <RampPlanCommitmentChart
                        allRampPlanMonths={rampPlanMonths}
                        actualSpend={props.actualSpend}
                        commitment={commitment}
                        commitments={props.rampPlan?.commitments ?? []}
                        commitmentCount={
                          props.rampPlan ? props.rampPlan.commitments.length : 0
                        }
                        index={i}
                        largeView={state.showCount === 1}
                        latestBreakpointVersions={latestBreakpointVersions}
                        loading={props.loading}
                        nonExportOffsetByMonth={
                          props.rampPlan?.nonExportOffsetByMonth ?? {}
                        }
                        nonExportOffsetRecurring={
                          props.rampPlan?.nonExportOffsetRecurring ?? {}
                        }
                        overflow={overflowByPeriod ? overflowByPeriod[i] : 0}
                      />
                    </Card>
                  );
                })}
              </StyledCarousel>
              <Legend
                backgroundColor={theme.panel_backgroundColor}
                items={[
                  [
                    {
                      color: theme.ramp_plans_projected,
                      text: copyText.commitmentChartLabelProjected,
                      opacity: "1",
                    },
                    {
                      color: theme.ramp_plans_projected,
                      text: copyText.commitmentChartLabelBreakoint,
                      opacity: "1",
                      style: "circle",
                    },
                    {
                      color: theme.ramp_plans_actuals,
                      text: copyText.commitmentChartLabelActualSpend,
                      opacity: "1",
                    },
                    {
                      color: theme.ramp_plans_derived_projection,
                      text: copyText.commitmentChartLabelDerivedProjection,
                      opacity: "1",
                    },
                  ],
                ]}
              />
            </>
          )}
        </Card>
        {!props.hideDimensionalAnalysis &&
          props.actualSpendDimensional &&
          props.selectedMonthForDimensionalChart && (
            <Card
              height={`${HEIGHT + 100}px`}
              marginLeft={theme.space_md}
              transition={"width 0.5s"}
              width={state.showCount === 1 ? "25%" : "15%"}
              overflowY="auto"
            >
              {!props.rampPlan || props.loading || props.loadingDimensional ? (
                <>
                  <EmptyPlaceholder
                    icon={faChartLine}
                    loading={Boolean(props.loadingDimensional)}
                    height="200px"
                    small
                    skeletonVariant="cartesian"
                    text={copyText.noDataPlaceholderMessage}
                  />
                  <EmptyPlaceholder
                    icon={faChartLine}
                    loading={Boolean(props.loadingDimensional)}
                    height="200px"
                    small
                    skeletonVariant="cartesian"
                    text={copyText.noDataPlaceholderMessage}
                  />
                </>
              ) : (
                <RampPlanDimensialSpendChart
                  actualSpendDimensional={props.actualSpendDimensional}
                  compressedUI={state.showCount > 1}
                  rampPlan={props.rampPlan}
                  rampPlanMonths={rampPlanMonths}
                  selectedMonthForDimensionalChart={
                    props.selectedMonthForDimensionalChart
                  }
                  onInteraction={props.onInteraction}
                />
              )}
            </Card>
          )}
      </Flex>
    </Box>
  );
}

function renderSmallLoadingBar(theme: Theme) {
  return (
    <EmptyPlaceholder
      loading={true}
      icon={faHashtag}
      skeletonVariant="bar"
      small
      height={theme.space_md}
    />
  );
}
