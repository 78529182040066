import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAzureBB(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        id="f2f04349-8aee-4413-84c9-a9053611b319"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="ad4c4f96-09aa-4f91-ba10-5cb8ad530f74"
            x1="9"
            y1="15.83"
            x2="9"
            y2="5.79"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#b3b3b3" />
            <stop offset="0.26" stopColor="#c1c1c1" />
            <stop offset="1" stopColor="#e6e6e6" />
          </linearGradient>
        </defs>
        <title>Icon-storage-86</title>
        <path
          d="M.5,5.79h17a0,0,0,0,1,0,0v9.48a.57.57,0,0,1-.57.57H1.07a.57.57,0,0,1-.57-.57V5.79A0,0,0,0,1,.5,5.79Z"
          fill="url(#ad4c4f96-09aa-4f91-ba10-5cb8ad530f74)"
        />
        <path
          d="M1.07,2.17H16.93a.57.57,0,0,1,.57.57V5.79a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V2.73A.57.57,0,0,1,1.07,2.17Z"
          fill="#37c2b1"
        />
        <path
          d="M2.81,6.89H15.18a.27.27,0,0,1,.26.27v1.4a.27.27,0,0,1-.26.27H2.81a.27.27,0,0,1-.26-.27V7.16A.27.27,0,0,1,2.81,6.89Z"
          fill="#fff"
        />
        <path
          d="M2.82,9.68H15.19a.27.27,0,0,1,.26.27v1.41a.27.27,0,0,1-.26.27H2.82a.27.27,0,0,1-.26-.27V10A.27.27,0,0,1,2.82,9.68Z"
          fill="#37c2b1"
        />
        <path
          d="M2.82,12.5H15.19a.27.27,0,0,1,.26.27v1.41a.27.27,0,0,1-.26.27H2.82a.27.27,0,0,1-.26-.27V12.77A.27.27,0,0,1,2.82,12.5Z"
          fill="#258277"
        />
      </svg>
    </Icon>
  );
}
