import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPCloudRun(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
      >
        <g data-name="Product Icons">
          <g>
            <polygon
              fill="#aecbfa"
              points="8.9 2.63 12.02 12 21.38 12 8.9 2.63"
            />
            <polygon
              fill="#4285f4"
              fillRule="evenodd"
              points="21.38 12 12.02 12 8.9 21.38 21.38 12"
            />
            <polygon
              fill="#4285f4"
              points="3.44 21.38 6.57 19.81 8.9 12 5.78 12 3.44 21.38"
            />
            <polygon
              fill="#aecbfa"
              points="3.44 2.63 5.78 12 8.9 12 6.57 4.19 3.44 2.63"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
}
