import { createColumnHelper } from "@tanstack/react-table";
import {
  ResourceSubscriptionEntity,
  UserEntity,
} from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import "react-datepicker/dist/react-datepicker.css";
import copyText from "../copyText";

type TableData = {
  id: string;
  createdAt: string;
  createdByID: string;
  emails: number;
  frequency: string;
  updatedAt: string | null;
};

type SubscriptionListProps = {
  isLoading: boolean;
  subscriptions: ResourceSubscriptionEntity[];
  users: UserEntity[];
  handleRowClicked: (id: string) => void;
};

export default function ReportSubscriptionList(
  props: SubscriptionListProps
): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const usersKeyedByID = keyBy(props.users, "id");

  const columns = useMemo(
    () => [
      columnHelper.accessor("createdByID", {
        header: copyText.reportSubscriptionListCreatedByTableHeader,
        size: 100,
      }),
      columnHelper.accessor("createdAt", {
        meta: { align: "center" },
        header: copyText.reportSubscriptionListCreatedAtTableHeader,
        size: 60,
      }),
      columnHelper.accessor("frequency", {
        meta: { align: "center" },
        header: copyText.reportSubscriptionListFrequencyTableHeader,
        size: 60,
      }),
      columnHelper.accessor("emails", {
        meta: { align: "center" },
        header: copyText.reportSubscriptionListSubscriberCountHeader,
        size: 70,
      }),
    ],
    [props.subscriptions]
  );

  const tableData = useMemo(
    () =>
      props.subscriptions.map((subscription) => {
        return {
          id: subscription.id,
          createdAt: formatDate(new Date(subscription.createdAt), "MM/dd/yyyy"),
          createdByID: `${usersKeyedByID[subscription.createdByID].firstName} ${
            usersKeyedByID[subscription.createdByID].lastName
          }`,
          emails: subscription.emails.length,
          frequency:
            subscription.frequency.charAt(0) +
            subscription.frequency.slice(1).toLowerCase(),
          updatedAt: subscription.updatedAt
            ? formatDate(new Date(subscription.updatedAt), "MM/dd/yyyy")
            : "--",
        };
      }),
    [props.subscriptions, usersKeyedByID]
  );

  return (
    <Box>
      <Table
        clickableRows
        compact
        columns={columns}
        data={tableData}
        isLoading={props.isLoading}
        initialState={{ pagination: { pageSize: 5 } }}
        showPagination
        sortable
        onClick={(row) => props.handleRowClicked(row.id)}
      />
    </Box>
  );
}
