import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { DashboardEntity } from "@ternary/api-lib/core/types";
import { UpdateDashboardParameters } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { dashboardID: string } & UpdateDashboardParameters,
  { previousDashboards: DashboardEntity[] }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { dashboardID: string } & UpdateDashboardParameters,
  { previousDashboards: DashboardEntity[] }
>;

export default function useUpdateDashboard(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ dashboardID, ...params }) =>
      client.updateDashboard(dashboardID, params),
    ...options,
  });
}
