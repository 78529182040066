import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { StorageTableUsageDatum, StorageUsageTableStruct } from "../types";

export interface Params {
  dateRange: Date[];
  dimensions?: string[];
}

export default function useGetStorageUsageSummary(
  params: Params,
  options?: UseQueryOptions<StorageTableUsageDatum[], UError>
): UseQueryResult<StorageTableUsageDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["storageUsageSummary", params],
    queryFn: async () => {
      const dimensions = [
        "bucketId",
        "lifecycleRules",
        "location",
        "projectId",
        "storageClass",
      ];

      const measures = [
        "totalStoredByteSeconds",
        "totalStoredObjectDays",
        "totalReceivedBytes",
        "totalSentBytes",
        "totalRequestCount",
        "totalDistinctBuckets",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.STORAGE_USAGE,
          dateRange: params.dateRange,
          dimensions,
          measures,
        })
      );

      const data = result.map((datum): StorageTableUsageDatum => {
        const [error, validData] = validate(datum, StorageUsageTableStruct);
        if (error) {
          throw new UError("INVALID_STORAGE_USAGE_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          bucketId: validData.bucketId ?? "null",
          projectId: validData.projectId ?? "null",
          storageClass: validData.storageClass ?? "null",
          lifecycleRules: validData.lifecycleRules, //stringified Obj
          location: validData.location ?? "null",
          totalDistinctBuckets: validData.totalDistinctBuckets ?? 0,
          totalStoredByteSeconds: validData.totalStoredByteSeconds ?? 0,
          totalStoredObjectDays: validData.totalStoredObjectDays ?? 0,
          totalSentBytes: validData.totalSentBytes ?? 0,
          totalReceivedBytes: validData.totalReceivedBytes ?? 0,
          totalRequestCount: validData.totalRequestCount ?? 0,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
