import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { TimeGranularity } from "@ternary/api-lib/constants/enums";
import { ChartWrapper } from "@ternary/api-lib/ui-lib/charts/ChartWrapper";
import TimeSeriesChartTooltip from "@ternary/api-lib/ui-lib/charts/TimeSeriesChartTooltip";
import { useHorizontalLine } from "@ternary/api-lib/ui-lib/charts/TooltipUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import { getFormatForGranularity } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  formatCurrencyRounded,
  formatNumber,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import {
  areaStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/web-ui-lib/charts/styles";
import {
  DEFAULT_X_AXIS_KEY,
  formatTimestamp,
} from "@ternary/web-ui-lib/charts/utils";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import { isString, noop } from "lodash";
import React, { useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";
import { AzureCommitmentUsageDatum, AzureCommittedUseMeasures } from "../types";

interface Props {
  data: AzureCommitmentUsageDatum[];
  measures: string[];
  isLoading: boolean;
  unit: UnitType;
}

const StyledBox = styled(Box)`
  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;

const AXIS_CHARACTER_WIDTH = 7;
const AXIS_MAX_LEFT_MARGIN = AXIS_CHARACTER_WIDTH * 10;

/* prettier-ignore */
const readableKeys = {
  onDemandUsageCost: copyText.azureCommitmentVizChartReadable_onDemandUsageCost,
  onDemandUsageHours: copyText.azureCommitmentVizChartReadable_onDemandUsageHours,
  riAmortizedCost: copyText.azureCommitmentVizChartReadable_riAmortizedCost,
  riUnusedCost: copyText.azureCommitmentVizChartReadable_riUnusedCost,
  riUnusedHours: copyText.azureCommitmentVizChartReadable_riUnusedHours,
  riUsageHours: copyText.azureCommitmentVizChartReadable_riUsageHours,
  spAmortizedCost: copyText.azureCommitmentVizChartReadable_spAmortizedCost,
  spUnusedCost: copyText.azureCommitmentVizChartReadable_spUnusedCost,
  spUsageHours: copyText.azureCommitmentVizChartReadable_spUsageHours,
};

export default function AzureCommittedUseVisibilityChart(
  props: Props
): JSX.Element {
  const theme = useTheme();
  const horizontalLineElement = useHorizontalLine();
  const [excludedKeysMap, setExcludedKeysMap] = useState({
    onDemandUsageCost: false,
    onDemandUsageHours: false,
    riAmortizedCost: false,
    riUnusedCost: false,
    riUnusedHours: false,
    riUsageHours: false,
    spAmortizedCost: false,
    spUnusedCost: false,
    spUsageHours: false,
  });

  const toggleKey = (key: string) => {
    setExcludedKeysMap((keyMap) => ({ ...keyMap, [key]: !keyMap[key] }));
  };

  let leftMargin = AXIS_CHARACTER_WIDTH * Math.round(150).toString().length;
  if (leftMargin > AXIS_MAX_LEFT_MARGIN) {
    leftMargin = AXIS_MAX_LEFT_MARGIN;
  }

  const dateFormat = getFormatForGranularity(TimeGranularity.DAY);

  const customColors = {
    onDemandUsageCost: theme.cud_chart_fill_coverable_cost,
    onDemandUsageHours: theme.cud_chart_fill_coverable_cost,
    riAmortizedCost: theme.cud_chart_fill_recommendation_max_savings,
    riUnusedCost: theme.cud_chart_fill_recommendation,
    riUnusedHours: theme.cud_chart_fill_existing_coverage,
    riUsageHours: theme.cud_chart_fill_recommendation,
    spAmortizedCost: theme.cud_chart_fill_recommendation,
    spUnusedCost: theme.cud_chart_fill_existing_coverage,
    spUsageHours: theme.cud_chart_fill_recommendation,
  };

  const chartData = useMemo(() => {
    const hiddenKeysTuples = Object.entries(excludedKeysMap);

    return props.data.map((datum) => {
      const mappedDatum = { ...datum };

      for (const [srcKey, isHidden] of hiddenKeysTuples) {
        mappedDatum[srcKey] = isHidden ? 0 : mappedDatum[srcKey];
      }

      return mappedDatum;
    });
  }, [props.data, excludedKeysMap]);

  if (props.isLoading) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartArea}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }

  return (
    <StyledBox
      backgroundColor={theme.panel_backgroundColor}
      height="100%"
      position="relative"
    >
      {chartData.length === 0 ? (
        <EmptyPlaceholder
          loading={props.isLoading}
          icon={faChartArea}
          skeletonVariant="cartesian"
          text={copyText.chartEmptyPlaceholderText}
        />
      ) : (
        <ResponsiveContainer debounce={1} height="100%" width="100%">
          <ChartWrapper>
            <ComposedChart data={chartData}>
              <defs>
                {Object.keys(readableKeys).map((key) => (
                  <linearGradient
                    key={key}
                    id={key}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={customColors[key]}
                      stopOpacity={0.6}
                    />
                    <stop
                      offset="95%"
                      stopColor={customColors[key]}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                ))}
              </defs>

              <Tooltip
                content={(tooltipProps) => (
                  <TimeSeriesChartTooltip
                    customColorsKeyedByGrouping={customColors}
                    dateFormat={dateFormat}
                    entry={tooltipProps.payload?.[0]?.payload}
                    excludedGroupings={[]}
                    reverseSortedGroupings={Object.keys(readableKeys)
                      .filter((measure) => props.measures.includes(measure))
                      .reverse()}
                    hideTotal
                    label={tooltipProps.label}
                    formatGroupingLabelFromKey={(key) =>
                      readableKeys[key] ?? key
                    }
                    unitType={props.unit}
                  />
                )}
                wrapperStyle={{ outline: "none" }}
              />
              <CartesianGrid
                {...cartesianStyleProps}
                stroke={theme.chart_cartesian_grid_lines}
              />

              <XAxis
                domain={[0, "auto"]}
                {...xAxisStyleProps}
                dataKey={DEFAULT_X_AXIS_KEY}
                stroke={theme.chart_axis_text}
                tick={{
                  stroke: theme.chart_axis_text,
                  fontWeight: 100,
                  fontSize: "0.8rem",
                }}
                tickFormatter={(value) => formatTimestamp(value, dateFormat)}
              />

              <YAxis
                {...yAxisStyleProps}
                domain={[0, "auto"]}
                stroke={theme.chart_axis_text}
                tick={{
                  stroke: theme.chart_axis_text,
                  fontWeight: 100,
                  fontSize: "0.8rem",
                }}
                tickCount={8}
                tickFormatter={(number) =>
                  props.unit === UnitType.CURRENCY
                    ? formatCurrencyRounded({ number })
                    : formatNumber(number)
                }
              />
              {[
                AzureCommittedUseMeasures.onDemandUsageCost,
                AzureCommittedUseMeasures.spUnusedCost,
                AzureCommittedUseMeasures.riUnusedCost,
                AzureCommittedUseMeasures.spAmortizedCost,
                AzureCommittedUseMeasures.riAmortizedCost,
                AzureCommittedUseMeasures.onDemandUsageHours,
                AzureCommittedUseMeasures.riUnusedHours,
                AzureCommittedUseMeasures.riUsageHours,
                AzureCommittedUseMeasures.spUsageHours,
              ]
                .filter((measure) => props.measures.includes(measure))
                .reverse()
                .map((key) => {
                  return (
                    <Area
                      {...areaStyleProps}
                      key={key}
                      animationDuration={200}
                      dataKey={key}
                      fill={
                        excludedKeysMap[key]
                          ? theme.tooltip_disabled_cell_color
                          : `url(#${key})`
                      }
                      stackId={"a"}
                      stroke={
                        excludedKeysMap[key]
                          ? theme.tooltip_disabled_cell_color
                          : customColors[key]
                      }
                      strokeWidth={1}
                    />
                  );
                })}

              {horizontalLineElement}

              <Legend
                iconType="square"
                verticalAlign="bottom"
                onClick={(payload) =>
                  isString(payload.dataKey)
                    ? toggleKey(payload.dataKey)
                    : noop()
                }
                formatter={(key) => readableKeys[key] ?? key}
              />
            </ComposedChart>
          </ChartWrapper>
        </ResponsiveContainer>
      )}
    </StyledBox>
  );
}
