import copyText from "../common.copyText";

// prettier-ignore
export default {
    ...copyText,
    customLabelModalDeleteMessage: "Are you sure you want to delete this Custom Label?",
    customLabelsListEmpty: "No Custom Labels were found for the selected Cloud",
    customLabelsModalTitleCreation: "Configure Custom Label",
    customLabelsSectionAddCard: "Add New Custom Label",
    customLabelsSectionInstructions: "Custom Labels allow you to create new labels based on existing labels. These can be used elsewhere in the application to more accurately filter your data. Note that mapping your existing Custom Labels to other Custom Labels is not currently supported.",
    customLabelsSectionTitle: "Custom Labels Manager",
    deleteCustomLabelConfirmationTitle: "Delete Custom Label",
    errorCreatingCustomLabelMessage:  "An error occured while attempting to create the custom label. Please try again.",
    errorDeletingCustomLabelMessage:  "An error occured while attempting to delete the custom label. Please try again.",
    errorUpdatingCustomLabelMessage:  "An error occured while attempting to update the custom label. Please try again.",
    learnMoreCaption: "Learn more about",
    learnMoreLink: "Custom Labels",
    modalTitleEdit: "Edit Custom Label",
    successCreatingCustomLabelMessage: "The custom label has been successfully created.",
    successDeletingCustomLabelMessage: "The custom label has been successfully deleted.",
    successUpdatingCustomLabelMessage: "The custom label has been successfully updated."
}
