import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UpdateMeasurePreferencesParams } from "../../../api/core/types";

type Preference = Awaited<
  ReturnType<CoreAPIClient["updateMeasurePreferences"]>
>[0];

type Options = UseMutationOptions<
  Preference[],
  CoreAPIClientError,
  UpdateMeasurePreferencesParams
>;

type Result = UseMutationResult<
  Preference[],
  CoreAPIClientError,
  UpdateMeasurePreferencesParams
>;

export default function useUpdateMeasurePreferences(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.updateMeasurePreferences(params),
    ...options,
  });
}
