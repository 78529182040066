import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { GenerateTakeoutReportParameters } from "@/api/core/types";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";
import queryKeys from "./queryKeys";

type TakeoutReport = Awaited<
  ReturnType<CoreAPIClient["generateTakeoutReport"]>
>;

type Options = UseQueryOptions<TakeoutReport, CoreAPIClientError>;
type Result = UseQueryResult<TakeoutReport, CoreAPIClientError>;

export default function useGenerateTakeoutReport(
  params: GenerateTakeoutReportParameters,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.takeoutReport(params.billID),
    queryFn: () => client.generateTakeoutReport(params),
    meta: { errorMessage: copyText.errorCreatingBillMessage },
    ...options,
  });
}
