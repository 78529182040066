import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAvailableDimensionsByDataSource from "@/hooks/useAvailableDimensionsByDataSource";
import useGatekeeper from "@/hooks/useGatekeeper";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  getCubeDateRangeFromDurationType,
  getInvoiceMonthFilters,
} from "@ternary/api-lib/analytics/utils";
import { DataSource, DurationType } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import { endOfMonth, format, startOfMonth } from "date-fns";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import NotConfiguredPlaceholder from "../../../components/NotConfiguredPlaceholder";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import Select, { DefaultOption } from "../../../ui-lib/components/Select";
import IconExport from "../../../ui-lib/icons/IconExport";
import { padInvoiceMonthDateRange } from "../../reporting-engine/utils";
import copyText from "../copyText";
import useGetCreditsBreakdown from "../hooks/useGetCreditsBreakdown";
import useGetSpendSummaryByDimension from "../hooks/useGetSpendSummaryByDimension";
import { CSVData, CostData, CreditBreakdown, CreditData } from "../types";
import CreditsBreakdownTable, { creditsByType } from "./CreditsBreakdownTable";
import NetCostTable from "./NetCostTable";

type SpendSummary = {
  credits: number;
  grossCost: number;
  name: string;
  netCost: number;
};

interface State {
  dateRange: DateRange | null;
  durationType: DurationType;
  keyInput: string;
  selectedDimension: DefaultOption;
}

const initialState: State = {
  dateRange: getFirstAndLastDayPrevMonth(),
  durationType: DurationType.INVOICE,
  keyInput: "",
  selectedDimension: { label: "projectId", value: "projectId" }, // TODO: Consider Category once available
};

export default function CostReportContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const authenticatedUser = useAuthenticatedUser();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const theme = useTheme();

  if (!gatekeeper.hasConfiguredDataIntegration) {
    return <NotConfiguredPlaceholder />;
  }

  if (!gatekeeper.canViewCostReports) {
    navigate(paths._home, { replace: true });
    return <></>;
  }

  const availableDimensions = useAvailableDimensionsByDataSource(
    DataSource.BILLING
  );

  const dateRange = getDateRangeFromState(state.durationType, state.dateRange);

  const dateRangeFilters =
    state.durationType === DurationType.INVOICE
      ? getInvoiceMonthFilters(state.dateRange ?? [])
      : [];

  //
  // Queries
  //

  const { data: spendSummaries = [], isFetching: isLoadingSpendSummaries } =
    useGetSpendSummaryByDimension({
      dimension: state.selectedDimension.value,
      dateRange,
      durationType: state.durationType,
      preAggFilters: dateRangeFilters,
    });

  const { data: credits = [], isFetching: isLoadingCredits } =
    useGetCreditsBreakdown({
      dateRange,
      durationType: state.durationType,
      preAggFilters: dateRangeFilters,
    });

  //
  // Interaction Handlers
  //

  function handleChangeSelectedDimension(
    dimensions: DefaultOption | null
  ): void {
    activityTracker.captureAction(actions.CHANGE_GROUPING_COST_REPORTS);

    if (dimensions !== null) {
      mergeState({ selectedDimension: dimensions });
    }
  }

  //
  // Render
  //

  const isLoading = isLoadingSpendSummaries || isLoadingCredits;

  const csvDataCost = getCSVDataFromCostEntries(spendSummaries);
  const csvDataCredit = getCSVDataFromCreditEntries(credits);

  const dimensionOptions = availableDimensions.map((dimension) => ({
    label: dimension,
    value: dimension,
  }));

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      marginBottom={theme.space_lg}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderBottom={`1px solid ${theme.section_card_border}`}
        padding={theme.space_md}
      >
        <Flex alignItems="center" justifyContent="space-between">
          {availableDimensions && (
            <Flex alignItems="center" width={400}>
              <Text bold marginRight={theme.space_sm} whiteSpace="nowrap">
                {copyText.actionGroupBy}:
              </Text>
              <Select
                defaultValue={state.selectedDimension}
                options={dimensionOptions}
                searchable
                value={state.selectedDimension}
                onChange={handleChangeSelectedDimension}
              />
            </Flex>
          )}
        </Flex>
        <DateRangeControls
          dateRange={state.dateRange}
          durationType={state.durationType}
          disabled={isLoading}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          hiddenOptions={[DurationType.YESTERDAY]}
          onChangeDateRange={(durationType, dateRange) =>
            mergeState({
              dateRange,
              durationType,
            })
          }
        />
      </Flex>

      <Flex direction="column" padding={theme.space_md}>
        <Flex justifyContent="space-between">
          <Box maxWidth="50rem" position="relative" width="calc(60% - 1rem)">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={theme.space_xs}
            >
              <Text as="h3" appearance="h3">
                {copyText.netCostTableTitle}
              </Text>
              <CSVLink
                data={csvDataCost.data}
                filename={csvDataCost.fileName}
                headers={csvDataCost.headers}
              >
                <Button
                  disabled={
                    isLoading ||
                    (spendSummaries.length === 0 && credits.length === 0)
                  }
                  iconStart={<IconExport />}
                  marginLeft="1.5rem"
                  secondary
                  size="tiny"
                >
                  {copyText.actionExport}
                </Button>
              </CSVLink>
            </Flex>
            <NetCostTable loading={isLoading} spendSummaries={spendSummaries} />
          </Box>
          <Box maxWidth="50rem" position="relative" width="calc(40% - 1rem)">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              marginBottom={theme.space_xs}
            >
              <Text as="h3" appearance="h3">
                {copyText.creditBreakdownTableTitle}
              </Text>
              <CSVLink
                data={csvDataCredit.data}
                filename={csvDataCredit.fileName}
                headers={csvDataCredit.headers}
              >
                <Button
                  disabled={isLoading || credits.length === 0}
                  iconStart={<IconExport />}
                  marginLeft="1.5rem"
                  secondary
                  size="tiny"
                >
                  {copyText.actionExport}
                </Button>
              </CSVLink>
            </Flex>
            <CreditsBreakdownTable credits={credits} loading={isLoading} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

function getFirstAndLastDayPrevMonth(): DateRange {
  const now = new Date();

  now.setDate(15);

  now.setMonth(now.getMonth() - 1); //last month
  const start = startOfMonth(now);
  const end = endOfMonth(now);

  return [start, end];
}

function getCSVDataFromCostEntries(entries: SpendSummary[]): CSVData<CostData> {
  if (!entries) return { data: [], fileName: "", headers: [] };

  const data = entries
    .sort((a, b) => b.grossCost - a.grossCost)
    .map((datum) => ({
      name: datum.name,
      credit: formatCurrencyRounded({
        accounting: true,
        number: datum.credits,
      }),
      grossCost: formatCurrencyRounded({
        accounting: true,
        number: datum.grossCost,
      }),
      netCost: formatCurrencyRounded({
        accounting: true,
        number: datum.netCost,
      }),
    }));

  // TODO: Replace this with totals from datalligator
  const totals = entries.reduce(
    (accum, datum) => {
      accum["credits"] += datum.credits;
      accum["grossCost"] += datum.grossCost;
      accum["netCost"] += datum.netCost;

      return accum;
    },
    { credits: 0, grossCost: 0, netCost: 0 }
  );

  data.push({
    name: copyText.accountingTotals,
    credit: formatCurrencyRounded({
      accounting: true,
      number: totals.credits,
    }),
    grossCost: formatCurrencyRounded({
      accounting: true,
      number: totals.grossCost,
    }),
    netCost: formatCurrencyRounded({
      accounting: true,
      number: totals.netCost,
    }),
  });

  const headers = [
    { label: copyText.csvHeaderName, key: "name" },
    { label: copyText.csvHeaderGrossCost, key: "grossCost" },
    { label: copyText.csvHeaderCredit, key: "credit" },
    { label: copyText.csvHeaderNetCost, key: "netCost" },
  ];

  const fileName = `report-cost-breakdown-${formatDate(
    new Date(),
    "MM/dd/yyyy"
  )}`;

  return { data, fileName, headers };
}

function getCSVDataFromCreditEntries(
  entries: CreditBreakdown[]
): CSVData<CreditData> {
  if (!entries) return { data: [], fileName: "", headers: [] };

  const data = entries
    .sort((a, b) => a.total - b.total)
    .map((datum) => ({
      type: creditsByType[datum.type]
        ? creditsByType[datum.type].text
        : datum.type,
      amount: formatCurrencyRounded({
        accounting: true,
        number: datum.total,
      }),
    }));

  const totalCredit = entries.reduce((a, b) => a + b.total, 0);

  data.push({
    type: copyText.accountingTotalCredits,
    amount: formatCurrencyRounded({
      accounting: true,
      number: totalCredit,
    }),
  });

  const fileName = `report-credit-breakdown-${format(
    new Date(),
    "MM-dd-yyyy"
  )}`;

  const headers = [
    { label: copyText.csvHeaderName, key: "type" },
    { label: copyText.csvHeaderAmount, key: "amount" },
  ];

  return { data, fileName, headers };
}

function getDateRangeFromState(
  durationType: DurationType,
  dateRange?: DateRange | null
): DateRange {
  if (!dateRange) {
    return getCubeDateRangeFromDurationType(durationType);
  }

  if (durationType === DurationType.INVOICE) {
    dateRange = padInvoiceMonthDateRange(dateRange);

    return [dateRange[0], dateRange[1]];
  }

  return [dateRange[0], dateRange[1]];
}
