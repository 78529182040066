import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";
import { User } from "../types";

export type Case = {
  assignees: User[];
};

interface Props {
  case?: Case;
  isLoading: boolean;
  isProcessing: boolean;
  onChange: () => void;
}

export default function CaseAssigneeList(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  if (!props.case || props.isLoading) {
    return (
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        direction="column"
        height={250}
        justifyContent="space-between"
        marginRight={theme.space_sm}
        padding={theme.space_lg}
        width="50%"
      >
        <EmptyPlaceholder loading={props.isLoading} />
      </Flex>
    );
  }

  const assignees = props.case.assignees;

  const remainingAssignees = assignees.slice(5, assignees.length);

  const content = (
    <Flex direction="column" alignItems="flex-start">
      {remainingAssignees.map((assignee, i) => (
        <Text
          key={assignee.id ? assignee.id : i}
          color={theme.text_color_inverse}
        >
          {getFullName(assignee)} - {assignee.email ? assignee.email : ""}
        </Text>
      ))}
    </Flex>
  );

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      height={250}
      justifyContent="space-between"
      marginRight={theme.space_sm}
      minWidth={270}
      padding={theme.space_lg}
      width="50%"
    >
      <Box>
        <Text fontWeight={theme.fontWeight_bold} marginBottom={theme.space_xs}>
          {copyText.caseViewAssigneeSectionTitle} {`(${assignees.length})`}
        </Text>
        {assignees.slice(0, 5).map((assignee) => (
          <Text key={assignee.id} truncate>
            {getFullName(assignee)} - {assignee.email}
          </Text>
        ))}
        {remainingAssignees.length > 0 ? (
          <Tooltip content={content} placement="top-start">
            <Text fontWeight={theme.fontWeight_semiBold}>
              {copyText.remainingCountCaption.replace(
                "%count%",
                String(remainingAssignees.length)
              )}
            </Text>
          </Tooltip>
        ) : null}
      </Box>
      <Flex justifyContent="flex-end" marginTop={theme.space_md}>
        <Button
          disabled={!gatekeeper.canUpdateCase}
          iconStart={<Icon icon={faPlus} />}
          secondary
          size="small"
          onClick={() => props.onChange()}
        >
          {copyText.addAssigneeButtonLabel}
        </Button>
      </Flex>
    </Flex>
  );
}
