import { CloudProviderType } from "@ternary/api-lib/constants/enums";

export function getVendorFromCloudProviderType(
  cloudProviderType: CloudProviderType
): string {
  switch (cloudProviderType) {
    case CloudProviderType.ALIBABA:
      return "Alibaba";
    case CloudProviderType.AWS:
      return "Amazon";
    case CloudProviderType.AZURE:
      return "Azure";
    case CloudProviderType.GCP:
      return "Google";
    case CloudProviderType.OCI:
      return "Oracle";
    case CloudProviderType.SNOWFLAKE:
      return "Snowflake";
    default:
      return cloudProviderType;
  }
}
