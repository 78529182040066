import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { v4 as uuidv4 } from "uuid";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import { ProjectEntity, QueryFilter } from "./types";
import { buildCubeQuery } from "./utils";

type Project = {
  projectId: string | null;
  cost?: number | null;
  credits?: number | null;
};

interface Params {
  dataSource: DataSource;
  dateRange: Date[];
  measures: string[];
  queryFilters: QueryFilter[];
}

export default function useGetProjects(
  params: Params,
  options?: UseQueryOptions<ProjectEntity[], UError>
): UseQueryResult<ProjectEntity[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          ...params,
          dimensions: ["projectId"],
          measures: params.measures,
        })
      )) as Project[];

      return result.map((project) => {
        // NOTE: null projectId is essentially all spend not under a project label
        return {
          _id: uuidv4(),
          cost: project.cost ?? 0,
          credits: project.credits ?? 0,
          name: project.projectId ?? "null",
        };
      });
    },
    queryKey: ["projects", params],
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
