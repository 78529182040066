import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconCheck(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 16 18">
        <path
          d="M13.32 3.993a.667.667 0 0 0-.459.202L6 11.057 3.804 8.862a.667.667 0 1 0-.943.943l2.667 2.666a.667.667 0 0 0 .943 0l7.333-7.333a.666.666 0 0 0-.484-1.145Z"
          fill="#fff"
        />
      </svg>
    </Icon>
  );
}
