import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton, TableLegacy } from "@/ui-lib/components/Table";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import {
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewFilter, UserConfig } from "@ternary/api-lib/core/types";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../copyText";

type User = {
  id: string;
  email: string;
};

type ScopedView = {
  id: string;
  createdAt: string;
  createdBy: string;
  filters: ScopedViewFilter[];
  name: string;
  type: ScopedViewType;
  updatedAt: string | null;
  userConfigs: UserConfig[];
};
export interface Props {
  isAdmin: boolean;
  isLoading: boolean;
  scopedViews: ScopedView[];
  users: User[];
  onInteraction: (interaction: ScopedViewList.Interaction) => void;
}

type TableData = {
  id: string;
  createdBy: string;
  enabled?: string;
  isSharedType: boolean;
  name: string;
  timeLastModified: string;
  totalFilters: number;
  totalUsers: number;
};

export function ScopedViewList(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const usersKeyedByID = keyBy(props.users, "id");

  const columns: Column<TableData>[] = [
    {
      accessor: "name",
      Header: copyText.tableHeaderName,
      width: 150,
    },
    {
      accessor: "isSharedType",
      align: "center",
      Cell: ({ value }) =>
        value ? (
          <Tooltip content={value ? copyText.sharedTooltip : ""}>
            <Icon icon={faUserGroup} />
          </Tooltip>
        ) : null,
      disableSortBy: true,
      Header: "",
      sortType: (rowA) => (rowA.values.favorite ? 1 : -1),
      width: 75,
    },
    {
      accessor: "totalFilters",
      Header: copyText.tableHeaderTotalFilters,
      width: 60,
    },
    ...(props.isAdmin
      ? ([
          {
            accessor: "totalUsers",
            Header: copyText.tableHeaderTotalUsers,
            width: 60,
          },
          {
            accessor: "createdBy",
            Header: copyText.tableHeaderCreatedBy,
            width: 140,
          },
        ] as const)
      : ([
          {
            accessor: "enabled",
            Header: copyText.tableHeaderEnabled,
            width: 80,
          },
          {
            accessor: "createdBy",
            Header: copyText.tableHeaderCreatedBy,
            width: 140,
          },
        ] as const)),
    {
      accessor: "timeLastModified",
      Cell: ({ value }) => (
        <>{formatDate(new Date(value ? value : 0), "MM/dd/yyyy hh:mm a")}</>
      ),
      width: 100,
    },
    {
      id: "actionMenu",
      align: "center",
      NoAccessorCell: ({ row }) => (
        <ScopedViewListDropdown
          isShared={row.original.isSharedType}
          scopedViewID={row.original.id}
          onInteraction={props.onInteraction}
        />
      ),
      Header: "",
      width: 50,
    },
  ];

  const data = useMemo(
    () =>
      props.scopedViews.map((scopedView) => ({
        id: scopedView.id,
        createdBy: usersKeyedByID[scopedView.createdBy]?.email,
        name: scopedView.name,
        timeLastModified: scopedView.updatedAt ?? scopedView.createdAt,
        totalFilters: scopedView.filters.length,
        totalUsers: scopedView.userConfigs.length,
        ...(props.isAdmin
          ? {}
          : {
              enabled: scopedView.userConfigs.some(
                (userConfig) =>
                  userConfig.userID === authenticatedUser.id &&
                  userConfig.status === UserConfigStatus.ENABLED
              )
                ? copyText.enabledYes
                : copyText.enabledNo,
            }),
        isSharedType:
          scopedView.type === ScopedViewType.ADMIN && !props.isAdmin,
      })),
    [props.scopedViews, props.users]
  );

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{ sortBy: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

interface ScopedViewListDropwdownProps {
  isShared: boolean;
  scopedViewID: string;
  onInteraction: (interaction: ScopedViewList.Interaction) => void;
}

function ScopedViewListDropdown(
  props: ScopedViewListDropwdownProps
): JSX.Element {
  const options = [
    {
      label: copyText.actionMenuItemEditScopedView,
      onClick: () =>
        props.onInteraction({
          type: ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED,
          scopedViewID: props.scopedViewID,
        }),
    },
    {
      label: copyText.actionMenuItemMakeCopyScopedView,
      onClick: () =>
        props.onInteraction({
          type: ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED,
          scopedViewID: props.scopedViewID,
        }),
    },
    ...(props.isShared
      ? []
      : [
          {
            label: copyText.actionMenuItemDeleteScopedView,
            onClick: () =>
              props.onInteraction({
                type: ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED,
                scopedViewID: props.scopedViewID,
              }),
          },
        ]),
  ];

  return (
    <Dropdown options={options} placement="bottom-end">
      <ActionMenuButton />
    </Dropdown>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ScopedViewList {
  export const INTERACTION_DELETE_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED";
  export const INTERACTION_EDIT_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED";
  export const INTERACTION_MAKE_COPY_BUTTON_CLICKED =
    "ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED";

  interface InteractionDeleteButtonClicked {
    type: typeof ScopedViewList.INTERACTION_DELETE_BUTTON_CLICKED;
    scopedViewID: string;
  }

  interface InteractionEditButtonClicked {
    type: typeof ScopedViewList.INTERACTION_EDIT_BUTTON_CLICKED;
    scopedViewID: string;
  }
  interface InteractionMakeCopyButtonClicked {
    type: typeof ScopedViewList.INTERACTION_MAKE_COPY_BUTTON_CLICKED;
    scopedViewID: string;
  }

  export type Interaction =
    | InteractionEditButtonClicked
    | InteractionDeleteButtonClicked
    | InteractionMakeCopyButtonClicked;
}

export default ScopedViewList;
