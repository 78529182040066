import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import Switch from "@/ui-lib/components/Switch";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useState } from "react";
import copyText from "../../copyText";
import {
  BQ_FIELDS_REGEX,
  GCP_PROJECT_ID_REGEX,
  getErrorMessageIfPresent,
  validBigQueryLocations,
} from "./GCPIntegrationForm";
import { CarbonFootprintSource, GCPIntegration } from "./types";

interface Props {
  carbonEnabled: boolean;
  isLoading: boolean;
  integration?: GCPIntegration;
  onUpdate: (input: CarbonFootprintSource | null) => void;
}

interface State {
  inputExportDatasetId: string;
  inputExportProjectId: string;
  inputExportTableId: string;
  inputExportLocation: string;
  enabled: boolean;

  inputExportLocationText: string;
}

const initialState: State = {
  inputExportDatasetId: "",
  inputExportProjectId: "",
  inputExportTableId: "",
  inputExportLocation: "",
  inputExportLocationText: "",
  enabled: false,
};

export default function GCPCloudFormCarbon(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (!props.integration) {
      mergeState(initialState);
      return;
    }

    const exportSource = props.integration.config.carbonFootprintSource;

    mergeState({
      inputExportDatasetId: exportSource ? exportSource.datasetID : "",
      inputExportProjectId: exportSource ? exportSource.projectID : "",
      inputExportTableId: exportSource ? exportSource.tableID : "",
      inputExportLocation: exportSource ? exportSource.location : "",
      enabled: props.carbonEnabled,
    });
  }, [props.integration]);

  useEffect(() => {
    if (!state.enabled) {
      //Disabling Carbon Data Config
      props.onUpdate(null);
      return;
    }

    props.onUpdate({
      projectID: state.inputExportProjectId,
      datasetID: state.inputExportDatasetId,
      tableID: state.inputExportTableId,
      location: state.inputExportLocation,
    });
  }, [
    state.inputExportProjectId,
    state.inputExportDatasetId,
    state.inputExportTableId,
    state.inputExportLocation,
    state.enabled,
  ]);

  let carbonAccessError: string | undefined;

  if (props.integration) {
    carbonAccessError = getErrorMessageIfPresent(
      props.integration,
      CloudCapability.CARBON_FOOTPRINT
    );
  }

  const validBQLocationOptions = validBigQueryLocations.map((location) => ({
    label: location,
    value: location,
  }));

  return (
    <Box height={416} padding={theme.space_xs} scrollable>
      {carbonAccessError && (
        <Box
          backgroundColor={theme.feedback_warn_background}
          borderRadius={theme.borderRadius_2}
          marginBottom={theme.space_sm}
          padding={theme.space_sm}
        >
          <Text>{carbonAccessError}</Text>
        </Box>
      )}
      <Box>
        <Flex justifyContent="space-between" marginBottom={theme.space_md}>
          <Flex>
            <Text marginBottom={theme.space_sm}>
              {copyText.enableCarbonData}
            </Text>
            <Tooltip
              content={copyText.cloudHelpCarbonDataIngestion}
              icon={faInfoCircle}
            />
          </Flex>
          <Switch
            name="test"
            checked={state.enabled}
            onChange={(e) => mergeState({ enabled: e })}
          />
        </Flex>
      </Box>
      {state.enabled && (
        <>
          <FormField
            input={TextInput}
            caption={copyText.cloudHelpExportCarbonProjectId}
            label={copyText.cloudCarbonAttributeProjectId}
            value={state.inputExportProjectId}
            variant={
              carbonAccessError === undefined &&
              GCP_PROJECT_ID_REGEX.test(state.inputExportProjectId)
                ? "success"
                : "danger"
            }
            onChange={(e) =>
              mergeState({ inputExportProjectId: e.target.value })
            }
          />
          <FormField
            input={TextInput}
            caption={copyText.cloudHelpExportCarbonDatasetId}
            label={copyText.cloudCarbonAttributeDatasetId}
            value={state.inputExportDatasetId}
            variant={
              carbonAccessError === undefined &&
              BQ_FIELDS_REGEX.test(state.inputExportDatasetId)
                ? "success"
                : "danger"
            }
            onChange={(e) =>
              mergeState({ inputExportDatasetId: e.target.value })
            }
          />
          <FormField
            input={TextInput}
            caption={copyText.cloudHelpExportCarbonTableId}
            label={copyText.cloudCarbonAttributeTableId}
            value={state.inputExportTableId}
            variant={
              carbonAccessError === undefined &&
              BQ_FIELDS_REGEX.test(state.inputExportTableId)
                ? "success"
                : "danger"
            }
            onChange={(e) => mergeState({ inputExportTableId: e.target.value })}
          />
          <FormField
            caption={copyText.cloudHelpExportCarbonLocation}
            label={copyText.cloudCarbonAttributeLocation}
          >
            <Select
              inputValue={state.inputExportLocationText}
              isCreatable
              options={validBQLocationOptions}
              placeholder={copyText.cloudAttributeLabelConfigurationPlaceholder}
              value={{
                label: state.inputExportLocation,
                value: state.inputExportLocation,
              }}
              onChange={(option) =>
                option && mergeState({ inputExportLocation: option.value })
              }
            />
          </FormField>
        </>
      )}
    </Box>
  );
}
