import styled from "@emotion/styled";
import React from "react";

interface StyledImgProps {
  margin?: string;
}

const StyledImg = styled.img<StyledImgProps>`
  border-radius: 50%;
  margin: ${(props) => props.margin ?? ""};
`;

interface Props {
  height?: string;
  src?: string;
  width?: string;
  margin?: string;
}

export default function Avatar(props: Props): JSX.Element {
  let alt = "user";
  let src = "/assets/img/user.png";

  if (props.src) {
    alt = "profile picture";
    src = props.src;
  }

  return (
    <StyledImg
      alt={alt}
      height={props.width ?? "36px"}
      margin={props.margin}
      src={src}
      width={props.width ?? "36px"}
    />
  );
}
