import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { ReportEntity } from "@ternary/api-lib/core/types";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type Options = UseQueryOptions<number[], CoreAPIClientError>;
type Result = UseQueryResult<number[], CoreAPIClientError>;

export default function useGenerateReportPDF(
  report: ReportEntity,
  options?: Options
): Result {
  const client = useCoreAPIClient();
  const authenticatedUser = useAuthenticatedUser();

  return useQuery({
    queryKey: ["reports", report.id, "pdf", report],
    queryFn: () =>
      client.generateReportPDF(authenticatedUser.tenant.fsDocID, report.id),
    meta: { errorMessage: copyText.errorLoadingReportPDFMessage },
    ...options,
  });
}
