import NotConfiguredPlaceholder from "@/components/NotConfiguredPlaceholder";
import paths from "@/constants/paths";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import Dropdown from "@/ui-lib/components/Dropdown";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faChartLine, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { DurationType, WidgetType } from "@ternary/api-lib/constants/enums";
import {
  DashboardEntity,
  DashboardWidgetSpecUnion,
  ReportEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import {
  getDateRangeFromDashboard,
  getReportsWithModifiedDates,
} from "@ternary/api-lib/utils/ReportUtils";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { keyBy } from "lodash";
import React, { useState } from "react";
import usePrefetchHomeContainer from "../api/core/hooks/usePrefetchHomeContainer";
import commonCopyText from "../common.copyText";
import useGetBudgetsByTenantID from "../features/budget-management/hooks/useGetBudgetsByTenantID";
import BudgetViewContainer from "../features/reporting-engine/components/BudgetViewContainer";
import RealizedCommitmentSavingsContainer from "../features/reporting-engine/components/RealizedCommitmentSavingsContainer";
import ReportGrid from "../features/reporting-engine/components/ReportGrid";
import ReportViewContainer from "../features/reporting-engine/components/ReportViewContainer";
import SavingsOpportunityForm, {
  Action,
} from "../features/reporting-engine/components/SavingsOpportunityForm";
import { SavingsOpportunityListModal } from "../features/reporting-engine/components/SavingsOpportunityListModal";
import { SavingsOpportunityViewContainer } from "../features/reporting-engine/components/SavingsOpportunityViewContainer";
import treCopyText from "../features/reporting-engine/copyText";
import useGetDashboardByID from "../features/reporting-engine/hooks/useGetDashboardByID";
import useGetReportsByIDs from "../features/reporting-engine/hooks/useGetReportsByIDs";
import useGetSavingsOpportunityFiltersByTenantID from "../features/reporting-engine/hooks/useGetSavingsOpportunityFiltersByTenantID";
import useUpdateSavingsOpportunityFilter from "../features/reporting-engine/hooks/useUpdateSavingsOpportunityFilter";
import useAvailableGlobalDate, {
  GlobalDateResult,
} from "../hooks/useAvailableGlobalDate";

const defaultSavingOpp = [];
const copyText = { ...commonCopyText, ...treCopyText };
const MODAL_UPDATE_SAVING_OPP = "MODAL_UPDATE_SAVING_OPP";

type Interaction =
  | BudgetViewContainer.Interaction
  | RealizedCommitmentSavingsContainer.Interaction
  | ReportGrid.Interaction
  | ReportViewContainer.Interaction
  | SavingsOpportunityListModal.Interaction
  | SavingsOpportunityForm.Interaction
  | SavingsOpportunityViewContainer.Interaction;

interface State {
  actionPanelKey: Action | null;
  modalKey: string;
  selectedSavingOppID: string | null;
}

const initialState: State = {
  actionPanelKey: null,
  modalKey: "",
  selectedSavingOppID: null,
};

export default function HomeContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const globalDate = useAvailableGlobalDate();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  // NOTE: Temporary solution to get more responsive data for insights meters
  usePrefetchHomeContainer();

  //
  // Queries
  //

  if (!gatekeeper.hasConfiguredDataIntegration) {
    return <NotConfiguredPlaceholder />;
  }

  const { data: dashboard, isLoading: isLoadingDashboard } =
    useGetDashboardByID(authenticatedUser.settings.preferredDashboardID);

  const widgetSpecs = (dashboard?.widgetSpecs ??
    []) as DashboardWidgetSpecUnion[];

  const reportSpecs = widgetSpecs.filter(
    (spec) => spec.type === WidgetType.REPORT
  );

  const reportIDs = reportSpecs.map(({ reportID }) => reportID);

  const { data: preModificationReports = [], isLoading: isLoadingReports } =
    useGetReportsByIDs(reportIDs);

  const { data: budgets = [], isLoading: isLoadingBudgets } =
    useGetBudgetsByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: savingOppFilters = defaultSavingOpp,
    isLoading: isLoadingSavingOppFilters,
    refetch: refetchFilters,
  } = useGetSavingsOpportunityFiltersByTenantID(authenticatedUser.tenant.id);

  const modifiedReports = updateHomePageReportDates(
    dashboard,
    preModificationReports,
    globalDate
  );

  const reportsKeyedByID = keyBy(modifiedReports, "id");

  const reports = reportSpecs.map((spec) => reportsKeyedByID[spec.reportID]);

  //
  // Mutations
  //

  const { mutate: updateSavingsOpportunityFilter } =
    useUpdateSavingsOpportunityFilter({
      onError: () => {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
        });
        postAlert({
          message: copyText.errorUpdateSavingOpportunityMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchFilters();
        mergeState({
          actionPanelKey: null,
          modalKey: "",
          selectedSavingOppID: null,
        });

        postAlert({
          message: copyText.SUCCESS_SAVING_OPP_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case BudgetViewContainer.INTERACTION_BUDGET_NAME_CLICKED: {
        const { budgetID } = interaction;

        navigate(paths._budgets, { searchParams: { budget_id: budgetID } });
        return;
      }
      case ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED: {
        const { reportID, report } = interaction;

        navigate(paths._reportBuilder.replace(":reportID", reportID), {
          state: { report },
        });
        return;
      }
      case SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE_SAVING_OPP,
          actionPanelKey: Action.UPDATE,
          selectedSavingOppID: interaction.savingsOpportunityFilterID,
        });
        return;
      }
      case SavingsOpportunityListModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({
          actionPanelKey: null,
          modalKey: "",
        });
        return;
      }
      case SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        updateSavingsOpportunityFilter({
          filterID: interaction.savingOpportunityFilterID,
          category: interaction.category,
          cloudProviderType: interaction.cloudProviderType,
          name: interaction.name,
          savingsType: interaction.savingsType,
          serviceType: interaction.serviceType,
        });

        return;
      }
    }
  }

  //
  // Render
  //

  const options = [
    {
      label: copyText.actionMenuItemManageDashboard,
      locked: !authenticatedUser.settings.preferredDashboardID,
      onClick: () =>
        navigate(
          paths._dashboard.replace(
            ":dashboardID",
            authenticatedUser.settings.preferredDashboardID
          )
        ),
    },
  ];

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_UPDATE_SAVING_OPP: {
        return (
          <SavingsOpportunityListModal
            actionPanelKey={Action.UPDATE}
            isLoading={isLoadingSavingOppFilters}
            filters={savingOppFilters ?? []}
            selectedSavingOppID={state?.selectedSavingOppID}
            onInteraction={handleInteraction}
          />
        );
      }
      default:
        return null;
    }
  }

  const canRenderReportList =
    !dashboard ||
    reports.length === 0 ||
    isLoadingReports ||
    isLoadingBudgets ||
    isLoadingSavingOppFilters;

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Text appearance="h3">{dashboard?.name}</Text>
        <Dropdown options={options} placement="bottom-end">
          <Button iconStart={<Icon icon={faEllipsis} />} size="small" />
        </Dropdown>
      </Flex>
      {state.modalKey && renderModal()}
      {canRenderReportList ? (
        <Box height={800} marginTop={theme.space_lg}>
          <EmptyPlaceholder
            icon={faChartLine}
            loading={isLoadingDashboard || isLoadingReports}
            text={copyText.emptyReports}
            skeletonVariant="cards_large"
          />
        </Box>
      ) : (
        <ReportGrid
          budgets={budgets}
          dashboardID={dashboard.id}
          editLayout={false}
          reports={reports}
          widgetSpecs={widgetSpecs}
          filters={savingOppFilters}
          onInteraction={handleInteraction}
        />
      )}
    </Box>
  );
}

function updateHomePageReportDates(
  dashboard: DashboardEntity | undefined,
  dashboardReports: ReportEntity[],
  globalDate: GlobalDateResult
): ReportEntity[] {
  if (globalDate.enabled) {
    return getReportsWithModifiedDates(dashboardReports, {
      dateRange: globalDate.date,
      durationType: globalDate.durationType,
      invoiceMonthRange: null,
    });
  }

  if (!dashboard || !dashboard.durationType) {
    return dashboardReports;
  }

  return getReportsWithModifiedDates(dashboardReports, {
    dateRange:
      dashboard.durationType === DurationType.CUSTOM
        ? getDateRangeFromDashboard(dashboard)
        : null,
    durationType: dashboard.durationType,
    invoiceMonthRange:
      dashboard.durationType === DurationType.INVOICE
        ? getDateRangeFromDashboard(dashboard)
        : null,
  });
}
