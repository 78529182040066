import React, { useContext } from "react";

const context = React.createContext(false);

type Props = {
  isTabHidden: boolean;
  children: React.ReactNode;
};

export default function IsInHiddenTabProvider(props: Props) {
  return (
    <context.Provider value={props.isTabHidden}>
      {props.children}
    </context.Provider>
  );
}

export function useIsInHiddenTab() {
  return useContext(context);
}
