import { Theme, useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";
import { keyBy } from "lodash";
import React, { useDeferredValue, useMemo } from "react";
import { Tooltip, TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { RawValue } from "../../analytics/types";
import Box from "../components/Box";
import Divider from "../components/Divider";
import Flex from "../components/Flex";
import Icon from "../components/Icon";
import Text from "../components/Text";
import copyText from "../copyText";
import { ECO_DATA_VIZ_COLORS } from "../theme/default";
import ChartDataManager, {
  removeGroupingsWithNoDataAtXAxisValue,
} from "../utils/ChartDataManager";
import { formatPercentage } from "../utils/formatNumber";
import { addRawValues } from "../utils/sort";
import { Dimension, Measure } from "./types";
import {
  COMPARISON_KEY,
  DEFAULT_X_AXIS_KEY,
  PERCENT_DIFFERENCE_KEY,
  PREVIOUS_TIMESTAMP_KEY,
  RAW_DIFFERENCE_KEY,
  formatMeasureValueWithUnit,
} from "./utils";

type RechartsProps = TooltipProps<ValueType, NameType>;

type Props = {
  dataManager: ChartDataManager;
  hideSubtotal?: boolean;
  hideTotal?: boolean;
  hoveredChartKey: string | null;
  isImpactMode?: boolean;
  maxRows?: number;
  readableKeys?: { [key: string]: string };
  xAxisFormatter?: (value: string) => string;
} & RechartsProps;

function TooltipTable(props: Props) {
  const theme = useTheme();

  const xAxisValueProp: string | null = props.label ?? null;
  const xAxisValue = useDeferredValue(xAxisValueProp);
  const isStale = xAxisValue !== xAxisValueProp;
  const active = !!props.active;
  const maxRows = props.maxRows ?? 20;

  const groupingTable = useMemo(() => {
    const groupingTable = props.dataManager.getGroupingTable();

    if (!xAxisValue) return groupingTable;

    return removeGroupingsWithNoDataAtXAxisValue({
      dataManager: props.dataManager,
      groupingTable,
      xAxisValue,
    });
  }, [props.dataManager, xAxisValue]);

  if (!active || xAxisValue === null) {
    return null;
  }

  const label = props.xAxisFormatter
    ? props.xAxisFormatter(xAxisValue)
    : xAxisValue;

  const chartDataKeyedByTimestamp = keyBy(
    props.dataManager.chartData,
    DEFAULT_X_AXIS_KEY
  );

  const isComparisonData = groupingTable.measureHeaders.some((measure) =>
    measure.name.includes(COMPARISON_KEY)
  );
  const isTimeSeriesData = new Date(xAxisValue).getTime() > 0;

  const datum = chartDataKeyedByTimestamp[xAxisValue];

  const previousLabel = props.xAxisFormatter
    ? isTimeSeriesData && isComparisonData && datum
      ? props.xAxisFormatter(String(datum[PREVIOUS_TIMESTAMP_KEY]))
      : props.xAxisFormatter(xAxisValue)
    : xAxisValue;

  const chartColors = props.isImpactMode
    ? ECO_DATA_VIZ_COLORS
    : theme.data_visualization_colors;

  const getChartKeyColor = (chartKey: string) => {
    if (props.dataManager.isExcluded(chartKey)) {
      return theme.tooltip_disabled_cell_color;
    }

    let index = [...props.dataManager.sortedChartKeys]
      .reverse()
      .findIndex((otherKey) => otherKey === chartKey);

    if (chartKey.includes(COMPARISON_KEY)) {
      const newIndex = [...props.dataManager.sortedChartKeys]
        .reverse()
        .indexOf(chartKey.replace(COMPARISON_KEY, ""));

      if (newIndex !== -1) {
        index = newIndex;
      }
    }

    if (index < 0) return "#FF00FF";

    return chartColors[index % chartColors.length];
  };

  const getMeasureString = (chartKey: string) => {
    const measure = props.dataManager.getMeasure(chartKey);
    const datum = props.dataManager.getRawData(chartKey, xAxisValue);

    if (!datum || !measure) return "--";

    const value = datum[measure.name];

    if (typeof value !== "number") return "--";

    if (
      measure.name === RAW_DIFFERENCE_KEY &&
      typeof datum[PERCENT_DIFFERENCE_KEY] === "number"
    ) {
      return `${formatMeasureValueWithUnit({
        value,
        unit: measure.unit,
      }).toString()} (${formatPercentage(datum[PERCENT_DIFFERENCE_KEY])})`;
    }

    return formatMeasureValueWithUnit({
      value,
      unit: measure.unit,
    });
  };

  const getReadableKey = (key: string) => {
    const readableKeys = props.readableKeys ?? {};
    if (key in readableKeys) return readableKeys[key];
    return key;
  };

  const getSubtotals = () => {
    const rawData =
      xAxisValue !== null
        ? props.dataManager.getRawDataAtXAxisValue(xAxisValue)
        : null;

    if (rawData === null) return [];

    return props.dataManager.measures.map((measure) => ({
      measure,
      value: rawData.reduce(
        (accum: RawValue, data) => addRawValues(accum, data[measure.name]),
        null
      ),
    }));
  };

  const getTotal = () => {
    return getSubtotals().reduce(
      (accum: RawValue, subtotal) => addRawValues(accum, subtotal.value),
      null
    );
  };

  const isUniformUnit =
    props.dataManager.measures.length > 0 &&
    props.dataManager.measures.every(
      (measure) => measure.unit === props.dataManager.measures[0].unit
    );

  const uniformUnit = props.dataManager.measures[0].unit;

  const showSubtotal =
    !props.hideSubtotal &&
    props.dataManager.dimensions.length > 0 &&
    props.dataManager.measures.length >= 2;
  const showTotal =
    !props.hideTotal &&
    isUniformUnit &&
    (props.dataManager.dimensions.length > 0 ||
      props.dataManager.measures.length > 0);

  const subtotals = getSubtotals();
  const total = getTotal();

  const { hiddenRowCount, visibleRowKeys } = getTruncation(
    groupingTable.groupingKeys,
    maxRows
  );

  // "isOther" can only be true if xAxisKey isn't time-series
  const isOther = label === copyText.dimensionOtherNotShown;

  return (
    <Box
      backgroundColor={theme.tooltip_background_color}
      padding={theme.space_xs}
      margin={theme.space_xl}
      maxHeight="600px"
      overflow="hidden"
      zIndex={theme.zIndex_800}
    >
      <Box marginBottom={theme.space_xxs}>
        <Text color={theme.tooltip_text_color}>{isStale ? "---" : label}</Text>
      </Box>

      {isOther && (
        <Box marginBottom={theme.space_xxs}>
          <Text
            fontSize={theme.fontSize_small}
            color={theme.tooltip_text_color}
          >
            {copyText.otherColumnMessage}
          </Text>
        </Box>
      )}

      <table>
        <thead>
          <tr>
            {groupingTable.dimensionHeaders.map(
              (dimension, index, { length }) => (
                <td key={`${dimension.name} :: ${index}`}>
                  <Box paddingRight={index !== length - 1 ? theme.space_xs : 0}>
                    <Text
                      bold
                      color={theme.tooltip_text_color}
                      fontSize={theme.fontSize_small}
                    >
                      {getReadableKey(dimension.name)}
                    </Text>
                  </Box>
                </td>
              )
            )}

            {groupingTable.measureHeaders.map((measure, index) => (
              <td key={`${measure.name} :: ${index}`}>
                <Flex paddingLeft={theme.space_xs} justifyContent="flex-end">
                  <Text
                    align="right"
                    bold
                    color={theme.tooltip_text_color}
                    fontSize={theme.fontSize_small}
                  >
                    {`${getReadableKey(measure.name)} ${
                      isComparisonData
                        ? getComparisonLabel(
                            measure.name,
                            isTimeSeriesData,
                            label,
                            previousLabel
                          )
                        : ""
                    }`}
                  </Text>
                </Flex>
              </td>
            ))}
          </tr>
        </thead>

        <tbody>
          {visibleRowKeys.map((groupingKey, rowIndex) => (
            <tr key={groupingKey}>
              {groupingTable.dimensionRows[rowIndex].map(
                ({ dimension, value }, index, { length }) => (
                  <td key={dimension.name}>
                    <Box
                      paddingRight={index !== length - 1 ? theme.space_xs : 0}
                    >
                      <Text
                        color={
                          props.hoveredChartKey &&
                          props.dataManager.isChartKeyInGrouping(
                            props.hoveredChartKey,
                            groupingKey
                          )
                            ? theme.tooltip_text_color_hover
                            : theme.tooltip_text_color
                        }
                        fontSize={theme.fontSize_small}
                      >
                        {value}
                      </Text>
                    </Box>
                  </td>
                )
              )}

              {groupingTable.measureRows[rowIndex].map(({ chartKey }) => {
                const background = chartKey.includes(COMPARISON_KEY)
                  ? `repeating-linear-gradient(45deg,${getChartKeyColor(
                      chartKey
                    )}, ${getChartKeyColor(
                      chartKey
                    )} 5px, white 5px, white 10px)`
                  : getChartKeyColor(chartKey);

                const measureString = getMeasureString(chartKey);

                return (
                  <td key={chartKey}>
                    <Flex
                      paddingLeft={theme.space_xs}
                      justifyContent="flex-end"
                    >
                      <Flex
                        borderBottom={
                          props.hoveredChartKey === chartKey
                            ? `1px solid ${theme.tooltip_text_color}`
                            : `1px solid ${theme.tooltip_background_color}`
                        }
                        paddingHorizontal={theme.space_xxs}
                        transition="border-color 0.2s linear"
                      >
                        <Text
                          color={
                            props.hoveredChartKey === chartKey
                              ? theme.tooltip_text_color_hover
                              : theme.tooltip_text_color
                          }
                          fontSize={theme.fontSize_small}
                        >
                          {measureString}
                        </Text>
                        <Flex alignItems="center" paddingLeft={theme.space_xs}>
                          {chartKey.includes(RAW_DIFFERENCE_KEY) ? (
                            <Icon
                              color="white"
                              icon={
                                measureString.includes("(0%)") ||
                                measureString.includes("(-- %)")
                                  ? faEquals
                                  : measureString.includes("-")
                                    ? faArrowDown
                                    : faArrowUp
                              }
                            />
                          ) : (
                            <Box
                              background={background}
                              height={`calc(${theme.space_xxs} * 2)`}
                              width={`calc(${theme.space_xxs} * 2)`}
                            />
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </td>
                );
              })}
            </tr>
          ))}

          {hiddenRowCount > 0 && (
            <tr>
              <td
                colSpan={
                  props.dataManager.dimensions.length +
                  props.dataManager.measures.length
                }
              >
                <Text
                  color={theme.tooltip_text_color}
                  fontSize={theme.fontSize_small}
                  align="right"
                >
                  {copyText.numRowsHidden.replace(
                    "%COUNT%",
                    String(hiddenRowCount)
                  )}
                </Text>
              </td>
            </tr>
          )}

          {(showSubtotal || showTotal) && (
            <tr>
              <td
                colSpan={
                  props.dataManager.dimensions.length +
                  props.dataManager.measures.length
                }
              >
                <Divider
                  color={theme.tooltip_text_color}
                  margin={theme.space_xs}
                />
              </td>
            </tr>
          )}

          {showSubtotal && (
            <tr>
              <td colSpan={props.dataManager.dimensions.length}>
                <Text
                  align="right"
                  color={theme.tooltip_text_color}
                  fontSize={theme.fontSize_small}
                >
                  Subtotals:
                </Text>
              </td>

              {subtotals.map(({ measure, value }) => {
                if (measure.name.includes(RAW_DIFFERENCE_KEY)) return null;
                return (
                  <td key={measure.name}>
                    (
                    <Text
                      align="right"
                      color={theme.tooltip_text_color}
                      fontSize={theme.fontSize_small}
                    >
                      {typeof value === "number"
                        ? formatMeasureValueWithUnit({
                            value,
                            unit: measure.unit,
                          })
                        : "--"}
                    </Text>
                    )
                  </td>
                );
              })}
            </tr>
          )}

          {showTotal && (
            <tr>
              <td
                colSpan={
                  props.dataManager.dimensions.length > 0
                    ? props.dataManager.dimensions.length
                    : props.dataManager.measures.length - 1
                }
              >
                <Text
                  align="right"
                  color={theme.tooltip_text_color}
                  fontSize={theme.fontSize_small}
                >
                  Total:
                </Text>
              </td>

              <td
                colSpan={
                  props.dataManager.dimensions.length > 0
                    ? props.dataManager.measures.length
                    : 1
                }
              >
                <Text
                  align="right"
                  color={theme.tooltip_text_color}
                  fontSize={theme.fontSize_small}
                >
                  {typeof total === "number"
                    ? formatMeasureValueWithUnit({
                        value: total,
                        unit: uniformUnit,
                      })
                    : "--"}
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
}

function getComparisonLabel(
  measureName: string,
  isTimeSeries: boolean,
  label: string,
  previousLabel: string
) {
  if (measureName.includes(RAW_DIFFERENCE_KEY) || !isTimeSeries) return "";

  return `(${measureName.includes(COMPARISON_KEY) ? previousLabel : label})`;
}

// Note: This has to be a regular function and not a react component
//       otherwise Recharts won't render it properly
export function getTooltip(props: Props, theme: Theme) {
  return (
    <Tooltip
      content={
        <TooltipTable
          dataManager={props.dataManager}
          hideSubtotal={props.hideSubtotal}
          hideTotal={props.hideTotal}
          hoveredChartKey={props.hoveredChartKey}
          isImpactMode={props.isImpactMode}
          maxRows={props.maxRows}
          readableKeys={props.readableKeys}
          xAxisFormatter={props.xAxisFormatter}
        />
      }
      cursor={{ stroke: theme.chart_cursor_line_color }}
      wrapperStyle={{ outline: "none", zIndex: theme.zIndex_800 }}
    />
  );
}

function getTruncation(allGroupingKeys: string[], maxRows?: number) {
  const visibleRowCount = Math.min(maxRows ?? Infinity, allGroupingKeys.length);
  const visibleRowKeys = allGroupingKeys.slice(0, visibleRowCount);
  const hiddenRowCount = allGroupingKeys.length - visibleRowCount;

  return hiddenRowCount <= 1
    ? { hiddenRowCount: 0, visibleRowKeys: allGroupingKeys }
    : { hiddenRowCount, visibleRowKeys };
}

TooltipTable.INTERACTION_COLOR_CLICKED =
  `TooltipTable.INTERACTION_COLOR_CLICKED` as const;

interface InteractionColorClicked {
  type: typeof TooltipTable.INTERACTION_COLOR_CLICKED;
  chartKey: string;
  dimensions: Dimension[];
  dimensionValues: string[];
  measure: Measure;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace TooltipTable {
  export type Interaction = InteractionColorClicked;
}

export default TooltipTable;
