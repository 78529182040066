import { createStructParam } from "@/lib/use-query-params";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AzureAKSClustersEntity } from "../types";

type Props = {
  clusters: AzureAKSClustersEntity[];
  isLoadingClusters: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureAKSClustersEntity>();

export function AzureKubernetesClustersTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const columns = useMemo(
    () => [
      ...(
        [
          ["cloudId", 120],
          ["clusterName", 120],
          ["resourceGroupName", 160],
          ["subscriptionName", 160],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureKubernetesTableInstanceHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text truncate>
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      ...(["nodepool", "instanceType"] as const).map((dimensionKey) => {
        const headerKey: keyof typeof copyText = `azureKubernetesTableInstanceHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          header: copyText[headerKey],
          meta: { align: "center" },
        });
      }),

      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureComputeTableHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      ...(
        [
          ["instanceHours", 100],
          ["estimatedCpuCoreHoursAvailable", 100],
          ["upperBoundCpuCoreHoursUsed", 100],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureKubernetesTableInstanceHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => formatNumber(getValue()),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("estimatedRssByteHoursAvailable", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header:
          copyText.azureKubernetesTableInstanceHeader_estimatedRssByteHoursAvailable,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("upperBoundRssByteHoursUsed", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header:
          copyText.azureKubernetesTableInstanceHeader_upperBoundRssByteHoursUsed,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.clusters}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingClusters}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}
