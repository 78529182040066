import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

const RESOURCE_LEVEL_BILLING = "Access BigQuery Resource-Level Billing Data";

export type DataIntegrationGates = ReturnType<typeof dataIntegrationGates>;

export default function dataIntegrationGates(params: GateGetterParameters) {
  return {
    hasConfiguredDataIntegration: (() => {
      return params.integrations.length > 0;
    })(),
    isConfiguredResourceLevelBilling: (() =>
      params.integrations.some((integration) => {
        if (integration.providerType === CloudProviderType.GCP) {
          return integration.validations.some(
            (validation) =>
              validation.name === RESOURCE_LEVEL_BILLING && validation.success
          );
        }

        return false;
      }))(),
  };
}
