import { DateHelper } from "@/lib/dates";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React from "react";
import copyText from "../../copyText";
import { fiscalDateFns, FiscalYearsData } from "../../hooks/useFiscalYearsData";

type Props = {
  fiscalYearsData: FiscalYearsData;
  onInteraction: (interaction: TodayInfo.Interaction) => void;
};

function TodayInfo(props: Props) {
  const theme = useTheme();

  const now = new DateHelper().date;
  const nowString = fiscalDateFns.dateToString(now);

  const fiscalPeriodCopyText = getFiscalPeriodCopyText(
    nowString,
    props.fiscalYearsData
  );

  if (!fiscalPeriodCopyText) {
    return (
      <Box>
        <Text>{copyText.fiscalCalendarTodayInfoNoCalendar}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text
        appearance="link"
        as="div"
        onClick={() =>
          props.onInteraction({ type: TodayInfo.INTERACTION_CLICK_TODAY })
        }
      >
        <Box fontWeight={theme.fontWeight_bold} lineHeight={1.3}>
          {copyText.fiscalCalendarTodayInfoCurrentPeriod}
        </Box>

        <Box>{fiscalPeriodCopyText}</Box>
      </Text>

      <Text
        fontWeight={theme.fontWeight_bold}
        lineHeight={1.3}
        marginTop={theme.space_xs}
      >
        {copyText.fiscalCalendarTodayInfoCurrentDate}
      </Text>
      <Text>{format(now, "MM/dd/yy")}</Text>
    </Box>
  );
}

TodayInfo.INTERACTION_CLICK_TODAY =
  "TodayInfo.INTERACTION_CLICK_TODAY" as const;

interface InteractionClickToday {
  type: typeof TodayInfo.INTERACTION_CLICK_TODAY;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace TodayInfo {
  export type Interaction = InteractionClickToday;
}

export default TodayInfo;

function getFiscalPeriodCopyText(
  dateString: string,
  fiscalYearsData: FiscalYearsData
) {
  const thisYear =
    Object.values(fiscalYearsData.yearMap).find(
      (year) =>
        fiscalDateFns.diffDays(dateString, year.startDate) >= 0 &&
        fiscalDateFns.diffDays(year.endDate, dateString) >= 0
    ) ?? null;

  if (!thisYear) return null;

  const thisQuarter =
    [thisYear.Q1, thisYear.Q2, thisYear.Q3, thisYear.Q4].find(
      (quarter) =>
        fiscalDateFns.diffDays(dateString, quarter.startDate) >= 0 &&
        fiscalDateFns.diffDays(quarter.endDate, dateString) >= 0
    ) ?? null;

  if (!thisQuarter) return null;

  const thisMonth =
    thisQuarter.months.find(
      (month) =>
        fiscalDateFns.diffDays(dateString, month.startDate) >= 0 &&
        fiscalDateFns.diffDays(month.endDate, dateString) >= 0
    ) ?? null;

  if (!thisMonth) return null;

  const FY = thisYear.yearNumber;
  const FQ = thisQuarter.quarterNumber;
  const FM = thisMonth.monthNumber;
  const FW = String(
    Math.floor(fiscalDateFns.diffDays(thisYear.startDate, dateString) / 7)
  );

  return copyText.fiscalCalendarTodayInfoFiscalPeriod
    .replace("%FY%", FY)
    .replace("%FQ%", FQ)
    .replace("%FM%", FM)
    .replace("%FW%", FW);
}
