import { useTheme } from "@emotion/react";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import Card from "../../../components/Card";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";

type Integration = {
  id: string;
  channelID: string;
  teamID: string;
  workspaceURL: string;
};

interface Props {
  integration?: Integration;
  onInteraction: (interaction: SlackIntegrationCard.Interaction) => void;
}

function SlackIntegrationCard(props: Props): JSX.Element {
  const theme = useTheme();

  function handleCreate() {
    if (!props.onInteraction) return;

    props.onInteraction({
      type: SlackIntegrationCard.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED,
    });
  }

  //
  // Early Exit If Not Configured Yet
  //

  if (!props.integration) {
    return (
      <Card
        height={300}
        marginHorizontal={theme.space_sm}
        marginVertical={theme.space_xs}
        padding={theme.space_md}
        width={300}
      >
        <Flex height="100%" direction="column" width="100%">
          <Text appearance="h3">{copyText.slackIntegrationTitle}</Text>
          <Flex
            alignItems="center"
            direction="column"
            flexGrow={1}
            justifyContent="center"
          >
            <Text>{copyText.slackIntegrationNotConfigured}</Text>
            <Text>{copyText.slackIntegrationInstructions}</Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <input
              alt={copyText.slackIntegrationTitle}
              height="40"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              type="image"
              width="139"
              onClick={handleCreate}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  //
  // If Already Configured - Probably a new component in the future.
  //

  const options = [
    {
      label: copyText.optionLabelDeleteIntegration,
      onClick: () =>
        props.onInteraction({
          type: SlackIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED,
        }),
    },
    {
      label: copyText.optionLabelUpdateIntegration,
      onClick: () =>
        props.onInteraction({
          type: SlackIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED,
        }),
    },
  ];

  return (
    <>
      <Card
        height={300}
        marginHorizontal={theme.space_sm}
        marginVertical={theme.space_xs}
        padding={theme.space_md}
        width={300}
      >
        <Flex direction="column" justifyContent="space-between">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_xs}
          >
            <Text appearance="h2">{copyText.slackIntegrationTitle}</Text>
            <Dropdown options={options} placement="bottom-end">
              <Button
                iconStart={<Icon icon={faEllipsisVertical} />}
                size="small"
              />
            </Dropdown>
          </Flex>
          <Text bold>{copyText.slackIntegrationWorkspaceURLLabel}</Text>
          <Text marginBottom={theme.space_md}>
            {props.integration.workspaceURL}
          </Text>
          <Text bold>{copyText.slackIntegrationAttributeTeamURL}</Text>
          <Text marginBottom={theme.space_md}>{props.integration.teamID}</Text>
          <Text bold>{copyText.slackIntegrationAttributeChannelID}</Text>
          <Text marginBottom={theme.space_md}>
            {props.integration.channelID}
          </Text>
        </Flex>
      </Card>
    </>
  );
}

SlackIntegrationCard.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED =
  "SlackIntegrationCard.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED" as const;
SlackIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED =
  "SlackIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED" as const;
SlackIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED =
  "SlackIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED" as const;

type InteractionCreateSlackIntegration = {
  type: typeof SlackIntegrationCard.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED;
};

type InteractionDeleteIntegrationButtonClicked = {
  type: typeof SlackIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED;
};

type InteractionUpdateSlackIntegration = {
  type: typeof SlackIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace SlackIntegrationCard {
  export type Interaction =
    | InteractionCreateSlackIntegration
    | InteractionDeleteIntegrationButtonClicked
    | InteractionUpdateSlackIntegration;
}

export default SlackIntegrationCard;
