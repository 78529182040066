import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type ApiKeyEntity = Awaited<ReturnType<CoreAPIClient["getApiKeys"]>>[0];

export default function useGetApiKeys(
  options?: UseQueryOptions<ApiKeyEntity[], CoreAPIClientError>
): UseQueryResult<ApiKeyEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["apiKeys"],
    queryFn: () => client.getApiKeys(),
    ...options,
  });
}
