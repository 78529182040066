import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import GlobalStyles from "./GlobalStyles";
import { Theme } from "./default";

interface Props {
  theme: Theme;
}

export default function ThemeProvider(
  props: PropsWithChildren<Props>
): JSX.Element {
  return (
    <EmotionThemeProvider theme={props.theme}>
      <GlobalStyles />
      {props.children}
    </EmotionThemeProvider>
  );
}
