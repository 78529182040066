import SelectCheckbox from "@/ui-lib/components/SelectCheckbox";
import { Option } from "@/ui-lib/components/SelectDropdown";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  GcpCommitmentRecommendationTierType,
  GcpCommitmentServiceType,
  GcpCommitmentType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { CudRecommendationEntity } from "@ternary/api-lib/core/types";
import { ChartWrapper } from "@ternary/api-lib/ui-lib/charts/ChartWrapper";
import TimeSeriesChartTooltip from "@ternary/api-lib/ui-lib/charts/TimeSeriesChartTooltip";
import { useHorizontalLine } from "@ternary/api-lib/ui-lib/charts/TooltipUtils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { getFormatForGranularity } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatCurrencyRounded } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import {
  areaStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/web-ui-lib/charts/styles";
import {
  DEFAULT_X_AXIS_KEY,
  formatTimestamp,
} from "@ternary/web-ui-lib/charts/utils";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import { isString, noop } from "lodash";
import React, { useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart as _AreaChart,
} from "recharts";
import copyText from "../../copyText";
import {
  CudInventoryFilters,
  CudOnDemandChartDatum,
  GCPBillingEntity,
} from "../types";
import { getReadableGcpCommitmentServiceTypeStrings } from "../utils";

interface Props {
  chartOptions: {
    accountOptions: Option[];
    serviceOptions: Option[];
  };
  commitmentType: GcpCommitmentType;
  costChartData: CudOnDemandChartDatum[];
  coverableCost: GCPBillingEntity[];
  cudInventoryFilters: CudInventoryFilters;
  granularity: TimeGranularity;
  isLoading: boolean;
  recommendations: CudRecommendationEntity[];
  serviceFilters: string[];
  onInteraction: (interaction: CudVisibilityChart.Interaction) => void;
}

type ChartDatum = {
  [key: string]: string | number | null;
};

const StyledBox = styled(Box)`
  .recharts-legend-wrapper {
    max-height: 8rem;
    overflow-y: auto;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.space_xs};

    .recharts-symbols {
      d: path("M -8 -16 h 32 v 32 h -32 Z");
    }
  }

  span.recharts-legend-item-text {
    color: ${(props) => props.theme.text_color} !important;
    display: block;
    font-size: ${({ theme }) => theme.fontSize_ui};
    /* Bring in the below if legend key lengths become an issue */
    /* max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }

  .recharts-default-legend {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    justify-content: center;

    li {
      cursor: pointer;
    }
  }
`;
const AXIS_CHARACTER_WIDTH = 7;
const AXIS_MAX_LEFT_MARGIN = AXIS_CHARACTER_WIDTH * 10;

function CudVisibilityChart(props: Props): JSX.Element {
  const theme = useTheme();
  const horizontalLineElement = useHorizontalLine();
  const [excludedKeysMap, setExcludedKeysMap] = useState({
    coverableCost: false,
    existingCoverage: false,
    resourceRecommendation: false,
    spendMaximumSavingsRecommendation: false,
    totalSpend: false,
  });

  const chartData = useMemo(() => {
    let recommendations = props.recommendations;

    if (props.costChartData.length === 0 && props.coverableCost.length === 0) {
      return [];
    }

    // Service Filter
    if (props.serviceFilters) {
      recommendations = recommendations.filter((recommendation) => {
        return props.serviceFilters.includes(recommendation.serviceType);
      });
    }

    const recommendationValues = recommendations.reduce(
      (accum, rec) => {
        const recValue =
          props.granularity === TimeGranularity.DAY
            ? rec.targetCommitmentValueCostPerHour * 24
            : rec.targetCommitmentValueCostPerHour;

        if (rec.tier === GcpCommitmentRecommendationTierType.FULL_UTILIZATION) {
          accum.resourceValue += recValue;
        } else if (
          rec.tier === GcpCommitmentRecommendationTierType.MAXIMUM_SAVINGS
        ) {
          accum.spendMaximumSavingsValue += recValue;
        } else {
          accum.resourceValue += recValue;
        }
        return accum;
      },
      {
        resourceValue: 0,
        spendMaximumSavingsValue: 0,
        spendFullUtilizationValue: 0,
      }
    );

    const updatedChartData = handleChangeChartData({
      costChartData: props.costChartData,
      coverableCost: props.coverableCost,
      cudInventoryFilters: props.cudInventoryFilters,
      serviceFilters: props.serviceFilters,
    });

    const dataWithExcludedRemoved: ChartDatum[] = updatedChartData.map(
      (datum) => {
        return {
          coverableCost: excludedKeysMap.coverableCost
            ? 0
            : datum.coverableCost,
          existingCoverage: excludedKeysMap.existingCoverage
            ? 0
            : datum.existingCoverage,
          timestamp: datum.timestamp,
          totalSpend: excludedKeysMap.totalSpend ? 0 : datum.totalSpend,
          resourceRecommendation: excludedKeysMap.resourceRecommendation
            ? 0
            : recommendationValues.resourceValue,
          spendMaximumSavingsRecommendation:
            excludedKeysMap.spendMaximumSavingsRecommendation
              ? 0
              : recommendationValues.spendMaximumSavingsValue,
        };
      }
    );

    return dataWithExcludedRemoved;
  }, [
    props.commitmentType,
    props.costChartData,
    props.coverableCost,
    props.granularity,
    props.serviceFilters,
    excludedKeysMap,
  ]);

  const toggleKey = (key: string) =>
    setExcludedKeysMap((keyMap) => ({ ...keyMap, [key]: !keyMap[key] }));

  let leftMargin = AXIS_CHARACTER_WIDTH * Math.round(150).toString().length;
  if (leftMargin > AXIS_MAX_LEFT_MARGIN) {
    leftMargin = AXIS_MAX_LEFT_MARGIN;
  }

  const dateFormat = getFormatForGranularity(props.granularity);

  const readableKeys = {
    totalSpend: copyText.cudChartOnDemandTotalSpend,
    coverableCost: copyText.cudChartOnDemandCoverableCost,
    existingCoverage: copyText.cudChartOnDemandExistingCoverage,
    resourceRecommendation:
      props.commitmentType === GcpCommitmentType.RESOURCE
        ? copyText.cudChartOnDemandResourceRecommendation
        : copyText.cudChartOnDemandSpendFullUtilizationRecommendation,
    ...(props.commitmentType === GcpCommitmentType.SPEND
      ? {
          spendMaximumSavingsRecommendation:
            copyText.cudChartOnDemandSpendMaximumSavingsRecommendation,
        }
      : {}),
  };

  const customColors = {
    coverableCost: theme.cud_chart_fill_coverable_cost,
    existingCoverage: theme.cud_chart_fill_existing_coverage,
    resourceRecommendation: theme.cud_chart_fill_recommendation,
    spendMaximumSavingsRecommendation:
      theme.cud_chart_fill_recommendation_max_savings,
    totalSpend: theme.cud_chart_fill_total_spend,
  };

  const recommendationKeys = [
    "spendMaximumSavingsRecommendation",
    "resourceRecommendation",
  ];

  if (props.isLoading) {
    return (
      <EmptyPlaceholder
        loading={props.isLoading}
        icon={faChartArea}
        skeletonVariant="cartesian"
        text={copyText.chartEmptyPlaceholderText}
      />
    );
  }
  return (
    <StyledBox
      backgroundColor={theme.panel_backgroundColor}
      height="90%"
      position="relative"
      width="100%"
    >
      <Flex
        alignItems="center"
        flexGrow={1}
        marginBottom={theme.space_md}
        justifyContent="space-between"
      >
        <Text appearance="h4">{copyText.cudChartSummaryOnDemand}</Text>
        <Flex>
          <SelectCheckbox
            closeOnSubmit
            hideValues
            options={props.chartOptions.serviceOptions}
            placeholder={copyText.cudChartServicesFilter}
            selectedValues={props.serviceFilters}
            width="15rem"
            onChange={(event) => {
              props.onInteraction({
                filters: event,
                type: CudVisibilityChart.INTERACTION_ADD_SERVICES_FILTER_CLICKED,
              });
            }}
          />
        </Flex>
      </Flex>
      {chartData.length === 0 ? (
        <EmptyPlaceholder
          loading={props.isLoading}
          icon={faChartArea}
          skeletonVariant="cartesian"
          text={copyText.chartEmptyPlaceholderText}
        />
      ) : (
        <ResponsiveContainer debounce={1} height="100%" width="100%">
          <ChartWrapper>
            <_AreaChart data={chartData}>
              <defs>
                {Object.keys(readableKeys).map((key) => {
                  if (recommendationKeys.includes(key)) {
                    return (
                      <pattern
                        id={key}
                        width="10"
                        height="10"
                        patternUnits="userSpaceOnUse"
                        key={key}
                        color={customColors[key]}
                      >
                        <path
                          stroke={customColors[key]}
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="M 5,5 L 10,10"
                        />
                        <path
                          stroke={customColors[key]}
                          strokeLinecap="round"
                          strokeWidth="2"
                          d="M 5,5 L 0,0"
                        />
                        {key === "resourceRecommendation" && (
                          <>
                            <path
                              stroke={customColors[key]}
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M 5,5 L 0,10"
                            />
                            <path
                              stroke={customColors[key]}
                              strokeLinecap="round"
                              strokeWidth="2"
                              d="M 5,5 L 10,0"
                            />{" "}
                          </>
                        )}
                      </pattern>
                    );
                  } else {
                    return (
                      <linearGradient
                        key={key}
                        id={key}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor={customColors[key]}
                          stopOpacity={
                            key === "coverableCost" || key === "totalSpend"
                              ? 0.9
                              : 0.6
                          }
                        />
                        <stop
                          offset="95%"
                          stopColor={customColors[key]}
                          stopOpacity={0.3}
                        />
                      </linearGradient>
                    );
                  }
                })}
              </defs>
              <Tooltip
                content={(tooltipProps) => (
                  <TimeSeriesChartTooltip
                    customColorsKeyedByGrouping={customColors}
                    dateFormat={dateFormat}
                    entry={tooltipProps.payload?.[0]?.payload}
                    excludedGroupings={[]}
                    reverseSortedGroupings={Object.keys(readableKeys).reverse()}
                    hideTotal
                    label={tooltipProps.label}
                    formatGroupingLabelFromKey={(key) =>
                      readableKeys[key] ?? key
                    }
                    unitType={UnitType.CURRENCY}
                  />
                )}
                wrapperStyle={{ outline: "none" }}
              />
              <CartesianGrid
                {...cartesianStyleProps}
                stroke={theme.chart_cartesian_grid_lines}
              />

              <XAxis
                domain={[0, "auto"]}
                {...xAxisStyleProps}
                dataKey={DEFAULT_X_AXIS_KEY}
                stroke={theme.chart_axis_text}
                tick={{
                  stroke: theme.chart_axis_text,
                  fontWeight: 100,
                  fontSize: "0.8rem",
                }}
                tickFormatter={(value) => formatTimestamp(value, dateFormat)}
              />

              <YAxis
                {...yAxisStyleProps}
                domain={[0, "auto"]}
                stroke={theme.chart_axis_text}
                tick={{
                  stroke: theme.chart_axis_text,
                  fontWeight: 100,
                  fontSize: "0.8rem",
                }}
                tickCount={8}
                tickFormatter={(number) =>
                  formatCurrencyRounded({
                    number,
                  })
                }
              />
              {Object.keys(readableKeys).map((key) => {
                return (
                  <Area
                    {...areaStyleProps}
                    key={key}
                    animationDuration={200}
                    dataKey={key}
                    fill={
                      excludedKeysMap[key]
                        ? theme.tooltip_disabled_cell_color
                        : `url(#${key})`
                    }
                    stackId={
                      [...recommendationKeys, "existingCoverage"].includes(key)
                        ? "commitmentStack"
                        : undefined
                    }
                    stroke={
                      excludedKeysMap[key]
                        ? theme.tooltip_disabled_cell_color
                        : customColors[key]
                    }
                    strokeWidth={
                      recommendationKeys.includes(key) ? 4 : undefined
                    }
                    strokeDasharray={
                      recommendationKeys.includes(key) ? "8 8" : undefined
                    }
                  />
                );
              })}

              {horizontalLineElement}

              <Legend
                iconType="square"
                verticalAlign="bottom"
                onClick={(payload) =>
                  isString(payload.dataKey)
                    ? toggleKey(payload.dataKey)
                    : noop()
                }
                formatter={(key) => readableKeys[key] ?? key}
              />
            </_AreaChart>
          </ChartWrapper>
        </ResponsiveContainer>
      )}
    </StyledBox>
  );
}

function handleChangeChartData(params: {
  costChartData: CudOnDemandChartDatum[];
  coverableCost: GCPBillingEntity[];
  cudInventoryFilters: CudInventoryFilters;
  serviceFilters: string[];
}): ChartDatum[] {
  const { costChartData, coverableCost, cudInventoryFilters, serviceFilters } =
    params;

  const serviceDescriptionFromFilters: string[] = [];

  serviceFilters.map((service) => {
    const serviceLabelType: GcpCommitmentServiceType =
      GcpCommitmentServiceType[service];
    if (serviceLabelType === GcpCommitmentServiceType.CLOUD_MEMORY_STORE) {
      serviceDescriptionFromFilters.push(
        copyText.cudChartOnDemandMemoryRedis,
        copyText.cudChartOnDemandMemoryMemcached
      );
    }
    if (
      serviceLabelType === GcpCommitmentServiceType.BIGQUERY_ENTERPRISE ||
      serviceLabelType === GcpCommitmentServiceType.BIGQUERY_ENTERPRISE_PLUS
    ) {
      serviceDescriptionFromFilters.push(
        copyText.cudChartOnDemandBigQueryReservationApi
      );
    }

    const serviceLabel =
      getReadableGcpCommitmentServiceTypeStrings(serviceLabelType);
    serviceDescriptionFromFilters.push(serviceLabel);
  });

  const chartData: {
    [key: string]: {
      totalSpend: number;
    };
  } = costChartData[0]?.billingData
    .filter((bill) => {
      return serviceDescriptionFromFilters.includes(bill.serviceDescription);
    })
    .reduce((accum, bill) => {
      if (accum[bill.timestamp]) {
        accum[bill.timestamp] = {
          ...accum[bill.timestamp],
          totalSpend: (accum[bill.timestamp].totalSpend += bill.cost),
        };
      } else {
        accum[bill.timestamp] = {
          totalSpend: bill.cost,
        };
      }

      return accum;
    }, {});

  if (chartData) {
    return Object.keys(chartData).map((keys) => {
      const filteredCoverableCost = coverableCost.reduce((accum, cost) => {
        if (
          cost.timestamp === keys &&
          serviceDescriptionFromFilters.includes(cost.serviceDescription)
        ) {
          if (typeof cost.minDailyCost === "number") {
            return accum + cost.minDailyCost;
          } else {
            return accum + cost.cost;
          }
        }
        return accum;
      }, 0);

      const cudExistingCoverage = costChartData[0]?.cudInventoryData.reduce(
        (accum, cud) => {
          if (cud.timestamp === keys && serviceFilters.includes(cud.service)) {
            if (
              cudInventoryFilters.service &&
              cud.service === cudInventoryFilters.service
            ) {
              return accum + cud.potentialCreditedAmount;
            } else if (!cudInventoryFilters.service) {
              return accum + cud.potentialCreditedAmount;
            }
          }
          return accum;
        },
        0
      );

      return {
        ...chartData[keys],
        coverableCost: filteredCoverableCost,
        existingCoverage: cudExistingCoverage,
        timestamp: keys,
      };
    });
  }
  return [];
}

CudVisibilityChart.INTERACTION_ADD_SERVICES_FILTER_CLICKED =
  `CudVisibilityChart.INTERACTION_ADD_SERVICES_FILTER_CLICKED` as const;

interface InteractionAddServicesFilterClicked {
  type: typeof CudVisibilityChart.INTERACTION_ADD_SERVICES_FILTER_CLICKED;
  filters: string[];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CudVisibilityChart {
  export type Interaction = InteractionAddServicesFilterClicked;
}
export default CudVisibilityChart;
