import DataQuery, { Properties } from "@ternary/api-lib/analytics/DataQuery";
import { getDataSourceFilters } from "@ternary/api-lib/utils/ReportUtils";
import { useFilterStore } from "../../../context/FilterStoreProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";

type UseDataQueryParams = Omit<Properties, "fiscalPeriodMap" | "isFiscalMode">;

// Browser specific wrapper for centralizing some of the logic
// that we always need for doing a Datalligator Query
export default function useBuildDataQuery(params: UseDataQueryParams) {
  const authenticatedUser = useAuthenticatedUser();
  const filterStore = useFilterStore();

  const fiscalPeriodMap = authenticatedUser.tenant.fiscalCalendar?.periods;
  const isFiscalMode = authenticatedUser.settings.fiscalMode;

  const globalFilters = getDataSourceFilters(
    filterStore.queryFilters,
    params.dataSource
  );

  const query = new DataQuery({
    ...params,
    fiscalPeriodMap,
    isFiscalMode,
    preAggFilters: [...globalFilters, ...(params.preAggFilters ?? [])],
  });

  return [authenticatedUser.tenant.fsDocID, query] as const;
}
