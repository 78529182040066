import { Input } from "@/types";
import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { ChangeEvent } from "react";
import copyText from "../../copyText";
import {
  BQ_FIELDS_REGEX,
  GCPLocationSelect,
  GCP_PROJECT_ID_REGEX,
  getErrorMessageIfPresent,
} from "./GCPIntegrationForm";
import { GCPIntegration } from "./types";

interface Props {
  integration?: GCPIntegration;
  datasetIDInput: Input<string>;
  isLoading: boolean;
  locationInput: Input<string>;
  projectIDInput: Input<string>;
  tableIDInput: Input<string>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function GCPCloudFormDetailedBillingExport(
  props: Props
): JSX.Element {
  const theme = useTheme();

  let detailedAccessError: string | undefined;

  if (props.integration) {
    detailedAccessError = getErrorMessageIfPresent(
      props.integration,
      CloudCapability.DETAILED_BILLING_EXPORT
    );
  }

  return (
    <Box height={416} padding={theme.space_xs} scrollable>
      <FormField
        name="detailedProjectID"
        input={TextInput}
        caption={copyText.cloudHelpExportDetailedProjectId}
        label={copyText.cloudAttributeDetailedExportProjectId}
        value={props.projectIDInput.value}
        variant={
          detailedAccessError === undefined &&
          GCP_PROJECT_ID_REGEX.test(props.projectIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        name="detailedDatasetID"
        input={TextInput}
        caption={copyText.cloudHelpExportDetailedDatasetId}
        label={copyText.cloudAttributeDetailedExportDatasetId}
        value={props.datasetIDInput.value}
        variant={
          detailedAccessError === undefined &&
          BQ_FIELDS_REGEX.test(props.datasetIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        name="detailedTableID"
        input={TextInput}
        caption={copyText.cloudHelpExportDetailedTableId}
        label={copyText.cloudAttributeDetailedExportTableId}
        value={props.tableIDInput.value}
        variant={
          detailedAccessError === undefined &&
          BQ_FIELDS_REGEX.test(props.tableIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        caption={copyText.cloudHelpExportDetailedLocation}
        label={copyText.cloudAttributeDetailedExportLocation}
      >
        <GCPLocationSelect
          locationInput={props.locationInput}
          targetName="detailedLocation"
          onChange={props.onChange}
        />
      </FormField>
    </Box>
  );
}
