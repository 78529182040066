import { createStructParam } from "@/lib/use-query-params";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton, TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { EBStoreGroup, EBStoreGroupFilters } from "../types";

type TableData = {
  billPayerAccountId: string;
  groupID: string;
  operationsCost: number;
  region: string;
  snapshotCost: number;
  snapshotUsageBytes: number;
  snapshotCount: number;
  storageCost: number;
  storageUsageBytes: number;
  totalCost: number;
  volumeCount: number;
};

type Props = {
  storeGroups: EBStoreGroup[];
  isLoadingEBStoreGroups: boolean;
  onInteraction: (interaction: EBStoreGroupTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function EBStoreGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "ebs_group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "groupID",
        Cell: ({ value: groupID }) => {
          return (
            <EBSDropdown
              groupID={groupID}
              onSelectVolumes={(groupID) => {
                props.onInteraction({
                  type: EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED,
                  selectedVolumeGroupID: groupID,
                });
              }}
              onSelectSnapshots={(groupID) => {
                props.onInteraction({
                  type: EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED,
                  selectedSnapshotGroupID: groupID,
                });
              }}
            />
          );
        },
        disableSortBy: true,
        Header: "",
        width: 50,
      },
      {
        accessor: "billPayerAccountId",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "billPayerAccountId",
                filterValue: value,
              })
            }
          >
            {value || copyText.s3TableNull}
          </Text>
        ),
        Header: (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.ebsTableGroupHeader_billPayerAccountId}
          </Tooltip>
        ),
        width: 150,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: EBStoreGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value || copyText.s3TableNull}
          </Text>
        ),
        Header: copyText.ebsTableGroupHeader_region,
        width: 100,
      },
      {
        accessor: "volumeCount",
        align: "center",
        Header: copyText.ebsTableGroupHeader_volumeCount,
        sortDescFirst: true,
        width: 60,
      },
      {
        accessor: "snapshotCount",
        align: "center",
        Header: copyText.ebsTableGroupHeader_snapshotCount,
        sortDescFirst: true,
        width: 60,
      },
      {
        accessor: "storageUsageBytes",
        align: "center",
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        Header: copyText.ebsTableGroupHeader_storageUsageBytes,
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "snapshotUsageBytes",
        align: "center",
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        Header: copyText.ebsTableGroupHeader_snapshotUsageBytes,
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "snapshotCost",
        align: "center",
        Cell: ({ value: snapshotCost }) => (
          <>{formatCurrency({ number: snapshotCost })}</>
        ),
        Header: copyText.ebsTableGroupHeader_snapshotCost,
        sortDescFirst: true,
        width: 100,
      },
      {
        accessor: "storageCost",
        align: "center",
        Cell: ({ value: storageCost }) => (
          <>{formatCurrency({ number: storageCost })}</>
        ),
        Header: copyText.ebsTableGroupHeader_storageCost,
        sortDescFirst: true,
        width: 100,
      },
      {
        accessor: "operationsCost",
        align: "center",
        Cell: ({ value: operationsCost }) => (
          <>{formatCurrency({ number: operationsCost })}</>
        ),
        Header: copyText.ebsTableGroupHeader_operationsCost,
        sortDescFirst: true,
        width: 100,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.ebsTableGroupHeader_totalCost,
        sortDescFirst: true,
        width: 100,
      },
    ],
    [props.storeGroups]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.storeGroups.map((storeGroup) => ({
      billPayerAccountId: storeGroup.billPayerAccountId,
      groupID: storeGroup.groupID,
      operationsCost: storeGroup.operationsCost,
      region: storeGroup.region,
      snapshotCost: storeGroup.snapshotCost,
      snapshotCount: storeGroup.snapshots.length,
      snapshotUsageBytes: !storeGroup.snapshotUsageBytes
        ? 0
        : storeGroup.snapshotUsageBytes / storeGroup.snapshots.length,
      storageCost: storeGroup.storageCost,
      storageUsageBytes: !storeGroup.storageUsageBytes
        ? 0
        : storeGroup.storageUsageBytes / storeGroup.volumes.length,
      totalCost: storeGroup.totalCost,
      volumeCount: storeGroup.volumes.length,
    }));
    return tableData;
  }, [props.storeGroups]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingEBStoreGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );

  interface EBSDropdownProps {
    groupID: string;
    onSelectVolumes: (id: string) => void;
    onSelectSnapshots: (id: string) => void;
  }

  function EBSDropdown(dropdownProps: EBSDropdownProps): JSX.Element {
    const options = [
      {
        label: copyText.storageSubTableViewVolumes,
        onClick: () => dropdownProps.onSelectVolumes(dropdownProps.groupID),
      },
      {
        label: copyText.storageSubTableViewSnapshots,
        onClick: () => dropdownProps.onSelectSnapshots(dropdownProps.groupID),
      },
    ];

    return (
      <Dropdown options={options} placement="bottom-start">
        <ActionMenuButton />
      </Dropdown>
    );
  }
}

EBStoreGroupTable.INTERACTION_FILTER_CLICKED =
  `EBStoreGroupTable.INTERACTION_FILTER_CLICKED` as const;

EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED =
  `EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED` as const;

EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED =
  `EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof EBStoreGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof EBStoreGroupFilters;
  filterValue: EBStoreGroupFilters[keyof EBStoreGroupFilters];
}
interface InteractionViewVolumesClicked {
  type: typeof EBStoreGroupTable.INTERACTION_VIEW_VOLUMES_CLICKED;
  selectedVolumeGroupID: string;
}
interface InteractionViewSnapshotsClicked {
  type: typeof EBStoreGroupTable.INTERACTION_VIEW_SNAPSHOTS_CLICKED;
  selectedSnapshotGroupID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace EBStoreGroupTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionViewSnapshotsClicked
    | InteractionViewVolumesClicked;
}
export default EBStoreGroupTable;
