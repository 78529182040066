import styled from "@emotion/styled";
import Box, { Props as BoxProps } from "@ternary/web-ui-lib/components/Box";
import * as CSS from "csstype";
import React from "react";

type _Props = {
  height?: number | string;
  minHeight?: string;
  padding?: number | string;
  topOnHover?: CSS.Property.Top;
  width?: number | string;
};

type StyleProps = _Props;

const StyledDiv = styled(Box)<StyleProps>`
  background-color: ${(props) => props.theme.panel_backgroundColor};
  border-radius: 0.375rem;
  min-height: ${(props) => props.minHeight || "unset"};
  padding: ${(props) => props.padding ?? props.theme.space_md};
  position: ${(props) => props.position ?? "initial"};
  width: ${(props) => props.width || "unset"};
  z-index: 0;
  &:hover {
    top: ${(props) => props.topOnHover};
  }
`;

type Props<T> = BoxProps<T> & _Props;

export default function Card<T>(props: Props<T>): JSX.Element {
  return <StyledDiv {...props}>{props.children}</StyledDiv>;
}
