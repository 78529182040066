import { DateRange } from "@ternary/api-lib/analytics/utils";
import { DurationType } from "@ternary/api-lib/constants/enums";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { differenceInDays, sub } from "date-fns";
import copyText from "./copyText";

export const numberSort = (rowA, rowB, columnID, desc) => {
  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

export const stringSort = (rowA, rowB, columnID, desc) => {
  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

// Big Query

export function getPreviousPeriodDateRange(dateRange: DateRange): DateRange {
  const numberOfDaysInRange = differenceInDays(dateRange[1], dateRange[0]);

  return [
    sub(dateRange[0], { days: numberOfDaysInRange + 1 }),
    sub(dateRange[1], { days: numberOfDaysInRange + 1 }),
  ];
}

export function getStringifiedDurationType(
  durationType: DurationType,
  dateRange: Date[]
): string {
  switch (durationType) {
    case DurationType.YESTERDAY: {
      return copyText.durationTypeYesterday;
    }
    case DurationType.LAST_SEVEN_DAYS: {
      return copyText.durationTypeLastSevenDays;
    }
    case DurationType.LAST_THIRTY_DAYS: {
      return copyText.durationTypeLastThirtyDays;
    }
    case DurationType.LAST_NINETY_DAYS: {
      return copyText.durationTypeLastNinetyDays;
    }
    case DurationType.MONTH_TO_DATE: {
      return copyText.durationTypeMonthToDate;
    }
    case DurationType.YEAR_TO_DATE: {
      return copyText.durationTypeYearToDate;
    }
    default: {
      return `${formatDate(dateRange[0], "MM/dd/yyyy")} - ${formatDate(dateRange[1], "MM/dd/yyyy")}`;
    }
  }
}
