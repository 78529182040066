import { FiscalPeriodMap, LabelMapsEntity } from "@/api/core/types";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import getTopNRawData from "@ternary/api-lib/analytics/getTopNRawData";
import { Order, QueryFilter, RawData } from "@ternary/api-lib/analytics/types";
import {
  DataSource,
  DurationType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

export interface Params {
  dateRange: Date[] | [string, string];
  dataSource: DataSource;
  dimensions?: string[];
  durationType?: DurationType;
  isComparisonMode?: boolean;
  isFiscalMode?: boolean;
  fillMissingDates?: boolean;
  fiscalPeriodMap?: FiscalPeriodMap | null;
  granularity?: TimeGranularity;
  limit?: number;
  measures: string[];
  queryFilters?: QueryFilter[];
  order?: Order;
  overflow?: boolean;
  queryKey?: string[];
}

export default function useGetTopNRawData(
  params: Params,
  options?: UseQueryOptions<RawData[], UError>
): UseQueryResult<RawData[], UError> {
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  const { queryKey, ...restParams } = params;

  // NOTE: to prevent refetches based on dimension order change when available in cache (not safe for measures)
  restParams.dimensions = [...(restParams.dimensions ?? [])].sort();

  return useQuery({
    queryKey: ["topNRawData", ...(queryKey ? queryKey : []), restParams],
    queryFn: async () => {
      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const labelMap = labelMaps[params.dataSource] ?? {};

      return getTopNRawData(
        {
          client,
          labelMap,
        },
        restParams
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
