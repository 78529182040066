import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";
import { UpdateUserParams } from "../types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { userID: string } & UpdateUserParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { userID: string } & UpdateUserParams
>;

export default function useUpdateUser(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ userID, ...params }) => client.updateUser(userID, params),
    ...options,
  });
}
