import { useTheme } from "@emotion/react";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React from "react";
import copyText from "../common.copyText";
import externalLinks from "../constants/externalLinks";
import { DateHelper } from "../lib/dates";

const now = new DateHelper();

export default function Footer(): JSX.Element {
  const theme = useTheme();
  return (
    <Flex marginTop={theme.space_lg}>
      <Text whiteSpace="pre">
        &copy;{` ${format(now.date, "yyyy")} `}
        <a href={externalLinks.companyWebsite} target="_blank" rel="noreferrer">
          {copyText.companyName}
        </a>
      </Text>
    </Flex>
  );
}
