import { ReportScope } from "@ternary/api-lib/constants/enums";
import { Action } from "@ternary/api-lib/telemetry";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";

type Report = {
  chartType: string;
  compareType: string | null;
  dataSource: string;
  dimensions: string[];
  durationType: string;
  measures: string[];
  metric: string | null;
  scope: string;
};

type ReportViewRecord = {
  chartType: string;
  compareType?: string;
  dataSource: string;
  dimensions?: string[];
  durationType: string;
  isSystemReport: boolean;
  isUnitEcon: boolean;
  measures: string[];
};

export function useCaptureViewReport(
  action: Action,
  data: unknown[],
  report: Partial<Report>
) {
  const activityTracker = useActivityTracker();
  const seenRecordsRef = useRef<ReportViewRecord[]>([]);

  let currentRecord: null | ReportViewRecord = null;

  if (
    data.length > 0 &&
    report.chartType &&
    report.dataSource &&
    report.durationType &&
    (!!report.metric || (report.measures && report.measures.length > 0))
  ) {
    // Only capture report view if fully configured and finished loading
    currentRecord = {
      chartType: report.chartType,
      dataSource: report.dataSource,
      durationType: report.durationType,
      isUnitEcon: !!report.metric,
      measures: report.measures ? [...report.measures].sort() : [],
      isSystemReport: report.scope === ReportScope.GLOBAL,
    };
  }

  if (currentRecord && report.compareType) {
    currentRecord.compareType = report.compareType;
  }

  if (currentRecord && report.dimensions && report.dimensions.length > 0) {
    currentRecord.dimensions = [...report.dimensions].sort();
  }

  useEffect(() => {
    if (!currentRecord) return;

    const hasSeenRecord = !!seenRecordsRef.current.find((seenRecord) =>
      isEqual(currentRecord, seenRecord)
    );

    if (hasSeenRecord) return;

    seenRecordsRef.current.push(currentRecord);
    activityTracker.captureAction(action, currentRecord);
  });
}
