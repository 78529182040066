import React, {
  ReactElement,
  createContext,
  useContext,
  useState,
} from "react";

interface State {
  actingMspPartnerID: string | null;
  actingTenantID: string | null;
}

const initialState: State = {
  actingMspPartnerID: null,
  actingTenantID: null,
};

interface Store {
  actingMspPartnerID: string | null;
  actingTenantID: string | null;
  reset: () => void;
  set: (state: Partial<State>) => void;
}

const context = createContext<Store | undefined>(undefined);

export default function InternalAdminProvider({
  children,
}: {
  children: ReactElement | ReactElement[];
}): JSX.Element {
  const [state, setState] = useState<State>(initialState);

  const reset = () => setState(initialState);

  const set = (state: Partial<State>) =>
    setState((currentState) => ({ ...currentState, ...state }));

  return (
    <context.Provider value={{ ...state, reset, set }}>
      {children}
    </context.Provider>
  );
}

export function useInternalAdmin(): Store {
  const store = useContext(context);

  if (!store) {
    throw new Error(
      "useInternalAdmin must be wrapped by InternalAdminProvider"
    );
  }

  return store;
}
