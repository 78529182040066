import { useTheme } from "@emotion/react";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";

export default function CUDPartiallyConfiguredWarning() {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.feedback_negative_background}
      borderRadius={theme.borderRadius_1}
      marginBottom={theme.space_lg}
      padding={theme.space_md}
    >
      <Box marginRight={theme.space_sm}>
        <Icon fontSize={theme.h4_fontSize} icon={faTriangleExclamation} />
      </Box>

      <Text>{copyText.cudPartiallyConfiguredWarning}</Text>
    </Flex>
  );
}
