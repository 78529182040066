import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type MspBillingRuleSetEntity = Awaited<
  ReturnType<CoreAPIClient["getRuleSetsByTenantID"]>
>[number];

export default function useGetMspBillingRuleSetsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<MspBillingRuleSetEntity[], CoreAPIClientError>
): UseQueryResult<MspBillingRuleSetEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const enabled =
    options?.enabled !== undefined
      ? options.enabled && gatekeeper.canReadMspBillingRuleSets
      : gatekeeper.canReadMspBillingRuleSets;

  return useQuery({
    queryKey: ["ruleSets", tenantID],
    queryFn: () => client.getRuleSetsByTenantID(tenantID),
    meta: { errorMessage: copyText.errorLoadingRuleSetsMessage },
    enabled,
    ...options,
  });
}
