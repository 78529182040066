import Legend from "@/components/Legend";
import timing from "@/constants/timing";
import { convertResource } from "@/utils/resources";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatNumberRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import copyText from "../../copyText";
import { SECONDS_PER_HOUR } from "./CUDOptimizerSection";

type HourUsageDatum = {
  cud1YearCost: number;
  cud1YearUsageAmount: number;
  cud3YearCost: number;
  cud3YearUsageAmount: number;
  cudsCreditAmount: number;
  family: string;
  region: string;
  resourceCost: number;
  resourceUsageAmount: number;
  resourceUsageUnit: string;
  sudsCreditAmount: number;
  totalResourceCreditsAmount: number;
  timestamp: string;
};

interface Props {
  cud1YrPerHour: number;
  cud3YrPerHour: number;
  timeSeriesData: HourUsageDatum[];
}

// NOTE: We only show a fraction of the data on the graph
const reductionRatio = 4;

export default function CUDGraph(props: Props): JSX.Element {
  const theme = useTheme();
  let yAxisUnit = "";
  const data = props.timeSeriesData
    .map((datum, i) => {
      const total = datum.resourceUsageAmount;
      if (i === 0) {
        const { unit } = convertResource({
          quantity: total,
          unit: datum.resourceUsageUnit,
        });
        yAxisUnit = unit;
      }

      const date = datum.timestamp;

      const onDemand = total - (props.cud1YrPerHour + props.cud3YrPerHour);
      // Convert units
      const unit = datum.resourceUsageUnit;
      const onDemandConverted = convertResource({
        quantity: onDemand > 0 ? onDemand : 0,
        unit,
      });
      const cud1YrConverted = convertResource({
        quantity: props.cud1YrPerHour,
        unit,
      });
      const cud3YrConverted = convertResource({
        quantity: props.cud3YrPerHour,
        unit,
      });
      const convertedHistoricCUD1YrUsage = convertResource({
        quantity: datum.cud1YearUsageAmount + datum.cud3YearUsageAmount,
        unit,
      });
      const convertedHistoricCUD3YrUsage = convertResource({
        quantity: datum.cud3YearUsageAmount,
        unit,
      });
      const convertedWaste = convertResource({
        quantity: onDemand < 0 ? onDemand : 0,
        unit,
      });

      return {
        cud1: cud1YrConverted.quantity / SECONDS_PER_HOUR,
        cud3: cud3YrConverted.quantity / SECONDS_PER_HOUR,
        date: formatDate(new Date(date), "MM/dd"),
        historicCUD1YrUsage:
          convertedHistoricCUD1YrUsage.quantity / SECONDS_PER_HOUR,
        historicCUD3YrUsage:
          convertedHistoricCUD3YrUsage.quantity / SECONDS_PER_HOUR,
        onDemand: onDemandConverted.quantity / SECONDS_PER_HOUR,
        waste: convertedWaste.quantity / SECONDS_PER_HOUR,
      };
    })
    .filter((_, i) => {
      return i % reductionRatio === 0;
    });

  const xAxisTicks = data
    .filter((_, i) => i === 0 || (i + 1) % 30 === 0 || i + 1 === data.length)
    .map((x) => x.date);

  return (
    <Box>
      <Text
        fontSize={theme.fontSize_base}
        fontWeight={theme.h4_fontWeight}
        marginBottom={theme.space_md}
      >
        {copyText.cudOptimizerGraphTitle}
      </Text>
      <ResponsiveContainer width="95%" height={200}>
        <ComposedChart
          data={data}
          margin={{ bottom: 24, left: 24, right: 24, top: 8 }}
        >
          <CartesianGrid
            stroke={theme.chart_cartesian_grid_lines}
            vertical={false}
          />
          ;
          <Area
            animationDuration={timing.chartAnimationDuration}
            dataKey="cud3"
            fill={theme.cud_color_3yr}
            fillOpacity={theme.chart_fill_opacity}
            stackId="b"
            strokeWidth={0}
            type="monotone"
          />
          <Area
            animationDuration={timing.chartAnimationDuration}
            dataKey="cud1"
            fill={theme.cud_color_1yr}
            fillOpacity={theme.chart_fill_opacity}
            stackId="b"
            strokeWidth={0}
            type="monotone"
          />
          <Area
            animationDuration={timing.chartAnimationDuration}
            dataKey="onDemand"
            fill={theme.cud_color_on_demand}
            fillOpacity={theme.chart_fill_opacity}
            stackId="b"
            stroke={theme.cud_color_on_demand}
            strokeWidth={0}
            type="monotone"
          />
          <Area
            animationDuration={timing.chartAnimationDuration}
            dataKey="waste"
            fill={theme.feedback_negative}
            fillOpacity={theme.chart_fill_opacity}
            stackId="b"
            stroke={theme.feedback_negative}
            strokeWidth={0}
            type="monotone"
          />
          <Line
            animationDuration={1000}
            dataKey="historicCUD1YrUsage"
            dot={false}
            stroke={theme.cud_color_1yr_stroke}
            strokeWidth={3}
            type="monotone"
          />
          <Line
            animationDuration={1000}
            dataKey="historicCUD3YrUsage"
            dot={false}
            stroke={theme.cud_color_3yr_stroke}
            strokeWidth={3}
            type="monotone"
          />
          <XAxis
            allowDataOverflow
            dataKey="date"
            dy={8}
            interval="preserveStartEnd"
            ticks={xAxisTicks}
            label={{
              value: copyText.cudGraphAxisLabelDays,
              position: "bottom",
              dy: 8,
              style: {
                fill: theme.text_color,
                fontWeight: "bold",
                fontSize: "0.8rem",
                textTransform: "lowercase",
              },
            }}
            stroke={theme.chart_axis_text}
            tick={{
              stroke: theme.text_color,
              fontSize: "0.75rem",
              fontWeight: 300,
            }}
          />
          <YAxis
            dx={-4}
            label={{
              angle: -90,
              dx: -40,
              fill: theme.text_color,
              style: {
                fontWeight: "bold",
                fontSize: "0.8rem",
                textTransform: "lowercase",
              },
              value: yAxisUnit,
            }}
            stroke={theme.chart_axis_text}
            tick={{
              fontSize: "0.75rem",
              fontWeight: 100,
              stroke: theme.text_color,
            }}
            tickFormatter={formatNumberRounded}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <Legend
        items={[
          [
            {
              text: copyText.cudCategoryOnDemand,
              color: theme.cud_color_on_demand,
            },
            { text: copyText.cudCategoryCUD1Yr, color: theme.cud_color_1yr },
            { text: copyText.cudCategoryCUD3Yr, color: theme.cud_color_3yr },
          ],
          [
            {
              text: copyText.cudCategoryActualCUD1yr,
              color: theme.cud_color_1yr_stroke,
              style: "line",
            },
            {
              text: copyText.cudCategoryActualCUD3yr,
              color: theme.cud_color_3yr_stroke,
              style: "line",
            },
          ],
        ]}
      />
    </Box>
  );
}
