import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconExport(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 16 16">
        <path d="M3.267 1.333C2.567 1.333 2 1.9 2 2.6v10.133c0 .7.567 1.267 1.267 1.267h5.066c.7 0 1.267-.567 1.267-1.267V8.3H5.8a.634.634 0 0 1 0-1.267h3.8V2.6c0-.7-.567-1.267-1.267-1.267H3.267Zm6.333 5.7V8.3h1.267v.887a.507.507 0 0 0 .864.358l1.52-1.521a.507.507 0 0 0 0-.716l-1.52-1.52a.507.507 0 0 0-.864.358v.887H9.6Z" />
      </svg>
    </Icon>
  );
}
