import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import CoreAPIClient from "../CoreAPIClient";

type UserEntity = Awaited<ReturnType<CoreAPIClient["getUsersByTenantID"]>>[0];

export default function useGetUsersByTenantID(
  tenantID: string,
  options?: UseQueryOptions<UserEntity[], CoreAPIClientError>
): UseQueryResult<UserEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["users", tenantID],
    queryFn: () => client.getUsersByTenantID(tenantID),
    ...options,
  });
}
