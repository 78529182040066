import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { CostCompareBillEntity } from "@ternary/api-lib/core/types";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetBillsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<CostCompareBillEntity[], CoreAPIClientError>
): UseQueryResult<CostCompareBillEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.all,
    queryFn: () => client.getBillsByTenantID(tenantID),
    enabled: gatekeeper.canListBills,
    ...options,
  });
}
