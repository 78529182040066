import { useTheme } from "@emotion/react";
import React from "react";

interface Props {
  fill?: string;
  height?: number;
  payload?: { value: number };
  tickFormatter?: (value: any) => string;
  width?: number;
  x?: number;
  y?: number;
}

export default function YAxisTick(props: Props) {
  const theme = useTheme();

  if (!props.payload || !props.y) return null;

  return (
    <text
      height={props.height}
      fill={props.fill}
      fontSize={"0.8rem"}
      fontWeight={100}
      orientation="left"
      stroke={theme.chart_axis_text}
      strokeWidth={1}
      textAnchor="end"
      width={props.width}
      x={props.x}
      y={props.y}
    >
      <tspan x={props.x} dy="0.355em">
        {props.tickFormatter
          ? props.tickFormatter(props.payload.value)
          : props.payload.value}
      </tspan>
    </text>
  );
}
