import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { cloneElement, MouseEvent, PropsWithChildren } from "react";
import { excludeByType, findByType } from "../utils/children";
import Overlay from "./Overlay";

const POSITION = {
  bottom: "bottom",
  center: "center",
  top: "top",
  "top-start": "top-start",
};

type Position = keyof typeof POSITION;

const Root = styled("div")<{ position?: Position }>(({ position, theme }) => {
  return {
    alignItems: (() => {
      switch (position) {
        case POSITION.top:
          return "flex-start";
        case POSITION["top-start"]:
          return "flex-start";
        case POSITION.bottom:
          return "flex-end";
        default:
          return "center";
      }
    })(),
    bottom: 0,
    display: "flex",
    justifyContent: (() => {
      switch (position) {
        case POSITION["top-start"]:
          return "flex-start";
        default:
          return "center";
      }
    })(),
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: theme.zIndex_1600,
  };
});

const Content = styled("div")<{
  minWidth?: number | string;
  width?: number | string;
}>(({ minWidth, theme, width }) => ({
  backgroundColor: theme.modal_background_color,
  borderRadius: theme.borderRadius_2,
  boxShadow: `0 8px 16px ${theme.box_shadow}`,
  display: "flex",
  flexDirection: "column",
  margin: theme.space_xxl,
  minWidth: minWidth ?? "400px",
  position: "relative",
  ...(width && { width }),
}));

const HeaderRoot = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.space_lg,
}));

const BodyRoot = styled("div")(({ theme }) => ({
  padding: theme.space_lg,
}));

const FooterRoot = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.space_lg,

  "& > *:not(:last-child)": {
    marginRight: theme.space_md,
  },
}));

type Props = React.PropsWithChildren<{
  actions?: { text: string; onClick: () => void }[];
  closeOnClickOutside?: boolean;
  isOpen?: boolean;
  minWidth?: number | string;
  position?: Position;
  showCloseButton?: boolean;
  title?: string;
  width?: number | string;
  onClose?: (event?: MouseEvent) => void;
}>;

export default function Modal(props: Props): JSX.Element {
  const theme = useTheme();

  if (!props.isOpen) return <></>;

  let header;

  if (props.title) {
    header = (
      <Header
        color={theme.text_color_secondary}
        showCloseButton={props.showCloseButton}
        onClose={props.onClose}
      >
        <Text fontSize={theme.h4_fontSize}>{props.title}</Text>
      </Header>
    );
  } else {
    const headerChild = findByType(props.children, Header);

    if (headerChild) {
      header = cloneElement(headerChild, {
        showCloseButton: props.showCloseButton,
        onClose: props.onClose,
      });
    }
  }

  let body = findByType(props.children, Body);

  if (!body) {
    const bodyChildren = excludeByType(props.children, [Header, Footer]);

    if (bodyChildren) {
      body = <Body>{bodyChildren}</Body>;
    }
  }

  let footer;

  if (props.actions) {
    const length = props.actions.length;

    const actions = props.actions.map(({ text, ...restProps }, i) => (
      <Button
        key={i}
        primary={i === length - 1}
        secondary={i !== length - 1}
        {...restProps}
      >
        {text}
      </Button>
    ));

    footer = <Footer>{actions}</Footer>;
  } else {
    footer = findByType(props.children, Footer);
  }

  const contentProps = {
    minWidth: props.minWidth,
    width: props.width,
  };

  return (
    <Root position={props.position}>
      <Overlay
        closeOnClickOutside={props.closeOnClickOutside}
        onClose={props.onClose}
      />
      <Content {...contentProps}>
        {header}
        {body}
        {footer}
      </Content>
    </Root>
  );
}

interface HeaderProps {
  showCloseButton?: boolean;
  color?: string;
  onClose?: () => void;
}

export function Header(props: PropsWithChildren<HeaderProps>): JSX.Element {
  const closeButton = props.showCloseButton ? (
    <Button
      iconStart={<Icon color={props.color} icon={faTimes} />}
      size="small"
      onClick={props.onClose}
    />
  ) : undefined;

  return (
    <HeaderRoot>
      {props.children}
      {closeButton}
    </HeaderRoot>
  );
}

function Body(props: PropsWithChildren<unknown>): JSX.Element {
  return <BodyRoot>{props.children}</BodyRoot>;
}

function Footer(props: PropsWithChildren<unknown>): JSX.Element {
  return <FooterRoot>{props.children}</FooterRoot>;
}

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
