import React from "react";
import { DotProps } from "recharts";

interface Props extends DotProps {
  onClick: (...args: any[]) => void;
}

export default function ActiveDot(props: Props) {
  return (
    <circle
      cursor={props.cursor}
      cx={props.cx}
      cy={props.cy}
      fill={props.fill}
      r={7}
      stroke="white"
      strokeWidth={3}
      onClick={props.onClick}
    />
  );
}
