import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Form from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useRef, useState } from "react";
import copyText from "../copyText";
import { DownloadAzureFormField } from "./DownloadAzureFormField";
import { DownloadOCIFormField } from "./DownloadOCIFormField";

export default function TenantInfoContainer() {
  const user = useAuthenticatedUser();
  const theme = useTheme();

  return (
    <Box width={550}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginVertical={theme.space_lg}
      >
        <Text fontSize={theme.h4_fontSize}>
          {copyText.currentTenantGeneralInformation}
        </Text>
      </Flex>

      <Form>
        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.currentTenantGeneralInformationFormNameLabel}
          </Text>
          <CopyBox
            text={user.tenant.name}
            tooltipLabel={
              copyText.currentTenantGeneralInformationFormNameTooltip
            }
          />
        </Box>

        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.currentTenantGeneralInformationFormIDLabel}
          </Text>
          <CopyBox
            text={user.tenant.id}
            tooltipLabel={copyText.currentTenantGeneralInformationFormIDTooltip}
          />
        </Box>

        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.currentTenantGeneralInformationFormServiceAccount}
          </Text>
          <CopyBox
            text={user.tenant.serviceAccountEmail}
            tooltipLabel={
              copyText.currentTenantGeneralInformationFormServiceAccountTooltip
            }
          />
        </Box>

        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.currentTenantGeneralInformationFormServiceAccountUID}
          </Text>
          <CopyBox
            text={user.tenant.serviceAccountUID}
            tooltipLabel={
              copyText.currentTenantGeneralInformationFormServiceAccountUIDTooltip
            }
          />
        </Box>

        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.actionMenuItemDownloadAzureCert}
          </Text>
          <DownloadAzureFormField
            text={copyText.actionMenuItemAzureCertLabel}
            user={user}
          />
        </Box>

        <Box marginBottom={theme.space_lg}>
          <Text as="label" fontWeight={theme.fontWeight_bold}>
            {copyText.actionMenuItemDownloadOCIKey}
          </Text>
          <DownloadOCIFormField
            text={copyText.actionMenuItemOCIKeyLabel}
            user={user}
          />
        </Box>
      </Form>
    </Box>
  );
}

type CopyBoxProps = {
  text: string;
  tooltipLabel: string;
};

function CopyBox(props: CopyBoxProps) {
  const theme = useTheme();
  const timeoutRef = useRef<number | null>(null);
  const [showingSuccessMessage, setShowingSuccessMessage] = useState(false);
  const CONTENT_HEIGHT = "1.25rem";

  useEffect(() => {
    return () => {
      if (typeof timeoutRef.current === "number") {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function handleClick() {
    navigator.clipboard.writeText(props.text);

    const prevTimeout = timeoutRef.current;

    if (typeof prevTimeout === "number") {
      window.clearTimeout(prevTimeout);
    }

    setShowingSuccessMessage(true);

    timeoutRef.current = window.setTimeout(() => {
      setShowingSuccessMessage(false);
    }, 1500);
  }

  return (
    <Tooltip content={props.tooltipLabel} extendedTop placement="top-start">
      <Box position="relative">
        <Box
          top={showingSuccessMessage ? `-${CONTENT_HEIGHT}` : 0}
          maxWidth="100%"
          opacity={showingSuccessMessage ? 1 : 0}
          paddingHorizontal={theme.space_xs}
          position="absolute"
          right={0}
          transition="top 200ms, opacity 200ms ease-in-out"
        >
          <Text color={theme.feedback_positive}>
            {copyText.currentTenantCopiedToClipboard}
          </Text>
        </Box>

        <TextInput
          readOnly
          cursor="pointer"
          value={props.text}
          iconEnd={
            <Icon clickable color={theme.text_color_secondary} icon={faCopy} />
          }
          onClick={handleClick}
        />
      </Box>
    </Tooltip>
  );
}
