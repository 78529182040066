import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const SnowflakeCreditSummaryStruct = {
  totalCreditsUsed: nullable(number()),
  computeCreditsUsed: nullable(number()),
  cloudServicesCreditsUsed: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const SnowflakeSpendSummaryStruct = {
  totalCreditsUsed: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const SnowflakeWarehouseSummaryStruct = {
  accountName: nullable(string()),
  cloudServicesCreditsUsed: nullable(number()),
  computeCreditsUsed: nullable(number()),
  queryCount: nullable(number()),
  queryElapsedTime: nullable(number()),
  tags: nullable(string()),
  totalCreditsUsed: nullable(number()),
  warehouseID: number(),
  warehouseName: nullable(string()),
  warehouseSize: nullable(string()),
  warehouseType: nullable(string()),
};

export type SnowflakeCreditSummaryEntity = {
  totalCreditsUsed: number;
};

export type SnowflakeCreditDatum = NotNullable<
  Infer<typeof SnowflakeCreditSummaryStruct>
>;

export type SnowflakeWarehouseDatum = NotNullable<
  Infer<typeof SnowflakeWarehouseSummaryStruct>
>;
