import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import IconTimes from "@/ui-lib/icons/IconTimes";
import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { ChangeEvent } from "react";
import copyText from "../../copyText";
import { CostAndUsageReportInputs } from "../../types";

interface Props {
  costAndUsageReportFields: CostAndUsageReportInputs[];
  onUpdate: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
  onDelete: (index: number) => void;
}

const AWS_BUCKET_NAME_REGEX =
  /(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9-]*(\.[a-z0-9])?)*$)/;

const regionOptions = [
  "ap-northeast-1",
  "ap-northeast-2",
  "ap-south-1",
  "ap-southeast-1",
  "ap-southeast-2",
  "ca-central-1",
  "eu-central-1",
  "eu-north-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "sa-east-1",
  "us-east-1",
  "us-east-2",
  "us-gov-east-1",
  "us-gov-west-1",
  "us-west-1",
  "us-west-2",
];

export default function AlibabaIntegrationReportForm(
  props: Props
): JSX.Element {
  const theme = useTheme();
  return (
    <>
      {props?.costAndUsageReportFields?.map((report, index: number) => {
        return (
          <Flex key={index} alignItems={"center"} justifyContent="center">
            <Flex
              direction="column"
              justifyContent="space-between"
              marginTop={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-evenly">
                <Box width={240}>
                  <FormField
                    input={TextInput}
                    label={copyText.cloudAlibabaReportNameLabel}
                    name="name"
                    required
                    variant={report.nameInput.length ? "success" : "danger"}
                    value={report.nameInput}
                    onChange={(event) => props.onUpdate(event, index)}
                  />
                </Box>
                <Box width={240}>
                  <FormField
                    input={TextInput}
                    label={copyText.cloudAlibabaReportS3BucketLabel}
                    marginLeft={theme.space_xs}
                    name="s3BucketName"
                    required
                    value={report.s3BucketNameInput}
                    variant={
                      AWS_BUCKET_NAME_REGEX.test(report.s3BucketNameInput) &&
                      report.s3BucketNameInput.length
                        ? "success"
                        : "danger"
                    }
                    onChange={(event) => props.onUpdate(event, index)}
                  />
                </Box>
              </Flex>
              <Flex justifyContent="space-evenly">
                <Box width={240}>
                  <FormField
                    input={TextInput}
                    label={copyText.cloudAlibabaReportPathLabel}
                    name="reportPathPrefix"
                    required
                    value={report.reportPathPrefixInput}
                    variant={
                      report.reportPathPrefixInput.length ? "success" : "danger"
                    }
                    onChange={(event) => props.onUpdate(event, index)}
                  />
                </Box>
                <FormField
                  label={copyText.cloudAlibabaReportRegionLabel}
                  marginLeft={theme.space_xs}
                >
                  <Box width={240}>
                    <Select
                      isClearable
                      searchable
                      options={regionOptions.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      placeholder={copyText.cloudAWSReportDefaultRegionLabel}
                      value={
                        report.regionInput
                          ? {
                              value: report.regionInput,
                              label: report.regionInput,
                            }
                          : null
                      }
                      onChange={(event) => {
                        props.onUpdate(
                          {
                            target: {
                              name: "region",
                              value: event?.value ?? "",
                            },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }}
                    />
                  </Box>
                </FormField>
              </Flex>
            </Flex>
            {props?.costAndUsageReportFields.length > 1 && (
              <Box>
                <Button
                  iconStart={<IconTimes />}
                  size="small"
                  type="button"
                  onClick={() => props.onDelete(index)}
                />
              </Box>
            )}
          </Flex>
        );
      })}
    </>
  );
}
