import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { startOfMonth } from "date-fns";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import {
  AWSDatabaseElastiCacheSpendSummaryEntity,
  awsDatabaseElastiCacheMeasures,
} from "../types";

export interface Params {
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [AWSDatabaseElastiCacheSpendSummaryEntity, UError],
  [AWSDatabaseElastiCacheSpendSummaryEntity, UError],
  [AWSDatabaseElastiCacheSpendSummaryEntity, UError],
];

export default function useGetAWSDatabaseElastiCacheSpendSummaries(
  params?: Params,
  options?: UseQueryOptions<AWSDatabaseElastiCacheSpendSummaryEntity, UError>
): UseQueryResult<AWSDatabaseElastiCacheSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();
  const gatekeeper = useGatekeeper();

  const enabled = gatekeeper.hasAWSIntegration;

  const measures = [awsDatabaseElastiCacheMeasures.cost];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
              measures,
            })
          );

          return {
            totalCost: result[0].cost ?? 0,
          } as AWSDatabaseElastiCacheSpendSummaryEntity;
        },
        queryKey: ["awsDatabaseElastiCacheSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          );

          return {
            totalCost: result[0].cost ?? 0,
          } as AWSDatabaseElastiCacheSpendSummaryEntity;
        },
        queryKey: [
          "awsDatabaseElastiCacheSpendSummary",
          "lastMonthFull",
          params,
        ],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
              measures,
            })
          );

          return {
            totalCost: result[0].cost ?? 0,
          } as AWSDatabaseElastiCacheSpendSummaryEntity;
        },
        queryKey: ["awsDatabaseElastiCacheSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
