import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { CreateDashboardParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateDashboardParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  CreateDashboardParameters
>;

export default function useCreateDashboard(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.createDashboard(params),
    ...options,
  });
}
