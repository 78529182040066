import { useQuery } from "@tanstack/react-query";
import { FindReallocationJobsQueryResult } from "@ternary/api-lib/core/queries/FindReallocationJobsQuery";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetReallocationsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<
    FindReallocationJobsQueryResult["reallocationJobs"],
    CoreAPIClientError
  >
): UseQueryResult<
  FindReallocationJobsQueryResult["reallocationJobs"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.reallocationJobs,
    queryFn: () => client.getReallocationJobsByTenantID(tenantID),
    enabled: gatekeeper.canListReallocationJobs,
    ...options,
  });
}
