import { AlertType, postAlert } from "@/utils/alerts";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import React, { useState } from "react";
import { useConfig } from "../../../context/ConfigProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import copyText from "../copyText";
import useDeleteJiraIntegration from "../hooks/useDeleteJiraIntegration";
import useDeleteSlackIntegration from "../hooks/useDeleteSlackIntegration";
import useGetJiraIntegrationByTenantID from "../hooks/useGetJiraIntegrationByTenantID";
import useGetSlackIntegrationByTenantID from "../hooks/useGetSlackIntegrationByTenantID";
import useUpdateJiraIntegration from "../hooks/useUpdateJiraIntegration";
import useUpdateSlackIntegration from "../hooks/useUpdateSlackIntegration";
import CreateJiraIntegrationModalForm from "./CreateJiraIntegrationModalForm";
import JiraIntegrationCard from "./JiraIntegrationCard";
import SlackIntegrationCard from "./SlackIntegrationCard";
import UpdateJiraIntegrationModalForm from "./UpdateJiraIntegrationModalForm";
import UpdateSlackIntegrationModalForm from "./UpdateSlackIntegrationModalForm";

const MODAL_CREATE_JIRA = "MODAL_CREATE_JIRA";
const MODAL_DELETE_JIRA = "MODAL_DELETE_JIRA";
const MODAL_UPDATE_JIRA = "MODAL_UPDATE_JIRA";
const MODAL_DELETE_SLACK = "MODAL_DELETE_SLACK";
const MODAL_UPDATE_SLACK = "MODAL_UPDATE_SLACK";

type Interaction =
  | CreateJiraIntegrationModalForm.Interaction
  | JiraIntegrationCard.Interaction
  | SlackIntegrationCard.Interaction
  | UpdateJiraIntegrationModalForm.Interaction
  | UpdateSlackIntegrationModalForm.Interaction;

export default function IntegrationManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const config = useConfig();
  const queryClient = useQueryClient();

  //
  // State
  //

  const [modalKey, setModalKey] = useState("");

  //
  // Queries
  //

  const {
    data: jiraIntegration,
    isLoading: isLoadingJiraIntegration,
    refetch: refetchJiraIntegration,
  } = useGetJiraIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: slackIntegration,
    isLoading: isLoadingSlackIntegration,
    refetch: refetchSlackIntegration,
  } = useGetSlackIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  //
  // Mutations
  //

  const {
    isPending: isDeletingJiraIntegration,
    mutate: deleteJiraIntegration,
  } = useDeleteJiraIntegration({
    onSuccess: () => {
      setModalKey("");

      // We don't want to show loading state again so just set data to null
      queryClient.setQueryData(["integrations", "jira"], null);

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successDeletingJiraIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorDeletingJiraIntegrationMessage,
      });
    },
  });

  const {
    isPending: isDeletingSlackIntegration,
    mutate: deleteSlackIntegration,
  } = useDeleteSlackIntegration({
    onSuccess: () => {
      setModalKey("");
      // We don't want to show loading state again so just set data to null
      queryClient.setQueryData(["integrations", "slack"], null);

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successDeletingSlackIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.slackErrorDeletingIntegration,
      });
    },
  });

  const {
    isPending: isUpdatingJiraIntegration,
    mutate: updateJiraIntegration,
  } = useUpdateJiraIntegration({
    onSuccess: () => {
      setModalKey("");

      refetchJiraIntegration();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingSlackIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.slackErrorUpdatingIntegration,
      });
    },
  });

  const {
    isPending: isUpdatingSlackIntegration,
    mutate: updateSlackIntegration,
  } = useUpdateSlackIntegration({
    onSuccess: () => {
      setModalKey("");

      refetchSlackIntegration();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingSlackIntegrationMessage,
      });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.slackErrorUpdatingIntegration,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleDeleteJiraIntegration() {
    if (!jiraIntegration) return;

    deleteJiraIntegration({ tenantID: authenticatedUser.tenant.fsDocID });
  }

  function handleDeleteSlackIntegration() {
    if (!slackIntegration) return;

    deleteSlackIntegration({ tenantID: authenticatedUser.tenant.fsDocID });
  }

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case CreateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setModalKey("");
        return;
      }
      case CreateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        const searchParams = new URLSearchParams({
          returnURL:
            window.location.origin +
            window.location.pathname +
            "?tab=integrations",
          tenantID: authenticatedUser.tenant.fsDocID,
          apiToken: interaction.apiToken,
          email: interaction.email,
          projectID: interaction.projectID,
          ...(interaction.customTransitionID
            ? { customTransitionID: interaction.customTransitionID }
            : {}),
        });

        const redirectURL = `${
          config.JIRA_API_BASE_URL
        }/connect?${searchParams.toString()}`;

        window.location.href = redirectURL;

        return;
      }
      case JiraIntegrationCard.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED: {
        setModalKey(MODAL_CREATE_JIRA);
        return;
      }
      case JiraIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED: {
        setModalKey(MODAL_DELETE_JIRA);
        return;
      }
      case JiraIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED: {
        setModalKey(MODAL_UPDATE_JIRA);
        return;
      }
      case UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setModalKey("");
        return;
      }
      case UpdateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateJiraIntegration({
          tenantID: authenticatedUser.tenant.fsDocID,
          apiToken: interaction.apiToken,
          email: interaction.email,
          issueTransitionID: interaction.issueTransitionID,
        });
        return;
      }
      case SlackIntegrationCard.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED: {
        const searchParams = new URLSearchParams({
          returnURL:
            window.location.origin +
            window.location.pathname +
            "?tab=integrations",
          tenantID: authenticatedUser.tenant.fsDocID,
        });

        const redirectURL = `${
          config.SLACK_API_BASE_URL
        }/connect?${searchParams.toString()}`;

        window.location.href = redirectURL;
        return;
      }
      case SlackIntegrationCard.INTERACTION_DELETE_INTEGRATION_BUTTON_CLICKED:
        setModalKey(MODAL_DELETE_SLACK);
        return;
      case SlackIntegrationCard.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED:
        setModalKey(MODAL_UPDATE_SLACK);
        return;
      case UpdateSlackIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setModalKey("");
        return;
      }
      case UpdateSlackIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateSlackIntegration({
          tenantID: authenticatedUser.tenant.fsDocID,
          channelID: interaction.channelID,
        });
        return;
      }
    }
  }

  //
  // Render
  //

  const isLoading = isLoadingJiraIntegration || isLoadingSlackIntegration;

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="45vh">
        <EmptyPlaceholder icon={faList} loading skeletonVariant="cards" />;
      </Flex>
    );
  }

  function renderModal() {
    switch (modalKey) {
      case MODAL_CREATE_JIRA: {
        return (
          <CreateJiraIntegrationModalForm onInteraction={handleInteraction} />
        );
      }
      case MODAL_DELETE_JIRA: {
        return (
          <ConfirmationModal
            isLoading={isDeletingJiraIntegration}
            message={copyText.deleteJiraIntegrationConfirmationMessage}
            title={copyText.deleteIntegrationConfirmationTitle}
            variant="danger"
            onCancel={() => setModalKey("")}
            onConfirm={handleDeleteJiraIntegration}
          />
        );
      }
      case MODAL_DELETE_SLACK: {
        return (
          <ConfirmationModal
            isLoading={isDeletingSlackIntegration}
            message={copyText.slackIntegrationDeleteConfirmation}
            title={copyText.deleteIntegrationConfirmationTitle}
            variant="danger"
            onCancel={() => setModalKey("")}
            onConfirm={handleDeleteSlackIntegration}
          />
        );
      }
      case MODAL_UPDATE_JIRA: {
        if (!jiraIntegration) return;

        return (
          <UpdateJiraIntegrationModalForm
            integration={jiraIntegration}
            isProcessing={isUpdatingJiraIntegration}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_UPDATE_SLACK: {
        if (!slackIntegration) return;

        return (
          <UpdateSlackIntegrationModalForm
            integration={slackIntegration}
            isProcessing={isUpdatingSlackIntegration}
            onInteraction={handleInteraction}
          />
        );
      }
    }
  }

  return (
    <>
      {renderModal()}
      <Flex>
        <JiraIntegrationCard
          integration={jiraIntegration}
          onInteraction={handleInteraction}
        />
        <SlackIntegrationCard
          integration={slackIntegration}
          onInteraction={handleInteraction}
        />
      </Flex>
    </>
  );
}
