import useRefFn from "@/hooks/useRefFn";
import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faLeaf, faTableList } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { numberSort, stringSort } from "../../utils";
import {
  GCPCloudRunServiceGroup,
  GCPCloudRunServiceGroupFilters,
} from "../types";

type TableData = {
  averageMemory: number;
  averageVCPU: number;
  cost: number;
  lowCarbon: boolean;
  projectId: string;
  region: string;
  regionalPriceTier: string;
  serviceCount: number;
};

type Props = {
  isLoading: boolean;
  serviceGroups: GCPCloudRunServiceGroup[];
  onInteraction: (
    interaction: GCPCloudRunServiceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

function GCPCloudRunServiceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      id: "cost",
      desc: true,
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.serviceGroups.map((serviceGroup) => ({
        averageVCPU: serviceGroup.averageVCPU ?? 0,
        averageMemory: serviceGroup.averageMemory ?? 0,
        cost: serviceGroup.cost ?? 0,
        lowCarbon: serviceGroup.lowCarbon ?? false,
        serviceCount: serviceGroup.averageNumServices ?? 0,
        projectId: serviceGroup.projectId ?? copyText.cloudRunTableNull,
        region: serviceGroup.region ?? copyText.cloudRunTableNull,
        regionalPriceTier:
          serviceGroup.regionalPriceTier ?? copyText.cloudRunTableNull,
      })),
    [props.serviceGroups]
  );

  // useRefFn keeps the props.onInteraction closure from becoming stale
  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "viewServices",
        NoAccessorCell: ({ row }) => {
          return (
            <Tooltip
              content={
                copyText.cloudRunTableServiceGroupTooltipLabelViewServices
              }
            >
              <LinkWithSearchParams
                searchParams={{
                  selected_group: getSelectedGroupJSON(row.original),
                }}
              >
                <Button
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        width: 50,
      },
      {
        accessor: "lowCarbon",
        Cell: ({ value }) => (
          <Tooltip
            width="200px"
            content={
              value
                ? copyText.cloudRunTableServiceGroupLowCarbonToolTip
                : copyText.cloudRunTableServiceGroupHighCarbonToolTip
            }
          >
            <Icon
              clickable
              color={value ? theme.feedback_positive : theme.feedback_neutral}
              icon={faLeaf}
              onClick={() =>
                props.onInteraction({
                  type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "lowCarbon",
                  filterValue: value.toString(),
                })
              }
            />
          </Tooltip>
        ),
        width: 25,
      },
      {
        accessor: "projectId",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "projectId",
                filterValue: value,
              })
            }
          >
            {value || "null"}
          </Text>
        ),
        Header: copyText.cloudRunTableServiceGroupHeader_projectId,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value}
          </Text>
        ),
        Header: copyText.cloudRunTableServiceGroupHeader_region,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "regionalPriceTier",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "regionalPriceTier",
                filterValue: value,
              })
            }
          >
            {value}
          </Text>
        ),
        Header: copyText.cloudRunTableServiceGroupHeader_type,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "cost",
        align: "center",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.cloudRunTableServiceGroupHeader_cost,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "serviceCount",
        align: "center",
        Header: copyText.cloudRunTableServiceGroupHeader_serviceCount,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "averageVCPU",
        align: "center",
        Cell: ({ value }) => <>{formatNumber(value, 1)}</>,
        Header: copyText.cloudRunTableServiceGroupHeader_averageVCPU,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "averageMemory",
        align: "center",
        Cell: ({ value }) => <>{prettyBytes(value, { binary: true })}</>,
        Header: copyText.cloudRunTableServiceGroupHeader_averageMemory,
        sortType: numberSort,
        width: 100,
      },
    ],
    [onInteraction]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      projectId: datum.projectId,
      region: datum.region,
      regionalPriceTier: datum.regionalPriceTier,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return "";
  }
}

GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED =
  `GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof GCPCloudRunServiceGroupFilters;
  filterValue: GCPCloudRunServiceGroupFilters[keyof GCPCloudRunServiceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPCloudRunServiceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default GCPCloudRunServiceGroupTable;
