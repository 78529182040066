import { format } from "date-fns-tz";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "../constants/enums";
import { configureFiscalDateParams } from "./fiscalDateUtils";
import { Order, QueryFilter } from "./types";
import {
  DateRange,
  getSchemaFromDataSource,
  isStringTuple,
  roundDate,
} from "./utils";

const ISO_TIMESTAMP_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export type DatalligatorFilter =
  | DatalligatorSingleFilter
  | DatalligatorCompositeFilter;

export type DatalligatorSingleFilter = {
  schema_field_name: string;
  operator: DatalligatorOperator;
  values: string[];
};

export type DatalligatorCompositeFilter = {
  boolean_logical_operator: "and" | "or";
  filters: (DatalligatorFilter | DatalligatorCompositeFilter)[];
};

export interface Properties {
  dataSource: DataSource;
  dateRange: Date[];
  dimensions?: string[];
  durationType?: DurationType;
  fiscalPeriodMap?: Record<string, string> | null;
  granularity?: TimeGranularity;
  isComparisonMode?: boolean;
  isFiscalMode?: boolean;
  limit?: number;
  measures?: string[];
  offset?: number;
  order?: Order;
  postAggFilters?: QueryFilter[];
  preAggFilters?: QueryFilter[];
}

export default class DataQuery {
  public data_source: string;
  public dimensions?: string[];
  public end_time: string;
  public limit?: null | number;
  public measures?: string[];
  public offset?: number;
  public order_by?: { measure: string; direction: "asc" | "desc" }[];
  public post_agg_filters?: DatalligatorFilter[];
  public pre_agg_filters?: DatalligatorFilter[];
  public precision?: TimeGranularity;
  public response_format?: string;
  public start_time: string;

  constructor(props: Properties) {
    // Configure props for fiscal mode if enabled
    if (props.isFiscalMode && props.fiscalPeriodMap) {
      props = configureFiscalDateParams(props);
    }

    let dateRange: [string, string];

    if (isStringTuple(props.dateRange)) {
      dateRange = props.dateRange;
    } else {
      dateRange = this._getDateRange(props.dateRange);
    }

    // TODO: Remove the need to do this conversion
    this.data_source = getSchemaFromDataSource(props.dataSource);

    if (props.dimensions) {
      this.dimensions = props.dimensions;
    }

    if (props.postAggFilters) {
      this.post_agg_filters = props.postAggFilters.map(transformFilter);
    }

    if (props.preAggFilters) {
      this.pre_agg_filters = props.preAggFilters.map(transformFilter);
    }

    this.limit = props.limit;

    if (props.measures) {
      this.measures = props.measures;
    }

    if (props.order) {
      this.order_by = Object.entries(props.order).map(([key, value]) => ({
        measure: key,
        direction: value,
      }));
    }

    this.precision = props.granularity;

    this.end_time = dateRange[1];

    this.start_time = dateRange[0];

    this.offset = props.offset ?? 0;
  }

  protected _getDateRange(dateRange: DateRange): [string, string] {
    const startDate = roundDate(dateRange[0]);
    const endDate = roundDate(dateRange[1]);

    return [
      format(startDate, ISO_TIMESTAMP_FORMAT),
      format(endDate, ISO_TIMESTAMP_FORMAT),
    ];
  }
}

export type DatalligatorOperator =
  | "afterDate"
  | "afterOrOnDate"
  | "beforeDate"
  | "beforeOrOnDate"
  | "contains"
  | "endsWith"
  | "equals"
  | "gt"
  | "gte"
  | "inDateRange"
  | "lt"
  | "lte"
  | "notContains"
  | "notEndsWith"
  | "notEquals"
  | "notInDateRange"
  | "notSet"
  | "notStartsWith"
  | "set"
  | "startsWith";

export function getOperator(operator: Operator): DatalligatorOperator {
  switch (operator) {
    case Operator.CONTAINS:
      return "contains";
    case Operator.EQUALS:
      return "equals";
    case Operator.GTE:
      return "gte";
    case Operator.LTE:
      return "lte";
    case Operator.NOT_CONTAINS:
      return "notContains";
    case Operator.NOT_EQUALS:
      return "notEquals";
    case Operator.NOT_SET:
      return "notSet";
    case Operator.SET:
      return "set";
    default:
      return "equals";
  }
}

function transformFilter(filter: QueryFilter): DatalligatorFilter {
  if ("name" in filter) {
    return {
      schema_field_name: filter.name,
      // TODO: Remove the need to do this conversion
      operator: getOperator(filter.operator),
      values: filter.values ?? [],
    };
  }

  if ("and" in filter) {
    return {
      boolean_logical_operator: "and",
      filters: filter.and.map((filter) => transformFilter(filter)),
    };
  }

  if ("or" in filter) {
    return {
      boolean_logical_operator: "or",
      filters: filter.or.map((filter) => transformFilter(filter)),
    };
  }

  throw new Error("INVALID_FILTER");
}
