import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { Role } from "@ternary/api-lib/constants/roles";
import PieChart from "@ternary/api-lib/ui-lib/charts/PieChart";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format } from "date-fns";
import { keyBy } from "lodash";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import IconExport from "../../../ui-lib/icons/IconExport";
import { updateAuthenticatedUserGrants } from "../../../utils/QueryClientUtils";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGrantUsersTenantAccess from "../../admin/hooks/useGrantUsersTenantAccess";
import useGetTenantsByParentTenantID from "../../global-admin/hooks/useGetTenantsByParentTenantID";
import copyText from "../copyText";
import useGetMspChildResources from "../hooks/useGetMspChildResources";
import MspCostAlertTable from "./MspCostAlertTable";

const csvHeaders = [
  { key: "tenantName", label: copyText.tableHeaderTenantName },
  { key: "createdAt", label: copyText.tableHeaderAlertedAt },
  { key: "delta", label: copyText.tableHeaderDelta },
];

type Interaction = MspCostAlertTable.Interaction;

type State = {
  selectedTenantID: string | null;
  showModal: boolean;
};

const initialState: State = {
  selectedTenantID: null,
  showModal: false,
};

interface Props {
  parentTenantID: string;
}

export default function MspCostAlertViewContainer(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const { data: _mspTenants = [], isLoading: isLoadingMspTenants } =
    useGetTenantsByParentTenantID(props.parentTenantID, {
      enabled: gatekeeper.canReadTenantsPartner,
    });

  const tenantsKeyedByDocID = keyBy(_mspTenants, "fsDocID");

  const { data: mspResources, isLoading: isLoadingMspResources } =
    useGetMspChildResources(props.parentTenantID);

  //
  // Mutations
  //

  const {
    isPending: isGrantingUserTenantAccess,
    mutate: grantUsersTenantAccess,
  } = useGrantUsersTenantAccess({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorGrantingUserTenantAccessMessage,
      });
    },
    onSuccess: (_, params) => {
      updateAuthenticatedUserGrants(queryClient, params);

      window.open(
        `${window.location.origin}/?tenant_id=${params.tenantID}`,
        "_blank"
      );

      mergeState({ selectedTenantID: null, showModal: false });
    },
  });

  //
  // InteractionHandlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case MspCostAlertTable.INTERACTION_LINK_CLICKED: {
        const tenant = tenantsKeyedByDocID[interaction.tenantDocID];

        mergeState({
          selectedTenantID: tenant.id,
          showModal: true,
        });
        return;
      }
    }
  }

  function handleSubmitTenantAccessModal() {
    if (!state.selectedTenantID) return;

    grantUsersTenantAccess({
      tenantID: state.selectedTenantID,
      grants: [
        {
          userID: authenticatedUser.id,
          email: authenticatedUser.email,
          roles: [Role.TENANT_ADMIN],
        },
      ],
    });
  }

  //
  // Render
  //

  const costAlerts = mspResources?.alerts.map((alert) => ({
    ...alert,
    tenantName: tenantsKeyedByDocID[alert.tenantDocID]?.name ?? "null",
  }));

  const costAlertSummaries = mspResources?.alertSummaries.map((summary) => ({
    ...summary,
    tenantName: tenantsKeyedByDocID[summary.tenantDocID]?.name ?? "null",
  }));

  const isLoading = isLoadingMspResources || isLoadingMspTenants;

  return (
    <Box>
      {state.showModal && (
        <ConfirmationModal
          isLoading={isGrantingUserTenantAccess}
          message={copyText.tenantAccessModalMessage}
          title={copyText.tenantAccessModalTitle}
          onCancel={() => mergeState({ showModal: false })}
          onConfirm={handleSubmitTenantAccessModal}
        />
      )}
      <Flex justifyContent="space-between">
        <Box width="52%">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginBottom={theme.space_xs}
          >
            <Text appearance="h3">{copyText.sectionHeaderAnomalies}</Text>
            <CSVLink
              data={costAlerts ?? []}
              headers={csvHeaders}
              filename={`msp-cost-alerts-${format(new Date(), "MM-dd-yyyy")}`}
            >
              <Button iconStart={<IconExport />} secondary size="small">
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
          <MspCostAlertTable
            costAlertSummaries={costAlerts ?? []}
            isLoading={isLoading}
            onInteraction={handleInteraction}
          />
        </Box>
        <Box height={400} width="45%">
          <Text appearance="h3">{copyText.sectionHeaderAnomalousSpend}</Text>
          <PieChart
            data={costAlertSummaries ?? []}
            dimensions={[{ name: "tenantName", isDate: false }]}
            isLoading={isLoading}
            measures={[{ name: "totalDelta", unit: UnitType.CURRENCY }]}
            readableKeys={{ tenantName: copyText.tableHeaderTenantName }}
            showFullLabel
            showTooltip
          />
        </Box>
      </Flex>
    </Box>
  );
}
