import useGetUsersByTenantID from "@/api/core/hooks/useGetUsersByTenantID";
import Card from "@/components/Card";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { DateHelper } from "@/lib/dates";
import DatePicker from "@/ui-lib/components/DatePicker";
import Grid from "@/ui-lib/components/Grid";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faChevronRight,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInDays, format } from "date-fns";
import { isEqual, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { AuthenticatedUserEntity } from "../../../../api/core/types";
import useUpdateTenant from "../../../../hooks/useUpdateTenant";
import { getFullName } from "../../../../utils/UserUtils";
import copyText from "../../copyText";
import useFiscalYearsData, {
  createFiscalYearsData,
  fiscalDateFns,
} from "../../hooks/useFiscalYearsData";
import { createFiscalCalendarWithoutYear } from "../../hooks/useUpdateFiscalCalendar";
import { EditedFiscalCalendar, FiscalYearValidationResult } from "../../types";
import AddYearModal from "./AddYearModal";
import FiscalCalendarYearForm from "./FiscalCalendarForm";
import FiscalCalendarView from "./FiscalCalendarView";
import FiscalCalendarYearsOverviewList from "./FiscalCalendarYearsOverviewList";
import FiscalYearValidations, {
  validateFiscalYear,
} from "./FiscalYearValidations";
import TodayInfo from "./TodayInfo";

const TABS_HEADER_HEIGHT = 52;

type Interaction =
  | AddYearModal.Interaction
  | FiscalCalendarYearForm.Interaction
  | TodayInfo.Interaction
  | FiscalCalendarYearsOverviewList.Interaction;

type State = {
  calendar: EditedFiscalCalendar | null;
  selectedYearNumber: string | null;
  showAddYearModal: boolean;
};

export default function FiscalCalendarBuilderContainer() {
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();

  const fiscalCalendar = authenticatedUser.tenant.fiscalCalendar;

  const [{ tab }] = useQueryParams({ tab: withDefault(StringParam, "tenant") });
  const isVisible = tab === "fiscalCalendar";

  const [state, setState] = useState<State>({
    calendar: null,
    selectedYearNumber: null,
    showAddYearModal: false,
  });

  const mergeState = getMergeState(setState);

  const initialFiscalYearsData = useFiscalYearsData(fiscalCalendar);
  const fiscalYearsData = useFiscalYearsData(state.calendar);

  const { data: users, isLoading: isLoadingTenantUsers } =
    useGetUsersByTenantID(authenticatedUser.tenant.fsDocID);

  const { isPending: isUpdatingCalendar, mutate: updateTenant } =
    useUpdateTenant({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.fiscalCalendarUpdatingErrorMessage,
        });
      },
      onSuccess: (_, params) => {
        queryClient.invalidateQueries({ queryKey: ["tenants"] });

        queryClient.setQueryData<AuthenticatedUserEntity>(
          ["authenticatedUser"],
          (authenticatedUser) => {
            if (!authenticatedUser) return;

            const updatedTenant: AuthenticatedUserEntity["tenant"] = {
              ...authenticatedUser.tenant,
              fiscalCalendar: params.fiscalCalendar ?? null,
            };

            return {
              ...authenticatedUser,
              tenant: updatedTenant,
            };
          }
        );

        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.fiscalCalendarUpdatingSuccessMessage,
        });
      },
    });

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AddYearModal.INTERACTION_CLOSE_BUTTON_CLICKED:
        mergeState({ showAddYearModal: false });
        return;

      case AddYearModal.INTERACTION_SUBMIT_BUTTON_CLICKED:
        mergeState({
          calendar: interaction.calendar,
          selectedYearNumber: interaction.yearNumber,
          showAddYearModal: false,
        });
        return;

      case FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR:
        mergeState({ calendar: interaction.calendar });
        return;

      case FiscalCalendarYearsOverviewList.INTERACTION_CLICK_YEAR:
        mergeState({ selectedYearNumber: interaction.yearNumber });
        return;

      case TodayInfo.INTERACTION_CLICK_TODAY:
        mergeState({
          selectedYearNumber: getClosestFiscalYearToNow(state.calendar),
        });
        return;
    }
  }

  function selectAdjacentYear(diff: number) {
    const { selectedYearNumber } = state;

    if (!selectedYearNumber) return;

    const adjacentYearNumber = String(Number(selectedYearNumber) + diff);
    const adjacentYear = fiscalYearsData.yearMap[adjacentYearNumber];

    if (adjacentYear) {
      mergeState({ selectedYearNumber: adjacentYearNumber });
    }
  }

  function removeSelectedYear() {
    if (!isSelectedYearRemovable || !state.selectedYearNumber) return;

    const nextCalendar = createFiscalCalendarWithoutYear({
      prevCalendar: state.calendar,
      fiscalYearNumber: state.selectedYearNumber,
    });

    const newSelectedYearNumber = prevYear
      ? prevYearNumber
      : nextYear
        ? nextYearNumber
        : null;

    mergeState({
      calendar: nextCalendar,
      selectedYearNumber: newSelectedYearNumber,
    });
  }

  function updateCalendar() {
    if (
      isEqual(
        fiscalCalendar && {
          endDate: fiscalCalendar.endDate,
          periods: fiscalCalendar.periods,
        },
        state.calendar
      )
    ) {
      return;
    }

    updateTenant({
      fiscalCalendar: state.calendar,
      tenantID: authenticatedUser.tenant.fsDocID,
    });
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, calendar: fiscalCalendar }));

    if (fiscalCalendar) {
      setState((prev) => ({
        ...prev,
        selectedYearNumber: getClosestFiscalYearToNow(fiscalCalendar),
      }));
    }
  }, [fiscalCalendar]);

  const [minYearNumber, maxYearNumber] = fiscalYearsData.yearKeys.length
    ? [
        fiscalYearsData.yearKeys[0] ?? null,
        fiscalYearsData.yearKeys[fiscalYearsData.yearKeys.length - 1] ?? null,
      ]
    : [null, null];

  const isSelectedYearRemovable =
    !!state.selectedYearNumber &&
    (state.selectedYearNumber === minYearNumber ||
      state.selectedYearNumber === maxYearNumber);

  const prevYearNumber = String(Number(state.selectedYearNumber) - 1);
  const prevYear = fiscalYearsData.yearMap[prevYearNumber]
    ? fiscalYearsData.yearMap[prevYearNumber]
    : null;

  const nextYearNumber = String(Number(state.selectedYearNumber) + 1);
  const nextYear = fiscalYearsData.yearMap[nextYearNumber]
    ? fiscalYearsData.yearMap[nextYearNumber]
    : null;

  const addedCount = fiscalYearsData.yearKeys.filter(
    (yearNumber) => !initialFiscalYearsData.yearKeys.includes(yearNumber)
  ).length;

  const removedCount = initialFiscalYearsData.yearKeys.filter(
    (yearNumber) => !fiscalYearsData.yearKeys.includes(yearNumber)
  ).length;

  const updatedCount = fiscalYearsData.yearKeys.filter((yearNumber) => {
    const prevVersion = initialFiscalYearsData.yearMap[yearNumber]
      ? initialFiscalYearsData.yearMap[yearNumber]
      : null;

    if (!prevVersion) return false;

    const updatedVersion = fiscalYearsData.yearMap[yearNumber];

    return !isEqual(prevVersion, updatedVersion);
  }).length;

  const isCalendarChanged = addedCount + removedCount + updatedCount > 0;

  const yearValidationResults = fiscalYearsData.yearKeys.map(
    (yearNumber) =>
      validateFiscalYear(
        yearNumber,
        fiscalYearsData
      ) as FiscalYearValidationResult
  );

  const invalidYearValidationResults = yearValidationResults.filter(
    (result) => !result.canSave
  );

  const canSave =
    yearValidationResults.length > 0 &&
    invalidYearValidationResults.length === 0;

  function renderUpdatedByUserCaption() {
    if (isLoadingTenantUsers) return <LoadingSpinner />;
    if (!users) return;

    const usersKeyedByID = keyBy(users, "id");

    const updatedByUserName = authenticatedUser.tenant.updatedByID
      ? getFullName(usersKeyedByID[authenticatedUser.tenant.updatedByID])
      : null;

    const updatedAt = authenticatedUser.tenant.updatedAt
      ? format(new Date(authenticatedUser.tenant.updatedAt), "yyyy/M/d h:mm aa")
      : null;

    return (
      <Box>
        {updatedByUserName && updatedAt && (
          <Text
            color={theme.text_color_disabled}
            fontSize={theme.fontSize_small}
            whiteSpace="nowrap"
          >
            {copyText.fiscalCalendarChangeUpdatedBy}
            <Box as="span" fontWeight={theme.fontWeight_bold}>
              {updatedByUserName}
            </Box>
            {copyText.fiscalCalendarChangeAt}
            <Box as="span" fontWeight={theme.fontWeight_bold}>
              {updatedAt}
            </Box>
          </Text>
        )}
      </Box>
    );
  }

  return (
    <Flex
      direction="column"
      height={`calc(100% - ${TABS_HEADER_HEIGHT}px)`}
      minHeight={675}
    >
      {state.showAddYearModal && (
        <AddYearModal
          calendar={state.calendar}
          yearOptions={
            minYearNumber === null || maxYearNumber === null
              ? "all"
              : [
                  String(Number(minYearNumber) - 1),
                  String(Number(maxYearNumber) + 1),
                ]
          }
          onInteraction={handleInteraction}
        />
      )}
      <Flex
        flex="0 0 auto"
        alignItems="flex-start"
        justifyContent="space-between"
        marginTop={theme.space_sm}
        marginBottom={theme.space_lg}
      >
        <Box flex="1 1 300px">
          <Text fontSize={theme.h4_fontSize}>
            {copyText.fiscalCalendarBuilderHeader}
          </Text>
        </Box>

        <Flex flex="1 1 300px" justifyContent="flex-end">
          {!!state.selectedYearNumber && (
            <Tooltip
              hide={isSelectedYearRemovable}
              content={copyText.fiscalCalendarRemoveYearWarning}
            >
              <Box>
                <Button
                  disabled={!isSelectedYearRemovable}
                  iconStart={<Icon icon={faMinus} />}
                  onClick={removeSelectedYear}
                  primary
                  type="button"
                  width={140}
                >
                  {copyText.fiscalCalendarRemoveYear}
                </Button>
              </Box>
            </Tooltip>
          )}

          <Button
            iconStart={<Icon icon={faPlus} />}
            marginLeft={theme.space_sm}
            onClick={() => mergeState({ showAddYearModal: true })}
            primary
            type="button"
            width={140}
          >
            {copyText.fiscalCalendarAddYear}
          </Button>
        </Flex>
      </Flex>

      <Flex justifyContent="center" marginBottom={theme.space_lg}>
        {renderYearPicker()}
      </Flex>

      <Flex flex="1 0 0" justifyContent="space-between">
        <Flex width={250} direction="column" zIndex={3}>
          {fiscalYearsData.yearKeys.length > 0 && (
            <>
              <Card>
                <TodayInfo
                  onInteraction={handleInteraction}
                  fiscalYearsData={fiscalYearsData}
                />
              </Card>

              {!!state.selectedYearNumber && (
                <Box flex="1 0 0" minHeight={0} marginTop={theme.space_xl}>
                  <FiscalCalendarYearsOverviewList
                    key={String(isVisible)}
                    selectedYearNumber={state.selectedYearNumber}
                    fiscalYearsData={fiscalYearsData}
                    onInteraction={handleInteraction}
                  />
                </Box>
              )}

              <Flex
                direction="column"
                justifyContent="center"
                height={56}
                overflowX="visible"
                marginTop={theme.space_sm}
              >
                {renderUpdatedByUserCaption()}
              </Flex>
            </>
          )}
        </Flex>

        <Flex
          direction="column"
          flex="1 1 auto"
          marginHorizontal={theme.space_xxl}
          maxWidth={750}
          zIndex={2}
        >
          {fiscalYearsData.yearKeys.length > 0 ? (
            <>
              <FiscalCalendarView
                fiscalYear={
                  state.selectedYearNumber &&
                  fiscalYearsData.yearMap[state.selectedYearNumber]
                    ? fiscalYearsData.yearMap[state.selectedYearNumber]
                    : null
                }
              />

              <Box flex="1 0 0" maxHeight={300} />

              {renderSaveResetButtons()}
            </>
          ) : (
            <Flex justifyContent="center">
              <Box marginTop={theme.space_xl}>
                <Text
                  color={theme.text_color_caption}
                  fontSize={theme.h3_fontSize}
                >
                  {copyText.fiscalCalendarNotConfigured}
                </Text>
                <Text
                  color={theme.text_color_caption}
                  fontSize={theme.h3_fontSize}
                  marginBottom={theme.space_md}
                >
                  {copyText.fiscalCalendarNotConfiguredAddYear}
                </Text>
              </Box>
            </Flex>
          )}
        </Flex>

        <Flex direction="column" width={250} zIndex={1}>
          {!!state.selectedYearNumber &&
            fiscalYearsData.yearKeys.length > 0 && (
              <Card>
                <FiscalCalendarYearForm
                  key={state.selectedYearNumber}
                  calendar={state.calendar}
                  fiscalYearNumber={state.selectedYearNumber}
                  onInteraction={handleInteraction}
                />
                <Box marginTop={theme.space_md}>
                  <FiscalYearValidations
                    key={state.selectedYearNumber}
                    fiscalYearsData={fiscalYearsData}
                    hideNoIssues
                    yearNumber={state.selectedYearNumber}
                  />
                </Box>
              </Card>
            )}
        </Flex>
      </Flex>
    </Flex>
  );

  function renderYearPicker() {
    if (fiscalYearsData.yearKeys.length === 0) return null;

    return (
      <Box>
        <Text
          fontSize={theme.fontSize_small}
          fontWeight={theme.fontWeight_bold}
          align="center"
        >
          {copyText.fiscalCalendarYearPickerLabel}
        </Text>
        <Flex>
          <Flex alignItems="center" flex="1 0 50px" justifyContent="flex-end">
            {prevYear && (
              <Box
                color={theme.text_color_caption}
                cursor="pointer"
                onClick={() => selectAdjacentYear(-1)}
                padding={theme.space_sm}
              >
                <Icon clickable icon={faChevronLeft} />
              </Box>
            )}
          </Flex>
          <Box flex="0 0 100px">
            <DatePicker
              placeholderText={copyText.fiscalCalendarYearPickerPlaceholder}
              selected={
                state.selectedYearNumber
                  ? new Date(Number(state.selectedYearNumber), 0, 1)
                  : undefined
              }
              onChange={(date) =>
                mergeState({
                  selectedYearNumber: date ? format(date, "yyyy") : null,
                })
              }
              minDate={
                minYearNumber ? new Date(Number(minYearNumber), 0, 1) : null
              }
              maxDate={
                maxYearNumber ? new Date(Number(maxYearNumber), 0, 1) : null
              }
              showYearPicker
              dateFormat="yyyy"
            />
          </Box>
          <Flex alignItems="center" flex="1 0 50px">
            {nextYear && (
              <Box
                color={theme.text_color_caption}
                cursor="pointer"
                onClick={() => selectAdjacentYear(1)}
                padding={theme.space_sm}
              >
                <Icon clickable icon={faChevronRight} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  }

  function renderSaveResetButtons() {
    return (
      <Flex>
        <Box flex="0 0 30px" />
        <Box flex="1 0 0" />

        <Card
          flex="4 0 300px"
          padding={theme.space_xs}
          marginTop={theme.space_sm}
        >
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            gridColumnGap={theme.space_sm}
          >
            <Button
              disabled={!isCalendarChanged || isUpdatingCalendar}
              secondary
              fullWidth
              onClick={() =>
                mergeState({
                  calendar: fiscalCalendar,
                  selectedYearNumber: getClosestFiscalYearToNow(fiscalCalendar),
                })
              }
              type="button"
            >
              {copyText.fiscalCalendarSaveReset}
            </Button>

            <Button
              disabled={!isCalendarChanged || isUpdatingCalendar || !canSave}
              fullWidth
              onClick={updateCalendar}
              primary
              type="button"
            >
              {isUpdatingCalendar ? (
                <LoadingSpinner />
              ) : (
                copyText.fiscalCalendarSaveUpdate
              )}
            </Button>

            <Box>
              {isCalendarChanged && (
                <Box>
                  {!canSave && (
                    <Text
                      align="right"
                      color={theme.feedback_negative}
                      fontSize={theme.fontSize_small}
                      lineHeight={1.3}
                    >
                      {invalidYearValidationResults.length === 1
                        ? copyText.fiscalCalendarSaveHasError
                        : copyText.fiscalCalendarSaveHasErrors.replace(
                            "%COUNT%",
                            String(invalidYearValidationResults.length)
                          )}
                    </Text>
                  )}

                  {addedCount > 0 && (
                    <Text
                      align="right"
                      color={theme.text_color_caption}
                      fontSize={theme.fontSize_small}
                      lineHeight={1.3}
                    >
                      {addedCount === 1
                        ? copyText.fiscalCalendarSaveAddedOne
                        : copyText.fiscalCalendarSaveAddedMany.replace(
                            "%COUNT%",
                            String(addedCount)
                          )}
                    </Text>
                  )}

                  {removedCount > 0 && (
                    <Text
                      align="right"
                      color={theme.text_color_caption}
                      fontSize={theme.fontSize_small}
                      lineHeight={1.3}
                    >
                      {removedCount === 1
                        ? copyText.fiscalCalendarSaveRemovedOne
                        : copyText.fiscalCalendarSaveRemovedMany.replace(
                            "%COUNT%",
                            String(removedCount)
                          )}
                    </Text>
                  )}

                  {updatedCount > 0 && (
                    <Text
                      align="right"
                      color={theme.text_color_caption}
                      fontSize={theme.fontSize_small}
                      lineHeight={1.3}
                    >
                      {updatedCount === 1
                        ? copyText.fiscalCalendarSaveChangedOne
                        : copyText.fiscalCalendarSaveChangedMany.replace(
                            "%COUNT%",
                            String(updatedCount)
                          )}
                    </Text>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Card>

        <Box flex="1 0 0" />
        <Box flex="0 0 30px" />
      </Flex>
    );
  }
}

function getClosestFiscalYearToNow(calendar: EditedFiscalCalendar | null) {
  const now = new DateHelper().date;
  const createdYears = createFiscalYearsData({ calendar });

  const closestYearNumber = createdYears.yearKeys.reduce(
    (bestYearNumber, thisYearNumber) => {
      if (bestYearNumber === null) return thisYearNumber;

      const bestYear = createdYears.yearMap[bestYearNumber];
      const thisYear = createdYears.yearMap[thisYearNumber];

      const bestDiff = differenceInDays(
        now,
        fiscalDateFns.stringToDate(bestYear.startDate)
      );
      const thisDiff = differenceInDays(
        now,
        fiscalDateFns.stringToDate(thisYear.startDate)
      );

      if (bestDiff < 0 || thisDiff < 0) {
        // if at least one is negative, get the least negative
        return bestDiff > thisDiff ? bestYearNumber : thisYearNumber;
      }

      // if both are positive, get the smallest
      return bestDiff < thisDiff ? bestYearNumber : thisYearNumber;
    },
    null as null | string
  );

  return closestYearNumber;
}
