import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  SnowflakeDatabaseUsageBytesDatum,
  SnowflakeDatabaseUsageBytesStruct,
} from "../types";

export default function useGetSnowflakeDatabaseUsageBytes(
  options?: UseQueryOptions<SnowflakeDatabaseUsageBytesDatum, UError>
): UseQueryResult<SnowflakeDatabaseUsageBytesDatum, UError> {
  const client = useAnalyticsApiClient();
  const now = new DateHelper();

  return useQuery({
    queryKey: ["snowflakeDatabaseUsageBytes"],
    queryFn: async () => {
      const measures = ["databaseBytes", "failsafeBytes"];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.SNOWFLAKE_DATABASE_USAGE_METER_HELPER,
          dateRange: [now.firstOfMonth(), now.date],
          measures,
        })
      );

      const data = result.map((datum): SnowflakeDatabaseUsageBytesDatum => {
        const [error, validData] = validate(
          datum,
          SnowflakeDatabaseUsageBytesStruct
        );
        if (error) {
          throw new UError("INVALID_DATABASE_BYTES_DATA", {
            context: { error, result: datum },
          });
        }

        return {
          databaseBytes: validData.databaseBytes ?? 0,
          failsafeBytes: validData.failsafeBytes ?? 0,
        };
      });

      return data[0];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
