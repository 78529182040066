export function removeAppLoader(): void {
  const appLoader = document.getElementById("app-loader");

  if (appLoader && appLoader.parentNode) {
    appLoader.parentNode.removeChild(appLoader);
  } else {
    console.log("Element does not exist");
  }
}

export default {
  removeAppLoader,
};
