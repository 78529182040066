import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";

type Validation = Awaited<
  ReturnType<CoreAPIClient["validateDataIntegration"]>
>[0];

type Options = UseMutationOptions<
  Validation[],
  CoreAPIClientError,
  { integrationID: string }
>;

type Result = UseMutationResult<
  Validation[],
  CoreAPIClientError,
  { integrationID: string }
>;

export default function useValidateDataIntegration(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ integrationID }) =>
      client.validateDataIntegration(integrationID),
    ...options,
  });
}
