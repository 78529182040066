import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, Operator } from "@ternary/api-lib/constants/enums";
import { keyBy } from "lodash";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  bigQueryUsageDimensions,
  bigQueryUsageMeasures,
} from "../bigQuerySchema";
import { GcpBigQueryDatasetGroupEntity } from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
}

export type BigQueryDatasetGroup = {
  onDemandCost: number;
  datasetId: string;
  jobCount: number;
  maxJobSlots: number;
  projectId: string;
  queryBytes: number;
  queryCount: number;
  reservationCount: number;
};

export default function useGetBigQueryDatasetGroups(
  params: Params,
  options?: UseQueryOptions<BigQueryDatasetGroup[], UError>
): UseQueryResult<BigQueryDatasetGroup[], UError> {
  const client = useAnalyticsApiClient();

  // Query 1: Get cost, job count, max slots
  const costDimensions = [
    bigQueryUsageDimensions.datasetID,
    bigQueryUsageDimensions.projectID,
  ];

  const costMeasures = [
    bigQueryUsageMeasures.onDemandCost,
    bigQueryUsageMeasures.jobCount,
    bigQueryUsageMeasures.maxJobSlots,
    bigQueryUsageMeasures.reservationCount,
  ];

  const [tenantID, costQuery] = useBuildDataQuery({
    dataSource: DataSource.BIGQUERY_USAGE,
    dateRange: params.dateRange,
    dimensions: costDimensions,
    measures: costMeasures,
    preAggFilters: params.queryFilters,
  });

  // Query 2: Get Query Count and Query Bytes
  const queryDimensions = [
    bigQueryUsageDimensions.datasetID,
    bigQueryUsageDimensions.projectID,
  ];

  const queryMeasures = [
    bigQueryUsageMeasures.jobCount,
    bigQueryUsageMeasures.totalBytesBilled,
  ];

  const [_, queryQuery] = useBuildDataQuery({
    dataSource: DataSource.BIGQUERY_USAGE,
    dateRange: params.dateRange,
    dimensions: queryDimensions,
    measures: queryMeasures,
    preAggFilters: [
      ...(params.queryFilters ? params.queryFilters : []),
      {
        name: bigQueryUsageDimensions.jobType,
        operator: Operator.CONTAINS,
        values: ["QUERY"],
      },
    ],
  });

  return useQuery({
    queryKey: ["bigQueryDatasetGroups", params],
    queryFn: async () => {
      const costResult = await client.loadData<GcpBigQueryDatasetGroupEntity>(
        tenantID,
        costQuery
      );
      const costResultsKeyedByDatasetAndProjectID = keyBy(
        costResult.response,
        (datum) => `${datum.datasetId} - ${datum.projectId}`
      );

      const queryResult = await client.loadData<GcpBigQueryDatasetGroupEntity>(
        tenantID,
        queryQuery
      );

      const resources = queryResult.response.map(
        (datum): BigQueryDatasetGroup => {
          const key = `${datum.datasetId} - ${datum.projectId}`;

          return {
            // DIMENSIONS
            datasetId: datum.datasetId,
            projectId: datum.projectId,

            // MEASURES
            jobCount: costResultsKeyedByDatasetAndProjectID[key].jobCount ?? 0,
            maxJobSlots:
              costResultsKeyedByDatasetAndProjectID[key].maxJobSlots ?? 0,
            onDemandCost:
              costResultsKeyedByDatasetAndProjectID[key].onDemandCost ?? 0,
            queryBytes: datum.totalBytesBilled,
            queryCount: datum.jobCount,
            reservationCount:
              costResultsKeyedByDatasetAndProjectID[key].reservationCount ?? 0,
          };
        }
      );

      return resources;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
