import Select from "@/ui-lib/components/Select";
import styled from "@emotion/styled";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import copyText from "../../copyText";

const StyledDiv = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  ul {
    background-color: black;
    z-index: 10000000000000;
  }
`;

const StyledSelectWrapper = styled.div<{ due: boolean }>`
  width: 20%;
  margin: 0 1%;
  box-shadow: ${(props) =>
    props.due
      ? `0 0 0 2px ${(props) => props.theme.primary_color_focus}`
      : "unset"};
  transition: box-shadow 200ms;
  border-radius: 8px;
`;

const StyledOptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  span:first-of-type {
    margin-right: 1rem;
  }
`;

type Cloud = {
  id: string;
  name: string;
};

type Family = {
  cost: number;
  name: string;
};

type Region = {
  cost: number;
  name: string;
};

interface Props {
  gcpIntegrations: Cloud[];
  families: Family[];
  isDisabledCloudFilter: boolean;
  isDisabledFamilyFilter: boolean;
  isDisabledProjectFilter: boolean;
  isDisabledRegionFilter: boolean;
  isEnabledCUDSharing: boolean;
  isLoadingCloudFilter: boolean;
  isLoadingFamilies: boolean;
  isLoadingRegions: boolean;
  regions: Region[];
  selectedCloudID: string | null;
  selectedFamily: string | null;
  selectedRegion: string | null;
  onChangeCloud: (selection: string) => void;
  onChangeFamily: (selection: string) => void;
  onChangeRegion: (selection: string) => void;
}

const CUDFilters = (props: Props): JSX.Element => {
  const integrationOptions = props.gcpIntegrations.map((integration) => {
    return {
      value: integration.id,
      label: integration.name,
    };
  });

  const regionOptions = props.regions.map((region) => {
    return {
      value: region.name,
      label: (
        <StyledOptionWrapper>
          <span>{region.name}</span>
          <span>
            {formatCurrencyRounded({
              number: region.cost,
            })}
          </span>
        </StyledOptionWrapper>
      ),
    };
  });

  const familyOptions = props.families.map((family) => {
    return {
      value: family.name,
      label: (
        <StyledOptionWrapper>
          <span>{family.name}</span>
          <span>
            {formatCurrencyRounded({
              number: family.cost,
            })}
          </span>
        </StyledOptionWrapper>
      ),
    };
  });

  const selectedCloud = integrationOptions.find((option) => {
    return option.value === props.selectedCloudID;
  });

  const selectedRegion = regionOptions.find((region) => {
    return region.value === props.selectedRegion;
  });

  const selectedFamily = familyOptions.find((family) => {
    return family.value === props.selectedFamily;
  });

  return (
    <StyledDiv>
      <StyledSelectWrapper due={!selectedCloud}>
        <Select
          disabled={props.isDisabledCloudFilter}
          isLoading={props.isLoadingCloudFilter}
          options={integrationOptions}
          placeholder={copyText.cudFilterPlaceholderCloud}
          value={selectedCloud}
          onChange={(option) => option && props.onChangeCloud(option.value)}
        />
      </StyledSelectWrapper>
      <StyledSelectWrapper due={Boolean(selectedCloud && !selectedRegion)}>
        <Select
          disabled={props.isDisabledRegionFilter || props.isLoadingRegions}
          isLoading={props.isLoadingRegions}
          options={regionOptions}
          placeholder={copyText.cudFilterPlaceholderRegion}
          value={selectedRegion}
          onChange={(option) => option && props.onChangeRegion(option.value)}
        />
      </StyledSelectWrapper>
      <StyledSelectWrapper
        due={Boolean(selectedCloud && selectedRegion && !selectedFamily)}
      >
        <Select
          disabled={props.isDisabledFamilyFilter || props.isLoadingFamilies}
          isLoading={props.isLoadingFamilies}
          options={familyOptions}
          placeholder={copyText.cudFilterPlaceholderFamily}
          value={selectedFamily}
          onChange={(option) => option && props.onChangeFamily(option.value)}
        />
      </StyledSelectWrapper>
    </StyledDiv>
  );
};

export default React.memo(
  (props: Props) => <CUDFilters {...props} />,
  (prevProps, newProps) => {
    const areEqual = Object.keys(prevProps).reduce((accum, key) => {
      return prevProps[key] === newProps[key] && accum;
    }, true);

    // if this comparison function returns true, then the update is skipped
    return areEqual;
  }
);
