import Card from "@/components/Card";
import Divider from "@/ui-lib/components/Divider";
import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React, { useLayoutEffect, useRef } from "react";
import { FiscalYearsData, fiscalDateFns } from "../../hooks/useFiscalYearsData";
import { FiscalYear } from "../../types";
import FiscalYearValidations from "./FiscalYearValidations";

type Props = {
  fiscalYearsData: FiscalYearsData;
  selectedYearNumber: string;
  onInteraction: (
    interaction: FiscalCalendarYearsOverviewList.Interaction
  ) => void;
};

function FiscalCalendarYearsOverviewList(props: Props) {
  const displayElementRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (displayElementRef.current) {
      displayElementRef.current.scrollIntoView({ block: "center" });
    }
  }, [props.fiscalYearsData.yearKeys.join("-"), props.selectedYearNumber]);

  if (props.fiscalYearsData.yearKeys.length === 0) return null;

  return (
    <Box width="100%" height="100%" overflowY="auto">
      {props.fiscalYearsData.yearKeys.map((yearNumber) => (
        <BasicDisplay
          key={yearNumber}
          fiscalYearsData={props.fiscalYearsData}
          isSelectedYear={yearNumber === props.selectedYearNumber}
          onSelect={() =>
            props.onInteraction({
              type: FiscalCalendarYearsOverviewList.INTERACTION_CLICK_YEAR,
              yearNumber,
            })
          }
          ref={(element) => {
            if (yearNumber === props.selectedYearNumber) {
              displayElementRef.current = element;
            }
          }}
          year={props.fiscalYearsData.yearMap[yearNumber]}
        />
      ))}
    </Box>
  );
}

type BasicDisplayProps = {
  fiscalYearsData: FiscalYearsData;
  isSelectedYear?: boolean;
  onSelect: () => void;
  year: FiscalYear;
};

const BasicDisplay = React.forwardRef<HTMLDivElement, BasicDisplayProps>(
  function _BasicDisplay(props, ref) {
    const theme = useTheme();

    const formattedDateRange =
      format(fiscalDateFns.stringToDate(props.year.startDate), "MM/dd/yy") +
      " - " +
      format(fiscalDateFns.stringToDate(props.year.endDate), "MM/dd/yy");

    return (
      <Card
        border={
          props.isSelectedYear
            ? `2px solid ${theme.primary_color_border}`
            : undefined
        }
        innerRef={ref}
        marginBottom={theme.space_sm}
        padding="0"
      >
        <Box
          paddingHorizontal={theme.space_sm}
          paddingVertical={theme.space_xs}
        >
          <Button onClick={props.onSelect} fullWidth>
            <Text fontSize={theme.h4_fontSize} fontWeight={theme.h4_fontWeight}>
              {props.year.yearNumber}
            </Text>
          </Button>
        </Box>

        <Divider margin={0} />

        <Box
          paddingHorizontal={theme.space_md}
          paddingVertical={theme.space_sm}
        >
          <Text fontWeight={theme.fontWeight_bold} whiteSpace="nowrap">
            {formattedDateRange}
          </Text>
          <Text>{props.year.weekCount} weeks</Text>
          <Text>
            {fiscalDateFns.getDayOfWeekCopyText(props.year.startDayOfWeek) +
              " - " +
              fiscalDateFns.getDayOfWeekCopyText(
                fiscalDateFns.getDayOfWeek(props.year.endDate)
              )}
          </Text>
          <Text>{props.year.quarterPattern}</Text>
        </Box>

        <Divider margin={0} />

        <Box
          paddingHorizontal={theme.space_md}
          paddingVertical={theme.space_sm}
        >
          <FiscalYearValidations
            fiscalYearsData={props.fiscalYearsData}
            hideSuccess
            yearNumber={props.year.yearNumber}
          />
        </Box>
      </Card>
    );
  }
);

FiscalCalendarYearsOverviewList.INTERACTION_CLICK_YEAR =
  "FiscalCalendarYearsOverviewList.INTERACTION_CLICK_YEAR" as const;

interface InteractionClickYear {
  type: typeof FiscalCalendarYearsOverviewList.INTERACTION_CLICK_YEAR;
  yearNumber: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FiscalCalendarYearsOverviewList {
  export type Interaction = InteractionClickYear;
}

export default FiscalCalendarYearsOverviewList;
