export function hasProperty<ObjectType extends object, Prop extends string>(
  object: ObjectType,
  prop: Prop
): object is ObjectType & Record<Prop, unknown> {
  return Object.prototype.hasOwnProperty.call(object, prop);
}

export function getEnumAtKey<T extends { [key: string]: string }>(
  enumMap: T,
  key: string
): T[keyof T] | null {
  if (hasProperty(enumMap, key)) return enumMap[key as keyof T];
  return null;
}
