import { FiscalPeriodMap, LabelMap, LabelMapsEntity } from "@/api/core/types";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createFiscalDates } from "@ternary/api-lib/analytics/fiscalDateUtils";
import { Order, QueryFilter, RawData } from "@ternary/api-lib/analytics/types";
import { getLabelMappedData } from "@ternary/api-lib/analytics/utils";
import {
  DataSource,
  DurationType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { getPaginatedResults } from "../utils";

export interface Params {
  dataSource: DataSource;
  dateRange: Date[] | [string, string];
  dimensions?: string[];
  durationType?: DurationType;
  fillMissingDates?: boolean;
  fiscalPeriodMap?: FiscalPeriodMap | null;
  isComparisonMode?: boolean;
  isFiscalMode?: boolean;
  granularity?: TimeGranularity;
  limit?: number;
  measures: string[];
  order?: Order;
  queryFilters?: QueryFilter[];
  queryKey?: string[];
}

export default function useGetPaginatedRawData(
  params: Params,
  options?: UseQueryOptions<RawData[], UError>
): UseQueryResult<RawData[], UError> {
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  const { queryKey } = params;

  return useQuery({
    queryKey: ["rawCSVData", ...(queryKey ? queryKey : [])],
    queryFn: async () => {
      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const labelMap: LabelMap = labelMaps[params.dataSource] ?? {};

      const reversedLabelMap = Object.entries(labelMap).reduce(
        (accum: { [key: string]: string }, [key, value]) => ({
          ...accum,
          [value]: key,
        }),
        {}
      );

      let result = await getPaginatedResults({
        client,
        numberOfQueries: 2,
        offset: 0,
        previousResults: [],
        queryParams: params,
      });

      if (params.isFiscalMode && params.granularity) {
        result = createFiscalDates(result, params.granularity);
      }

      return getLabelMappedData(result, reversedLabelMap);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
