/*
  This is helper function that wraps the state setting return value of useState
  It attempts to recreate setState() behavior from class-based components

  It assumes:
  - your state is a keyed object
  - you will call this function with a partial of that state to update it

  This defaults to using the callback syntax, which always merges your state
  into the most recent value of your state. This is important when doing async
  state updates, because doing something like:
  
  setState({...state, foo: bar})

  will undo any changes to state that have occured since handing this function off to
  the Promise API. So if we're blocked on an API call, state may change before we come
  back to update it with the results. Example:

  ** Start: **
  We call getData(), below:

  const [state, setState] = useState({data: [], loading: false, danger: 0})

  getData() {
    setState({...state, loading: true})
    const res = await apiCall() // Hands off rest of execution to Promise API
    setState({...state, loading: false, data: res})
  }

  ** Middle: **
  While waiting, a different action occurs and turns danger to 1.
    setState({...state, danger: 1})

  ** End: **
  The promise in getData() resolves and when it calls setState, its state value
  is still referencing the value of state from the start

  So the ""...state" portion of things has danger = 0, and we undo the middle change  
*/
export default function getMergeState<State>(
  ss: React.Dispatch<React.SetStateAction<State>>
) {
  return function mergeState(ps: Partial<State>) {
    return ss((s: State) => ({ ...s, ...ps }));
  };
}
