import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import Box from "../components/Box";
import Flex from "../components/Flex";
import Text from "../components/Text";
import copyText from "../copyText";
import { Theme } from "../theme/default";
import { ChartDatum } from "./types";
import {
  formatMeasureValueWithUnit,
  getFillByGrouping,
  getFillByIndex,
  StrictCustomColors,
} from "./utils";

export const TOTAL_KEY = "TOTAL_KEY";

const DEFAULT_MAX_ITEMS = 21;

const StyledTable = styled.table`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize_ui};

  td:nth-of-type(1) {
    padding-right: 1.5rem;
  }
  td:nth-of-type(2) {
    padding-right: 0.5rem;
    text-align: right;
  }
  td:nth-of-type(3) {
    text-align: right;
  }
`;

interface Props {
  currencyCode?: string;
  customColors?: string[];
  customColorsKeyedByGrouping?: StrictCustomColors;
  entry: ChartDatum;
  excludedGroupings: string[];
  hideTotal?: boolean;
  label: string;
  limit?: number | null;
  reverse?: boolean;
  reverseSortedGroupings: string[];
  tooltipDataKey?: string;
  unitType?: string;
  formatter?: (value: any, grouping: string) => string;
}

export default function SimpleChartTooltip(props: Props): JSX.Element | null {
  const theme = useTheme();

  const MAX_ITEMS = props.limit ?? DEFAULT_MAX_ITEMS;

  const entry = props.entry || {};

  const sortedGroupings = useMemo(() => {
    const applicableKeys = Object.keys(props.entry ?? {});

    const applicableGroupings = props.reverseSortedGroupings.filter(
      (grouping: string) => applicableKeys.includes(grouping)
    );

    if (props.reverse) {
      return applicableGroupings;
    }

    return [...applicableGroupings].reverse();
  }, [props.reverseSortedGroupings, props.entry]);

  if (props.label === undefined) {
    return null;
  }

  const totalRowsToShow = sortedGroupings.filter(
    (grouping) => !props.excludedGroupings.includes(grouping)
  ).length;

  const moreCount = totalRowsToShow - MAX_ITEMS;

  const total = getTotal(
    sortedGroupings.filter(
      (grouping) => !props.excludedGroupings.includes(grouping)
    ),
    entry
  );

  return (
    <Box
      backgroundColor={theme.tooltip_background_color}
      marginHorizontal={theme.space_md}
      padding="0.25rem"
      zIndex={theme.zIndex_800}
    >
      <Box
        borderBottom={`1px solid ${theme.border_color_dark}`}
        color={theme.text_color_inverse}
        paddingBottom={"0.25rem"}
        marginBottom={"0.25rem"}
      >
        <Flex justifyContent="space-between">
          <Text color={theme.tooltip_text_color}>{props.label}</Text>
          {!props.hideTotal && (
            <Text
              color={theme.text_color_inverse}
              fontWeight={theme.fontWeight_bold}
              marginLeft={theme.space_sm}
            >
              {props.formatter
                ? props.formatter(total, TOTAL_KEY)
                : formatMeasureValueWithUnit({
                    currencyCode: props.currencyCode,
                    unit: props.unitType,
                    value: total,
                  })}
            </Text>
          )}
        </Flex>
      </Box>
      <StyledTable>
        <tbody>
          {sortedGroupings
            .map((grouping: string, i) =>
              renderRow(
                props,
                props.currencyCode,
                entry as { [key: string]: number },
                grouping,
                i,
                theme
              )
            )
            .filter(
              (row) => !props.excludedGroupings.includes(row.key as string)
            )
            .slice(0, MAX_ITEMS)}
          <tr>
            {moreCount > 0 && !props.limit && (
              <>
                <td />
                <td />
                <td>
                  <Box
                    color={theme.text_color_inverse}
                  >{`${moreCount} ${copyText.moreOptionsLabel}`}</Box>
                </td>
              </>
            )}
          </tr>
        </tbody>
      </StyledTable>
    </Box>
  );
}

function renderRow(
  props: Props,
  currencyCode: string | undefined,
  entry: { [grouping: string]: number },
  grouping: string,
  index: number,
  theme: Theme
) {
  const selected = grouping === props.tooltipDataKey;

  const colorsKeyedByIndex = getColors(
    props.customColors || theme.data_visualization_colors,
    props.reverse
  );

  return (
    <tr key={grouping}>
      <td>
        <Box
          backgroundColor={
            props.customColorsKeyedByGrouping
              ? getFillByGrouping(grouping, props.customColorsKeyedByGrouping)
              : getFillByIndex(index, colorsKeyedByIndex)
          }
          maxWidth={`calc(${theme.space_xxs} * 2)`}
          padding={theme.space_xxs}
        />
      </td>
      <td>
        <Box maxWidth="300px">
          <Text
            color={
              selected
                ? theme.tooltip_text_color_hover
                : theme.tooltip_text_color
            }
            fontSize={theme.fontSize_small}
            overflowWrap="break-word"
          >
            {grouping}:
          </Text>
        </Box>
      </td>
      <td>
        <Text
          color={
            selected ? theme.tooltip_text_color_hover : theme.tooltip_text_color
          }
          fontSize={theme.fontSize_small}
        >
          {props.formatter
            ? props.formatter(entry[grouping], grouping)
            : formatMeasureValueWithUnit({
                currencyCode,
                unit: props.unitType,
                value: (entry[grouping] as number) || 0,
              })}
        </Text>
      </td>
    </tr>
  );
}

function getTotal(groupings: string[], entry: ChartDatum) {
  return groupings.reduce(
    (total, grouping) => total + ((entry[grouping] as number) || 0),
    0
  );
}

function getColors(colors: string[], reverse?: boolean) {
  if (reverse) {
    return [...colors].reverse();
  } else return [...colors];
}
