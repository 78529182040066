import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type DashboardGates = ReturnType<typeof dashboardGates>;

export default function dashboardGates(params: GateGetterParameters) {
  const canCreateSystemDashboard = params._permissionsIncludeAllOf([
    Permission.CREATE_DASHBOARD_SYSTEM,
  ]);

  const canDistributeSystemDashboard = params._permissionsIncludeAllOf([
    Permission.DISTRIBUTE_SYSTEM_DASHBOARD,
  ]);

  const canListSystemDashboards = params._permissionsIncludeAllOf([
    Permission.READ_DASHBOARDS_SYSTEM,
  ]);

  const canRemoveSystemDashboard = params._permissionsIncludeAllOf([
    Permission.REMOVE_SYSTEM_DASHBOARD,
  ]);

  const canUpdateDashboard = (createdByID: string) =>
    params._permissionsIncludeAllOf([Permission.UPDATE_DASHBOARD]) ||
    createdByID === params.authenticatedUser.id;

  return {
    canCreateDashboards: params._permissionsIncludeAllOf([
      Permission.CREATE_DASHBOARD,
    ]),
    canCreateSystemDashboard,
    canDistributeSystemDashboard,
    canListDashboards: params._permissionsIncludeAllOf([
      Permission.READ_DASHBOARDS,
    ]),
    canListSystemDashboards,
    canRemoveSystemDashboard,
    canUpdateDashboard,
    canUpdateSystemDashboard: params._permissionsIncludeAllOf([
      Permission.UPDATE_DASHBOARD_SYSTEM,
    ]),
    getCanDeleteSpecificDashboard: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_DASHBOARD]) ||
      Boolean(createdByID === params.authenticatedUser.id),
  };
}
