import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type UserEntity = Awaited<ReturnType<CoreAPIClient["getUsersByTenantID"]>>[0];

export default function useGetUsersByIDs(
  userIDs: string[],
  options?: UseQueryOptions<UserEntity[], CoreAPIClientError>
): UseQueryResult<UserEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["users", userIDs],
    queryFn: () => client.getUsersByIDs(userIDs),
    ...options,
  });
}
