import { useMatchPath } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { DurationType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import React, { useEffect, useState } from "react";
import { DateParam, createEnumParam, useQueryParams } from "use-query-params";
import copyText from "../constants/copyText";
import { locationStateKeyedByPath } from "../constants/paths";
import { useGlobalDateStore } from "../context/GlobalDateProvider";
import useAuthenticatedUser from "../hooks/useAuthenticatedUser";
import { DEFAULT_GLOBAL_DURATION_TYPE } from "../hooks/useAvailableGlobalDate";
import Switch from "../ui-lib/components/Switch";
import getMergeState from "../utils/getMergeState";
import GlobalDatePickerModal from "./GlobalDatePickerModal";

type Interaction = GlobalDatePickerModal.Interaction;

const durationEnum = createEnumParam(Object.values(DurationType));

interface State {
  enableDateSelector: boolean;
  isOpen: boolean;
  //Store dates temporarily for quick date re-apply
  storedDates: Date[] | null;
  storedDurationType: DurationType | null;
}

const initialState: State = {
  enableDateSelector: false,
  isOpen: false,
  storedDates: null,
  storedDurationType: null,
};

export default function GlobalDatePickerManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const globalDate = useGlobalDateStore();
  const currentPath = useMatchPath();
  const theme = useTheme();

  const [queryParamState, setQueryParamState] = useQueryParams({
    global_date_range_end: DateParam,
    global_date_range_start: DateParam,
    duration: durationEnum,
  });

  //
  // State
  //

  const [state, setState] = useState<State>({
    ...initialState,
    enableDateSelector: !!queryParamState.duration,
  });

  const mergeState = getMergeState(setState);

  const locationState = locationStateKeyedByPath[currentPath];

  const dateRange = state.storedDates
    ? state.storedDates
    : getCubeDateRangeFromDurationType(
        queryParamState.duration ?? DEFAULT_GLOBAL_DURATION_TYPE
      );

  const startDate = formatDate(
    globalDate.dateRange ? globalDate.dateRange[0] : dateRange[0],
    "MM/dd/yyyy"
  );
  const endDate = formatDate(
    globalDate.dateRange ? globalDate.dateRange[1] : dateRange[1],
    "MM/dd/yyyy"
  );

  //
  // Side effect
  //

  useEffect(() => {
    //Set to globalDate context for shared links

    if (
      !globalDate.dateRange &&
      queryParamState.global_date_range_start &&
      queryParamState.global_date_range_end &&
      queryParamState.duration
    ) {
      globalDate.set({
        dateRange: [
          queryParamState.global_date_range_start,
          queryParamState.global_date_range_end,
        ],
        durationType: queryParamState.duration,
      });
      mergeState({
        storedDates: [
          queryParamState.global_date_range_start,
          queryParamState.global_date_range_end,
        ],
        storedDurationType: queryParamState.duration,
      });
    } else if (
      globalDate.dateRange &&
      !queryParamState.global_date_range_start &&
      !queryParamState.global_date_range_end &&
      locationState?.enableGlobalDate
    ) {
      // Reset params when passing through pages that don't support global dates
      setQueryParamState({
        global_date_range_start: globalDate.dateRange[0],
        global_date_range_end: globalDate.dateRange[1],
        duration: globalDate.durationType,
      });
    }
  }, [queryParamState, globalDate]);

  //
  // Interaction
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case GlobalDatePickerModal.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({ isOpen: false });
        return;
      }
      case GlobalDatePickerModal.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        setQueryParamState({
          global_date_range_start: interaction.dateRange[0],
          global_date_range_end: interaction.dateRange[1],
          duration: interaction.durationType,
        });

        globalDate.set({
          dateRange: [interaction.dateRange[0], interaction.dateRange[1]],
          durationType: interaction.durationType,
        });
        mergeState({
          isOpen: false,
          storedDates: [interaction.dateRange[0], interaction.dateRange[1]],
          storedDurationType: interaction.durationType,
        });
        return;
      }
      case GlobalDatePickerModal.INTERACTION_REMOVE_DATE_BUTTON_CLICKED: {
        setQueryParamState({
          global_date_range_end: undefined,
          global_date_range_start: undefined,
          duration: undefined,
        });
        globalDate.reset();
        mergeState({ isOpen: false });
        return;
      }
    }
  }

  function handleDateSelectorToggle(): void {
    if (state.enableDateSelector) {
      setQueryParamState({
        duration: undefined,
        global_date_range_end: undefined,
        global_date_range_start: undefined,
      });
      globalDate.reset();
      mergeState({ enableDateSelector: false });
    } else {
      const storedDurationType =
        state.storedDurationType ?? DEFAULT_GLOBAL_DURATION_TYPE;
      const storedDates =
        state.storedDates ??
        getCubeDateRangeFromDurationType(storedDurationType);

      setQueryParamState({
        duration: storedDurationType,
        global_date_range_end: storedDates[1],
        global_date_range_start: storedDates[0],
      });

      globalDate.set({
        dateRange: storedDates,
        durationType: storedDurationType,
      });

      mergeState({ enableDateSelector: true });
    }
  }

  //
  // Render
  //

  return (
    <Box marginRight={theme.space_md} marginLeft={theme.space_md}>
      <Tooltip
        content={copyText.globalDateNotAvailableMessage}
        hide={locationState?.enableGlobalDate}
      >
        <Flex
          alignItems="center"
          backgroundColor={
            state.enableDateSelector && locationState?.enableGlobalDate
              ? theme.primary_color_background
              : theme.secondary_color_background
          }
          borderRadius={theme.borderRadius_2}
          padding={theme.space_xxs}
          height={theme.size_small}
        >
          <Button
            disabled={
              !state.enableDateSelector || !locationState?.enableGlobalDate
            }
            iconStart={<Icon icon={faCalendar} />}
            marginRight={theme.space_xxs}
            primary={
              state.enableDateSelector && locationState?.enableGlobalDate
            }
            secondary
            size="small"
            onClick={() => mergeState({ isOpen: !state.isOpen })}
          >
            {startDate} - {endDate}
          </Button>
          <Box marginTop={theme.space_xxs} marginRight={theme.space_xxs}>
            <Tooltip
              hide={!locationState?.enableGlobalDate}
              content={copyText.globalDateSelectorTooltipCaption.replace(
                "%switch%",
                state.enableDateSelector ? "Disable" : "Enable"
              )}
            >
              <Switch
                disabled={!locationState?.enableGlobalDate}
                checked={state.enableDateSelector}
                onChange={handleDateSelectorToggle}
                height={20}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Tooltip>

      <GlobalDatePickerModal
        dateRange={dateRange}
        durationType={queryParamState.duration ?? DEFAULT_GLOBAL_DURATION_TYPE}
        isFiscalMode={authenticatedUser.settings.fiscalMode}
        isOpen={state.isOpen}
        onInteraction={handleInteraction}
      />
    </Box>
  );
}
