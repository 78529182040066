import EventReporter from "@/utils/EventReporter";
import React, { createContext, PropsWithChildren, useContext } from "react";

const context = createContext<EventReporter | undefined>(undefined);

export interface Props {
  reporter: EventReporter;
}

export default function EventReporterProvider({
  children,
  reporter,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={reporter}>{children}</context.Provider>;
}

export function useEventReporter(): EventReporter {
  const reporter = useContext(context);

  if (!reporter) {
    throw new Error(
      "useEventReporter must be wrapped by EventReporterProvider"
    );
  }

  return reporter;
}
