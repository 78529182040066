import { Permission } from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type AdminGates = ReturnType<typeof adminGates>;

export default function adminGates(params: GateGetterParameters) {
  const canCreateAdminScopedView = params._permissionsIncludeAllOf([
    Permission.CREATE_ADMIN_SCOPED_VIEW,
  ]);

  const canCreateDataIntegration = params._permissionsIncludeAllOf([
    Permission.CREATE_DATA_INTEGRATION,
  ]);

  const canCreateIntegration = params._permissionsIncludeAllOf([
    Permission.CREATE_INTEGRATAION,
  ]);

  const canCreateUserGroupConfig = params._permissionsIncludeAllOf([
    Permission.CREATE_USER_GROUP_CONFIG,
  ]);

  const canDeleteDataIntegration = params._permissionsIncludeAllOf([
    Permission.DELETE_DATA_INTEGRATION,
  ]);

  const canDeleteIntegration = params._permissionsIncludeAllOf([
    Permission.DELETE_INTEGRATION,
  ]);

  const canDeleteUserGroupConfig = params._permissionsIncludeAllOf([
    Permission.DELETE_USER_GROUP_CONFIG,
  ]);

  const canGrantUsersTenantAccess = params._permissionsIncludeAllOf([
    Permission.GRANT_TENANT_ACCESS,
  ]);

  const canListAdminScopedViews = params._permissionsIncludeAllOf([
    Permission.READ_ADMIN_SCOPED_VIEWS,
  ]);

  const canListDataIntegrations = params._permissionsIncludeAllOf([
    Permission.READ_DATA_INTEGRATIONS,
  ]);

  const canListGrants = params._permissionsIncludeAllOf([
    Permission.READ_GRANTS,
  ]);

  const canListIntegrations = params._permissionsIncludeAllOf([
    Permission.READ_INTEGRATIONS,
  ]);

  const canListUsers = params._permissionsIncludeAllOf([Permission.READ_USERS]);

  const canListUserGroupConfigs = params._permissionsIncludeAllOf([
    Permission.READ_USER_GROUP_CONFIGS,
  ]);

  const canRevokeUserTenantAccess = params._permissionsIncludeAllOf([
    Permission.REVOKE_TENANT_ACCESS,
  ]);

  const canUpdateDataIntegration = params._permissionsIncludeAllOf([
    Permission.UPDATE_DATA_INTEGRATION,
  ]);

  const canUpdateIntegration = params._permissionsIncludeAllOf([
    Permission.UPDATE_INTEGRATION,
  ]);

  const canUpdateGrant = params._permissionsIncludeAllOf([
    Permission.UPDATE_GRANT,
  ]);

  const isLabelPreferenceAdmin = params._permissionsIncludeAllOf([
    Permission.CREATE_LABEL_PREFERENCE,
    Permission.READ_LABEL_PREFERENCE,
    Permission.UPDATE_LABEL_PREFERENCE,
  ]);

  const isMeasurePreferenceAdmin = params._permissionsIncludeAllOf([
    Permission.CREATE_MEASURE_PREFERENCE,
    Permission.READ_MEASURE_PREFERENCE,
    Permission.UPDATE_MEASURE_PREFERENCE,
  ]);

  const dataIntegrationGates = {
    canCreateDataIntegration,
    canDeleteDataIntegration,
    canUpdateDataIntegration,
  };

  const hasDataIntegrationPermission = Object.values(dataIntegrationGates).some(
    (gate) => gate === true
  );

  const isMspAdmin = params._permissionsIncludeAllOf([
    Permission.READ_TENANTS_PARTNER,
  ]);

  const hasMspLevelPermission =
    params.mspTenantSettings && !isMspAdmin
      ? params.mspTenantSettings.allowCloudConfiguration
      : true;

  const canViewDataIntegrationsTab =
    hasDataIntegrationPermission && hasMspLevelPermission;

  const integrationGates = {
    canCreateIntegration,
    canDeleteIntegration,
    canUpdateIntegration,
  };

  const canViewIntegrationsTab = Object.values(integrationGates).some(
    (gate) => gate === true
  );

  const userGates = {
    canGrantUsersTenantAccess,
    canRevokeUserTenantAccess,
  };

  const canViewUsersTab = Object.values(userGates).some(
    (gate) => gate === true
  );

  const adminGates = {
    ...dataIntegrationGates,
    ...integrationGates,
    ...userGates,
    canCreateAdminScopedView,
    canCreateUserGroupConfig,
    canDeleteUserGroupConfig,
    canListAdminScopedViews,
    canListGrants,
    canListUserGroupConfigs,
    canUpdateGrant,
    isLabelPreferenceAdmin,
    isMeasurePreferenceAdmin,
  };

  const canViewAdminPage = Object.values(adminGates).some(
    (gate) => gate === true
  );

  return {
    ...adminGates,
    canListDataIntegrations,
    canListIntegrations,
    canListUsers,
    canViewAdminPage,
    canViewDataIntegrationsTab,
    canViewIntegrationsTab,
    canViewUsersTab,
  };
}
