import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CloudSQLCostDatum, CloudSQLCostStruct } from "../types";

export interface Params {
  dateRange: Date[];
  dimensions?: string[];
  queryFilters?: QueryFilter[];
}

export default function useGetCloudSQLCostSummary(
  params: Params,
  options: UseQueryOptions<CloudSQLCostDatum[], UError>
): UseQueryResult<CloudSQLCostDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cloudSQLCostSummary", params],
    queryFn: async () => {
      const measures = [
        "backupCost",
        "cpuAndRamCost",
        "cpuCost",
        "idlingCost",
        "networkCost",
        "ramCost",
        "storageCost",
        "cost",
      ];

      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.CLOUD_SQL_COST,
          dateRange: params.dateRange,
          dimensions: params.dimensions,
          measures,
          queryFilters: params.queryFilters,
        })
      );

      const data = result.map((datum): CloudSQLCostDatum => {
        const [error, validData] = validate(datum, CloudSQLCostStruct);
        if (error) {
          throw new UError("INVALID_CLOUD_SQL_COST_SUMMARY", {
            context: { error, result: datum },
          });
        }

        return {
          backupCost: validData.backupCost ?? 0,
          classification: validData.classification ?? "null",
          cpuAndRamCost: validData.cpuAndRamCost ?? 0,
          cpuCost: validData.cpuCost ?? 0,
          databaseType: validData.databaseType ?? "null",
          idlingCost: validData.idlingCost ?? 0,
          networkCost: validData.networkCost ?? 0,
          projectId: validData.projectId ?? "null",
          ramCost: validData.ramCost ?? 0,
          region: validData.region ?? "null",
          storageCost: validData.storageCost ?? 0,
          cost: validData.cost ?? 0,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
