import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import copyText from "../../copyText";
import { GcpBigQueryDatasetFilters } from "../types";

type TableData = {
  datasetId: string;
  jobCount: number;
  maxJobSlots: number;
  onDemandCost: string;
  projectId: string;
  queryBytes: number;
  queryCount: number;
  reservationCount: number;
};

export type GcpBigQueryDatasetGroup = {
  datasetId: string;
  jobCount: number;
  maxJobSlots: number;
  onDemandCost: number;
  projectId: string;
  queryBytes: number;
  queryCount: number;
  reservationCount: number;
};

interface Props {
  datasets: GcpBigQueryDatasetGroup[];
  isLoading: boolean;
  onInteraction: (
    interaction: GcpBigQueryDatasetGroupTable.Interaction
  ) => void;
}

export function GcpBigQueryDatasetGroupTable(props: Props): JSX.Element {
  const theme = useTheme();
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        cell: ({ row }) => (
          <Button
            disabled={row.original.reservationCount < 1}
            iconStart={<Icon icon={faTableList} />}
            primary
            size="tiny"
            onClick={() =>
              props.onInteraction({
                type: GcpBigQueryDatasetGroupTable.INTERACTION_RESOURCE_CLICKED,
                selectedDatasetID: row.original.datasetId,
                selectedProjectID: row.original.projectId,
              })
            }
          />
        ),
        size: 48,
      }),
      columnHelper.accessor("datasetId", {
        id: "datasetId",
        cell: ({ getValue }) =>
          getValue() ? (
            <Text
              color={theme.primary_color_text}
              cursor={"pointer"}
              onClick={() =>
                props.onInteraction({
                  type: GcpBigQueryDatasetGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "datasetId",
                  filterValue: getValue(),
                })
              }
            >
              {getValue()}
            </Text>
          ) : (
            <Text>{copyText.bigQueryDatasetGroupTableNull}</Text>
          ),
        header: copyText.bigQueryDatasetGroupTableHeader_datasetId,
        size: 250,
      }),
      columnHelper.accessor("projectId", {
        id: "projectId",
        cell: ({ getValue }) =>
          getValue() ? (
            <Text
              color={theme.primary_color_text}
              cursor={"pointer"}
              onClick={() =>
                props.onInteraction({
                  type: GcpBigQueryDatasetGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: "projectId",
                  filterValue: getValue(),
                })
              }
            >
              {getValue()}
            </Text>
          ) : (
            <Text>{copyText.bigQueryDatasetGroupTableNull}</Text>
          ),
        header: copyText.bigQueryDatasetGroupTableHeader_projectId,
      }),
      columnHelper.accessor("onDemandCost", {
        meta: { align: "center" },
        header: copyText.bigQueryDatasetGroupTableHeader_onDemandCost,
      }),
      columnHelper.accessor("jobCount", {
        meta: { align: "center" },
        header: copyText.bigQueryDatasetGroupTableHeader_jobCount,
      }),
      columnHelper.accessor("queryCount", {
        meta: { align: "center" },
        header: copyText.bigQueryDatasetGroupTableHeader_queryCount,
      }),
      columnHelper.accessor("queryBytes", {
        meta: { align: "center" },
        header: copyText.bigQueryDatasetGroupTableHeader_queryBytes,
        cell: ({ getValue }) => prettyBytes(getValue()),
      }),
      columnHelper.accessor("maxJobSlots", {
        meta: { align: "center" },
        header: copyText.bigQueryDatasetGroupTableHeader_maxJobSlots,
      }),
    ],
    [props.datasets]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.datasets.map((item) => {
      return {
        datasetId: item.datasetId,
        jobCount: item.jobCount,
        maxJobSlots: Math.floor(item.maxJobSlots),
        onDemandCost: formatCurrency({
          number: item.onDemandCost,
        }),
        projectId: item.projectId,
        queryBytes: item.queryBytes,
        queryCount: item.queryCount,
        reservationCount: item.reservationCount,
      };
    });
    return tableData;
  }, [props.datasets]);

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      initialState={{
        pagination: { pageSize: 10 },
        sorting: [{ desc: true, id: "onDemandCost" }],
      }}
      showPagination
      sortable
    />
  );
}

GcpBigQueryDatasetGroupTable.INTERACTION_FILTER_CLICKED =
  `GcpBigQueryDatasetTable.INTERACTION_FILTER_CLICKED` as const;

GcpBigQueryDatasetGroupTable.INTERACTION_RESOURCE_CLICKED =
  `GcpBigQueryResourceTable.INTERACTION_RESOURCE_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof GcpBigQueryDatasetGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof GcpBigQueryDatasetFilters;
  filterValue: GcpBigQueryDatasetFilters[keyof GcpBigQueryDatasetFilters];
}

interface InteractionResourceClicked {
  type: typeof GcpBigQueryDatasetGroupTable.INTERACTION_RESOURCE_CLICKED;
  selectedDatasetID: string;
  selectedProjectID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GcpBigQueryDatasetGroupTable {
  export type Interaction =
    | InteractionFilterClicked
    | InteractionResourceClicked;
}

export default GcpBigQueryDatasetGroupTable;
