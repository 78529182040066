import { buildCubeQuery } from "@/api/analytics/utils";
import { nullable, string, validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { uniq } from "lodash";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

export interface Params {
  dateRange: Date[];
}

const schema = {
  accountId: nullable(string()),
};

export default function useGetAWSKubernetesAccountIDs(
  params: Params,
  options?: UseQueryOptions<string[], UError>
): UseQueryResult<string[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsKubernetesAccountIDs", params],
    queryFn: async () => {
      const dimensions = ["accountId"];

      const costPromise = client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
          dateRange: params.dateRange,
          dimensions,
        })
      );

      const usagePromise = client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_KUBERNETES_NODE_USAGE,
          dateRange: params.dateRange,
          dimensions,
        })
      );

      const [costResult, usageResult] = await Promise.all([
        costPromise,
        usagePromise,
      ]);

      const accountIDs = [...costResult, ...usageResult].map(
        (datum): string => {
          const [error, validData] = validate(datum, schema);

          if (error) {
            throw new UError("INVALID_AWS_KUBERNETES_ACCOUNT_IDS", {
              context: { error, result: datum },
            });
          }

          return validData.accountId ?? "null";
        }
      );

      return uniq(accountIDs);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
