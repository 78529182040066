import { useTheme } from "@emotion/react";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { ReactElement } from "react";
import paths from "../../../constants/paths";
import { LinkWithSearchParams } from "../../../lib/react-router";
import copyText from "../copyText";

interface Props {
  cloudProviderType: CloudProviderType;
  serviceIcon: ReactElement;
  serviceName: string;
}

export default function NotConfiguredSectionBadge(props: Props) {
  const theme = useTheme();

  const copyTextFragments = copyText.integrationNotConfiguredMessage.split("|");

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={120}
      justifyContent="space-between"
      marginBottom={theme.space_sm}
      padding={theme.space_xl}
      width="100%"
    >
      <Flex alignItems="center">
        <Flex alignItems="center" height={75} width={75}>
          {props.serviceIcon}
        </Flex>
        <Box>
          <Text appearance="h4" color={theme.text_color_disabled}>
            {props.serviceName}
          </Text>
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Text whiteSpace="pre">
          {copyTextFragments[0]}
          <LinkWithSearchParams
            to={paths._admin}
            searchParams={{ tab: "clouds" }}
          >
            {copyTextFragments[1]}
          </LinkWithSearchParams>
          {copyTextFragments[2].replace("%provider%", props.cloudProviderType)}
        </Text>
      </Flex>
    </Flex>
  );
}
