import { buildCubeQuery } from "@/api/analytics/utils";
import { nullable, number, validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { DateRange } from "../../../../utils/dates";
import { AWSKubernetesCostUsageSummaryEntity } from "../types";

const usageStruct = {
  cpuTotal: nullable(number()),
  cpuUsed: nullable(number()),
  memoryTotal: nullable(number()),
  memoryUsed: nullable(number()),
};

const costStruct = {
  cost: nullable(number()),
};

export interface Params {
  dateRange: DateRange;
  queryFilters?: QueryFilter[];
}

export default function useGetAWSKubernetesCostUsageSummary(
  params: Params,
  options?: UseQueryOptions<AWSKubernetesCostUsageSummaryEntity, UError>
): UseQueryResult<AWSKubernetesCostUsageSummaryEntity, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    // Total Cost
    queryFn: async () => {
      const [costResult, usageResult] = await Promise.all([
        client.load(
          buildCubeQuery({
            ...params,
            dataSource: DataSource.AWS_KUBERNETES_NODE_COST,
            measures: ["cost"],
          })
        ),
        client.load(
          buildCubeQuery({
            ...params,
            dataSource: DataSource.AWS_KUBERNETES_INSTANCES,
            measures: ["memoryTotal", "memoryUsed", "cpuTotal", "cpuUsed"],
          })
        ),
      ]);

      const [costError, costData] = validate(costResult[0], costStruct);
      const [usageError, usageData] = validate(usageResult[0], usageStruct);

      if (costError || usageError) {
        throw new UError("INVALID_KUBERNETES_COST_SUMMARY", {
          context: {
            error: [costError, usageError],
            result: [costData, usageData],
          },
        });
      }

      return {
        cpuTotal: usageData.cpuTotal ?? 0,
        cpuUsed: usageData.cpuUsed ?? 0,
        memoryTotal: usageData.memoryTotal ?? 0,
        memoryUsed: usageData.memoryUsed ?? 0,
        totalCost: costData.cost ?? 0,
      };
    },
    queryKey: ["AWSKubernetesSpendSummary", "currentMTD", params],
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
