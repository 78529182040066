import { DataSource, Operator, TimeGranularity } from "../constants/enums";
import CubeQuery from "./CubeQuery";
import { AnalyticsApiClient } from "./getTopNRawData";
import { LabelMap, QueryFilter } from "./types";

type BudgetSpendDatum = {
  cost?: number;
  customNetCost?: number;
  netCost?: number;
  timestamp: string;
};

type BudgetScope = {
  key: string;
  values: string[];
};

type Budget = {
  measure: string;
  scopes?: BudgetScope[];
};

interface Params {
  budget: Budget;
  client: AnalyticsApiClient;
  dateRange: Date[] | string;
  granularity?: TimeGranularity;
  labelMap: LabelMap;
}

export default async function getBudgetData(
  params: Params
): Promise<BudgetSpendDatum[]> {
  const result = (await params.client.load(
    new CubeQuery({
      dataSource: DataSource.BILLING,
      dateRange: params.dateRange,
      granularity: params.granularity ?? TimeGranularity.DAY,
      labelMap: params.labelMap,
      measures: [params.budget.measure],
      queryFilters: getBudgetFilters(params.budget.scopes ?? []),
    })
  )) as BudgetSpendDatum[];

  return result;
}

export function getBudgetFilters(scopes: BudgetScope[]): QueryFilter[] {
  return scopes.map((scope) => ({
    name: scope.key,
    operator: Operator.EQUALS,
    values: scope.values,
  }));
}
