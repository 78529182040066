import styled from "@emotion/styled";
import React from "react";
import getCn from "../utils/getCn";

const COMPONENT_NAME = "Legend";
const cn = getCn(COMPONENT_NAME);

const StyledDiv = styled.div<{ backgroundColor?: string }>`
  .${cn("wrapper")} {
    color: ${(props) => props.theme.text_color};
    border-radius: ${(props) => props.theme.borderRadius_2};
    background-color: ${(props) => props.backgroundColor || "transparent"};
    padding: ${(props) => props.theme.space_xs}
      ${(props) => props.theme.space_md};
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.25rem;
  }
`;

const StyledItem = styled.div<{ color: string; opacity?: string }>`
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.4rem;

  div {
    padding: 0.5rem;
    max-height: 1rem;
    max-width: 1rem;
    background-color: ${(props) => props.color};
    margin-right: 0.25rem;
    opacity: ${(props) => props.opacity || 0.6};
  }

  .${cn("line")} {
    border: 5px solid ${(props) => props.color};
    border-style: none none solid;
    color: #fff;
    background-color: #fff;
    padding: 0 1rem;
    max-width: 2rem;
    opacity: 1;
  }

  .${cn("circle")} {
    border: 4px solid ${(props) => props.color};
    color: #fff;
    background-color: #fff;
    padding: 0.5rem;
    opacity: 1;
    border-radius: 50%;
  }
`;

type item = { color: string; opacity?: string; style?: string; text: string };

interface Props {
  backgroundColor?: string;
  items: item[][];
}

export default function Legend(props: Props): JSX.Element {
  return (
    <StyledDiv backgroundColor={props.backgroundColor}>
      {props.items.map((itemSet, i) => (
        <div key={i} className={cn("wrapper")}>
          {itemSet.map((item) => (
            <StyledItem
              key={item.text}
              color={item.color}
              opacity={item.opacity}
            >
              <div className={item.style ? cn(item.style) : ""} />
              <span>{item.text}</span>
            </StyledItem>
          ))}
        </div>
      ))}
    </StyledDiv>
  );
}
