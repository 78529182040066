import { DateHelper } from "@/lib/dates";
import { useCSVDownloader } from "@/ui-lib/components/CSVDownloader";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import IconExport from "@/ui-lib/icons/IconExport";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { QueryFilter, RawValue } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay, format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { createStructParam } from "../../../../lib/use-query-params";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import Grid from "../../../../ui-lib/components/Grid";
import Modal from "../../../../ui-lib/components/Modal";
import { useDebounce } from "../../../../utils/timers";
import copyText from "../../copyText";
import useGetAWSRedshiftResourceGroups from "../hooks/useGetAWSRedshiftResourceGroups";
import useGetAWSRedshiftResources from "../hooks/useGetAWSRedshiftResources";
import useGetAWSRedshiftSpendSummaries from "../hooks/useGetAWSRedshiftSpendSummaries";
import {
  AWSRedshiftGroupFilters,
  AWSRedshiftResourceGroupEntity,
  AWSRedshiftResourcesEntity,
  awsRedshiftDimensions,
  awsRedshiftMeasures,
} from "../types";
import AWSWarehouseMeters from "./AWSWarehouseMeters";
import AWSWarehouseResourceTable from "./AWSWarehouseResourceTable";
import AWSWarehouseResourceTableControls from "./AWSWarehouseResourceTableControls";
import { AWSWarehouseSubTable } from "./AWSWarehouseSubTable";

type Interaction =
  | AWSWarehouseResourceTable.Interaction
  | AWSWarehouseResourceTableControls.Interaction;

const AWSWarehouseCostChartOption = {
  USAGE_TYPE: "USAGE_TYPE",
  ACCOUNT_ID: "ACCOUNT_ID",
  REGION: "REGION",
  CLUSTER_ID: "LINKED_ACCOUNT",
  INSTANCE_TYPE: "PRODUCT_FAMILY",
} as const;

type AWSWarehouseCostChartOption =
  (typeof AWSWarehouseCostChartOption)[keyof typeof AWSWarehouseCostChartOption];

const AWSWarehouseUsageChartOption = {
  NODE: "NODE",
  STORAGE: "STORAGE",
  BACKUP_STORAGE: "BACKUP_STORAGE",
  SERVERLESS_COMPUTE: "SERVERLESS_COMPUTE",
  DATA_SCAN: "DATA_SCAN",
} as const;

type AWSWarehouseUsageChartOption =
  (typeof AWSWarehouseUsageChartOption)[keyof typeof AWSWarehouseUsageChartOption];

const costGroupingEnum = createEnumParam(
  Object.values(AWSWarehouseCostChartOption)
);

const usageChartOptionEnum = createEnumParam(
  Object.values(AWSWarehouseUsageChartOption)
);

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  groupFilters: AWSRedshiftGroupFilters;
  selectedCostChartOption: AWSWarehouseCostChartOption;
  selectedGroup: z.infer<typeof selectedInstanceGroupStruct> | null;
  selectedUsageChartOption: AWSWarehouseUsageChartOption;
  usageMeasure: UsageMeasure;
};

const UsageMeasure = {
  CPU: "CPU",
  MEMORY: "MEMORY",
} as const;

type UsageMeasure = (typeof UsageMeasure)[keyof typeof UsageMeasure];

const durationEnum = createEnumParam(Object.values(DurationType));
const usageMeasureEnum = createEnumParam(Object.values(UsageMeasure));

const instanceGroupFiltersStruct = z.object({
  clusterId: z.nullable(z.string()),
  usageAccountId: z.nullable(z.string()),
  region: z.nullable(z.string()),
});

const instanceGroupFiltersDefault = {
  clusterId: null,
  usageAccountId: null,
  region: null,
};

const selectedInstanceGroupStruct = z.object({
  clusterId: z.string(),
  usageAccountId: z.string(),
});

const queryParamConfigMap = {
  cost_grouping: withDefault(
    costGroupingEnum,
    AWSWarehouseCostChartOption.USAGE_TYPE
  ),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  instance_group_filters: createStructParam(instanceGroupFiltersStruct),
  instance_table_sort: JsonParam,
  selected_group: createStructParam(selectedInstanceGroupStruct),
  usage_grouping: withDefault(
    usageChartOptionEnum,
    AWSWarehouseUsageChartOption.NODE
  ),
  usage_measure: withDefault(usageMeasureEnum, UsageMeasure.CPU),
};

const costGroupingLabel = {
  [AWSWarehouseCostChartOption.ACCOUNT_ID]:
    copyText.awsDatabaseCostChartOptionLabel_account_id,
  [AWSWarehouseCostChartOption.CLUSTER_ID]:
    copyText.awsDatabaseCostChartOptionLabel_cluster_id,
  [AWSWarehouseCostChartOption.INSTANCE_TYPE]:
    copyText.awsWarehouseCostChartOptionLabel_instance_type,
  [AWSWarehouseCostChartOption.REGION]:
    copyText.awsWarehouseCostChartOptionLabel_region,
  [AWSWarehouseCostChartOption.USAGE_TYPE]:
    copyText.awsWarehouseCostChartOptionLabel_usage_type,
};

const usageGroupingLabel = {
  [AWSWarehouseUsageChartOption.NODE]:
    copyText.awsQarehouseUsageChartOptionLabel_node,
  [AWSWarehouseUsageChartOption.STORAGE]:
    copyText.awsQarehouseUsageChartOptionLabel_storage,
  [AWSWarehouseUsageChartOption.BACKUP_STORAGE]:
    copyText.awsQarehouseUsageChartOptionLabel_backup_storage,
  [AWSWarehouseUsageChartOption.SERVERLESS_COMPUTE]:
    copyText.awsQarehouseUsageChartOptionLabel_serverless_compute,
  [AWSWarehouseUsageChartOption.DATA_SCAN]:
    copyText.awsQarehouseUsageChartOptionLabel_data_scan,
};

export default function AWSWarehouseContainer() {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();
  const queryClient = useQueryClient();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = getQueryParamState(queryParams);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);
  const { downloadCSV, csvElement } = useCSVDownloader();

  //
  // QUERIES
  //

  const dateRange = globalDate.date ?? queryParamState.dateRange;

  const awsRedshiftSpendSummariesSpendSummaries =
    useGetAWSRedshiftSpendSummaries({
      queryFilters: getBubbleFilterQueryFilters(queryParamState.groupFilters),
    });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    awsRedshiftSpendSummariesSpendSummaries;

  const isLoadingSpendSummaries = awsRedshiftSpendSummariesSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = getCostMesureFromOption(
    queryParamState.selectedCostChartOption
  );

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    queryFilters: [
      ...getBubbleFilterQueryFilters(queryParamState.groupFilters),
      { name: "clusterId", operator: Operator.SET },
    ],
    measures: costMeasures,
  });

  const usageDimensions = getUsageDimensionsFromOption(
    queryParamState.selectedUsageChartOption
  );

  const usageMeasures = getUsageMesureFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      queryFilters: [
        ...getBubbleFilterQueryFilters(queryParamState.groupFilters),
        ...getUsageQueryFilters(usageDimensions),
      ],
      measures: usageMeasures,
    });

  const { data: resourceGroups = [], isFetching: isLoadingResourceGroups } =
    useGetAWSRedshiftResourceGroups({
      dateRange,
    });

  const { data: resources = [], isFetching: isLoadingResources } =
    useGetAWSRedshiftResources(
      {
        dateRange,
        queryFilters: queryParamState.selectedGroup
          ? getSelectedInstanceGroupQueryFilters(queryParamState.selectedGroup)
          : [],
      },
      { enabled: queryParamState.selectedGroup !== null }
    );

  const {
    data: allResources = [],
    isFetching: isLoadingAllResources,
    refetch: fetchAllResources,
  } = useGetAWSRedshiftResources(
    {
      dateRange,
      queryFilters: [],
    },
    { enabled: false, refetchInterval: false }
  );

  function clearAllResourcesQuery() {
    queryClient.removeQueries({ queryKey: ["AWSRedshiftResourcesCSV"] });
  }

  //
  // MODIFIED QUERY DATA
  //

  const filteredResourceGroups = useMemo(() => {
    return getFilteredResourceGroups({
      allResourceGroups: resourceGroups ?? [],
      resourceGroupFilters: queryParamState.groupFilters,
      searchText: debouncedSearchText,
    });
  }, [debouncedSearchText, resourceGroups, queryParamState.groupFilters]);

  const hourCorrectedUsageData = usageData.map((datum) => {
    if (queryParamState.dateRangeGranularity === TimeGranularity.HOUR) {
      return datum;
    }

    const corrected = Object.entries(datum).map(
      ([key, value]): [string, RawValue] => {
        return [key, typeof value === "number" ? value / 24 : value];
      }
    );

    return Object.fromEntries(corrected);
  });

  const resourceGroupCSVData = useMemo(
    () => getResourceGroupCSVData(filteredResourceGroups),
    [filteredResourceGroups]
  );

  const resourcesCSVData = useMemo(
    () => getResourcesCSVData(resources),
    [resources]
  );

  //
  // EFFECTS
  //

  useEffect(() => {
    if (allResources.length === 0) {
      return;
    }

    const allResourcesCSVData = getResourcesCSVData(allResources);

    downloadCSV({
      data: allResourcesCSVData.rows,
      fileName: `aws-redshift-all-datawarehouse-resources-${format(
        new Date(),
        "MM-dd-yyyy"
      )}`,
      headers: allResourcesCSVData.headers,
    });

    // clear allResources query from the cache.
    clearAllResourcesQuery();
  }, [allResources]);

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case AWSWarehouseResourceTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };
        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSWarehouseResourceTableControls.INTERACTION_DOWNLOAD_CLUSTERS_CLICKED: {
        fetchAllResources();
        break;
      }
      case AWSWarehouseResourceTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.groupFilters };

        nextFilters[interaction.filterKey] = null;

        setQueryParams({ instance_group_filters: nextFilters });
        break;
      }
      case AWSWarehouseResourceTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);

        break;
      }
    }
  }

  //
  // RENDER
  //

  const costGroupingOptions = [
    AWSWarehouseCostChartOption.USAGE_TYPE,
    AWSWarehouseCostChartOption.ACCOUNT_ID,
    AWSWarehouseCostChartOption.REGION,
    AWSWarehouseCostChartOption.CLUSTER_ID,
    AWSWarehouseCostChartOption.INSTANCE_TYPE,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setQueryParams({ cost_grouping: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === queryParams.cost_grouping
    ) ?? costGroupingOptions[0];

  const usageGroupingOptions = [
    AWSWarehouseUsageChartOption.NODE,
    AWSWarehouseUsageChartOption.STORAGE,
    AWSWarehouseUsageChartOption.BACKUP_STORAGE,
    AWSWarehouseUsageChartOption.SERVERLESS_COMPUTE,
    AWSWarehouseUsageChartOption.DATA_SCAN,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setQueryParams({ usage_grouping: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === queryParams.usage_grouping
    ) ?? usageGroupingOptions[0];

  return (
    <Box width="100%" paddingTop={theme.space_md}>
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="flex-end"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={new DateHelper().date}
          onChangeDateRange={(duration, newDateRange) => {
            setQueryParams({
              duration,
              ...(newDateRange && newDateRange[0] && newDateRange[1]
                ? {
                    date_range_start: newDateRange[0],
                    date_range_end: newDateRange[1],
                  }
                : {
                    date_range_start: null,
                    date_range_end: null,
                  }),
            });
          }}
        />
      </Flex>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSWarehouseMeters
          isLoading={isLoadingSpendSummaries}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsDatabaseCostAggregateChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultCostGroupingOption}
                options={costGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {costGroupingLabel[queryParams.cost_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <StackedBarChart
                data={costData}
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                disableDrilldown
                emptyPlaceholderText={copyText.noDataPlaceholderMessage}
                isLoading={isLoadingCostData}
                limit={10}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
              />
            </Box>
          </Box>
        </Flex>
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.awsDatabaseUsageChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultUsageGroupingOption}
                options={usageGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {usageGroupingLabel[queryParams.usage_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <AreaChart
                data={hourCorrectedUsageData}
                disableDrilldown
                hideTotal
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map(getMeasureWithUnit)}
                readableKeys={readableKeys}
                showLegend
                showTooltip
                stacked
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                yAxisLabel={getYAxisUnit(
                  queryParamState.selectedUsageChartOption
                )}
              />
            </Box>
          </Box>
        </Flex>
      </Grid>

      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <AWSWarehouseResourceTableControls
          csvData={resourceGroupCSVData}
          debouncedSearchText={debouncedSearchText}
          instanceGroupFilters={queryParamState.groupFilters}
          isLoadingAllClusters={isLoadingAllResources}
          searchText={searchText}
          onInteraction={handleInteraction}
        />
        {csvElement}
      </Box>

      <Box width="100%" overflowX="auto">
        <AWSWarehouseResourceTable
          clusterGroups={filteredResourceGroups}
          isLoadingResourceGroups={isLoadingResourceGroups}
          onInteraction={handleInteraction}
        />
      </Box>

      {queryParamState.selectedGroup !== null && (
        <Modal
          isOpen
          showCloseButton
          onClose={() =>
            setQueryParams({
              instance_table_sort: null,
              selected_group: null,
            })
          }
          minWidth={1100}
        >
          <Modal.Header>
            <Flex
              justifyContent="space-between"
              marginLeft={theme.space_sm}
              width="100%"
            >
              <Box>
                <Text fontSize={theme.h4_fontSize}>
                  {copyText.awsWarehouseResourcesTableModalHeader}
                </Text>
              </Box>
              {resourcesCSVData.rows.length > 0 && (
                <CSVLink
                  data={resourcesCSVData.rows}
                  headers={resourcesCSVData.headers}
                  filename={`aws-redshift-resources-${format(new Date(), "MM-dd-yyyy")}`}
                >
                  <Button
                    iconStart={<IconExport />}
                    marginRight={theme.space_sm}
                    secondary
                    size="small"
                  >
                    {copyText.exportButtonLabel}
                  </Button>
                </CSVLink>
              )}
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <AWSWarehouseSubTable
              resources={resources}
              isLoadingResources={isLoadingResources}
            />
          </Modal.Body>
        </Modal>
      )}
    </Box>
  );
}

function getCostDimensionsFromOption(option: AWSWarehouseCostChartOption) {
  switch (option) {
    case AWSWarehouseCostChartOption.ACCOUNT_ID:
      return [awsRedshiftDimensions.usageAccountId];
    case AWSWarehouseCostChartOption.REGION:
      return [awsRedshiftDimensions.region];
    case AWSWarehouseCostChartOption.CLUSTER_ID:
      return [awsRedshiftDimensions.clusterId];
    case AWSWarehouseCostChartOption.INSTANCE_TYPE:
      return [awsRedshiftDimensions.instanceType];
    case AWSWarehouseCostChartOption.USAGE_TYPE:
      return [];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getCostMesureFromOption(option: AWSWarehouseCostChartOption) {
  switch (option) {
    case AWSWarehouseCostChartOption.USAGE_TYPE:
      return [
        awsRedshiftMeasures.cost,
        awsRedshiftMeasures.nodeCost,
        awsRedshiftMeasures.storageCost,
        awsRedshiftMeasures.serverlessComputeCost,
        awsRedshiftMeasures.backupStorageCost,
        awsRedshiftMeasures.dataScanCost,
      ];
    default: {
      return [awsRedshiftMeasures.cost];
    }
  }
}

function getUsageDimensionsFromOption(option: AWSWarehouseUsageChartOption) {
  switch (option) {
    case AWSWarehouseUsageChartOption.NODE:
      return [
        awsRedshiftDimensions.usageAccountId,
        awsRedshiftDimensions.clusterId,
        awsRedshiftDimensions.instanceType,
      ];
    case AWSWarehouseUsageChartOption.SERVERLESS_COMPUTE:
      return [awsRedshiftDimensions.usageAccountId];
    default: {
      return [
        awsRedshiftDimensions.usageAccountId,
        awsRedshiftDimensions.clusterId,
      ];
    }
  }
}

function getUsageMesureFromOption(option: AWSWarehouseUsageChartOption) {
  switch (option) {
    case AWSWarehouseUsageChartOption.NODE:
      return [awsRedshiftMeasures.nodeUsage];
    case AWSWarehouseUsageChartOption.STORAGE:
      return [awsRedshiftMeasures.storageUsage];
    case AWSWarehouseUsageChartOption.BACKUP_STORAGE:
      return [awsRedshiftMeasures.backupStorageUsage];
    case AWSWarehouseUsageChartOption.SERVERLESS_COMPUTE:
      return [awsRedshiftMeasures.serverlessComputeUsage];
    case AWSWarehouseUsageChartOption.DATA_SCAN:
      return [awsRedshiftMeasures.dataScanUsage];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getYAxisUnit(option: AWSWarehouseUsageChartOption): string {
  switch (option) {
    case AWSWarehouseUsageChartOption.NODE:
      return copyText.unitNodeHours;
    case AWSWarehouseUsageChartOption.STORAGE:
    case AWSWarehouseUsageChartOption.BACKUP_STORAGE:
    case AWSWarehouseUsageChartOption.SERVERLESS_COMPUTE:
    case AWSWarehouseUsageChartOption.DATA_SCAN:
      return copyText.unitByteMonths;
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: awsRedshiftMeasures.cost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.backupStorageCost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.backupStorageUsage, unit: UnitType.BYTES },
  { name: awsRedshiftMeasures.dataScanCost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.nodeCost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.serverlessComputeCost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.serverlessComputeUsage, unit: UnitType.BYTES },
  { name: awsRedshiftMeasures.storageCost, unit: UnitType.CURRENCY },
  { name: awsRedshiftMeasures.dataScanUsage, unit: UnitType.STANDARD },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

const readableKeys = {
  nodeUsage: "nodeUsage",
  dataScanUsage: "dataScanUsage",
  storageUsage: "storageUsage",
  serverlessComputeUsage: "serverlessComputeUsage",
  backupStorageUsage: "backupStorageUsage",
};

const resourceGroupCSVAccessors = [
  "clusterId",
  "usageAccountId",
  "region",
  "storage",
  "cost",
  "dataScanCost",
  "nodeCost",
  "storageCost",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getResourceGroupCSVData(
  groups: AWSRedshiftResourceGroupEntity[]
): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    clusterId: group.clusterId,
    cost: group.cost,
    region: group.region,
    storage: group.storage,
    usageAccountId: group.usageAccountId,
    dataScanCost: group.dataScanCost,
    nodeCost: group.nodeCost,
    storageCost: group.storageCost,
  }));

  const headers = resourceGroupCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsWarehouseResourceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

const clustersCSVAccessors = [
  "clusterArn",
  "payerAccountId",
  "clusterId",
  "usageAccountId",
  "region",
  "productFamily",
  "instanceMemory",
  "instanceType",
  "cost",
  "nodeUsage",
  "nodeCost",
  "instanceVcpu",
  "io",
  "storage",
  "storageCost",
  "storageUsage",
  "dataScanCost",
  "dataScanUsage",
  "serverlessComputeCost",
  "serverlessComputeUsage",
  "backupStorageCost",
  "backupStorageUsage",
] as const;

function getResourcesCSVData(groups: AWSRedshiftResourcesEntity[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    clusterArn: group.clusterArn,
    payerAccountId: group.payerAccountId,
    clusterId: group.clusterId,
    usageAccountId: group.usageAccountId,
    region: group.region,
    productFamily: group.productFamily,
    instanceMemory: group.instanceMemory,
    instanceType: group.instanceType,
    cost: group.cost,
    nodeUsage: group.nodeUsage,
    nodeCost: group.nodeCost,
    instanceVcpu: group.instanceVcpu,
    io: group.io,
    storage: group.storage,
    storageCost: group.storageCost,
    storageUsage: group.storageUsage,
    dataScanCost: group.dataScanCost,
    dataScanUsage: group.dataScanUsage,
    serverlessComputeCost: group.serverlessComputeCost,
    serverlessComputeUsage: group.serverlessComputeUsage,
    backupStorageCost: group.backupStorageCost,
    backupStorageUsage: group.backupStorageUsage,
  }));

  const headers = clustersCSVAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `awsWarehouseResourceTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredInstanceGroupsParams = {
  allResourceGroups: AWSRedshiftResourceGroupEntity[];
  resourceGroupFilters: AWSRedshiftGroupFilters;
  searchText: string | null;
};

function getFilteredResourceGroups(
  params: GetFilteredInstanceGroupsParams
): AWSRedshiftResourceGroupEntity[] {
  const searchText = params.searchText ?? "";

  return params.allResourceGroups.filter((instanceGroup) => {
    if (
      !isInstanceGroupPassingFilters(instanceGroup, params.resourceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInInstanceGroup(instanceGroup, searchText)) {
      return false;
    }

    return true;
  });
}

function getUsageQueryFilters(usageDimension: string[]): QueryFilter[] {
  return usageDimension.map(
    (key): QueryFilter => ({
      name: key,
      operator: Operator.SET,
    })
  );
}

function getSelectedInstanceGroupQueryFilters(
  selectedInstanceGroup: z.infer<typeof selectedInstanceGroupStruct>
): QueryFilter[] {
  return [
    awsRedshiftDimensions.clusterId,
    awsRedshiftDimensions.usageAccountId,
    awsRedshiftDimensions.region,
  ].map(
    (key): QueryFilter =>
      selectedInstanceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedInstanceGroup[key]],
          }
  );
}

function getBubbleFilterQueryFilters(
  filters: AWSRedshiftGroupFilters
): QueryFilter[] {
  const queryFilters: QueryFilter[] = [];

  [
    awsRedshiftDimensions.clusterId,
    awsRedshiftDimensions.usageAccountId,
    awsRedshiftDimensions.region,
  ].forEach((key) => {
    const filterValue = filters[key];

    if (filterValue === null) return;

    queryFilters.push(
      filterValue === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [filterValue],
          }
    );
  });

  return queryFilters;
}

function isInstanceGroupPassingFilters(
  instanceGroup: AWSRedshiftResourceGroupEntity,
  filters: AWSRedshiftGroupFilters
): boolean {
  const filterKeys = [
    awsRedshiftDimensions.clusterId,
    awsRedshiftDimensions.region,
    awsRedshiftDimensions.usageAccountId,
  ];

  for (const filterKey of filterKeys) {
    const filterValue = filters[filterKey];

    if (
      filterValue !== null &&
      instanceGroup[filterKey].toLowerCase().trim() !==
        filterValue.toLowerCase().trim()
    ) {
      return false;
    }
  }

  return true;
}

function isSearchTextInInstanceGroup(
  instanceGroup: AWSRedshiftResourceGroupEntity,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    instanceGroup.clusterId,
    instanceGroup.region,
    instanceGroup.usageAccountId,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText.toLowerCase().trim())
  );
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    groupFilters:
      queryParams.instance_group_filters ?? instanceGroupFiltersDefault,
    selectedGroup: queryParams.selected_group ?? null,
    selectedCostChartOption: queryParams.cost_grouping,
    usageMeasure: queryParams.usage_measure,
    selectedUsageChartOption: queryParams.usage_grouping,
  };
}
