import { create } from "zustand";

type MspStoreAction = {
  setParentTenantID: (parentTenantID: string) => void;
};

type MspStoreState = {
  selectedParentTenantID: string | null;
};

export const useMspStore = create<MspStoreState & MspStoreAction>((set) => ({
  selectedParentTenantID: null,
  setParentTenantID: (parentTenantID: string) =>
    set({ selectedParentTenantID: parentTenantID }),
}));
