import { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { DashboardScope } from "@ternary/api-lib/constants/enums";
import { SYSTEM_TAG } from "@ternary/api-lib/constants/system";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Select from "../../../ui-lib/components/Select";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

interface DashboardModalProps {
  existingTags: string[];
  isCreateModal?: boolean;
  isGlobalDashboard?: boolean;
  isProcessing?: boolean;
  name?: string;
  tags?: string[];
  title: string;
  onClose: () => void;
  onSubmit: (params: {
    name: string;
    scope: DashboardScope;
    tags: string[];
  }) => void;
}

type State = {
  inputTags: string[];
  isConfirmingSystemDashboard: boolean;
  nameInput: string;
};

const initialState: State = {
  inputTags: [],
  isConfirmingSystemDashboard: false,
  nameInput: "",
};

export default function DashboardFormModal(
  props: DashboardModalProps
): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [state, setState] = useState({
    ...initialState,
    nameInput: props.name ?? "",
    inputTags: props.tags
      ? props.tags.map((tag) => ({ label: tag, value: tag }))
      : [],
  });

  const mergeState = getMergeState(setState);

  if (state.isConfirmingSystemDashboard) {
    return (
      <Modal
        closeOnClickOutside={false}
        isOpen={true}
        showCloseButton
        width={500}
        onClose={props.onClose}
      >
        <Modal.Header>
          <Text appearance="h4">{copyText.systemDashboardSaveLabel}</Text>
        </Modal.Header>

        <Modal.Body>
          <Text>{copyText.systemDashboardPrompt}</Text>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={props.isProcessing}
            secondary
            width={70}
            onClick={() => mergeState({ isConfirmingSystemDashboard: false })}
          >
            {copyText.cancelButtonLabel}
          </Button>
          <Button
            disabled={props.isProcessing}
            primary
            variant="danger"
            width={70}
            onClick={() => {
              const tags = state.inputTags.map(({ value }) => value);

              props.onSubmit({
                name: state.nameInput,
                scope: DashboardScope.GLOBAL,
                tags: tags.includes(SYSTEM_TAG) ? tags : [SYSTEM_TAG, ...tags],
              });
            }}
          >
            {props.isProcessing ? (
              <LoadingSpinner />
            ) : (
              copyText.systemReportConfirm
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const showCreateSystemButton =
    gatekeeper.canCreateSystemDashboard && props.isCreateModal;

  const isValid = state.nameInput.length > 0;

  const hasChanged =
    state.nameInput !== props.name ||
    !isEqual(
      state.inputTags,
      (props.tags ?? []).map((tag) => ({ label: tag, value: tag }))
    );

  const canSubmit = props.isCreateModal ? isValid : isValid && hasChanged;

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={true}
      showCloseButton
      width={500}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          autoFocus
          input={TextInput}
          label={copyText.nameInputLabel}
          placeholder={copyText.dashboardFormModalPlaceholder}
          value={state.nameInput}
          onChange={(event) => mergeState({ nameInput: event.target.value })}
        />

        <Box>
          <Text>{copyText.dashboardFormModalTagsLabel}</Text>
          <Select
            isMulti
            isSearchable
            isCreatable
            options={props.existingTags.map((tag) => ({
              label: tag,
              value: tag,
            }))}
            value={state.inputTags}
            onChange={(selectedTags) =>
              mergeState({ inputTags: [...selectedTags] })
            }
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          justifyContent={showCreateSystemButton ? "space-between" : "flex-end"}
          width={"100%"}
        >
          {showCreateSystemButton && (
            <Button
              disabled={state.nameInput.length === 0 || props.isProcessing}
              primary
              onClick={() => mergeState({ isConfirmingSystemDashboard: true })}
            >
              {copyText.systemDashboardSaveLabel}
            </Button>
          )}
          <Box>
            <Button
              disabled={props.isProcessing}
              secondary
              width={70}
              onClick={props.onClose}
            >
              {copyText.cancelButtonLabel}
            </Button>
            <Button
              disabled={
                !canSubmit ||
                (props.isGlobalDashboard &&
                  !gatekeeper.canUpdateSystemDashboard) ||
                props.isProcessing
              }
              primary
              marginLeft={theme.space_md}
              width={70}
              onClick={() =>
                props.onSubmit({
                  name: state.nameInput,
                  scope: DashboardScope.SHARED,
                  tags:
                    state.inputTags.length > 0
                      ? state.inputTags.map((inputTag) => inputTag.value)
                      : [],
                })
              }
            >
              {props.isProcessing ? (
                <LoadingSpinner />
              ) : (
                copyText.saveButtonLabel
              )}
            </Button>
          </Box>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
