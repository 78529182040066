import { nullable, number, optional } from "@/api/analytics/utils/Cubestruct";

export const DatabaseSpendSummaryStruct = {
  cost: nullable(number()),
};

export const CostSpendSummaryStruct = {
  cost: nullable(number()),
};

export const KubernetesSpendSummaryStruct = {
  cost: nullable(number()),
};

export const StorageSpendSummaryStruct = {
  cost: optional(nullable(number())),
};
