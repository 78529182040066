import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateResourceSubscriptionParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { subscriptionID: string } & UpdateResourceSubscriptionParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { subscriptionID: string } & UpdateResourceSubscriptionParams
>;

export function useUpdateResourceSubscription(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ subscriptionID, ...params }) =>
      client.updateResourceSubscription(subscriptionID, params),
    ...options,
  });
}
