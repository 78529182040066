import { DateHelper } from "@/lib/dates";
import DatePicker from "@/ui-lib/components/DatePicker";
import { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import IconTimes from "@/ui-lib/icons/IconTimes";
import { useTheme } from "@emotion/react";
import { AzureExportType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { ChangeEvent } from "react";
import Switch from "../../../../ui-lib/components/Switch";
import copyText from "../../copyText";
import { AzureBillingFields } from "../../types";

interface Props {
  billingExportFields: AzureBillingFields[];
  onUpdate: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
  onDelete: (index: number) => void;
}

export default function AzureBillingExport(props: Props): JSX.Element {
  const theme = useTheme();
  const now = new DateHelper();

  const typeOptions = [
    {
      label: copyText.azureExportTypeActualsLabel,
      value: AzureExportType.ACTUALS,
    },
    {
      label: copyText.azureExportTypeAmortizedLabel,
      value: AzureExportType.AMORTIZED,
    },
  ];

  return (
    <>
      {props?.billingExportFields?.map((billingExport, index: number) => {
        const selectedExportType = typeOptions.find(
          (option) => option.value === billingExport.exportTypeInput
        );

        return (
          <Flex key={index} alignItems="center" marginBottom={theme.space_xxl}>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.space_md}
            >
              <Flex justifyContent="space-between" width="100%">
                <Box width={422}>
                  <FormField label={copyText.cloudAzureExportTypeLabel}>
                    <Select
                      isClearable
                      options={typeOptions}
                      value={
                        selectedExportType
                          ? {
                              value: selectedExportType.value,
                              label: selectedExportType.label,
                            }
                          : null
                      }
                      onChange={(option) => {
                        props.onUpdate(
                          {
                            target: {
                              name: "exportType",
                              value: option?.value,
                            },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }}
                    />
                  </FormField>
                </Box>
                <FormField label={copyText.cloudAzureStrictStoragePrefixLabel}>
                  <Flex justifyContent="flex-end">
                    <Switch
                      name="strictStoragePrefix"
                      checked={
                        billingExport.strictStoragePrefixInput === "true"
                      }
                      onChange={(value) => {
                        props.onUpdate(
                          {
                            target: {
                              name: "strictStoragePrefix",
                              value: value === true ? "true" : "false",
                            },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }}
                    />
                  </Flex>
                </FormField>
              </Flex>
              <Flex>
                <FormField
                  input={TextInput}
                  label={copyText.cloudAzureServiceURLLabel}
                  name="serviceURL"
                  required
                  variant={
                    billingExport.serviceURLInput.length ? "success" : "danger"
                  }
                  value={billingExport.serviceURLInput}
                  onChange={(event) => props.onUpdate(event, index)}
                />
                <FormField
                  input={TextInput}
                  label={copyText.cloudAzureStorageContainerLabel}
                  marginLeft={theme.space_md}
                  name="storageContainer"
                  required
                  value={billingExport.storageContainerInput}
                  variant={
                    billingExport.storageContainerInput.length
                      ? "success"
                      : "danger"
                  }
                  onChange={(event) => props.onUpdate(event, index)}
                />
                <FormField
                  input={TextInput}
                  label={copyText.cloudAzureStoragePrefixLabel}
                  marginLeft={theme.space_md}
                  name="storagePrefix"
                  required
                  value={billingExport.storagePrefixInput}
                  variant={
                    billingExport.storagePrefixInput.length
                      ? "success"
                      : "danger"
                  }
                  onChange={(event) => props.onUpdate(event, index)}
                />
              </Flex>
              <Flex justifyContent="space-between" width="100%">
                <Box width={200}>
                  <FormField label={copyText.datePickerModalStartLabelOptional}>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      minDate={now.date}
                      onChange={(val: Date) => {
                        props.onUpdate(
                          {
                            target: {
                              name: "startDate",
                              value:
                                (val && val.toISOString().split("T")[0]) ?? "",
                            },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }}
                      selected={
                        typeof billingExport.startDateInput === "string" &&
                        billingExport.startDateInput.length
                          ? new Date(billingExport.startDateInput)
                          : null
                      }
                    />
                  </FormField>
                </Box>
                <Box width={200}>
                  <FormField label={copyText.datePickerModalEndLabelOptional}>
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      minDate={now.date}
                      onChange={(val: Date) => {
                        props.onUpdate(
                          {
                            target: {
                              name: "endDate",
                              value:
                                (val && val.toISOString().split("T")[0]) ?? "",
                            },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }}
                      selected={
                        typeof billingExport.endDateInput === "string" &&
                        billingExport.endDateInput.length
                          ? new Date(billingExport.endDateInput)
                          : null
                      }
                    />
                  </FormField>
                </Box>
                <Box width={200}>
                  <FormField
                    input={TextInput}
                    label={copyText.cloudAzuredateColumnLabelOptional}
                    name="dateColumn"
                    value={billingExport.dateColumnInput}
                    onChange={(event) => props.onUpdate(event, index)}
                  />
                </Box>
              </Flex>
            </Flex>
            {props?.billingExportFields.length > 1 && (
              <Box>
                <Button
                  iconStart={<IconTimes />}
                  size="small"
                  type="button"
                  onClick={() => props.onDelete(index)}
                />
              </Box>
            )}
          </Flex>
        );
      })}
    </>
  );
}
