import { Children, ComponentType, ReactElement, ReactNode } from "react";

export function excludeByType(
  children: ReactNode | undefined,
  type: ComponentType | ComponentType[]
): ReactElement[] | undefined {
  const types = Array.isArray(type) ? type : [type];

  return (
    Children.map(children, (child) => {
      if (isReactElement(child) && !types.some((type) => type === child.type)) {
        return child;
      }
    }) ?? undefined
  );
}

export function findByType(
  children: ReactNode | undefined,
  type: ComponentType
): ReactElement | undefined {
  let match: ReactElement | undefined = undefined;

  Children.forEach(children, (child) => {
    if (isReactElement(child) && child.type === type) {
      match = child;
    }
  });

  return match;
}

export function isReactElement(node: ReactNode): node is ReactElement {
  if (node && typeof node === "object" && node !== null && "type" in node) {
    const element: ReactElement = node;

    return Boolean(element);
  }

  return false;
}

export default { excludeByType, findByType, isReactElement };
