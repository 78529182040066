import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { format } from "date-fns";
import UError from "unilib-error";
import { validate } from "../../../../api/analytics/utils/Cubestruct";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSCommitmentAllocationDatum,
  AWSCommitmentAllocationStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
  granularity?: TimeGranularity;
  queryFilters?: QueryFilter[];
  dimensions: string[];
}

export default function useGetAWSCommitmentAllocation(
  params: Params,
  options?: UseQueryOptions<AWSCommitmentAllocationDatum[], UError>
): UseQueryResult<AWSCommitmentAllocationDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsCommitmentAllocation", params],
    queryFn: async () => {
      const allocationData = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_COMMITMENT_ALLOCATION,
          dateRange: [
            format(params.dateRange[0], "M/d/yyyy"),
            format(params.dateRange[1], "M/d/yyyy"),
          ],
          dimensions: params.dimensions,
          granularity: params.granularity,
          measures: [
            "amortizedCost",
            "countDistinctARN",
            "coveredUsageHours",
            "effectiveCost",
            "lineItemBlendedCost",
            "lineItemUnblendedCost",
            "netEffectiveCost",
            "riNetAmortizedUpfrontCostForUsage",
            "riNetRecurringFeeForUsage",
            "riRecurringFeeForUsage",
            "spCoveredUsageCost",
            "spNetAmortizedUpfrontCommitmentForBillingPeriod",
            "spNetRecurringCommitmentForBillingPeriod",
            "spRecurringCommitmentForBillingPeriod",
          ],
          queryFilters: params.queryFilters,
        })
      );

      return allocationData.map((datum) => {
        const [error, validData] = validate(
          datum,
          AWSCommitmentAllocationStruct
        );

        if (error) {
          throw new UError("INVALID_AWS_COMMITMENT_USAGE_DATUM", {
            context: { error, result: datum },
          });
        }

        /* prettier-ignore */
        return {
          // MEASURES
          amortizedCost: validData.amortizedCost ?? 0,
          countDistinctARN: validData.countDistinctARN ?? 0,
          coveredUsageHours: validData.coveredUsageHours ?? 0,
          effectiveCost: validData.effectiveCost ?? 0,
          lineItemBlendedCost: validData.lineItemBlendedCost ?? 0,
          lineItemUnblendedCost: validData.lineItemUnblendedCost ?? 0,
          netEffectiveCost: validData.netEffectiveCost ?? 0,
          riNetAmortizedUpfrontCostForUsage: validData.riNetAmortizedUpfrontCostForUsage ?? 0,
          riNetRecurringFeeForUsage: validData.riNetRecurringFeeForUsage ?? 0,
          riRecurringFeeForUsage: validData.riRecurringFeeForUsage ?? 0,
          spCoveredUsageCost: validData.spCoveredUsageCost ?? 0,
          spNetAmortizedUpfrontCommitmentForBillingPeriod: validData.spNetAmortizedUpfrontCommitmentForBillingPeriod ?? 0,
          spNetRecurringCommitmentForBillingPeriod: validData.spNetRecurringCommitmentForBillingPeriod ?? 0,
          spRecurringCommitmentForBillingPeriod: validData.spRecurringCommitmentForBillingPeriod ?? 0,

          // DIMENSIONS
          billPayerAccountId: validData.billPayerAccountId ?? "",
          commitmentARN: validData.commitmentARN ?? "",
          commitmentType: validData.commitmentType ?? "",
          coverageType: validData.coverageType ?? "",
          instanceId: validData.instanceId ?? null,
          lineItemUsageAccountId: validData.lineItemUsageAccountId ?? "",
          offeringType: validData.offeringType ?? "",
          region: validData.region ?? "",
        };
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
