import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type LabelGroupingEntity = Awaited<
  ReturnType<CoreAPIClient["getLabelGroupingRulesByTenantID"]>
>[0];

type Options = UseQueryOptions<LabelGroupingEntity[], CoreAPIClientError>;
type Result = UseQueryResult<LabelGroupingEntity[], CoreAPIClientError>;

export default function useGetLabelGroupingRulesByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["labelGroupingRules"],
    queryFn: () => client.getLabelGroupingRulesByTenantID(tenantID),
    enabled: gatekeeper.canListCustomLabels,
    ...options,
  });
}
