import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPCloudSQL(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 60 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_8)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 34.26V46.3L29.82 61.9V49.86L0.5 34.26ZM0.5 52.86V64.9L29.82 80.5V68.46L0.5 52.86Z"
            fill="#AECBFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.82 61.9L59.14 46.3V34.26L29.82 49.86V61.9ZM29.82 80.5L59.14 64.9V52.86L29.82 68.46V80.5Z"
            fill="#669DF6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.14 28.14V16.1L29.82 0.5V12.54L59.14 28.14Z"
            fill="#4285F4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.82 0.5L0.5 16.1V28.14L29.82 12.54V0.5Z"
            fill="#669DF6"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.5 15.98V28.06L29.82 43.66V31.58L0.5 15.98Z"
            fill="#AECBFA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.82 43.66L59.14 28.06V15.98L29.82 31.58V43.66Z"
            fill="#669DF6"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_8">
            <rect width="60" height="81" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
