import { useTheme } from "@emotion/react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React from "react";
import ReactSwitch from "react-switch";

export interface Props {
  checked: boolean;
  disabled?: boolean;
  height?: number;
  name?: string;
  width?: number;
  onChange: (checked: boolean, name?: string) => void;
}

// TODO: Add Icons to handle
export default function Switch({
  checked,
  disabled,
  height,
  name,
  width,
  onChange,
}: Props): JSX.Element {
  const theme = useTheme();

  //Should be 2 pixels smaller than the height of the switch
  const diameter = height ? height - 2 : undefined;

  return (
    <ReactSwitch
      checked={checked}
      checkedIcon={false}
      disabled={disabled}
      height={height ?? 24}
      name={name}
      offColor={theme.switch_color_off}
      onColor={theme.switch_color_on}
      uncheckedIcon={false}
      width={width ?? 40}
      handleDiameter={diameter}
      onChange={(checked) => onChange(checked, name)}
    />
  );
}

type LabeledSwitchProps = Props & {
  label: string;
  labelSide?: "left" | "right";
  tooltipCaption?: string;
};

export function LabeledSwitch({
  label,
  labelSide,
  tooltipCaption,
  ...props
}: LabeledSwitchProps) {
  const theme = useTheme();

  const labelText = (
    <Flex
      alignItems="center"
      marginRight={labelSide !== "right" ? theme.space_sm : undefined}
      marginLeft={labelSide === "right" ? theme.space_sm : undefined}
    >
      <Text
        color={props.disabled ? theme.text_color_disabled : theme.text_color}
        whiteSpace="nowrap"
      >
        {label}
      </Text>
      {tooltipCaption && (
        <Box marginLeft={theme.space_xs}>
          <Tooltip content={tooltipCaption}>
            <Icon
              icon={faCircleInfo}
              color={theme.text_color_inverse}
              size="sm"
            />
          </Tooltip>
        </Box>
      )}
    </Flex>
  );

  return (
    <label>
      <Flex
        alignItems="center"
        cursor={props.disabled ? undefined : "pointer"}
        justifyContent="space-between"
      >
        {labelSide !== "right" && labelText}
        <Switch {...props} />
        {labelSide === "right" && labelText}
      </Flex>
    </label>
  );
}
