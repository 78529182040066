import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAwsMemoryDB(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" version="1.1">
        <title>Icon-Architecture/32/Arch_Amazon-MemoryDB-for-Redis_32</title>
        <g
          id="Icon-Architecture/32/Arch_Amazon-MemoryDB-for-Redis_32"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Icon-Architecture-BG/32/Database" fill="#C925D1">
            <rect id="Rectangle" x="0" y="0" width="40" height="40" />
          </g>
          <g
            id="Icon-Service/32/Amazon-MemoryDB-for-Redis_32"
            transform="translate(6.000000, 7.000000)"
            fill="#FFFFFF"
          >
            <path
              d="M9.26,6.09 C4.393,6.09 1,4.749 1,3.545 C1,2.341 4.393,1 9.26,1 C14.077,1 17.06,2.321 17.06,3.545 C17.06,4.769 14.077,6.09 9.26,6.09 L9.26,6.09 Z M16.943,13.591 C17.892,13.591 18.838,13.697 19.755,13.906 L19.978,12.931 C19.33,12.783 18.667,12.701 18,12.65 L18,3.991 C18.036,3.846 18.06,3.698 18.06,3.545 C18.06,1.425 14.524,0 9.26,0 C4.658,0 0,1.218 0,3.545 L0,23.163 C0,24.577 1.534,25.718 4.321,26.377 L4.551,25.403 C2.361,24.886 1,24.027 1,23.163 L1,19.024 C1.649,19.439 2.477,19.804 3.499,20.088 L3.767,19.124 C2.498,18.771 1.544,18.294 1,17.771 L1,12.018 C2.589,13.055 5.351,13.754 8.627,13.89 L8.668,12.891 C5.12,12.744 2.161,11.91 1,10.757 L1,5.232 C2.612,6.455 5.946,7.09 9.26,7.09 C12.816,7.09 15.579,6.438 17,5.362 L17,12.594 C16.981,12.594 16.962,12.591 16.943,12.591 C11.874,12.591 7.247,15.382 4.867,19.874 L5.75,20.341 C7.956,16.178 12.245,13.591 16.943,13.591 L16.943,13.591 Z M23.411,15.372 C22.613,14.896 21.761,14.507 20.879,14.218 L21.192,13.268 C22.143,13.58 23.062,13.999 23.923,14.513 L23.411,15.372 Z M18.614,26.074 C18.549,25.468 18.317,24.917 17.956,24.464 L23.416,19.301 C25.23,21.021 26.358,23.503 26.488,26.074 L18.614,26.074 Z M15.505,24.263 C16.573,24.263 17.453,25.051 17.614,26.074 L13.396,26.074 C13.557,25.051 14.437,24.263 15.505,24.263 L15.505,24.263 Z M24.141,18.616 L26.023,16.837 L25.335,16.111 L17.223,23.78 C16.729,23.456 16.139,23.263 15.505,23.263 C13.886,23.263 12.565,24.499 12.396,26.074 L6.901,26.074 C7.157,20.818 11.452,16.622 16.695,16.622 C18.105,16.622 19.466,16.915 20.739,17.492 L21.153,16.581 C19.748,15.944 18.248,15.622 16.695,15.622 C10.736,15.622 5.888,20.535 5.888,26.574 L5.888,27.074 L27.5,27.074 L27.5,26.574 C27.5,23.576 26.244,20.632 24.141,18.616 L24.141,18.616 Z"
              id="Fill-6"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
}
