import { TableLegacy } from "@/ui-lib/components/Table";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../../copyText";
import { CloudSQLResourceType, CloudSQLUsageDatum } from "../types";

type TableData = {
  databaseId: string;
  latestProvisionedCpu: number | null;
  latestProvisionedDisk: number | null;
  latestProvisionedRam: number | null;
  maxCpuAverageUsedCores: number | null;
  maxDiskUsedBytes: number | null;
  maxRamAverageUsedBytes: number | null;
  projectId: string;
  totalCost: number;
};

interface Props {
  instances: CloudSQLUsageDatum[];
  isLoading: boolean;
  resourceType: CloudSQLResourceType;
}

export default function GCPDatabaseInstanceTable(props: Props): JSX.Element {
  const getDefaultSortBy = () => {
    switch (props.resourceType) {
      case CloudSQLResourceType.MEMORY:
        return "latestProvisionedRam";
      case CloudSQLResourceType.DISK:
        return "latestProvisionedDisk";
      case CloudSQLResourceType.NETWORK:
        return "latestProvisionedRam";
      default:
        return "latestProvisionedCpu";
    }
  };

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "databaseId",
        Header: copyText.cloudSQLInstanceTableHeaderDatabaseId,
      },
      {
        accessor: "totalCost",
        Header: copyText.cloudSQLInstanceTableHeaderTotalCost,
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
      },
      {
        accessor: "latestProvisionedCpu",
        Header: copyText.cloudSQLInstanceTableHeaderMaxCpuReservedCores,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.CPU, value)}</>
        ),
      },
      {
        accessor: "maxCpuAverageUsedCores",
        Header: copyText.cloudSQLInstanceTableHeaderMaxCpuAverageUsedCores,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.CPU, value)}</>
        ),
      },
      {
        accessor: "latestProvisionedRam",
        Header: copyText.cloudSQLInstanceTableHeaderRamReservedBytes,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.MEMORY, value)}</>
        ),
      },
      {
        accessor: "maxRamAverageUsedBytes",
        Header: copyText.cloudSQLInstanceTableHeaderMaxRamAverageUsedBytes,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.MEMORY, value)}</>
        ),
      },
      {
        accessor: "latestProvisionedDisk",
        Header: copyText.cloudSQLInstanceTableHeaderMaxDiskSizeBytes,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.DISK, value)}</>
        ),
      },
      {
        accessor: "maxDiskUsedBytes",
        Header: copyText.cloudSQLInstanceTableHeaderMaxDiskUsedBytes,
        Cell: ({ value }) => (
          <>{formatUsage(CloudSQLResourceType.DISK, value)}</>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => {
    return props.instances.map((node): TableData => {
      return {
        databaseId: node.databaseId ?? "",
        totalCost: node.totalCost ?? "",
        projectId: node.projectId ?? "",
        latestProvisionedCpu: node.latestProvisionedCpu ?? 0,
        maxCpuAverageUsedCores: node.maxCpuAverageUsedCores ?? 0,
        latestProvisionedRam: node.latestProvisionedRam ?? 0,
        maxRamAverageUsedBytes: node.avgRamUsedBytes ?? 0,
        latestProvisionedDisk: node.latestProvisionedDisk ?? 0,
        maxDiskUsedBytes: node.avgDiskUsedBytes ?? 0,
      };
    });
  }, [props.instances]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      showPagination
      initialState={{
        sortBy: [{ id: getDefaultSortBy(), desc: true }],
      }}
      sortable
      showEmptyTable
    />
  );
}

const formatUsage = (usageType: CloudSQLResourceType, bytes: number | null) => {
  if (bytes === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return usageType !== CloudSQLResourceType.CPU
    ? prettyBytes(bytes)
    : formatNumber(bytes, 2);
};
