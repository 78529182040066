import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { integrationID: string; providerType: CloudProviderType }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { integrationID: string; providerType: CloudProviderType }
>;

export default function useDeleteDataIntegration(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ integrationID, providerType }) =>
      client.deleteDataIntegration(integrationID, providerType),
    ...options,
  });
}
