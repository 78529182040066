import React, { createContext, PropsWithChildren, useContext } from "react";
import { InternalApiClient } from "./InternalApiClient";

const context = createContext<InternalApiClient | undefined>(undefined);

export interface Props {
  client: InternalApiClient;
}

export default function InternalApiClientProvider({
  children,
  client,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={client}>{children}</context.Provider>;
}

export function useInternalApiClient(): InternalApiClient {
  const client = useContext(context);

  if (!client) {
    throw new Error(
      "useInternalApiClient must be wrapped by InternalApiClientProvider"
    );
  }

  return client;
}
