import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";

type Params = {
  rampPlanID: string;
};

type Options = UseMutationOptions<string, CoreAPIClientError, Params>;

type Result = UseMutationResult<string, CoreAPIClientError, Params>;

export default function useDeleteRampPlan(options: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ rampPlanID }) => client.deleteRampPlan(rampPlanID),
    ...options,
  });
}
