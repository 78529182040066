import Form, { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import { Option } from "@/ui-lib/components/SelectDropdown";
import TextArea from "@/ui-lib/components/TextArea";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faExchange,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React, { useState } from "react";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import copyText from "../copyText";

type Case = {
  id: string;
  name: string;
};

interface Props {
  cases: Case[];
  isLoading: boolean;
  isProcessing: boolean;
  onClose: () => void;
  onInteraction: (
    interaction: AddResourceCommentToCaseForm.Interaction
  ) => void;
}

interface State {
  caseIDInput: string;
  commentInput: string;
}

const initialState = {
  caseIDInput: "",
  commentInput: "",
};

export function AddResourceCommentToCaseForm(props: Props): JSX.Element {
  const theme = useTheme();

  const resourceSelectionModeState = useCaseManagementStore();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Interaction Handlers
  //

  function handleAddChart() {
    resourceSelectionModeState.set({
      isResourceSelectionMode: true,
      selectedResourceID: null,
      selectedResourceType: null,
    });
  }

  function handleSubmit(): void {
    props.onInteraction({
      type: AddResourceCommentToCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      caseID: state.caseIDInput,
      text: state.commentInput,
    });
  }

  function handleReset() {
    resourceSelectionModeState.reset();
    setState(initialState);
    props.onClose();
  }

  const caseOptions: Option[] = props.cases.map((_case) => ({
    label: _case.name,
    value: _case.id,
  }));

  const selectedCase =
    caseOptions.find((caseOption) => caseOption.value === state.caseIDInput) ??
    null;

  const selectedResourceID = resourceSelectionModeState.selectedResourceID;
  const selectedResourceName = resourceSelectionModeState.selectedResourceName;

  const canSave = state.commentInput.length > 0 && state.caseIDInput.length > 0;

  function renderButtonContent() {
    if (!selectedResourceID || !selectedResourceName) {
      return copyText.selectReportButtonLabel;
    }

    const content = (
      <Flex>
        <Box marginRight={theme.space_sm}>Name:</Box>
        <Box>{selectedResourceName}</Box>
      </Flex>
    );

    return (
      <Flex alignItems="center">
        <Box marginRight={theme.space_sm}>
          {copyText.changeReportButtonLabel}
        </Box>
        <Tooltip content={content}>
          <Icon icon={faInfoCircle} />
        </Tooltip>
      </Flex>
    );
  }

  return (
    <Form>
      <Flex direction="column">
        <FormField label={copyText.formFieldLabelSelectCase}>
          <Select
            isLoading={props.isLoading}
            isSearchable
            options={caseOptions}
            value={selectedCase}
            onChange={(option) =>
              mergeState({ caseIDInput: option?.value ?? "" })
            }
          />
        </FormField>
      </Flex>
      <FormField
        name="comment"
        input={TextArea}
        label={copyText.formFieldLabelComment}
        resizeable
        required
        rows={8}
        value={state.commentInput}
        onChange={(event) => mergeState({ commentInput: event.target.value })}
      />
      <Button
        disabled={props.isProcessing}
        iconStart={<Icon icon={selectedResourceID ? faExchange : faPlus} />}
        marginBottom={theme.space_lg}
        primary={!!selectedResourceID}
        secondary={!selectedResourceID}
        size="small"
        type="button"
        variant={selectedResourceID ? "success" : undefined}
        width="100%"
        onClick={handleAddChart}
      >
        {renderButtonContent()}
      </Button>
      <Flex justifyContent="flex-end" marginTop={theme.space_sm}>
        <Button secondary type="reset" width={100} onClick={handleReset}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={props.isProcessing || !canSave}
          marginLeft={theme.space_sm}
          primary
          width={100}
          onClick={handleSubmit}
          type="button"
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

AddResourceCommentToCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "CreateCaseActivityForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionSubmitButtonClicked = {
  type: typeof AddResourceCommentToCaseForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  caseID: string;
  text: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AddResourceCommentToCaseForm {
  export type Interaction = InteractionSubmitButtonClicked;
}

export default AddResourceCommentToCaseForm;
