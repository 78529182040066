import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BudgetsCurrentDailyGraph } from "@ternary/api-lib/ui-lib/charts/BudgetsCurrentDailyGraph";
import BudgetsHistoricalDailyGraph from "@ternary/api-lib/ui-lib/charts/BudgetsHistoricalDailyGraph";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, { useState } from "react";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import Card from "../../../components/Card";
import Tabs from "../../../ui-lib/components/Tabs";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { Budget } from "../types";
import BudgetVersionedMonthlyBarChart from "./BudgetVersionedMonthlyBarChart";
import BudgetsHistoricalMonthlyGraph from "./BudgetsHistoricalMonthlyGraph";
import BudgetsReportSummaryModal from "./BudgetsReportSummaryModal";

const CHART_HEIGHT = 180;

const StyledButton = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 150px;
  right: 20px;
  background-color: ${(props) =>
    props.theme.budgets_expand_button_background_color};
  transform: translateY(-0.5rem);

  :hover {
    background-color: ${(props) =>
      props.theme.budgets_expand_button_background_color};
    transform: translateY(-0.6rem);
  }
`;

interface Props {
  dailySpend: BudgetSpendEntity[];
  monthlySpend: BudgetSpendEntity[];
  loading: boolean;
  selectedBudget: Budget | undefined;
  selectedYear: number;
  onSelectYear: (selectedYear: number) => void;
}

interface State {
  showBudgetModal: boolean;
  tab: "month" | "year";
}

export default function BudgetsChartsSection(props: Props): JSX.Element | null {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    showBudgetModal: false,
    tab: "month",
  });
  const mergeState = getMergeState(setState);

  function handleToggleShowModal(showBudgetModal: boolean) {
    mergeState({ showBudgetModal });
  }

  const month = (
    <Flex justifyContent="space-between">
      <StyledButton
        circular
        iconStart={
          <FontAwesomeIcon
            color={theme.text_color_inverse}
            icon={faExpandAlt}
          />
        }
        onClick={() => handleToggleShowModal(true)}
      />
      <Box width="33%">
        <BudgetsCurrentDailyGraph
          budget={props.selectedBudget || null}
          data={props.dailySpend}
          height={CHART_HEIGHT}
          loading={props.loading}
        />
      </Box>
      <Box width="33%">
        <BudgetsHistoricalDailyGraph
          budget={props.selectedBudget || null}
          data={props.dailySpend}
          height={CHART_HEIGHT}
          loading={props.loading}
        />
      </Box>
      <Box width="33%">
        <BudgetsHistoricalMonthlyGraph
          budget={props.selectedBudget || null}
          dailySpend={props.dailySpend}
          height={CHART_HEIGHT}
          loading={props.loading}
        />
      </Box>
    </Flex>
  );

  const isVariable =
    props.selectedBudget && props.selectedBudget.amount.variableAmount;

  const year =
    props.selectedBudget && isVariable ? (
      <Box width="100%">
        <BudgetVersionedMonthlyBarChart
          budget={props.selectedBudget}
          loading={props.loading}
          height={CHART_HEIGHT}
          selectedYear={props.selectedYear}
          spendData={props.monthlySpend}
          onSelectYear={props.onSelectYear}
        />
      </Box>
    ) : null;

  if (!props.selectedBudget) return null;

  return (
    <>
      <BudgetsReportSummaryModal
        budget={props.selectedBudget}
        dailySpend={props.dailySpend}
        loading={props.loading}
        show={state.showBudgetModal}
        onHide={() => handleToggleShowModal(false)}
      />
      <Card marginBottom={theme.space_md} minHeight="300px" width="100%">
        {isVariable ? (
          <Tabs
            activeValue={state.tab}
            tabs={[
              { component: month, value: "month", label: copyText.unitMonth },
              { component: year, value: "year", label: copyText.unitYear },
            ]}
            onSelect={(tab) => mergeState({ tab: tab as "month" | "year" })}
          />
        ) : (
          month
        )}
      </Card>
    </>
  );
}
