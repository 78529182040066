import { Theme, useTheme } from "@emotion/react";
import React, { useMemo, useState } from "react";
import { GroupBase, InputActionMeta, StylesConfig } from "react-select";
import _Select from "react-select/async";
import CreatableSelect, {
  CreatableProps as SelectProps,
} from "react-select/creatable";

const MAX_OPTIONS = 250;

export type DefaultOption = { label: string; value: string };

export interface Props<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends SelectProps<OptionType, IsMulti, GroupType> {
  compact?: boolean;
  disabled?: boolean;
  isCreatable?: boolean;
  searchable?: boolean;
}

export function getStyles<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  theme: Theme,
  compact?: boolean,
  hideMultiDeleteButton?: boolean
): Partial<StylesConfig<OptionType, IsMulti, GroupType>> {
  return {
    container: (styles) => ({ ...styles, width: "100%" }),
    control: (styles, props) => ({
      ...styles,
      backgroundColor: props.isDisabled
        ? theme.background_color_disabled
        : theme.panel_backgroundColor,
      borderColor: props.isFocused
        ? "transparent"
        : theme.select_control_border_color,
      borderStyle: "solid",
      borderWidth: `1px`,
      borderRadius: theme.borderRadius_2,
      boxShadow: props.isFocused
        ? `0 0 0 2px ${theme.primary_color_focus}`
        : "none",
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      minHeight: compact ? "28px" : "38px",

      "&:hover": {
        borderColor: props.isFocused
          ? "transparent"
          : theme.primary_color_border,
      },
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      minHeight: compact ? "28px" : "38px",

      "div:nth-of-type(1)": {
        ...(hideMultiDeleteButton ? { display: "none" } : {}),
      },

      "div:last-child": {
        padding: compact ? "4px" : styles.padding,
      },
    }),
    indicatorSeparator: (styles) => ({ ...styles, width: 0 }),
    input: (styles) => ({
      ...styles,
      color: `${theme.select_color}`,
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
      border: "none",
      borderRadius: theme.borderRadius_2,
      boxShadow: `0 4px 8px ${theme.box_shadow}`,
      padding: `0 ${theme.space_xxs}`,
      zIndex: theme.zIndex_100,
    }),
    menuList: (styles) => ({ ...styles, padding: 0 }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: theme.secondary_color_background,
      borderRadius: theme.borderRadius_1,
      color: theme.secondary_color,
    }),
    option: (styles, props) => ({
      ...styles,
      backgroundColor: props.isSelected
        ? theme.primary_color_background
        : theme.input_background_color,
      borderRadius: theme.borderRadius_2,
      color: props.isSelected ? theme.text_color_inverse : theme.text_color,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      margin: `${theme.space_xxs} 0`,
      transition: "none",

      "&:hover": {
        backgroundColor: props.isSelected
          ? theme.primary_color_background
          : theme.secondary_color_background,
      },
    }),
    singleValue: (styles, props) => ({
      ...styles,
      color: props.isDisabled ? theme.text_color_disabled : theme.select_color,
    }),
    valueContainer: (styles) => ({
      ...styles,
      minHeight: compact ? "28px" : "38px",
    }),
  };
}

export default function Select<
  OptionType extends { value: unknown } = DefaultOption,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(props: Props<OptionType, IsMulti, GroupType>): JSX.Element {
  const [stateInputValue, setInputValue] = useState("");

  const theme = useTheme();
  const disabled = props.disabled || props.isDisabled;
  const searchable = props.searchable || props.isSearchable || false;

  const inputValue = props.inputValue ?? stateInputValue;

  /*
  NOTE:
    react-select uses the same filterOption function for options ({ label, value })
    and groups ({ label, options: Option[] }). For an option within a group to pass
    the filter, filterOption must first return true for the group, then again for the
    option itself.
  */
  function filterOption(option: OptionType | GroupType, rawInput: string) {
    if (option === null) return false;

    if (
      typeof option === "object" &&
      "options" in option &&
      Array.isArray(option.options)
    ) {
      // Current option is a group of options
      const group = option;
      const foundMatchInGroup = group.options.some((groupOption: OptionType) =>
        filterOption(groupOption, rawInput)
      );
      return foundMatchInGroup;
    } else if (
      typeof option === "object" &&
      "label" in option &&
      typeof option.label === "string"
    ) {
      // Current option is a single option
      return inputMatchesText(rawInput, option.label);
    } else {
      // If option.label is not a string, it can't be filtered
      return true;
    }
  }

  function getFilteredOptions(): (OptionType | GroupType)[] {
    if (!props.options) return [];

    if (!searchable) return [...props.options];

    return props.options
      ?.filter((option) => filterOption(option, inputValue))
      .sort(sortForSelecting)
      .slice(0, MAX_OPTIONS);
  }

  /*
  NOTE:
    if 50k options is not enough, reach for server side filtering.
    on change, issue a new query
    add a filter to cube that says dimension INCLUDES inputValue
  */
  function loadOptions() {
    return new Promise<(OptionType | GroupType)[]>((resolve) => {
      resolve(getFilteredOptions());
    });
  }

  function sortForSelecting(optionA: OptionType | GroupType) {
    if (optionA && Array.isArray(props.value)) {
      if (typeof optionA === "object" && "value" in optionA) {
        const selected = props.value.find(
          (option) => option.value === optionA.value
        );

        if (selected) {
          return -1;
        }
      } else if (typeof optionA === "object" && "options" in optionA) {
        const selected = props.value.find((value) =>
          optionA.options.some((option) => option.value === value)
        );

        if (selected) {
          return -1;
        }
      }
    }

    return 0;
  }

  function handleInputChange(value: string, { action }: InputActionMeta): void {
    if (["input-blur", "set-value"].includes(action)) {
      return;
    }

    // Necessary for the value to actually be visible and not conflict with search text
    if (["menu-close"].includes(action)) {
      setInputValue("");
      return;
    }

    setInputValue(value);
  }

  const defaultOptions = useMemo(() => {
    if (!props.options) return [];

    if (props.options.length < MAX_OPTIONS) {
      return [...props.options].sort(sortForSelecting);
    }

    return getFilteredOptions().sort(sortForSelecting).slice(0, MAX_OPTIONS);
  }, [inputValue, props.menuIsOpen, props.options, props.isLoading]);

  const hideMultiDeleteButton =
    props.isMulti && Array.isArray(props.value) && props.value.length <= 1;

  const selectProps = {
    isDisabled: disabled,
    isSearchable: searchable,
    inputValue: inputValue,
    loadOptions: loadOptions,
    defaultOptions: defaultOptions,
    styles: getStyles<OptionType, IsMulti, GroupType>(
      theme,
      props.compact,
      hideMultiDeleteButton
    ),
    onInputChange: props.onInputChange ?? handleInputChange,
    filterOption: filterOption,
    ...props,
  } as Omit<typeof props, "components">;

  return props.isCreatable ? (
    <CreatableSelect
      {...selectProps}
      key={JSON.stringify(props.isLoading)}
      defaultValue={props.defaultValue}
      formatCreateLabel={props.formatCreateLabel}
      isValidNewOption={props.isValidNewOption}
    />
  ) : (
    <_Select {...selectProps} key={JSON.stringify(props.isLoading)} />
  );
}

function inputMatchesText(input: string, text: string) {
  return text.toLowerCase().includes(input.toLowerCase());
}
