import Modal from "@/ui-lib/components/Modal";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  DayOfWeek,
  ResourceType,
  SubscriptionFrequency,
  WeekOfMonth,
} from "@ternary/api-lib/constants/enums";
import { DashboardEntity, UserEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, keyBy } from "lodash";
import React, { ChangeEvent, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import { AlertType, postAlert } from "../../../utils/alerts";
import copyText from "../copyText";
import { useCreateResourceSubscription } from "../hooks/useCreateResourceSubscription";
import { useDeleteResourceSubscription } from "../hooks/useDeleteResourceSubscription";
import { useUpdateResourceSubscription } from "../hooks/useUpdateResourceSubscription";
import { convertToHourOfDay, getDateTimeInput } from "../utils";
import DashboardSubscriptionForm from "./DashboardSubscribeForm";
import DashboardSubscriptionList from "./DashboardSubscriptionList";

export enum Action {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

interface Props {
  isLoadingDashboard: boolean;
  dashboard: DashboardEntity;
  users: UserEntity[];
  refetch: () => void;
  onClose: () => void;
}

type State = {
  action: Action | null;
  dateTimeInput: Date;
  emailsInput: string[];
  frequencyInput: SubscriptionFrequency;
  selectedDayOfWeekInput: DayOfWeek | null;
  selectedSubscriptionID: string | null;
  selectedWeekOfMonthInput: WeekOfMonth | null;
};

const todayAtNoon = new Date();
todayAtNoon.setHours(12);
todayAtNoon.setMinutes(0);

const initialState: State = {
  action: Action.CREATE,
  dateTimeInput: todayAtNoon,
  emailsInput: [],
  frequencyInput: SubscriptionFrequency.DAILY,
  selectedDayOfWeekInput: null,
  selectedSubscriptionID: null,
  selectedWeekOfMonthInput: null,
};

export default function DashboardSubscriptionContainer(
  props: Props
): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const [state, setState] = useState(
    props.dashboard.subscriptions && props.dashboard.subscriptions.length > 0
      ? {
          ...initialState,
          action: null,
        }
      : initialState
  );
  const mergeState = getMergeState(setState);

  const subscriptions = props.dashboard.subscriptions ?? [];

  const {
    isPending: isCreatingResourceSubscription,
    mutate: createResourceSubscription,
  } = useCreateResourceSubscription({
    onError: () => {
      props.onClose();
      postAlert({
        message: copyText.errorCreatingResourceSubscriptionMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      if (subscriptions.length === 0) {
        props.onClose();
      }

      props.refetch();

      mergeState({ action: null });

      postAlert({
        message: copyText.SUCCESS_DASHBOARD_SUBSCRIPTION_CREATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdatingResourceSubscription,
    mutate: updateResourceSubscription,
  } = useUpdateResourceSubscription({
    onError: () => {
      props.onClose();
      postAlert({
        message: copyText.errorUpdatingResourceSubscriptionMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      props.refetch();

      mergeState({ action: null, selectedSubscriptionID: null });

      postAlert({
        message: copyText.SUCCESS_DASHBOARD_SUBSCRIPTION_UPDATED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeletingResourceSubscription,
    mutate: deleteResourceSubscription,
  } = useDeleteResourceSubscription({
    onError: () => {
      props.onClose();
      postAlert({
        message: copyText.errorDeletingResourceSubscriptionMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      props.refetch();

      if (subscriptions.length === 1) {
        props.onClose();
      }

      mergeState({ action: null, selectedSubscriptionID: null });

      postAlert({
        message: copyText.SUCCESS_DASHBOARD_SUBSCRIPTION_DELETED_message,
        type: AlertType.SUCCESS,
      });
    },
  });

  const subscriptionsKeyedByID = keyBy(subscriptions, "id");

  function handleSubscriptionClicked(id: string): void {
    const subscription = subscriptionsKeyedByID[id];

    mergeState({
      action: Action.UPDATE,
      dateTimeInput: getDateTimeInput(subscription.hourOfDay),
      emailsInput: subscription.emails,
      frequencyInput: subscription.frequency,
      selectedDayOfWeekInput: subscription.dayOfWeek,
      selectedWeekOfMonthInput: subscription.weekOfMonth,
      selectedSubscriptionID: subscription.id,
    });
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value: string | string[] | Date = event.target.value;

    if (value === SubscriptionFrequency.DAILY) {
      mergeState({
        [`${name}Input`]: value,
        selectedWeekOfMonthInput: null,
        selectedDayOfWeekInput: null,
      });

      return;
    } else if (value === SubscriptionFrequency.WEEKLY) {
      mergeState({
        [`${name}Input`]: value,
        selectedWeekOfMonthInput: null,
      });
    }

    mergeState({ [`${name}Input`]: value });
  }

  function handleSubmit(): void {
    if (
      state.action === Action.CREATE &&
      state.selectedSubscriptionID === null
    ) {
      createResourceSubscription({
        tenantID: authenticatedUser.tenant.id,
        dashboardID: props.dashboard.id,
        emails: state.emailsInput,
        frequency: state.frequencyInput,
        hourOfDay: convertToHourOfDay(state.dateTimeInput.getUTCHours()),
        ...(state.selectedDayOfWeekInput
          ? { dayOfWeek: state.selectedDayOfWeekInput }
          : {}),
        ...(state.selectedWeekOfMonthInput
          ? { weekOfMonth: state.selectedWeekOfMonthInput }
          : {}),
        resourceType: ResourceType.DASHBOARD,
      });
    } else if (
      state.action === Action.UPDATE &&
      state.selectedSubscriptionID !== null
    ) {
      updateResourceSubscription({
        subscriptionID: state.selectedSubscriptionID,
        emails: state.emailsInput,
        frequency: state.frequencyInput,
        hourOfDay: convertToHourOfDay(state.dateTimeInput.getUTCHours()),
        ...(state.selectedDayOfWeekInput
          ? { dayOfWeek: state.selectedDayOfWeekInput }
          : {}),
        ...(state.selectedWeekOfMonthInput
          ? { weekOfMonth: state.selectedWeekOfMonthInput }
          : {}),
      });
    }

    if (subscriptions.length > 0) {
      mergeState({ action: null });
    }
  }

  function handleDelete(): void {
    if (!state.selectedSubscriptionID) return;

    deleteResourceSubscription({
      subscriptionID: state.selectedSubscriptionID,
    });
  }

  function renderForm(): JSX.Element | null {
    switch (state.action) {
      case Action.CREATE: {
        return (
          <DashboardSubscriptionForm
            action={Action.CREATE}
            dateTimeInput={state.dateTimeInput}
            emails={state.emailsInput}
            frequency={state.frequencyInput}
            selectedDayOfWeek={state.selectedDayOfWeekInput}
            selectedWeekOfMonth={state.selectedWeekOfMonthInput}
            users={props.users}
            onChange={handleInputChange}
          />
        );
      }
      case Action.UPDATE: {
        return (
          <DashboardSubscriptionForm
            action={Action.UPDATE}
            dateTimeInput={state.dateTimeInput}
            emails={state.emailsInput}
            frequency={state.frequencyInput}
            selectedDayOfWeek={state.selectedDayOfWeekInput}
            selectedWeekOfMonth={state.selectedWeekOfMonthInput}
            users={props.users}
            onChange={handleInputChange}
          />
        );
      }

      default: {
        return null;
      }
    }
  }

  function canSubmit(): boolean {
    const subscription = state.selectedSubscriptionID
      ? subscriptionsKeyedByID[state.selectedSubscriptionID]
      : undefined;

    if (subscription && state.action === Action.UPDATE) {
      return !isEqual(
        {
          emails: subscription.emails,
          frequency: subscription.frequency,
          dayOfWeek: subscription.dayOfWeek,
          weekOfMonth: subscription.weekOfMonth,
          hourOfDay: subscription.hourOfDay,
        },
        {
          emails: state.emailsInput,
          frequency: state.frequencyInput,
          dayOfWeek: state.selectedDayOfWeekInput,
          weekOfMonth: state.selectedWeekOfMonthInput,
          hourOfDay: convertToHourOfDay(state.dateTimeInput.getUTCHours()),
        }
      );
    } else if (!subscription && state.action === Action.CREATE) {
      let hasGranularFrequency = false;
      if (state.frequencyInput === SubscriptionFrequency.WEEKLY) {
        hasGranularFrequency = !!state.selectedDayOfWeekInput;
      } else if (state.frequencyInput === SubscriptionFrequency.MONTHLY) {
        hasGranularFrequency =
          !!state.selectedDayOfWeekInput && !!state.selectedWeekOfMonthInput;
      } else {
        hasGranularFrequency = true;
      }

      return state.emailsInput.length > 0 && hasGranularFrequency;
    }

    return false;
  }

  const isLoading =
    props.isLoadingDashboard ||
    isCreatingResourceSubscription ||
    isUpdatingResourceSubscription ||
    isDeletingResourceSubscription;

  if (state.action === Action.DELETE && state.selectedSubscriptionID) {
    const subscription = subscriptionsKeyedByID[state.selectedSubscriptionID];
    const subscriberCount = subscription.emails.length;
    return (
      <ConfirmationModal
        isLoading={isDeletingResourceSubscription}
        message={copyText.deleteDashboardSubscriptionConfirmationMessage.replace(
          "%NUMBER%",
          subscriberCount.toString()
        )}
        title={copyText.deleteDashboardSubscriptionConfirmationTitle}
        variant="danger"
        onCancel={() => mergeState({ action: Action.UPDATE })}
        onConfirm={handleDelete}
      />
    );
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={true}
      showCloseButton
      width={550}
      onClose={props.onClose}
    >
      <Modal.Header>
        {subscriptions.length > 0 ? (
          <Text appearance="h4">
            {copyText.modalTitleEditSubscriptionToDashboard}
          </Text>
        ) : (
          <Text appearance="h4">{copyText.modalTitleSubscribeToDashboard}</Text>
        )}
      </Modal.Header>
      <Modal.Body>
        {state.action === null && (
          <DashboardSubscriptionList
            isLoading={isLoading}
            subscriptions={subscriptions}
            users={props.users}
            handleRowClicked={(id) => handleSubscriptionClicked(id)}
          />
        )}

        {renderForm()}
      </Modal.Body>
      <Modal.Footer>
        {state.action !== null ? (
          <Flex justifyContent="space-between" width={"100%"}>
            {subscriptions.length > 0 && state.action === Action.UPDATE ? (
              <Button
                secondary
                width={100}
                onClick={() => mergeState({ action: Action.DELETE })}
              >
                {copyText.actionDelete}
              </Button>
            ) : null}

            <Flex justifyContent="flex-end" width={"100%"}>
              {subscriptions.length > 0 ? (
                <Button
                  secondary
                  width={100}
                  onClick={() => setState({ ...initialState, action: null })}
                >
                  {copyText.backButtonLabel}
                </Button>
              ) : null}
              <Button
                disabled={!canSubmit()}
                marginLeft={theme.space_md}
                primary
                width={100}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <LoadingSpinner />
                ) : state.action === Action.UPDATE ? (
                  copyText.submitButtonLabel
                ) : (
                  copyText.dashboardSubscriptionSubscribeButtonLabel
                )}
              </Button>
            </Flex>
          </Flex>
        ) : null}

        {state.action === null ? (
          <Button
            primary
            onClick={() =>
              mergeState({ ...initialState, action: Action.CREATE })
            }
          >
            {copyText.dashboardSubscriptionModalCreateButtonLabel}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
