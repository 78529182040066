import { useTheme } from "@emotion/react";
import {
  faCancel,
  faEdit,
  faPencil,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { ReportScope, ReportType } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo, useState } from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useRefFn from "../../../hooks/useRefFn";
import Checkbox from "../../../ui-lib/components/Checkbox";
import TableTags from "../../../ui-lib/components/TableTags";
import IconStarOutline from "../../../ui-lib/icons/IconStarOutline";
import copyText from "../copyText";
import { getScopeText } from "../utils";

interface Report {
  id: string;
  createdAt: string;
  createdByEmail: string | null;
  updatedAt: string | null;
  updatedByEmail: string | null;
  favoritedUserIDs: string[];
  isDistributed?: boolean | null;
  name: string;
  scope: string;
  tags: string[];
  type: ReportType;
}

interface Props {
  existingTags: string[];
  highlightedTags: string[];
  isInternal?: boolean;
  isLoading: boolean;
  reports: Report[];
  selectedReportIDs: string[];
  showMultiSelect: boolean;
  onInteraction: (interaction: ReportTable.Interaction) => void;
}

type TableData = {
  id: string;
  createdAt: string;
  isFavorited: boolean;
  isGlobal: boolean;
  isMultiSelected: boolean;
  isDistributed?: boolean | null;
  name: string;
  owner: string;
  selectedCount: number;
  shared: string;
  tags: string[];
  tagsString: string | null;
  timeLastModified: string;
  type: ReportType;
};

const defaultSortBy = {
  id: "name",
  desc: false,
};

const columnHelper = createColumnHelper<TableData>();

export function ReportTable(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [filteredFavorites, setFilteredFavorites] = useState<boolean>(false);

  function handleClickRow(reportID: string): void {
    props.onInteraction({
      type: ReportTable.INTERACTION_ROW_CLICKED,
      reportID,
    });
  }

  function handleFilterFavorites() {
    props.onInteraction({
      type: ReportTable.INTERACTION_FILTER_FAVORITES_CLICKED,
    });
  }

  const handleClickFavorite = useRefFn((reportID: string) => {
    props.onInteraction({
      type: ReportTable.INTERACTION_FAVORITE_REPORT_CLICKED,
      reportID,
    });
  });

  const handleClickTag = useRefFn((tag: string) => {
    props.onInteraction({
      type: ReportTable.INTERACTION_TAG_CLICKED,
      tag,
    });
  });

  const handleMultiSelectAll = useRefFn(() => {
    const selectedIDSet = new Set(props.selectedReportIDs);

    const visableIDs = props.reports
      .filter((report) => report.scope !== ReportScope.GLOBAL)
      .map((reports) => reports.id);

    const visableSelectedIDs = visableIDs.filter((id) => selectedIDSet.has(id));

    let selectedIDs: string[];
    if (visableSelectedIDs.length > 0) {
      selectedIDs = [];
    } else {
      selectedIDs = visableIDs;
    }

    props.onInteraction({
      type: ReportTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      reportIDs: selectedIDs,
    });
  });

  const handleMultiSelectOne = useRefFn((id: string, checked: boolean) => {
    const selectedIDSet = new Set(props.selectedReportIDs);

    if (checked) {
      selectedIDSet.add(id);
    } else {
      selectedIDSet.delete(id);
    }

    props.onInteraction({
      type: ReportTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED,
      reportIDs: props.reports
        .filter((report) => selectedIDSet.has(report.id))
        .map((report) => report.id),
    });
  });

  const handleSelectSingle = useRefFn((id: string) => {
    props.onInteraction({
      type: ReportTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED,
      reportID: id,
    });
  });

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "favorite",
        cell: ({ row }) => (
          <Flex alignItems="center" cursor={"pointer"} justifyContent="center">
            {row.original.isFavorited ? (
              <Icon
                backgroundColorOnHover={theme.report_favorite_color_hover}
                clickable
                color={theme.report_favorite_color}
                icon={faStar}
                size="lg"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickFavorite(row.original.id);
                }}
              />
            ) : (
              <IconStarOutline
                backgroundColorOnHover={theme.report_favorite_color}
                clickable
                color={theme.table_header_background_color}
                size="20px"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickFavorite(row.original.id);
                }}
              />
            )}
          </Flex>
        ),
        header: () => (
          <Icon
            backgroundColorOnHover={theme.report_favorite_color}
            clickable
            color={filteredFavorites ? theme.report_favorite_color : undefined}
            icon={faStar}
            size="lg"
            onClick={() => {
              setFilteredFavorites(!filteredFavorites);
              handleFilterFavorites();
            }}
          />
        ),
        meta: { align: "center", disableClick: true },
        size: 40,
      }),
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 300,
      }),
      columnHelper.accessor("owner", {
        header: copyText.tableHeaderOwner,
        size: 220,
      }),
      columnHelper.accessor("createdAt", {
        meta: { align: "center" },
        cell: (cell) => formatDate(new Date(cell.getValue()), "MM/dd/yyyy"),
        header: copyText.tableHeaderCreatedAt,
        size: 120,
      }),
      columnHelper.accessor("timeLastModified", {
        meta: { align: "center" },
        cell: (cell) => formatDate(new Date(cell.getValue()), "MM/dd/yyyy"),
        header: copyText.tableHeaderTimeLastModified,
        size: 120,
      }),
      columnHelper.accessor("shared", {
        meta: { align: "center" },
        header: copyText.tableHeaderVisibility,
        size: 100,
      }),
      ...(props.isInternal
        ? [
            columnHelper.accessor("isDistributed", {
              meta: { align: "center" },
              header: copyText.tableHeaderIsDistributed,
              size: 100,
            }),
          ]
        : []),
      columnHelper.accessor("tags", {
        cell: (cell) => {
          const value = cell.getValue();
          if (value.length === 0) return "--";

          return (
            <TableTags
              highlightedTags={props.highlightedTags}
              tags={value}
              onClickTag={handleClickTag}
            />
          );
        },
        header: copyText.tableHeaderTags,
        size: 300,
        sortingFn: (a, b) => {
          const aTags = a.original.tagsString;
          const bTags = b.original.tagsString;

          if (aTags === null || bTags === null) {
            return aTags === bTags ? 0 : bTags === null ? -1 : 1;
          }

          return aTags < bTags ? -1 : aTags > bTags ? 1 : 0;
        },
      }),
      columnHelper.display({
        id: "editButton",
        cell: ({ row }) => {
          let disabled = false;

          switch (row.original.type) {
            case ReportType.DEFAULT:
              disabled = !gatekeeper.canUpdateReports(row.original.id);
              break;
            case ReportType.SYSTEM_COPY:
              disabled = true;
              break;
            case ReportType.SYSTEM:
              disabled = !gatekeeper.canUpdateSystemReport;
          }

          return props.showMultiSelect ? (
            <Box onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={row.original.isMultiSelected}
                disabled={disabled}
                onChange={(e) => {
                  e.preventDefault();

                  if (disabled) return;

                  handleMultiSelectOne(
                    row.original.id,
                    e.currentTarget.checked
                  );
                }}
              />
            </Box>
          ) : (
            <Button
              disabled={disabled}
              iconStart={<Icon icon={faPencil} />}
              secondary
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectSingle(row.original.id);
              }}
            />
          );
        },
        header: (headerContext) => {
          const rows = headerContext.table.getRowModel().rows;

          const checkedCount = rows.reduce(
            (count, row) => (row.original.isMultiSelected ? count + 1 : count),
            0
          );

          return props.showMultiSelect ? (
            <Checkbox
              checked={checkedCount > 0 && checkedCount === rows.length}
              dashed={checkedCount > 0}
              onChange={handleMultiSelectAll}
            />
          ) : (
            <Flex
              alignItems="center"
              height={theme.fontSize_base}
              justifyContent="space-between"
              width="100%"
            >
              <Tooltip content={copyText.tableHeaderEditTags}>
                <Button
                  iconStart={<Icon icon={faEdit} />}
                  size="tiny"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onInteraction({
                      type: ReportTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                      display: !props.showMultiSelect,
                    });
                  }}
                />
              </Tooltip>
            </Flex>
          );
        },
        meta: { disableClick: true },
        size: 50,
      }),
      ...(props.showMultiSelect
        ? [
            columnHelper.display({
              id: "multiSelectToggle",
              header: function renderMultiEditButton(header) {
                const rows = header.table.getRowModel().rows;

                const selectedCount = rows[0]?.original.selectedCount ?? 0;

                if (selectedCount > 0) {
                  return (
                    <Flex
                      alignItems="center"
                      height={theme.fontSize_base}
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Tooltip
                        content={copyText.reportListEditNTagsToolTipMessage.replace(
                          "%NUMBER%",
                          selectedCount.toString()
                        )}
                      >
                        <Button
                          iconStart={<Icon icon={faEdit} />}
                          primary
                          size="tiny"
                          onClick={(e) => {
                            e.preventDefault();

                            props.onInteraction({
                              type: ReportTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED,
                            });
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  );
                }

                return (
                  <Flex
                    alignItems="center"
                    height={theme.fontSize_base}
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Tooltip
                      content={copyText.reportListCancelEditToolTipMessage}
                    >
                      <Button
                        iconStart={<Icon icon={faCancel} />}
                        secondary
                        size="tiny"
                        onClick={(e) => {
                          e.preventDefault();

                          props.onInteraction({
                            type: ReportTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED,
                            display: false,
                          });
                        }}
                      />
                    </Tooltip>
                  </Flex>
                );
              },
              size: 35,
            }),
          ]
        : []),
      ...(props.isInternal
        ? [
            columnHelper.display({
              id: "internalButton",
              cell: ({ row }) => (
                <Button
                  disabled={!gatekeeper.canUpdateSystemReport}
                  secondary
                  size="small"
                  width={130}
                  onClick={(event) => {
                    event.stopPropagation();

                    if (row.original.isDistributed) {
                      props.onInteraction({
                        type: ReportTable.INTERACTION_REMOVE_REPORT_BUTTON_CLICKED,
                        reportID: row.original.id,
                      });
                    } else {
                      props.onInteraction({
                        type: ReportTable.INTERACTION_DISTRIBUTE_REPORT_BUTTON_CLICKED,
                        reportID: row.original.id,
                      });
                    }
                  }}
                >
                  {row.original.isDistributed
                    ? copyText.actionMenuItemRemoveSystemReport
                    : copyText.actionMenuItemDistributeSystemReport}
                </Button>
              ),
              meta: { align: "center" },
              size: 140,
            }),
          ]
        : []),
    ],
    [
      filteredFavorites,
      props.highlightedTags,
      props.selectedReportIDs,
      props.showMultiSelect,
      props.reports,
    ]
  );

  const tableData = useMemo(
    () =>
      props.reports.map((report): TableData => {
        const sortedTags = [...report.tags].sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
          }
          if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        return {
          id: report.id,
          createdAt: report.createdAt,
          isFavorited: report.favoritedUserIDs.includes(authenticatedUser.id),
          isGlobal: report.scope === ReportScope.GLOBAL,
          isMultiSelected: props.selectedReportIDs.includes(report.id),
          isDistributed: report.isDistributed,
          name: report.name,
          owner: report.createdByEmail ?? "--",
          selectedCount: props.selectedReportIDs.length,
          shared: getScopeText(report.scope),
          tags: sortedTags,
          tagsString: sortedTags.length
            ? sortedTags.join("-").toLowerCase()
            : null,
          timeLastModified: report.updatedAt ?? report.createdAt,
          type: report.type,
        };
      }),
    [props.reports, props.selectedReportIDs]
  );

  return (
    <Table
      clickableRows
      columns={columns}
      rowHeight="70px"
      data={tableData}
      initialState={{ pagination: { pageSize: 10 }, sorting: [defaultSortBy] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onClick={(row) => handleClickRow(row.id)}
    />
  );
}

ReportTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED =
  `ReportList.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED` as const;
ReportTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED =
  `ReportList.INTERACTION_EDIT_MULTI_SELECT_CLICKED` as const;
ReportTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED =
  `ReportList.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED` as const;
ReportTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED =
  `ReportList.INTERACTION_EDIT_SINGLE_TAGS_CLICKED` as const;
ReportTable.INTERACTION_FAVORITE_REPORT_CLICKED =
  `ReportList.INTERACTION_FAVORITE_REPORT_CLICKED` as const;
ReportTable.INTERACTION_FILTER_FAVORITES_CLICKED =
  `ReportList.INTERACTION_FILTER_FAVORITES_CLICKED` as const;
ReportTable.INTERACTION_DISTRIBUTE_REPORT_BUTTON_CLICKED =
  `ReportList.INTERACTION_DISTRIBUTE_REPORT_BUTTON_CLICKED` as const;
ReportTable.INTERACTION_ROW_CLICKED =
  `ReportList.INTERACTION_ROW_CLICKED` as const;
ReportTable.INTERACTION_TAG_CLICKED =
  `ReportList.INTERACTION_TAG_CLICKED` as const;
ReportTable.INTERACTION_REMOVE_REPORT_BUTTON_CLICKED =
  `ReportList.INTERACTION_REMOVE_REPORT_BUTTON_CLICKED` as const;

type InteractionDisplayMultiSelectClicked = {
  type: typeof ReportTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED;
  display: boolean;
};

type InteractionEditMultipleTagsClicked = {
  type: typeof ReportTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED;
  reportIDs: string[];
};

type InteractionEditMultiSelectClicked = {
  type: typeof ReportTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED;
};

type InteractionEditSingleTagsClicked = {
  type: typeof ReportTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED;
  reportID: string;
};

type InteractionFavoriteReportClicked = {
  type: typeof ReportTable.INTERACTION_FAVORITE_REPORT_CLICKED;
  reportID: string;
};

type InteractionFilterFavoritesClicked = {
  type: typeof ReportTable.INTERACTION_FILTER_FAVORITES_CLICKED;
};

type InteractionDistributeReportButtonClicked = {
  type: typeof ReportTable.INTERACTION_DISTRIBUTE_REPORT_BUTTON_CLICKED;
  reportID: string;
};

type InteractionRowClicked = {
  type: typeof ReportTable.INTERACTION_ROW_CLICKED;
  reportID: string;
};

type InteractionTagClicked = {
  type: typeof ReportTable.INTERACTION_TAG_CLICKED;
  tag: string;
};

type InteractionRemoveReportButtonClicked = {
  type: typeof ReportTable.INTERACTION_REMOVE_REPORT_BUTTON_CLICKED;
  reportID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportTable {
  export type Interaction =
    | InteractionDisplayMultiSelectClicked
    | InteractionEditMultipleTagsClicked
    | InteractionEditMultiSelectClicked
    | InteractionEditSingleTagsClicked
    | InteractionFavoriteReportClicked
    | InteractionFilterFavoritesClicked
    | InteractionDistributeReportButtonClicked
    | InteractionRowClicked
    | InteractionTagClicked
    | InteractionRemoveReportButtonClicked;
}

export default ReportTable;
