import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAwsOpenSearch(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" version="1.1">
        <defs>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="100%"
            y2="0%"
            id="linearGradient-1"
          >
            <stop stopColor="#2E27AD" offset="0%" />
            <stop stopColor="#527FFF" offset="100%" />
          </linearGradient>
        </defs>
        <g
          id="Icon-Architecture/64/Arch_Amazon-OpenSearch-Service_64"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Icon-Architecture-BG/64/Analytics"
            fill="url(#linearGradient-1)"
          >
            <rect id="Rectangle" x="0" y="0" width="80" height="80" />
          </g>
          <path
            d="M44.26,51.33 C39.843,51.33 36.25,47.737 36.25,43.321 C36.25,38.904 39.843,35.311 44.26,35.311 C48.677,35.311 52.27,38.904 52.27,43.321 C52.27,47.737 48.677,51.33 44.26,51.33 L44.26,51.33 Z M44.26,33.311 C38.74,33.311 34.25,37.801 34.25,43.321 C34.25,48.84 38.74,53.33 44.26,53.33 C49.78,53.33 54.27,48.84 54.27,43.321 C54.27,37.801 49.78,33.311 44.26,33.311 L44.26,33.311 Z M64.548,61.346 C63.897,62.067 62.777,62.127 62.052,61.477 L53.712,53.954 C54.593,53.17 55.384,52.29 56.049,51.313 L64.416,58.851 C65.139,59.503 65.197,60.624 64.548,61.346 L64.548,61.346 Z M32.011,43.321 C32.011,36.566 37.506,31.071 44.26,31.071 C51.014,31.071 56.509,36.566 56.509,43.321 C56.509,50.075 51.014,55.57 44.26,55.57 C37.506,55.57 32.011,50.075 32.011,43.321 L32.011,43.321 Z M57.063,49.535 C57.98,47.654 58.509,45.55 58.509,43.321 C58.509,35.463 52.117,29.071 44.26,29.071 C36.403,29.071 30.011,35.463 30.011,43.321 C30.011,51.178 36.403,57.57 44.26,57.57 C47.159,57.57 49.856,56.695 52.109,55.202 L60.714,62.964 C61.432,63.609 62.333,63.927 63.232,63.9260024 C64.263,63.9260024 65.29,63.508 66.034,62.685 C67.42,61.143 67.296,58.757 65.756,57.366 L57.063,49.535 Z M67,20 L67,54 L65,54 L65,21 L57,21 L57,32 L55,32 L55,20 C55,19.448 55.448,19 56,19 L66,19 C66.553,19 67,19.448 67,20 L67,20 Z M51,61 L53,61 L53,66 C53,66.553 52.553,67 52,67 L42,67 C41.447,67 41,66.553 41,66 L41,60 L43,60 L43,65 L51,65 L51,61 Z M43,26 L41,26 L41,14 C41,13.448 41.447,13 42,13 L52,13 C52.553,13 53,13.448 53,14 L53,28 L51,28 L51,15 L43,15 L43,26 Z M37,59 L39,59 L39,66 C39,66.553 38.553,67 38,67 L28,67 C27.448,67 27,66.553 27,66 L27,51 L29,51 L29,65 L37,65 L37,59 Z M29,36 L27,36 L27,23 C27,22.448 27.448,22 28,22 L38,22 C38.553,22 39,22.448 39,23 L39,27 L37,27 L37,24 L29,24 L29,36 Z M15,65 L23,65 L23,31 L15,31 L15,65 Z M24,29 L14,29 C13.448,29 13,29.448 13,30 L13,66 C13,66.553 13.448,67 14,67 L24,67 C24.553,67 25,66.553 25,66 L25,30 C25,29.448 24.553,29 24,29 L24,29 Z"
            id="Amazon-OpenSearch-Service_Icon_64_Squid"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    </Icon>
  );
}
