import { DistributeSystemDashboardCommandResult } from "@ternary/api-lib/core/commands/DistributeSystemDashboardCommand";
import { DistributeSystemReportCommandResult } from "@ternary/api-lib/core/commands/DistributeSystemReportCommand";
import { RemoveSystemDashboardCommandResult } from "@ternary/api-lib/core/commands/RemoveSystemDashboardCommand";
import { RemoveSystemReportCommandResult } from "@ternary/api-lib/core/commands/RemoveSystemReportCommand";
import { EventReporter } from "@ternary/api-lib/telemetry";
import HTTPRequestSender from "../HTTPRequestSender";

interface Config {
  eventReporter: EventReporter;
  sender: HTTPRequestSender;
}

export class InternalApiClient {
  private readonly _eventReporter: EventReporter;
  private readonly _sender: HTTPRequestSender;

  constructor({ sender, eventReporter }: Config) {
    this._sender = sender;
    this._eventReporter = eventReporter;
  }

  public async distributeSystemDashboard(dashboardID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/dashboards/distribute/${dashboardID}`,
        { method: "POST" }
      )) as DistributeSystemDashboardCommandResult;

      return result.dashboardID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new Error("ERROR_DISTRIBUTING_SYSTEM_DASHBOARD");
    }
  }

  public async distributeSystemReport(reportID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/reports/distribute/${reportID}`,
        { method: "POST" }
      )) as DistributeSystemReportCommandResult;

      return result.reportID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new Error("ERROR_DISTRIBUTING_SYSTEM_REPORT");
    }
  }

  public async removeSystemDashboard(dashboardID: string): Promise<string> {
    try {
      const result = (await this._sender.send(
        `/dashboards/remove/${dashboardID}`,
        { method: "POST" }
      )) as RemoveSystemDashboardCommandResult;

      return result.dashboardID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new Error("ERROR_REMOVING_SYSTEM_DASHBOARD");
    }
  }

  public async removeSystemReport(reportID: string): Promise<string> {
    try {
      const result = (await this._sender.send(`/reports/remove/${reportID}`, {
        method: "POST",
      })) as RemoveSystemReportCommandResult;

      return result.reportID;
    } catch (error) {
      this._eventReporter.reportError(error);

      throw new Error("ERROR_REMOVING_SYSTEM_REPORT");
    }
  }
}
