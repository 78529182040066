import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import useRefFn from "@ternary/api-lib/ui-lib/hooks/useRefFn";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AzureAKSClusterGroupFilters,
  AzureAKSClustersGroupEntity,
} from "../types";

type Props = {
  clusterGroups: AzureAKSClustersGroupEntity[];
  isLoadingClusterGroups: boolean;
  onInteraction: (
    interaction: AzureKubernetesClusterGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureAKSClustersGroupEntity>();

function AzureKubernetesClusterGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "viewInstances",
        cell: ({ row }) => (
          <Tooltip content={copyText.azureKubernetesTableViewClusters}>
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        size: 50,
      }),
      ...(
        [
          ["cloudId", 120],
          ["clusterName", 120],
          ["resourceGroupName", 160],
          ["subscriptionName", 160],
        ] as const
      ).map(([dimensionKey, size]) => {
        const headerKey: keyof typeof copyText = `azureKubernetesTableHeader_${dimensionKey}`;

        return columnHelper.accessor(dimensionKey, {
          cell: ({ getValue }) => (
            <Text
              color={theme.primary_color_text}
              truncate
              cursor="pointer"
              onClick={() =>
                onInteraction({
                  type: AzureKubernetesClusterGroupTable.INTERACTION_FILTER_CLICKED,
                  filterKey: dimensionKey,
                  filterValue: getValue(),
                })
              }
            >
              <Tooltip content={getValue()}>
                {getValue() || copyText.azureKubernetesTableNull}
              </Tooltip>
            </Text>
          ),
          header: copyText[headerKey],
          meta: { align: "center" },
          size,
        });
      }),
      columnHelper.accessor("cost", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureKubernetesTableHeader_cost,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("instanceHours", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureKubernetesTableHeader_instanceHours,
        meta: { align: "center" },
      }),
      columnHelper.accessor("estimatedCpuCoreHoursAvailable", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header:
          copyText.azureKubernetesTableHeader_estimatedCpuCoreHoursAvailable,
        meta: { align: "center" },
        sortDescFirst: true,
      }),
      columnHelper.accessor("upperBoundCpuCoreHoursUsed", {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureKubernetesTableHeader_upperBoundCpuCoreHoursUsed,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("estimatedRssByteHoursAvailable", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header:
          copyText.azureKubernetesTableHeader_estimatedRssByteHoursAvailable,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
      columnHelper.accessor("upperBoundRssByteHoursUsed", {
        cell: ({ getValue }) => prettyBytes(getValue(), { binary: true }),
        header: copyText.azureKubernetesTableHeader_upperBoundRssByteHoursUsed,
        meta: { align: "center" },
        size: 140,
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Box maxWidth={"100%"} overflowX="auto">
      <Table
        columns={columns}
        compact
        data={props.clusterGroups}
        initialState={{ sorting: [sortRule] }}
        isLoading={props.isLoadingClusterGroups}
        showPagination
        sortable
        onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
      />
    </Box>
  );
}

function getSelectedGroupJSON(datum: AzureAKSClustersGroupEntity) {
  try {
    return JSON.stringify({
      cloudId: datum.cloudId,
      clusterName: datum.clusterName,
      resourceGroupName: datum.resourceGroupName,
      subscriptionName: datum.subscriptionName,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return "";
  }
}

AzureKubernetesClusterGroupTable.INTERACTION_FILTER_CLICKED =
  `AzureKubernetesClusterGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AzureKubernetesClusterGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AzureAKSClusterGroupFilters;
  filterValue: AzureAKSClusterGroupFilters[keyof AzureAKSClusterGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureKubernetesClusterGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AzureKubernetesClusterGroupTable;
