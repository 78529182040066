import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type DashboardEntity = Awaited<
  ReturnType<CoreAPIClient["getDashboardsByTenantID"]>
>[0];

export default function useGetDashboardsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<DashboardEntity[], CoreAPIClientError>
): UseQueryResult<DashboardEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const canListDashboards =
    tenantID === SYSTEM_TENANT_ID
      ? gatekeeper.canListSystemDashboards
      : gatekeeper.canListDashboards;

  const enabled = options?.enabled
    ? options.enabled && canListDashboards
    : canListDashboards;

  return useQuery({
    queryKey: ["dashboards", tenantID],
    queryFn: () => client.getDashboardsByTenantID(tenantID),
    enabled,
    meta: { errorMessage: copyText.errorLoadingDashboardsMessage },
    ...options,
  });
}
