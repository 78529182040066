import { Action, ActivityTracker } from "@ternary/api-lib/telemetry";
import EventReporter from "./EventReporter";

/*
    TODO: pending learnings on telemetry side
        - track activities NOT done on a page
        - track performance (tie optional durations to actions)
*/

export const ACTIONPROPERTIES = {
  durationSeconds: "duration_seconds",
  meterName: "meter_name",
  pagePath: "page_path",
  responseLength: "response_length",
};

export const USERACTIONS = {
  misclickMeter: "misclick_meter",
  pageEnter: "page_enter",
  pageLeave: "page_leave",
};

interface Config {
  enableMixpanel: boolean;
  enableSentry: boolean;
  eventReporter: EventReporter;
}

/*
    The activity tracker can be used anywhere in the app to "deal with" things users are doing.
    As this evolves, the purpose can include anything activity-related, including:
      - Telemetry regarding page visits / duration
      - Telemetry regarding specific click events
      - Telemetry regarding surprising "lack" of one more activities
      - Track metadata regarding these activities such as duration, load time and frequency
      - A shared store of recent activity (to support undo behaviors, for example)
      - Dynamic user flows that vary based on recent activity
*/

export default class ActivityTrackerImpl implements ActivityTracker {
  actions: string[];
  pagePath: string;
  timeStartedOnPage: Date | null;

  public readonly _enableMixpanel: boolean;
  public readonly _enableSentry: boolean;
  public readonly _eventReporter: EventReporter;

  constructor({ enableMixpanel, eventReporter }: Config) {
    this._enableMixpanel = enableMixpanel;
    this._eventReporter = eventReporter;
    this.pagePath = window.location.pathname;
  }

  // Set a page when a user arrives on it, and mark the time
  public setPage(pagePath: string): void {
    this.pagePath = pagePath;
    this.timeStartedOnPage = new Date();
  }

  public captureAction(
    action: Action,
    properties?: Record<string, unknown>
  ): void {
    if (!this._enableMixpanel) return;

    this._eventReporter.reportEvent(action, properties);
  }

  public captureMessage(message: string): void {
    if (!this._enableSentry) return;

    this._eventReporter.reportMessage(message);
  }

  // To be fired when navigating away from a specific page, regardless of destination
  onLeavePage(): void {
    if (!this._enableMixpanel) return;
    if (!this.timeStartedOnPage) return;

    const now = new Date();
    const secondsOnPage =
      (now.getTime() - this.timeStartedOnPage.getTime()) / 1000;

    this._eventReporter.reportEvent(USERACTIONS.pageLeave, {
      [ACTIONPROPERTIES.pagePath]: this.pagePath,
      [ACTIONPROPERTIES.durationSeconds]: secondsOnPage,
    });
  }
}
