import {
  CloudProviderType,
  CustomMetricDataType,
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import {
  AWSIntegrationEntity,
  AzureIntegrationEntity,
  GCPIntegrationEntity,
  SnowflakeIntegrationEntity,
} from "@ternary/api-lib/core/types";
import UError from "unilib-error";

export const ERROR_INVALID_PROPERTY_TYPE = "INVALID_PROPERTY_TYPE";
const ERROR_INVALID_PROPERTY_TYPE_ARRAY = "ERROR_INVALID_PROPERTY_TYPE_ARRAY";
const ERROR_PROPERTY_DOES_NOT_EXIST = "PROPERTY_DOES_NOT_EXIST";

interface TypeMap {
  boolean: boolean;
  number: number;
  object: Record<string, unknown>;
  string: string;
}

export function hasOwnProperty<
  X extends Record<string, unknown>,
  Y extends string,
  T extends keyof TypeMap,
>(obj: X, prop: Y, type: T): asserts obj is X & Record<Y, TypeMap[T]> {
  if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
    throw new Error(ERROR_PROPERTY_DOES_NOT_EXIST);
  }

  if (typeof obj[prop] !== type) {
    throw new Error(ERROR_INVALID_PROPERTY_TYPE);
  }
}

export function isAWSIntegration(integration: {
  providerType: CloudProviderType;
}): integration is AWSIntegrationEntity {
  return integration.providerType === CloudProviderType.AWS;
}

export function isAzureIntegration(integration: {
  providerType: CloudProviderType;
}): integration is AzureIntegrationEntity {
  return integration.providerType === CloudProviderType.AZURE;
}

export function isCustomMetricDataType(
  input: string
): input is CustomMetricDataType {
  return Object.keys(CustomMetricDataType).some(
    (key) => CustomMetricDataType[key] === input
  );
}

export function isDataSource(input: string): input is DataSource {
  return Object.keys(DataSource).some((key) => DataSource[key] === input);
}

export function isGCPIntegration(integration: {
  providerType: CloudProviderType;
}): integration is GCPIntegrationEntity {
  return integration.providerType === CloudProviderType.GCP;
}

export function isSnowflakeIntegration(integration: {
  providerType: CloudProviderType;
}): integration is SnowflakeIntegrationEntity {
  return integration.providerType === CloudProviderType.SNOWFLAKE;
}

export function isOperator(input: string): input is Operator {
  return Object.keys(Operator).some((key) => Operator[key] === input);
}

export function validateObjectTypes<I>(
  obj: Record<string, unknown>,
  typeMap: { [key: string]: keyof TypeMap | "array" }
): asserts obj is Record<string, unknown> & I {
  for (const prop in typeMap) {
    if (typeMap[prop] === "array") {
      if (!Array.isArray(obj[prop])) {
        if (obj[prop] === null) {
          obj[prop] = [];
        } else {
          throw new Error(ERROR_INVALID_PROPERTY_TYPE_ARRAY);
        }
      }
      continue;
    }

    if (!Object.prototype.hasOwnProperty.call(obj, prop)) {
      throw new UError(ERROR_PROPERTY_DOES_NOT_EXIST, {
        context: { property: prop },
      });
    }

    if (typeof obj[prop] !== typeMap[prop]) {
      throw new UError(ERROR_INVALID_PROPERTY_TYPE, {
        context: { property: prop },
      });
    }
  }
}

export function isTimeGranularity(input: string): input is TimeGranularity {
  return Object.keys(TimeGranularity).some(
    (key) => TimeGranularity[key] === input
  );
}
