import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import getBudgetData from "@ternary/api-lib/analytics/getBudgetData";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { BudgetEntity, LabelMapsEntity } from "@ternary/api-lib/core/types";
import UError from "unilib-error";
import { BudgetSpendEntity } from "../../../api/analytics/types";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

interface Params {
  budget: BudgetEntity | undefined;
  dateRange: Date[] | string;
  granularity: TimeGranularity;
}

export default function useGetBudgetSpendByGranularity(
  params: Params,
  options?: UseQueryOptions<BudgetSpendEntity[], UError>
): UseQueryResult<BudgetSpendEntity[], UError> {
  const queryClient = useQueryClient();
  const client = useAnalyticsApiClient();

  const labelMaps = queryClient.getQueryData<LabelMapsEntity>(["labelMaps"]);

  if (!labelMaps) {
    throw new Error("ERROR_MAPS_UNDEFINED");
  }

  const labelMap = labelMaps[DataSource.BILLING];

  return useQuery({
    queryKey: ["budgetSpendSummary", params],
    queryFn: async () => {
      const budget = params.budget;

      if (!budget) return [];

      const result = await getBudgetData({
        ...params,
        budget,
        client,
        labelMap,
      });

      return result;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
