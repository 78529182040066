import { useMemo } from "react";
import { QuarterPattern } from "../types";
import { createFiscalYearsData, fiscalDateFns } from "./useFiscalYearsData";

type FiscalCalendar = {
  endDate: string;
  periods: Periods;
};

type Periods = {
  // ["M01-Q1-Y2022"]: "2022-01-31",
  // ["M02-Q1-Y2022"]: "2022-03-07",
  // ...

  [key: string]: string;
};

type UseUpdatedFiscalCalendarParams = {
  endDate: Date | null;
  fiscalYearNumber: string | null;
  prevCalendar: FiscalCalendar | null;
  quarterPattern: Omit<QuarterPattern, QuarterPattern.OTHER> | null;
  startDate: Date | null;
};

export default function useUpdatedFiscalCalendar({
  endDate,
  fiscalYearNumber,
  prevCalendar,
  quarterPattern,
  startDate,
}: UseUpdatedFiscalCalendarParams) {
  return useMemo(() => {
    if (!fiscalYearNumber || !startDate || !endDate || !quarterPattern) {
      return prevCalendar;
    }

    return createUpdatedFiscalCalendar({
      endDate: fiscalDateFns.dateToString(endDate),
      fiscalYearNumber,
      prevCalendar,
      quarterPattern,
      startDate: fiscalDateFns.dateToString(startDate),
    });
  }, [prevCalendar, fiscalYearNumber, startDate, endDate, quarterPattern]);
}

type CreateUpdatedFiscalCalendarParams = {
  endDate: string;
  fiscalYearNumber: string;
  prevCalendar: FiscalCalendar | null;
  quarterPattern: Omit<QuarterPattern, QuarterPattern.OTHER>;
  startDate: string;
};

export function createUpdatedFiscalCalendar({
  endDate,
  fiscalYearNumber,
  prevCalendar,
  quarterPattern,
  startDate,
}: CreateUpdatedFiscalCalendarParams) {
  const newPeriods = createYearPeriods({
    fiscalYearNumber,
    quarterPattern,
    startDate,
  });

  if (!prevCalendar) prevCalendar = { endDate, periods: {} };

  const updatedPeriods = { ...prevCalendar.periods, ...newPeriods };

  let replaceEndDate = true;

  if (
    Object.values(
      getPeriodsSubsetFromMatch(
        prevCalendar.periods,
        new RegExp(`M\\d{2}-Q\\d-Y(?!${fiscalYearNumber})\\d{4}`)
      )
    ).some(
      (otherYearDate) =>
        // if any dates from other years are after the given end date,
        // use the previous calendar's endDate
        fiscalDateFns.diffDays(endDate, otherYearDate) < 0
    )
  ) {
    replaceEndDate = false;
  }

  const newCalendar: FiscalCalendar = {
    endDate: replaceEndDate ? endDate : prevCalendar.endDate,
    periods: updatedPeriods,
  };

  return newCalendar;
}

type CreateFiscalCalendarWithoutYearParams = {
  prevCalendar: FiscalCalendar | null;
  fiscalYearNumber: string;
};

export function createFiscalCalendarWithoutYear({
  fiscalYearNumber,
  prevCalendar,
}: CreateFiscalCalendarWithoutYearParams): FiscalCalendar | null {
  if (!prevCalendar) return null;

  const fiscalYearsData = createFiscalYearsData({ calendar: prevCalendar });

  if (!fiscalYearsData.yearKeys.includes(fiscalYearNumber)) return prevCalendar;

  if (fiscalYearsData.yearKeys.length === 1) return null;

  const [newLastYear] = fiscalYearsData.yearKeys
    .filter((otherKey) => otherKey !== fiscalYearNumber)
    .slice(-1);

  const newEndDate = fiscalYearsData.yearMap[newLastYear].endDate;

  const newPeriods = getPeriodsSubsetFromMatch(
    prevCalendar.periods,
    new RegExp(`M\\d{2}-Q\\d-Y(?!${fiscalYearNumber})\\d{4}`)
  );

  return {
    endDate: newEndDate,
    periods: newPeriods,
  };
}

//
// Helpers
//

type CreateYearPeriodsParams = {
  fiscalYearNumber: string;
  quarterPattern: Omit<QuarterPattern, QuarterPattern.OTHER>;
  startDate: string;
};

function createYearPeriods({
  fiscalYearNumber,
  quarterPattern,
  startDate,
}: CreateYearPeriodsParams) {
  const quarterPatternArray = quarterPattern
    .split("-")
    .map((count) => Number(count));

  const periods: Periods = {};

  let monthStartDate = startDate;
  for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
    const quarterNumber = Math.floor(monthIndex / 3) + 1;
    const monthNumber = String(monthIndex + 1).padStart(2, "0");

    const periodKey = [
      `M${monthNumber}`,
      `Q${quarterNumber}`,
      `Y${fiscalYearNumber}`,
    ].join("-");

    periods[periodKey] = monthStartDate;

    // Determine next monthStartDate
    const weekLength = quarterPatternArray[monthIndex % 3];
    monthStartDate = fiscalDateFns.add(monthStartDate, { weeks: weekLength });
  }

  return periods;
}

function getPeriodsSubsetFromMatch(periods: Periods, exp: RegExp) {
  return Object.keys(periods).reduce((accum: Periods, periodKey) => {
    if (!periodKey.match(exp)) return accum;

    return { ...accum, [periodKey]: periods[periodKey] };
  }, {});
}
