import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import Switch from "../../../ui-lib/components/Switch";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

type NotificationSettings = {
  notifyAlerts: boolean;
  notifyBudgets: boolean;
  notifyRecommendations: boolean;
  notifyReportsDaily: boolean;
  notifyReportsWeekly: boolean;
  notifyReportsMonthly: boolean;
};

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  userNotifications: NotificationSettings;
  onInteraction: (interaction: UserNotificationPreferences.Interaction) => void;
}

type State = {
  isDisableAll: boolean;
  isReportsEnabled: boolean;
  enableAlertsInput: boolean;
  enableBudgetsInput: boolean;
  enableRecommendationsInput: boolean;
  enableReportsDailyInput: boolean;
  enableReportsWeeklyInput: boolean;
  enableReportsMonthlyInput: boolean;
};

function UserNotificationPreferences(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //
  const initialState: State = {
    isDisableAll: hasAllPreferencesDisabled(props.userNotifications),
    isReportsEnabled:
      props.userNotifications.notifyReportsDaily ||
      props.userNotifications.notifyReportsWeekly ||
      props.userNotifications.notifyReportsMonthly,
    enableAlertsInput: props.userNotifications.notifyAlerts,
    enableBudgetsInput: props.userNotifications.notifyBudgets,
    enableRecommendationsInput: props.userNotifications.notifyRecommendations,
    enableReportsDailyInput: props.userNotifications.notifyReportsDaily,
    enableReportsWeeklyInput: props.userNotifications.notifyReportsWeekly,
    enableReportsMonthlyInput: props.userNotifications.notifyReportsMonthly,
  };

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value: boolean = event.target.value === "true" ? true : false;

    mergeState({
      [`${name}Input`]: value,
    });
  }

  function handleDisableAll(checked: boolean): void {
    if (!checked) {
      setState({
        ...initialState,
        isDisableAll: false,
      });
      return;
    }

    const updatedState = { ...state };

    for (const key in updatedState) {
      if (key !== "isDisableAll") {
        updatedState[key] = false;
      } else {
        updatedState[key] = true;
      }
    }

    setState(updatedState);
  }

  function handleDisableReports(checked: boolean): void {
    if (!checked) {
      mergeState({
        isReportsEnabled: false,
        enableReportsDailyInput: false,
        enableReportsMonthlyInput: false,
        enableReportsWeeklyInput: false,
      });
    } else {
      mergeState({
        isReportsEnabled: checked,
        enableReportsDailyInput: props.userNotifications.notifyReportsDaily,
        enableReportsMonthlyInput: props.userNotifications.notifyReportsMonthly,
        enableReportsWeeklyInput: props.userNotifications.notifyReportsWeekly,
      });
    }
  }

  function hasChanged(): boolean {
    return !isEqual(props.userNotifications, {
      alerts: state.enableAlertsInput,
      budgets: state.enableBudgetsInput,
      recommendations: state.enableRecommendationsInput,
      report: {
        daily: state.enableReportsDailyInput,
        monthly: state.enableReportsMonthlyInput,
        weekly: state.enableReportsWeeklyInput,
      },
    });
  }

  function handleClose() {
    setState(initialState);
    props.onInteraction({
      type: UserNotificationPreferences.INTERACTION_CLOSE_BUTTON_CLICKED,
    });
  }

  function handleSubmit(): void {
    props.onInteraction({
      type: UserNotificationPreferences.INTERACTION_SUBMIT_BUTTON_CLICKED,
      notificationConfig: {
        notifyAlerts: state.enableAlertsInput,
        notifyBudgets: state.enableBudgetsInput,
        notifyRecommendations: state.enableRecommendationsInput,
        notifyReportsDaily: state.enableReportsDailyInput,
        notifyReportsMonthly: state.enableReportsMonthlyInput,
        notifyReportsWeekly: state.enableReportsWeeklyInput,
      },
    });
  }

  const disable = state.isDisableAll || !gatekeeper.canGrantUsersTenantAccess;

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      minWidth={500}
      showCloseButton
      onClose={handleClose}
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.newUserNotificationsModalTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box
          marginBottom={theme.space_md}
          marginLeft={theme.space_sm}
          maxWidth={400}
          overflowWrap="break-word"
        >
          <Text>{copyText.newUserNotificationsModalDescription}</Text>
        </Box>

        <Flex direction="column" paddingLeft={theme.space_sm}>
          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_md}
            width={"90%"}
          >
            <Text fontSize={theme.fontSize_base} marginRight={theme.space_xl}>
              {copyText.newUserNotificationsDisableAllLabel}
            </Text>
            <Switch
              disabled={!gatekeeper.canGrantUsersTenantAccess}
              name="disableAll"
              checked={state.isDisableAll}
              onChange={(checked) => handleDisableAll(checked)}
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_md}
            width={"90%"}
          >
            <Text fontSize={theme.fontSize_base} marginRight={theme.space_xl}>
              {copyText.newUserNotificationsAlertsLabel}
            </Text>
            <Switch
              disabled={disable}
              name="enableAlerts"
              checked={state.enableAlertsInput}
              onChange={(checked) =>
                handleChange({
                  target: {
                    name: "enableAlerts",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_md}
            width={"90%"}
          >
            <Text fontSize={theme.fontSize_base} marginRight={theme.space_xl}>
              {copyText.newUserNotificationBudgetsLabel}
            </Text>
            <Switch
              disabled={disable}
              name="enableBudgets"
              checked={state.enableBudgetsInput}
              onChange={(checked) =>
                handleChange({
                  target: {
                    name: "enableBudgets",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </Flex>

          <Flex
            justifyContent="space-between"
            marginBottom={theme.space_md}
            width={"90%"}
          >
            <Text fontSize={theme.fontSize_base}>
              {copyText.newUserNotificationsRecommendationsLabel}
            </Text>
            <Switch
              disabled={disable}
              name="enableRecommendations"
              checked={state.enableRecommendationsInput}
              onChange={(checked) =>
                handleChange({
                  target: {
                    name: "enableRecommendations",
                    value: String(checked),
                  },
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </Flex>

          {/* Reports */}

          <Flex direction="column" overflow="hidden">
            <Flex
              justifyContent="space-between"
              marginBottom={theme.space_md}
              width={"90%"}
            >
              <Text fontSize={theme.fontSize_base} marginRight={theme.space_xl}>
                {copyText.newUserNotificationsReportsLabel}
              </Text>
              <Switch
                disabled={disable}
                name="enableReportsDaily"
                checked={state.isReportsEnabled}
                onChange={(checked) => handleDisableReports(checked)}
              />
            </Flex>

            <Box
              height={state.isReportsEnabled ? 120 : 0}
              transition={"height ease 0.5s"}
            >
              <Box
                borderRadius={theme.borderRadius_4}
                backgroundColor={theme.secondary_color_background}
                paddingLeft={theme.space_lg}
                padding={theme.space_xs}
                width={"92%"}
              >
                <Flex
                  justifyContent="space-between"
                  marginBottom={theme.space_xs}
                  marginTop={theme.space_xxs}
                >
                  <Text
                    fontSize={theme.fontSize_base}
                    marginRight={theme.space_xl}
                  >
                    {copyText.newUserNotificationsDailyLabel}
                  </Text>
                  <Switch
                    disabled={disable}
                    name="enableReportsDaily"
                    checked={state.enableReportsDailyInput}
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: "enableReportsDaily",
                          value: String(checked),
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                </Flex>
                <Flex
                  justifyContent="space-between"
                  marginBottom={theme.space_xs}
                >
                  <Text
                    fontSize={theme.fontSize_base}
                    marginRight={theme.space_xl}
                  >
                    {copyText.newUserNotificationsWeeklyLabel}
                  </Text>
                  <Switch
                    disabled={disable}
                    name="enableReportsWeekly"
                    checked={state.enableReportsWeeklyInput}
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: "enableReportsWeekly",
                          value: String(checked),
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                </Flex>
                <Flex
                  justifyContent="space-between"
                  marginBottom={theme.space_xs}
                >
                  <Text
                    fontSize={theme.fontSize_base}
                    marginRight={theme.space_xl}
                  >
                    {copyText.newUserNotificationsMonthlyLabel}
                  </Text>
                  <Switch
                    disabled={disable}
                    name="enableReportsMonthly"
                    checked={state.enableReportsMonthlyInput}
                    onChange={(checked) =>
                      handleChange({
                        target: {
                          name: "enableReportsMonthly",
                          value: String(checked),
                        },
                      } as ChangeEvent<HTMLInputElement>)
                    }
                  />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!hasChanged()} primary onClick={handleSubmit}>
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function hasAllPreferencesDisabled(settings: NotificationSettings): boolean {
  return (
    !settings.notifyAlerts &&
    !settings.notifyBudgets &&
    !settings.notifyRecommendations &&
    !settings.notifyReportsDaily &&
    !settings.notifyReportsWeekly &&
    !settings.notifyReportsMonthly
  );
}

UserNotificationPreferences.INTERACTION_CLOSE_BUTTON_CLICKED =
  `UserNotificationPreferences.INTERACTION_CLOSE_BUTTON_CLICKED` as const;

UserNotificationPreferences.INTERACTION_SUBMIT_BUTTON_CLICKED =
  `UserNotificationPreferences.INTERACTION_SUBMIT_BUTTON_CLICKED` as const;

interface InteractionCloseButtonClicked {
  type: typeof UserNotificationPreferences.INTERACTION_CLOSE_BUTTON_CLICKED;
}
interface InteractionSubmitButtonClicked {
  type: typeof UserNotificationPreferences.INTERACTION_SUBMIT_BUTTON_CLICKED;
  notificationConfig: NotificationSettings;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace UserNotificationPreferences {
  export type Interaction =
    | InteractionSubmitButtonClicked
    | InteractionCloseButtonClicked;
}

export default UserNotificationPreferences;
