import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSComputeInstanceStruct = {
  cpuUtilization: nullable(number()),
  diskReadBytes: nullable(number()),
  diskWriteBytes: nullable(number()),
  family: nullable(string()),
  familyType: nullable(string()),
  instanceId: nullable(string()),
  instanceType: nullable(string()),
  instanceUnits: nullable(number()),
  lineItemUsageAccountId: nullable(string()),
  networkInBytes: nullable(number()),
  networkOutBytes: nullable(number()),
  operatingSystem: nullable(string()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSComputeInstanceGroupStruct = {
  cpuUtilization: nullable(number()),
  diskReadBytes: nullable(number()),
  diskWriteBytes: nullable(number()),
  family: nullable(string()),
  familyType: nullable(string()),
  instanceIDCount: nullable(number()),
  instanceUnits: nullable(number()),
  lineItemUsageAccountId: nullable(string()),
  networkInBytes: nullable(number()),
  networkOutBytes: nullable(number()),
  operatingSystem: nullable(string()),
  productMemoryBytes: nullable(number()),
  productVCPU: nullable(number()),
  region: nullable(string()),
  totalCost: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const AWSComputeSpendSummaryStruct = {
  cost: nullable(number()),
};

export type AWSComputeInstance = NotNullable<
  Infer<typeof AWSComputeInstanceStruct>
>;

export type AWSComputeInstanceGroup = NotNullable<
  Infer<typeof AWSComputeInstanceGroupStruct>
>;

export type AWSComputeInstanceGroupFilters = {
  family: string | null;
  region: string | null;
  lineItemUsageAccountId: string | null;
  operatingSystem: string | null;
  familyType: string | null;
};

export type AWSComputeSpendSummaryEntity = {
  totalCost: number;
};
