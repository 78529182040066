import { useTheme } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";

interface Props {
  icon?: IconProp;
  text: string;
}

export default function Label(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.secondary_color_background}
      borderRadius={theme.borderRadius_2}
      justifyContent="center"
      padding={`${theme.space_xxs} ${theme.space_sm}`}
    >
      {props.icon && (
        <Box marginRight={theme.space_xs}>
          <FontAwesomeIcon color={theme.primary_color_text} icon={props.icon} />
        </Box>
      )}
      <Text>{props.text}</Text>
    </Flex>
  );
}
