import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPBigQuery(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 455 421"
        enableBackground="new 0 0 455 421"
      >
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d=" M266.000000,422.000000   C178.691437,422.000000 91.882843,421.978302 5.074400,422.084381   C1.814229,422.088348 0.893044,421.653320 0.896227,418.076477   C1.018695,280.449188 1.020475,142.821747 0.891592,5.194471   C0.888075,1.439189 1.845911,0.912854 5.303263,0.915144   C154.094086,1.013703 302.884979,1.012719 451.675812,0.919955   C455.076660,0.917835 456.116302,1.360386 456.112579,5.173546   C455.978394,142.800827 455.981934,280.428253 456.101410,418.055542   C456.104492,421.589569 455.251862,422.094879 451.946442,422.088440   C390.131134,421.968048 328.315552,422.000000 266.000000,422.000000  z"
        />
        <path
          fill="#4486FA"
          opacity="1.000000"
          stroke="none"
          d=" M306.205505,396.290192   C304.193512,396.677917 302.367767,396.835327 300.541992,396.835754   C246.723907,396.848846 192.905472,396.743103 139.087952,396.918060   C125.434647,396.962463 115.895332,391.580292 109.018120,379.526886   C79.980835,328.634399 50.675995,277.892151 21.081327,227.321793   C13.721593,214.745728 13.704440,203.409500 21.068409,190.822968   C50.741482,140.105591 80.068314,89.184319 109.268372,38.192524   C115.919388,26.577906 125.323288,21.132734 138.582626,21.157560   C197.898941,21.268621 257.215729,21.266047 316.532043,21.159008   C329.589417,21.135447 338.951538,26.443365 345.512573,37.880470   C374.917389,89.138824 404.439301,140.331329 434.231506,191.365143   C441.251770,203.390823 441.695709,214.512009 434.378723,226.506943   C424.499023,242.702850 415.265808,259.293152 405.482056,275.910522   C403.879059,276.791168 403.001038,275.879639 402.123718,275.166046   C400.833527,274.116669 399.701691,272.889587 398.539246,271.701965   C364.006165,236.420563 327.989227,202.637390 293.316345,167.497086   C292.153687,166.318771 291.049255,165.053268 289.210754,164.146133   C288.518707,163.743332 288.166016,163.460739 287.675049,162.855988   C287.119324,162.021835 287.472626,160.776917 285.814819,160.984406   C276.922485,151.708084 267.774628,143.419250 256.209534,137.973663   C229.320694,125.312668 194.442535,131.313431 173.187500,151.997040   C161.063141,163.795441 152.816544,177.560028 150.294815,194.206833   C145.853867,223.522858 154.980301,247.971619 178.067978,266.936340   C180.894318,269.257935 184.234680,270.905457 187.013840,273.881653   C188.709976,276.627014 190.751129,278.588318 192.752533,280.589294   C229.121552,316.950928 265.494324,353.308838 301.826416,389.707367   C303.648651,391.532959 306.158173,392.963470 306.205505,396.290192  z"
        />
        <path
          fill="#3D79E1"
          opacity="1.000000"
          stroke="none"
          d=" M306.476410,396.690948   C305.655121,394.405518 303.793915,393.343903 302.419189,391.967377   C265.273499,354.772614 228.095398,317.610138 190.950851,280.414215   C189.458176,278.919464 187.470673,277.751526 186.985382,275.050415   C189.118530,273.732361 190.798111,275.113281 192.585556,275.880371   C218.100128,286.830383 242.766068,285.450500 266.495178,270.768372   C272.381897,267.126038 273.443481,267.168457 278.493500,272.176270   C285.701294,279.323822 292.813812,286.567627 300.053040,293.683105   C304.363831,297.920258 305.621948,297.865234 309.959442,293.697540   C310.799316,292.890564 311.628052,292.070740 312.429169,291.225403   C316.034454,287.421112 316.268127,285.630493 312.501617,281.977631   C304.982483,274.685425 297.088409,267.782959 289.852081,260.191345   C285.278961,255.393692 285.240265,254.269836 288.730835,248.533997   C303.538879,224.200821 304.956543,199.132370 292.752808,173.320221   C291.268341,170.180466 289.028778,167.390335 288.405457,163.513153   C289.960754,162.871979 291.060944,163.515060 292.036743,164.530472   C292.959534,165.490692 293.918579,166.416168 294.864807,167.353775   C330.686798,202.849136 366.508453,238.344818 402.336517,273.834076   C403.043274,274.534149 403.825317,275.158234 404.890564,275.962341   C396.153870,291.973602 387.140533,307.864319 378.032837,323.700745   C367.592041,341.855072 356.675659,359.749146 346.710327,378.159271   C339.462067,391.549835 329.012909,397.898376 313.918518,396.869141   C311.599121,396.710999 309.262329,396.808777 306.476410,396.690948  z"
        />
        <path
          fill="#FDFEFF"
          opacity="1.000000"
          stroke="none"
          d=" M288.329468,163.041199   C304.904510,189.135101 306.908691,215.949173 293.048218,243.656799   C292.528412,244.695847 291.936279,245.700851 291.340424,246.699371   C286.537506,254.747894 286.526276,254.754822 293.089844,261.271210   C300.058105,268.189392 307.077515,275.056366 314.010651,282.009460   C318.031036,286.041412 317.969238,287.412811 313.985748,291.518036   C305.121735,300.652924 305.117920,300.656738 295.978149,291.535248   C289.027924,284.598877 282.033020,277.705627 275.182892,270.671600   C273.397400,268.838165 272.183411,268.419708 269.824738,270.005707   C243.438507,287.747955 215.997131,289.004150 187.315857,274.795776   C168.931152,263.617737 156.334534,248.358139 150.539459,227.773972   C137.950256,183.057098 168.809784,136.381958 214.783447,130.897629   C244.155823,127.393738 267.914520,137.777939 286.719543,160.784058   C287.356110,161.107101 287.599884,161.359787 287.828064,161.984863   C287.912720,162.505981 287.987274,162.741653 288.329468,163.041199  z"
        />
        <path
          fill="#3D79E1"
          opacity="1.000000"
          stroke="none"
          d=" M287.951996,161.986801   C287.675629,161.989471 287.407928,161.741959 287.070312,161.225815   C288.109833,160.244507 287.869263,161.195023 287.951996,161.986801  z"
        />
        <path
          fill="#4588FA"
          opacity="1.000000"
          stroke="none"
          d=" M231.568237,264.862671   C198.617905,267.784973 174.597610,245.604980 168.637421,220.865631   C161.018600,189.241669 180.876648,157.236252 212.863876,150.282043   C244.664108,143.368500 276.303589,164.510315 282.086823,196.537445   C287.872070,228.575577 266.990753,258.551697 234.955383,264.204437   C233.972519,264.377838 233.000290,264.611572 231.568237,264.862671  z"
        />
        <path
          fill="#FEFFFF"
          opacity="1.000000"
          stroke="none"
          d=" M217.037689,247.563339   C216.982788,227.611710 217.083481,208.115936 216.877197,188.623398   C216.836975,184.823792 217.923569,183.888046 221.620941,183.944443   C232.304047,184.107361 232.306656,183.937393 232.306641,194.597839   C232.306610,211.093353 232.184769,227.590439 232.398087,244.083191   C232.447144,247.876328 231.426529,249.496017 227.553131,249.548477   C223.945343,249.597305 220.350037,250.112442 217.037689,247.563339  z"
        />
        <path
          fill="#FEFEFF"
          opacity="1.000000"
          stroke="none"
          d=" M203.275589,206.171295   C203.299301,218.616241 203.299301,230.576645 203.299301,242.682343   C194.621414,239.574707 187.968002,230.390762 187.967896,221.947769   C187.967834,217.116226 187.945938,212.284470 187.986649,207.453278   C187.996002,206.342163 187.611557,205.061798 188.768539,204.231430   C190.235214,203.178772 201.657074,204.349625 203.275589,206.171295  z"
        />
        <path
          fill="#F8FBFF"
          opacity="1.000000"
          stroke="none"
          d=" M247.441223,242.738968   C245.085693,237.042587 246.379974,231.118332 246.258163,225.286850   C246.012817,213.542969 246.183701,213.632629 257.976440,213.812698   C260.880005,213.857025 261.651886,214.611160 262.072021,217.646774   C263.757111,229.822098 256.669250,236.679901 247.441223,242.738968  z"
        />
      </svg>
    </Icon>
  );
}
