import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { isBefore } from "date-fns";
import React, { useMemo } from "react";
import useRefFn from "../../../../hooks/useRefFn";
import copyText from "../../copyText";
import {
  AWSCommitmentInventoryDatum,
  AWSCommitmentInventoryFilter,
  AWSCommittedUseType,
} from "../types";

type Props = {
  inventory: AWSCommitmentInventoryDatum[];
  isLoading: boolean;
  onAddFilter: (
    filterKey: keyof AWSCommitmentInventoryFilter,
    value: string | null
  ) => void;
};

type TableData = {
  commitmentId: string;
  expirationDate: Date;
  family: string;
  instanceCount: number;
  instanceSize: string;
  operatingSystem: string;
  purchaseDate: Date;
  realizedSavings: number;
  region: string;
  status: string;
  type: string;
  unrealizedSavings: number;
  utilizationPercentage: number;
};

function getTableData(inventory: AWSCommitmentInventoryDatum[]): TableData[] {
  return inventory.map((datum) => ({
    ...datum,
    expirationDate: new Date(datum.expirationDate),
    purchaseDate: new Date(datum.purchaseDate),
    status: datum.state,
    utilizationPercentage: datum.utilizationPercentage / 100,
  }));
}

const columnHelper = createColumnHelper<TableData>();

export default function AWSCommitmentInventoryTable(props: Props) {
  const theme = useTheme();
  const data = useMemo(() => getTableData(props.inventory), [props.inventory]);
  const handleAddFilter = useRefFn(props.onAddFilter);

  const inventoryType: AWSCommittedUseType | "both" = useMemo(() => {
    let foundRI = false;
    let foundSP = false;

    for (const datum of data) {
      if (datum.type === "Reserved Instance") foundRI = true;
      if (datum.type === "Savings Plan") foundSP = true;

      if (foundRI && foundSP) break;
    }

    return foundRI && !foundSP
      ? AWSCommittedUseType.RI
      : !foundRI && foundSP
        ? AWSCommittedUseType.SP
        : "both";
  }, [props.inventory]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("type", {
        cell: ({ getValue }) =>
          getValue() ? (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              onClick={() => handleAddFilter("type", getValue() || null)}
            >
              {" "}
              {getValue()}{" "}
            </Text>
          ) : (
            <>---</>
          ),
        header: copyText.awsInventoryTableHeader_00_type,
        size: 210,
      }),
      ...(inventoryType === "both" || inventoryType === AWSCommittedUseType.RI
        ? [
            columnHelper.accessor("family", {
              cell: ({ getValue }) =>
                getValue() ? (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    onClick={() =>
                      handleAddFilter("family", getValue() || null)
                    }
                  >
                    {" "}
                    {getValue()}{" "}
                  </Text>
                ) : (
                  <>---</>
                ),
              header: copyText.awsInventoryTableHeader_01_family,
              size: 210,
            }),
            columnHelper.accessor("instanceSize", {
              cell: ({ getValue }) =>
                getValue() ? (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    onClick={() =>
                      handleAddFilter("instanceSize", getValue() || null)
                    }
                  >
                    {" "}
                    {getValue()}{" "}
                  </Text>
                ) : (
                  <>---</>
                ),
              header: copyText.awsInventoryTableHeader_02_instanceSize,
              size: 210,
            }),
            columnHelper.accessor("region", {
              cell: ({ getValue }) =>
                getValue() ? (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    onClick={() =>
                      handleAddFilter("region", getValue() || null)
                    }
                  >
                    {" "}
                    {getValue()}{" "}
                  </Text>
                ) : (
                  <>---</>
                ),
              header: copyText.awsInventoryTableHeader_03_region,
              size: 210,
            }),
            columnHelper.accessor("operatingSystem", {
              cell: ({ getValue }) =>
                getValue() ? (
                  <Text
                    color={theme.primary_color_text}
                    cursor="pointer"
                    onClick={() =>
                      handleAddFilter("operatingSystem", getValue() || null)
                    }
                  >
                    {" "}
                    {getValue()}{" "}
                  </Text>
                ) : (
                  <>---</>
                ),
              header: copyText.awsInventoryTableHeader_04_operatingSystem,
              size: 210,
            }),
            columnHelper.accessor("instanceCount", {
              size: 210,
              header: copyText.awsInventoryTableHeader_05_instanceCount,
            }),
          ]
        : []),
      columnHelper.accessor("commitmentId", {
        cell: ({ getValue }) =>
          getValue() ? (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              onClick={() =>
                handleAddFilter("commitmentId", getValue() || null)
              }
            >
              {" "}
              {getValue()}{" "}
            </Text>
          ) : (
            <>---</>
          ),
        header: copyText.awsInventoryTableHeader_06_commitmentId,
        size: 210,
      }),
      columnHelper.accessor("realizedSavings", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsInventoryTableHeader_07_realizedSavings,
        meta: { align: "right" },
        size: 210,
        sortDescFirst: true,
      }),
      columnHelper.accessor("unrealizedSavings", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsInventoryTableHeader_08_unrealizedSavings,
        meta: { align: "right" },
        size: 210,
        sortDescFirst: true,
      }),
      columnHelper.accessor("utilizationPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsInventoryTableHeader_09_utilizationPercentage,
        meta: { align: "right" },
        size: 210,
        sortDescFirst: true,
      }),
      columnHelper.accessor("status", {
        cell: ({ getValue }) =>
          getValue() ? (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              onClick={() => handleAddFilter("status", getValue() || null)}
            >
              {" "}
              {getValue()}{" "}
            </Text>
          ) : (
            <>---</>
          ),
        header: copyText.awsInventoryTableHeader_10_status,
        size: 210,
      }),
      columnHelper.accessor("purchaseDate", {
        cell: ({ getValue }) => <>{formatDate(getValue(), "yyyy/MM/dd")}</>,
        header: copyText.awsInventoryTableHeader_11_purchaseDate,
        size: 210,
        sortDescFirst: true,
        sortingFn: (a, b) =>
          isBefore(a.original.purchaseDate, b.original.purchaseDate)
            ? -1
            : isBefore(b.original.purchaseDate, a.original.purchaseDate)
              ? 1
              : 0,
      }),
      columnHelper.accessor("expirationDate", {
        cell: ({ getValue }) => <>{formatDate(getValue(), "yyyy/MM/dd")}</>,
        header: copyText.awsInventoryTableHeader_12_expirationDate,
        size: 210,
        sortDescFirst: true,
        sortingFn: (a, b) =>
          isBefore(a.original.expirationDate, b.original.expirationDate)
            ? -1
            : isBefore(b.original.expirationDate, a.original.expirationDate)
              ? 1
              : 0,
      }),
    ];
  }, [inventoryType]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "realizedSavings", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}
