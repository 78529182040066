import ErrorBoundary from "@/components/ErrorBoundary";
import useGatekeeper from "@/hooks/useGatekeeper";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import copyText from "../copyText";
import ReallocationManagementContainer from "./ReallocationManagementContainer";

export function ReallocationPage(): JSX.Element {
  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "allocationRules"),
  });

  const gatekeeper = useGatekeeper();

  const containers = [
    {
      component: <ReallocationManagementContainer />,
      disabled: !gatekeeper.canListReallocations,
      label: copyText.reallocationTabLabelAllocationRules,
      value: "allocationRules",
    },
  ];

  return (
    <ErrorBoundary boundaryName="ReallocationPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default ReallocationPage;
