import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateUserTenantRolesParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { grantID: string } & UpdateUserTenantRolesParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { grantID: string } & UpdateUserTenantRolesParams
>;

export default function useUpdateUserTenantRoles(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ grantID, ...params }) =>
      client.updateUserTenantRoles(grantID, params),
    ...options,
  });
}
