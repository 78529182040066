import { useTheme } from "@emotion/react";
import {
  faCancel,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClose,
  faLock,
  faPencil,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { DataSource } from "@ternary/api-lib/constants/enums";
import {
  DimensionPreference,
  MeasurePreference,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { groupBy, isEqual, keyBy, noop, sortBy, uniq } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useGetLabelMapsByTenantID from "../../../api/core/useGetLabelMapsByTenantID";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useAvailableDimensionsByDataSource from "../../../hooks/useAvailableDimensionsByDataSource";
import useAvailableMeasuresByDataSource from "../../../hooks/useAvailableMeasuresByDataSource";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Checkbox from "../../../ui-lib/components/Checkbox";
import ConfirmationModal from "../../../ui-lib/components/ConfirmationModal";
import Dropdown from "../../../ui-lib/components/Dropdown";
import Grid from "../../../ui-lib/components/Grid";
import { Skeleton } from "../../../ui-lib/components/Table";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import useCreateDimensionPreferences from "../hooks/useCreateDimensionPreferences";
import useCreateMeasurePreferences from "../hooks/useCreateMeasurePreferences";
import useGetDimensionPreferencesByTenantID from "../hooks/useGetDimensionPreferencesByTenantID";
import useGetMeasurePreferencesByTenantID from "../hooks/useGetMeasurePreferencesByTenantID";
import useUpdateDimensionPreferences from "../hooks/useUpdateDimensionPreferences";
import useUpdateMeasurePreferences from "../hooks/useUpdateMeasurePreferences";

const Tab = {
  LABELS: "LABELS",
  MEASURES: "MEASURES",
} as const;

type Tab = (typeof Tab)[keyof typeof Tab];

const DefaultCategory = {
  HIDDEN: "HIDDEN",
  PREFERRED: "PREFERRED",
  UNASSIGNED: "UNASSIGNED",
} as const;

type DefaultCategory = (typeof DefaultCategory)[keyof typeof DefaultCategory];

type CategoryItem = {
  category: string;
  dataSource: string;
  name: string;
};

type DataSourceCategoryOrder = {
  [dataSource: string]: string[];
};

type State = {
  currentOrderedCategories: string[];
  isConfirmingReset: boolean;
  labelCategoryItems: CategoryItem[];
  labelCategoryOrders: DataSourceCategoryOrder;
  measureCategoryItems: CategoryItem[];
  measureCategoryOrders: DataSourceCategoryOrder;
  searchText: string;
  selectedCategoryItems: CategoryItem[];
  selectedDataSource: DataSource;
  selectedTab: Tab;
};

const initialState: State = {
  currentOrderedCategories: [],
  isConfirmingReset: false,
  labelCategoryItems: [],
  labelCategoryOrders: {},
  measureCategoryItems: [],
  measureCategoryOrders: {},
  searchText: "",
  selectedCategoryItems: [],
  selectedDataSource: DataSource.BILLING,
  selectedTab: Tab.LABELS,
};

const defaultDimensionPreferences = [];
const defaultMeasurePreferences = [];

export default function GlobalPreferencesManagementContainer(): JSX.Element {
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const inputRef = useRef<HTMLInputElement | null>(null);

  //
  // Queries
  //

  const { data: labels, isLoading: isLoadingLabelMaps } =
    useGetLabelMapsByTenantID(authenticatedUser.tenant.fsDocID);

  const {
    data: dimensionPreferences = defaultDimensionPreferences,
    isLoading: isLoadingDimensionPreferences,
    refetch: refetchDimensionPreferences,
  } = useGetDimensionPreferencesByTenantID(authenticatedUser.tenant.id);

  const {
    data: measurePreferences = defaultMeasurePreferences,
    isLoading: isLoadingMeasurePreferences,
    refetch: refetchMeasurePreferences,
  } = useGetMeasurePreferencesByTenantID(authenticatedUser.tenant.id);

  //
  // Mutations
  //

  const {
    isPending: isCreatingDimensionPreferences,
    mutate: createDimensionPreferences,
  } = useCreateDimensionPreferences({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorCreatingPreferredLabelsMessage,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.CREATE_LABEL_PREFERENCES, {
        source: state.selectedDataSource,
      });
    },
    onSuccess: () => {
      refetchDimensionPreferences();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successCreatingDimensionPreferences,
      });
    },
  });

  const {
    isPending: isCreatingMeasurePreferences,
    mutate: createMeasurePreferences,
  } = useCreateMeasurePreferences({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorCreatingPreferredMeasuresMessage,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.CREATE_LABEL_PREFERENCES, {
        source: state.selectedDataSource,
      });
    },
    onSuccess: () => {
      refetchMeasurePreferences();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successCreatingMeasurePreferences,
      });
    },
  });

  const {
    isPending: isUpdatingDimensionPreferences,
    mutate: updateDimensionPreferences,
  } = useUpdateDimensionPreferences({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingDimensionPreferencesMessage,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPDATE_LABEL_PREFERENCES, {
        source: state.selectedDataSource,
      });
    },
    onSuccess: () => {
      refetchDimensionPreferences();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingDimensionPreferencesMessage,
      });
    },
  });

  const {
    isPending: isUpdatingMeasurePreferences,
    mutate: updateMeasurePreferences,
  } = useUpdateMeasurePreferences({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingMeasurePreferencesMessage,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPDATE_MEASURE_PREFERENCES, {
        source: state.selectedDataSource,
      });
    },
    onSuccess: () => {
      refetchMeasurePreferences();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingMeasurePreferencesMessage,
      });
    },
  });

  const isLoading =
    isLoadingLabelMaps ||
    isLoadingDimensionPreferences ||
    isLoadingMeasurePreferences ||
    isCreatingDimensionPreferences ||
    isCreatingMeasurePreferences ||
    isUpdatingDimensionPreferences ||
    isUpdatingMeasurePreferences;

  //
  // Side Effects
  //

  useEffect(() => {
    const labelCategoryItems = dimensionPreferences.reduce(
      (accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      },
      []
    );

    mergeState({
      labelCategoryItems,
      labelCategoryOrders:
        getCategoryOrdersKeyedByDataSource(labelCategoryItems),
      ...(state.selectedTab === Tab.LABELS
        ? {
            currentOrderedCategories: getOrderedCategories(
              labelCategoryItems,
              state.selectedDataSource
            ),
          }
        : {}),
    });
  }, [dimensionPreferences]);

  useEffect(() => {
    const measureCategoryItems = measurePreferences.reduce(
      (accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      },
      []
    );

    mergeState({
      measureCategoryItems,
      measureCategoryOrders:
        getCategoryOrdersKeyedByDataSource(measureCategoryItems),
      ...(state.selectedTab === Tab.MEASURES
        ? {
            currentOrderedCategories: getOrderedCategories(
              measureCategoryItems,
              state.selectedDataSource
            ),
          }
        : {}),
    });
  }, [measurePreferences]);

  const dataSourcesWithLabelChanges = useMemo(() => {
    const initialDimensionPreferences = dimensionPreferences
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .reduce((accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      }, []);

    return getDataSourcesWithChanges(
      initialDimensionPreferences,
      state.labelCategoryItems
    );
  }, [dimensionPreferences, state.labelCategoryItems]);

  const dataSourcesWithMeasureChanges = useMemo(() => {
    const initialMeasurePreferences = measurePreferences
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .reduce((accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      }, []);
    return getDataSourcesWithChanges(
      initialMeasurePreferences,
      state.measureCategoryItems
    );
  }, [measurePreferences, state.measureCategoryItems]);

  //
  // Handler Helpers
  //

  function createDimensionPreferencesAtDataSource(dataSource: string): void {
    if (!isDataSource(dataSource)) return;

    const preferences = getDataSourcePreferences(
      dataSource,
      state.labelCategoryItems
    );

    const categories = preferences.reduce((accum: string[], preference) => {
      if (accum.some((category) => category === preference.category)) {
        return accum;
      } else {
        accum.push(preference.category);
        return accum;
      }
    }, []);

    const preferencesKeyedByCategory = preferences.reduce(
      (accum: { [key: string]: DimensionPreference }, preference) => {
        if (accum[preference.category]) {
          accum[preference.category].values.push(preference.value);
        } else {
          accum[preference.category] = {
            id: undefined,
            category: preference.category,
            priority: categories.indexOf(preference.category) + 1,
            values: [preference.value],
          };
        }

        return accum;
      },
      {}
    );

    createDimensionPreferences({
      tenantID: authenticatedUser.tenant.id,
      dataSource,
      preferences: Object.values(preferencesKeyedByCategory),
    });
  }

  function updateDimensionPreferencesAtDataSource(dataSource: string): void {
    if (!isDataSource(dataSource)) return;

    const updatedPreferences = getDataSourcePreferences(
      dataSource,
      state.labelCategoryItems
    );

    const existingPreferences = getDataSourcePreferences(
      dataSource,
      dimensionPreferences
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
        .reduce((accum: CategoryItem[], preference) => {
          preference.values.forEach((val) =>
            accum.push({
              category: preference.category,
              dataSource: preference.dataSource,
              name: val,
            })
          );

          return accum;
        }, [])
    );

    // Get ordered array for priority
    const categories = [...updatedPreferences, ...existingPreferences].reduce(
      (accum: string[], preference) => {
        if (accum.some((category) => category === preference.category)) {
          return accum;
        } else {
          accum.push(preference.category);
          return accum;
        }
      },
      []
    );

    // Group values into array for category/datasource
    const preferencesKeyedByCategory = categories.reduce(
      (accum: { [key: string]: DimensionPreference }, category, index) => {
        const existingRecord = dimensionPreferences.find(
          (pref) => pref.category === category && pref.dataSource === dataSource
        );

        const currentPreferences = updatedPreferences.reduce(
          (accum: string[], record) => {
            if (record.category === category) {
              accum.push(record.value);
            }
            return accum;
          },
          []
        );

        if (accum[category]) {
          accum[category].values = currentPreferences;
        } else {
          accum[category] = {
            id: existingRecord?.id,
            category,
            priority: index + 1,
            values: currentPreferences,
          };
        }

        return accum;
      },
      {}
    );

    // Compare to existing preferences to get changes
    const changes = Object.values(preferencesKeyedByCategory).reduce(
      (accum: DimensionPreference[], preference) => {
        const existingPreferences = dimensionPreferences.find(
          (pref) =>
            pref.category === preference.category &&
            pref.dataSource === dataSource
        );

        const hasChangedValues = !isEqual(
          existingPreferences?.values,
          preference.values
        );

        const hasChangedPriority = !isEqual(
          existingPreferences?.priority,
          preference.priority
        );

        if (!hasChangedValues && !hasChangedPriority) {
          return accum;
        }

        return [...accum, preference];
      },
      []
    );

    updateDimensionPreferences({
      tenantID: authenticatedUser.tenant.id,
      dataSource,
      preferences: changes,
    });
  }

  function createMeasurePreferencesAtDataSource(dataSource: string): void {
    if (!isDataSource(dataSource)) return;

    const preferences = getDataSourcePreferences(
      dataSource,
      state.measureCategoryItems
    );

    const categories = preferences.reduce((accum: string[], preference) => {
      if (accum.some((category) => category === preference.category)) {
        return accum;
      } else {
        accum.push(preference.category);
        return accum;
      }
    }, []);

    const preferencesKeyedByCategory = preferences.reduce(
      (accum: { [key: string]: MeasurePreference }, preference) => {
        if (accum[preference.category]) {
          accum[preference.category].values.push(preference.value);
        } else {
          accum[preference.category] = {
            id: undefined,
            category: preference.category,
            priority: categories.indexOf(preference.category) + 1,
            values: [preference.value],
          };
        }

        return accum;
      },
      {}
    );

    createMeasurePreferences({
      tenantID: authenticatedUser.tenant.id,
      dataSource,
      preferences: Object.values(preferencesKeyedByCategory),
    });
  }

  function updateMeasurePreferencesAtDataSource(dataSource: string): void {
    if (!isDataSource(dataSource)) return;

    const updatedPreferences = getDataSourcePreferences(
      dataSource,
      state.measureCategoryItems
    );

    const existingPreferences = getDataSourcePreferences(
      dataSource,
      measurePreferences
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
        .reduce((accum: CategoryItem[], preference) => {
          preference.values.forEach((val) =>
            accum.push({
              category: preference.category,
              dataSource: preference.dataSource,
              name: val,
            })
          );

          return accum;
        }, [])
    );

    // Get ordered array for priority
    const categories = [...updatedPreferences, ...existingPreferences].reduce(
      (accum: string[], preference) => {
        if (accum.some((category) => category === preference.category)) {
          return accum;
        } else {
          accum.push(preference.category);
          return accum;
        }
      },
      []
    );

    // Group values into array for category/datasource
    const preferencesKeyedByCategory = categories.reduce(
      (accum: { [key: string]: MeasurePreference }, category, index) => {
        const existingRecord = measurePreferences.find(
          (pref) => pref.category === category && pref.dataSource === dataSource
        );

        const currentPreferences = updatedPreferences.reduce(
          (accum: string[], record) => {
            if (record.category === category) {
              accum.push(record.value);
            }
            return accum;
          },
          []
        );

        if (accum[category]) {
          accum[category].values = currentPreferences;
        } else {
          accum[category] = {
            id: existingRecord?.id,
            category,
            priority: index + 1,
            values: currentPreferences,
          };
        }

        return accum;
      },
      {}
    );

    // Compare to existing preferences to get changes
    const changes = Object.values(preferencesKeyedByCategory).reduce(
      (accum: MeasurePreference[], preference) => {
        const existingPreferences = measurePreferences.find(
          (pref) =>
            pref.category === preference.category &&
            pref.dataSource === dataSource
        );

        const hasChangedValues = !isEqual(
          existingPreferences?.values,
          preference.values
        );

        const hasChangedPriority = !isEqual(
          existingPreferences?.priority,
          preference.priority
        );

        if (!hasChangedValues && !hasChangedPriority) {
          return accum;
        }

        return [...accum, preference];
      },
      []
    );

    updateMeasurePreferences({
      tenantID: authenticatedUser.tenant.id,
      dataSource,
      preferences: changes,
    });
  }

  function updateCategoryOrdersStateFromCurrent() {
    setState((current) => {
      const stateKey =
        current.selectedTab === Tab.LABELS
          ? "labelCategoryOrders"
          : "measureCategoryOrders";

      const updatedDict = {
        ...current[stateKey],
        [current.selectedDataSource]: current.currentOrderedCategories,
      };

      return {
        ...current,
        [stateKey]: updatedDict,
      };
    });
  }

  function updateCategoryItemsStateFromOrders() {
    setState((current) => {
      const labelCategoryItems = getOrderedCategoryItems(
        current.labelCategoryItems,
        current.labelCategoryOrders
      );

      const measureCategoryItems = getOrderedCategoryItems(
        current.measureCategoryItems,
        current.measureCategoryOrders
      );

      return {
        ...current,
        labelCategoryItems,
        measureCategoryItems,
      };
    });
  }

  //
  // Handlers
  //

  function handleRenameCategory(from: string, to: string) {
    const orderIndex = state.currentOrderedCategories.indexOf(from);

    if (orderIndex < 0) return;

    const updatedOrders = [...state.currentOrderedCategories];
    updatedOrders.splice(orderIndex, 1, to);

    const stateKey =
      state.selectedTab === Tab.LABELS
        ? "labelCategoryItems"
        : "measureCategoryItems";

    const updatedCategoryItems = state[stateKey].map((item) =>
      item.category === from && item.dataSource === state.selectedDataSource
        ? { ...item, category: to }
        : item
    );

    mergeState({
      [stateKey]: updatedCategoryItems,
      currentOrderedCategories: updatedOrders,
    });
    updateCategoryOrdersStateFromCurrent();
  }

  function handleChangeDataSource(dataSource: string) {
    const dataSourceCategoryOrderDict =
      state[
        state.selectedTab === Tab.LABELS
          ? "labelCategoryOrders"
          : "measureCategoryOrders"
      ];

    const currentOrderedCategories = getOrderedDataSourceCategories(
      dataSourceCategoryOrderDict,
      dataSource
    );

    updateCategoryOrdersStateFromCurrent();
    updateCategoryItemsStateFromOrders();

    mergeState({
      currentOrderedCategories,
      selectedCategoryItems: [],
      selectedDataSource: DataSource[dataSource],
    });
  }

  function handleChangeToMeasures() {
    if (state.selectedTab === Tab.MEASURES) return;

    updateCategoryOrdersStateFromCurrent();

    const currentOrderedCategories = getOrderedDataSourceCategories(
      state.measureCategoryOrders,
      state.selectedDataSource
    );

    mergeState({
      currentOrderedCategories,
      selectedCategoryItems: [],
      selectedTab: Tab.MEASURES,
    });

    updateCategoryItemsStateFromOrders();
  }

  function handleChangeToLabels() {
    if (state.selectedTab === Tab.LABELS) return;

    updateCategoryOrdersStateFromCurrent();

    const currentOrderedCategories = getOrderedDataSourceCategories(
      state.labelCategoryOrders,
      state.selectedDataSource
    );

    mergeState({
      currentOrderedCategories,
      selectedCategoryItems: [],
      selectedTab: Tab.LABELS,
    });

    updateCategoryItemsStateFromOrders();
  }

  function handleRemoveCategory(category: string) {
    const stateKey =
      state.selectedTab === Tab.LABELS
        ? "labelCategoryItems"
        : "measureCategoryItems";

    const filteredCategoryItems = state[stateKey].filter(
      (item) =>
        !(
          item.category === category &&
          item.dataSource === state.selectedDataSource
        )
    );

    const filteredCategories = state.currentOrderedCategories.filter(
      (otherCategory) => otherCategory !== category
    );

    mergeState({
      [stateKey]: filteredCategoryItems,
      currentOrderedCategories: filteredCategories,
    });
    updateCategoryOrdersStateFromCurrent();
    updateCategoryItemsStateFromOrders();
  }

  function handleMoveSelected(category: string) {
    const selectedKeyedByName = keyBy(
      state.selectedCategoryItems,
      (item) => item.name
    );

    const stateKey =
      state.selectedTab === Tab.LABELS
        ? "labelCategoryItems"
        : "measureCategoryItems";

    const selectedRemoved = state[stateKey].filter(
      (item) =>
        !(
          !!selectedKeyedByName[item.name] &&
          item.dataSource === state.selectedDataSource
        )
    );

    const updatedSelectedItems = state.selectedCategoryItems.map((item) => ({
      ...item,
      category,
    }));

    const sorted = sortBy(
      [...selectedRemoved, ...updatedSelectedItems],
      (item) => item.name
    );

    mergeState({
      [stateKey]: sorted,
      selectedCategoryItems: [],
    });
    updateCategoryOrdersStateFromCurrent();
    updateCategoryItemsStateFromOrders();
  }

  function handleSaveChanges() {
    dataSourcesWithLabelChanges.forEach((dataSource) => {
      if (
        !dimensionPreferences.find(
          (preference) => preference.dataSource === dataSource
        )
      ) {
        createDimensionPreferencesAtDataSource(dataSource);
        return;
      }

      updateDimensionPreferencesAtDataSource(dataSource);
    });

    dataSourcesWithMeasureChanges.forEach((dataSource) => {
      if (
        !measurePreferences.find(
          (preference) => preference.dataSource === dataSource
        )
      ) {
        createMeasurePreferencesAtDataSource(dataSource);
        return;
      }

      updateMeasurePreferencesAtDataSource(dataSource);
    });
  }

  function handleReorderCategory(
    category: string,
    direction: "left" | "right"
  ) {
    const sourceIndex = state.currentOrderedCategories.findIndex(
      (other) => other === category
    );

    if (sourceIndex < 0) {
      return;
    }

    const destinationIndex =
      direction === "left" ? sourceIndex - 1 : sourceIndex + 1;

    if (
      destinationIndex < 0 ||
      destinationIndex >= state.currentOrderedCategories.length
    ) {
      return;
    }

    const orderedCategories = [...state.currentOrderedCategories];
    const temp = orderedCategories[sourceIndex];
    orderedCategories[sourceIndex] = orderedCategories[destinationIndex];
    orderedCategories[destinationIndex] = temp;

    mergeState({ currentOrderedCategories: orderedCategories });
    updateCategoryOrdersStateFromCurrent();
    updateCategoryItemsStateFromOrders();
  }

  function handleResetChanges() {
    const labelCategoryItems = dimensionPreferences
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .reduce((accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      }, []);

    const measureCategoryItems = measurePreferences
      .sort((a, b) => (a.priority > b.priority ? 1 : -1))
      .reduce((accum: CategoryItem[], preference) => {
        preference.values.forEach((val) =>
          accum.push({
            category: preference.category,
            dataSource: preference.dataSource,
            name: val,
          })
        );

        return accum;
      }, []);

    const labelCategoryOrders =
      getCategoryOrdersKeyedByDataSource(labelCategoryItems);
    const measureCategoryOrders =
      getCategoryOrdersKeyedByDataSource(measureCategoryItems);

    const currentOrderedCategories = getOrderedDataSourceCategories(
      state.selectedTab === Tab.LABELS
        ? labelCategoryOrders
        : measureCategoryOrders,
      state.selectedDataSource
    );

    mergeState({
      labelCategoryItems,
      labelCategoryOrders,
      measureCategoryItems,
      measureCategoryOrders,
      currentOrderedCategories,
      searchText: "",
      selectedCategoryItems: [],
    });
  }

  function getOrderedDataSourceCategories(
    dict: DataSourceCategoryOrder,
    dataSource: string
  ) {
    const found = dict[dataSource] ?? [];

    if (found.length > 0) return found;

    return getOrderedCategories([]);
  }

  //
  // Render
  //

  const dimensionsByDataSource = useAvailableDimensionsByDataSource(
    state.selectedDataSource
  );

  const availableMeasures = useAvailableMeasuresByDataSource(
    state.selectedDataSource
  );

  const dropdownItems = Object.keys(labels ?? {}).map((key) => ({
    label: DataSource[key],
    onClick: () => handleChangeDataSource(key),
  }));

  const hasUnsavedChanges =
    dataSourcesWithLabelChanges.length +
      dataSourcesWithMeasureChanges.length ===
    0;

  const dataSourceCategoryItems = (
    state.selectedTab === Tab.LABELS
      ? state.labelCategoryItems
      : state.measureCategoryItems
  ).filter((item) => item.dataSource === state.selectedDataSource);

  const categoryKeyedByItem = keyBy(
    dataSourceCategoryItems,
    (item) => item.name
  );

  const unassignedCategoryItems: CategoryItem[] = (
    state.selectedTab === Tab.LABELS
      ? dimensionsByDataSource
      : availableMeasures.map((measure) => measure.name)
  )
    .filter((name) => !categoryKeyedByItem[name])
    .map((name) => ({
      category: DefaultCategory.UNASSIGNED,
      dataSource: state.selectedDataSource,
      name,
    }));

  const itemsGroupedByCategory = groupBy(
    dataSourceCategoryItems,
    (item) => item.category
  );

  if (!gatekeeper.isLabelPreferenceAdmin) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const categories = state.currentOrderedCategories;

  function renderCategoryList() {
    return (
      <Grid
        gridGap={theme.space_sm}
        gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
        marginTop={theme.space_sm}
      >
        {categories.map((category, index) => {
          const isDefaultCategory = (
            [DefaultCategory.PREFERRED, DefaultCategory.HIDDEN] as string[]
          ).includes(category);

          const searchText = state.searchText.trim().toLowerCase();
          const categoryItems = itemsGroupedByCategory[category] ?? [];
          const filteredItems =
            searchText === ""
              ? categoryItems
              : categoryItems.filter((item) =>
                  item.name.toLowerCase().includes(searchText)
                );

          return (
            <Box key={category} height={300} maxWidth={450} minWidth={300}>
              <CategoryPreferencesCard
                canEdit={!isDefaultCategory}
                canMoveLeft={index > 0}
                canMoveRight={index < categories.length - 1}
                categories={categories}
                category={category}
                categoryItems={filteredItems}
                isLoading={isLoading}
                selectedItems={state.selectedCategoryItems}
                onMoveCategory={(direction) =>
                  handleReorderCategory(category, direction)
                }
                onMoveSelectedToThisCategory={() =>
                  handleMoveSelected(category)
                }
                onRemove={
                  isDefaultCategory
                    ? noop
                    : () => handleRemoveCategory(category)
                }
                onRename={(to) => handleRenameCategory(category, to)}
                onSelect={(items) =>
                  mergeState({ selectedCategoryItems: items })
                }
              />
            </Box>
          );
        })}
      </Grid>
    );
  }

  return (
    <Flex flex="0 0 100%" minHeight={0}>
      {/* LEFT COLUMN */}
      <Flex direction="column" paddingRight={theme.space_md} flex="0 0 11rem">
        <Box position="sticky" top={0}>
          <Flex
            alignItems="center"
            backgroundColor={
              state.selectedTab === Tab.LABELS
                ? theme.background_color_disabled
                : "none"
            }
            backgroundColorOnHover={
              state.selectedTab === Tab.LABELS
                ? undefined
                : theme.background_color_disabled
            }
            borderRadius={theme.borderRadius_2}
            cursor="pointer"
            height={35}
            marginBottom={theme.space_xxs}
            paddingLeft={theme.space_md}
            onClick={handleChangeToLabels}
          >
            <Text fontSize={theme.fontSize_base}>
              {copyText.labelManagementBoxTitleAll}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            backgroundColor={
              state.selectedTab === Tab.MEASURES
                ? theme.background_color_disabled
                : "none"
            }
            backgroundColorOnHover={
              state.selectedTab === Tab.MEASURES
                ? undefined
                : theme.background_color_disabled
            }
            borderRadius={theme.borderRadius_2}
            cursor="pointer"
            height={35}
            marginBottom={theme.space_xxs}
            paddingLeft={theme.space_md}
            onClick={handleChangeToMeasures}
          >
            <Text fontSize={theme.fontSize_base}>
              {copyText.measureManagementBoxTitleAll}
            </Text>
          </Flex>
        </Box>
      </Flex>

      {/* CENTER COLUMN */}
      <Box flex="0 1 100%">
        <Flex
          alignItems="center"
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          flex="0 0 auto"
          justifyContent="space-between"
          padding={theme.space_sm}
          paddingVertical={theme.space_xs}
          position="sticky"
          top={0}
          zIndex={1}
        >
          <Flex alignItems="center">
            <Text fontSize="1.25rem" marginRight={theme.space_md}>
              {copyText.labelsManagementDataSourceTitle}
            </Text>

            <Dropdown
              defaultSelectedOption={dropdownItems.find(
                (option) => option.label === DataSource.BILLING
              )}
              disabled={isLoading}
              options={dropdownItems}
              placement="bottom-start"
              selectedOption={dropdownItems.find(
                (option) => option.label === state.selectedDataSource
              )}
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
              >
                <Text truncate marginRight={theme.space_sm}>
                  {state.selectedDataSource}
                </Text>
              </Button>
            </Dropdown>
          </Flex>

          <Flex alignItems="center" width={500}>
            <Box>
              <TextInput
                disabled={isLoading}
                iconEnd={
                  <Icon
                    color={theme.text_color_secondary}
                    clickable
                    icon={state.searchText.length > 0 ? faClose : faSearch}
                    onClick={() => {
                      mergeState({ searchText: "" });
                      inputRef.current?.focus();
                    }}
                  />
                }
                inputRef={inputRef}
                size="small"
                placeholder={copyText.searchInputPlaceholder}
                value={state.searchText}
                onChange={(event) =>
                  mergeState({ searchText: event.target.value })
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    mergeState({ searchText: "" });
                  }
                }}
              />
            </Box>

            <Box marginHorizontal={theme.space_sm}>
              <Button
                disabled={isLoading || hasUnsavedChanges}
                primary
                size="small"
                onClick={handleSaveChanges}
              >
                {copyText.labelMeasureManagementSave}
              </Button>
            </Box>

            <Box>
              <Button
                disabled={isLoading || hasUnsavedChanges}
                primary
                size="small"
                onClick={() => mergeState({ isConfirmingReset: true })}
              >
                {copyText.labelMeasureManagementReset}
              </Button>
            </Box>

            {state.isConfirmingReset && (
              <ConfirmationModal
                title={copyText.labelMeasureManagementResetTitle}
                message={copyText.labelMeasureManagementResetMessage}
                onCancel={() => mergeState({ isConfirmingReset: false })}
                onConfirm={() => {
                  mergeState({ isConfirmingReset: false });
                  handleResetChanges();
                }}
              />
            )}
          </Flex>
        </Flex>

        {renderCategoryList()}
      </Box>

      {/* RIGHT COLUMN */}
      <Box paddingLeft={theme.space_md} flex="0 0 300px">
        <Box position="sticky" top={0}>
          <CategoryForm
            categories={categories}
            onCreateCategory={(category) =>
              mergeState({
                currentOrderedCategories: [
                  ...state.currentOrderedCategories,
                  category,
                ],
              })
            }
          />

          <Box height={300} marginTop={theme.space_md} width={300}>
            <CategoryPreferencesCard
              canEdit={false}
              canMoveLeft={false}
              canMoveRight={false}
              categories={categories}
              category={DefaultCategory.UNASSIGNED}
              categoryItems={
                state.searchText.trim() === ""
                  ? unassignedCategoryItems
                  : unassignedCategoryItems.filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(state.searchText.trim().toLowerCase())
                    )
              }
              isLoading={isLoading}
              selectedItems={state.selectedCategoryItems}
              onMoveCategory={noop}
              onMoveSelectedToThisCategory={() =>
                handleMoveSelected(DefaultCategory.UNASSIGNED)
              }
              onRemove={noop}
              onRename={noop}
              onSelect={(items) => mergeState({ selectedCategoryItems: items })}
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

type CategoryPreferencesCardProps = {
  canEdit: boolean;
  canMoveLeft: boolean;
  canMoveRight: boolean;
  categories: string[];
  category: string;
  categoryItems: CategoryItem[];
  isLoading: boolean;
  selectedItems: CategoryItem[];
  onMoveCategory: (dir: "left" | "right") => void;
  onMoveSelectedToThisCategory: () => void;
  onRemove: () => void;
  onRename: (name: string) => void;
  onSelect: (items: CategoryItem[]) => void;
};

function CategoryPreferencesCard(props: CategoryPreferencesCardProps) {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useState({
    inputValue: props.category,
    isEditing: false,
  });
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (state.isEditing) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [state.isEditing]);

  function handleSelect(item: CategoryItem) {
    if (props.isLoading) return;

    const isSelected = !!selectedCategoryItems.find(
      (selectedItem) =>
        selectedItem.dataSource === item.dataSource &&
        selectedItem.name === item.name
    );

    props.onSelect(
      isSelected
        ? props.selectedItems.filter(
            (selectedItem) =>
              !(
                selectedItem.dataSource === item.dataSource &&
                selectedItem.name === item.name
              )
          )
        : [...props.selectedItems, item]
    );
  }

  function handleSelectAll() {
    if (props.isLoading) return;

    const selectedWithCategoryRemoved = props.selectedItems.filter(
      (selectedItem) =>
        !selectedCategoryItems.find(
          (item) =>
            selectedItem.dataSource === item.dataSource &&
            selectedItem.name === item.name
        )
    );

    if (selectedCategoryItems.length > 0) {
      props.onSelect(selectedWithCategoryRemoved);
    } else {
      props.onSelect([...selectedWithCategoryRemoved, ...props.categoryItems]);
    }
  }

  function handleRename() {
    if (!canSubmit) return;
    props.onRename(state.inputValue.trim());
    mergeState({
      inputValue: state.inputValue.trim(),
      isEditing: false,
    });
  }

  const selectedCategoryItems = props.categoryItems.filter((item) =>
    props.selectedItems.find(
      (selectedItem) =>
        selectedItem.dataSource === item.dataSource &&
        selectedItem.name === item.name
    )
  );

  const canSubmit =
    state.inputValue !== "" &&
    ![
      ...props.categories,
      DefaultCategory.HIDDEN,
      DefaultCategory.PREFERRED,
      DefaultCategory.UNASSIGNED,
      "OTHER",
    ]
      .map((category) => category.toLowerCase())
      .includes(state.inputValue.trim().toLowerCase());

  function renderCardControls() {
    if (state.isEditing) {
      return (
        <Flex>
          <Button
            disabled={!canSubmit}
            iconEnd={<Icon icon={faCheck} />}
            size="tiny"
            onClick={handleRename}
          />
          <Button
            iconEnd={<Icon icon={faCancel} />}
            marginLeft={theme.space_xxs}
            size="tiny"
            onClick={() =>
              mergeState({ inputValue: props.category, isEditing: false })
            }
          />
        </Flex>
      );
    }

    return (
      <Flex>
        {props.canMoveLeft && (
          <Button
            iconEnd={<Icon icon={faChevronLeft} />}
            marginRight={theme.space_xxs}
            size="tiny"
            onClick={() => props.onMoveCategory("left")}
          />
        )}
        {props.canMoveRight && (
          <Button
            iconEnd={<Icon icon={faChevronRight} />}
            marginRight={theme.space_xxs}
            size="tiny"
            onClick={() => props.onMoveCategory("right")}
          />
        )}
        {props.canEdit && (
          <>
            <Button
              iconEnd={<Icon icon={faPencil} />}
              size="tiny"
              onClick={() => mergeState({ isEditing: true })}
            />
            <Button
              iconEnd={<Icon icon={faTrash} />}
              marginLeft={theme.space_xxs}
              size="tiny"
              onClick={props.onRemove}
            />
          </>
        )}
      </Flex>
    );
  }

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      height="100%"
      padding={theme.space_sm}
    >
      <Flex
        alignItems="center"
        flex="0 0 auto"
        justifyContent="space-between"
        paddingBottom={theme.space_xs}
      >
        <Box>
          {state.isEditing ? (
            <TextInput
              placeholder={copyText.labelMeasureManagementCategoryName}
              value={state.inputValue}
              inputRef={inputRef}
              onChange={(event) =>
                mergeState({ inputValue: event.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRename();
                }
                if (e.key === "Escape") {
                  mergeState({ inputValue: props.category, isEditing: false });
                }
              }}
            />
          ) : (
            <Text appearance="h3">{formatCategoryName(props.category)}</Text>
          )}
        </Box>

        {renderCardControls()}
      </Flex>

      <Flex
        alignItems="flex-end"
        flex="0 0 auto"
        height={30}
        justifyContent="space-between"
        paddingBottom={theme.space_xs}
        paddingRight={theme.space_xs}
      >
        {props.selectedItems.length > 0 &&
        !props.isLoading &&
        selectedCategoryItems.length !== props.selectedItems.length ? (
          <Button
            disabled={props.isLoading}
            secondary
            size="tiny"
            onClick={() => props.onMoveSelectedToThisCategory()}
          >
            {copyText.labelMeasureMoveToCategory
              .replace("%COUNT%", String(props.selectedItems.length))
              .replace("%CATEGORY%", formatCategoryName(props.category))}
          </Button>
        ) : (
          <Box />
        )}

        {props.categoryItems.length > 0 && !props.isLoading && (
          <Flex>
            <Text
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={handleSelectAll}
            >
              {selectedCategoryItems.length === 0
                ? copyText.labelMeasureSelectAll
                : copyText.labelMeasureSelectNone}
            </Text>
            <Checkbox
              checked={
                selectedCategoryItems.length === props.categoryItems.length
              }
              dashed={selectedCategoryItems.length > 0}
              onChange={noop}
              onClick={handleSelectAll}
            />
          </Flex>
        )}
      </Flex>

      {/* CATEGORY ITEMS */}
      {props.isLoading ? (
        <Flex
          direction="column"
          flex="0 1 100%"
          height="100%"
          justifyContent="space-between"
          overflowX="auto"
        >
          <Skeleton height="30px" theme={theme} />
          <Skeleton height="30px" theme={theme} />
          <Skeleton height="30px" theme={theme} />
          <Skeleton height="30px" theme={theme} />
          <Skeleton height="30px" theme={theme} />
        </Flex>
      ) : (
        <Box flex="0 1 100%" overflowX="auto">
          {props.categoryItems.length === 0 && (
            <Box height="100%" border={`1px solid ${theme.background_color}`} />
          )}

          {props.categoryItems.map((categoryItem) => (
            <Flex
              key={categoryItem.name}
              cursor="pointer"
              justifyContent="space-between"
              paddingBottom={theme.space_xxs}
              paddingRight={theme.space_xs}
              onClick={() => handleSelect(categoryItem)}
            >
              <Text truncate>{categoryItem.name}</Text>
              <Checkbox
                checked={
                  !!props.selectedItems.find(
                    (otherItem) => otherItem.name === categoryItem.name
                  )
                }
                disabled={props.isLoading}
                onChange={noop}
                onClick={(e) => e.stopPropagation()}
              />
            </Flex>
          ))}
        </Box>
      )}
    </Flex>
  );
}

type CategoryFormProps = {
  categories: string[];
  onCreateCategory: (category: string) => void;
};

function CategoryForm(props: CategoryFormProps) {
  const theme = useTheme();
  const [inputText, setInputText] = useState("");

  const canSubmit =
    inputText !== "" &&
    ![
      ...props.categories,
      DefaultCategory.HIDDEN,
      DefaultCategory.PREFERRED,
      DefaultCategory.UNASSIGNED,
      "OTHER",
    ]
      .map((category) => category.toLowerCase())
      .includes(inputText.trim().toLowerCase());

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      padding={theme.space_sm}
    >
      <Box paddingBottom={theme.space_sm}>
        <Text appearance="h3">
          {copyText.labelMeasureManagementAddCategory}
        </Text>
      </Box>

      <Box paddingBottom={theme.space_sm}>
        <TextInput
          value={inputText}
          placeholder={copyText.labelMeasureManagementCategoryName}
          onChange={(e) => setInputText(e.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;
            props.onCreateCategory(inputText);
            setInputText("");
          }}
        />
      </Box>

      <Flex justifyContent="flex-end">
        <Button
          disabled={!canSubmit}
          secondary
          onClick={() => {
            props.onCreateCategory(inputText);
            setInputText("");
          }}
        >
          {copyText.labelMeasureManagementAddCategory}
        </Button>
      </Flex>
    </Box>
  );
}

function formatCategoryName(name: string) {
  switch (name) {
    case DefaultCategory.HIDDEN:
    case DefaultCategory.PREFERRED:
    case DefaultCategory.UNASSIGNED: {
      const key: keyof typeof copyText = `defaultCategory_${name}`;
      return copyText[key];
    }

    default:
      return name;
  }
}

function getDataSourcePreferences(
  dataSource: string,
  categoryItems: CategoryItem[]
) {
  return categoryItems
    .filter(
      (item) =>
        item.dataSource === dataSource &&
        item.category !== DefaultCategory.UNASSIGNED
    )
    .map((item) => {
      return {
        category: item.category,
        value: item.name,
      };
    });
}

function getCategoryOrdersKeyedByDataSource(
  categoryItems: CategoryItem[]
): DataSourceCategoryOrder {
  const dataSources = uniq(categoryItems.map((item) => item.dataSource));

  return Object.fromEntries(
    dataSources.map((dataSource) => [
      dataSource,
      getOrderedCategories(categoryItems, dataSource),
    ])
  );
}

export function getOrderedCategories(
  categoryItems: {
    category: string;
    dataSource: string;
  }[],
  dataSource?: string
): string[] {
  const categorySet = new Set<string>();
  const orderedCategories: string[] = [];

  if (dataSource) {
    categoryItems = categoryItems.filter(
      (item) => item.dataSource === dataSource
    );
  }

  categoryItems.forEach((item) => {
    if (!categorySet.has(item.category)) {
      orderedCategories.push(item.category);
      categorySet.add(item.category);
    }
  });

  if (!categorySet.has(DefaultCategory.PREFERRED)) {
    orderedCategories.unshift(DefaultCategory.PREFERRED);
  }

  if (!categorySet.has(DefaultCategory.HIDDEN)) {
    orderedCategories.push(DefaultCategory.HIDDEN);
  }

  return orderedCategories;
}

function getDataSourcesWithChanges(
  before: CategoryItem[],
  after: CategoryItem[]
) {
  const beforeGroupedByDataSource = groupBy(before, (item) => item.dataSource);
  const afterGroupedByDataSource = groupBy(after, (item) => item.dataSource);

  const allDataSources = uniq([
    ...Object.keys(beforeGroupedByDataSource),
    ...Object.keys(afterGroupedByDataSource),
  ]);

  return allDataSources.filter((dataSource) => {
    const before = beforeGroupedByDataSource[dataSource] ?? [];
    const after = afterGroupedByDataSource[dataSource] ?? [];

    return !isEqual(before, after);
  });
}

function getOrderedCategoryItems(
  categoryItems: CategoryItem[],
  dataSourceCategoryOrder: DataSourceCategoryOrder
) {
  const itemsGroupedByDataSource = groupBy(
    categoryItems,
    (item) => item.dataSource
  );
  const dataSources = sortBy(Object.keys(itemsGroupedByDataSource));

  return dataSources
    .map((dataSource) =>
      getOrderedCategoryItemsForDataSource(
        itemsGroupedByDataSource[dataSource],
        dataSourceCategoryOrder[dataSource] ?? []
      )
    )
    .flat();
}

function getOrderedCategoryItemsForDataSource(
  categoryItems: CategoryItem[],
  categoryOrder: string[]
) {
  const itemsGroupedByCategory = groupBy(
    categoryItems,
    (item) => item.category
  );

  return categoryOrder
    .map((category) => itemsGroupedByCategory[category] ?? [])
    .map((categoryItems) =>
      sortBy(categoryItems, (item) => item.name.toLocaleLowerCase())
    )
    .flat();
}

function isDataSource(dataSource: string): dataSource is DataSource {
  return dataSource in DataSource && DataSource[dataSource] === dataSource;
}
