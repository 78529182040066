import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import React, { useMemo } from "react";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { getStringifiedDelta } from "../../alert-tracking/utils";
import copyText from "../copyText";

type Summary = {
  id: string;
  tenantDocID: string;
  createdAt: string;
  actualValue: number;
  delta: number;
  expectedValue: { lowerBound: number; upperBound: number };
  tenantName: string;
};

type TableData = Summary & { hasTenantAccess: boolean };

const columnHelper = createColumnHelper<TableData>();

interface Props {
  costAlertSummaries: Summary[];
  isLoading: boolean;
  onInteraction: (interaction: MspCostAlertTable.Interaction) => void;
}

export function MspCostAlertTable(props: Props) {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("tenantName", {
        cell: (cell) => {
          const tenantDocID = cell.row.original.tenantDocID;
          const alertID = cell.row.original.id;
          const hasAccess = cell.row.original.hasTenantAccess;

          return hasAccess ? (
            <Link
              target="_blank"
              to={{
                pathname: paths._alertTracking,
                search: `?tenant_id=${tenantDocID}&alertID=${alertID}`,
              }}
            >
              {cell.getValue()}
            </Link>
          ) : (
            <Text
              appearance="link"
              color={theme.link_color}
              onClick={() =>
                props.onInteraction({
                  type: MspCostAlertTable.INTERACTION_LINK_CLICKED,
                  tenantDocID,
                })
              }
            >
              {cell.getValue()}
            </Text>
          );
        },
        header: copyText.tableHeaderTenantName,
        size: 200,
      }),
      columnHelper.accessor("createdAt", {
        cell: (cell) => formatDate(new Date(cell.getValue()), "yyyy-MM-dd"),
        header: copyText.tableHeaderAlertedAt,
        size: 110,
      }),
      columnHelper.accessor("delta", {
        cell: (cell) => {
          return getStringifiedDelta(cell.row.original.actualValue, {
            lowerBound: cell.row.original.expectedValue.lowerBound,
            upperBound: cell.row.original.expectedValue.upperBound,
          });
        },
        header: copyText.tableHeaderDelta,
      }),
    ],
    [props.costAlertSummaries]
  );

  const data = props.costAlertSummaries.map((summary) => {
    const grant = authenticatedUser.grants.find(
      (grant) => grant.tenantDocID === summary.tenantDocID
    );

    return { ...summary, hasTenantAccess: !!grant };
  });

  return (
    <Table
      columns={columns}
      compact
      data={data}
      initialState={{ sorting: [{ id: "delta", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

MspCostAlertTable.INTERACTION_LINK_CLICKED =
  "MspChildCostAlertTable.INTERACTION_LINK_CLICKED" as const;

interface InteractionLinkClicked {
  type: typeof MspCostAlertTable.INTERACTION_LINK_CLICKED;
  tenantDocID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspCostAlertTable {
  export type Interaction = InteractionLinkClicked;
}

export default MspCostAlertTable;
