import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

type CaseEntity = Awaited<ReturnType<CoreAPIClient["getCasesByTenantID"]>>[0];

export default function useGetCasesByTenantID(
  tenantID: string,
  options?: UseQueryOptions<CaseEntity[], CoreAPIClientError>
): UseQueryResult<CaseEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.cases,
    queryFn: () => client.getCasesByTenantID(tenantID),
    ...options,
  });
}
