import { createStructParam } from "@/lib/use-query-params";
import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import useRefFn from "@ternary/api-lib/ui-lib/hooks/useRefFn";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AzureStorageBBDatum,
  azureStorageBBDimensions,
  azureStorageBBMeasures,
} from "../types";

type Props = {
  data: AzureStorageBBDatum[];
  isLoading: boolean;
  onInteraction: (interaction: AzureStorageBBTable.Interaction) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

const columnHelper = createColumnHelper<AzureStorageBBDatum>();

function AzureStorageBBTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cost",
    })
  );

  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    () => [
      columnHelper.accessor(azureStorageBBDimensions.storageAccount, {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AzureStorageBBTable.INTERACTION_FILTER_CLICKED,
                filterKey: azureStorageBBDimensions.storageAccount,
                filterValue: getValue(),
              })
            }
          >
            <Tooltip content={getValue()}>
              {getValue() || copyText.azureComputeTableNull}
            </Tooltip>
          </Text>
        ),
        header: copyText.azureStorageBBTableHeader_storageAccount,
        size: 180,
      }),
      columnHelper.accessor(azureStorageBBDimensions.projectId, {
        cell: ({ getValue }) => (
          <Text
            color={theme.primary_color_text}
            truncate
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AzureStorageBBTable.INTERACTION_FILTER_CLICKED,
                filterKey: azureStorageBBDimensions.projectId,
                filterValue: getValue(),
              })
            }
          >
            <Tooltip content={getValue()}>
              {getValue() || copyText.azureComputeTableNull}
            </Tooltip>
          </Text>
        ),
        header: copyText.azureStorageBBTableHeader_projectId,
        size: 160,
      }),
      columnHelper.accessor(azureStorageBBMeasures.containerCount, {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureStorageBBTableHeader_containerCount,
        meta: { align: "right" },
        sortDescFirst: true,
        size: 160,
      }),
      columnHelper.accessor(azureStorageBBMeasures.cost, {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.azureStorageBBTableHeader_cost,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(azureStorageBBMeasures.blobSizeBytes, {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.azureStorageBBTableHeader_blobSizeBytes,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(azureStorageBBMeasures.transactions, {
        cell: ({ getValue }) => formatNumber(getValue()),
        header: copyText.azureStorageBBTableHeader_transactions,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(azureStorageBBMeasures.bytesDownloaded, {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.azureStorageBBTableHeader_bytesDownloaded,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
      columnHelper.accessor(azureStorageBBMeasures.bytesUploaded, {
        cell: ({ getValue }) => prettyBytes(getValue()),
        header: copyText.azureStorageBBTableHeader_bytesUploaded,
        meta: { align: "right" },
        sortDescFirst: true,
      }),
    ],
    []
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.data}
      initialState={{ sorting: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

AzureStorageBBTable.INTERACTION_FILTER_CLICKED =
  `AzureStorageBBTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AzureStorageBBTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AzureStorageBBDatum;
  filterValue: AzureStorageBBDatum[keyof AzureStorageBBDatum];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureStorageBBTable {
  export type Interaction = InteractionFilterClicked;
}

export default AzureStorageBBTable;
