import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CostAssistGates = ReturnType<typeof costAssistGates>;

export default function costAssistGates(params: GateGetterParameters) {
  const canCreateBills = params._permissionsIncludeAllOf([
    Permission.CREATE_COST_COMPARE_BILL,
  ]);

  const canCreateRampPlans = params._permissionsIncludeAllOf([
    Permission.CREATE_RAMP_PLAN,
  ]);

  const canCreateReallocations = params._permissionsIncludeAllOf([
    Permission.CREATE_REALLOCATION,
  ]);

  const canDeleteRampPlans = params._permissionsIncludeAllOf([
    Permission.DELETE_RAMP_PLAN,
  ]);

  const canDeleteReallocations = params._permissionsIncludeAllOf([
    Permission.DELETE_REALLOCATION,
  ]);

  const canExecuteReallocations = params._permissionsIncludeAllOf([
    Permission.TRIGGER_REALLOCATION,
  ]);

  const canGenerateTakeoutReport = params._permissionsIncludeAllOf([
    Permission.READ_COST_COMPARE_BILLS,
    Permission.GENERATE_COST_COMPARE_TAKEOUT_REPORT,
  ]);

  const canListBills = params._permissionsIncludeAllOf([
    Permission.READ_COST_COMPARE_BILLS,
  ]);

  const canListRampPlans = params._permissionsIncludeAllOf([
    Permission.READ_RAMP_PLANS,
  ]);

  const canListReallocationJobs = params._permissionsIncludeAllOf([
    Permission.READ_REALLOCATION_JOBS,
  ]);

  const canListReallocations = params._permissionsIncludeAllOf([
    Permission.READ_REALLOCATIONS,
  ]);

  const canUpdateRampPlans = params._permissionsIncludeAllOf([
    Permission.UPDATE_RAMP_PLAN,
  ]);

  const canUpdateReallocations = params._permissionsIncludeAllOf([
    Permission.UPDATE_REALLOCATION,
  ]);

  const costAssistGates = {
    canCreateBills,
    canCreateRampPlans,
    canCreateReallocations,
    canDeleteReallocations,
    canExecuteReallocations,
    canGenerateTakeoutReport,
    canListBills,
    canListRampPlans,
    canListReallocationJobs,
    canListReallocations,
    canUpdateRampPlans,
    canUpdateReallocations,
  };

  const canViewCostAssist = Object.values(costAssistGates).every(
    (gate) => gate === true
  );

  return {
    ...costAssistGates,
    canDeleteRampPlans,
    canViewCostAssist,
    canViewCostCompare: params._featureFlagsIncludeAllOf([
      FeatureFlag.COST_COMPARE,
    ]),
    getCanDeleteSpecificBill: (billCreatedBy: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_COST_COMPARE_BILL]) ||
      Boolean(billCreatedBy === params.authenticatedUser.email),
  };
}
