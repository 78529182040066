import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";
import queryKeys from "./queryKeys";

type SlackIntegrationEntity = Awaited<
  ReturnType<CoreAPIClient["getSlackIntegrationByTenantID"]>
>;

type Options = UseQueryOptions<SlackIntegrationEntity, CoreAPIClientError>;
type Result = UseQueryResult<SlackIntegrationEntity, CoreAPIClientError>;

export default function useGetSlackIntegrationByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.slackIntegration,
    queryFn: () => client.getSlackIntegrationByTenantID(tenantID),
    meta: { errorMessage: copyText.errorLoadingSlackIntegrationMessage },
    retry: (failureCount, error) => {
      // No need to retry if user hasn't set up integration yet.
      if (failureCount === 0 && error.reason === "NOT_FOUND") {
        return false;
      }

      // If different failure reason we still want to retry 2 more times.
      return failureCount > -1 && failureCount < 2;
    },
    ...options,
  });
}
