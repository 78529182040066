import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Tabs from "@/ui-lib/components/Tabs";
import getMergeState from "@/utils/getMergeState";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { get, isEmpty, isEqual } from "lodash";
import React, { FormEvent, useState } from "react";
import Form from "../../../../ui-lib/components/Form";
import copyText from "../../copyText";
import SnowflakeIntegrationFormAccount from "./SnowflakeIntegrationFormAccount";
import SnowflakeIntegrationFormBasic from "./SnowflakeIntegrationFormBasic";

export type MemberAccount = {
  id: string | null;
  name: string;
  passwordPreview: string;
  role: string;
  username: string;
  warehouse: string;
};

export type UpdateAccount = {
  id: string | null;
  name?: string;
  passwordPreview?: string;
  role?: string;
  username?: string;
  warehouse?: string;
};

type Validation = {
  error: string;
  name: string;
  success: boolean;
};

type SnowflakeIntegration = {
  config: {
    accounts: MemberAccount[];
    orgName: string;
  };
  name: string;
  validations: Validation[];
};

type Container = {
  component: JSX.Element;
  label: string;
  value: string;
};

interface Props {
  isLoading: boolean;
  integration?: SnowflakeIntegration;
  onInteraction: (interaction: SnowflakeIntegrationForm.Interaction) => void;
}
interface State {
  accounts: MemberAccount[];
  containers: Container[];
  isUpdate: boolean;
  name: string;
  orgName: string;
  tab: string;
}

const initialState: State = {
  accounts: [],
  containers: [],
  isUpdate: false,
  name: "",
  orgName: "",
  tab: "0",
};

export function SnowflakeIntegrationForm(props: Props): JSX.Element {
  const integration = props.integration;
  const [state, setState] = useState(
    integration
      ? {
          ...initialState,
          accounts: [...integration.config.accounts],
          containers: renderContainers(integration),
          isUpdate: true,
          name: integration.name,
          orgName: integration.config.orgName,
        }
      : {
          ...initialState,
          containers: [
            {
              component: (
                <SnowflakeIntegrationFormBasic
                  isLoading={false}
                  onChange={handleChangeBasic}
                />
              ),
              label: copyText.cloudAdminBasicInfo,
              value: "0",
            },
            {
              component: (
                <SnowflakeIntegrationFormAccount
                  tabNumber={1}
                  isLoading={props.isLoading}
                  onChange={handleChange}
                />
              ),
              label: copyText.integrationSnowflakeFormTabTitle.replace(
                "%number%",
                "1"
              ),
              value: `1`,
            },
          ],
        }
  );
  const mergeState = getMergeState(setState);

  function renderContainers(integration: SnowflakeIntegration): Container[] {
    const validation = integration.validations.find(
      (validation) =>
        validation.name === CloudCapability.ACCOUNT_USAGE_SNOWFLAKE
    );
    const error = validation?.error ? validation?.error : null;
    const errorMessages = error?.split("*").slice(1);

    const existingForms = integration.config.accounts.map((account, i) => ({
      component: (
        <SnowflakeIntegrationFormAccount
          tabNumber={i + 1}
          errorMessage={errorMessages && errorMessages[i]}
          isLoading={props.isLoading}
          integration={{
            orgName: integration.config.orgName,
            account,
          }}
          onChange={handleChange}
        />
      ),
      label: copyText.integrationSnowflakeFormTabTitle.replace(
        "%number%",
        (i + 1).toString()
      ),
      value: `${i + 1}`,
    }));

    return [
      {
        component: (
          <SnowflakeIntegrationFormBasic
            isLoading={props.isLoading}
            organizationName={integration.config.orgName}
            integrationName={integration.name}
            onChange={handleChangeBasic}
          />
        ),
        label: copyText.cloudAdminBasicInfo,
        value: "0",
      },
      ...existingForms,
    ];
  }

  function canUpdate(): boolean {
    const completedBasics =
      state.name.trim().length > 0 && state.orgName.trim().length > 0;

    let completedAccounts = false;

    state.accounts.forEach((account) => {
      if (
        account.name.trim().length > 0 &&
        account.passwordPreview.trim().length > 0 &&
        account.role.trim().length > 0 &&
        account.username.trim().length > 0 &&
        account.warehouse.trim().length > 0
      ) {
        completedAccounts = true;
      } else {
        completedAccounts = false;
      }
    });

    const hasFormChanges =
      state.name !== integration?.name ||
      state.orgName !== integration.config.orgName ||
      !isEqual(state.accounts, integration.config.accounts);

    return hasFormChanges && completedBasics && completedAccounts;
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (props.integration) {
      const integration = props.integration.config;
      const removedAccounts = integration.accounts.filter((account) => {
        if (
          account.id !== null &&
          !state.accounts.some((act) => act.id === account.id)
        ) {
          return account;
        }
      });

      const updatedAccounts = state.accounts.reduce(
        (accum: UpdateAccount[], account, i) => {
          if (!removedAccounts.some((removed) => removed.id === account.id)) {
            const changes = getAccountChanges(integration.accounts[i], account);
            if (changes) {
              accum.push({
                id: account.id,
                ...changes,
              });
            }
          }

          return accum;
        },
        []
      );

      const allAccounts = [
        ...updatedAccounts,
        ...removedAccounts.map((removed) => ({ id: removed.id })),
      ];

      props.onInteraction({
        type: SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        organizationName: state.orgName,
        integrationName: state.name,
        ...(allAccounts.length > 0
          ? {
              accounts: allAccounts,
            }
          : undefined),
      });
    } else {
      props.onInteraction({
        type: SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        organizationName: state.orgName,
        integrationName: state.name,
        accounts: state.accounts,
      });
    }
  }

  function handleChange(tabNumber: number, account: MemberAccount): void {
    const index = tabNumber - 1;

    setState((currentState) => {
      if (!currentState.accounts.length) {
        return {
          ...currentState,
          accounts: [account],
        };
      }
      const accouts = [...currentState.accounts];
      accouts[index] = account;
      return {
        ...currentState,
        accounts: accouts,
      };
    });
  }

  function handleChangeBasic(orgName: string, integrationName: string) {
    mergeState({
      orgName,
      name: integrationName,
    });
  }

  function handleAddTab(): void {
    const accountNumber = state.containers.length;
    const currentContainers = state.containers;
    currentContainers.push({
      component: (
        <SnowflakeIntegrationFormAccount
          tabNumber={accountNumber}
          isLoading={false}
          onChange={handleChange}
        />
      ),
      label: copyText.integrationSnowflakeFormTabTitle.replace(
        "%number%",
        `${accountNumber}`
      ),
      value: `${accountNumber}`,
    });

    mergeState({ containers: [...state.containers], tab: `${accountNumber}` });
  }

  function handleRemoveTab(): void {
    const containers = state.containers.splice(0, state.containers.length - 1);
    const accounts =
      state.accounts.length >= containers.length
        ? state.accounts.splice(0, state.accounts.length - 1)
        : state.accounts;

    mergeState({
      tab: `${containers.length - 1}`,
      containers,
      accounts,
    });
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: SnowflakeIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text appearance="h4">
            {props.integration
              ? copyText.integrationSnowflakeFormTitleUpdate
              : copyText.integrationSnowflakeFormTitleCreate}
          </Text>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" maxWidth={450}>
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            marginBottom={"1rem"}
          >
            {state.containers.length > 2 ? (
              <Button
                marginLeft={"1rem"}
                size="small"
                onClick={handleRemoveTab}
              >
                {copyText.integrationSnowflakeRemoveAccountButtonLabel}
              </Button>
            ) : null}
            <Button
              iconEnd={<Icon icon={faPlus} />}
              marginLeft={"1rem"}
              primary
              size="small"
              onClick={handleAddTab}
            >
              {copyText.integrationSnowflakeAddAccountButtonLabel}
            </Button>
          </Flex>
          <Form>
            <Tabs
              activeValue={state.tab}
              tabs={state.containers}
              onSelect={(tab: string) => mergeState({ tab })}
            />
          </Form>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canUpdate() || props.isLoading}
          fullWidth
          primary
          onClick={handleSubmit}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getAccountChanges<ChangeObj extends Record<string, unknown>>(
  entity: Record<string, unknown>,
  object: ChangeObj
) {
  const changes = Object.entries(object).reduce(
    (accum, [key, value]) => ({
      ...accum,
      ...(value === undefined || isEqual(value, get(entity, key))
        ? {}
        : { [key]: value }),
    }),
    {}
  );
  if (isEmpty(changes)) return null;
  return changes;
}

SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  `SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE` as const;

SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  `SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE` as const;

SnowflakeIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED =
  `SnowflakeIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

SnowflakeIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `SnowflakeIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

interface InteractionRefreshButtonClicked {
  type: typeof SnowflakeIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED;
  integrationID: string;
}

interface InteractionSubmitButtonClickedCreate {
  type: typeof SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  accounts: MemberAccount[];
  integrationName: string;
  organizationName: string;
}
interface InteractionSubmitButtonClickedUpdate {
  type: typeof SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  accounts?: {
    id: string | null;
    name?: string;
    passwordPreview?: string;
    role?: string;
    username?: string;
    warehouse?: string;
  }[];
  integrationName?: string;
  organizationName?: string;
}

interface InteractionCancelButtonClicked {
  type: typeof SnowflakeIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SnowflakeIntegrationForm {
  export type Interaction =
    | InteractionRefreshButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate
    | InteractionCancelButtonClicked;
}

export default SnowflakeIntegrationForm;
