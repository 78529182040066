import React from "react";
import { DateRange } from "../../../analytics/utils";
import { DurationType, TimeGranularity } from "../../../constants/enums";
import { Dimension, Measure, RawData, SortRule } from "../types";
import CrossSectionalDataTable from "./CrossSectionalDataTable";
import TimeSeriesTable from "./TimeSeriesDataTable";

type Interaction =
  | TimeSeriesTable.Interaction
  | CrossSectionalDataTable.Interaction;

interface Props {
  creditTypes?: string[];
  compareDateRange?: DateRange;
  compareType?: DurationType | null;
  data: RawData[];
  dateRange?: DateRange;
  dimensions: Dimension[];
  footer?: boolean;
  isCumulativeMode?: boolean;
  isFiscalMode?: boolean;
  isGroupable?: boolean;
  isInvoiceMonthMode?: boolean;
  isColumnResizing?: boolean;
  isLoading: boolean;
  isServer?: boolean;
  limit?: number | null;
  maxRows?: number;
  measures: Measure[];
  pinnedColumns?: number;
  reverse?: boolean;
  selectedMeasures?: Measure[];
  sortable?: boolean;
  sortRule?: SortRule;
  timeSeriesGranularity?: TimeGranularity;
  onInteraction?: (interaction: Interaction) => void;
}

export default function DataTable(props: Props): JSX.Element {
  if (props.timeSeriesGranularity) {
    return (
      <TimeSeriesTable
        {...props}
        compact
        timeSeriesGranularity={props.timeSeriesGranularity}
      />
    );
  }

  return (
    <CrossSectionalDataTable
      {...props}
      isColumnResizable={props.isColumnResizing}
    />
  );
}
