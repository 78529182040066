import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { CreateDimensionPreferencesParams } from "../../../api/core/types";

type Preference = Awaited<
  ReturnType<CoreAPIClient["createDimensionPreferences"]>
>[0];

type Options = UseMutationOptions<
  Preference[],
  CoreAPIClientError,
  CreateDimensionPreferencesParams
>;

type Result = UseMutationResult<
  Preference[],
  CoreAPIClientError,
  CreateDimensionPreferencesParams
>;

export default function useCreateDimensionPreferences(
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.createDimensionPreferences(params),
    ...options,
  });
}
