import { getReportChartConfiguration } from "@ternary/api-lib/analytics/getReportChartConfiguration";
import { RawData } from "@ternary/api-lib/analytics/types";
import {
  ChartType,
  CustomMetricDataType,
  JobStatus,
} from "@ternary/api-lib/constants/enums";
import AreaChart from "@ternary/api-lib/ui-lib/charts/AreaChart";
import KPIChart from "@ternary/api-lib/ui-lib/charts/KPIChart";
import DataTable from "@ternary/api-lib/ui-lib/charts/Table/DataTable";
import LineChart from "@ternary/web-ui-lib/charts/LineChart";
import PieChart from "@ternary/web-ui-lib/charts/PieChart";
import { StackedBarChart } from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import { isEqual, noop } from "lodash";
import React from "react";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGetCustomMetricsByTenantID from "../hooks/useGetCustomMetricsByTenantID";
import useGetDatadogIntegrationByTenantID from "../hooks/useGetDatadogIntegrationByTenantID";
import { ReportDataConfig } from "../types";
import { getTooltipUnitFormatter } from "../utils";

type Props = {
  data: RawData[];
  isLoading?: boolean;
  report: ReportDataConfig;
  reverse?: boolean;
  showLegend?: boolean;
  showToolTip?: boolean;
  tooltipLimit?: number;
  onInteraction: (interaction: ReportChart.Interaction) => void;
};

function ReportChart(props: Props) {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();

  const { data: integration, isLoading: isLoadingIntegration } =
    useGetDatadogIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  const { data: _customMetrics, isLoading: isLoadingCustomMetrics } =
    useGetCustomMetricsByTenantID(authenticatedUser.tenant.id);

  const customMetricMap = _customMetrics?.reduce((accum, metric) => {
    if (metric.status === JobStatus.FAILURE) {
      return accum;
    }

    accum[metric.name] = {
      groupings: Object.entries(metric.schema).reduce(
        (accum: string[], [key, value]) => {
          if (value === CustomMetricDataType.DIMENSION) {
            accum.push(key);
          }

          return accum;
        },
        []
      ),
      name: metric.name,
    };

    return accum;
  }, {});

  const combinedExternalMetrics = {
    ...integration?.metrics,
    ...customMetricMap,
  };

  const {
    comparisonMode,
    dimensions,
    impactMode,
    isInvoiceMonthMode,
    measures,
    timeGranularity,
    xAxisKey,
  } = getReportChartConfiguration({
    report: props.report,
    integration: { metrics: combinedExternalMetrics },
  });

  const isLoading =
    props.isLoading || isLoadingCustomMetrics || isLoadingIntegration;

  const tooltipFormatter = getTooltipUnitFormatter({
    dataSource: props.report.dataSource,
    dimensions,
    measures,
  });

  const disableDrilldown = isEqual(
    props.report.dimensions,
    props.report.filters.map(({ name }) => name)
  );

  switch (props.report.chartType) {
    case ChartType.LINE: {
      return (
        <LineChart
          activityTracker={activityTracker}
          data={props.data}
          dimensions={dimensions}
          disableDrilldown={disableDrilldown}
          excludeOther={props.report.excludeOther}
          isComparisonMode={comparisonMode}
          isDataSorted={!isInvoiceMonthMode}
          isEcoImpactMode={impactMode}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          limit={props.report.limit}
          measures={measures}
          showLegend={props.showLegend}
          showTooltip={props.showToolTip}
          timeSeriesGranularity={timeGranularity}
          tooltipFormatter={tooltipFormatter}
          tooltipLimit={props.tooltipLimit}
          xAxisKey={xAxisKey}
          onInteraction={props.onInteraction}
        />
      );
    }
    case ChartType.CLUSTERED_BAR:
    case ChartType.STACKED_BAR: {
      return (
        <StackedBarChart
          activityTracker={activityTracker}
          clustered={props.report.chartType === ChartType.CLUSTERED_BAR}
          data={props.data}
          dimensions={dimensions}
          disableDrilldown={disableDrilldown}
          excludeOther={props.report.excludeOther}
          isComparisonMode={comparisonMode}
          isDataSorted={!isInvoiceMonthMode}
          isEcoImpactMode={impactMode}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          limit={props.report.limit}
          measures={measures}
          showLegend={props.showLegend}
          showTooltip={props.showToolTip}
          timeSeriesGranularity={timeGranularity}
          tooltipFormatter={tooltipFormatter}
          tooltipLimit={props.tooltipLimit}
          xAxisKey={xAxisKey}
          onInteraction={props.onInteraction}
        />
      );
    }
    case ChartType.KPI: {
      return (
        <KPIChart
          data={props.data}
          durationType={props.report.durationType}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          isServerChart={false}
          measures={measures}
        />
      );
    }
    case ChartType.PIE: {
      return (
        <PieChart
          data={props.data}
          dimensions={dimensions}
          disableDrilldown={disableDrilldown}
          isEcoImpactMode={impactMode}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          limit={props.report.limit}
          measures={measures}
          showFullLabel
          showLegend={props.showLegend}
          showTooltip={props.showToolTip}
          onInteraction={props.onInteraction}
        />
      );
    }
    case ChartType.TABLE: {
      const tableProps = {
        ...(props.report.sortRule ? { sortRule: props.report.sortRule } : {}),
        ...(props.report.timeGranularity
          ? { timeSeriesGranularity: props.report.timeGranularity }
          : {}),
      };

      return (
        <Box height="100%" overflow="auto" width="100%">
          <DataTable
            data={props.data}
            dimensions={dimensions}
            footer
            isCumulativeMode={props.report.isCumulative}
            isFiscalMode={authenticatedUser.settings.fiscalMode}
            isGroupable
            isInvoiceMonthMode={isInvoiceMonthMode}
            isLoading={isLoading}
            measures={measures}
            sortable
            sortRule={props.report.sortRule ?? undefined}
            onInteraction={noop}
            {...tableProps}
          />
        </Box>
      );
    }
    case ChartType.STACKED_AREA: {
      return (
        <AreaChart
          activityTracker={activityTracker}
          data={props.data}
          dimensions={dimensions}
          disableDrilldown={disableDrilldown}
          excludeOther={props.report.excludeOther}
          isDataSorted={!isInvoiceMonthMode}
          isEcoImpactMode={impactMode}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          limit={props.report.limit}
          measures={measures}
          showLegend={props.showLegend}
          showTooltip={props.showToolTip}
          stacked
          timeSeriesGranularity={timeGranularity}
          tooltipFormatter={tooltipFormatter}
          tooltipLimit={props.tooltipLimit}
          xAxisKey={xAxisKey}
          onInteraction={props.onInteraction}
        />
      );
    }
    case ChartType.AREA: {
      return (
        <AreaChart
          activityTracker={activityTracker}
          data={props.data}
          dimensions={dimensions}
          disableDrilldown={disableDrilldown}
          hideTotal
          isEcoImpactMode={impactMode}
          isFiscalMode={authenticatedUser.settings.fiscalMode}
          isLoading={isLoading}
          limit={props.report.limit}
          measures={measures}
          mergeMeasures
          showLegend={props.showLegend}
          showTooltip={props.showToolTip}
          timeSeriesGranularity={timeGranularity}
          tooltipFormatter={tooltipFormatter}
          tooltipLimit={props.tooltipLimit}
          xAxisKey={xAxisKey}
          onInteraction={props.onInteraction}
        />
      );
    }
    default:
      return null;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportChart {
  export type Interaction =
    | LineChart.Interaction
    | PieChart.Interaction
    | AreaChart.Interaction
    | StackedBarChart.Interaction;
}

export default ReportChart;
