import useGatekeeper from "@/hooks/useGatekeeper";
import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React from "react";
import copyText from "../copyText";
import {
  CommitmentPeriod,
  NetCostByDateDimensional,
  NetCostByRampPlanID,
  RampPlanBreakpoint,
} from "../types";
import RampPlansTable from "./RampPlansTable";

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface Props {
  loading: boolean;
  loadingAllActuals: boolean;
  loadingDelete: boolean;
  previousNetCostByRampPlanID: NetCostByRampPlanID;
  rampPlanCSVQueryResultList: {
    rampPlanID: string;
    data: NetCostByDateDimensional[] | undefined;
    isLoading: boolean;
    selectedPricing: string;
  }[];
  rampPlans: RampPlan[];
  selectedRampPlanID: string | undefined;
  onInteraction: (
    interaction: RampPlansTableSection.Interaction | RampPlansTable.Interaction
  ) => void;
}
export function RampPlansTableSection(props: Props): JSX.Element {
  const theme = useTheme();

  const gatekeeper = useGatekeeper();

  function handleClickCreate(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();

    props.onInteraction({
      type: RampPlansTableSection.INTERACTION_CREATE_BUTTON_CLICKED,
    });
  }

  return (
    <Box width="100%">
      <Flex
        justifyContent="flex-end"
        marginBottom={theme.space_md}
        width="100%"
      >
        <Button
          iconStart={<FontAwesomeIcon icon={faPlus} />}
          locked={!gatekeeper.canCreateRampPlans}
          primary
          onClick={handleClickCreate}
        >
          {copyText.tableSectionCreateButton}
        </Button>
      </Flex>
      <RampPlansTable
        loading={props.loading}
        loadingAllActuals={props.loadingAllActuals}
        loadingDelete={props.loadingDelete}
        rampPlanCSVQueryResultList={props.rampPlanCSVQueryResultList}
        rampPlans={props.rampPlans}
        previousNetCostByRampPlanID={props.previousNetCostByRampPlanID}
        selectedRampPlanID={props.selectedRampPlanID}
        onInteraction={props.onInteraction}
      />
    </Box>
  );
}

RampPlansTableSection.INTERACTION_CREATE_BUTTON_CLICKED =
  `RampPlansTableSection.INTERACTION_CREATE_BUTTON_CLICKED` as const;

interface InteractionCreateButtonClicked {
  type: typeof RampPlansTableSection.INTERACTION_CREATE_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RampPlansTableSection {
  export type Interaction = InteractionCreateButtonClicked;
}

export default RampPlansTableSection;
