import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { add, format, sub } from "date-fns";
import React, { forwardRef } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import TextInput, { Size, Props as TextInputProps } from "./TextInput";

const DatePickerWrapper = styled("div")<{
  size?: Size;
  timeSelectWidth?: string;
}>(({ size, theme, timeSelectWidth }) => ({
  ".react-datepicker": {
    backgroundColor: theme.panel_backgroundColor,
    border: "none",
    borderRadius: theme.borderRadius_2,
    boxShadow: `0px 4px 8px ${theme.box_shadow}`,
    fontFamily: "Ternary",
    width: "100%",
  },

  ".react-datepicker-wrapper": {
    display: "inline-block",
    width: "100%",
  },

  ".react-datepicker__input-container": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },

  ".react-datepicker__close-icon": {
    backgroundColor: theme.text_color_secondary,
    border: "none",
    position: "relative",
    left: "-25px",
  },

  ".react-datepicker-popper": {
    zIndex: theme.zIndex_1600,
  },

  ".react-datepicker__day": {
    alignItems: "center",
    borderRadius: theme.borderRadius_2,
    color: theme.text_color,
    cursor: "pointer",
    display: "flex",
    height: (() => {
      if (size === "small") return "28px";
      if (size === "large") return "36px";
      return "32px";
    })(),
    justifyContent: "center",
    width: (() => {
      if (size === "small") return "28px";
      if (size === "large") return "36px";
      return "32px";
    })(),

    "&:hover": {
      backgroundColor: theme.secondary_color_background,
    },
  },

  ".react-datepicker__day--outside-month": {
    color: theme.text_color_secondary,
  },

  ".react-datepicker__day--today": {
    color: theme.primary_color_text,
    fontWeight: "normal",
  },

  ".react-datepicker__day--selected": {
    backgroundColor: `${theme.primary_color_background} !important`,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__day-name": {
    color: theme.date_picker_day_name_color,
    display: "flex",
    justifyContent: "center",
    width: "32px",
  },

  ".react-datepicker__day-names": {
    backgroundColor: theme.panel_backgroundColor,
    display: "flex",
    fontSize: "12px",
    justifyContent: "space-between",
    paddingTop: theme.space_xs,
  },

  ".react-datepicker__header": {
    backgroundColor: `${theme.panel_backgroundColor}`,
    border: "none",
    padding: 0,
  },

  ".react-datepicker__triangle": {
    display: "none",
  },

  ".react-datepicker__week": {
    display: "flex",
    justifyContent: "space-around",
  },

  ".react-datepicker-year-header": {
    fontWeight: theme.fontWeight_bold,
    textAlign: "center",
  },

  ".react-datepicker__navigation": {
    outline: "none",
    cursor: "pointer",
    position: "absolute",
    border: "transparent",
    textIndent: "-999em",
    backgroundColor: "transparent",
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    paddingLeft: "0.25rem",
    paddingRight: "0.25rem",
    lineHeight: "unset",
    height: "0.5rem",
    transform: "translateX(-25%) rotate(45deg)",
    ":active": {
      ":hover": {
        borderWidth: "3px",
        borderColor: theme.text_color,
      },
    },
  },

  ".react-datepicker__navigation--previous": {
    top: "10px",
    left: "20px",
    borderLeft: `2px solid ${theme.text_color}`,
    borderBottom: `2px solid ${theme.text_color}`,
  },

  ".react-datepicker__navigation--next": {
    top: "10px",
    right: "10px",
    borderRight: `2px solid ${theme.text_color}`,
    borderTop: `2px solid ${theme.text_color}`,
  },

  ".react-datepicker__month": {
    color: theme.text_color,
    fontSize: theme.fontSize_ui,
    paddingBottom: theme.space_xs,
    paddingTop: theme.space_xs,
  },

  ".react-datepicker__month-text": {
    alignItems: "center",
    borderRadius: theme.borderRadius_2,
    cursor: "pointer",
    display: "flex",
    height: (() => {
      if (size === "small") return "28px";
      if (size === "large") return "36px";
      return "32px";
    })(),
    justifyContent: "center",

    "&:hover": {
      backgroundColor: theme.secondary_color_background,
    },
  },

  ".react-datepicker__month-text--selected": {
    backgroundColor: `${theme.primary_color_background} !important`,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__month-container": {
    width: "100%",
  },

  ".react-datepicker__month-wrapper": {
    alignItems: "center",
    display: "flex",
    height: "32px",
    justifyContent: "space-around",
    marginBottom: theme.space_xs,
    width: "100%",
  },

  ".react-datepicker-time__header": {
    display: "none",
  },

  ".react-datepicker.react-datepicker--time-only": {
    width: (() => {
      if (timeSelectWidth) {
        return timeSelectWidth;
      }
      return "inherit";
    })(),
  },

  ".react-datepicker__time-container": {
    backgroundColor: theme.input_background_color,
    border: "none",
    borderRadius: theme.borderRadius_2,
    boxShadow: `0 4px 8px ${theme.box_shadow}`,
    padding: `0 ${theme.space_xxs}`,
    width: "100%",
  },

  ".react-datepicker__time .react-datepicker__time-box": {
    textAlign: "left",
    width: "100%",
  },

  ".react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item":
    {
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.input_background_color,
      borderRadius: theme.borderRadius_2,
      color: theme.text_color,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      margin: `${theme.space_xxs} 0`,
      transition: "none",

      "&:hover": {
        backgroundColor: theme.secondary_color_background,
      },
    },

  ".react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected":
    {
      backgroundColor: theme.primary_color_background,
      color: theme.text_color_inverse,
      fontWeight: "normal",

      "&:hover": {
        backgroundColor: theme.primary_color_background,
      },
    },

  ".react-datepicker__year": {
    color: theme.text_color,
    fontSize: theme.fontSize_ui,
    paddingBottom: theme.space_xs,
    paddingTop: theme.space_xs,
  },

  ".react-datepicker__year-text": {
    alignItems: "center",
    borderRadius: theme.borderRadius_2,
    cursor: "pointer",
    display: "flex",
    height: "32px",
    justifyContent: "center",

    "&:hover": {
      backgroundColor: theme.secondary_color_background,
    },
  },

  ".react-datepicker__year-text--selected": {
    backgroundColor: `${theme.primary_color_background} !important`,
    color: theme.text_color_inverse,
  },

  ".react-datepicker__year-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    marginBottom: theme.space_xs,
    paddingRight: theme.space_xxs,
    paddingLeft: theme.space_xxs,
  },

  ".react-datepicker__year-text--disabled, .react-datepicker__day--disabled": {
    color: theme.text_color_disabled,
    cursor: "not-allowed",
  },

  ".react-datepicker__month--disabled": {
    backgroundColor: theme.background_color_disabled,
    color: theme.text_color_disabled,
    cursor: "not-allowed",
  },

  "& .react-datepicker__aria-live": {
    display: "block",
    height: 0,
    overflow: "hidden",
    position: "absolute",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InputRef = forwardRef<HTMLInputElement, TextInputProps>((props, _ref) => {
  return <TextInput {...props} />;
});

InputRef.displayName = "InputRef";

interface Props extends ReactDatePickerProps<string> {
  size?: Size;
  timeSelectWidth?: string;
  onClick?: () => void;
}

export default function DatePicker(props: Props): JSX.Element {
  return (
    <DatePickerWrapper
      size={props.size}
      timeSelectWidth={props.timeSelectWidth}
      onClick={props.onClick}
    >
      <ReactDatePicker
        customInput={<InputRef size={props.size} />}
        dateFormat={props.dateFormat ?? "MM/dd/yyyy"}
        renderCustomHeader={(params) => (
          <CustomHeader
            {...params}
            showMonthYearPicker={props.showMonthYearPicker}
            showYearPicker={props.showYearPicker}
          />
        )}
        {...props}
      />
    </DatePickerWrapper>
  );
}

interface CustomHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  decreaseYear: () => void;
  increaseMonth: () => void;
  increaseYear: () => void;
  changeYear: (year: number) => void;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
}

function CustomHeader(props: CustomHeaderProps): JSX.Element {
  const theme = useTheme();

  function handleClickLeft() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(sub(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.decreaseYear();
      return;
    }

    props.decreaseMonth();
  }

  function handleClickRight() {
    if (props.showYearPicker) {
      props.changeYear(Number(format(add(props.date, { years: 12 }), "yyyy")));
      return;
    }

    if (props.showMonthYearPicker) {
      props.increaseYear();
      return;
    }

    props.increaseMonth();
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={`${theme.borderRadius_2} ${theme.borderRadius_2} 0 0`}
      justifyContent="space-between"
      padding={theme.space_sm}
    >
      <Button
        iconEnd={<Icon icon={faChevronLeft} />}
        size="tiny"
        type="button"
        onClick={handleClickLeft}
      />
      <Text>
        {formatDate(
          props.date,
          props.showMonthYearPicker ? "yyyy" : "MMMM do, yyyy"
        )}
      </Text>
      <Button
        iconEnd={<Icon icon={faChevronRight} />}
        size="tiny"
        type="button"
        onClick={handleClickRight}
      />
    </Flex>
  );
}
