import useGetRawData from "@/api/analytics/useGetRawData";
import useGetRecommendationsByTenantID from "@/api/core/hooks/useGetRecommendationsByTenantID";
import useGatekeeper from "@/hooks/useGatekeeper";
import { DateHelper } from "@/lib/dates";
import Dropdown from "@/ui-lib/components/Dropdown";
import { Option } from "@/ui-lib/components/SelectDropdown";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  BinaryFilter,
  RawData,
  UnaryFilter,
} from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  DataSource,
  DurationType,
  GcpCommitmentDurationType,
  GcpCommitmentScope,
  GcpCommitmentServiceType,
  GcpCommitmentType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { CudRecommendationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { keyBy } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { AuthenticatedUserEntity } from "../../../../api/core/types";
import useUpdateUserTenantSetting from "../../../../api/core/useUpdateUserTenantSetting";
import SideDrawer from "../../../../components/SideDrawer";
import paths from "../../../../constants/paths";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../lib/react-router";
import DateRangeControls from "../../../../ui-lib/components/DateRangeControls";
import { AlertType, postAlert } from "../../../../utils/alerts";
import getMergeState from "../../../../utils/getMergeState";
import { useDebounce } from "../../../../utils/timers";
import {
  isGCPIntegration,
  isTimeGranularity,
} from "../../../../utils/typeGuards";
import useGetDataIntegrationsByTenantID from "../../../admin/hooks/useGetDataIntegrationsByTenantID";
import copyText from "../../copyText";
import useGetCudInventoryInstanceData from "../hooks/useGetCudInventoryCommitmentData";
import useGetCudInventoryData from "../hooks/useGetCudInventoryData";
import useGetCudMeterInventoryData from "../hooks/useGetCudMeterInventoryData";
import useGetCudOnDemandCostData from "../hooks/useGetCudOnDemandCostData";
import useGetCudOnDemandCoverableCost from "../hooks/useGetCudOnDemandCoverableCost";
import useGetCudRecommendationsByTenantID from "../hooks/useGetCudRecommendationsByTenantID";
import useGetCudUtilizationPercentData from "../hooks/useGetCudUtilizationPercentData";
import {
  CudInventoryDatum,
  CudInventoryFilters,
  CudRecommendationFilters,
  CudRecommendationTableDatum,
  InsightRecommendation,
} from "../types";
import { getReadableGcpCommitmentServiceTypeStrings } from "../utils";
import { CudInventoryModal } from "./CudInventoryCommitmentModal";
import CudInventoryTable from "./CudInventoryTable";
import CudInventoryTableControls, {
  CudInventoryCSVData,
} from "./CudInventoryTableControls";
import { CudRecommendationModal } from "./CudRecommendationModal";
import CudRecommendationTable, {
  INSTANCE_FAMILY_COMPUTEOPTIMIZED,
  INSTANCE_FAMILY_GENERAL,
} from "./CudRecommendationTable";
import CudRecommendationTableControls from "./CudRecommendationTableControls";
import CudSpendManagerMeters from "./CudSpendManagerMeters";
import CudVisibilityChart from "./CudVisibilityChart";
import EditCUDRecommendationSettingsForm from "./EditCUDRecommendationSettingsForm";

const MODAL_INSTANCE = "MODAL_INSTANCE";
const MODAL_RECOMMENDATION = "MODAL_RECOMMENDATION";

const CACHE_TIME = 3_600_000; // 1 hour

type Interaction =
  | EditCUDRecommendationSettingsForm.Interaction
  | CudInventoryTable.Interaction
  | CudInventoryTableControls.Interaction
  | CudRecommendationModal.Interaction
  | CudRecommendationTable.Interaction
  | CudRecommendationTableControls.Interaction
  | CudVisibilityChart.Interaction;

const queryParamConfigMap = {
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(
    createEnumParam(Object.values(DurationType)),
    DurationType.LAST_THIRTY_DAYS
  ),
  granularity: withDefault(
    createEnumParam(Object.values(TimeGranularity)),
    TimeGranularity.DAY
  ),
};

interface State {
  contraryInsightRecommendation?: InsightRecommendation;
  cudInventoryFilters: CudInventoryFilters;
  cudRecommendationFilters: CudRecommendationFilters;
  inventorySearchText: string;
  isSideDrawerOpen: boolean;
  modalKey: string;
  recommendationsSearchText: string;
  selectedFamily: string | null;
  selectedRecommendationBillingAccountID: string | null;
  selectedRecommendationID: string | null;
  selectedRecommendationProjectID: string | null;
  selectedRecommendationRegion: string | null;
  selectedRecommendationServiceType: GcpCommitmentServiceType | null;
  selectedRecommendationTargetCommitmentInstanceFamily: string | null;
  selectedRegion: string | null;
  selectedService: GcpCommitmentServiceType | null;
  serviceFilters: string[];
  showTooltip: boolean;
}

const initialState: State = {
  cudInventoryFilters: {
    family: null,
    region: null,
    service: null,
  },
  cudRecommendationFilters: {
    billingAccountID: null,
    projectID: null,
    region: null,
    serviceType: null,
    targetCommitmentInstanceFamily: null,
  },
  inventorySearchText: "",
  isSideDrawerOpen: false,
  modalKey: "",
  recommendationsSearchText: "",
  selectedFamily: null,
  selectedRecommendationID: null,
  selectedRecommendationBillingAccountID: null,
  selectedRecommendationProjectID: null,
  selectedRecommendationRegion: null,
  selectedRecommendationServiceType: null,
  selectedRecommendationTargetCommitmentInstanceFamily: null,
  selectedRegion: null,
  selectedService: null,
  serviceFilters: [],
  showTooltip: false,
};

const defaultAllCudInventoryInstances = [];
const defaultIntegrations = [];
const defaultCudInventory = [];
const defaultCudInventoryInstances = [];
const defaultCudRecommendations = [];
const defaultCudChart = [];
const defaultAccountData = [];
const defaultInsightRecommendations = [];

export default function CudVisibilityContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const theme = useTheme();
  const settings = authenticatedUser.settings;
  const queryParamState = getQueryParamState(queryParams);
  const dateRange = queryParamState.dateRange;

  //
  // State
  //

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  const classification = useMemo(
    () =>
      validateClassification({
        family: state.selectedFamily,
        region: state.selectedRegion,
        service: state.selectedService,
      }),
    [state.selectedFamily, state.selectedRegion, state.selectedService]
  );

  const queryFilters = createClassificationFilters(classification);

  const recClassification = useMemo(
    () =>
      validateRecClassification({
        billingAccountID: state.selectedRecommendationBillingAccountID,
        projectID: state.selectedRecommendationProjectID,
        region: state.selectedRecommendationRegion,
        service: state.selectedRecommendationServiceType,
        targetCommitmentInstanceFamily:
          state.selectedRecommendationTargetCommitmentInstanceFamily,
      }),
    [
      state.selectedRecommendationBillingAccountID,
      state.selectedRecommendationRegion,
      state.selectedRecommendationServiceType,
      state.selectedRecommendationTargetCommitmentInstanceFamily,
    ]
  );

  const recQueryFilters = createRecClassificationFilters(recClassification);

  //
  // Queries
  //

  const {
    data: integrations = defaultIntegrations,
    isLoading: isLoadingClouds,
  } = useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id);

  const gcpIntegrations = integrations.filter(isGCPIntegration).sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const {
    data: cudInventory = defaultCudInventory,
    isLoading: isLoadingCudInventory,
  } = useGetCudInventoryData();

  const {
    data: allCudInventoryInstances = defaultAllCudInventoryInstances,
    isLoading: isLoadingAllCudInventoryInstances,
  } = useGetCudInventoryInstanceData(
    {},
    { enabled: gatekeeper.canListCudInventory }
  );

  const {
    data: cudInventoryInstances = defaultCudInventoryInstances,
    isLoading: isLoadingCudInventoryInstances,
  } = useGetCudInventoryInstanceData(
    { queryFilters },
    { enabled: queryFilters.length > 0 && gatekeeper.canListCudInventory }
  );

  const cudMeterInventoryData = useGetCudMeterInventoryData(
    { dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION },
    {
      gcTime: CACHE_TIME,
      enabled: gatekeeper.hasGCPIntegration,
    }
  );

  const [{ data: mtdCudInventory }, { data: lastMonthCudInventory }] =
    cudMeterInventoryData;

  const isLoadingCudInventoryData = cudMeterInventoryData.some(
    (summary) => summary.isFetching
  );

  const {
    data: cudMeterUtilizationData,
    isLoading: isLoadingCudMeterUtilizationData,
  } = useGetCudUtilizationPercentData({
    dateRange: dateRange,
    granularity: queryParamState.granularity,
  });

  const {
    data: insightRecommendations = defaultInsightRecommendations,
    isLoading: isLoadingInsightRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: gatekeeper.canListRecommendations,
  });

  const {
    data: cudRecommendations = defaultCudRecommendations,
    isLoading: isLoadingRecommendations,
    refetch: refetchRecommendations,
  } = useGetCudRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: gatekeeper.canListCudRecommendations,
  });

  const {
    data: accountData = defaultAccountData,
    isFetching: isLoadingAccountData,
  } = useGetRawData(
    {
      dataSource: DataSource.BILLING,
      dateRange: dateRange,
      dimensions: ["cloudName", "billingAccountId"],
      queryFilters: [
        {
          name: "vendor",
          operator: Operator.EQUALS,
          values: ["Google"],
        },
      ],
      measures: [],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasGCPIntegration }
  );

  const {
    data: costChartData = defaultCudChart,
    isFetching: isLoadingCostChartData,
  } = useGetCudOnDemandCostData({
    dateRange: dateRange,
    granularity: queryParamState.granularity,
  });

  const {
    data: coverableCost = defaultCudChart,
    isFetching: isLoadingcoverableCost,
  } = useGetCudOnDemandCoverableCost({
    dateRange: dateRange,
    granularity: queryParamState.granularity,
  });

  //
  // Mutations
  //

  const {
    isPending: isUpdatingUserTenantSettings,
    mutate: updateUserTenantSettings,
  } = useUpdateUserTenantSetting({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingUserSettings_message,
      });
    },
    onSuccess: (_, params) => {
      mergeState({
        isSideDrawerOpen: false,
      });

      refetchRecommendations();

      queryClient.setQueryData<AuthenticatedUserEntity | undefined>(
        ["authenticatedUser"],
        (authenticatedUser) => {
          if (!authenticatedUser) return;

          return {
            ...authenticatedUser,
            settings: { ...authenticatedUser.settings, ...params },
          };
        }
      );

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingUserSettings_message,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case CudInventoryTable.INTERACTION_VIEW_INSTANCES_CLICKED:
        {
          mergeState({
            modalKey: MODAL_INSTANCE,
            selectedFamily: interaction.selectedFamily,
            selectedRegion: interaction.selectedRegion,
            selectedService: interaction.selectedService,
          });
        }
        break;
      case CudInventoryTable.INTERACTION_FILTER_CLICKED: {
        setState((currentState) => {
          const nextFilters = { ...currentState.cudInventoryFilters };
          nextFilters[interaction.filterKey] = interaction.filterValue;
          return {
            ...currentState,
            cudInventoryFilters: nextFilters,
          };
        });
        break;
      }
      case CudRecommendationModal.INTERACTION_LINK_CLICKED: {
        let dataSource: DataSource = DataSource.BILLING;
        let measures: string[] = ["cost"];
        let dimensions: string[] = [];

        if (
          state.selectedRecommendationServiceType ===
          GcpCommitmentServiceType.CLOUD_SQL
        ) {
          dataSource = DataSource.CLOUD_SQL_COST;
          measures = ["cpuAndRamCost"];
        }

        if (gatekeeper.isConfiguredResourceLevelBilling) {
          dataSource = DataSource.DETAILED_BILLING;
          dimensions = ["resourceId"];
        }

        navigate(paths._reportBuilderNew, {
          state: {
            report: {
              dataSource: dataSource,
              durationType: DurationType.LAST_THIRTY_DAYS,
              filters: recQueryFilters,
              measures: measures,
              dimensions: dimensions,
            },
          },
        });
        break;
      }
      case CudRecommendationTable.INTERACTION_FILTER_CLICKED: {
        setState((currentState) => {
          const nextFilters = { ...currentState.cudRecommendationFilters };
          nextFilters[interaction.filterKey] = interaction.filterValue;
          return {
            ...currentState,
            cudRecommendationFilters: nextFilters,
          };
        });
        break;
      }
      case CudInventoryTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...state.cudInventoryFilters };

        nextFilters[interaction.filterKey] = null;

        mergeState({ cudInventoryFilters: nextFilters });

        break;
      }
      case CudInventoryTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        mergeState({ inventorySearchText: interaction.searchText });
        break;
      }
      case CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...state.cudRecommendationFilters };

        nextFilters[interaction.filterKey] = null;

        mergeState({ cudRecommendationFilters: nextFilters });

        break;
      }
      case CudRecommendationTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        mergeState({ recommendationsSearchText: interaction.searchText });
        break;
      }
      case CudRecommendationTable.INTERACTION_VIEW_RECOMMENDATION_CLICKED:
        {
          const rec = cudRecommendationsKeyedByID[interaction.recommendationID];

          mergeState({
            modalKey: MODAL_RECOMMENDATION,
            selectedRecommendationID: interaction.recommendationID,
            selectedRecommendationBillingAccountID: rec.billingAccountID,
            selectedRecommendationProjectID: rec.projectID,
            selectedRecommendationRegion: rec.region,
            selectedRecommendationServiceType: rec.serviceType,
            contraryInsightRecommendation:
              interaction.contraryInsightRecommendation,
            selectedRecommendationTargetCommitmentInstanceFamily:
              rec.targetCommitmentInstanceFamily,
          });
        }
        break;
      case EditCUDRecommendationSettingsForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ isSideDrawerOpen: false });
        return;
      }
      case EditCUDRecommendationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateUserTenantSettings({
          settingsID: authenticatedUser.settings.id,
          cudRecCommitmentLength: interaction.commitmentLength,
          cudRecCommitmentType: interaction.commitmentType,
        });
        break;
      }
      case CudVisibilityChart.INTERACTION_ADD_SERVICES_FILTER_CLICKED: {
        mergeState({ serviceFilters: interaction.filters });
        break;
      }
    }
  }

  //
  // Render
  //

  const debouncedInventorySearchText = useDebounce(state.inventorySearchText);

  const filteredCudInventory = useMemo(() => {
    return getFilteredCudInventory({
      cudInventory: cudInventory,
      cudInventoryFilters: state.cudInventoryFilters,
      searchText: debouncedInventorySearchText,
    });
  }, [cudInventory, state.cudInventoryFilters, debouncedInventorySearchText]);

  const inventoryCsvData = useMemo(
    () => getInventoryCSVData(filteredCudInventory),
    [filteredCudInventory]
  );

  const debouncedRecommendationsSearchText = useDebounce(
    state.recommendationsSearchText
  );

  const filteredRecommendations = useMemo(() => {
    return getFilteredRecommendations({
      cudRecommendations: cudRecommendations,
      cudRecommendationFilters: state.cudRecommendationFilters,
      searchText: debouncedRecommendationsSearchText,
    });
  }, [
    cudRecommendations,
    state.cudRecommendationFilters,
    debouncedRecommendationsSearchText,
  ]);

  const recommendationsCsvData = useMemo(
    () => getRecommendationsCsvData(filteredRecommendations),
    [filteredRecommendations]
  );

  const cudRecommendationsKeyedByID = keyBy(cudRecommendations, "id");

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_INSTANCE: {
        return (
          <CudInventoryModal
            isLoading={isLoadingCudInventoryInstances}
            title={`${classification?.family} - ${classification?.region} - ${
              classification?.service
                ? getReadableGcpCommitmentServiceTypeStrings(
                    classification?.service
                  )
                : null
            }`}
            instances={cudInventoryInstances}
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedFamily: null,
                selectedRegion: null,
                selectedService: null,
              })
            }
          />
        );
      }
      case MODAL_RECOMMENDATION: {
        // NOTE: At this point it the rec will always exist
        if (
          state.selectedRecommendationID &&
          cudRecommendationsKeyedByID[state.selectedRecommendationID]
        ) {
          return (
            <CudRecommendationModal
              recommendation={
                cudRecommendationsKeyedByID[state.selectedRecommendationID]
              }
              contraryInsightRecommendation={
                state.contraryInsightRecommendation
              }
              title={copyText.cudRecModalTitle}
              onCancel={() =>
                mergeState({
                  modalKey: "",
                  selectedRecommendationID: null,
                  selectedRecommendationBillingAccountID: null,
                  selectedRecommendationRegion: null,
                  selectedRecommendationServiceType: null,
                })
              }
              onInteraction={handleInteraction}
            />
          );
        }
      }
    }
    return null;
  }

  const chartOptionValues = useMemo(() => {
    return getChartOptionValues({
      accountData,
      filteredCudInventory,
      recommendations: filteredRecommendations,
    });
  }, [accountData, filteredCudInventory, filteredRecommendations]);

  useMemo(() => {
    const serviceFilterValues: string[] = [];
    chartOptionValues.serviceOptions.map((serviceOption) =>
      serviceFilterValues.push(serviceOption.value)
    );
    mergeState({
      serviceFilters: serviceFilterValues,
    });
    return;
  }, [chartOptionValues]);

  useMemo(() => {
    if (
      gatekeeper.canListCudRecommendations &&
      filteredRecommendations.length === 0 &&
      settings.cudRecCommitmentType === GcpCommitmentType.SPEND
    ) {
      mergeState({ showTooltip: true });
      return;
    }

    const checkRecommendationDates = filteredRecommendations.filter(
      (recommendation) => {
        const recDate = new Date(recommendation.lastRefreshTime);

        const weekFromToday = getCubeDateRangeFromDurationType(
          DurationType.LAST_SEVEN_DAYS
        );
        if (recDate < weekFromToday[0]) {
          return recommendation;
        }
      }
    );

    if (
      checkRecommendationDates.length > 0 &&
      settings.cudRecCommitmentType === GcpCommitmentType.SPEND
    ) {
      mergeState({ showTooltip: true });
    } else {
      mergeState({ showTooltip: false });
    }
    return;
  }, [filteredRecommendations, settings.cudRecCommitmentType]);

  function handleChangeGranularity(granularity: string): void {
    if (!isTimeGranularity(granularity)) return;

    setQueryParams({ granularity });
  }

  //
  // JSX
  //

  const chartLoading =
    isLoadingCostChartData ||
    isLoadingcoverableCost ||
    isLoadingAccountData ||
    isLoadingCudInventory ||
    isLoadingRecommendations;

  const granularityOptions = [
    {
      label: copyText.granularityOptionLabelHour,
      value: TimeGranularity.HOUR,
      onClick: handleChangeGranularity,
    },
    {
      label: copyText.granularityOptionLabelDay,
      value: TimeGranularity.DAY,
      onClick: handleChangeGranularity,
    },
  ];

  const selectedGranularityOption = granularityOptions.find(
    (option) => option.value === queryParamState.granularity
  );

  const commitmentLengthOptions = [
    {
      label: copyText.commitmentLengthOption1YearLabel,
      value: GcpCommitmentDurationType.TWELVE_MONTH,
    },
    {
      label: copyText.commitmentLengthOption3YearLabel,
      value: GcpCommitmentDurationType.THIRTY_SIX_MONTH,
    },
  ];

  const selectedCommitmentLength = commitmentLengthOptions.find(
    (option) => option.value === settings.cudRecCommitmentLength
  );

  const commitmentTypeOptions = [
    {
      label: copyText.commitmentTypeOptionSpendLabel,
      value: GcpCommitmentType.SPEND,
    },
    {
      label: copyText.commitmentTypeOptionResourceLabel,
      value: GcpCommitmentType.RESOURCE,
    },
  ];

  const selectedCommitmentType = commitmentTypeOptions.find(
    (option) => option.value === settings.cudRecCommitmentType
  );

  return (
    <Box>
      {/* Date Controls*/}
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_lg}
        paddingHorizontal={theme.space_md}
        paddingVertical={theme.space_sm}
        justifyContent="space-between"
      >
        <Flex>
          <Button
            onClick={() => mergeState({ isSideDrawerOpen: true })}
            primary
            size="small"
          >
            {`${copyText.cudVisSpendMetersRecsComputeTypeLabel} ${selectedCommitmentType?.label}`}
          </Button>
          <Button
            onClick={() => mergeState({ isSideDrawerOpen: true })}
            marginLeft={theme.space_xs}
            primary
            size="small"
          >
            {`${copyText.cudVisSpendMetersRecsTermLabel} ${selectedCommitmentLength?.label}`}
          </Button>
        </Flex>
        <Flex>
          <Dropdown
            options={granularityOptions}
            placement="bottom-start"
            selectedOption={selectedGranularityOption}
          >
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              secondary
              size="small"
            >
              {selectedGranularityOption?.label}
            </Button>
          </Dropdown>
          <DateRangeControls
            dateRange={queryParamState.dateRange}
            durationType={queryParamState.duration}
            hiddenOptions={
              queryParamState.granularity === TimeGranularity.HOUR
                ? [
                    DurationType.LAST_NINETY_DAYS,
                    DurationType.QUARTER_TO_DATE,
                    DurationType.YEAR_TO_DATE,
                  ]
                : [DurationType.QUARTER_TO_DATE, DurationType.YESTERDAY]
            }
            maxDate={new DateHelper().date}
            onChangeDateRange={(duration, newDateRange) => {
              setQueryParams({
                duration,
                ...(newDateRange && newDateRange[0] && newDateRange[1]
                  ? {
                      date_range_start: newDateRange[0],
                      date_range_end: newDateRange[1],
                    }
                  : {
                      date_range_start: null,
                      date_range_end: null,
                    }),
              });
            }}
          />
        </Flex>
      </Flex>
      {/* Meters */}
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <CudSpendManagerMeters
          amountSaved={mtdCudInventory?.amountSaved ?? 0}
          commitmentLength={settings.cudRecCommitmentLength}
          isLoadingInventoryData={isLoadingCudInventoryData}
          isLoadingRecommendations={isLoadingRecommendations}
          isLoadingUtilizationData={isLoadingCudMeterUtilizationData}
          recommendationData={filteredRecommendations}
          showTooltip={state.showTooltip}
          totalCost={lastMonthCudInventory?.totalCost ?? 0}
          utilizationPercent={cudMeterUtilizationData?.utilizationPercent ?? 0}
        />
      </Box>
      {/* On Demand Cost Chart  */}
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Box height={450} paddingVertical={theme.space_md}>
          <CudVisibilityChart
            chartOptions={chartOptionValues}
            commitmentType={settings.cudRecCommitmentType}
            costChartData={costChartData}
            coverableCost={coverableCost}
            cudInventoryFilters={state.cudInventoryFilters}
            granularity={queryParamState.granularity}
            isLoading={chartLoading}
            recommendations={filteredRecommendations}
            serviceFilters={state.serviceFilters}
            onInteraction={handleInteraction}
          />
        </Box>
      </Box>
      {/* Inventory Table */}
      <Flex
        justifyContent="center"
        alignItems="center"
        width="100%"
        marginBottom={theme.space_sm}
      >
        <Text appearance="h4" marginRight={theme.space_xs}>
          {copyText.cudInventorySectionTitle}
        </Text>
      </Flex>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginBottom={theme.space_sm}
        padding={theme.space_md}
      >
        <CudInventoryTableControls
          csvData={inventoryCsvData}
          debouncedSearchText={debouncedInventorySearchText}
          filters={state.cudInventoryFilters}
          searchText={state.inventorySearchText}
          onInteraction={handleInteraction}
        />
      </Box>

      <CudInventoryTable
        instanceData={allCudInventoryInstances}
        inventoryData={filteredCudInventory}
        isLoading={isLoadingCudInventory || isLoadingAllCudInventoryInstances}
        onInteraction={handleInteraction}
      />

      <SideDrawer
        isOpen={state.isSideDrawerOpen}
        onClose={() => {
          mergeState({ isSideDrawerOpen: false });
        }}
        title={copyText.cudRecSettingsFormTitle}
        renderContent={() => {
          return (
            <EditCUDRecommendationSettingsForm
              commitmentLength={settings.cudRecCommitmentLength}
              commitmentType={settings.cudRecCommitmentType}
              gcpIntegrations={gcpIntegrations}
              isLoadingGCPIntegrations={isLoadingClouds}
              isProcessing={isUpdatingUserTenantSettings}
              onInteraction={handleInteraction}
            />
          );
        }}
      />
      {/* Recommendation Table */}
      {gatekeeper.canViewGcpRateRecs ? (
        <>
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            marginBottom={theme.space_sm}
          >
            <Text appearance="h4" marginRight={theme.space_xs}>
              {copyText.cudRecSectionTitle}
            </Text>
          </Flex>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_1}
            marginBottom={theme.space_sm}
            padding={theme.space_md}
          >
            <CudRecommendationTableControls
              csvData={recommendationsCsvData}
              debouncedSearchText={debouncedRecommendationsSearchText}
              filters={state.cudRecommendationFilters}
              searchText={state.recommendationsSearchText}
              onInteraction={handleInteraction}
            />
          </Box>
          <CudRecommendationTable
            cudRecommendations={filteredRecommendations}
            commitmentType={settings.cudRecCommitmentType}
            insightRecommendations={insightRecommendations}
            isLoading={
              isLoadingRecommendations || isLoadingInsightRecommendations
            }
            onInteraction={handleInteraction}
          />
        </>
      ) : undefined}
      {renderModal()}
    </Box>
  );
}

type GetFilteredCudRecommendationParams = {
  cudRecommendations: CudRecommendationEntity[];
  cudRecommendationFilters: CudRecommendationFilters;
  searchText: string;
};

type GetFilteredCudInventoryParams = {
  cudInventory: CudInventoryDatum[];
  cudInventoryFilters: CudInventoryFilters;
  searchText: string;
};

function cudInventoryPassesFilters(
  cudInventory: CudInventoryDatum,
  filters: CudInventoryFilters
): boolean {
  const groupDimensionKeys = ["family", "region", "service"];

  return !groupDimensionKeys.some(
    (key) =>
      filters[key] !== null &&
      cudInventory[key]?.toLowerCase().trim() !==
        filters[key].toLowerCase().trim()
  );
}

function cudInventoryHasSearchText(
  cudInventory: CudInventoryDatum,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    cudInventory.family ?? "",
    cudInventory.region ?? "",
    cudInventory.service ?? "",
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

function recommendationPassesFilters(
  cudRecommendation: CudRecommendationEntity,
  filters: CudRecommendationFilters
): boolean {
  const groupDimensionKeys = [
    "billingAccountID",
    "projectID",
    "region",
    "serviceType",
    "targetCommitmentInstanceFamily",
  ];

  return !groupDimensionKeys.some(
    (key) =>
      filters[key] !== null &&
      cudRecommendation[key]?.toLowerCase().trim() !==
        filters[key].toLowerCase().trim()
  );
}

function recommendationHasSearchText(
  cudRecommendation: CudRecommendationEntity,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    cudRecommendation.billingAccountID ?? "",
    cudRecommendation.region ?? "",
    cudRecommendation.serviceType ?? "",
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value.toLowerCase().trim().includes(searchText)
  );
}

type Classification = ReturnType<typeof validateClassification>;

function validateClassification(classification: {
  family: string | null;
  region: string | null;
  service: GcpCommitmentServiceType | null;
}) {
  const { family, region, service } = classification;

  if (family || region || service) return { family, region, service };

  return null;
}

function createClassificationFilters(
  classification: Classification
): (UnaryFilter | BinaryFilter)[] {
  if (!classification) return [];

  const { family, region, service } = classification;

  const makeFilter = (
    dimName: string,
    value: string | null
  ): UnaryFilter | BinaryFilter =>
    value === null
      ? {
          name: dimName,
          operator: Operator.NOT_SET,
        }
      : {
          name: dimName,
          operator: Operator.EQUALS,
          values: [value],
        };

  return [
    makeFilter("family", family),
    makeFilter("region", region),
    makeFilter("service", service),
  ];
}

type RecClassification = ReturnType<typeof validateRecClassification>;

function validateRecClassification(classification: {
  billingAccountID?: string | null;
  projectID?: string | null;
  region: string | null;
  service: GcpCommitmentServiceType | null;
  targetCommitmentInstanceFamily: string | null;
}) {
  const {
    billingAccountID,
    projectID,
    region,
    service,
    targetCommitmentInstanceFamily,
  } = classification;

  if (
    billingAccountID ||
    projectID ||
    region ||
    service ||
    targetCommitmentInstanceFamily
  )
    return {
      billingAccountID,
      projectID,
      region,
      service,
      targetCommitmentInstanceFamily,
    };

  return null;
}

function createRecClassificationFilters(
  classification: RecClassification
): (UnaryFilter | BinaryFilter)[] {
  if (!classification) return [];

  const {
    billingAccountID,
    projectID,
    region,
    service,
    targetCommitmentInstanceFamily,
  } = classification;

  const readableServiceName =
    service === GcpCommitmentServiceType.CLOUD_MEMORY_STORE
      ? "Cloud Memorystore for Redis"
      : getReadableGcpCommitmentServiceTypeStrings(service);

  const makeFilter = (
    dimName: string,
    value: string | null
  ): UnaryFilter | BinaryFilter =>
    value === null
      ? {
          name: dimName,
          operator: Operator.NOT_SET,
        }
      : {
          name: dimName,
          operator: Operator.EQUALS,
          values: [value],
        };

  const filters: (UnaryFilter | BinaryFilter)[] = [];
  if (billingAccountID) {
    filters.push(makeFilter("billingAccountId", billingAccountID));

    if (
      targetCommitmentInstanceFamily &&
      targetCommitmentInstanceFamily !== INSTANCE_FAMILY_GENERAL &&
      targetCommitmentInstanceFamily !== INSTANCE_FAMILY_COMPUTEOPTIMIZED
    ) {
      filters.push(
        makeFilter(
          "machine_series_family",
          targetCommitmentInstanceFamily
            ? targetCommitmentInstanceFamily.toLowerCase()
            : null
        )
      );
    }
  } else if (projectID) {
    filters.push(makeFilter("projectId", projectID));
  }

  if (region !== "global") {
    filters.push(makeFilter("region", region));
  }

  filters.push(makeFilter("serviceDescription", readableServiceName));

  return filters;
}

//
// CSV
//

const cudInventoryCsvAccessors = [
  "service",
  "family",
  "region",
  "amountSaved",
  "utilizationPercent",
  "hourlyCommittedAmount",
  "reservedVCPU",
  "reservedMemoryGiB",
] as const;

function getInventoryCSVData(
  inventoryData: CudInventoryDatum[]
): CudInventoryCSVData {
  if (!inventoryData.length) {
    return { headers: [], rows: [] };
  }

  const rows = inventoryData.map((datum) => {
    return {
      amountSaved: datum.amountSaved
        ? formatCurrency({ number: datum.amountSaved })
        : null,
      family: datum.family,
      hourlyCommittedAmount: datum.hourlyCommittedAmount
        ? formatCurrency({ number: datum.hourlyCommittedAmount })
        : null,
      region: datum.region,
      reservedMemoryGiB: datum.reservedMemoryGiB
        ? prettyBytes(datum.reservedMemoryGiB)
        : null,
      reservedVCPU: datum.reservedVCPU,
      service: datum.service
        ? getReadableGcpCommitmentServiceTypeStrings(datum.service)
        : null,
      utilizationPercent: formatPercentage(datum.utilizationPercent / 100),
    };
  });

  const headers = cudInventoryCsvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `cudInventoryTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

const cudRecommendationsCsvAccessors = [
  "projectID",
  "serviceType",
  "estimatedMonthlySavings",
  "scope",
  "term",
  "region",
  "targetCommitmentValue",
  "targetCommitmentType",
  "targetCommitmentInstanceFamily",
  "lastRefreshTime",
] as const;

function getRecommendationsCsvData(
  recommendationsData: CudRecommendationTableDatum[]
): CudInventoryCSVData {
  if (!recommendationsData.length) {
    return { headers: [], rows: [] };
  }

  const rows = recommendationsData.map((datum) => {
    return {
      projectID: datum.projectID,
      serviceType: getReadableGcpCommitmentServiceTypeStrings(
        datum.serviceType
      ),
      estimatedMonthlySavings: datum.estimatedMonthlySavings,
      lastRefreshTime: formatDate(
        new Date(datum.lastRefreshTime),
        "MM/dd/yyyy hh:mm a"
      ),
      scope:
        datum.scope === GcpCommitmentScope.BILLING_SHARED
          ? copyText.cudRecScopeBillingShared
          : copyText.cudRecScopeProject,
      term:
        datum.term === GcpCommitmentDurationType.THIRTY_SIX_MONTH
          ? copyText.commitmentLengthOption3YearLabel
          : copyText.commitmentLengthOption1YearLabel,
      region: datum.region,
      targetCommitmentValue: datum.targetCommitmentValue,
      targetCommitmentType: datum.targetCommitmentType,
      targetCommitmentInstanceFamily: datum.targetCommitmentInstanceFamily,
    };
  });

  const headers = cudRecommendationsCsvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `cudVisRecommendationHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

function getFilteredCudInventory(
  params: GetFilteredCudInventoryParams
): CudInventoryDatum[] {
  return params.cudInventory.filter((cudInventory) => {
    if (!cudInventoryPassesFilters(cudInventory, params.cudInventoryFilters)) {
      return false;
    }

    if (!cudInventoryHasSearchText(cudInventory, params.searchText)) {
      return false;
    }

    return true;
  });
}

function getFilteredRecommendations(
  params: GetFilteredCudRecommendationParams
): CudRecommendationEntity[] {
  return params.cudRecommendations.filter((cudRecommendation) => {
    if (!cudRecommendation.isApplicable || cudRecommendation.isHidden) {
      return false;
    }

    if (
      !recommendationPassesFilters(
        cudRecommendation,
        params.cudRecommendationFilters
      )
    ) {
      return false;
    }

    if (!recommendationHasSearchText(cudRecommendation, params.searchText)) {
      return false;
    }

    return true;
  });
}

function getChartOptionValues({
  accountData,
  filteredCudInventory,
  recommendations,
}: {
  accountData: RawData[];
  filteredCudInventory: CudInventoryDatum[];
  recommendations: CudRecommendationEntity[];
}) {
  const accountOptions = accountData.reduce((accum: Option[], account) => {
    const accountName: string =
      typeof account.cloudName === "string" ? account.cloudName : "";
    const accountID: string =
      typeof account.billingAccountId === "string"
        ? account.billingAccountId
        : "";

    const option: Option = {
      label: `[${accountName}] ${accountID}`,
      value: accountID,
    };
    return [...accum, option];
  }, []);

  const services: string[] = [];

  filteredCudInventory.map((cudInventoryService) => {
    if (
      cudInventoryService.service &&
      !services.includes(cudInventoryService.service)
    ) {
      services.push(cudInventoryService.service);
    }
  });

  recommendations.map((recommendation) => {
    if (!services.includes(recommendation.serviceType)) {
      services.push(recommendation.serviceType);
    }
  });

  const serviceOptions: Option[] = services.map((service) => {
    if (Object.keys(GcpCommitmentServiceType).includes(service)) {
      const serviceLabelType: GcpCommitmentServiceType =
        GcpCommitmentServiceType[service];
      const serviceLabel =
        getReadableGcpCommitmentServiceTypeStrings(serviceLabelType);

      return {
        label: serviceLabel,
        value: service,
      };
    }
    return {
      label: service,
      value: service,
    };
  });
  return { accountOptions, serviceOptions };
}

function getQueryParamState(
  params: DecodedValueMap<typeof queryParamConfigMap>
) {
  const dateRange =
    params.date_range_start && params.date_range_end
      ? [params.date_range_start, params.date_range_end]
      : getCubeDateRangeFromDurationType(params.duration);

  return {
    duration: params.duration,
    dateRange,
    granularity: params.granularity,
  };
}
