import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { CreateRampPlanParameters } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateRampPlanParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  CreateRampPlanParameters
>;

export default function useCreateRampPlan(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.createRampPlan(params),
    ...options,
  });
}
