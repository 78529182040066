import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import * as CSS from "csstype";
import React from "react";
import { LinkProps, Link as ReactRouterLink } from "react-router-dom";
import { Theme } from "../theme/default";

export interface Props extends LinkProps {
  color?: CSS.Property.Color;
  textDecoration?: CSS.Property.TextDecoration;
  theme?: Theme;
}

const _Link = styled(ReactRouterLink, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<Props>(({ color, textDecoration, theme }) => ({
  color: color ?? theme.link_color,
  fontSize: theme.fontSize_ui,

  "&:hover": {
    color: color ?? theme.link_color_hover,
    textDecoration: textDecoration ?? "underline",
  },

  "&:focus": {
    color: color ?? theme.link_color,
  },
}));

export default function Link(props: Props): JSX.Element {
  return <_Link {...props} />;
}
