import { createElement } from "react";
import { toast, ToastOptions } from "react-toastify";
import copyText from "../common.copyText";
import { Alert } from "../ui-lib/components/AlertContainer";

export enum AlertType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

type AlertOptions = {
  message: string;
  shortcut?: string;
  type?: AlertType;
} & Omit<ToastOptions, "type">;

export function postAlert({
  message,
  shortcut,
  type,
  ...toastOptions
}: AlertOptions) {
  const content = createElement(Alert, {
    message,
    shortcut,
    title: copyText[`${type}_title`],
  });

  switch (type) {
    case AlertType.ERROR:
      return toast.error(content, { autoClose: false, ...toastOptions });
    case AlertType.SUCCESS:
      return toast.success(content, toastOptions);
    case AlertType.WARNING:
      return toast.warning(content, { autoClose: false, ...toastOptions });
    default:
      return toast(message);
  }
}
