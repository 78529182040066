import commonCopyText from "../../common.copyText";

export default {
  ...commonCopyText,
  accountingCredits: "Credits",
  accountingGrossCost: "Gross Cost",
  accountingGrossCosts: "Gross Costs",
  accountingNetCost: "Net Cost",
  accountingTotalCredits: "Total Credits",
  accountingTotals: "Totals",
  actionExport: "Export",
  actionGroupBy: "Group By",
  categoryOther: "other",
  creditBreakdownTableEmptyPlaceholderText: "No Credits",
  creditBreakdownTableTitle: "Total Credits Breakdown",
  creditDescriptionCUD:
    "Resource-based Committed Use contracts purchased for Compute Engine in return for deeply discounted prices for VM usage.",
  creditDescriptionCUDDollarBase:
    "Spend-based Committed Use contracts purchased for services in exchange for your commitment to spend a minimum amount.",
  creditDescriptionDiscount:
    "The discount credit type is used for credits earned after a contractual spending threshold is reached. Note that in the Cloud Billing reports available in the Cloud Console, the discount credit type is listed as Spending based discounts (contractual).",
  creditDescriptionFreeTier:
    "Some services offer free resource usage up to specified limits. For these services, credits are applied to implement the free tier usage.",
  creditDescriptionPromotion:
    "The promotion credit type includes Google Cloud Free Trial and marketing campaign credits, or other grants to use Google Cloud. When available, promotional credits are considered a form of payment and are automatically applied to reduce your total bill.",
  creditDescriptionSubscriptionBenefit:
    "Credits earned by purchasing long-term subscriptions to services in exchange for discounts.",
  creditDescriptionSUD:
    "The sustained use discounts credit type is an automatic discount that you earn for running specific Compute Engine resources for a significant portion of the billing month.",
  creditNameCUD: "Committed Use Discount",
  creditNameCUDDollarBase: "Committed Use Discount (Dollar Base)",
  creditNameDiscount: "Discount",
  creditNameFreeTier: "Free Tier",
  creditNamePromotion: "Promotion",
  creditNameSubscriptionBenefit: "Subscription Benefit",
  creditNameSUD: "Sustained Use Discount",
  csvHeaderAmount: "Amount",
  csvHeaderCredit: "Credit",
  csvHeaderGrossCost: "Gross Cost",
  csvHeaderName: "Name",
  csvHeaderNetCost: "Net Cost",
  labelApplicatorPlaceholderKey: "Enter Key",
  netCostTableEmptyPlaceholderText: "No Costs Data",
  netCostTableTitle: "Total Cost Breakdown",
  tableHeaderValue: "Value",
};
