import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconExclamationDiamond(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 14 14">
        <path
          d="M13.398 6.35 7.65.602a.92.92 0 0 0-1.3 0L.602 6.35a.92.92 0 0 0 0 1.3l5.748 5.748a.92.92 0 0 0 1.3 0l5.748-5.748a.92.92 0 0 0 0-1.3Zm-6.095 3.68h-.606a.303.303 0 0 1-.303-.302v-.606c0-.168.135-.303.303-.303h.606c.167 0 .303.135.303.303v.606a.303.303 0 0 1-.303.303ZM7 7.607A.606.606 0 0 1 6.394 7V4.576a.606.606 0 0 1 1.212 0V7A.606.606 0 0 1 7 7.606Z"
          fill="#FA5F69"
        />
      </svg>
    </Icon>
  );
}
