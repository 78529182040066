import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

export enum FeedbackBarVariants {
  error,
  info,
  success,
  warning,
}

interface StyledDivProps {
  backgroundColor: string;
  borderColor: string;
  margin?: string;
}

const StyledDiv = styled.div<StyledDivProps>`
  background-color: ${(props) => props.backgroundColor};
  border-left: 4px solid ${(props) => props.borderColor};
  padding: 0.5rem;
  width: 100%;
  margin-bottom: 1rem;
  margin: ${(props) => (props.margin ? props.margin : "")};

  span {
    font-weight: 600;
    margin-right: 0.5rem;
  }

  p {
    margin: 0;
    color: ${(props) => props.theme.text_color};
    font-weight: 400;
  }
`;

interface Props {
  margin?: string;
  text: string;
  title: string;
  variant: FeedbackBarVariants;
}

export default function FeedbackBar(props: Props): JSX.Element {
  const theme = useTheme();

  const colorConfig = {
    [FeedbackBarVariants.error]: {
      backgroundColor: theme.feedback_negative_background,
      borderColor: theme.feedback_negative_outline,
    },
    [FeedbackBarVariants.info]: {
      backgroundColor: theme.feedback_neutral_background,
      borderColor: theme.feedback_neutral_outline,
    },
    [FeedbackBarVariants.success]: {
      backgroundColor: theme.feedback_positive_background,
      borderColor: theme.feedback_positive_outline,
    },
    [FeedbackBarVariants.warning]: {
      backgroundColor: theme.feedback_warn_background,
      borderColor: theme.feedback_warn_outline,
    },
  };

  const colors = colorConfig[props.variant];

  return (
    <StyledDiv {...colors} margin={props.margin}>
      <p>
        <span>{props.title}</span>
        {props.text}
      </p>
    </StyledDiv>
  );
}
