import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

type CaseEntity = Awaited<ReturnType<CoreAPIClient["getCaseByID"]>>;

type Options = UseQueryOptions<CaseEntity, CoreAPIClientError>;
type Result = UseQueryResult<CaseEntity, CoreAPIClientError>;

export default function useGetCaseByID(
  caseID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.case(caseID),
    queryFn: () => client.getCaseByID(caseID),
    ...options,
  });
}
