import { useQuery } from "@tanstack/react-query";
import { GenerateZendeskTokenCommandResult } from "@ternary/api-lib/core/commands/GenerateZendeskTokenCommand";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";

export default function useGenerateZendeskToken(
  options?: UseQueryOptions<
    GenerateZendeskTokenCommandResult["token"],
    CoreAPIClientError
  >
): UseQueryResult<
  GenerateZendeskTokenCommandResult["token"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["zendeskToken"],
    queryFn: () => client.generateZendeskToken(),
    ...options,
  });
}
