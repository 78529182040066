import { isGCPIntegration } from "@/utils/typeGuards";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type BigQueryGates = ReturnType<typeof bigQueryGates>;

export default function bigQueryGates(params: GateGetterParameters) {
  return {
    isBigQueryConfigured: (() => {
      const gcpIntegrations = params.integrations.filter(isGCPIntegration);

      return gcpIntegrations.reduce((isConfigured: boolean, integration) => {
        const monitoring = integration.config.bigQueryMonitoring;
        const validationSuccess = integration.validations.find(
          (validation) => validation.name === CloudCapability.BIGQUERY_DATA
        )?.success;

        if (!validationSuccess || !monitoring || monitoring.length === 0) {
          return isConfigured;
        } else {
          return true;
        }
      }, false);
    })(),
  };
}
