import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";
import queryKeys from "./queryKeys";

type MspTenantSettingsEntity = Awaited<
  ReturnType<CoreAPIClient["getMspTenantSettingsByTenantID"]>
>;

export default function useGetMspTenantSettingsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<MspTenantSettingsEntity, CoreAPIClientError>
): UseQueryResult<MspTenantSettingsEntity, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.mspTenantSettings(tenantID),
    queryFn: () => client.getMspTenantSettingsByTenantID(tenantID),
    meta: { errorMessage: copyText.errorLoadingTenantSettingsMessage },
    ...options,
  });
}
