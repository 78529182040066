import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { cloudSpannerDimensions, cloudSpannerResourceMeasures } from "../types";

type CloudSpannerResourceSummary = {
  projectId: string | null;
  region: string | null;
  cost: number | null;
  totalBackupCost: number | null;
  totalComputeCost: number | null;
  totalDataBoostComputeCost: number | null;
  totalEgressCost: number | null;
  totalStorageCost: number | null;
};

type CloudSpannerResourceSummaryEntity = {
  [cloudSpannerDimensions.projectID]: string | null;
  [cloudSpannerDimensions.region]: string | null;
  [cloudSpannerResourceMeasures.cost]: number;
  [cloudSpannerResourceMeasures.totalBackupCost]: number;
  [cloudSpannerResourceMeasures.totalComputeCost]: number;
  [cloudSpannerResourceMeasures.totalDataBoostComputeCost]: number;
  [cloudSpannerResourceMeasures.totalEgressCost]: number;
  [cloudSpannerResourceMeasures.totalStorageCost]: number;
};

export interface Params {
  dateRange: Date[];
}

export default function useGetCloudSpannerResourceSummary(
  params: Params,
  options?: UseQueryOptions<CloudSpannerResourceSummaryEntity[], UError>
): UseQueryResult<CloudSpannerResourceSummaryEntity[], UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["cloudSpannerResourceSummary", params],
    queryFn: async () => {
      const measures = [
        cloudSpannerResourceMeasures.cost,
        cloudSpannerResourceMeasures.totalBackupCost,
        cloudSpannerResourceMeasures.totalComputeCost,
        cloudSpannerResourceMeasures.totalDataBoostComputeCost,
        cloudSpannerResourceMeasures.totalEgressCost,
        cloudSpannerResourceMeasures.totalStorageCost,
      ];
      const dimensions = [
        cloudSpannerDimensions.projectID,
        cloudSpannerDimensions.region,
        cloudSpannerDimensions.instanceConfig,
      ];

      const query = new DataQuery({
        dataSource: DataSource.CLOUD_SPANNER,
        dateRange: params.dateRange,
        dimensions,
        measures,
      });
      const result = await client.loadData<CloudSpannerResourceSummary>(
        tenantID,
        query
      );

      return result.response.map((summary) => ({
        [cloudSpannerDimensions.projectID]: summary.projectId,
        [cloudSpannerDimensions.region]: summary.region,
        [cloudSpannerResourceMeasures.totalBackupCost]:
          summary.totalBackupCost ?? 0,
        [cloudSpannerResourceMeasures.totalComputeCost]:
          summary.totalComputeCost ?? 0,
        [cloudSpannerResourceMeasures.totalDataBoostComputeCost]:
          summary.totalDataBoostComputeCost ?? 0,
        [cloudSpannerResourceMeasures.totalEgressCost]:
          summary.totalEgressCost ?? 0,
        [cloudSpannerResourceMeasures.totalStorageCost]:
          summary.totalStorageCost ?? 0,
        [cloudSpannerResourceMeasures.cost]: summary.cost ?? 0,
      }));
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasGCPIntegration,
    ...options,
  });
}
