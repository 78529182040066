import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatDistance } from "date-fns";
import React, { useMemo } from "react";
import copyText from "../copyText";

type User = {
  tenantID: string;
  createdAt: string;
  email: string;
  loginCount: number;
  lastLogin: string | null;
  name: string;
};

const columnHelper = createColumnHelper<User>();

interface Props {
  isLoading: boolean;
  users: User[];
}

export function MspChildUserActivityTable(props: Props) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", { header: copyText.tableHeaderName }),
      columnHelper.accessor("email", { header: copyText.tableHeaderEmail }),
      columnHelper.accessor("createdAt", {
        cell: (cell) => {
          const value = cell.getValue();
          return formatDate(new Date(value), "yyyy-MM-dd");
        },
        header: copyText.tableHeaderCreatedAt,
        size: 80,
      }),
      columnHelper.accessor("loginCount", {
        header: copyText.tableHeaderLogins,
        size: 60,
      }),
      columnHelper.accessor("lastLogin", {
        cell: (cell) => {
          const value = cell.getValue();

          if (!value) {
            return "--";
          }

          return formatDistance(new Date(), new Date(value));
        },
        header: copyText.tableHeaderLastLogin,
        size: 100,
      }),
    ],
    [props.users]
  );

  return (
    <Table
      columns={columns}
      compact
      data={props.users}
      initialState={{ sorting: [{ id: "lastLogin", desc: true }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

export default MspChildUserActivityTable;
