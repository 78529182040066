import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable, Nullable, Unwrap } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const CloudSQLCostStruct = {
  backupCost: number(),
  classification: nullable(string()),
  cost: number(),
  cpuAndRamCost: number(),
  cpuCost: number(),
  databaseType: nullable(string()),
  idlingCost: number(),
  networkCost: number(),
  projectId: nullable(string()),
  ramCost: number(),
  region: nullable(string()),
  storageCost: number(),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const CloudSQLUsageStruct = {
  databaseId: nullable(string()),
  databaseType: nullable(string()),
  maxCpuAverageUsedCores: number(),
  maxCpuReservedCores: number(),
  maxDiskSizeBytes: number(),
  maxDiskUsedBytes: number(),
  maxRamAverageUsedBytes: number(),
  maxRamUsedBytesWithBuffer: nullable(number()),
  networkSentBytes: nullable(number()),
  projectId: nullable(string()),
  ramReservedBytes: number(),
  region: nullable(string()),
  totalCost: nullable(number()),
  totalCpuReservedCores: number(),
  totalCpuUsedCoreHours: number(),
  totalDiskSizeBytes: number(),
  totalDiskUsedBytes: number(),
  totalNetworkSentBytes: nullable(number()),
  totalRamReservedBytes: number(),
  totalRamUsedBytes: number(),
  totalRamUsedBytesWithBuffer: nullable(number()),
};

export type CloudSQLCostDatum = NotNullable<Infer<typeof CloudSQLCostStruct>>;

export type CloudSQLUsageDatum = NotNullable<
  Infer<typeof CloudSQLUsageStruct> & {
    avgCpuReservedCores: number;
    avgCpuUsedCoreHours: number;
    avgDiskSizeBytes: number;
    avgDiskUsedBytes: number;
    avgNetworkSentBytes: number;
    avgRamReservedBytes: number;
    avgRamUsedBytes: number;
    totalCost: number;
    latestProvisionedCpu: number;
    latestProvisionedDisk: number;
    latestProvisionedRam: number;
    latestUsedCpu: number;
    latestUsedDisk: number;
    latestUsedRam: number;
  }
>;

export const CloudSQLResourceType = {
  CPU: "CPU",
  DISK: "DISK",
  MEMORY: "MEMORY",
  NETWORK: "NETWORK",
} as const;

export type CloudSQLResourceType =
  (typeof CloudSQLResourceType)[keyof typeof CloudSQLResourceType];

export type CloudSQLResource = Unwrap<
  Nullable<CloudSQLCostDatum> & Nullable<CloudSQLUsageDatum>
>;
