import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CustomPricingGates = ReturnType<typeof customPricingGates>;

export default function customPricingGates(params: GateGetterParameters) {
  return {
    canApplyCustomPricing: params._featureFlagsIncludeAllOf([
      FeatureFlag.CUSTOM_PRICING,
    ]),
  };
}
