import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Switch from "@/ui-lib/components/Switch";
import { useTheme } from "@emotion/react";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

interface Props {
  isProcessing: boolean;
  fiscalMode: boolean;
  onInteraction: (interaction: EditCalendarSettingsForm.Interaction) => void;
}

export function EditCalendarSettingsForm(props: Props): JSX.Element {
  const theme = useTheme();

  function handleToggleFiscalMode(checked: boolean) {
    props.onInteraction({
      type: EditCalendarSettingsForm.INTERACTION_FISCAL_SWITCH_CLICKED,
      fiscalMode: checked ? true : false,
    });
  }

  return (
    <Form height={85}>
      <Text
        fontSize={theme.h3_fontSize}
        fontWeight={theme.h3_fontWeight}
        marginVertical={theme.space_md}
        color={theme.text_color}
      >
        {copyText.subTitleDatePreferences}
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.calendarOption_fiscalMode}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          marginHorizontal={theme.space_md}
          minHeight={theme.space_lg}
          minWidth={theme.space_lg}
          position="relative"
        >
          {props.isProcessing ? (
            <LoadingSpinner />
          ) : (
            <Switch
              checked={props.fiscalMode}
              onChange={(checked) => handleToggleFiscalMode(checked)}
            />
          )}
        </Flex>
      </Flex>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditCalendarSettingsForm {
  export const INTERACTION_FISCAL_SWITCH_CLICKED = `EditAppearanceSettingsForm.INTERACTION_FISCAL_SWITCH_CLICKED`;

  interface InteractionThemeSwitchClicked {
    type: typeof INTERACTION_FISCAL_SWITCH_CLICKED;
    fiscalMode: boolean;
  }

  export type Interaction = InteractionThemeSwitchClicked;
}

export default EditCalendarSettingsForm;
