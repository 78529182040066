import React, { createContext, PropsWithChildren, useContext } from "react";
import AnalyticsApiClient from "../api/analytics/AnalyticsApiClient";

const context = createContext<AnalyticsApiClient | undefined>(undefined);

export interface Props {
  loader: AnalyticsApiClient;
}

export default function AnalyticsApiClientProvider({
  children,
  loader,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={loader}>{children}</context.Provider>;
}

export function useAnalyticsApiClient(): AnalyticsApiClient {
  const loader = useContext(context);

  if (!loader) {
    throw new Error(
      "useAnalyticsApiClient must be wrapped by AnalyticsApiClientProvider"
    );
  }

  return loader;
}
