import { uniq } from "lodash";

export function getPayerAccountIDs(recs: { cloudID: string }[]) {
  return uniq(recs.map((rec) => rec.cloudID)).sort();
}

export function getLinkedAccountIDsInPayerAccount(
  payerAccountID: string,
  recs: { cloudID: string; accountID: string | null }[]
) {
  return uniq(
    recs
      .filter((rec) => rec.cloudID === payerAccountID)
      .map((rec) => rec.accountID)
  ).sort();
}
