import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import {
  formatCurrencyRounded,
  formatNumberRounded,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useEffect, useState } from "react";
import Meter, { getChangePercent } from "../../../components/Meter";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import RecommendationsBreakdownMeter from "./RecommendationsBreakdownMeter";

type Recommendation = {
  applicable: boolean;
  estimateValue: number;
  state: string;
  snoozeUntil: string | null;
};

interface Props {
  category?: string;
  lastMonthSpend: number | null;
  lastMTDSpend: number | null;
  isLoading: boolean;
  mtdSpend: number | null;
  recommendations: Recommendation[];
}

interface State {
  recommendationsCount: number | null;
  recommendationsValue: number | null;
}

export default function RecommendationsMetersSection(
  props: Props
): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    recommendationsCount: null,
    recommendationsValue: null,
  });
  const mergeState = getMergeState(setState);

  useEffect(() => {
    mergeState(getRecommendationStats(props.recommendations));
  }, [props.recommendations]);

  const changeMTD = getChangePercent({
    biggerIsBetter: false,
    value: props.mtdSpend as number,
    comparison: props.lastMTDSpend as number,
    theme,
  });

  let category = "";

  // TODO: create category constants post migration
  switch (props.category) {
    case "compute":
      category = `(${copyText.categoryCompute})`;
      break;
    case "kubernetes":
      category = `(${copyText.categoryKubernetes})`;
      break;
    case "storage":
      category = `(${copyText.categoryStorage})`;
      break;
    default:
      category = "";
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      height={200}
      justifyContent={
        props.category === "kubernetes" ? "space-around" : "space-between"
      }
      marginBottom={theme.space_md}
      padding={theme.space_lg}
    >
      <RecommendationsBreakdownMeter
        isLoading={props.isLoading}
        recommendations={props.recommendations}
      />
      <Box
        backgroundColor={theme.secondary_color_background}
        height="100%"
        paddingLeft="2px"
      />
      <Meter
        footerTitle={copyText.recommendationMeterFooterTitlePercentAssigned}
        header={copyText.recommendationMeterHeaderRecommendationsCount}
        loading={props.isLoading}
        value={formatNumberRounded(state.recommendationsCount || 0)}
        width="25%"
      />
      {props.category === "kubernetes" ? null : (
        <>
          <Box
            backgroundColor={theme.secondary_color_background}
            height="100%"
            paddingLeft="2px"
          />
          <Meter
            footerColor={changeMTD.color}
            footerIcon={changeMTD.icon}
            footerTitle={
              copyText.recommendationMeterFooterTitleChangeFromLastMonth
            }
            footerValue={changeMTD.textValue}
            header={`${copyText.recommendationMeterHeaderTotalMTDSpend} ${category}`}
            loading={props.isLoading}
            value={formatCurrencyRounded({
              number: props.mtdSpend || 0,
            })}
            width="25%"
          />
        </>
      )}
    </Flex>
  );

  function getRecommendationStats(recommendations: Recommendation[]) {
    // TODO: use constants
    const current = recommendations.filter(
      (rec) =>
        (rec.state === "new" || rec.state === "accepted") && rec.applicable
    );

    const recommendationsValue = current.reduce(
      (accum, entry) => accum + entry.estimateValue,
      0
    );

    return {
      recommendationsCount: current.length,
      recommendationsValue,
    };
  }
}
