import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

interface Props {
  height?: number | string;
  width?: number | string;
}

export default function BarLoader(props: Props) {
  const theme = useTheme();

  return (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 500 100"
      preserveAspectRatio="none"
      style={{
        width: props.width ?? "100%",
        height: props.height ?? "2rem",
      }}
    >
      <rect
        x="0"
        y="10"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="500"
        height="90"
      />
    </ContentLoader>
  );
}
