import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";
import { useInternalApiClient } from "../../../api/internal/InternalApiClientProvider";

type Options = UseMutationOptions<string, Error, string>;
type Result = UseMutationResult<string, Error, string>;

export function useRemoveSystemDashboard(options?: Options): Result {
  const client = useInternalApiClient();

  return useMutation({
    mutationFn: (dashboardID) => client.removeSystemDashboard(dashboardID),
    ...options,
  });
}
