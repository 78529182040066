import useRefFn from "@/hooks/useRefFn";
import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSDatabaseInstanceGroup,
  AWSDatabaseInstanceGroupFilters,
} from "../types";

type TableData = {
  databaseType: string;
  family: string;
  familyType: string;
  instanceCount: number;
  instanceUnits: number;
  lineItemUsageAccountId: string;
  networkBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  region: string;
  totalCost: number;
  usedDiskBytes: number;
};

type Props = {
  instanceGroups: AWSDatabaseInstanceGroup[];
  isLoading: boolean;
  onInteraction: (
    interaction: AWSDatabaseInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

function AWSDatabaseInstanceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      id: "totalCost",
      desc: true,
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        databaseType: instanceGroup.databaseType,
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceCount: instanceGroup.instanceIDCount,
        instanceUnits: instanceGroup.instanceUnits,
        lineItemUsageAccountId: instanceGroup.lineItemUsageAccountId,
        networkBytes: instanceGroup.networkBytes,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
        usedDiskBytes: instanceGroup.usedDiskBytes,
      })),
    [props.instanceGroups]
  );

  // useRefFn keeps the props.onInteraction closure from becoming stale
  const onInteraction = useRefFn(props.onInteraction);

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "viewInstances",
        NoAccessorCell: ({ row }) => {
          return (
            <Tooltip
              content={
                copyText.awsDatabaseTableInstanceGroupTooltipLabelViewInstances
              }
            >
              <LinkWithSearchParams
                searchParams={{
                  selected_group: getSelectedGroupJSON(row.original),
                }}
              >
                <Button
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        width: 50,
      },
      {
        accessor: "lineItemUsageAccountId",
        align: "left",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "lineItemUsageAccountId",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsDatabaseTableNull}
          </Text>
        ),
        Header: (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsComputeTableInstanceGroupHeader_lineItemUsageAccountId}
          </Tooltip>
        ),
        sortType: stringSort,
        width: 140,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsDatabaseTableNull}
          </Text>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_region,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "family",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "family",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsDatabaseTableNull}
          </Text>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_family,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "familyType",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "familyType",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsDatabaseTableNull}
          </Text>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_familyType,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "databaseType",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              onInteraction({
                type: AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "databaseType",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsDatabaseTableNull}
          </Text>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_databaseType,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "instanceCount",
        align: "center",
        Header: copyText.awsDatabaseTableInstanceGroupHeader_instanceCount,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "instanceUnits",
        align: "center",
        Header: copyText.awsDatabaseTableInstanceGroupHeader_instanceUnits,
        sortType: numberSort,
        width: 60,
      },
      {
        accessor: "totalCost",
        align: "center",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_totalCost,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productVCPU",
        align: "center",
        Header: copyText.awsDatabaseTableInstanceGroupHeader_productVCPU,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productMemoryBytes",
        align: "center",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header: copyText.awsDatabaseTableInstanceGroupHeader_productMemoryBytes,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "networkBytes",
        align: "center",
        Cell: ({ value: networkBytes }) => <>{prettyBytes(networkBytes)}</>,
        Header: copyText.awsDatabaseTableInstanceGroupHeader_networkBytes,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "usedDiskBytes",
        align: "center",
        Cell: ({ value: usedDiskBytes }) => <>{prettyBytes(usedDiskBytes)}</>,
        Header: copyText.awsDatabaseTableInstanceGroupHeader_usedDiskBytes,
        width: 100,
      },
    ],
    [onInteraction]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      lineItemUsageAccountId: datum.lineItemUsageAccountId,
      databaseType: datum.databaseType,
      region: datum.region,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return "";
  }
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKeys: (keyof TableData)[] = [
    "family",
    "familyType",
    "instanceCount",
    "lineItemUsageAccountId",
    "networkBytes",
    "productMemoryBytes",
    "productVCPU",
    "region",
    "totalCost",
    "usedDiskBytes",
  ];

  return tableDataKeys.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSDatabaseInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseInstanceGroupFilters;
  filterValue: AWSDatabaseInstanceGroupFilters[keyof AWSDatabaseInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSDatabaseInstanceGroupTable;
