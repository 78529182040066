import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

type AlertRuleEntity = Awaited<ReturnType<CoreAPIClient["getAlertRuleByID"]>>;

export default function useGetAlertRuleByID(
  alertRuleID: string,
  options?: UseQueryOptions<AlertRuleEntity, CoreAPIClientError> & {
    includeEvents?: boolean;
  }
): UseQueryResult<AlertRuleEntity, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: queryKeys.rule(alertRuleID),
    queryFn: () => client.getAlertRuleByID(alertRuleID, options?.includeEvents),
    ...options,
  });
}
