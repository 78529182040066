import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateReportsParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string[],
  CoreAPIClientError,
  UpdateReportsParameters
>;

type Result = UseMutationResult<
  string[],
  CoreAPIClientError,
  UpdateReportsParameters
>;

export default function useUpdateReports(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.updateReports(params),
    ...options,
  });
}
