import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LabelMap, QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import { getDataSourceFilters } from "@ternary/api-lib/utils/ReportUtils";
import { uniq } from "lodash";
import UError from "unilib-error";
import { useFilterStore } from "../../context/FilterStoreProvider";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import { buildCubeQuery } from "./utils";

interface Params {
  dataSource: DataSource;
  dateRange: Date[] | [string, string];
  dimensions: string[];
  measure?: string;
  queryFilters?: QueryFilter[];
}

interface DimensionValuesMap {
  [key: string]: string[];
}

export default function useGetDimensionValuesByDataSource(
  params: Params,
  options?: UseQueryOptions<DimensionValuesMap, UError>
): UseQueryResult<DimensionValuesMap, UError> {
  const filterStore = useFilterStore();
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["dimensionValues", params, filterStore.queryFilters],
    queryFn: async () => {
      const globalFilters = getDataSourceFilters(
        filterStore.queryFilters,
        params.dataSource
      );

      const result = await client.load(
        buildCubeQuery({
          ...params,
          measures: params.measure ? [params.measure] : undefined,
          queryFilters: [
            ...globalFilters,
            ...(params.queryFilters ? params.queryFilters : []),
          ],
        })
      );

      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const labelMap: LabelMap = labelMaps[params.dataSource] ?? {};

      const reversedLabelMap = Object.entries(labelMap).reduce(
        (accum: { [key: string]: string }, [key, value]) => {
          accum[value] = key;
          return accum;
        },
        {}
      );

      const dimensionValuesMap = result.reduce(
        (accum: DimensionValuesMap, datum) => {
          Object.entries(datum).forEach(([key, value]) => {
            if (typeof value !== "string") return;

            const dimension = reversedLabelMap[key]
              ? reversedLabelMap[key]
              : key;

            if (!accum[dimension]) {
              accum[dimension] = [value];
              return;
            }

            if (accum[dimension]) {
              accum[dimension].push(value);
              return;
            }
          }, {});

          return accum;
        },
        {}
      );

      return Object.entries(dimensionValuesMap).reduce(
        (accum, [key, value]) => {
          accum[key] = uniq(value);
          return accum;
        },
        {}
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
