import { CloudSQLUsageDatum } from "./gcp-database-cloud-sql/types";
import { StorageUsageDatum } from "./gcp-storage/types";

export const ebsCostChartDimension = [
  "billPayerAccountId",
  "region",
  "storageMedia",
  "type",
  "volumeId",
];

export const ebsUsageChartDimensions = ["region", "storageMedia", "volumeId"];

export const ebsUsageChartMeasures = [
  "bareMetalCost",
  "creditTotal",
  "maxIopsBurstPerformance",
  "maxIopsVolumeBytes",
  "maxThroughputVolumeBytes",
  "maxVolumeSizeBytes",
  "operationsCost",
  "otherCost",
  "provisionedThroughputCost",
  "snapshotCost",
  "storageCost",
  "storageUsageBytes",
  "cost",
  "totalReadTime",
  "totalWriteTime",
  "volumeIdleTime",
  "volumeQueueLength",
  "volumeReadBytes",
  "volumeReadOps",
  "volumeWriteBytes",
  "volumeWriteOps",
];

export const s3CostChartDimension = [
  "accountID",
  "bucketName",
  "region",
  "storageClass",
];

export const s3UsageChartDimensions = ["storageClass", "region", "bucketName"];

export const s3UsageChartMeasures = [
  "bytesDownloaded",
  "bytesUploaded",
  "requestCount",
  "numberOfObjects",
  "storageUsedBytes",
];

export const classificationDimensions = ["projectId", "databaseType", "region"];

export const cloudSQLUsageMeasures: (keyof CloudSQLUsageDatum)[] = [
  "maxCpuReservedCores",
  "totalCpuReservedCores",

  "maxCpuAverageUsedCores",
  "totalCpuUsedCoreHours",

  "maxDiskSizeBytes",
  "totalDiskSizeBytes",

  "maxDiskUsedBytes",
  "totalDiskUsedBytes",

  "ramReservedBytes",
  "totalRamReservedBytes",

  "maxRamAverageUsedBytes",
  "totalRamUsedBytes",

  "maxRamUsedBytesWithBuffer",
  "totalRamUsedBytesWithBuffer",

  "networkSentBytes",
  "totalNetworkSentBytes",
];

export const storageCostDimensions = [
  "costKind",
  "lowCarbon",
  "projectId",
  "storageClass",
  "region",
];

export const storageUsageDimensions = ["storageClass", "method", "location"];

export const storageUsageMeasures: (keyof StorageUsageDatum)[] = [
  "totalStoredByteSeconds",
  "totalSentBytes",
  "totalReceivedBytes",
  "totalRequestCount",
];
