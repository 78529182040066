import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UpdateSlackIntegrationParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  UpdateSlackIntegrationParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  UpdateSlackIntegrationParams
>;

export default function useUpdateSlackIntegration(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: async (params) => client.updateSlackIntegration(params),
    ...options,
  });
}
