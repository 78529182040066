import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CustomMetricGates = ReturnType<typeof customMetricGates>;

export default function customMetricGates(params: GateGetterParameters) {
  return {
    canCreateCustomMetrics: params._permissionsIncludeAllOf([
      Permission.CREATE_CUSTOM_METRIC,
    ]),
    canDeleteCustomMetrics: params._permissionsIncludeAllOf([
      Permission.DELETE_CUSTOM_METRIC,
    ]),
    canListCustomMetrics: params._permissionsIncludeAllOf([
      Permission.READ_CUSTOM_METRICS,
    ]),
    canUpdateCustomMetrics: params._permissionsIncludeAllOf([
      Permission.UPDATE_CUSTOM_METRIC,
    ]),
  };
}
