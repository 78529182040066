import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable, Unwrap } from "../types";

export const EBStoreStruct = {
  billPayerAccountId: nullable(string()),
  lineItemUsageAccountId: nullable(string()),
  maxIopsVolumeBytes: nullable(number()),
  maxThroughputVolumeBytes: nullable(number()),
  maxVolumeSizeBytes: nullable(number()),
  operationsCost: nullable(number()),
  region: nullable(string()),
  family: nullable(string()),
  snapshotCost: nullable(number()),
  snapshotUsageBytes: nullable(number()),
  storageCost: nullable(number()),
  storageUsageBytes: nullable(number()),
  totalCost: nullable(number()),
  totalReadTime: nullable(number()),
  totalWriteTime: nullable(number()),
  type: nullable(string()),
  volumeId: nullable(string()),
  volumeReadOps: nullable(number()),
  volumeWriteOps: nullable(number()),
};

export type EBStore = NotNullable<Infer<typeof EBStoreStruct>>;

export type EBStoreGroup = Unwrap<
  Pick<
    EBStore,
    | "billPayerAccountId"
    | "operationsCost"
    | "region"
    | "snapshotCost"
    | "snapshotUsageBytes"
    | "storageCost"
    | "storageUsageBytes"
    | "totalCost"
  > & {
    groupID: string;
    volumes: EBStore[];
    snapshots: EBStore[];
  }
>;

export type EBStoreGroupFilters = {
  billPayerAccountId: string | null;
  region: string | null;
};
