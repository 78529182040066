import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAzureVM(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg
        id="ec6dc5e8-5958-4688-833b-f95ec061a4a8"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="abe3e0fd-f5f5-4d67-8cca-cb6314d5da62"
            x1="8.86"
            y1="13.02"
            x2="8.86"
            y2="1.02"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#0078d4" />
            <stop offset="0.82" stopColor="#5ea0ef" />
          </linearGradient>
          <linearGradient
            id="a49c2fd2-7c71-4d63-b68f-ddf5594dd0b8"
            x1="8.86"
            y1="17.65"
            x2="8.86"
            y2="13.02"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#1490df" />
            <stop offset="0.98" stopColor="#1f56a3" />
          </linearGradient>
        </defs>
        <title>Icon-compute-28</title>
        <rect
          x="-0.14"
          y="1.02"
          width="18"
          height="12"
          rx="0.6"
          fill="url(#abe3e0fd-f5f5-4d67-8cca-cb6314d5da62)"
        />
        <rect x="0.86" y="2.02" width="16" height="10" rx="0.33" fill="#fff" />
        <polygon
          points="11.86 5.27 11.86 8.76 8.86 10.52 8.86 7.02 11.86 5.27"
          fill="#0078d4"
        />
        <polygon
          points="11.86 5.27 8.86 7.03 5.86 5.27 8.86 3.52 11.86 5.27"
          fill="#83b9f9"
        />
        <polygon
          points="8.86 7.03 8.86 10.52 5.86 8.76 5.86 5.27 8.86 7.03"
          fill="#5ea0ef"
        />
        <polygon
          points="5.86 8.76 8.86 7.02 8.86 10.52 5.86 8.76"
          fill="#83b9f9"
          opacity="0.2"
        />
        <polygon
          points="11.86 8.76 8.86 7.02 8.86 10.52 11.86 8.76"
          fill="#5ea0ef"
          opacity="0.2"
        />
        <path
          d="M12.46,16.65c-1.77-.28-1.84-1.57-1.84-3.63H7.09c0,2.06-.07,3.35-1.84,3.63a1,1,0,0,0-.89,1h9A1,1,0,0,0,12.46,16.65Z"
          fill="url(#a49c2fd2-7c71-4d63-b68f-ddf5594dd0b8)"
        />
      </svg>
    </Icon>
  );
}
