import { keyframes, useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { partition } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { DurationType } from "../../constants/enums";
import { getTimeDurationCaption } from "../../utils/ReportUtils";
import Box from "../components/Box";
import EmptyPlaceholder from "../components/EmptyPlaceholder";
import Flex from "../components/Flex";
import Icon from "../components/Icon";
import Text from "../components/Text";
import { formatPercentage } from "../utils/formatNumber";
import { Measure, RawData } from "./types";
import { COMPARISON_KEY, formatMeasureValueWithUnit } from "./utils";

const customFontSize = "2.5rem";

type Props = {
  data: RawData[];
  durationType: DurationType;
  isFiscalMode: boolean;
  isLoading: boolean;
  isServerChart: boolean;
  measures: Measure[];
};

export default function KPIChart(props: Props) {
  const theme = useTheme();

  const [formattedValue, value, formattedComparisonValue, comparisonValue] =
    useMemo(() => {
      const datum = props.data.at(0) ?? null;

      if (!datum) {
        return [null, null, null, null] as const;
      }

      const [measures, comparisonMeasures] = partition(
        props.measures,
        (measure) => !measure.name.includes(COMPARISON_KEY)
      );

      const measure = measures.at(0) ?? null;
      const value = measure ? datum[measure.name] : null;
      let formattedValue: string | null = null;

      if (typeof value === "number" && measure !== null) {
        formattedValue = formatMeasureValueWithUnit({
          value,
          unit: measure.unit,
        });
      }

      const comparisonMeasure = comparisonMeasures.at(0) ?? null;
      const comparisonValue = comparisonMeasure
        ? datum[comparisonMeasure.name]
        : null;

      let formattedComparisonValue: string | null = null;

      if (typeof comparisonValue === "number" && comparisonMeasure !== null) {
        formattedComparisonValue = formatMeasureValueWithUnit({
          value: comparisonValue,
          unit: comparisonMeasure.unit,
        });
      }

      return [
        formattedValue,
        typeof value === "number" ? value : null,
        formattedComparisonValue,
        typeof comparisonValue === "number" ? comparisonValue : null,
      ] as const;
    }, [props.data, props.measures]);

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.panel_backgroundColor}
      direction="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      {props.isLoading ? (
        <Box maxHeight={300} maxWidth={600}>
          <EmptyPlaceholder
            height="100%"
            icon={faList}
            loading={props.isLoading}
            skeletonVariant="meter"
            small
            width="100%"
          />
        </Box>
      ) : (
        <>
          <Flex
            direction="column"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <Flex alignItems="center">
              <Text as="div" bold fontSize={theme.fontSize_base}>
                {props.measures[0]?.name}
              </Text>
              <Text
                as="div"
                marginLeft={theme.space_sm}
                color={theme.text_color_secondary}
              >
                {getTimeDurationCaption(props.durationType, props.isLoading)}
              </Text>
            </Flex>

            <Text as="div" bold fontSize={customFontSize}>
              <AnimatedText
                durationMS={200}
                delayMS={50}
                isServer={props.isServerChart}
              >
                {formattedValue ?? "---"}
              </AnimatedText>
            </Text>

            {formattedComparisonValue !== null && (
              <Text
                as="div"
                bold
                color={theme.text_color_secondary}
                fontSize={theme.fontSize_base}
              >
                <AnimatedText
                  durationMS={200}
                  delayMS={50}
                  isServer={props.isServerChart}
                >
                  {formattedComparisonValue ?? "---"}
                </AnimatedText>
              </Text>
            )}

            {value !== null && comparisonValue !== null && (
              <Box>
                <PercentageDelta
                  value={value}
                  comparison={comparisonValue}
                  isServer={props.isServerChart}
                />
              </Box>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
}

type PercentageDeltaProps = {
  value: number;
  comparison: number;
  isServer: boolean;
};

function PercentageDelta(props: PercentageDeltaProps) {
  const theme = useTheme();
  const percentageDelta = getChangePercent(props.value, props.comparison);

  return (
    <Flex alignItems="center">
      {percentageDelta > 0 && <Icon icon={faArrowUp} />}
      {percentageDelta < 0 && <Icon icon={faArrowDown} />}
      <Text
        as="div"
        bold
        fontSize={theme.h4_fontSize}
        marginLeft={percentageDelta === 0 ? 0 : theme.space_xs}
      >
        <AnimatedText durationMS={200} delayMS={50} isServer={props.isServer}>
          {formatPercentage(percentageDelta)}
        </AnimatedText>
      </Text>
    </Flex>
  );
}

function getChangePercent(value: number, comparison: number): number {
  // no comparison is supported as empty space
  if (isNaN(Number(value)) || isNaN(Number(comparison))) {
    return 0;
  }

  // In the case of Infinity, just force the UI to "100%"
  if (comparison === 0) return 1;

  return (value - comparison) / Math.abs(comparison);
}

const slideIn = keyframes({
  from: {
    transform: "translateY(-10px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

type AnimatedTextProps = {
  children: string;
  delayMS?: number;
  durationMS?: number;
  isServer: boolean;
};

function AnimatedText(props: AnimatedTextProps) {
  const [updateID, setUpdateID] = useState(0);

  useEffect(() => {
    if (!props.isServer) {
      setUpdateID((updateID) => updateID + 1);
    }
  }, [props.children]);

  if (props.isServer) {
    return (
      <Box display="inline-block" position="relative">
        {props.children}
      </Box>
    );
  }

  return (
    <>
      {props.children.split("").map((char, index) => {
        const delay = (props.delayMS ?? 0) * index;

        return (
          <Box
            animation={`${slideIn} ${props.durationMS}ms ${delay}ms forwards`}
            display="inline-block"
            key={`${char}-${index}-${updateID}`}
            opacity={0}
            position="relative"
          >
            {char}
          </Box>
        );
      })}
    </>
  );
}
