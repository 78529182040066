import { DataSource } from "./enums";

export const DEFAULT_CHART_GROUPINGS_LIMIT = 25;

type MeasureWithUnit = {
  name: string;
  unit: UnitType;
};

export const UnitType = {
  BINARY_BYTES: "BINARY_BYTES",
  BYTES: "BYTES",
  CORES: "CORES",
  CURRENCY: "CURRENCY",
  KILOGRAMS: "KILOGRAMS",
  PERCENTAGE: "PERCENTAGE",
  STANDARD: "STANDARD",
} as const;

export type UnitType = (typeof UnitType)[keyof typeof UnitType];

//
// AWS Compute
//

export const awsComputeUtilizationMeasures: MeasureWithUnit[] = [
  { name: "instanceCountWithCPUMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithDiskMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithGpuMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithMemoryMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithNetworkInMetrics", unit: UnitType.STANDARD },
  { name: "instanceCountWithNetworkOutMetrics", unit: UnitType.STANDARD },
  { name: "maxOfAverageCPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageGPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "maxOfAverageMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageCPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageGPUUtilization", unit: UnitType.PERCENTAGE },
  { name: "minOfAverageMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p50OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p95OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxCpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxDiskUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxGpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "p99OfMaxMemoryUtilization", unit: UnitType.PERCENTAGE },
  { name: "sumUsageCost", unit: UnitType.CURRENCY },
  { name: "sumUsageHours", unit: UnitType.STANDARD },
];

const awsComputeUtilizationDimensions = [
  "usageAccountId",
  "productInstanceTypeFamily",
  "productInstanceType",
  "productInstanceFamily",
  "vcpuPerInstance",
  "gpuPerInstance",
  "memoryPerInstance",
  "productRegion",
];

export const ec2Measures: MeasureWithUnit[] = [
  { name: "totalCost", unit: UnitType.CURRENCY },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "productVCPUChart", unit: UnitType.STANDARD },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "networkInBytes", unit: UnitType.BYTES },
  { name: "networkOutBytes", unit: UnitType.BYTES },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
];

const ec2Dimensions = [
  "billPayerAccountId",
  "family",
  "instanceType",
  "region",
];

//
// AWS Kubernetes
//

const awsKubernetesNodeCostMeasures: MeasureWithUnit[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "savingsPlanCoveredCost", unit: UnitType.CURRENCY },
  { name: "nonSavingsPlanCoveredCost", unit: UnitType.CURRENCY },
];

const awsKubernetesNodeUsageMeasures: MeasureWithUnit[] = [
  { name: "sum_TotalCPUHour", unit: UnitType.STANDARD },
  { name: "sum_TotalMemoryByteHour", unit: UnitType.BYTES },
  { name: "sum_RemainingCPUHour", unit: UnitType.STANDARD },
  { name: "sum_RemainingMemoryByteHour", unit: UnitType.BYTES },
  { name: "sum_UsedCPUHour", unit: UnitType.BYTES },
  { name: "sum_UsedMemoryByteHour", unit: UnitType.BYTES },
  { name: "max_PerNodeHour_CPUUtilization", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_FilesystemUtilization", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_CPUReservedCapacity", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_MemoryReservedCapacity", unit: UnitType.STANDARD },
  { name: "max_PerNodeHour_MemoryUtilization", unit: UnitType.STANDARD },
  {
    name: "max_PerNodeHour_NetworkTotalBytesPerSecond",
    unit: UnitType.STANDARD,
  },
  {
    name: "max_PerNodeHour_NumberOfRunningContainers",
    unit: UnitType.STANDARD,
  },
  { name: "max_PerNodeHour_NumberOfRunningPods", unit: UnitType.STANDARD },
];

const awsKubernetesNodeCostDimensions = [
  "accountId",
  "clusterName",
  "nodeName",
  "instanceId",
  "instanceType",
  "instanceTypeFamily",
  "vcpuPerInstance",
  "memoryPerInstance",
  "region",
  "lineItemDescription",
  "timestamp",
  "costKind",
];

const awsKubernetesNodeUsageDimensions = [
  "accountId",
  "clusterName",
  "nodeName",
  "instanceId",
  "instanceType",
  "instanceTypeFamily",
  "vcpuPerInstance",
  "memoryPerInstance",
  "region",
  "timestamp",
];

//
// Azure Storage Block Blob
//

export const AzureStorageBBDimensions = [
  "accountId",
  "pricingModel",
  "projectId",
  "resourceId",
  "skuDescription",
  "storageAccount",
];

export const AzureStorageBBMeasures: MeasureWithUnit[] = [
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "blobSizeBytes", unit: UnitType.BINARY_BYTES },
  { name: "bytesDownloaded", unit: UnitType.BYTES },
  { name: "bytesUploaded", unit: UnitType.BYTES },
  { name: "containerCount", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "transactions", unit: UnitType.STANDARD },
  { name: "usageAmount", unit: UnitType.STANDARD },
];

//
// Azure Virtual Machines
//

export const azureVMComputeMeasures: MeasureWithUnit[] = [
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "cpuUtilization", unit: UnitType.PERCENTAGE },
  { name: "ramAvailableBytes", unit: UnitType.BINARY_BYTES },
  { name: "receivedBytes", unit: UnitType.BINARY_BYTES },
  { name: "sentBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskReadBytes", unit: UnitType.BINARY_BYTES },
  { name: "diskWriteBytes", unit: UnitType.BINARY_BYTES },
];

const azureVMComputeDimensions = [
  "family",
  "instanceType",
  "location",
  "operatingSystem",
  "projectId",
];

//
// Azure SQL Databases
//

export const azureSqlDatabaseMeasures: MeasureWithUnit[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "cpuPercentMin", unit: UnitType.PERCENTAGE },
  { name: "cpuPercentMax", unit: UnitType.PERCENTAGE },
  { name: "cpuPercentAvg", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentMin", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentMax", unit: UnitType.PERCENTAGE },
  { name: "memoryPercentAvg", unit: UnitType.PERCENTAGE },
  { name: "cores", unit: UnitType.CORES },
  { name: "memoryGB", unit: UnitType.BINARY_BYTES },
  { name: "storageCapacityGB", unit: UnitType.BINARY_BYTES },
  { name: "connections", unit: UnitType.STANDARD },
  { name: "storagePercent", unit: UnitType.PERCENTAGE },
  { name: "resourceCount", unit: UnitType.STANDARD },
];

const azureSqlDatabaseDimensions = [
  "projectId",
  "resourceId",
  "instanceName",
  "location",
  "skuName",
  "skuTier",
  "databaseEngine",
  "billingMethod",
  "computeTier",
  "backupStorageRedundancy",
  "backupRetentionPeriodDays",
  "highAvailabilityEnabled",
];

//
// Big Query
//

const bigQueryCapacityMeasures: MeasureWithUnit[] = [
  { name: "slotMsCommitted", unit: UnitType.STANDARD },
];

const bigQueryCostMeasures: MeasureWithUnit[] = [
  { name: "computeCost", unit: UnitType.CURRENCY },
  { name: "storageCost", unit: UnitType.CURRENCY },
  { name: "egressCost", unit: UnitType.CURRENCY },
  { name: "streamingInsertCost", unit: UnitType.CURRENCY },
];

const bigQueryCostDimensions = ["projectId"];

const bigQueryStorageMeasures: MeasureWithUnit[] = [
  { name: "activeLogicalB", unit: UnitType.BYTES },
  { name: "longTermLogicalB", unit: UnitType.BYTES },
  { name: "activePhysicalB", unit: UnitType.BYTES },
  { name: "longTermPhysicalB", unit: UnitType.BYTES },
];

const bigQueryStorageDimensions = [
  "datasetProjectId",
  "datasetName",
  "location",
  "storageBillingModel",
];

const bigQueryUsageMeasures: MeasureWithUnit[] = [
  { name: "onDemandCost", unit: UnitType.CURRENCY },
  { name: "totalSlotS", unit: UnitType.STANDARD },
  { name: "avgJobDuration", unit: UnitType.STANDARD },
  { name: "jobCount", unit: UnitType.STANDARD },
];

const bigQueryUsageDimensions = [
  "timestamp",
  "userEmail",
  "projectId",
  "datasetProjectId",
  "datasetId",
  "tableId",
  "jobType",
  "reservationId",
  "region",
  "statementType",
  "priority",
];

const bigQueryTimelineMeasures: MeasureWithUnit[] = [
  { name: "totalSlotMs", unit: UnitType.STANDARD },
];

//
// Billing
//

// Same measures are used for both Billing & Detailed Billing
const billingMeasures: MeasureWithUnit[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "absoluteCredits", unit: UnitType.CURRENCY },
  { name: "netCost", unit: UnitType.CURRENCY },
  { name: "usageAmount", unit: UnitType.STANDARD },
  { name: "usageAmountInPricingUnits", unit: UnitType.STANDARD },
  { name: "customNetCost", unit: UnitType.CURRENCY },
  { name: "amortizedCost", unit: UnitType.CURRENCY },
  { name: "netAmortizedCost", unit: UnitType.CURRENCY },
  { name: "indirectCost", unit: UnitType.CURRENCY },
  { name: "absoluteCreditsResellerMargin", unit: UnitType.CURRENCY },
];

// If customer has AWS integration we combine these with regular billing
export const awsBillingMeasures: MeasureWithUnit[] = [
  { name: "awsUsageBlendedCost", unit: UnitType.CURRENCY },
  { name: "awsNetUnblendedCost", unit: UnitType.CURRENCY },
  { name: "awsReservationEffectiveCost", unit: UnitType.CURRENCY },
  { name: "awsReservationRecurringFeeForUsage", unit: UnitType.CURRENCY },
  {
    name: "awsReservationAmortizedUpfrontCostForUsage",
    unit: UnitType.CURRENCY,
  },
  {
    name: "awsReservationAmortizedUpfrontFeeForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  { name: "awsSavingsPlanEffectiveCost", unit: UnitType.CURRENCY },
  {
    name: "awsSavingsPlanAmortizedUpfrontCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
  {
    name: "awsSavingsPlanRecurringCommitmentForBillingPeriod",
    unit: UnitType.CURRENCY,
  },
];

const billingDimensions = [
  "ancestryIds",
  "ancestryNames",
  "billingAccountId",
  "billingAccountName",
  "billingEntity",
  "category",
  "cloudId",
  "cloudName",
  "commitmentPaymentModel",
  "customReason",
  "databaseEngine",
  "dataSource",
  "invoiceMonth",
  "location",
  "operatingSystem",
  "parentId",
  "parentName",
  "pricingModel",
  "pricingUnit",
  "projectId",
  "projectName",
  "projectNumber",
  "region",
  "serviceDescription",
  "serviceId",
  "skuDescription",
  "skuId",
  "tenancy",
  "usageUnit",
  "vendor",
  "zone",
];

// Detailed Billing is just regular billing + resourceId
const detailedBillingDimensions = [...billingDimensions, "resourceId"];

//
// Carbon Footprint
//

const carbonFootprintMeasures: MeasureWithUnit[] = [
  { name: "locationBasedOffset", unit: UnitType.KILOGRAMS },
  { name: "scope1", unit: UnitType.KILOGRAMS },
  { name: "scope2Location", unit: UnitType.KILOGRAMS },
  { name: "scope3", unit: UnitType.KILOGRAMS },
];

const carbonFootprintDimensions = [
  "carbonModel",
  "billingAccountId",
  "location",
  "region",
  "projectId",
  "serviceId",
  "serviceDescription",
];

//
// Cloud SQL
//

const cloudSqlCostMeasures: MeasureWithUnit[] = [
  { name: "cpuAndRamCost", unit: UnitType.CURRENCY },
  { name: "remainingCost", unit: UnitType.CURRENCY },
  { name: "storageCost", unit: UnitType.CURRENCY },
  { name: "cost", unit: UnitType.CURRENCY },
];

const cloudSqlUsageMeasures: MeasureWithUnit[] = [
  { name: "maxCpuAverageUsedCores", unit: UnitType.KILOGRAMS },
  { name: "maxCpuReservedCores", unit: UnitType.BYTES },
  { name: "maxDiskSizeBytes", unit: UnitType.BYTES },
  { name: "maxDiskUsedBytes", unit: UnitType.BYTES },
  { name: "maxRamAverageUsedBytes", unit: UnitType.BYTES },
  { name: "maxRamUsedBytesWithBuffer", unit: UnitType.BYTES },
  { name: "networkSentBytes", unit: UnitType.BYTES },
  { name: "ramReservedBytes", unit: UnitType.BYTES },
  { name: "totalCpuReservedCores", unit: UnitType.STANDARD },
  { name: "totalCpuUsedCoreHours", unit: UnitType.STANDARD },
  { name: "totalDiskSizeBytes", unit: UnitType.BYTES },
  { name: "totalDiskUsedBytes", unit: UnitType.BYTES },
  { name: "totalNetworkSentBytes", unit: UnitType.BYTES },
  { name: "totalRamReservedBytes", unit: UnitType.BYTES },
  { name: "totalRamUsedBytes", unit: UnitType.BYTES },
  { name: "totalRamUsedBytesWithBuffer", unit: UnitType.BYTES },
];

const cloudSqlCostDimensions = [
  "availability",
  "classification",
  "databaseType",
  "instanceType",
  "projectId",
  "region",
  "replica",
];

const cloudSqlInstanceUsageDimensions = [
  "availability",
  "databaseId",
  "databaseType",
  "projectId",
  "region",
];

//
// Google Compute Engine
//

const gceMeasures: MeasureWithUnit[] = [
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "diskReadBytes", unit: UnitType.BYTES },
  { name: "diskWriteBytes", unit: UnitType.BYTES },
  { name: "provisionedDiskBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytes", unit: UnitType.BYTES },
  { name: "productMemoryBytesChart", unit: UnitType.BYTES },
  { name: "receivedBytes", unit: UnitType.BYTES },
  { name: "sentBytes", unit: UnitType.BYTES },
  { name: "receivedBytes", unit: UnitType.BYTES },
  { name: "productVCPU", unit: UnitType.BYTES },
  { name: "productVCPUChart", unit: UnitType.BYTES },
];

const gceDimensions = [
  "category",
  "family",
  "operatingSystem",
  "projectId",
  "region",
  "storageClass",
  "storageType",
];

//
// Google Kubernetes Engine
//

const kubernetesContainerUsageMeasures: MeasureWithUnit[] = [
  { name: "gpuRequests", unit: UnitType.STANDARD },
  { name: "limitCPUCores", unit: UnitType.STANDARD },
  { name: "limitMemoryBytes", unit: UnitType.BYTES },
  { name: "requestCPUCores", unit: UnitType.STANDARD },
  { name: "requestCPUCost", unit: UnitType.CURRENCY },
  { name: "requestMemoryBytes", unit: UnitType.BYTES },
  { name: "requestMemoryCost", unit: UnitType.CURRENCY },
  { name: "totalGpuMemoryBytes", unit: UnitType.BYTES },
  { name: "totalRequestCost", unit: UnitType.CURRENCY },
  { name: "totalWasteCost", unit: UnitType.CURRENCY },
  { name: "usedCPUCores", unit: UnitType.STANDARD },
  { name: "usedCPUCost", unit: UnitType.CURRENCY },
  { name: "usedGpuMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryCost", unit: UnitType.CURRENCY },
  { name: "wasteCPUs", unit: UnitType.STANDARD },
  { name: "wasteMemoryBytes", unit: UnitType.BYTES },
  { name: "wasteCPUCost", unit: UnitType.CURRENCY },
  { name: "wasteMemoryCost", unit: UnitType.CURRENCY },
];

const kubernetesNodeUsageMeasures: MeasureWithUnit[] = [
  { name: "CPUCoreUsageTime", unit: UnitType.STANDARD },
  { name: "cost", unit: UnitType.CURRENCY },
  { name: "totalCPUCores", unit: UnitType.STANDARD },
  { name: "totalCPUCost", unit: UnitType.CURRENCY },
  { name: "totalGPUMemoryBytes", unit: UnitType.BYTES },
  { name: "totalMemoryCost", unit: UnitType.CURRENCY },
  { name: "totalMemoryBytes", unit: UnitType.BYTES },
  { name: "usedGPUMemoryBytes", unit: UnitType.BYTES },
  { name: "usedMemoryBytes", unit: UnitType.BYTES },
  { name: "wasteCPUs", unit: UnitType.STANDARD },
  { name: "wasteMemoryBytes", unit: UnitType.BYTES },
];

const kubernetesContainerUsageDimensions = [
  "cloudId",
  "clusterName",
  "containerName",
  "location",
  "namespace",
  "nodeName",
  "nodepool",
  "podName",
  "projectId",
  "workload",
];

const kubernetesNodeUsageDimensions = [
  "cloudId",
  "clusterName",
  "location",
  "nodeName",
  "nodepool",
  "projectId",
];

//
// Snowflake
//

const snowflakeWarehouseUsageMeasures: MeasureWithUnit[] = [
  { name: "computeCreditsUsed", unit: UnitType.STANDARD },
  { name: "cloudServicesCreditsUsed", unit: UnitType.STANDARD },
  { name: "totalCreditsUsed", unit: UnitType.STANDARD },
  { name: "queryCount", unit: UnitType.STANDARD },
  { name: "queryElapsedTime", unit: UnitType.STANDARD },
];

const snowflakeWarehouseUsageDimensions = [
  "accountName",
  "warehouseName",
  "warehouseID",
  "warehouseSize",
  "warehouseType",
  "tags",
];

//
// Maps
//

export const availableMeasuresMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: awsComputeUtilizationMeasures,
  [DataSource.AWS_COMPUTE_VISIBILITY]: [
    ...awsComputeUtilizationMeasures,
    ...ec2Measures,
  ],
  [DataSource.AWS_KUBERNETES_NODE_COST]: awsKubernetesNodeCostMeasures,
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: awsKubernetesNodeUsageMeasures,
  [DataSource.AZURE_COMPUTE_VISIBILITY]: azureVMComputeMeasures,
  [DataSource.AZURE_SQL_VISIBILITY]: azureSqlDatabaseMeasures,
  [DataSource.AZURE_STORAGE_VISIBILITY]: AzureStorageBBMeasures,
  [DataSource.BIGQUERY_CAPACITY_COMMITTED]: bigQueryCapacityMeasures,
  [DataSource.BIGQUERY_COST]: bigQueryCostMeasures,
  [DataSource.BIGQUERY_STORAGE]: bigQueryStorageMeasures,
  [DataSource.BIGQUERY_TIMELINE]: bigQueryTimelineMeasures,
  [DataSource.BIGQUERY_USAGE]: bigQueryUsageMeasures,
  [DataSource.BILLING]: billingMeasures,
  [DataSource.CARBON_FOOTPRINT]: carbonFootprintMeasures,
  [DataSource.CLOUD_SQL_COST]: cloudSqlCostMeasures,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: cloudSqlUsageMeasures,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: cloudSqlUsageMeasures,
  [DataSource.DETAILED_BILLING]: billingMeasures,
  [DataSource.GCP_COMPUTE_VISIBILITY]: gceMeasures,
  [DataSource.KUBERNETES_CONTAINER_USAGE]: kubernetesContainerUsageMeasures,
  [DataSource.KUBERNETES_NODE_USAGE]: kubernetesNodeUsageMeasures,
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: snowflakeWarehouseUsageMeasures,
};

export const defaultDimensionsMap = {
  [DataSource.AWS_COMPUTE_UTILIZATION]: awsComputeUtilizationDimensions,
  [DataSource.AWS_COMPUTE_VISIBILITY]: ec2Dimensions,
  [DataSource.AWS_KUBERNETES_NODE_COST]: awsKubernetesNodeCostDimensions,
  [DataSource.AWS_KUBERNETES_NODE_USAGE]: awsKubernetesNodeUsageDimensions,
  [DataSource.AZURE_COMPUTE_VISIBILITY]: azureVMComputeDimensions,
  [DataSource.AZURE_SQL_VISIBILITY]: azureSqlDatabaseDimensions,
  [DataSource.AZURE_STORAGE_VISIBILITY]: AzureStorageBBDimensions,
  [DataSource.BIGQUERY_COST]: bigQueryCostDimensions,
  [DataSource.BIGQUERY_STORAGE]: bigQueryStorageDimensions,
  [DataSource.BIGQUERY_USAGE]: bigQueryUsageDimensions,
  [DataSource.BILLING]: billingDimensions,
  [DataSource.CARBON_FOOTPRINT]: carbonFootprintDimensions,
  [DataSource.CLOUD_SQL_COST]: cloudSqlCostDimensions,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY]: cloudSqlInstanceUsageDimensions,
  [DataSource.CLOUD_SQL_INSTANCE_USAGE]: cloudSqlInstanceUsageDimensions,
  [DataSource.DETAILED_BILLING]: detailedBillingDimensions,
  [DataSource.EXTERNAL_METRICS]: [],
  [DataSource.GCP_COMPUTE_VISIBILITY]: gceDimensions,
  [DataSource.KUBERNETES_CONTAINER_USAGE]: kubernetesContainerUsageDimensions,
  [DataSource.KUBERNETES_NODE_USAGE]: kubernetesNodeUsageDimensions,
  [DataSource.SNOWFLAKE_WAREHOUSE_USAGE]: snowflakeWarehouseUsageDimensions,
};

export const dataSourcesWithMeasures = Object.keys(
  availableMeasuresMap
) as (keyof typeof availableMeasuresMap)[];
