import paths, { locationStateKeyedByPath, Path } from "@/constants/paths";
import {
  FILTER_DATA_KEY,
  GLOBAL_DATE_END,
  GLOBAL_DATE_START,
  GLOBAL_DURATION_TYPE,
} from "@/constants/searchParams";
import { useFilterStore } from "@/context/FilterStoreProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { DataSource, Operator } from "@ternary/api-lib/constants/enums";
import Link from "@ternary/web-ui-lib/components/Link";
import React from "react";
import {
  createSearchParams,
  LinkProps,
  matchPath,
  NavigateOptions,
  URLSearchParamsInit,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import UError from "unilib-error";

const pathValues = Object.values(paths);

export type FilterData = {
  auth_id?: string;
  filters?: {
    dataSource: DataSource;
    name: string;
    operator: Operator;
    values?: string[] | null;
  }[];
  sv_ids?: string[];
};

//
// LinkWithSearchParams
//

/**
 * Custom Link component. Wraps react-router Link and takes the same props. Should
 * be used for any in app navigation to enforce our app specific search param rules.
 * @see https://reactrouter.com/docs/en/v6/api#link
 */

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type LinkPropsWithOptionalTo = PartialBy<LinkProps, "to">;

type LinkWithSearchParamsProps = LinkPropsWithOptionalTo & {
  disabled?: boolean;
  searchParams?: URLSearchParamsInit;
};

export function LinkWithSearchParams({
  disabled,
  searchParams: newSearchParamsInit,
  to,
  ...props
}: LinkWithSearchParamsProps): JSX.Element {
  const [currentSearchParams] = useSearchParams();
  const location = useLocation();
  const authenticatedUser = useAuthenticatedUser();
  const filterStore = useFilterStore();

  if (disabled) return <>{props.children}</>;

  const isNewPath = to !== undefined;
  const pathname =
    to === undefined
      ? location.pathname
      : typeof to === "string"
        ? to
        : to.pathname;

  const match = pathValues.find((path) => matchPath(path, pathname ?? ""));

  const locationState = locationStateKeyedByPath[match ?? ""];

  let nextSearchParams: URLSearchParams;

  if (!newSearchParamsInit && !isNewPath) {
    throw new UError(
      'Must provide at least one of the following props: "to" or "searchParams"',
      { context: { pathname, search: currentSearchParams.toString() } }
    );
  }

  if (newSearchParamsInit && isNewPath) {
    // New path with new params
    nextSearchParams = createSearchParams(newSearchParamsInit);
  } else if (newSearchParamsInit && !isNewPath) {
    // Same path with updates to current params
    const newSearchParams = createSearchParams(newSearchParamsInit);

    const mergedSearchParamsInit = {
      ...URLSearchParamsToObject(currentSearchParams),
      ...URLSearchParamsToObject(newSearchParams),
    };

    // Remove params with empty string values
    const nextSearchParamsInit = Object.entries(mergedSearchParamsInit).reduce(
      (accum, [key, value]) => {
        if (value === "") {
          return accum;
        }

        return {
          ...accum,
          [key]: value,
        };
      },
      {} as Record<string, string | string[]>
    );

    nextSearchParams = createSearchParams(nextSearchParamsInit);
  } else {
    nextSearchParams = createSearchParams();
  }

  nextSearchParams.set("tenant_id", authenticatedUser.tenant.id);

  if (locationState && locationState.enableGlobalFiltering) {
    const filterData: FilterData = {
      auth_id: authenticatedUser.id,
      ...(filterStore.customFilters.length > 0
        ? { filters: filterStore.customFilters }
        : {}),
      ...(filterStore.scopedViews.length > 0
        ? { sv_ids: filterStore.scopedViews.map(({ id }) => id) }
        : {}),
    };

    nextSearchParams.set(FILTER_DATA_KEY, JSON.stringify(filterData));
  }

  if (locationState && locationState.enableGlobalDate) {
    const startDate = currentSearchParams.get(GLOBAL_DATE_START);
    const endDate = currentSearchParams.get(GLOBAL_DATE_END);
    const duration = currentSearchParams.get(GLOBAL_DURATION_TYPE);

    if (startDate && endDate && duration) {
      nextSearchParams.set(GLOBAL_DATE_START, startDate);
      nextSearchParams.set(GLOBAL_DATE_END, endDate);
      nextSearchParams.set(GLOBAL_DURATION_TYPE, duration);
    }
  }

  return (
    <Link
      textDecoration="none"
      to={{ pathname, search: `?${nextSearchParams.toString()}` }}
      {...props}
    />
  );
}

export function URLSearchParamsToObject(
  searchParams: URLSearchParams
): Record<string, string | string[]> {
  const object: Record<string, string | string[]> = {};

  for (const [key, value] of searchParams.entries()) {
    const current = object[key];

    if (current === undefined) {
      object[key] = value;
      continue;
    }

    if (typeof current === "string") {
      object[key] = [current, value];
      continue;
    }

    current.push(value);
  }

  return object;
}

//
// useMatchPath
//

/**
 * Returns the current static path. Calculated from the current dynamic path.
 * @see https://reactrouter.com/docs/en/v6/api#matchpath
 */

export function useMatchPath(): Path {
  const location = useLocation();

  const match = pathValues.find((path) => matchPath(path, location.pathname));

  if (!match) return "/";

  return match;
}

//
// useNavigateWithSearchParams
//

type Options = NavigateOptions & {
  searchParams?: Record<string, string>;
};

type NavigateWithSearchParamsFunction = (
  pathname: string,
  options?: Options
) => void;

/**
 * Returns a custom navigate function that enforces our app specific search
 * param rules. Should be used for programatically navigating anywhere in the app.
 * @see https://reactrouter.com/docs/en/v6/api#usenavigate
 */

export function useNavigateWithSearchParams(): NavigateWithSearchParamsFunction {
  const navigate = useNavigate();

  const [currentSearchParams] = useSearchParams();
  const authenticatedUser = useAuthenticatedUser();
  const filterStore = useFilterStore();

  return (pathname: string, options?: Options) => {
    const match = pathValues.find((path) => matchPath(path, pathname));

    const locationState = locationStateKeyedByPath[match ?? ""];

    const searchParams = new URLSearchParams({
      tenant_id: authenticatedUser.tenant.id,
      ...(options?.searchParams ? options.searchParams : {}),
    });

    if (locationState && locationState.enableGlobalFiltering) {
      const filterData: FilterData = {
        auth_id: authenticatedUser.id,
        ...(filterStore.customFilters.length > 0
          ? { filters: filterStore.customFilters }
          : {}),
        ...(filterStore.scopedViews.length > 0
          ? { sv_ids: filterStore.scopedViews.map(({ id }) => id) }
          : {}),
      };

      searchParams.set(FILTER_DATA_KEY, JSON.stringify(filterData));
    }

    if (locationState && locationState.enableGlobalDate) {
      const startDate = currentSearchParams.get(GLOBAL_DATE_START);
      const endDate = currentSearchParams.get(GLOBAL_DATE_END);
      const duration = currentSearchParams.get(GLOBAL_DURATION_TYPE);

      if (startDate && endDate && duration) {
        searchParams.set(GLOBAL_DATE_START, startDate);
        searchParams.set(GLOBAL_DATE_END, endDate);
        searchParams.set(GLOBAL_DURATION_TYPE, duration);
      }
    }

    navigate({ pathname, search: `?${searchParams.toString()}` }, options);
  };
}
