import Chip from "@/ui-lib/components/Chip";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import IconExport from "../../../../ui-lib/icons/IconExport";
import copyText from "../../copyText";
import { CudRecommendationFilters } from "../types";

export type CudInventoryCSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number | null>[];
};

type Props = {
  csvData: CudInventoryCSVData;
  filters: CudRecommendationFilters;
  debouncedSearchText: string;
  searchText: string;
  onInteraction: (
    interaction: CudRecommendationTableControls.Interaction
  ) => void;
};

function CudRecommendationTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.filters.billingAccountID !== null ? (
          <Chip
            label={copyText.cudVisRecommendationHeader_billingAccountID}
            text={props.filters.billingAccountID}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "billingAccountID",
              })
            }
          />
        ) : null}
        {props.filters.projectID !== null ? (
          <Chip
            label={copyText.cudVisRecommendationHeader_projectID}
            text={props.filters.projectID}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "projectID",
              })
            }
          />
        ) : null}
        {props.filters.region !== null ? (
          <Chip
            label={copyText.cudVisRecommendationHeader_region}
            text={props.filters.region}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "region",
              })
            }
          />
        ) : null}
        {props.filters.serviceType !== null ? (
          <Chip
            label={copyText.cudVisRecommendationHeader_serviceType}
            text={props.filters.serviceType}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "serviceType",
              })
            }
          />
        ) : null}
        {props.filters.targetCommitmentInstanceFamily !== null ? (
          <Chip
            label={
              copyText.cudVisRecommendationHeader_targetCommitmentInstanceFamily
            }
            text={props.filters.targetCommitmentInstanceFamily}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "targetCommitmentInstanceFamily",
              })
            }
          />
        ) : null}
      </Flex>
      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: CudRecommendationTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>
      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`cud-recommendations-${format(new Date(), "MM-dd-yyyy")}`}
        >
          <Button iconStart={<IconExport />} secondary size="small">
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `CudRecommendationsControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

CudRecommendationTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `CudRecommendationsControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof CudRecommendationTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof CudRecommendationFilters;
}
interface InteractionSearchTextUpdated {
  type: typeof CudRecommendationTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CudRecommendationTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default CudRecommendationTableControls;
