import paths from "@/constants/paths";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useMatchPath } from "@/lib/react-router";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import IconExport from "@/ui-lib/icons/IconExport";
import { useTheme } from "@emotion/react";
import {
  faGripHorizontal,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ReportDataResult } from "@ternary/api-lib/analytics/getReportData";
import { ChartType, ResourceType } from "@ternary/api-lib/constants/enums";
import { ReportEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import ResourceSelector from "@ternary/api-lib/ui-lib/components/ResourceSelector";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import LineChart from "@ternary/web-ui-lib/charts/LineChart";
import PieChart from "@ternary/web-ui-lib/charts/PieChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useEffect, useState } from "react";
import { useCSVDownloader } from "../../../ui-lib/components/CSVDownloader";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import { useCaptureViewReport } from "../hooks/useCaptureViewReport";
import useGetReportCSV from "../hooks/useGetReportCSV";
import useGetReportData from "../hooks/useGetReportData";
import { getTimeDurationCaption } from "../utils";
import ReportChart from "./ReportChart";

const DEFAULT_TOOLTIP_LIMIT = 9;

type Interaction =
  | PieChart.Interaction
  | AreaChart.Interaction
  | StackedBarChart.Interaction
  | LineChart.Interaction;

interface Props {
  dashboardID: string;
  isEditLayout?: boolean;
  index: number;
  report: ReportEntity;
  onInteraction: (interaction: ReportViewContainer.Interaction) => void;
}

interface State {
  csvReport: ReportEntity | null;
  report: ReportEntity;
}

const defaultReportDataResult: ReportDataResult = {
  data: [],
  isLargeDataSet: false,
};

export function ReportViewContainer(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const { downloadCSV, csvElement } = useCSVDownloader();

  //
  // State
  //

  const [state, setState] = useState<State>({
    csvReport: null,
    report: props.report,
  });
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: reportDataResult = defaultReportDataResult,
    isFetching: isLoadingReportData,
  } = useGetReportData(state.report);

  const csvResult = useGetReportCSV({ report: state.csvReport });

  function handleClickCSV() {
    setState((currentState) => ({
      ...currentState,
      csvReport: { ...currentState.report },
    }));
  }

  useCaptureViewReport(
    actions.VIEW_TRE_REPORT_DASHBOARD,
    reportDataResult.data,
    state.report
  );

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case LineChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case PieChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case AreaChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED:
      case StackedBarChart.INTERACTION_ADD_GROUPING_FILTER_CLICKED: {
        const report = {
          ...props.report,
          filters: [...props.report.filters, ...interaction.filters],
        };

        setState((currentState) => ({
          ...currentState,
          report,
        }));
        break;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    downloadCSV({
      data: csvResult.data.rows,
      fileName: `ternary-data-${formatDate(new Date(), "MM-dd-yyyy")}`,
      headers: csvResult.data.headers,
    });
  }, [csvResult.data]);

  useEffect(() => mergeState({ report: props.report }), [props.report]);

  //
  // Render
  //

  const tooltipLimit =
    props.report.limit !== null
      ? DEFAULT_TOOLTIP_LIMIT > props.report.limit
        ? props.report.limit
        : DEFAULT_TOOLTIP_LIMIT
      : DEFAULT_TOOLTIP_LIMIT;

  const showGripIcon = currentPath === paths._dashboard && props.isEditLayout;
  const showDeleteButton = currentPath === paths._dashboard;

  return (
    <ResourceSelector
      resourceID={props.report.id}
      resourceName={props.report.name}
      resourceType={ResourceType.REPORT}
    >
      <Box height="100%">
        {csvElement}
        <Box
          backgroundColor={theme.panel_backgroundColor}
          borderRadius={theme.borderRadius_2}
          height="100%"
          paddingBottom={theme.space_md}
          paddingHorizontal={theme.space_md}
          width="100%"
        >
          {showGripIcon && (
            <Flex
              alignItems="center"
              justifyContent="center"
              paddingTop={theme.space_xxs}
            >
              <Flex marginBottom={`-${theme.space_sm}`}>
                <Icon
                  color={theme.report_display_grip_color}
                  draggable
                  icon={faGripHorizontal}
                  size="lg"
                />
                <Box marginLeft={1}>
                  <Icon
                    color={theme.report_display_grip_color}
                    draggable
                    icon={faGripHorizontal}
                    size="lg"
                  />
                </Box>
              </Flex>
            </Flex>
          )}
          <Box height="100%" width="100%">
            <Tooltip
              content={
                props.report.description
                  ? `${copyText.descriptionInputLabel}: ${props.report.description}`
                  : `${copyText.nameInputLabel}: ${props.report.name}`
              }
              delayHide={250}
              width={"250px"}
            >
              <Box paddingVertical={theme.space_sm}>
                <Flex alignItems="center" justifyContent="space-between">
                  <Box
                    maxHeight={`calc(${theme.h4_fontSize} * 1.5)`}
                    overflow="hidden"
                  >
                    <Text
                      appearance="link"
                      fontSize={theme.h4_fontSize}
                      onClick={() =>
                        props.onInteraction({
                          type: ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED,
                          reportID: props.report.id,
                          report: state.report,
                        })
                      }
                    >
                      {props.report.name}
                    </Text>
                  </Box>
                  <Flex alignItems="center">
                    <Text
                      color={theme.text_color_secondary}
                      whiteSpace="nowrap"
                    >
                      {getTimeDurationCaption(
                        props.report.durationType,
                        authenticatedUser.settings.fiscalMode
                      )}
                    </Text>
                    {props.report.chartType === ChartType.TABLE && (
                      <Tooltip content={copyText.downloadCSVTooltip}>
                        <Button
                          disabled={csvResult.isLoading}
                          iconStart={
                            csvResult.isLoading ? (
                              <LoadingSpinner
                                color={theme.feedback_neutral_outline}
                                size="xs"
                              />
                            ) : (
                              <IconExport />
                            )
                          }
                          marginLeft={theme.space_sm}
                          size="small"
                          onClick={handleClickCSV}
                        />
                      </Tooltip>
                    )}
                    {showDeleteButton && (
                      <Button
                        disabled={
                          !gatekeeper.canUpdateDashboard(props.dashboardID)
                        }
                        iconStart={<Icon icon={faTrashAlt} />}
                        marginLeft={theme.space_xs}
                        size="small"
                        onClick={() =>
                          props.onInteraction({
                            type: ReportViewContainer.INTERACTION_DELETE_BUTTON_CLICKED,
                            index: props.index,
                          })
                        }
                      />
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Tooltip>
            <Box height="85%" paddingBottom={theme.space_md}>
              <ReportChart
                data={reportDataResult.data}
                isLoading={isLoadingReportData}
                report={state.report}
                showToolTip={!props.isEditLayout}
                tooltipLimit={tooltipLimit}
                onInteraction={handleInteraction}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ResourceSelector>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportViewContainer {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `ReportViewContainer.INTERACTION_DELETE_BUTTON_CLICKED`;
  export const INTERACTION_REPORT_NAME_CLICKED = `ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED`;

  interface InteractionDeleteButtonClicked {
    type: typeof ReportViewContainer.INTERACTION_DELETE_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionReportNameClicked {
    type: typeof ReportViewContainer.INTERACTION_REPORT_NAME_CLICKED;
    reportID: string;
    report: ReportEntity;
  }

  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionReportNameClicked;
}

export default ReportViewContainer;
