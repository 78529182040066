import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faChartArea,
  faClipboardUser,
  faClose,
  faFileInvoiceDollar,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  BudgetEntity,
  DashboardEntity,
  ReportEntity,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, noop } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import copyText from "../constants/copyText";
import {
  NavOption,
  defaultNavOptions,
  navOptions,
} from "../constants/navSearch";
import paths from "../constants/paths";
import useGatekeeper from "../hooks/useGatekeeper";
import {
  LinkWithSearchParams,
  useNavigateWithSearchParams,
} from "../lib/react-router";
import Modal from "../ui-lib/components/Modal";
import TextInput from "../ui-lib/components/TextInput";
import { useDebounce } from "../utils/timers";

const MIN_MODAL_WIDTH = 550;

const List = styled("ul")({
  listStyle: "none",
  margin: "0",
  maxHeight: "200px",
  outline: "none",
  overflowY: "auto",
  padding: 0,
});

type Props = {
  showModal: boolean;
  reports: ReportEntity[];
  dashboards: DashboardEntity[];
  budgets: BudgetEntity[];
  isLoading: boolean;
  onClose: () => void;
};

export function NavSearchModal(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  const [currentSearchParams] = useSearchParams();
  const { reportID, dashboardID } = useParams<{
    reportID?: string;
    dashboardID?: string;
  }>();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);

  //
  // State
  //
  const [searchText, setSearchText] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  //
  // Quries
  //

  const debounceSearchText = useDebounce(searchText);

  const navigationOptions = [
    ...navOptions,
    ...(gatekeeper.canAccessInternalAdmin
      ? [
          {
            href: paths._internalAdmin,
            icon: faClipboardUser,
            keywords: [],
            name: copyText.internalAdminTitle,
          },
          {
            href: paths._internalMspPartnerTenants,
            icon: faClipboardUser,
            keywords: [],
            name: copyText.navSearchOptionNameInternalAdminMSP,
          },
        ]
      : []),
  ];

  const reportOptions = useMemo(() => {
    return props.reports.map((report) => ({
      href: paths._reportBuilder.replace(":reportID", report.id),
      icon: faChartArea,
      name: `${copyText.navSearchOptionNameReports}: ${report.name}`,
      keywords: [],
    }));
  }, [props.reports]);

  const dashboardOptions = useMemo(() => {
    return props.dashboards.map((dashboard) => ({
      href: paths._dashboard.replace(":dashboardID", dashboard.id),
      icon: faChartArea,
      name: `${copyText.navSearchOptionNameDashboard}: ${dashboard.name}`,
      keywords: [],
    }));
  }, [props.dashboards]);

  const budgetOptions = useMemo(() => {
    return props.budgets.map((budget) => ({
      href: paths._budgets,
      icon: faFileInvoiceDollar,
      name: `${copyText.navSearchOptionNameBudgets} ${budget.name}`,
      params: { budget_id: budget.id },
      keywords: [],
    }));
  }, [props.budgets]);

  const filteredOptions = useMemo(() => {
    let options = defaultNavOptions;

    if (!searchText.length) {
      itemRefs.current = options.map((_, i) => itemRefs.current[i] ?? null);
      return options;
    }

    const str = searchText.toLowerCase().replace(/[\s-]/g, "");

    options = props.isLoading
      ? navigationOptions
      : [
          ...budgetOptions,
          ...navigationOptions,
          ...reportOptions,
          ...dashboardOptions,
        ];

    const filtered = options.filter((option) => {
      return (
        hasKeyword(str, option.keywords) ||
        option.name.toLowerCase().replace(/[\s-]/g, "").includes(str)
      );
    });

    return mapInsightsParams(str, filtered);
  }, [
    debounceSearchText,
    defaultNavOptions,
    navOptions,
    props.budgets,
    props.dashboards,
    props.isLoading,
    props.reports,
  ]);

  useEffect(() => {
    const listElement = listRef.current;

    if (listElement) {
      listElement.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [selectedIndex, filteredOptions]);

  useEffect(() => {
    //Used for autofocus on modal open
    const inputElement = inputRef.current;

    inputElement?.focus();
  }, [props.showModal]);

  //
  // Handlers
  //

  function handleKeyPress(event) {
    if (selectedIndex === null) {
      setSelectedIndex(0);
    }
    switch (event.key) {
      case "ArrowUp": {
        // Refocus on input when on the first option
        if (selectedIndex === 0) {
          if (inputRef.current && listRef.current) {
            listRef.current.blur();
            inputRef.current.focus();
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.setSelectionRange(
                  inputRef.current.value.length,
                  inputRef.current.value.length
                );
              }
            }, 1);
            setSelectedIndex(null);
          }
        } else {
          setSelectedIndex((prevIndex) => {
            const index = prevIndex ?? 0;
            const newIndex = index > 0 ? index - 1 : index;
            itemRefs.current[newIndex]?.scrollIntoView({
              behavior: "auto",
              block: "center",
              inline: "center",
            });
            return newIndex;
          });
        }
        return;
      }
      case "ArrowDown": {
        setSelectedIndex((prevIndex) => {
          const index = prevIndex ?? 0;
          const newIndex =
            index < filteredOptions.length - 1 ? index + 1 : index;
          itemRefs.current[newIndex]?.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
          return newIndex;
        });
        return;
      }
      case "Enter": {
        if (selectedIndex !== null) {
          const selectedOption = filteredOptions[selectedIndex];
          navigate(selectedOption.href, {
            searchParams: selectedOption.params,
          });
          handleClose();
        }
        noop();
        return;
      }
    }
  }

  function handleKeyDown(event: React.KeyboardEvent): void {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (inputRef.current) {
        inputRef.current.blur();
      }
      if (listRef.current) {
        listRef.current.focus();
        handleKeyPress(event);
      }
    }
  }

  function handleClose(): void {
    setSearchText("");
    setSelectedIndex(null);
    props.onClose();
  }

  //
  // Render
  //

  function isSelected(option: NavOption): boolean {
    const budgetID = currentSearchParams.get("budget_id");
    const tabParam = currentSearchParams.get("tab");

    const hasSearchParams =
      !!budgetID || !!tabParam || !!reportID || !!dashboardID;

    if (!hasSearchParams && !option.params) {
      return option.href === location.pathname;
    } else if (hasSearchParams && option.params) {
      if (budgetID) {
        return (
          option.href === location.pathname &&
          option.params["budget_id"].includes(budgetID)
        );
      }
      if (tabParam) {
        return (
          option.href === location.pathname &&
          option.params["tab"].includes(tabParam)
        );
      }
    } else if (hasSearchParams && !option.params) {
      if (reportID) {
        return option.href.includes(reportID);
      }
      if (dashboardID) {
        return option.href.includes(dashboardID);
      }
    }

    return false;
  }

  const isDefault = isEqual(filteredOptions, defaultNavOptions);

  return (
    <Modal
      closeOnClickOutside
      isOpen={props.showModal}
      minWidth={MIN_MODAL_WIDTH}
      showCloseButton
      onClose={handleClose}
    >
      <Modal.Header>
        <Text appearance="h4">{copyText.navSearchModalTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <TextInput
            iconEnd={
              <Icon
                color={theme.text_color_secondary}
                clickable
                icon={searchText.length > 0 ? faClose : faSearch}
                onClick={() => setSearchText("")}
              />
            }
            inputRef={inputRef}
            placeholder={copyText.searchInputPlaceholder}
            size="large"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(event) => handleKeyDown(event)}
          />

          {isDefault ? (
            <Text marginLeft={theme.space_xs} marginTop={theme.space_md}>
              {copyText.navSearchModalQuickLinksText}
            </Text>
          ) : (
            <Text marginLeft={theme.space_xs} marginTop={theme.space_md}>
              {copyText.navSearchModalSuggestedLinksText}
            </Text>
          )}

          <Flex
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={200}
            marginTop={theme.space_xs}
            position="relative"
            width={MIN_MODAL_WIDTH}
          >
            <List
              ref={listRef}
              tabIndex={0}
              onMouseOver={() => {
                setSelectedIndex(null);
              }}
            >
              {filteredOptions.map((option, index) => {
                const isHovered = index === selectedIndex; //For arrow keys
                const currentPage = isSelected(option);
                return (
                  <li
                    key={index}
                    ref={(el) => (itemRefs.current[index] = el)}
                    onMouseDown={(e) => e.preventDefault()}
                    onMouseOver={() => listRef.current?.focus()}
                  >
                    <Flex
                      as={LinkWithSearchParams}
                      alignItems="center"
                      background={
                        currentPage
                          ? theme.primary_color_background
                          : isHovered
                            ? theme.secondary_color_background_hover
                            : undefined
                      }
                      backgroundColorOnHover={
                        !currentPage
                          ? theme.secondary_color_background_hover
                          : undefined
                      }
                      borderRadius={theme.borderRadius_2}
                      padding={theme.space_xxs}
                      paddingLeft={theme.space_sm}
                      searchParams={option.params ?? {}}
                      tabIndex={0}
                      to={option.href}
                      width={"100%"}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <Icon
                        icon={option.icon}
                        color={
                          currentPage
                            ? theme.text_color_inverse
                            : theme.text_color
                        }
                      />
                      <Text
                        marginLeft={theme.space_md}
                        color={
                          currentPage
                            ? theme.text_color_inverse
                            : theme.text_color
                        }
                      >
                        {option.name}
                      </Text>
                    </Flex>
                  </li>
                );
              })}
            </List>
          </Flex>
        </Box>
      </Modal.Body>
    </Modal>
  );
}

function mapInsightsParams(
  searchStr: string,
  options: NavOption[]
): NavOption[] {
  return options.map((option) => {
    if (hasKeyword(searchStr, option.keywords, true)) {
      if (
        option.href === paths._insightsDatabaseSnowflake ||
        option.href === paths._insightsDataWarehouseSnowflake
      ) {
        return option;
      } else {
        return {
          ...option,
          params: { tab: "optimizations" },
        };
      }
    } else {
      return option;
    }
  });
}

function hasKeyword(
  str: string,
  keywords: string[],
  isOptimization?: boolean
): boolean {
  const keywordSet = isOptimization
    ? ["rightsizing", "optimizations"]
    : keywords;
  return keywordSet.some((keyword) => {
    if (keyword.startsWith(str)) return true;

    let diffs = 0;

    for (let i = 0; i < str.length; i++) {
      if (str[i] === keyword[i]) continue;
      diffs++;
    }

    return !(diffs > 1);
  });
}
