/**
  This function appends a script element to the HTML document body.
*/
export function initStatuspage(url: string): void {
  const script = document.createElement("script");

  // script source copied from: https://manage.statuspage.io/pages/dkkmd0cmrwpd/status-embed
  script.id = "statuspage-snippet";
  script.src = `${url}`;

  document.body.appendChild(script);
}
