import DataQuery, { Properties as BaseProperties } from "./DataQuery";

interface Properties extends BaseProperties {
  forecasting: {
    algorithm: string;
    dateRange: Date[];
    measure: string;
  };
}

export default class DataQueryExperimental extends DataQuery {
  public forecasting: {
    algorithm: string;
    end_time: string;
    measure: string;
    start_time: string;
  };

  constructor(props: Properties) {
    const { forecasting, ...baseProps } = props;

    super(baseProps);

    const dateRange = this._getDateRange(props.forecasting.dateRange);

    this.forecasting = {
      algorithm: forecasting.algorithm,
      end_time: dateRange[1],
      measure: forecasting.measure,
      start_time: dateRange[0],
    };
  }
}
