import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import Dropdown from "@/ui-lib/components/Dropdown";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faCloud,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import {
  AWSIntegrationEntity,
  AlibabaIntegrationEntity,
  AzureIntegrationEntity,
  GCPIntegrationEntity,
  OracleIntegrationEntity,
  SnowflakeIntegrationEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import copyText from "../copyText";
import useCreateAWSIntegration from "../hooks/useCreateAWSIntegration";
import useCreateAlibabaIntegration from "../hooks/useCreateAlibabaIntegration";
import useCreateAzureIntegration from "../hooks/useCreateAzureIntegration";
import useCreateGCPIntegration from "../hooks/useCreateGCPIntegration";
import useCreateOracleIntegration from "../hooks/useCreateOracleIntegration";
import useCreateSnowflakeIntegration from "../hooks/useCreateSnowflakeIntegration";
import useDeleteDataIntegration from "../hooks/useDeleteDataIntegration";
import useGetDataIntegrationsByTenantID from "../hooks/useGetDataIntegrationsByTenantID";
import useUpdateAWSIntegration from "../hooks/useUpdateAWSIntegration";
import useUpdateAlibabaIntegration from "../hooks/useUpdateAlibabaIntegration";
import useUpdateAzureIntegration from "../hooks/useUpdateAzureIntegration";
import useUpdateGCPIntegration from "../hooks/useUpdateGCPIntegration";
import useUpdateOracleIntegration from "../hooks/useUpdateOracleIntegration";
import useUpdateSnowflakeIntegration from "../hooks/useUpdateSnowflakeIntegration";
import useValidateDataIntegration from "../hooks/useValidateDataIntegration";
import AWSIntegrationCard from "./AWSIntegrationCard";
import AWSIntegrationForm from "./AWSIntegrationForm/AWSIntegrationForm";
import AlibabaIntegrationCard from "./AlibabaIntegrationCard";
import AlibabaIntegrationForm from "./AlibabaIntegrationForm/AlibabaIntegrationForm";
import AzureIntegrationCard from "./AzureIntegrationCard";
import AzureIntegrationForm from "./AzureIntegrationForm/AzureIntegrationForm";
import GCPIntegrationCard from "./GCPIntegrationCard";
import GCPIntegrationForm from "./GCPIntegrationForm/GCPIntegrationForm";
import OracleIntegrationCard from "./OracleIntegrationCard";
import OracleIntegrationForm from "./OracleIntegrationForm";
import { SnowflakeIntegrationCard } from "./SnowflakeIntegrationCard";
import SnowflakeIntegrationForm from "./SnowflakeIntegrationForm/SnowflakeIntegrationForm";
import ValidationErrorModal from "./ValidationErrorModal";

const MODAL_ALIBABA = "ALIBABA";
const MODAL_AWS = "AWS";
const MODAL_AZURE = "AZURE";
const MODAL_GCP = "GCP";
const MODAL_OCI = "OCI";
const MODAL_SNOWFLAKE = "SNOWFLAKE";
const MODAL_DELETE = "DELETE";
const MODAL_VALIDATIONS = "VALIDATIONS";

type Interaction =
  | AlibabaIntegrationCard.Interaction
  | AlibabaIntegrationForm.Interaction
  | AWSIntegrationCard.Interaction
  | AWSIntegrationForm.Interaction
  | AzureIntegrationCard.Interaction
  | AzureIntegrationForm.Interaction
  | GCPIntegrationCard.Interaction
  | GCPIntegrationForm.Interaction
  | OracleIntegrationCard.Interaction
  | OracleIntegrationForm.Interaction
  | SnowflakeIntegrationCard.Interaction
  | SnowflakeIntegrationForm.Interaction
  | ValidationErrorModal.Interaction;

interface State {
  modalKey: string;
  selectedIntegrationID: string | null;
}

const initialState: State = {
  modalKey: "",
  selectedIntegrationID: null,
};

export default function DataIntegrationManagementContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _integrations = [],
    isLoading: isLoadingIntegrations,
    refetch: refetchIntegrations,
  } = useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id, {
    enabled: gatekeeper.canListDataIntegrations,
    meta: { errorMessage: copyText.errorLoadingDataIntegrationsMessage },
  });

  //
  // Mutations
  //

  const {
    isPending: isCreatingAlibabaIntegration,
    mutate: createAlibabaIntegration,
  } = useCreateAlibabaIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorCreatingAlibabaIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "" });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingAlibabaIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingAWSIntegration, mutate: createAWSIntegration } =
    useCreateAWSIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingAWSIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        mergeState({ modalKey: "" });

        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successCreatingAWSIntegrationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isCreatingAzureIntegration,
    mutate: createAzureIntegration,
  } = useCreateAzureIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingAzureIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "" });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingAzureIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isCreatingGCPIntegration, mutate: createGCPIntegration } =
    useCreateGCPIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingGCPIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        mergeState({ modalKey: "" });

        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successCreatingGCPIntegrationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isCreatingOracleIntegration,
    mutate: createOracleIntegration,
  } = useCreateOracleIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorCreatingOracleIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "" });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingOracleIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isCreatingSnowflakeIntegration,
    mutate: createSnowflakeIntegration,
  } = useCreateSnowflakeIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorCreatingSnowflakeIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "" });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingSnowflakeIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isDeletingDataIntegration,
    mutate: deleteDataIntegration,
  } = useDeleteDataIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorDeletingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      refetchIntegrations();

      postAlert({
        message: copyText.successDeletingDataIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdatingAlibabaIntegration,
    mutate: updateAlibabaIntegration,
  } = useUpdateAlibabaIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorUpdatingIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isUpdatingAWSIntegration, mutate: updateAWSIntegration } =
    useUpdateAWSIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingAWSIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        mergeState({ modalKey: "", selectedIntegrationID: null });

        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successUpdatingAWSIntegrationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isUpdatingAzureIntegration,
    mutate: updateAzureIntegration,
  } = useUpdateAzureIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingAzureIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingAzureIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const { isPending: isUpdatingGCPIntegration, mutate: updateGCPIntegration } =
    useUpdateGCPIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingGCPIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        mergeState({ modalKey: "", selectedIntegrationID: null });

        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successUpdatingGCPIntegrationMessage,
          type: AlertType.SUCCESS,
        });
      },
    });

  const {
    isPending: isUpdatingOracleIntegration,
    mutate: updateOracleIntegration,
  } = useUpdateOracleIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorUpdatingIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isUpdatingSnowflakeIntegration,
    mutate: updateSnowflakeIntegration,
  } = useUpdateSnowflakeIntegration({
    onError: () => {
      mergeState({ modalKey: "" });
      postAlert({
        message: copyText.errorUpdatingIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      mergeState({ modalKey: "", selectedIntegrationID: null });

      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingIntegrationMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isValidatingIntegration,
    mutate: validateDataIntegration,
  } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      refetchIntegrations();
    },
  });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AlibabaIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case AlibabaIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_ALIBABA,
        });
        return;
      }
      case AlibabaIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AlibabaIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createAlibabaIntegration({
          tenantID: authenticatedUser.tenant.id,
          costAndUsageReports: interaction.costAndUsageReports,
          name: interaction.name,
          roleARN: interaction.roleARN,
        });
        return;
      }
      case AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateAlibabaIntegration({
          integrationID: selectedIntegration.id,
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.config?.costAndUsageReports
            ? { costAndUsageReports: interaction.config.costAndUsageReports }
            : {}),
          ...(interaction.config?.roleARN
            ? { roleARN: interaction.config.roleARN }
            : {}),
        });

        return;
      }
      case AWSIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AWSIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_AWS,
        });
        return;
      }
      case AWSIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case AWSIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createAWSIntegration({
          tenantID: authenticatedUser.tenant.id,
          costAndUsageReports: interaction.costAndUsageReports,
          name: interaction.name,
          monitoringRoleARN: interaction.monitoringRoleARN,
          roleARN: interaction.roleARN,
        });
        return;
      }
      case AWSIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateAWSIntegration({
          integrationID: selectedIntegration.id,
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.config
            ? {
                costAndUsageReports: interaction.config.costAndUsageReports,
                monitoringRoleARN: interaction.config.monitoringRoleARN,
                roleARN: interaction.config.roleARN,
              }
            : {}),
        });

        return;
      }
      case AWSIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case GCPIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_GCP,
        });
        return;
      }
      case GCPIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case GCPIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createGCPIntegration({
          tenantID: authenticatedUser.tenant.id,
          name: interaction.name,
          bigQueryMonitoring: interaction.bigQueryMonitoring,
          billingAccountID: interaction.billingAccountID,
          billingExportSource: interaction.billingExportSource,
          cudSharingEnabled: interaction.cudSharingEnabled,
          carbonFootprintSource: interaction.carbonFootprintSource,
          detailedBillingExportSource: interaction.detailedBillingExportSource,
          pricingExportSource: interaction.pricingExportSource,
          rootElement: interaction.rootElement,
        });
        return;
      }

      case GCPIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateGCPIntegration({
          integrationID: selectedIntegration.id,
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.config
            ? {
                bigQueryMonitoring: interaction.config.bigQueryMonitoring,
                billingAccountID: interaction.config.billingAccountID,
                billingExportSource: interaction.config.billingExportSource,
                carbonFootprintSource: interaction.config.carbonFootprintSource,
                cudSharingEnabled: interaction.config.cudSharingEnabled,
                detailedBillingExportSource:
                  interaction.config.detailedBillingExportSource,
                pricingExportSource: interaction.config.pricingExportSource,
                rootElement: interaction.config.rootElement,
              }
            : {}),
        });
        return;
      }

      case GCPIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case AzureIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_AZURE,
        });
        return;
      }
      case AzureIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case AzureIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case AzureIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createAzureIntegration({
          tenantID: authenticatedUser.tenant.id,
          appID: interaction.appID,
          billingExports: interaction.billingExports,
          directoryID: interaction.directoryID,
          name: interaction.name,
          type: interaction.azureType,
        });
        return;
      }
      case AzureIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateAzureIntegration({
          integrationID: selectedIntegration.id,
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.config
            ? {
                billingExports: interaction.config.billingExports,
                type: interaction.config.azureType,
              }
            : {}),
        });
        return;
      }
      case OracleIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case OracleIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_OCI,
        });
        return;
      }
      case OracleIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case OracleIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createOracleIntegration({
          tenantID: authenticatedUser.tenant.id,
          name: interaction.name,
          region: interaction.region,
          userOCID: interaction.userOCID,
          tenancyOCID: interaction.tenancyOCID,
        });
        return;
      }
      case OracleIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateOracleIntegration({
          integrationID: selectedIntegration?.id,
          ...(interaction.name ? { name: interaction.name } : {}),
          ...(interaction.config
            ? {
                region: interaction.config.region,
                userOCID: interaction.config.userOCID,
                tenancyOCID: interaction.config.tenancyOCID,
              }
            : {}),
        });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_EDIT_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_SNOWFLAKE,
        });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_REFRESH_BUTTON_CLICKED: {
        validateDataIntegration({ integrationID: interaction.integrationID });
        return;
      }
      case SnowflakeIntegrationCard.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: MODAL_DELETE,
        });
        return;
      }
      case SnowflakeIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ selectedIntegrationID: null, modalKey: "" });
        return;
      }
      case SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE: {
        createSnowflakeIntegration({
          tenantID: authenticatedUser.tenant.id,
          orgName: interaction.organizationName,
          accounts: interaction.accounts.map((account) => ({
            ...account,
            password: account.passwordPreview,
          })),
          name: interaction.integrationName,
        });
        return;
      }
      case SnowflakeIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE: {
        if (!selectedIntegration) return;

        updateSnowflakeIntegration({
          integrationID: selectedIntegration.id,
          orgName: interaction.organizationName,
          name: interaction.integrationName,
          ...(interaction.accounts
            ? {
                accounts: interaction.accounts.map((account) => ({
                  ...account,
                  ...(account.passwordPreview
                    ? { password: account.passwordPreview }
                    : {}),
                })),
              }
            : {}),
        });

        return;
      }
      case ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED: {
        mergeState({
          selectedIntegrationID: interaction.integrationID,
          modalKey: getModalFromProvider(interaction.providerType),
        });
        return;
      }
    }
  }

  function getModalFromProvider(providerType: CloudProviderType) {
    switch (providerType) {
      case CloudProviderType.ALIBABA:
        return MODAL_ALIBABA;
      case CloudProviderType.AWS:
        return MODAL_AWS;
      case CloudProviderType.AZURE:
        return MODAL_AZURE;
      case CloudProviderType.GCP:
        return MODAL_GCP;
      case CloudProviderType.OCI:
        return MODAL_OCI;
      case CloudProviderType.SNOWFLAKE:
        return MODAL_SNOWFLAKE;
    }
  }

  //
  // Render
  //

  if (!gatekeeper.canListDataIntegrations) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  const integrations = _integrations.sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  const integrationsWithErrors = integrations.filter((integration) =>
    integration.validations.find((validation) => !validation.success)
  );

  const selectedIntegration = integrations.find(
    (integration) => integration.id === state.selectedIntegrationID
  );

  const createIntegrationOptions = [
    {
      label: copyText.createAlibabaIntegrationButtonLabel,
      onClick: () => mergeState({ modalKey: MODAL_ALIBABA }),
    },
    {
      label: copyText.createAWSIntegrationButtonLabel,
      onClick: () => mergeState({ modalKey: MODAL_AWS }),
    },
    {
      label: copyText.createAzureIntegrationButtonLabel,
      onClick: () => mergeState({ modalKey: MODAL_AZURE }),
    },
    {
      label: copyText.createGCPIntegrationButtonLabel,
      onClick: () => mergeState({ modalKey: MODAL_GCP }),
    },
    {
      label: copyText.createOracleIntegrationButtonLabel,
      onClick: () => mergeState({ modalKey: MODAL_OCI }),
    },
    {
      label: copyText.createSnowflakeButtonlabel,
      onClick: () => mergeState({ modalKey: MODAL_SNOWFLAKE }),
    },
  ];

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_ALIBABA: {
        return (
          <AlibabaIntegrationForm
            isLoading={
              isCreatingAlibabaIntegration ||
              isUpdatingAlibabaIntegration ||
              isDeletingDataIntegration
            }
            integration={selectedIntegration as AlibabaIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_AWS: {
        return (
          <AWSIntegrationForm
            isLoading={
              isCreatingAWSIntegration ||
              isUpdatingAWSIntegration ||
              isDeletingDataIntegration
            }
            integration={selectedIntegration as AWSIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_AZURE: {
        return (
          <AzureIntegrationForm
            isLoading={
              isCreatingAzureIntegration ||
              isUpdatingAzureIntegration ||
              isDeletingDataIntegration
            }
            selectedCloud={selectedIntegration as AzureIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_GCP: {
        return (
          <GCPIntegrationForm
            isLoading={
              isCreatingGCPIntegration ||
              isUpdatingGCPIntegration ||
              isDeletingDataIntegration
            }
            integration={selectedIntegration as GCPIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_OCI: {
        return (
          <OracleIntegrationForm
            isLoading={
              isCreatingOracleIntegration ||
              isUpdatingOracleIntegration ||
              isDeletingDataIntegration
            }
            integration={selectedIntegration as OracleIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_SNOWFLAKE: {
        return (
          <SnowflakeIntegrationForm
            isLoading={
              isCreatingSnowflakeIntegration ||
              isUpdatingSnowflakeIntegration ||
              isDeletingDataIntegration
            }
            integration={selectedIntegration as SnowflakeIntegrationEntity}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DELETE: {
        if (!selectedIntegration) return null;

        return (
          <ConfirmationModal
            isLoading={isDeletingDataIntegration}
            title={copyText.deleteDataIntegrationConfirmationTitle}
            message={copyText.deleteDataIntegrationConfirmationMessage}
            variant="danger"
            onConfirm={() =>
              deleteDataIntegration({
                integrationID: selectedIntegration.id,
                providerType: selectedIntegration.providerType,
              })
            }
            onCancel={() =>
              mergeState({ selectedIntegrationID: null, modalKey: "" })
            }
          />
        );
      }
      case MODAL_VALIDATIONS: {
        return (
          <ValidationErrorModal
            integrations={integrationsWithErrors}
            onClose={() => mergeState({ modalKey: "" })}
            onInteraction={handleInteraction}
          />
        );
      }
    }

    return null;
  }

  return (
    <Flex direction="column">
      {renderModal()}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_md}
      >
        <Text
          fontSize={theme.h4_fontSize}
        >{`${copyText.cloudsLabel} (${integrations.length})`}</Text>
        <Flex justifyContent="space-between" marginRight={theme.space_lg}>
          {integrationsWithErrors.length > 0 && (
            <Button
              iconStart={<IconExclamationDiamond />}
              marginRight={theme.space_sm}
              secondary
              onClick={() => mergeState({ modalKey: MODAL_VALIDATIONS })}
            >
              {copyText.cloudValidationErrorButton}
            </Button>
          )}
          <Dropdown options={createIntegrationOptions} placement="bottom-end">
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              locked={!gatekeeper.canCreateDataIntegration}
              primary
            >
              {copyText.cloudDropDownLabel}
            </Button>
          </Dropdown>
        </Flex>
      </Flex>
      <Box marginTop={theme.space_md}>
        {integrations.length > 0 ? (
          <Flex flexWrap="wrap">
            {integrations.map((integration) => {
              if (integration.providerType === CloudProviderType.GCP) {
                return (
                  <GCPIntegrationCard
                    key={integration.id}
                    integration={integration as GCPIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.ALIBABA) {
                return (
                  <AlibabaIntegrationCard
                    key={integration.id}
                    integration={integration as AlibabaIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.AZURE) {
                return (
                  <AzureIntegrationCard
                    key={integration.id}
                    integration={integration as AzureIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.AWS) {
                return (
                  <AWSIntegrationCard
                    key={integration.id}
                    integration={integration as AWSIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }

              if (integration.providerType === CloudProviderType.OCI) {
                return (
                  <OracleIntegrationCard
                    key={integration.id}
                    integration={integration as OracleIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }
              if (integration.providerType === CloudProviderType.SNOWFLAKE) {
                return (
                  <SnowflakeIntegrationCard
                    key={integration.id}
                    integration={integration as SnowflakeIntegrationEntity}
                    isProcessing={isValidatingIntegration}
                    onInteraction={handleInteraction}
                  />
                );
              }
            })}
          </Flex>
        ) : (
          <EmptyPlaceholder
            icon={faCloud}
            loading={isLoadingIntegrations}
            text={copyText.noDataIntegrationsMessage}
            skeletonVariant="cards"
          />
        )}
      </Box>
    </Flex>
  );
}
