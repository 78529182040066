import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  UseFloatingOptions,
  autoPlacement,
  autoUpdate,
  useFloating,
} from "@floating-ui/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GroupBase,
  InputActionMeta,
  MenuProps,
  OnChangeValue,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import copyText from "../copyText";
import IconCheck from "../icons/IconCheck";
import { isReactElement } from "../utils/children";
import Select from "./Select";

const MAX_OPTIONS = 200;

export type DefaultOption = { label: string; value: string };

type Placement = "auto" | UseFloatingOptions["placement"];

const checkboxTheme = (baseTheme: Theme) => ({
  Checkbox_backgroundColor_hover: baseTheme.primary_color_hover,
  Checkbox_borderColor_hover: baseTheme.primary_color_border,
  Checkbox_borderColor: baseTheme.secondary_color_border,
  ...baseTheme,
});

const Checkbox = styled("span")<{ checked: boolean }>(({
  checked,
  theme: baseTheme,
}) => {
  const theme = checkboxTheme(baseTheme);

  return {
    backgroundColor: (() => {
      return checked
        ? theme.primary_color_background
        : theme.panel_backgroundColor;
    })(),
    borderColor: checked ? "transparent" : theme.Checkbox_borderColor,
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "16px",
    marginRight: theme.space_xs,
    minWidth: "16px",
    width: "16px",

    "&:hover": {
      backgroundColor: !checked
        ? undefined
        : theme.Checkbox_backgroundColor_hover,
      borderColor: theme.Checkbox_borderColor_hover,
    },
  };
});

const DropdownRoot = styled("div")({
  display: "flex",
  position: "relative",
});

const DropdownContent = styled("div")<{ placement: Placement }>(
  ({ placement, theme }) => ({
    backgroundColor: theme.elevated_background_color,
    borderRadius: theme.borderRadius_2,
    boxShadow: `0 4px 8px ${theme.box_shadow}`,
    margin: placement === "auto" ? `-${theme.space_md}` : theme.space_md,
    minWidth: 150,
    padding: `${theme.space_xs} ${theme.space_xxs}`,
    transition: "none",
    zIndex: theme.zIndex_1600,
  })
);

export type Option = { label: string; value: string };

function getStyles(theme: Theme): Partial<StylesConfig<Option, boolean>> {
  return {
    container: (styles) => ({
      ...styles,
      padding: `0 ${theme.space_xxs}`,
    }),
    control: (styles, props) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
      borderColor: props.isFocused
        ? "transparent"
        : theme.secondary_color_border,
      borderStyle: "solid",
      borderWidth: `1px`,
      borderRadius: theme.borderRadius_2,
      boxShadow: props.isFocused
        ? `0 0 0 2px ${theme.primary_color_focus}`
        : "none",
      color: `${theme.select_color} !important`,
      cursor: "text",
      fontSize: theme.fontSize_ui,
      marginBottom: "10px",
      minHeight: "38px",
      minWidth: "250px",

      "&:hover": {
        borderColor: props.isFocused
          ? "transparent"
          : theme.primary_color_border,
      },
    }),
    input: (styles) => ({
      ...styles,
      color: `${theme.select_color}`,
    }),
    menu: () => ({
      backgroundColor: theme.input_background_color,
      border: "none",
      borderRadius: theme.borderRadius_2,
      boxShadow: "none",
      zIndex: theme.zIndex_100,
    }),
    option: (styles, props) => ({
      ...styles,
      alignItems: "center",
      borderRadius: theme.borderRadius_2,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,

      ...(props.isMulti
        ? {
            backgroundColor: "transparent",
            color: theme.text_color,
            display: "flex",

            "&:hover": {
              backgroundColor: theme.secondary_color_background,
            },
          }
        : {
            backgroundColor: props.isSelected
              ? theme.primary_color_background
              : theme.input_background_color,
            color: props.isSelected
              ? theme.text_color_inverse
              : theme.text_color,
            margin: `${theme.space_xxs} 0`,

            "&:hover": {
              backgroundColor: props.isSelected
                ? theme.primary_color_background
                : theme.secondary_color_background,
            },
          }),
    }),
  };
}

interface Props<IsMulti extends boolean> {
  closeOnSubmit?: boolean;
  createDialogue?: (value) => string;
  createOption?: (value: string) => Option;
  disabled?: boolean;
  hideSelectedOptions?: boolean;
  inputValidator?: (inputValue: string) => boolean;
  inputValue?: string;
  isClearable?: boolean;
  isCreatable?: boolean;
  isLoading?: boolean;
  isMulti?: IsMulti;
  isNumberInput?: boolean;
  options: Option[] | GroupBase<Option>[];
  placement?: Placement;
  searchInputPlaceholder?: string;
  selectedValues?: string[];
  sortSelected?: boolean;
  submitButtonText?: string;
  onBlur?: () => void;
  onChange: (value: IsMulti extends true ? string[] : string) => void;
  onClick?: () => void;
  onCreateOption?: (value: string) => void;
  onInputChange?: (value: string) => void;
}

export default function SelectDropdown<IsMulti extends boolean = false>(
  props: PropsWithChildren<Props<IsMulti>>
): JSX.Element {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<readonly Option[]>([]);

  const placementOption =
    !props.placement || props.placement === "auto"
      ? { middleware: [autoPlacement()] }
      : { placement: props.placement };

  const { refs, floatingStyles } = useFloating({
    strategy: "fixed",
    whileElementsMounted: autoUpdate,
    ...placementOption,
  });

  /*
  NOTE:
    react-select uses the same filterOption function for options ({ label, value })
    and groups ({ label, options: Option[] }). For an option within a group to pass
    the filter, filterOption must first return true for the group, then again for the
    option itself.
  */
  function filterOption(option: Option | GroupBase<Option>, rawInput: string) {
    if ("options" in option && Array.isArray(option.options)) {
      // Current option is a group of options
      const group = option;
      const foundMatchInGroup = group.options.some((groupOption) =>
        filterOption(groupOption, rawInput)
      );
      return foundMatchInGroup;
    } else if (typeof option.label === "string") {
      // Current option is a single option
      return inputMatchesText(rawInput, option.label);
    } else {
      // If option.label is not a string, it can't be filtered
      return true;
    }
  }

  function getFilteredOptions() {
    if (!props.options) return [];

    if (props.options[0] && "option" in props.options[0]) {
      return props.options
        .filter((option) => filterOption(option, inputValue))
        .sort(sortForSelecting)
        .slice(0, MAX_OPTIONS);
    }

    return (props.options as Option[])
      .filter((option) => filterOption(option, inputValue))
      .sort(sortForSelecting)
      .slice(0, MAX_OPTIONS);
  }

  /*
  NOTE:
    if 50k options is not enough, reach for server side filtering.
    on change, issue a new query
    add a filter to cube that says dimension INCLUDES inputValue
  */
  function loadOptions() {
    const result = new Promise<(Option | GroupBase<Option>)[]>((resolve) => {
      resolve(getFilteredOptions());
    });

    return result;
  }

  function sortForSelecting(optionA: Option | GroupBase<Option>) {
    if (optionA && Array.isArray(props.selectedValues)) {
      if ("options" in optionA) {
        const selected = props.selectedValues.find((value) =>
          optionA.options.some((option) => option.value === value)
        );

        if (selected) {
          return -1;
        }
      } else {
        const selected = props.selectedValues.find(
          (value) => value === optionA.value
        );

        if (selected) {
          return -1;
        }
      }
    }

    return 0;
  }

  const allValues = props.options.map((option: Option | GroupBase<Option>) =>
    "options" in option
      ? option.options.map((option) => option.value)
      : option.value
  );
  const optionValueSet = Object.fromEntries(
    allValues.map((value) => [value, true])
  );
  const createdOptions = selectedOptions.filter(
    (selectedOption) => !optionValueSet[selectedOption.value]
  );

  useEffect(() => {
    if (!props.selectedValues) return;

    setSelectedCount(props.selectedValues.length);
    setSelectedOptions(
      props.selectedValues.map((value) => ({ label: value, value }))
    );
  }, [props.selectedValues, isOpen]);

  function handleChange(option: OnChangeValue<Option, IsMulti>): void {
    setInputValue("");
    props.onInputChange?.("");

    const options = !option
      ? null
      : isReadonlyArray(option)
        ? option
        : [option];

    if (!option || !options) return;

    if (!props.closeOnSubmit) setIsOpen(false);

    if (!props.isMulti && props.closeOnSubmit) {
      setInputValue((options[0] as Option).value);
      setSelectedCount(1);
      setSelectedOptions(options as Option[]);

      return;
    }

    if (isMultiProps(props)) {
      props.onChange(options.map((option) => option.value));
    }

    if (isNotMultiProps(props)) {
      props.onChange((options[0] as Option).value);
    }
  }

  function handleCreateOption(inputValue: string) {
    if (props.onCreateOption) {
      props.onCreateOption(inputValue);
    }

    if (props.createOption) {
      const createdOption = props.createOption(inputValue);
      setSelectedCount((current) => current + 1);
      setSelectedOptions((current) => [createdOption, ...current]);
      setInputValue(inputValue);
    }
  }

  function handleInputChange(value: string, { action }: InputActionMeta): void {
    if (["input-blur", "menu-close", "set-value"].includes(action)) {
      return;
    }

    if (props.isNumberInput && !isFinite(Number(value))) {
      return;
    }

    setInputValue(value);
  }

  function handleSelectOption(value: OnChangeValue<Option, IsMulti>): void {
    if (!value || !isReadonlyArray(value)) return;

    setSelectedCount(value.length);
    setSelectedOptions(value);
  }

  function handleSubmit(menuProps): void {
    setInputValue("");
    props.onInputChange?.("");
    if (isMultiProps(props)) {
      handleChange(menuProps.getValue());
    }
    if (isNotMultiProps(props)) {
      props.onChange(inputValue);
    }
    setIsOpen(false);
  }

  const defaultOptions = useMemo(() => {
    if (!props.options) return [];

    if (props.options.length < MAX_OPTIONS) {
      if (props.options[0] && "option" in props.options[0]) {
        return [];
      }

      return [...(props.options as Option[])].sort(sortForSelecting);
    }

    return getFilteredOptions().sort(sortForSelecting).slice(0, MAX_OPTIONS);
  }, [inputValue, props.options, props.isLoading]);

  function handleOnBlur() {
    if (props.onBlur) {
      props.onBlur();
    }

    setIsOpen(false);
  }

  const selectProps = {
    autoFocus: true,
    blurInputOnSelect: false,
    closeMenuOnSelect: false,
    components: {
      DropdownIndicator: null,
      IndicatorSeparator: null,
      ...(props.closeOnSubmit ? { Menu } : {}),
      ...(props.isMulti ? { Option } : {}),
    },
    controlShouldRenderValue: false,
    defaultOptions: defaultOptions,
    defaultValue: selectedOptions,
    disabled: props.disabled,
    hideSelectedOptions:
      props.hideSelectedOptions !== undefined
        ? props.hideSelectedOptions
        : !props.closeOnSubmit,
    filterOption: filterOption,
    inputValue: inputValue,
    isClearable: props.isClearable,
    isLoading: props.isLoading,
    isMulti: props.isMulti,
    loadOptions: loadOptions,
    menuIsOpen: true,
    options: [...createdOptions, ...props.options],
    placeholder:
      props.searchInputPlaceholder || copyText.searchInputPlaceholder,
    searchable: true,
    selectProps: {
      selectedCount: selectedCount,
      submitButtonText: props.submitButtonText,
      onSubmit: handleSubmit,
    },
    styles: getStyles(theme),
    value: props.sortSelected ? selectedOptions : undefined,
    onChange: props.isMulti ? handleSelectOption : handleChange,
    onInputChange: handleInputChange,
    onBlur: handleOnBlur,
  };

  const select = props.isCreatable ? (
    <AsyncCreatableSelect
      {...selectProps}
      formatCreateLabel={props.createDialogue}
      isValidNewOption={props.inputValidator}
      onCreateOption={handleCreateOption}
    />
  ) : (
    <Select {...selectProps} />
  );

  function handleClickTrigger() {
    if (props.disabled) return;

    if (props.onClick) {
      props.onClick();
    }

    setIsOpen((isOpen) => !isOpen);
  }

  const children = props.children
    ? React.cloneElement(props.children as ReactElement, {
        onClick: handleClickTrigger,
      })
    : null;

  return (
    <DropdownRoot>
      {isReactElement(children) && (
        <div style={{ width: "100%" }} ref={refs.setReference}>
          {children}
        </div>
      )}
      {isOpen && (
        <DropdownContent
          ref={refs.setFloating}
          style={{ ...floatingStyles }}
          placement={props.placement}
        >
          {select}
        </DropdownContent>
      )}
    </DropdownRoot>
  );
}

function isReadonlyArray<T>(arg: T | readonly T[]): arg is readonly T[] {
  return Array.isArray(arg);
}

function isMultiProps(props: Props<boolean>): props is Props<true> {
  return Boolean(props.isMulti);
}

function isNotMultiProps(props: Props<boolean>): props is Props<false> {
  return !props.isMulti;
}

const Menu = ({
  children,
  ...menuProps
}: MenuProps<Option, boolean>): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customProps = (menuProps.selectProps as any).selectProps;
  return (
    <components.Menu {...menuProps}>
      <>
        {children}
        <Button
          fullWidth
          primary
          onClick={() => {
            customProps.onSubmit(menuProps);
          }}
        >
          {`${customProps.submitButtonText ?? copyText.submitButtonLabel} (${
            customProps.selectedCount
          })`}
        </Button>
      </>
    </components.Menu>
  );
};

const Option = (props: OptionProps<Option, boolean>): JSX.Element => {
  return (
    <components.Option {...props}>
      <Checkbox checked={props.isSelected}>
        {props.isSelected && <IconCheck clickable />}
      </Checkbox>
      {props.label}
    </components.Option>
  );
};

function inputMatchesText(input: string, text: string) {
  return text.toLowerCase().includes(input.toLowerCase());
}
