import { Theme, useTheme } from "@emotion/react";
import React from "react";
import Select, { getStyles } from "../ui-lib/components/Select";

interface Props {
  classNamePrefix?: string;
  inputValue: string;
  isActive?: boolean;
  isCreateable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  suggestions: string[];
  value: string[];
  onChange: (s: string[]) => void;
  onInputChange: (s: string) => void;
}

function getOption(s: string) {
  return {
    label: s,
    value: s,
  };
}

const superiorStyles = (
  theme: Theme,
  compact?: boolean,
  isActive?: boolean
) => {
  return {
    ...getStyles(theme),
    input: (styles) => ({
      ...styles,
      color: theme.select_color,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: theme.select_color,
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: theme.input_background_color,
    }),
    control: (styles, props) => {
      return {
        ...styles,
        backgroundColor: theme.input_background_color,
        borderColor: isActive
          ? "transparent"
          : props.isFocused
            ? theme.select_control_border_color_focused
            : theme.select_control_border_color,
        borderStyle: "solid",
        borderWidth: `1px`,
        borderRadius: theme.borderRadius_2,
        boxShadow: (() => {
          if (isActive) return `0 0 0 2px ${theme.select_is_active}`;
          if (props.isFocused) return `0 0 0 2px ${theme.primary_color_focus}`;
        })(),
        color: `${theme.select_color} !important`,
        cursor: "pointer",
        fontSize: theme.fontSize_ui,
        minHeight: compact ? "30px" : "40px",

        "&:hover": {
          borderColor: props.isFocused
            ? "transparent"
            : isActive
              ? theme.select_control_border_color_focused
              : theme.primary_color_focus,
          ...(isActive && {
            boxShadow: `0 0 0 2px ${theme.select_is_active}`,
          }),
        },
      };
    },
    option: (styles, props) => ({
      ...styles,
      borderRadius: theme.borderRadius_2,
      cursor: "pointer",
      fontSize: theme.fontSize_ui,
      transition: "none",

      ...(props.isMulti
        ? {
            backgroundColor: "transparent",
            color: theme.text_color,
            display: "flex",

            "&:hover": {
              backgroundColor: theme.secondary_color_background,
            },
          }
        : {
            backgroundColor: props.isSelected
              ? theme.primary_color_background
              : theme.input_background_color,
            color: props.isSelected
              ? theme.text_color_inverse
              : theme.text_color,
            margin: `${theme.space_xxs} 0`,

            "&:hover": {
              backgroundColor: props.isSelected
                ? theme.primary_color_background
                : theme.secondary_color_background,
            },
          }),
    }),
  };
};

export default function SuperiorSelect(props: Props): JSX.Element {
  const theme = useTheme();

  function handleChangeSelect(value) {
    if (Array.isArray(value)) {
      props.onChange(value ? value.map((v) => v.value) : []);
      return;
    }

    props.onChange([value.value]);
  }

  const filteredSuggestions = new Set(
    props.suggestions
      ? props.suggestions.filter((s) => s !== props.inputValue)
      : []
  );

  const options = (
    props.inputValue && props.isCreateable
      ? [props.inputValue, ...filteredSuggestions]
      : [...new Set(props.suggestions)]
  ).map(getOption);

  const isMulti = props.isMulti ?? false;

  return (
    <Select
      closeMenuOnSelect={!isMulti}
      inputValue={props.inputValue || ""}
      isClearable={isMulti}
      isDisabled={props.isDisabled || false}
      isLoading={props.isLoading || false}
      isMulti={isMulti as false}
      isSearchable
      options={options}
      placeholder={props.placeholder || ""}
      styles={superiorStyles(theme, false, props.isActive)}
      value={props.value.map(getOption)}
      onChange={handleChangeSelect}
      onInputChange={(s: string, { action }) => {
        if (action !== "set-value") {
          props.onInputChange(s);
        }
      }}
    />
  );
}
