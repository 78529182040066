import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { reportID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { reportID: string }
>;

export default function useDeleteReport(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ reportID }) => client.deleteReport(reportID),
    ...options,
  });
}
