import { LinkWithSearchParams } from "@/lib/react-router";
import { TableLegacy } from "@/ui-lib/components/Table";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../../copyText";
import { CloudSQLResource, CloudSQLResourceType } from "../types";

type TableData = {
  classification: string;
  cpuAndRamCost: number | null;
  databaseType: string;
  hasNoUsageData: boolean;
  projectID: string;
  region: string;
  totalCost: number | null;
  totalUsage: number | null;
  totalReserved: number | null;
  utilizationPercent: string;
};

interface Props {
  isLoading: boolean;
  resources: CloudSQLResource[];
  resourceType: CloudSQLResourceType;
  selectedProjectID?: string;
  selectedResourceID?: string;
}

export default function CloudSQLResourceTable(props: Props): JSX.Element {
  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "showGraph",
        NoAccessorCell: function renderButton({ row }) {
          return (
            <Tooltip
              content={
                row.original.hasNoUsageData
                  ? copyText.cloudSQLResourceTableTooltipLabelViewNoInstances
                  : copyText.cloudSQLResourceTableTooltipLabelViewInstances
              }
            >
              <LinkWithSearchParams
                disabled={row.original.hasNoUsageData}
                searchParams={{
                  database_type: row.original.databaseType,
                  project_id: row.original.projectID,
                  region: row.original.region,
                }}
              >
                <Button
                  iconStart={<Icon icon={faChartLine} />}
                  primary
                  disabled={row.original.hasNoUsageData}
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        Header: "",
        width: 50,
      },
      {
        accessor: "classification",
        Header: "Classification",
        width: 220,
      },
      {
        accessor: "projectID",
        Header: "Project ID",
        width: 220,
      },
      {
        accessor: "cpuAndRamCost",
        Header: "CPU&RAM Cost",
        Cell: ({ value }) => <>{formatCost(value)}</>,
        width: 140,
        sortDescFirst: true,
      },
      {
        accessor: "utilizationPercent",
        Header: `${props.resourceType} Utilization`,
        width: 160,
        sortDescFirst: true,
      },
      {
        accessor: "totalReserved",
        Cell: ({ value }) => <>{formatByteUsage(props.resourceType, value)}</>,
        Header:
          props.resourceType === CloudSQLResourceType.CPU
            ? copyText.cloudSQLResourceTableHeaderTotalProvisionedCores
            : copyText.cloudSQLResourceTableHeaderTotalProvisionedBytes,
        width: 135,
        sortDescFirst: true,
      },
      {
        accessor: "totalUsage",
        Cell: ({ value }) => <>{formatByteUsage(props.resourceType, value)}</>,
        Header:
          props.resourceType === CloudSQLResourceType.CPU
            ? copyText.cloudSQLResourceTableHeaderTotalCores
            : copyText.cloudSQLResourceTableHeaderTotalBytes,
        width: 150,
        sortDescFirst: true,
      },
      {
        accessor: "totalCost",
        Header: "Total Cost",
        Cell: ({ value }) => <>{formatCost(value)}</>,
        sortDescFirst: true,
        width: 140,
      },
    ],
    [props.resourceType]
  );

  const data = useMemo(() => {
    return getTableDataByType(props.resources, props.resourceType);
  }, [props.resources, props.resourceType]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{
        hiddenColumns:
          props.resourceType === CloudSQLResourceType.NETWORK
            ? ["utilizationPercent", "totalReserved"]
            : [],
        sortBy: [{ id: "totalCost", desc: true }],
      }}
      isLoading={props.isLoading}
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
    />
  );
}

function getTableDataByType(
  resources: CloudSQLResource[],
  usageType: CloudSQLResourceType
): TableData[] {
  return resources.map((node): TableData => {
    const hasNoUsageData = getHasNoUsageData(node);

    const data: TableData = {
      hasNoUsageData,
      classification: getClassificationString(node, hasNoUsageData),
      cpuAndRamCost: node.cpuAndRamCost,
      databaseType: node.databaseType ?? "",
      region: node.region ?? "",
      projectID: node.projectId ?? "",
      totalCost: node.totalCost,
      totalUsage: null,
      totalReserved: null,
      utilizationPercent: "",
    };

    switch (usageType) {
      case CloudSQLResourceType.MEMORY:
        return {
          ...data,
          totalUsage: node.avgRamUsedBytes,
          totalReserved: node.latestProvisionedRam,
          utilizationPercent: safePercent(
            node.totalRamUsedBytes,
            node.totalRamReservedBytes
          ),
        };
      case CloudSQLResourceType.CPU:
        return {
          ...data,
          totalUsage: node.avgCpuUsedCoreHours,
          totalReserved: node.latestProvisionedCpu,
          utilizationPercent: safePercent(
            node.totalCpuUsedCoreHours,
            node.totalCpuReservedCores
          ),
        };
      case CloudSQLResourceType.DISK:
        return {
          ...data,
          totalUsage: node.avgDiskUsedBytes,
          totalReserved: node.latestProvisionedDisk,
          utilizationPercent: safePercent(
            node.totalDiskUsedBytes,
            node.totalDiskSizeBytes
          ),
        };
      case CloudSQLResourceType.NETWORK:
        return {
          ...data,
          totalUsage: node.avgNetworkSentBytes,
          utilizationPercent: copyText.cloudSQLResourceTableLabelNotAvailable,
        };
    }
  });
}

const formatCost = (cost: number | null) => {
  if (cost === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return formatCurrency({ number: cost });
};

const formatByteUsage = (
  usageType: CloudSQLResourceType,
  bytes: number | null
) => {
  if (bytes === null) return copyText.cloudSQLResourceTableLabelNotAvailable;
  return usageType !== CloudSQLResourceType.CPU
    ? prettyBytes(bytes)
    : formatNumber(bytes, 2);
};

function safePercent(used?: number | null, total?: number | null): string {
  if (used === null && total === null) {
    return copyText.cloudSQLResourceTableLabelNotAvailable;
  }
  return typeof used === "number" && typeof total === "number" && total > 0
    ? `${((used / total) * 100).toFixed(2)}%`
    : `0`;
}

function getHasNoUsageData(node: CloudSQLResource) {
  const result =
    node.maxCpuReservedCores === null &&
    node.maxCpuAverageUsedCores === null &&
    node.ramReservedBytes === null &&
    node.maxRamAverageUsedBytes === null &&
    node.maxDiskSizeBytes === null &&
    node.maxDiskUsedBytes === null;

  return result;
}

function getClassificationString(
  node: CloudSQLResource,
  hasNoUsageData: boolean
) {
  const { databaseType, region, classification } = node;
  const isNull = (s: string | null) => s === null || s === "null";

  if (!hasNoUsageData) {
    return `[ ${databaseType} / ${region} ]`;
  }

  if (!isNull(databaseType) && !isNull(region)) {
    return `[ ${databaseType} / ${region} / ${classification} ]`;
  }

  if (isNull(databaseType)) {
    return `[ ${classification} / ${region} ]`;
  }

  if (isNull(region)) {
    return `[ ${databaseType} / ${classification} ]`;
  }

  return `[ ${classification} ]`;
}
