import useGatekeeper from "@/hooks/useGatekeeper";
import { Input } from "@/types";
import Form, { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Select from "@/ui-lib/components/Select";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  CloudProviderType,
  RecommendationCategory,
  SavingsOpportunityType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useState } from "react";
import copyText from "../copyText";
import { SavingsOpportunityFilter } from "./SavingsOpportunityViewContainer";

export enum Action {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

type Props = {
  action: Action;
  isProcessing: boolean;
  savingsOpportunity?: SavingsOpportunityFilter;
  onInteraction: (interaction: SavingsOpportunityForm.Interaction) => void;
};

type State = {
  categoryInput: Input<RecommendationCategory | null>;
  cloudProviderTypeInput: Input<CloudProviderType | null>;
  isConfirmingSystemSavingOpp: boolean;
  nameInput: Input<string>;
  savingsTypeInput: Input<SavingsOpportunityType>;
  serviceTypeInput: Input<ServiceType | null>;
};

export function SavingsOpportunityForm(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();
  const initialState: State = {
    categoryInput: {
      hasChanged: false,
      isValid: false,
      value: props.savingsOpportunity?.category ?? null,
    },
    cloudProviderTypeInput: {
      hasChanged: false,
      isValid: false,
      value: props.savingsOpportunity?.cloudProviderType ?? null,
    },
    isConfirmingSystemSavingOpp: false,
    nameInput: {
      hasChanged: false,
      isValid: props.savingsOpportunity?.name ? true : false,
      value: props.savingsOpportunity?.name ?? "",
    },
    savingsTypeInput: {
      hasChanged: false,
      isValid: true,
      value:
        props.savingsOpportunity?.savingsType ??
        SavingsOpportunityType.RATE_AND_USAGE,
    },
    serviceTypeInput: {
      hasChanged: false,
      isValid: false,
      value: props.savingsOpportunity?.serviceType ?? null,
    },
  };
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const theme = useTheme();

  const isGlobalSavingOpp =
    props.savingsOpportunity?.tenantID === SYSTEM_TENANT_ID;
  const canCreateGlobal =
    gatekeeper.canCreateGlobalSavingsOpp && props.action === Action.CREATE;

  const hasChanged = Object.values(state).some((input) => {
    if (typeof input !== "boolean") {
      return input.hasChanged;
    }
  });

  const canSubmit =
    props.action === Action.UPDATE
      ? hasChanged && state.nameInput.isValid
      : state.nameInput.isValid;

  //
  // Interaction Handlers
  //

  function handleChangeName(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = event.target.value;

    mergeState({
      nameInput: {
        hasChanged: value !== initialState.nameInput.value,
        isValid: value.trim().length > 0,
        value,
      },
    });
  }

  function handleChangeSelectInput(input: string | null, name: string): void {
    const hasChanged = !isEqual(input, props.savingsOpportunity?.[name]);

    if (name === "savingsType") {
      mergeState({
        categoryInput: initialState.categoryInput,
        serviceTypeInput: initialState.serviceTypeInput,
      });
    }

    mergeState({
      [`${name}Input`]: { value: input, isValid: true, hasChanged },
    });
  }

  const savingsTypeInputOptions = [
    {
      label: copyText.savingOpportunityFormSavingsTypeRateInputLable,
      value: SavingsOpportunityType.RATE,
    },
    {
      label: copyText.savingOpportunityFormSavingsTypeUsageInputLable,
      value: SavingsOpportunityType.USAGE,
    },
    {
      label: copyText.savingOpportunityFormSavingsTypeRateUsageInputLable,
      value: SavingsOpportunityType.RATE_AND_USAGE,
    },
  ];

  const savingsTypeInputOption = savingsTypeInputOptions.find(
    (option) => option.value === state.savingsTypeInput.value
  );

  const serviceTypeOptions = [
    {
      label: copyText.savingOpportunityDefaultServiceTypeInputLable,
      value: null,
    },
    {
      label: copyText.widgetTooltip_EBS,
      value: ServiceType.EBS,
      cloudProviderType: CloudProviderType.AWS,
      category: RecommendationCategory.STORAGE,
    },
    {
      label: copyText.widgetTooltip_EC2,
      value: ServiceType.EC2,
      cloudProviderType: CloudProviderType.AWS,
      category: RecommendationCategory.COMPUTE,
    },
    {
      label: copyText.widgetTooltip_EKS,
      value: ServiceType.EKS,
      cloudProviderType: CloudProviderType.AWS,
      category: RecommendationCategory.KUBERNETES,
    },
    {
      label: copyText.widgetTooltip_GCE,
      value: ServiceType.GCE,
      cloudProviderType: CloudProviderType.GCP,
      category: RecommendationCategory.COMPUTE,
    },
    {
      label: copyText.widgetTooltip_GCS,
      value: ServiceType.GCS,
      cloudProviderType: CloudProviderType.GCP,
      category: RecommendationCategory.STORAGE,
    },
    {
      label: copyText.widgetTooltip_GKE,
      value: ServiceType.GKE,
      cloudProviderType: CloudProviderType.GCP,
      category: RecommendationCategory.KUBERNETES,
    },
    {
      label: copyText.widgetTooltip_RDS,
      value: ServiceType.RDS,
      cloudProviderType: CloudProviderType.AWS,
      category: RecommendationCategory.DATABASE,
    },
    {
      label: copyText.widgetTooltip_S3,
      value: ServiceType.S3,
      cloudProviderType: CloudProviderType.AWS,
      category: RecommendationCategory.STORAGE,
    },
    {
      label: copyText.widgetTooltip_SQL,
      value: ServiceType.SQL,
      cloudProviderType: CloudProviderType.GCP,
      category: RecommendationCategory.DATABASE,
    },
  ].filter((serviceType) => {
    if (state.categoryInput.value && state.cloudProviderTypeInput.value) {
      return (
        serviceType.category === state.categoryInput.value &&
        serviceType.cloudProviderType === state.cloudProviderTypeInput.value
      );
    } else if (state.categoryInput.value) {
      return serviceType.category === state.categoryInput.value;
    } else if (state.cloudProviderTypeInput.value) {
      return (
        serviceType.cloudProviderType === state.cloudProviderTypeInput.value
      );
    }
    return serviceType;
  });

  const serviceTypeOption = serviceTypeOptions.find(
    (option) => option.value === state.serviceTypeInput.value
  );

  const cloudProviderTypeOptions = [
    {
      label: copyText.savingOpportunityDefaultProviderInputLable,
      value: null,
    },
    {
      label: CloudProviderType.GCP,
      value: CloudProviderType.GCP,
    },
    {
      label: CloudProviderType.AWS,
      value: CloudProviderType.AWS,
    },
  ].filter((cloudProvider) => {
    if (state.serviceTypeInput.value) {
      return cloudProvider.value === serviceTypeOption?.cloudProviderType;
    } else {
      return cloudProvider;
    }
  });

  const categoryOptions = [
    {
      label: copyText.savingOpportunityDefaultCategoryInputLable,
      value: null,
    },
    {
      label: copyText.sideNavItemLabelInsightsCompute,
      value: RecommendationCategory.COMPUTE,
    },
    {
      label: copyText.sideNavItemLabelInsightsDatabase,
      value: RecommendationCategory.DATABASE,
    },
    {
      label: copyText.sideNavItemLabelInsightsKubernetes,
      value: RecommendationCategory.KUBERNETES,
    },
    {
      label: copyText.sideNavItemLabelInsightsStorage,
      value: RecommendationCategory.STORAGE,
    },
  ].filter((category) => {
    if (state.serviceTypeInput.value) {
      return category.value === serviceTypeOption?.category;
    } else {
      return category;
    }
  });

  const cloudProviderTypeOption = cloudProviderTypeOptions.find(
    (option) => option.value === state.cloudProviderTypeInput.value
  );

  const categoryOption = categoryOptions.find(
    (option) => option.value === state.categoryInput.value
  );

  //
  // Render
  //

  if (state.isConfirmingSystemSavingOpp) {
    return (
      <Modal
        closeOnClickOutside={false}
        isOpen={true}
        showCloseButton
        width={500}
        onClose={() => mergeState({ isConfirmingSystemSavingOpp: false })}
      >
        <Modal.Header>
          <Text appearance="h4">{copyText.systemSavingOppAction}</Text>
        </Modal.Header>

        <Modal.Body>{copyText.systemSavingOpportunityPrompt}</Modal.Body>

        <Modal.Footer>
          <Button
            disabled={props.isProcessing}
            secondary
            width={70}
            onClick={() => mergeState({ isConfirmingSystemSavingOpp: false })}
          >
            {copyText.cancelButtonLabel}
          </Button>
          <Button
            disabled={props.isProcessing}
            locked={
              !gatekeeper.canEditGlobalSavingsOpp &&
              !gatekeeper.canCreateGlobalSavingsOpp
            }
            primary
            width={70}
            onClick={handleSubmit}
          >
            {props.isProcessing ? (
              <LoadingSpinner />
            ) : (
              copyText.systemReportConfirm
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    if (props.action === Action.CREATE) {
      props.onInteraction({
        type: SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        categoryInput: state.categoryInput.value,
        cloudProviderTypeInput: state.cloudProviderTypeInput.value,
        isConfirmingSystemSavingOpp: state.isConfirmingSystemSavingOpp,
        nameInput: state.nameInput.value.trim(),
        savingsTypeInput: state.savingsTypeInput.value,
        serviceTypeInput: state.serviceTypeInput.value,
      });
    } else {
      props.onInteraction({
        type: SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        savingOpportunityFilterID: props.savingsOpportunity?.id ?? "",
        ...(state.categoryInput.hasChanged
          ? { category: state.categoryInput.value }
          : {}),
        ...(state.cloudProviderTypeInput.hasChanged
          ? { cloudProviderType: state.cloudProviderTypeInput.value }
          : {}),
        isConfirmingSystemSavingOpp: state.isConfirmingSystemSavingOpp,
        ...(state.nameInput.hasChanged
          ? { name: state.nameInput.value.trim() }
          : {}),
        ...(state.savingsTypeInput.hasChanged
          ? { savingsType: state.savingsTypeInput.value }
          : {}),
        ...(state.serviceTypeInput.hasChanged
          ? { serviceType: state.serviceTypeInput.value }
          : {}),
      });
    }
  }

  return (
    <Form>
      <Flex direction="column" justifyContent="space-between">
        {/* Name */}
        <Box>
          <FormField
            required
            name="name"
            input={TextInput}
            label={copyText.savingOpportunityFormNameInputLable}
            value={state.nameInput.value}
            onChange={handleChangeName}
          />
        </Box>
        <Box>
          {/* Savings Type */}
          <Box>
            <FormField
              required
              label={copyText.savingOpportunityFormSavingsTypeInputLable}
            >
              <Box>
                <Select
                  menuPlacement="bottom"
                  isClearable={state.savingsTypeInput.value ? true : false}
                  options={savingsTypeInputOptions}
                  value={savingsTypeInputOption}
                  onChange={(option) =>
                    handleChangeSelectInput(
                      option?.value || null,
                      "savingsType"
                    )
                  }
                />
              </Box>
            </FormField>
          </Box>
          {/* Cloud Provider  */}
          <FormField label={copyText.savingOpportunityFormProviderInputLable}>
            <Box>
              <Select
                menuPlacement="bottom"
                isClearable={state.cloudProviderTypeInput.value ? true : false}
                options={cloudProviderTypeOptions}
                value={cloudProviderTypeOption}
                placeholder={
                  copyText.savingOpportunityDefaultProviderInputLable
                }
                onChange={(option) =>
                  handleChangeSelectInput(
                    option?.value || null,
                    "cloudProviderType"
                  )
                }
              />
            </Box>
          </FormField>
          {/* Category */}
          {state.savingsTypeInput.value === SavingsOpportunityType.USAGE && (
            <FormField label={copyText.savingOpportunityFormCategoryInputLable}>
              <Box>
                <Select
                  menuPlacement="bottom"
                  isClearable={state.categoryInput.value ? true : false}
                  options={categoryOptions}
                  placeholder={
                    copyText.savingOpportunityDefaultCategoryInputLable
                  }
                  value={categoryOption}
                  onChange={(option) =>
                    handleChangeSelectInput(option?.value || null, "category")
                  }
                />
              </Box>
            </FormField>
          )}
          {/* Service Type */}
          {state.savingsTypeInput.value === SavingsOpportunityType.USAGE && (
            <FormField
              label={copyText.savingOpportunityFormServiceTypeInputLable}
            >
              <Box>
                <Select
                  menuPlacement="bottom"
                  isClearable={state.serviceTypeInput.value ? true : false}
                  placeholder={
                    copyText.savingOpportunityDefaultServiceTypeInputLable
                  }
                  options={serviceTypeOptions}
                  value={serviceTypeOption}
                  onChange={(option) =>
                    handleChangeSelectInput(
                      option?.value || null,
                      "serviceType"
                    )
                  }
                />
              </Box>
            </FormField>
          )}
        </Box>
      </Flex>

      <Flex
        justifyContent={canCreateGlobal ? "space-between" : "flex-end"}
        marginTop={theme.space_sm}
        paddingBottom={theme.space_sm}
      >
        {/* Global Savings Opportunity */}
        {canCreateGlobal ? (
          <Button
            disabled={state.nameInput.value.length === 0}
            primary
            onClick={() => mergeState({ isConfirmingSystemSavingOpp: true })}
          >
            {copyText.systemSavingOppAction}
          </Button>
        ) : null}
        <Box>
          <Button
            disabled={props.isProcessing}
            secondary
            type="reset"
            width={100}
            onClick={() =>
              props.onInteraction({
                type: SavingsOpportunityForm.INTERACTION_CANCEL_BUTTON_CLICKED,
              })
            }
          >
            {copyText.cancelButtonLabel}
          </Button>
          <Button
            disabled={!canSubmit || props.isProcessing}
            marginLeft={theme.space_sm}
            locked={isGlobalSavingOpp && !gatekeeper.canEditGlobalSavingsOpp}
            primary
            type="button"
            width={100}
            onClick={(event) => {
              if (isGlobalSavingOpp) {
                mergeState({ isConfirmingSystemSavingOpp: true });
              } else {
                handleSubmit(event);
              }
            }}
          >
            {props.isProcessing ? (
              <LoadingSpinner />
            ) : (
              copyText.submitButtonLabel
            )}
          </Button>
        </Box>
      </Flex>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SavingsOpportunityForm {
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `SavingsOpportunityForm.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE = `SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE = `SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE`;

  type InteractionCancelButtonClicked = {
    type: typeof SavingsOpportunityForm.INTERACTION_CANCEL_BUTTON_CLICKED;
  };

  type InteractionSubmitButtonClickedCreate = {
    type: typeof SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
    categoryInput: RecommendationCategory | null;
    cloudProviderTypeInput: CloudProviderType | null;
    isConfirmingSystemSavingOpp: boolean;
    nameInput: string;
    savingsTypeInput: SavingsOpportunityType;
    serviceTypeInput: ServiceType | null;
  };

  type InteractionSubmitButtonClickedUpdate = {
    type: typeof SavingsOpportunityForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
    savingOpportunityFilterID: string;
    category?: RecommendationCategory | null;
    cloudProviderType?: CloudProviderType | null;
    isConfirmingSystemSavingOpp: boolean;
    name?: string;
    savingsType?: SavingsOpportunityType;
    serviceType?: ServiceType | null;
  };

  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClickedUpdate
    | InteractionSubmitButtonClickedCreate;
}

export default SavingsOpportunityForm;
