import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { GetBigQueryMetadataParams } from "../../../api/core/types";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type BigQueryMetadataEntity = Awaited<
  ReturnType<CoreAPIClient["getBigQueryMetadata"]>
>;

export default function useGetBigQueryMetadata(
  params: GetBigQueryMetadataParams,
  options?: UseQueryOptions<BigQueryMetadataEntity, CoreAPIClientError>
): UseQueryResult<BigQueryMetadataEntity, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["bigQueryMetadata", params],
    queryFn: () => client.getBigQueryMetadata(params),
    meta: { errorMessage: copyText.errorLoadingBigQueryMetadataMessage },
    ...options,
  });
}
