import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateSavingsOpportunityFilterParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  {
    filterID: string;
  } & UpdateSavingsOpportunityFilterParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  {
    filterID: string;
  } & UpdateSavingsOpportunityFilterParameters
>;

export default function useUpdateSavingsOpportunityFilter(
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ filterID, ...params }) => {
      return client.updateSavingsOpportunityFilter(filterID, params);
    },
    ...options,
  });
}
