import copyText from "../copyText";

type LabelGroupingRule = {
  inputs: { key: string; mappingIndex: number; priority: number }[];
  outputKey: string;
};

export function useGetLabelGroupingRulesCsvData(
  labelGroupingRules: LabelGroupingRule[]
) {
  const csvAccessors = ["outputKey", "inputs"] as const;

  if (labelGroupingRules.length === 0) {
    return { headers: [], rows: [] };
  }

  const rows = labelGroupingRules.map((labelGroupingRule) => {
    return {
      inputs: labelGroupingRule.inputs.map((input) => input.key),
      outputKey: labelGroupingRule.outputKey,
    };
  });

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `labelGroupingRulesTableHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
