import { useQuery } from "@tanstack/react-query";
import copyText from "../../common.copyText";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import CoreAPIClient from "./CoreAPIClient";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";
import filterKeys from "./queryKeys";

type ScopedViewFilter = Awaited<
  ReturnType<CoreAPIClient["getScopedViewFiltersByIDs"]>
>[0];

export default function useGetScopedViewFiltersByIDs(
  scopedViewIDs: string[],
  options?: UseQueryOptions<ScopedViewFilter[], CoreAPIClientError>
): UseQueryResult<ScopedViewFilter[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: filterKeys.scopedViewFilters(),
    queryFn: () => client.getScopedViewFiltersByIDs(scopedViewIDs),
    meta: { errorMessage: copyText.errorLoadingScopedViewFiltersMessage },
    ...options,
  });
}
