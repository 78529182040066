import Switch from "@/ui-lib/components/Switch";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";
import { GcpCommitmentDurationType } from "@ternary/api-lib/constants/enums";
import { GCPIntegrationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useMemo, useState } from "react";
import copyText from "../../copyText";
import {
  CUDInventoryEntity,
  CustomPricingEntity,
  GcpCommitmentStatusType,
} from "../types";
import {
  getActualSpend,
  getAverageUsage,
  getModifiedSpend,
  getOptimalPositionFor1yrCUDs,
  getOptimalPositionFor3yrCUDs,
} from "../utils";
import CUDChart from "./CUDChart";
import CUDFilters from "./CUDFilters";
import CUDGraph from "./CUDGraph";
import CUDSlider from "./CUDSlider";

export const SECONDS_PER_HOUR = 3600;
const MAX_SLIDER_RATIO = 1.2;

const StyledDivider = styled.div`
  width: 0.1rem;
  padding-top: 10%;
  background-color: ${({ theme }) => theme.secondary_color_background};
`;

const StyledSide = styled.div`
  width: 48%;
  z-index: 0;
`;

type Family = {
  cost: number;
  name: string;
};

type Region = {
  cost: number;
  name: string;
};

type HourUsageDatum = {
  cud1YearCost: number;
  cud1YearUsageAmount: number;
  cud3YearCost: number;
  cud3YearUsageAmount: number;
  cudsCreditAmount: number;
  family: string;
  region: string;
  resourceCost: number;
  resourceUsageAmount: number;
  resourceUsageUnit: string;
  sudsCreditAmount: number;
  totalResourceCreditsAmount: number;
  timestamp: string;
};

interface Props {
  cpuUsage: HourUsageDatum[];
  cudInventory: CUDInventoryEntity[];
  customPricing: CustomPricingEntity | undefined;
  families: Family[];
  gcpIntegrations: GCPIntegrationEntity[];
  isDisabledCloudFilter: boolean;
  isDisabledFamilyFilter: boolean;
  isDisabledProjectFilter: boolean;
  isDisabledRegionFilter: boolean;
  isEnabledCUDSharing: boolean;
  isLoadingCloudFilter: boolean;
  isLoadingCustomPricing: boolean;
  isLoadingFamilies: boolean;
  isLoadingRegions: boolean;
  isLoadingUsageData: boolean;
  memoryUsage: HourUsageDatum[];
  regions: Region[];
  selectedCloudID: string | null;
  selectedFamily: string | null;
  selectedRegion: string | null;
  selectedSUDToggleCPU: boolean;
  selectedSUDToggleMemory: boolean;
  onChangeCloud: (selection: string) => void;
  onChangeFamily: (selection: string) => void;
  onChangeRegion: (selection: string) => void;
  onChangeSUDToggleCPU: (selection: boolean) => void;
  onChangeSUDToggleMemory: (selection: boolean) => void;
}

interface State {
  cpuSlider1yr: number;
  cpuSlider3yr: number;
  memorySlider1yr: number;
  memorySlider3yr: number;
}

export default function CUDOptimizerSection(props: Props): JSX.Element {
  const theme = useTheme();
  const [state, setState] = useState<State>({
    cpuSlider1yr: 0,
    cpuSlider3yr: 0,
    memorySlider1yr: 0,
    memorySlider3yr: 0,
  });
  const mergeState = getMergeState(setState);

  const hasData =
    props.cpuUsage &&
    props.cpuUsage.length > 0 &&
    props.memoryUsage &&
    props.memoryUsage.length > 0;

  let currentCUD1yrCPU = 0;
  let currentCUD3yrCPU = 0;

  let currentCUD1yrMemory = 0;
  let currentCUD3yrMemory = 0;

  if (hasData) {
    props.cudInventory.map((cud) => {
      if (cud.commitmentStatus === GcpCommitmentStatusType.ACTIVE) {
        if (cud.commitmentPlan === GcpCommitmentDurationType.TWELVE_MONTH) {
          currentCUD1yrCPU += cud.resourceAmountVCPU;
          currentCUD1yrMemory += cud.resourceAmountRAM;
        } else {
          currentCUD3yrCPU += cud.resourceAmountVCPU;
          currentCUD3yrMemory += cud.resourceAmountRAM;
        }
      }
    });
    currentCUD1yrCPU = currentCUD1yrCPU * 3600;
    currentCUD3yrCPU = currentCUD3yrCPU * 3600;
    currentCUD1yrMemory = currentCUD1yrMemory * 3600;
    currentCUD3yrMemory = currentCUD3yrMemory * 3600;
  }

  useEffect(() => {
    mergeState({
      cpuSlider1yr: currentCUD1yrCPU,
      cpuSlider3yr: currentCUD3yrCPU,
      memorySlider1yr: currentCUD1yrMemory,
      memorySlider3yr: currentCUD3yrMemory,
    });
  }, [props.cpuUsage, props.memoryUsage]);

  const { averageUsageCPU, averageUsageMemory } = useMemo(() => {
    if (!hasData) {
      return { averageUsageCPU: 0, averageUsageMemory: 0 };
    }

    const averageUsageCPU = getAverageUsage(props.cpuUsage);
    const averageUsageMemory = getAverageUsage(props.memoryUsage);

    return { averageUsageCPU, averageUsageMemory };
  }, [props.cpuUsage, props.memoryUsage]);

  function handleChangeSliderCPU(
    duration: "Slider1yr" | "Slider3yr",
    value: number
  ) {
    mergeState({ [`cpu${duration}`]: value });
  }

  function handleChangeSliderMem(
    duration: "Slider1yr" | "Slider3yr",
    value: number
  ) {
    mergeState({ [`memory${duration}`]: value });
  }

  //
  // Get Optimal Positions
  //

  const optimalPositionFor1yrCUDS_cpu = useMemo(
    () =>
      getOptimalPositionFor1yrCUDs({
        averageUsage: averageUsageCPU,
        cuds3Year: state.cpuSlider3yr,
        customPricing: props.customPricing,
        selectedSUDToggle: props.selectedSUDToggleCPU,
        usage: props.cpuUsage,
      }),
    [
      state.cpuSlider3yr,
      props.cpuUsage,
      averageUsageCPU,
      props.selectedSUDToggleCPU,
    ]
  );

  const optimalPositionFor3yrCUDS_cpu = useMemo(
    () =>
      getOptimalPositionFor3yrCUDs({
        averageUsage: averageUsageCPU,
        cuds1Year: state.cpuSlider1yr,
        customPricing: props.customPricing,
        selectedSUDToggle: props.selectedSUDToggleCPU,
        usage: props.cpuUsage,
      }),
    [
      state.cpuSlider1yr,
      props.cpuUsage,
      averageUsageCPU,
      props.selectedSUDToggleCPU,
    ]
  );

  const optimalPositionFor1yrCUDS_memory = useMemo(
    () =>
      getOptimalPositionFor1yrCUDs({
        averageUsage: averageUsageMemory,
        cuds3Year: state.memorySlider3yr,
        customPricing: props.customPricing,
        selectedSUDToggle: props.selectedSUDToggleMemory,
        usage: props.memoryUsage,
      }),
    [
      state.memorySlider3yr,
      props.memoryUsage,
      averageUsageMemory,
      props.selectedSUDToggleMemory,
    ]
  );

  const optimalPositionFor3yrCUDS_memory = useMemo(
    () =>
      getOptimalPositionFor3yrCUDs({
        averageUsage: averageUsageMemory,
        cuds1Year: state.memorySlider1yr,
        customPricing: props.customPricing,
        selectedSUDToggle: props.selectedSUDToggleMemory,
        usage: props.memoryUsage,
      }),
    [
      state.memorySlider1yr,
      props.memoryUsage,
      averageUsageMemory,
      props.selectedSUDToggleMemory,
    ]
  );

  //
  // Get Spend Data For Usage
  //

  const actualCpuSpend = useMemo(
    () => getActualSpend(props.cpuUsage),
    [props.cpuUsage]
  );

  const actualMemSpend = useMemo(
    () => getActualSpend(props.memoryUsage),
    [props.memoryUsage]
  );

  const modifiedCpuSpend = useMemo(
    () =>
      getModifiedSpend({
        customPricing: props.customPricing,
        usage: props.cpuUsage,
        cud1Yr: state.cpuSlider1yr,
        cud3yr: state.cpuSlider3yr,
        selectedSUDToggle: props.selectedSUDToggleCPU,
      }),
    [
      props.cpuUsage,
      state.cpuSlider1yr,
      state.cpuSlider3yr,
      props.selectedSUDToggleCPU,
    ]
  );

  const modifiedMemSpend = useMemo(
    () =>
      getModifiedSpend({
        customPricing: props.customPricing,
        usage: props.memoryUsage,
        cud1Yr: state.memorySlider1yr,
        cud3yr: state.memorySlider3yr,
        selectedSUDToggle: props.selectedSUDToggleMemory,
      }),
    [
      props.memoryUsage,
      state.memorySlider1yr,
      state.memorySlider3yr,
      props.selectedSUDToggleMemory,
    ]
  );

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      marginBottom={theme.space_lg}
    >
      <Flex
        alignItems="center"
        borderBottom={`1px solid ${theme.section_card_border}`}
        padding={theme.space_md}
      >
        <Text appearance="h4">{copyText.cudOptimizerSectionTitle}</Text>
        <CUDFilters
          families={props.families}
          gcpIntegrations={props.gcpIntegrations}
          isDisabledCloudFilter={props.isDisabledCloudFilter}
          isDisabledFamilyFilter={props.isDisabledFamilyFilter}
          isDisabledProjectFilter={props.isDisabledProjectFilter}
          isDisabledRegionFilter={props.isDisabledRegionFilter}
          isEnabledCUDSharing={props.isEnabledCUDSharing}
          isLoadingCloudFilter={props.isLoadingCloudFilter}
          isLoadingFamilies={props.isLoadingFamilies}
          isLoadingRegions={props.isLoadingRegions}
          regions={props.regions}
          selectedCloudID={props.selectedCloudID}
          selectedFamily={props.selectedFamily}
          selectedRegion={props.selectedRegion}
          onChangeCloud={props.onChangeCloud}
          onChangeFamily={props.onChangeFamily}
          onChangeRegion={props.onChangeRegion}
        />
      </Flex>
      {renderBody(hasData)}
    </Box>
  );

  function renderBody(hasData: boolean): JSX.Element {
    if (!hasData || props.isLoadingCustomPricing) {
      return (
        <EmptyPlaceholder
          icon={faChartArea}
          height="600px"
          loading={props.isLoadingUsageData || props.isLoadingCustomPricing}
          text={copyText.cudEmptyPlaceholderText}
          skeletonVariant="cuds"
        />
      );
    }

    const unitCpu = "vcore-seconds";
    const unitMemory = "byte-seconds";

    const isUnadjustedCPU =
      currentCUD1yrCPU === state.cpuSlider1yr &&
      currentCUD3yrCPU === state.cpuSlider3yr;

    const isUnadjustedMemory =
      currentCUD1yrMemory === state.memorySlider1yr &&
      currentCUD3yrMemory === state.memorySlider3yr;

    return (
      <Flex
        padding={theme.space_md}
        justifyContent="space-between"
        minWidth={1000}
      >
        <StyledSide>
          <Flex justifyContent="space-between" alignItems="center">
            <Text appearance="h3">{copyText.cudOptimizerTitleCPU}</Text>
            <Box>
              <Flex>
                <Text marginRight={theme.space_xs}>
                  {copyText.cudOptimizerSudCreditLabel}
                </Text>
                <Switch
                  name="enabledSudsCredit"
                  checked={props.selectedSUDToggleCPU}
                  onChange={(value) => props.onChangeSUDToggleCPU(value)}
                />
              </Flex>
            </Box>
          </Flex>
          <CUDSlider
            customPricing={props.customPricing}
            duration={"1yr"}
            markBottom={currentCUD1yrCPU}
            min={0}
            markTop={optimalPositionFor1yrCUDS_cpu}
            max={averageUsageCPU * MAX_SLIDER_RATIO}
            type={"cpu"}
            unit={unitCpu}
            value={state.cpuSlider1yr}
            onChange={(val: number) => handleChangeSliderCPU("Slider1yr", val)}
          />
          <CUDSlider
            customPricing={props.customPricing}
            duration={"3yr"}
            markBottom={currentCUD3yrCPU}
            markTop={optimalPositionFor3yrCUDS_cpu}
            min={0}
            max={averageUsageCPU * MAX_SLIDER_RATIO}
            type={"cpu"}
            unit={unitCpu}
            value={state.cpuSlider3yr}
            onChange={(val: number) => handleChangeSliderCPU("Slider3yr", val)}
          />
          <Text appearance="h4" marginBottom={theme.space_md}>
            {copyText.cudChartTitle}
          </Text>
          <CUDChart
            isUnadjusted={isUnadjustedCPU}
            modifiedSpendData={modifiedCpuSpend}
            referenceSpendData={actualCpuSpend}
          />
          <CUDGraph
            cud1YrPerHour={state.cpuSlider1yr}
            cud3YrPerHour={state.cpuSlider3yr}
            timeSeriesData={props.cpuUsage}
          />
        </StyledSide>
        <StyledDivider />
        <StyledSide>
          <Flex justifyContent="space-between" alignItems="center">
            <Text appearance="h3">{copyText.cudOptimizerTitleMem}</Text>
            <Box>
              <Flex>
                <Text marginRight={theme.space_xs}>
                  {copyText.cudOptimizerSudCreditLabel}
                </Text>
                <Switch
                  name="enabledSudsCredit"
                  checked={props.selectedSUDToggleMemory}
                  onChange={(value) => props.onChangeSUDToggleMemory(value)}
                />
              </Flex>
            </Box>
          </Flex>
          <CUDSlider
            customPricing={props.customPricing}
            duration={"1yr"}
            markBottom={currentCUD1yrMemory}
            markTop={optimalPositionFor1yrCUDS_memory}
            min={0}
            max={averageUsageMemory * MAX_SLIDER_RATIO}
            type={"mem"}
            unit={unitMemory}
            value={state.memorySlider1yr}
            onChange={(val: number) => handleChangeSliderMem("Slider1yr", val)}
          />
          <CUDSlider
            customPricing={props.customPricing}
            duration={"3yr"}
            markBottom={currentCUD3yrMemory}
            markTop={optimalPositionFor3yrCUDS_memory}
            min={0}
            max={averageUsageMemory * MAX_SLIDER_RATIO}
            type={"mem"}
            unit={unitMemory}
            value={state.memorySlider3yr}
            onChange={(val: number) => handleChangeSliderMem("Slider3yr", val)}
          />
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.h4_fontWeight}
            marginBottom={theme.space_md}
          >
            {copyText.cudChartTitle}
          </Text>
          <CUDChart
            isUnadjusted={isUnadjustedMemory}
            modifiedSpendData={modifiedMemSpend}
            referenceSpendData={actualMemSpend}
          />
          <CUDGraph
            cud1YrPerHour={state.memorySlider1yr}
            cud3YrPerHour={state.memorySlider3yr}
            timeSeriesData={props.memoryUsage}
          />
        </StyledSide>
      </Flex>
    );
  }
}
