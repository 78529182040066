import { useQuery } from "@tanstack/react-query";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";

// NOTE: 50 Minutes.
const TOKEN_REFETCH_INTERVAL = 50 * 60 * 1000;

// TODO: Remove this when cube shim is gone
export default function useGenerateAnalyticsToken(
  tenantDocID: string,
  options?: UseQueryOptions<string, CoreAPIClientError>
): UseQueryResult<string, CoreAPIClientError> {
  const coreClient = useCoreAPIClient();

  return useQuery({
    queryKey: ["analyticsToken"],
    queryFn: () => coreClient.generateAnalyticsToken(tenantDocID),
    refetchInterval: TOKEN_REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    ...options,
  });
}
