import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { billingInfoID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { billingInfoID: string }
>;

export default function useDeleteBillingInfo(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ billingInfoID }) =>
      client.deleteMspBillingInfo(billingInfoID),
    ...options,
  });
}
