import {
  add,
  closestTo,
  differenceInDays,
  differenceInWeeks,
  isBefore,
  min,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
} from "date-fns";
import { format } from "date-fns-tz";
import { uniq } from "lodash";
import { DurationType, Operator, TimeGranularity } from "../constants/enums";
import { Properties } from "./CubeQuery";
import { Properties as DataQueryProperties } from "./DataQuery";
import { QueryFilter, RawData } from "./types";

export type FiscalPeriodMap = { [key: string]: string };

type DateRange = Date[];

type FiscalPeriod = {
  fiscalMonth: number;
  fiscalQuarter: number;
  fiscalWeek: number;
  fiscalYear: number;
};

const monthStartOfQuarter: { [quarter: number]: number } = {
  1: 1,
  2: 4,
  3: 7,
  4: 10,
};

export const fiscalGranularities: TimeGranularity[] = [
  TimeGranularity.MONTH,
  TimeGranularity.QUARTER,
  TimeGranularity.WEEK,
  TimeGranularity.YEAR,
];

// To Analytics: Configure params to send
export function configureFiscalDateParams(
  params: DataQueryProperties
): DataQueryProperties {
  const fiscalPeriodMap = params.fiscalPeriodMap as FiscalPeriodMap;

  const output = {
    ...params,
    dimensions: params.dimensions ? [...params.dimensions] : [],
    preAggFilters: params.preAggFilters ? [...params.preAggFilters] : [],
  };

  //
  // Phase 1
  //

  const currentPeriod = getCurrentFiscalPeriod(
    params.durationType,
    fiscalPeriodMap,
    params.isComparisonMode ?? false
  );

  //
  // Phase 2
  //

  const additionalFilters =
    params.durationType && params.durationType !== DurationType.CUSTOM
      ? getFiltersForFiscalPeriod(
          currentPeriod,
          fiscalPeriodMap,
          params.durationType
        )
      : [];

  const dateRange =
    params.durationType && params.durationType !== DurationType.CUSTOM
      ? getDateRangeFromFiscalPeriod(
          currentPeriod,
          params.durationType,
          fiscalPeriodMap,
          params.isComparisonMode ?? false
        )
      : params.dateRange;

  if (dateRange) {
    output.dateRange = dateRange;
  }

  if (output.preAggFilters) {
    output.preAggFilters = [...output.preAggFilters, ...additionalFilters];
  } else {
    output.preAggFilters = additionalFilters;
  }

  //
  // Phase 3
  //

  if (!params.granularity) {
    return output;
  }

  if (!output.dimensions) {
    output.dimensions = [];
  }

  // Only Some Granularities are Fiscal Dimensions
  if (fiscalGranularities.includes(params.granularity)) {
    switch (params.granularity) {
      case TimeGranularity.MONTH:
        output.dimensions.push("fiscalMonth");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.QUARTER:
        output.dimensions.push("fiscalQuarter");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.WEEK:
        output.dimensions.push("fiscalWeek");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.YEAR:
        output.dimensions.push("fiscalYear");
        break;
    }

    delete output.granularity;
  }

  output.dimensions = uniq(output.dimensions);

  return output;
}

// TODO: Delete this when cubeshim is removed.
// To Analytics: Configure params to send
export function configureFiscalDateCubeParams(params: Properties): Properties {
  const fiscalPeriodMap = params.fiscalPeriodMap as FiscalPeriodMap;

  const output = {
    ...params,
    dimensions: params.dimensions ? [...params.dimensions] : [],
    queryFilters: params.queryFilters ? [...params.queryFilters] : [],
  };

  //
  // Phase 1
  //

  const currentPeriod = getCurrentFiscalPeriod(
    params.durationType,
    fiscalPeriodMap,
    params.isComparisonMode ?? false
  );

  //
  // Phase 2
  //

  const additionalFilters =
    params.durationType && params.durationType !== DurationType.CUSTOM
      ? getFiltersForFiscalPeriod(
          currentPeriod,
          fiscalPeriodMap,
          params.durationType
        )
      : [];

  const dateRange =
    params.durationType && params.durationType !== DurationType.CUSTOM
      ? getDateRangeFromFiscalPeriod(
          currentPeriod,
          params.durationType,
          fiscalPeriodMap,
          params.isComparisonMode ?? false
        )
      : params.dateRange;

  if (dateRange) {
    output.dateRange = dateRange;
  }

  if (output.queryFilters) {
    output.queryFilters = [...output.queryFilters, ...additionalFilters];
  } else {
    output.queryFilters = additionalFilters;
  }

  //
  // Phase 3
  //

  if (!params.granularity) {
    return output;
  }

  if (!output.dimensions) {
    output.dimensions = [];
  }

  // Only Some Granularities are Fiscal Dimensions
  if (fiscalGranularities.includes(params.granularity)) {
    switch (params.granularity) {
      case TimeGranularity.MONTH:
        output.dimensions.push("fiscalMonth");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.QUARTER:
        output.dimensions.push("fiscalQuarter");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.WEEK:
        output.dimensions.push("fiscalWeek");
        output.dimensions.push("fiscalYear");
        break;
      case TimeGranularity.YEAR:
        output.dimensions.push("fiscalYear");
        break;
    }

    delete output.granularity;
  }

  output.dimensions = uniq(output.dimensions);

  return output;
}

// FiscalPeriodMap Format: {...  M01-Q1-Y2022: "2021-10-01", ...}
function getCurrentFiscalPeriod(
  durationType: DurationType | undefined,
  fiscalPeriodMap: FiscalPeriodMap,
  isComparisonMode: boolean
): FiscalPeriod {
  let today = new Date();

  if (isComparisonMode) {
    switch (durationType) {
      case DurationType.YESTERDAY: {
        today = sub(today, { days: 1 });
        break;
      }
      case DurationType.LAST_SEVEN_DAYS: {
        today = sub(today, { days: 8 });
        break;
      }
      case DurationType.MONTH_TO_DATE:
      case DurationType.LAST_THIRTY_DAYS: {
        today = sub(today, { days: 31 });
        break;
      }
      case DurationType.LAST_NINETY_DAYS: {
        today = sub(today, { days: 91 });
        break;
      }
      case DurationType.YEAR_TO_DATE: {
        today = sub(today, { days: 365 });
        break;
      }
    }
  }

  const fiscalPeriod: FiscalPeriod = {
    fiscalMonth: 0,
    fiscalQuarter: 0,
    fiscalWeek: 0,
    fiscalYear: 0,
  };

  // Filter out periods that haven't happened yet
  fiscalPeriodMap = Object.entries(fiscalPeriodMap).reduce(
    (accum, [key, value]) => {
      const date = new Date(value);

      if (date > today) return accum;

      return { ...accum, [key]: value };
    },
    {}
  );

  const fiscalKeys = Object.keys(fiscalPeriodMap);

  const dates = fiscalKeys.map((key) =>
    add(new Date(fiscalPeriodMap[key]), { days: 1 })
  );

  const closestDate = closestTo(today, dates);

  if (!closestDate) return fiscalPeriod;

  const dateKey = format(closestDate, "yyyy-MM-dd");

  const matchingPeriodKey = fiscalKeys.find(
    (fiscalKey) => fiscalPeriodMap[fiscalKey] === dateKey
  );

  if (!matchingPeriodKey) return fiscalPeriod;
  // Maybe this PR: Report this to Sentry (if not noisy)
  // Future: Show a toast message

  fiscalPeriod.fiscalMonth = Number(matchingPeriodKey.slice(1, 3));
  fiscalPeriod.fiscalQuarter = Number(matchingPeriodKey.slice(5, 6));
  fiscalPeriod.fiscalYear = Number(matchingPeriodKey.slice(8));

  // NOTE: Weeks derived from year
  const startOfCurrentFiscalYear =
    fiscalPeriodMap[`M01-Q1-Y${fiscalPeriod.fiscalYear}`];
  const days = differenceInDays(today, new Date(startOfCurrentFiscalYear));
  const week = Math.ceil(days / 7);
  fiscalPeriod.fiscalWeek = week;

  return fiscalPeriod;
}

function getFiltersForFiscalPeriod(
  currentPeriod: FiscalPeriod,
  fiscalPeriodMap: FiscalPeriodMap | null | undefined,
  durationType?: DurationType
): QueryFilter[] {
  const filters: QueryFilter[] = [];

  if (!fiscalPeriodMap) return filters;

  switch (durationType) {
    case DurationType.LAST_NINETY_DAYS:
      filters.push({
        name: "fiscalQuarter",
        operator: Operator.EQUALS,
        values: [
          subtractOneFromFiscalUnit(
            currentPeriod.fiscalQuarter,
            "quarter"
          ).toString(),
        ],
      });
      break;
    case DurationType.LAST_SEVEN_DAYS:
      filters.push({
        name: "fiscalWeek",
        operator: Operator.EQUALS,
        values: [
          subtractOneFromFiscalUnit(
            currentPeriod.fiscalWeek,
            "week"
          ).toString(),
        ],
      });
      break;
    case DurationType.LAST_THIRTY_DAYS: // Prev Month (fiscal)
      filters.push({
        name: "fiscalMonth",
        operator: Operator.EQUALS,
        values: [
          subtractOneFromFiscalUnit(
            currentPeriod.fiscalMonth,
            "month"
          ).toString(),
        ],
      });
      break;
    case DurationType.MONTH_TO_DATE: // Current Month
      filters.push({
        name: "fiscalMonth",
        operator: Operator.EQUALS,
        values: [currentPeriod.fiscalMonth.toString()],
      });
      break;
    case DurationType.QUARTER_TO_DATE:
      filters.push({
        name: "fiscalQuarter",
        operator: Operator.EQUALS,
        values: [currentPeriod.fiscalQuarter.toString()],
      });
      break;
    case DurationType.YEAR_TO_DATE:
      filters.push({
        name: "fiscalYear",
        operator: Operator.EQUALS,
        values: [currentPeriod.fiscalYear.toString()],
      });
      break;
  }

  return filters;

  function subtractOneFromFiscalUnit(
    currentNumber: number,
    unit: "week" | "month" | "quarter" | "year"
  ) {
    if (!fiscalPeriodMap) return 0;

    if (currentNumber !== 1 || unit === "year") {
      return currentNumber - 1;
    }

    // Mac said this was a safe assumption
    if (unit === "month") {
      return 12;
    }

    if (unit === "quarter") {
      return 4;
    }

    if (unit === "week") {
      const thisYearStart =
        fiscalPeriodMap[`M01-Q1-Y${currentPeriod.fiscalYear}`];
      const lastYearStart =
        fiscalPeriodMap[`M01-Q1-Y${currentPeriod.fiscalYear - 1}`];

      if (!lastYearStart || !thisYearStart) return 0;

      const weeks = differenceInWeeks(
        new Date(thisYearStart),
        new Date(lastYearStart)
      );

      return Math.ceil(weeks);
    }

    return 0;
  }
}

function getDateRangeFromFiscalPeriod(
  currentFiscalPeriod: FiscalPeriod,
  durationType: DurationType | undefined,
  fiscalPeriodMap: FiscalPeriodMap | null | undefined,
  isComparisonMode: boolean
): Date[] | undefined {
  let today = new Date();

  // All zero values for current period indicates that the user is not currently on the fiscal calendar
  const periodSum = Object.values(currentFiscalPeriod).reduce(
    (accum, num) => accum + num,
    0
  );
  if (periodSum === 0) {
    return [today, today];
  }

  let key: string;
  let dateRange: DateRange = [];

  if (!fiscalPeriodMap) return dateRange;

  switch (durationType) {
    // If YTD, look for M01 Q1
    case DurationType.YEAR_TO_DATE: {
      if (isComparisonMode) {
        const days = differenceInDays(startOfYear(today), today);
        today = sub(today, { days: days });
      }

      key = `M01-Q1-Y${currentFiscalPeriod.fiscalYear}`;
      const startDate = sub(new Date(fiscalPeriodMap[key]), { days: 2 });
      dateRange = [startDate, today];

      break;
    }

    // MTD
    case DurationType.MONTH_TO_DATE: {
      if (isComparisonMode) {
        const days = differenceInDays(startOfMonth(today), today);
        today = sub(today, { days: days });
      }

      const monthString =
        currentFiscalPeriod.fiscalMonth < 10
          ? `0${currentFiscalPeriod.fiscalMonth}`
          : currentFiscalPeriod.fiscalMonth;
      key = `M${monthString}-Q${currentFiscalPeriod.fiscalQuarter}-Y${currentFiscalPeriod.fiscalYear}`;
      const startDate = sub(new Date(fiscalPeriodMap[key]), { days: 2 });
      dateRange = [startDate, today];

      break;
    }

    // If QTD, look for M at start of quarter
    case DurationType.QUARTER_TO_DATE: {
      if (isComparisonMode) {
        const days = differenceInDays(startOfQuarter(today), today);
        today = sub(today, { days: days });
      }

      const startOfQuarterMonth =
        monthStartOfQuarter[currentFiscalPeriod.fiscalQuarter];

      const startOfQuarterFiscalPeriod = {
        ...currentFiscalPeriod,
        fiscalMonth: startOfQuarterMonth,
      };

      key = getFiscalPeriodKeyFromFiscalPeriod(startOfQuarterFiscalPeriod);
      const startDate = sub(new Date(fiscalPeriodMap[key]), { days: 2 });
      dateRange = [startDate, today];

      break;
    }

    // Last Month
    case DurationType.LAST_THIRTY_DAYS: {
      if (isComparisonMode) {
        today = sub(today, { days: 31 });
      }

      const startDateString =
        fiscalPeriodMap[
          getStartingPeriodStringsOfCurrentPeriodMinusOne(
            currentFiscalPeriod,
            "month"
          )
        ];
      // End date is start of this fiscal month
      const endDateString =
        fiscalPeriodMap[
          getFiscalPeriodKeyFromFiscalPeriod(currentFiscalPeriod)
        ];

      const startDate = sub(new Date(startDateString), { days: 2 });
      const endDate = add(new Date(endDateString), { days: 2 });
      dateRange = [startDate, endDate];

      break;
    }

    // Last Quarter
    case DurationType.LAST_NINETY_DAYS: {
      if (isComparisonMode) {
        today = sub(today, { days: 91 });
      }

      const startDateString =
        fiscalPeriodMap[
          getStartingPeriodStringsOfCurrentPeriodMinusOne(
            currentFiscalPeriod,
            "quarter"
          )
        ];
      const startDate = sub(new Date(startDateString), { days: 2 });

      const startOfCurrentQuarter = { ...currentFiscalPeriod };
      startOfCurrentQuarter.fiscalMonth =
        monthStartOfQuarter[currentFiscalPeriod.fiscalQuarter];
      const endDateString =
        fiscalPeriodMap[
          getFiscalPeriodKeyFromFiscalPeriod(startOfCurrentQuarter)
        ];

      // What was the key for the start of the current quarter -> the same key as today, but with the month changing to 1, 4, 7, 11
      const endDate = add(new Date(endDateString), { days: 2 });
      dateRange = [startDate, endDate];

      break;
    }

    // Last Week
    case DurationType.LAST_SEVEN_DAYS: {
      if (isComparisonMode) {
        today = sub(today, { days: 8 });
      }
      const startDate = sub(today, { days: 15 });
      const endDate = add(today, { days: 1 });

      dateRange = [startDate, endDate];

      break;
    }

    default:
      break;
  }

  if (dateRange.length === 0) {
    return;
  }

  return dateRange;
}

export function getFiscalLastMTDDateRange(
  fiscalPeriodMap: FiscalPeriodMap
): [Date, Date] | null {
  const today = new Date();

  const currentPeriod = getCurrentFiscalPeriod(
    undefined,
    fiscalPeriodMap,
    false
  );

  const startOfCurrentMonthDateString =
    fiscalPeriodMap[getFiscalPeriodKeyFromFiscalPeriod(currentPeriod)];
  const startOfPrevMonthDateString =
    fiscalPeriodMap[
      getStartingPeriodStringsOfCurrentPeriodMinusOne(currentPeriod, "month")
    ];

  if (!startOfCurrentMonthDateString || !startOfPrevMonthDateString) {
    return null;
  }

  const startOfCurrentMonth = new Date(startOfCurrentMonthDateString);
  const startOfPrevMonth = new Date(startOfPrevMonthDateString);

  const daysIntoCurrentFiscalMonth = differenceInDays(
    today,
    startOfCurrentMonth
  );
  let nDaysIntoPrevMonth = add(startOfPrevMonth, {
    days: daysIntoCurrentFiscalMonth,
  });

  // if (prev fiscal month).numOfDays < daysIntoCurrentFiscalMonth
  nDaysIntoPrevMonth = min([
    nDaysIntoPrevMonth,
    sub(startOfCurrentMonth, { days: 1 }),
  ]);

  return [startOfPrevMonth, nDaysIntoPrevMonth];
}

function getStartingPeriodStringsOfCurrentPeriodMinusOne(
  currentFiscalPeriod: FiscalPeriod,
  unit: "month" | "quarter" | "year"
): string {
  const output = { ...currentFiscalPeriod };

  // If we go back to prev month, and we get a 12, we need to subtract 1 from year
  if (unit === "month") {
    output.fiscalMonth--;

    if (output.fiscalMonth === 0) {
      output.fiscalMonth = 12;
      output.fiscalYear--;
    }

    // get quarter from month
    output.fiscalQuarter = Math.ceil(output.fiscalMonth / 3);
  }

  // If we go back to prev quarter, and we get a 4, we need to subtract 1 from year
  if (unit === "quarter") {
    output.fiscalQuarter--;

    if (output.fiscalQuarter === 0) {
      output.fiscalQuarter = 4;
      output.fiscalYear--;
    }

    // get first month in quarter
    output.fiscalMonth = (output.fiscalQuarter - 1) * 3 + 1;
  }

  if (unit === "year") {
    output.fiscalMonth = 1;
    output.fiscalQuarter = 1;
    output.fiscalYear = currentFiscalPeriod.fiscalYear - 1;
  }

  return getFiscalPeriodKeyFromFiscalPeriod(output);
}

function getFiscalPeriodKeyFromFiscalPeriod(period: FiscalPeriod): string {
  const monthString =
    period.fiscalMonth < 10 ? `0${period.fiscalMonth}` : period.fiscalMonth;

  return `M${monthString}-Q${period.fiscalQuarter}-Y${period.fiscalYear}`;
}

// From Analytics: Create result data
export function createFiscalDates(
  result: RawData[],
  granularity: TimeGranularity
): RawData[] {
  let sortedResult: RawData[] = [];

  if (fiscalGranularities.includes(granularity)) {
    sortedResult = result.sort((a, b) => {
      let aValue = "";
      let bValue = "";

      if (a.fiscalYear && b.fiscalYear) {
        aValue += String(a.fiscalYear);
        bValue += String(b.fiscalYear);
      }
      if (a.fiscalQuarter && b.fiscalQuarter) {
        aValue += String(a.fiscalQuarter);
        bValue += String(b.fiscalQuarter);
      }
      if (a.fiscalMonth && b.fiscalMonth) {
        aValue += String(a.fiscalMonth);
        bValue += String(b.fiscalMonth);
      }
      if (a.fiscalWeek && b.fiscalWeek) {
        aValue += String(a.fiscalWeek);
        bValue += String(b.fiscalWeek);
      }

      return Number(aValue) - Number(bValue);
    });
  } else {
    sortedResult = result.sort((a, b) => {
      if (typeof a.timestamp !== "string" || typeof b.timestamp !== "string")
        return 0;

      if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
        return -1;
      }

      if (isBefore(new Date(b.timestamp), new Date(a.timestamp))) {
        return 1;
      }

      return 0;
    });
  }

  const output: RawData[] = sortedResult.map((datum): RawData => {
    const resultingDatum: RawData = { ...datum };
    if (fiscalGranularities.includes(granularity)) {
      switch (granularity) {
        case TimeGranularity.MONTH:
          // FY2021-M11
          resultingDatum.timestamp = `FY${datum.fiscalYear}-M${datum.fiscalMonth}`;
          break;
        case TimeGranularity.QUARTER:
          // FY2021-Q2
          resultingDatum.timestamp = `FY${datum.fiscalYear}-Q${datum.fiscalQuarter}`;
          break;
        case TimeGranularity.WEEK:
          // FY2021-W35
          resultingDatum.timestamp = `FY${datum.fiscalYear}-W${datum.fiscalWeek}`;
          break;
        case TimeGranularity.YEAR:
          // FY2022
          resultingDatum.timestamp = `FY${datum.fiscalYear}`;
          break;
      }

      delete resultingDatum.fiscalMonth;
      delete resultingDatum.fiscalQuarter;
      delete resultingDatum.fiscalWeek;
      delete resultingDatum.fiscalYear;
    }

    return resultingDatum;
  });

  return output;
}
