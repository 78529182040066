import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { CreateTenantParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateTenantParams
>;

type Result = UseMutationResult<string, CoreAPIClientError, CreateTenantParams>;

export default function useCreateTenant(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.createTenant(params),
    ...options,
  });
}
