import Modal from "@/ui-lib/components/Modal";
import IconExclamationDiamond from "@/ui-lib/icons/IconExclamationDiamond";
import { useTheme } from "@emotion/react";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { DataIntegrationEntity } from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React from "react";
import copyText from "../copyText";

interface Props {
  integrations: DataIntegrationEntity[];
  onClose: () => void;
  onInteraction: (interaction: ValidationErrorModal.Interaction) => void;
}

export function ValidationErrorModal(props: Props): JSX.Element | null {
  const theme = useTheme();

  return (
    <Modal isOpen showCloseButton onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h3">{copyText.validationErrorsHeader}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box
          height="400px"
          width="800px"
          overflow="auto"
          paddingHorizontal={theme.space_sm}
        >
          <Text marginBottom={theme.space_md}>
            {copyText.validationErrorsHelperText}
          </Text>
          {props.integrations.map((integration) => {
            const unsuccessfulValidations = integration.validations.filter(
              (validation) => !validation.success
            );
            const integrationName = copyText.validationErrorsCloudText.replace(
              "%name%",
              integration.name
            );
            const errorCountText = copyText.validationErrorsCountText.replace(
              "%count%",
              unsuccessfulValidations.length.toString()
            );
            return (
              <Flex
                direction="column"
                key={integration.id}
                marginBottom={theme.space_lg}
              >
                <Flex alignItems="center">
                  <Text appearance="h4" marginRight={theme.space_xxs}>
                    {integrationName}
                  </Text>
                  <Tooltip content={copyText.validationErrorsTooltip}>
                    <Button
                      iconStart={<IconExclamationDiamond />}
                      size="tiny"
                      onClick={() =>
                        props.onInteraction({
                          type: ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED,
                          integrationID: integration.id,
                          providerType: integration.providerType,
                        })
                      }
                    />
                  </Tooltip>
                </Flex>
                <Text>{errorCountText}</Text>
                {unsuccessfulValidations.map((validation) => (
                  <Flex direction="column" key={validation.name}>
                    <Text bold>{validation.name}</Text>
                    {validation.error.length > 0 && (
                      <Text marginLeft={theme.space_md}>
                        {copyText.validationErrorText}
                        {validation.error}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Flex>
            );
          })}
        </Box>
      </Modal.Body>
    </Modal>
  );
}

ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED =
  `ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED` as const;

interface InteractionConfigureButtonClicked {
  type: typeof ValidationErrorModal.INTERACTION_CONFIGURE_BUTTON_CLICKED;
  integrationID: string;
  providerType: CloudProviderType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ValidationErrorModal {
  export type Interaction = InteractionConfigureButtonClicked;
}

export default ValidationErrorModal;
