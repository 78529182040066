export const cloudSpannerDimensions = {
  projectID: "projectId",
  region: "region",
  instanceConfig: "instanceConfig",
  instanceID: "instanceId",
} as const;

export const cloudSpannerInstanceMeasures = {
  avgCpuUtilization: "avgCpuUtilization",
  backupUsedB: "backupUsedB",
  maxCpuUtilization: "maxCpuUtilization",
  storageLimitB: "storageLimitB",
  storageUsedB: "storageUsedB",
  processingUnits: "processingUnits",
  totalBackupGibHour: "totalBackupGibHour",
  totalComputeHours: "totalComputeHours",
  totalEgressGib: "totalEgressGib",
  totalStorageGibHour: "totalStorageGibHour",
} as const;

export const cloudSpannerResourceMeasures = {
  cost: "cost",
  totalBackupCost: "totalBackupCost",
  totalComputeCost: "totalComputeCost",
  totalDataBoostComputeCost: "totalDataBoostComputeCost",
  totalEgressCost: "totalEgressCost",
  totalStorageCost: "totalStorageCost",
} as const;
