import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { startOfMonth } from "date-fns";
import UError from "unilib-error";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { DatabaseSpendSummaryEntity } from "../types";
import { DatabaseSpendSummaryStruct } from "./schema";

export interface Params {
  dataSource:
    | typeof DataSource.AWS_DATABASE_ELASTICACHE
    | typeof DataSource.AWS_DATABASE_MEMORY_DB
    | typeof DataSource.AWS_DATABASE_VISIBILITY
    | typeof DataSource.AZURE_SQL_VISIBILITY
    | typeof DataSource.CLOUD_SQL_COST;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [DatabaseSpendSummaryEntity, UError],
  [DatabaseSpendSummaryEntity, UError],
  [DatabaseSpendSummaryEntity, UError],
];

export default function useGetDatabaseSpendSummaries(
  params: Params,
  options?: UseQueryOptions<DatabaseSpendSummaryEntity, UError>
): UseQueryResult<DatabaseSpendSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();
  const gatekeeper = useGatekeeper();

  // For now the only two possible datasources are AWS & GCP. We need to gate this
  // query depending on which one gets passed in.
  let enabled = gatekeeper.hasGCPIntegration;
  if (params?.dataSource === DataSource.AZURE_SQL_VISIBILITY) {
    enabled = gatekeeper.hasAzureIntegration;
  } else if (params?.dataSource === DataSource.AWS_DATABASE_VISIBILITY) {
    enabled = gatekeeper.hasAWSIntegration;
  }
  const measures = ["cost"];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
              measures,
            })
          );

          const [error, data] = validate(result[0], DatabaseSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_DATABASE_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["databaseSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(result[0], DatabaseSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_DATABASE__COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["databaseSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
      // LAST MONTH TO DATE
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [
                startOfMonth(dateHelper.sameDayLastMonth()),
                dateHelper.sameDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(result[0], DatabaseSpendSummaryStruct);

          if (error) {
            throw new UError("INVALID_DATABASE_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCost: data.cost ?? 0,
          };
        },
        queryKey: ["databaseSpendSummary", "lastMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        enabled,
        ...options,
      },
    ],
  });
}
