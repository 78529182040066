import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { userGroupConfigID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { userGroupConfigID: string }
>;

export default function useDeleteUserGroupConfig(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ userGroupConfigID }) =>
      client.deleteUserGroupConfig(userGroupConfigID),
    ...options,
  });
}
