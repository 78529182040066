import styled from "@emotion/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React, { SVGProps } from "react";

interface FAStyleProps extends FontAwesomeIconProps {
  backgroundColorOnHover?: string;
  clickable?: boolean;
  draggable?: boolean;
}

interface IconStyleProps extends SVGProps<SVGSVGElement> {
  backgroundColorOnHover?: string;
  clickable?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  size?: number | string;
}

const FAIcon = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) =>
    prop !== "clickable" && prop !== "backgroundColorOnHover",
})(({ backgroundColorOnHover, clickable, color, draggable }: FAStyleProps) => ({
  color,
  cursor: (() => {
    if (clickable) return "pointer";
    if (draggable) return "grab";
    return "default";
  })(),

  "&:hover": {
    ...(backgroundColorOnHover && {
      color: backgroundColorOnHover,
    }),
  },
}));

const _Icon = styled("svg")<IconStyleProps>(
  ({
    backgroundColorOnHover,
    clickable,
    color,
    disabled,
    draggable,
    size,
  }) => ({
    color,
    cursor: (() => {
      if (disabled) return "default";
      if (clickable) return "pointer";
      if (draggable) return "grab";
      return "default";
    })(),
    fill: "currentcolor",
    ...(size && { height: size, width: size }),

    "&:hover": {
      ...(backgroundColorOnHover && {
        color: backgroundColorOnHover,
        fill: backgroundColorOnHover,
      }),
    },
  })
);

export type Props = {
  backgroundColorOnHover?: string;
  clickable?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  size?: number | string;
} & (
  | ({ icon: IconProp } & FontAwesomeIconProps)
  | ({ icon?: never } & SVGProps<SVGSVGElement>)
);

export default function Icon(props: Props): JSX.Element {
  if (props.icon) {
    return <FAIcon {...props} />;
  }

  const rootProps = {
    focusable: false,
    role: "img",
    viewBox: "0 0 24 24",
    ...props,
  };

  return <_Icon {...rootProps} />;
}
