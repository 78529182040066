import { useQuery } from "@tanstack/react-query";
import { FindCostAlertsQueryResult } from "@ternary/api-lib/core/queries/FindCostAlertsQuery";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetCostAlertsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<
    FindCostAlertsQueryResult["costAlerts"],
    CoreAPIClientError
  >
): UseQueryResult<FindCostAlertsQueryResult["costAlerts"], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.costAlerts,
    queryFn: () => client.getCostAlertsByTenantID(tenantID),
    enabled: gatekeeper.canListCostAlerts,
    ...options,
  });
}
