import Meter, { getChangePercent } from "@/components/Meter";
import { DateHelper } from "@/lib/dates";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import {
  formatCurrency,
  formatCurrencyRounded,
} from "@ternary/web-ui-lib/utils/formatNumber";
import { getDate, getDaysInMonth } from "date-fns";
import { meanBy } from "lodash";
import React from "react";
import copyText from "../../copyText";

type Recommendation = {
  applicable: boolean;
  estimateValue: number;
  state: string;
};

interface Props {
  isLoading: boolean;
  lastMonthSpend: number;
  lastMTDSpend: number;
  recommendations: Recommendation[];
  thisMTDSpend: number;
}

export default function GCPComputeInstanceMeters(props: Props): JSX.Element {
  const theme = useTheme();
  const now = new DateHelper();

  const mtdSpendComparison = getChangePercent({
    biggerIsBetter: false,
    value: props.thisMTDSpend,
    comparison: props.lastMTDSpend,
    theme,
  });

  const avgDailySpend = props.thisMTDSpend / getDate(now.date);
  const forecastedSpend = avgDailySpend * getDaysInMonth(now.date);

  const forecastedSpendComparison = getChangePercent({
    biggerIsBetter: false,
    comparison: props.lastMonthSpend,
    value: forecastedSpend,
    theme,
  });

  const recommendationStats = getRecommendationStats(props.recommendations);

  return (
    <Flex>
      <Meter
        header={copyText.gcpComputeMeterLastMonthTotalCostLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: props.lastMonthSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={mtdSpendComparison.color}
        footerIcon={mtdSpendComparison.icon}
        footerTitle={copyText.gcpComputeMeterMTDSpendComparisonCaption}
        footerValue={mtdSpendComparison.textValue}
        header={copyText.gcpComputeMeterCurrentMonthTotalCostLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: props.thisMTDSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={forecastedSpendComparison.color}
        footerIcon={forecastedSpendComparison.icon}
        footerTitle={copyText.gcpComputeMeterForecastedSpendComparisonCaption}
        footerValue={forecastedSpendComparison.textValue}
        header={copyText.gcpComputeMeterForecastedSpendLabel}
        loading={props.isLoading}
        value={formatCurrency({ number: forecastedSpend })}
        width="25%"
      />
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Meter
        footerColor={recommendationStats.color}
        footerTitle={copyText.gcpComputeMeterRecommendationsAverageCaption}
        footerValue={recommendationStats.recommendationsAverage}
        header={copyText.gcpComputeMeterRecommendationsLabel}
        loading={props.isLoading}
        value={recommendationStats.recommendationsCount}
        width="25%"
      />
    </Flex>
  );
}

function getRecommendationStats(recommendations: Recommendation[]): {
  color: string;
  recommendationsAverage: string;
  recommendationsCount: string;
} {
  const theme = useTheme();

  const current = recommendations.filter(
    (rec) => (rec.state === "new" || rec.state === "accepted") && rec.applicable
  );

  const recommendationsAverage =
    current.length > 0
      ? formatCurrencyRounded({
          number: meanBy(current, (rec) => rec.estimateValue),
        })
      : "--";

  return {
    color: current.length
      ? theme.backgroundColor_successPrimary_hover
      : theme.text_color_secondary,
    recommendationsAverage,
    recommendationsCount: current.length.toString(),
  };
}
