import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import Tabs from "@/ui-lib/components/Tabs";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import { Validation } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, FormEvent, useState } from "react";
import copyText from "../../copyText";
import { CostAndUsageReportInputs } from "../../types";
import AlibabaIntegrationFormBasic from "./AlibabaIntegrationFormBasic";
import AlibabaIntegrationReportsForm from "./AlibabaIntegrationReportsForm";
import { AlibabaIntegration, CostAndUsageReport } from "./types";

interface Props {
  isLoading: boolean;
  integration?: AlibabaIntegration;
  onInteraction: (interaction: AlibabaIntegrationForm.Interaction) => void;
}

interface State {
  //  S3 Bucket Tab
  costAndUsageReportFields: CostAndUsageReportInputs[];

  // Basic Tab
  nameInput: string;
  roleARNInput: string;

  // Tab State
  tab: string;
}

const initialState: State = {
  // S3 Bucket tab's state
  costAndUsageReportFields: [
    {
      nameInput: "",
      regionInput: "",
      reportPathPrefixInput: "",
      s3BucketNameInput: "",
    },
  ],
  // Basic tab state
  nameInput: "",
  roleARNInput: "",

  tab: "basic",
};

export function AlibabaIntegrationForm(props: Props): JSX.Element {
  const selectedCloud = props.integration;
  const [state, setState] = useState<State>(
    selectedCloud
      ? {
          ...initialState,
          costAndUsageReportFields:
            selectedCloud.config.costAndUsageReports.map((report) => ({
              nameInput: report.name,
              regionInput: report.region,
              reportPathPrefixInput: report.reportPathPrefix,
              s3BucketNameInput: report.s3BucketName,
            })),
          nameInput: selectedCloud.name,
          roleARNInput: selectedCloud.config.roleARN,
        }
      : initialState
  );
  const mergeState = getMergeState(setState);
  const theme = useTheme();

  const containers = [
    {
      component: (
        <AlibabaIntegrationFormBasic
          nameInput={state.nameInput}
          roleARNInput={state.roleARNInput}
          onUpdate={(event: ChangeEvent<HTMLInputElement>) =>
            mergeState({ [`${event.target.name}Input`]: event.target.value })
          }
        />
      ),
      label: copyText.cloudAdminBasicInfo,
      value: "basic",
    },
    {
      component: (
        <AlibabaIntegrationReportsForm
          costAndUsageReportFields={state.costAndUsageReportFields}
          onDelete={(index: number) =>
            setState((currentState) => {
              const costAndUsageReport = [
                ...currentState.costAndUsageReportFields,
              ];
              costAndUsageReport.splice(index, 1);
              return {
                ...currentState,
                costAndUsageReportFields: costAndUsageReport,
              };
            })
          }
          onUpdate={(event: ChangeEvent<HTMLInputElement>, index: number) => {
            const newValue = state.costAndUsageReportFields.map((datum, i2) =>
              index === i2
                ? {
                    ...datum,
                    ...{ [`${event.target.name}Input`]: event.target.value },
                  }
                : datum
            );
            mergeState({ costAndUsageReportFields: newValue });
          }}
        />
      ),
      label: copyText.cloudAlibabaAdminReports,
      value: "adminReports",
    },
  ];

  const newCostReportField: CostAndUsageReportInputs = {
    nameInput: "",
    regionInput: "",
    reportPathPrefixInput: "",
    s3BucketNameInput: "",
  };

  function canSubmit(): boolean {
    return props.integration ? canUpdate() : canCreate();
  }

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    if (props.integration) {
      const costAndUsageReportsState = state.costAndUsageReportFields.map(
        (report) => ({
          name: report.nameInput,
          region: report.regionInput,
          reportPathPrefix: report.reportPathPrefixInput,
          s3BucketName: report.s3BucketNameInput,
        })
      );

      const hasConfigChanges =
        !isEqual(
          costAndUsageReportsState,
          props.integration.config.costAndUsageReports
        ) || state.roleARNInput !== props.integration.config.roleARN;

      props.onInteraction({
        type: AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        ...(hasConfigChanges
          ? {
              config: {
                costAndUsageReports: costAndUsageReportsState,
                roleARN: state.roleARNInput,
              },
            }
          : {}),
        ...(state.nameInput !== props.integration.name
          ? { name: state.nameInput }
          : {}),
      });
    } else {
      props.onInteraction({
        type: AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        costAndUsageReports: state.costAndUsageReportFields.map((report) => ({
          name: report.nameInput,
          region: report.regionInput,
          reportPathPrefix: report.reportPathPrefixInput,
          s3BucketName: report.s3BucketNameInput,
        })),
        roleARN: state.roleARNInput,
        name: state.nameInput,
      });
    }
  }

  function canCreate(): boolean {
    return (
      state.nameInput.trim().length > 0 &&
      state.roleARNInput.trim().length > 0 &&
      isValidReportTable(state.costAndUsageReportFields)
    );
  }

  function canUpdate(): boolean {
    if (!props.integration) return false;

    const currentState = state.costAndUsageReportFields.map((report) => ({
      excludePathPrefixes: [],
      name: report.nameInput,
      region: report.regionInput,
      reportPathPrefix: report.reportPathPrefixInput,
      s3BucketName: report.s3BucketNameInput,
    }));
    const completed =
      state.nameInput.trim().length > 0 &&
      state.roleARNInput.trim().length > 0 &&
      isValidReportTable(state.costAndUsageReportFields);

    if (!props.integration) return completed;

    const hasChangedText =
      state.nameInput.trim() !== props.integration.name ||
      state.roleARNInput.trim() !== props.integration.config.roleARN ||
      !isEqual(currentState, props.integration.config.costAndUsageReports);

    return completed && hasChangedText;
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: AlibabaIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text appearance="h4">
            {props.integration
              ? copyText.cloudAlibabaFormTitleUpdate
              : copyText.cloudAlibabaFormTitleCreate}
          </Text>
          <Flex>
            {state.tab === "adminReports" && (
              <Button
                iconStart={<Icon icon={faPlus} />}
                marginRight={theme.space_sm}
                primary
                size="small"
                type="button"
                onClick={() =>
                  setState((currentState) => ({
                    ...currentState,
                    costAndUsageReportFields: [
                      ...currentState.costAndUsageReportFields,
                      newCostReportField,
                    ],
                  }))
                }
              >
                {copyText.createCostReportButtonLabel}
              </Button>
            )}
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex height={450} minWidth={500} scrollable>
          <Form>
            <Tabs
              activeValue={state.tab}
              tabs={containers}
              onSelect={(tab: string) => mergeState({ tab })}
            />
          </Form>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!canSubmit() || props.isLoading}
          fullWidth
          primary
          onClick={handleSubmit}
        >
          {props.isLoading ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function getErrorMessageIfPresent(
  integration: { validations: Validation[] } | undefined,
  validationName: CloudCapability
): string | undefined {
  if (!integration) return undefined;

  if (integration.validations.length === 0) return "";

  const validation = integration.validations.find(
    (validation) => validation.name === validationName
  );

  if (!validation) return "";

  return validation.success ? undefined : validation.error;
}

export function isValidReportTable(table: CostAndUsageReportInputs[]): boolean {
  const result = table.map((datum) => {
    return (
      datum.nameInput !== "" &&
      datum.reportPathPrefixInput !== "" &&
      datum.s3BucketNameInput !== ""
    );
  });
  return !result.includes(false);
}

AlibabaIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  `AlibabaIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED` as const;

AlibabaIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED =
  `AlibabaIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED` as const;

AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE =
  `AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE` as const;

AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE =
  `AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE` as const;

interface InteractionCancelButtonClicked {
  type: typeof AlibabaIntegrationForm.INTERACTION_CANCEL_BUTTON_CLICKED;
}

interface InteractionRefreshButtonClicked {
  type: typeof AlibabaIntegrationForm.INTERACTION_REFRESH_BUTTON_CLICKED;
  cloudID: string;
}

interface InteractionSubmitButtonClickedCreate {
  type: typeof AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
  costAndUsageReports: CostAndUsageReport[];
  name: string;
  roleARN: string;
}

interface InteractionSubmitButtonClickedUpdate {
  type: typeof AlibabaIntegrationForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
  config?: {
    costAndUsageReports: CostAndUsageReport[];
    roleARN: string;
  };
  name?: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AlibabaIntegrationForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionRefreshButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default AlibabaIntegrationForm;
