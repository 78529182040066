import { DateRange } from "@/utils/dates";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faChevronDown,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import {
  isValidDetailedBillingDate,
  isValidLastNLookback,
} from "@ternary/api-lib/analytics/utils";
import { DataSource, DurationType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { add, endOfDay } from "date-fns";
import React, { useEffect, useState } from "react";
import copyText from "../copyText";
import DatePicker from "./DatePicker";
import Divider from "./Divider";
import Dropdown from "./Dropdown";
import Form, { FormField } from "./Form";
import Modal from "./Modal";
import { LabeledSwitch } from "./Switch";
import TextInput from "./TextInput";
interface Props {
  compareDateRange: DateRange;
  dateRange: DateRange;
  dataSource?: DataSource;
  isComparisonMode: boolean;
  isInvoiceMonth: boolean;
  isOpen: boolean;
  lookbackType?: DurationType;
  maxDate?: Date;
  minDate?: Date;
  n?: number | null;
  showRollingLookback?: boolean;
  onChangeRollingLookback?: (
    durationType: DurationType,
    nLookback: number
  ) => void;
  onChangeDateRange: (
    compareDateRange: DateRange | undefined,
    dateRange: DateRange,
    isInvoiceMonth: boolean
  ) => void;
  onClose: () => void;
}

interface State {
  compareStartDate?: Date;
  compareEndDate?: Date;
  endDate: Date;
  includeCurrent: boolean;
  isRolling: boolean;
  lookbackType: DurationType;
  nInput: string;
  startDate: Date;
}

export default function DatePickerModal(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>({
    compareStartDate: props.compareDateRange[0],
    compareEndDate: props.compareDateRange[1],
    endDate: props.dateRange[1],
    includeCurrent:
      props.lookbackType === DurationType.LAST_N_DAYS_TO_DATE ||
      props.lookbackType === DurationType.LAST_N_MONTHS_TO_DATE,
    isRolling: typeof props.n === "number",
    lookbackType:
      props.lookbackType === DurationType.LAST_N_MONTHS ||
      props.lookbackType === DurationType.LAST_N_MONTHS_TO_DATE
        ? DurationType.LAST_N_MONTHS
        : DurationType.LAST_N_DAYS,
    nInput: typeof props.n === "number" ? String(props.n) : "",
    startDate: props.dateRange[0],
  });

  const mergeState = getMergeState(setState);

  const isWindowMode = props.showRollingLookback && state.isRolling;

  const isDetailedBilling =
    props.dataSource && props.dataSource === DataSource.DETAILED_BILLING;

  useEffect(() => {
    mergeState({
      compareStartDate: props.compareDateRange[0],
      compareEndDate: props.compareDateRange[1],
      endDate: props.dateRange[1],
      startDate: props.dateRange[0],
    });
  }, [props.compareDateRange, props.dateRange]);

  useEffect(() => {
    mergeState({
      nInput: typeof props.n === "number" ? String(props.n) : "",
      includeCurrent:
        props.lookbackType === DurationType.LAST_N_DAYS_TO_DATE ||
        props.lookbackType === DurationType.LAST_N_MONTHS_TO_DATE,
      isRolling: typeof props.n === "number",
      lookbackType:
        props.lookbackType === DurationType.LAST_N_MONTHS ||
        props.lookbackType === DurationType.LAST_N_MONTHS_TO_DATE
          ? DurationType.LAST_N_MONTHS
          : DurationType.LAST_N_DAYS,
    });
  }, [props.n, props.showRollingLookback, props.lookbackType]);

  function handleChangeCustomDateRange() {
    if (isWindowMode) {
      const nLookback: unknown = Number(state.nInput);

      if (!isValidLastNLookback(nLookback) || !props.onChangeRollingLookback) {
        return;
      }

      let lookbackType = state.lookbackType;

      if (state.includeCurrent) {
        if (lookbackType === DurationType.LAST_N_DAYS) {
          lookbackType = DurationType.LAST_N_DAYS_TO_DATE;
        } else if (lookbackType === DurationType.LAST_N_MONTHS) {
          lookbackType = DurationType.LAST_N_MONTHS_TO_DATE;
        }
      }

      props.onChangeRollingLookback(lookbackType, nLookback);
    } else {
      const dateRange = [state.startDate, state.endDate];

      const compareDateRange =
        state.compareEndDate && state.compareStartDate
          ? [state.compareStartDate, state.compareEndDate]
          : undefined;

      props.onChangeDateRange(
        compareDateRange,
        dateRange,
        props.isInvoiceMonth
      );
    }

    props.onClose();
  }

  function handleChangeDate(
    date: Date | [Date | null, Date | null] | null,
    key: string
  ): void {
    if (!date) return;

    if (isDetailedBilling && key === "startDate" && date instanceof Date) {
      const checkDifference = isValidDetailedBillingDate(date, state.endDate);

      if (!checkDifference) {
        let next30 = new Date(date);
        next30 = add(next30, { days: 30 });
        mergeState({ startDate: date, endDate: next30 });
      }
    }

    mergeState({ [key]: date });
  }

  function handleChangeLookbackType(lookbackType: string): void {
    if (
      lookbackType !== DurationType.LAST_N_DAYS &&
      lookbackType !== DurationType.LAST_N_MONTHS
    )
      return;
    mergeState({ lookbackType });
  }

  function handleChangeIncludeCurrent(includeCurrent: boolean): void {
    mergeState({ includeCurrent });
  }

  function canSave() {
    if (isWindowMode) {
      return isValidLastNLookback(Number(state.nInput));
    }

    if (isDetailedBilling) {
      return isValidDetailedBillingDate(state.startDate, state.endDate);
    }

    return (
      state.startDate instanceof Date &&
      typeof state.startDate.getMonth === "function" &&
      state.endDate instanceof Date &&
      typeof state.endDate.getMonth === "function"
    );
  }

  const lookbackOptions = [
    {
      label: copyText.datePickerModalDays,
      value: DurationType.LAST_N_DAYS,
      onClick: handleChangeLookbackType,
    },
    {
      label: copyText.datePickerModalMonths,
      value: DurationType.LAST_N_MONTHS,
      onClick: handleChangeLookbackType,
    },
  ];

  const selectedLookbackOption = lookbackOptions.find(
    (option) => option.value === state.lookbackType
  );

  let next30FromStartDate = new Date(state.startDate);
  next30FromStartDate = add(next30FromStartDate, { days: 31 });

  function renderFixedRangePicker() {
    return (
      <>
        <Flex>
          <Box marginRight={theme.space_lg} flex="0 1 100%">
            <FormField label={copyText.datePickerModalStartLabel}>
              <DatePicker
                dateFormat={props.isInvoiceMonth ? "MM/yyyy" : undefined}
                maxDate={props.maxDate}
                minDate={props.minDate}
                selected={state.startDate}
                showMonthYearPicker={props.isInvoiceMonth}
                onChange={(date) => handleChangeDate(date, "startDate")}
                onClick={() => mergeState({ isRolling: false })}
              />
            </FormField>
          </Box>
          <Box flex="0 1 100%">
            <FormField label={copyText.datePickerModalEndLabel}>
              <DatePicker
                dateFormat={props.isInvoiceMonth ? "MM/yyyy" : undefined}
                maxDate={
                  isDetailedBilling ? next30FromStartDate : props.maxDate
                }
                minDate={isDetailedBilling ? state.startDate : props.minDate}
                selected={state.endDate}
                showMonthYearPicker={props.isInvoiceMonth}
                onChange={(date) =>
                  handleChangeDate(date ? endOfDay(date) : null, "endDate")
                }
                onClick={() => mergeState({ isRolling: false })}
              />
            </FormField>
          </Box>
        </Flex>
        {props.isComparisonMode && props.isInvoiceMonth && (
          <>
            <Text
              cursor={isWindowMode ? "pointer" : "default"}
              fontSize={theme.h4_fontSize}
              fontWeight={theme.h4_fontWeight}
              marginBottom={theme.space_md}
            >
              {copyText.datePickerModalComparision}
            </Text>

            <Flex marginLeft={theme.space_lg}>
              {renderCompareRangePicker()}
            </Flex>
          </>
        )}
      </>
    );
  }

  function renderCompareRangePicker() {
    return (
      <Flex>
        <Box marginRight={theme.space_lg} flex="0 1 100%">
          <FormField label={copyText.datePickerModalStartLabel}>
            <DatePicker
              dateFormat={props.isInvoiceMonth ? "MM/yyyy" : undefined}
              maxDate={props.maxDate}
              minDate={props.minDate}
              selected={state.compareStartDate}
              showMonthYearPicker={props.isInvoiceMonth}
              onChange={(date) => handleChangeDate(date, "compareStartDate")}
              onClick={() => mergeState({ isRolling: false })}
            />
          </FormField>
        </Box>
        <Box flex="0 1 100%">
          <FormField label={copyText.datePickerModalEndLabel}>
            <DatePicker
              dateFormat={props.isInvoiceMonth ? "MM/yyyy" : undefined}
              maxDate={props.maxDate}
              minDate={props.minDate}
              selected={state.compareEndDate}
              showMonthYearPicker={props.isInvoiceMonth}
              onChange={(date) =>
                handleChangeDate(date ? endOfDay(date) : null, "compareEndDate")
              }
              onClick={() => mergeState({ isRolling: false })}
            />
          </FormField>
        </Box>
      </Flex>
    );
  }

  function renderNDaysPicker() {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text>{copyText.datePickerModalLast}</Text>
          <Box marginHorizontal={theme.space_xs} width={60}>
            <TextInput
              size="small"
              type="number"
              min="0"
              value={state.nInput}
              onChange={(e) => {
                if (isDetailedBilling) {
                  const maxInput = +e.currentTarget.value;
                  if (maxInput <= 31) {
                    mergeState({ nInput: e.currentTarget.value });
                  }
                } else {
                  mergeState({ nInput: e.currentTarget.value });
                }
              }}
              onClick={() => mergeState({ isRolling: true })}
            />
          </Box>
          <Tooltip
            hide={props.dataSource !== DataSource.DETAILED_BILLING}
            content={copyText.disableDateControlTooltipCaption}
          >
            <Dropdown
              disabled={isDetailedBilling}
              selectedOption={selectedLookbackOption}
              options={lookbackOptions}
              placement="bottom-start"
              onOpen={() => mergeState({ isRolling: true })}
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="tiny"
                type="button"
              >
                {selectedLookbackOption?.label}
              </Button>
            </Dropdown>
          </Tooltip>
        </Flex>
        <Box
          marginHorizontal={theme.space_xs}
          onClick={() => mergeState({ isRolling: true })}
        >
          <LabeledSwitch
            checked={state.includeCurrent}
            label={copyText.datePickerModalIncludeCurrentLabel}
            onChange={handleChangeIncludeCurrent}
          />
        </Box>
      </Flex>
    );
  }

  function renderFixedAndWindowPickers() {
    return (
      <>
        {/* FIXED */}
        <Box opacity={isWindowMode ? 0.6 : 1}>
          <Flex marginBottom={theme.space_md} alignItems="center">
            <Box marginRight={theme.space_sm}>
              <Icon
                clickable
                color={
                  isWindowMode
                    ? theme.text_color_disabled
                    : theme.primary_color_text
                }
                fontSize={theme.h4_fontSize}
                icon={isWindowMode ? faToggleOff : faToggleOn}
                onClick={() => mergeState({ isRolling: !state.isRolling })}
              />
            </Box>
            <Text
              cursor={isWindowMode ? "pointer" : "default"}
              fontSize={theme.h4_fontSize}
              fontWeight={theme.h4_fontWeight}
            >
              {copyText.datePickerModalFixed}
            </Text>
          </Flex>
          <Flex marginLeft={theme.space_lg}>{renderFixedRangePicker()}</Flex>
          {props.isComparisonMode && (
            <>
              <Text
                cursor={isWindowMode ? "pointer" : "default"}
                fontSize={theme.h4_fontSize}
                fontWeight={theme.h4_fontWeight}
                marginBottom={theme.space_md}
              >
                {copyText.datePickerModalComparision}
              </Text>

              <Flex marginLeft={theme.space_lg}>
                {renderCompareRangePicker()}
              </Flex>
            </>
          )}
        </Box>

        {/* DIVIDER */}
        <Flex alignItems="center">
          <Box flex="1 0 0">
            <Divider />
          </Box>
          <Box margin={theme.space_sm}>
            <Text>{copyText.datePickerModalOr}</Text>
          </Box>
          <Box flex="1 0 0">
            <Divider />
          </Box>
        </Flex>

        {/* WINDOW */}
        <Box opacity={isWindowMode ? 1 : 0.6}>
          <Flex marginBottom={theme.space_md} alignItems="center">
            <Box marginRight={theme.space_sm}>
              <Icon
                clickable
                color={
                  isWindowMode
                    ? theme.primary_color_text
                    : theme.text_color_disabled
                }
                fontSize={theme.h4_fontSize}
                icon={isWindowMode ? faToggleOn : faToggleOff}
                onClick={() => mergeState({ isRolling: !state.isRolling })}
              />
            </Box>
            <Text
              cursor={isWindowMode ? "default" : "pointer"}
              fontSize={theme.h4_fontSize}
              fontWeight={theme.h4_fontWeight}
              onClick={() => mergeState({ isRolling: true })}
            >
              {copyText.datePickerModalRolling}
            </Text>
          </Flex>
          <Box
            marginBottom={theme.space_lg}
            marginLeft={theme.space_lg}
            paddingTop={theme.space_xs}
          >
            {renderNDaysPicker()}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={props.isOpen}
      showCloseButton
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">
          {props.isInvoiceMonth
            ? copyText.datePickerModalTitleInvoice
            : copyText.datePickerModalTitleCustom}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {props.showRollingLookback
            ? renderFixedAndWindowPickers()
            : renderFixedRangePicker()}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Flex>
          <Button
            marginRight={theme.space_md}
            secondary
            width={100}
            onClick={props.onClose}
          >
            {copyText.closeButtonLabel}
          </Button>
          <Button
            disabled={!canSave()}
            primary
            width={100}
            onClick={handleChangeCustomDateRange}
          >
            {copyText.submitButtonLabel}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
