import Permission from "@ternary/api-lib/constants/permissions";
import {} from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type SavingOpportunityGates = ReturnType<typeof savingOpportunityGates>;

export default function savingOpportunityGates(params: GateGetterParameters) {
  return {
    canCreateSavingOpportunityFilters: params._permissionsIncludeAllOf([
      Permission.CREATE_SAVINGS_OPPORTUNITY,
    ]),
    canListSavingOpportunityFilters: params._permissionsIncludeAllOf([
      Permission.READ_SAVINGS_OPPORTUNITIES,
    ]),
    canCreateGlobalSavingsOpp: params._permissionsIncludeAllOf([
      Permission.CREATE_SAVINGS_OPPORTUNITY_SYSTEM,
    ]),
    canEditGlobalSavingsOpp: params._permissionsIncludeAllOf([
      Permission.UPDATE_SAVINGS_OPPORTUNITY_SYSTEM,
    ]),
    getCanDeleteSpecificSavingOpp: (createdByID: string) =>
      params._permissionsIncludeAllOf([
        Permission.DELETE_SAVINGS_OPPORTUNITY,
      ]) || Boolean(createdByID === params.authenticatedUser.id),
  };
}
