import { createStructParam } from "@/lib/use-query-params";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import Grid from "@/ui-lib/components/Grid";
import { DateRange } from "@/utils/dates";
import { useDebounce } from "@/utils/timers";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  CloudProviderType,
  DataSource,
  DurationType,
  Operator,
  RecommendationCategory,
  ServiceType,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { differenceInHours, endOfDay } from "date-fns";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import {
  DateParam,
  DecodedValueMap,
  JsonParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { z } from "zod";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import useGetRecommendationsByTenantID from "../../../../api/core/hooks/useGetRecommendationsByTenantID";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import copyText from "../../copyText";
import useGetCostSpendSummaries from "../../hooks/useGetCostSpendSummaries";
import useGetCloudRunServiceGroups from "../hooks/useGetCloudRunServiceGroups";
import useGetCloudRunServices from "../hooks/useGetCloudRunServices";
import {
  GCPCloudRunServiceGroup,
  GCPCloudRunServiceGroupFilters,
} from "../types";
import GCPCloudRunMeters from "./GCPCloudRunMeters";
import GCPCloudRunServiceGroupTable from "./GCPCloudRunServiceGroupTable";
import GCPCloudRunServiceGroupTableControls from "./GCPCloudRunServiceGroupTableControls";
import GCPCloudRunServiceTable from "./GCPCloudRunServiceTable";

type Interaction =
  | GCPCloudRunServiceGroupTable.Interaction
  | GCPCloudRunServiceGroupTableControls.Interaction;

const CostChartOption = {
  CATEGORY: "CATEGORY",
  PROJECT_ACCOUNT: "PROJECT_ACCOUNT",
  REGION: "REGION",
} as const;

type CostChartOption = (typeof CostChartOption)[keyof typeof CostChartOption];

const UsageChartOption = {
  CPU: "CPU",
  MEMORY: "MEMORY",
} as const;

type UsageChartOption =
  (typeof UsageChartOption)[keyof typeof UsageChartOption];

const costGroupingEnum = createEnumParam(Object.values(CostChartOption));
const usageChartOptionEnum = createEnumParam(Object.values(UsageChartOption));
const durationEnum = createEnumParam(Object.values(DurationType));

type QueryParams = DecodedValueMap<typeof queryParamConfigMap>;

type QueryParamState = {
  dateRange: DateRange;
  dateRangeGranularity: TimeGranularity;
  duration: DurationType;
  selectedCostChartOption: CostChartOption;
  selectedServiceGroup: z.infer<typeof selectedServiceGroupStruct> | null;
  selectedUsageChartOption: UsageChartOption;
  serviceGroupFilters: z.infer<typeof serviceGroupFiltersStruct>;
};

const serviceGroupFiltersDefault = {
  lowCarbon: null,
  projectId: null,
  region: null,
  regionalPriceTier: null,
};

const serviceGroupFiltersStruct = z.object({
  lowCarbon: z.nullable(z.string()),
  projectId: z.nullable(z.string()),
  region: z.nullable(z.string()),
  regionalPriceTier: z.nullable(z.string()),
});

const selectedServiceGroupStruct = z.object({
  projectId: z.string(),
  region: z.string(),
});

const queryParamConfigMap = {
  cost_grouping: withDefault(costGroupingEnum, CostChartOption.CATEGORY),
  date_range_end: DateParam,
  date_range_start: DateParam,
  duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
  filters: withDefault(JsonParam, []),
  selected_group: createStructParam(selectedServiceGroupStruct),
  service_group_filters: createStructParam(serviceGroupFiltersStruct),
  service_table_sort: JsonParam,
  usage_grouping: withDefault(usageChartOptionEnum, UsageChartOption.CPU),
};

const costGroupingLabel = {
  [CostChartOption.CATEGORY]: copyText.cloudRunCostChartOptionLabel_category,
  [CostChartOption.PROJECT_ACCOUNT]:
    copyText.cloudRunCostChartOptionLabel_projectAccount,
  [CostChartOption.REGION]: copyText.cloudRunCostChartOptionLabel_region,
};

const usageGroupingLabel = {
  [UsageChartOption.CPU]: copyText.cloudRunUsageChartOptionLabel_vCPU,
  [UsageChartOption.MEMORY]: copyText.cloudRunUsageChartOptionLabel_memory,
};

export default function GCPCloudRunVisibilityContainer(): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  //
  // STATE
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);

  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText);

  const queryParamState = getQueryParamState(queryParams);

  let dateRange: Date[] = [];

  const customDateRange =
    (queryParams.duration === DurationType.CUSTOM ||
      queryParams.duration === DurationType.INVOICE) &&
    queryParams.date_range_start &&
    queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : null;

  if (!globalDate.enabled && customDateRange) {
    dateRange = customDateRange;
  } else if (globalDate.date) {
    dateRange = globalDate.date;
  } else {
    dateRange = getCubeDateRangeFromDurationType(queryParams.duration);
  }

  //
  // QUERIES
  //

  const spendSummaries = useGetCostSpendSummaries({
    dataSource: DataSource.CLOUD_RUN,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    spendSummaries;

  const isLoadingSpendSummaries = spendSummaries.some(
    (summary) => summary.isFetching
  );
  const _defaultRecommendations = [];

  const {
    data: recommendations = _defaultRecommendations,
    isFetching: isLoadingRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID ?? "", {
    category: RecommendationCategory.COMPUTE,
    cloudProviderType: CloudProviderType.GCP,
    serviceType: ServiceType.CLOUD_RUN,
  });

  const costDimensions = getCostDimensionsFromOption(
    queryParamState.selectedCostChartOption
  );

  const costMeasures = ["totalMemoryCost", "totalVCPUCost"];

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.CLOUD_RUN,
    dateRange,
    dimensions: costDimensions,
    granularity: queryParamState.dateRangeGranularity,
    measures: costMeasures,
  });

  const usageMeasures = getUsageMeasuresFromOption(
    queryParamState.selectedUsageChartOption
  );

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: DataSource.CLOUD_RUN,
      dateRange,
      granularity: queryParamState.dateRangeGranularity,
      measures: usageMeasures,
    });

  const { data: serviceGroups, isFetching: isLoadingServiceGroups } =
    useGetCloudRunServiceGroups({
      dateRange,
    });

  const { data: services = [], isFetching: isLoadingServices } =
    useGetCloudRunServices(
      {
        dateRange: queryParamState.dateRange,
        queryFilters: queryParamState.selectedServiceGroup
          ? getServiceGroupQueryFilters(queryParamState.selectedServiceGroup)
          : [],
      },
      { enabled: Boolean(queryParamState.selectedServiceGroup) }
    );

  //
  // MODIFIED QUERY DATA
  //

  const filteredServiceGroups = useMemo(() => {
    return getFilteredServiceGroups({
      allServiceGroups: serviceGroups ?? [],
      searchText: debouncedSearchText,
      serviceGroupFilters: queryParamState.serviceGroupFilters,
    });
  }, [debouncedSearchText, serviceGroups, queryParamState.serviceGroupFilters]);

  const csvData = useMemo(
    () => getCSVData(filteredServiceGroups),
    [filteredServiceGroups]
  );

  //
  // INTERACTIONS
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case GCPCloudRunServiceGroupTable.INTERACTION_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.serviceGroupFilters };

        nextFilters[interaction.filterKey] = interaction.filterValue;

        setQueryParams({ service_group_filters: nextFilters });
        break;
      }
      case GCPCloudRunServiceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED: {
        const nextFilters = { ...queryParamState.serviceGroupFilters };

        nextFilters[interaction.filterKey] = null;

        if (Object.values(nextFilters).every((value) => value === null)) {
          setQueryParams({ service_group_filters: null });
        } else {
          setQueryParams({ service_group_filters: nextFilters });
        }
        break;
      }
      case GCPCloudRunServiceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED: {
        setSearchText(interaction.searchText);
        break;
      }
    }
  }

  //
  // RENDER
  //

  const costGroupingOptions = [
    CostChartOption.CATEGORY,
    CostChartOption.PROJECT_ACCOUNT,
    CostChartOption.REGION,
  ].map((costGrouping) => ({
    label: costGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setQueryParams({ cost_grouping: costGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === queryParams.cost_grouping
    ) ?? costGroupingOptions[0];

  const usageGroupingOptions = [
    UsageChartOption.CPU,
    UsageChartOption.MEMORY,
  ].map((usageGrouping) => ({
    label: usageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setQueryParams({ usage_grouping: usageGrouping }),
  }));

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === queryParams.usage_grouping
    ) ?? usageGroupingOptions[0];

  const resourceUsageKeys = useMemo(() => {
    let keys: { [key: string]: string } = {};

    if (queryParamState.selectedUsageChartOption === UsageChartOption.CPU) {
      keys = {
        totalVCPUDays: copyText.cloudRunResourceUsageAverageProvisionedVCPU,
      };
    } else if (
      queryParamState.selectedUsageChartOption === UsageChartOption.MEMORY
    ) {
      keys = {
        totalMemoryDays: copyText.cloudRunResourceUsageAverageProvisionedMemory,
      };
    }

    return keys;
  }, [queryParamState.selectedUsageChartOption]);

  let formatUsageValue: ((value: string) => string) | undefined = undefined;
  if (queryParamState.selectedUsageChartOption === UsageChartOption.MEMORY) {
    formatUsageValue = (value) => byteFormatter(value, { binary: true });
  }

  return (
    <Box>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPCloudRunMeters
          isLoading={isLoadingSpendSummaries || isLoadingRecommendations}
          lastMonthSpend={lastMonthFull?.cost ?? 0}
          lastMTDSpend={lastMTD?.cost ?? 0}
          thisMTDSpend={currentMTD?.cost ?? 0}
          recommendations={recommendations}
        />
      </Box>
      <Flex
        justifyContent="flex-end"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <DateRangeControls
          dateRange={dateRange}
          durationType={queryParams.duration}
          hiddenOptions={[DurationType.QUARTER_TO_DATE]}
          onChangeDateRange={(durationType, dateRange) => {
            setQueryParams({
              duration: durationType,
              date_range_start: dateRange?.[0] ?? null,
              date_range_end: dateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Grid
        gridColumnGap={theme.space_lg}
        gridTemplateColumns={`repeat(2, calc(50% - (${theme.space_lg} / 2) ))`}
      >
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.cloudRunCostAggregateChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultCostGroupingOption}
                options={costGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {costGroupingLabel[queryParams.cost_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <StackedBarChart
                data={costData}
                disableDrilldown
                dimensions={costDimensions.map((dimension) => ({
                  name: dimension,
                  isDate: false,
                }))}
                limit={10}
                isLoading={isLoadingCostData}
                measures={costMeasures.map(getMeasureWithUnit)}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                emptyPlaceholderText={copyText.noDataPlaceholderMessage}
              />
            </Box>
          </Box>
        </Flex>
        <Flex height={500} marginBottom={theme.space_lg}>
          <Box
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            flex={1}
            height="100%"
            padding={theme.space_md}
            width="50%"
          >
            <Flex justifyContent="space-between">
              <Text fontSize={theme.h3_fontSize}>
                {copyText.cloudRunUsageChartTitle}
              </Text>

              <Dropdown
                defaultSelectedOption={defaultUsageGroupingOption}
                options={usageGroupingOptions}
                placement="bottom-end"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  secondary
                  size="small"
                  width={140}
                >
                  {usageGroupingLabel[queryParams.usage_grouping]}
                </Button>
              </Dropdown>
            </Flex>
            <Box height={450} paddingVertical={theme.space_md}>
              <AreaChart
                data={usageData}
                dimensions={[]}
                disableDrilldown
                isLoading={isLoadingUsageData}
                measures={usageMeasures.map((measure) => ({
                  name: measure,
                  unit:
                    measure === "totalMemoryDays"
                      ? UnitType.BINARY_BYTES
                      : UnitType.CORES,
                }))}
                mergeMeasures
                readableKeys={resourceUsageKeys}
                showLegend
                showTooltip
                timeSeriesGranularity={queryParamState.dateRangeGranularity}
                tooltipFormatter={formatUsageValue}
                yAxisFormatter={formatUsageValue}
              />
            </Box>
          </Box>
        </Flex>
      </Grid>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        marginVertical={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPCloudRunServiceGroupTableControls
          csvData={csvData}
          debouncedSearchText={debouncedSearchText}
          searchText={searchText}
          serviceGroupFilters={queryParamState.serviceGroupFilters}
          onInteraction={handleInteraction}
        />
      </Box>
      <GCPCloudRunServiceGroupTable
        isLoading={isLoadingServiceGroups}
        serviceGroups={filteredServiceGroups ?? []}
        onInteraction={handleInteraction}
      />
      {queryParamState.selectedServiceGroup !== null && (
        <GCPCloudRunServiceTable
          isLoading={isLoadingServices}
          services={services}
          onClose={() =>
            setQueryParams({
              selected_group: null,
              service_table_sort: null,
            })
          }
        />
      )}
    </Box>
  );
}

function byteFormatter(value: unknown, options?: { binary?: boolean }): string {
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return "0";
  }

  return prettyBytes(value, options?.binary ? { binary: true } : undefined);
}

function getCostDimensionsFromOption(option: CostChartOption) {
  switch (option) {
    case CostChartOption.CATEGORY:
      return ["regionalPriceTier"];
    case CostChartOption.PROJECT_ACCOUNT:
      return ["projectId"];
    case CostChartOption.REGION:
      return ["region"];
    default: {
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
    }
  }
}

function getUsageMeasuresFromOption(option: UsageChartOption): string[] {
  if (option === UsageChartOption.CPU) {
    return ["totalVCPUDays"];
  } else if (option === UsageChartOption.MEMORY) {
    return ["totalMemoryDays"];
  }

  return [];
}

const measuresWithUnit: { name: string; unit: UnitType }[] = [
  { name: "totalMemoryCost", unit: UnitType.CURRENCY },
  { name: "totalVCPUCost", unit: UnitType.CURRENCY },
];

function getMeasureWithUnit(measure: string) {
  return (
    measuresWithUnit.find((other) => other.name === measure) ?? {
      name: measure,
      unit: UnitType.STANDARD,
    }
  );
}

function getServiceGroupQueryFilters(
  selectedServiceGroup: z.infer<typeof selectedServiceGroupStruct>
): QueryFilter[] {
  const groupDimensionKeys = ["projectId", "region"] as const;

  const andFilter = groupDimensionKeys.map(
    (key): QueryFilter =>
      selectedServiceGroup[key] === ""
        ? {
            name: key,
            operator: Operator.NOT_SET,
          }
        : {
            name: key,
            operator: Operator.EQUALS,
            values: [selectedServiceGroup[key]],
          }
  );

  return [{ and: andFilter }];
}

function getQueryParamState(queryParams: QueryParams): QueryParamState {
  const dateRange =
    queryParams.date_range_start && queryParams.date_range_end
      ? [queryParams.date_range_start, queryParams.date_range_end]
      : getCubeDateRangeFromDurationType(queryParams.duration);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const dateRangeGranularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  return {
    dateRange,
    dateRangeGranularity,
    duration: queryParams.duration,
    serviceGroupFilters:
      queryParams.service_group_filters ?? serviceGroupFiltersDefault,
    selectedCostChartOption: queryParams.cost_grouping,
    selectedServiceGroup: queryParams.selected_group ?? null,
    selectedUsageChartOption: queryParams.usage_grouping,
  };
}

function isServiceGroupPassingFilters(
  serviceGroup: GCPCloudRunServiceGroup,
  filters: GCPCloudRunServiceGroupFilters
): boolean {
  if (
    filters.lowCarbon !== null &&
    serviceGroup.lowCarbon?.toString().toLowerCase().trim() !==
      filters.lowCarbon.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.projectId !== null &&
    serviceGroup.projectId?.toLowerCase().trim() !==
      filters.projectId.toLowerCase().trim()
  ) {
    return false;
  }
  if (
    filters.region !== null &&
    serviceGroup.region?.toLowerCase().trim() !==
      filters.region.toLowerCase().trim()
  ) {
    return false;
  }

  if (
    filters.regionalPriceTier !== null &&
    serviceGroup.regionalPriceTier?.toLowerCase().trim() !==
      filters.regionalPriceTier.toLowerCase().trim()
  ) {
    return false;
  }

  return true;
}

function isSearchTextInServiceGroup(
  serviceGroup: GCPCloudRunServiceGroup,
  searchText: string
): boolean {
  if (searchText === "") return true;

  const values = [
    serviceGroup.projectId,
    serviceGroup.region,
    serviceGroup.regionalPriceTier,
  ].map((value) => (value === "" ? "null" : value));

  return values.some((value) =>
    value?.toLowerCase().trim().includes(searchText)
  );
}

const csvAccessors = [
  "projectId",
  "region",
  "type",
  "lowCarbon",
  "cost",
  "serviceCount",
  "averageVCPU",
  "averageMemory",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(groups: GCPCloudRunServiceGroup[]): CSVData {
  if (!groups.length) {
    return { headers: [], rows: [] };
  }

  const rows = groups.map((group) => ({
    averageMemory: group.averageMemory ?? 0,
    averageVCPU: group.averageVCPU ?? 0,
    cost: group.cost ?? 0,
    lowCarbon: group.lowCarbon
      ? group.lowCarbon.toString()
      : copyText.cloudRunTableNull,
    projectId: group.projectId ?? copyText.cloudRunTableNull,
    region: group.region ?? copyText.cloudRunTableNull,
    serviceCount: group.averageNumServices ?? 0,
    type: group.regionalPriceTier ?? copyText.cloudRunTableNull,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `cloudRunTableServiceGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}

type GetFilteredServiceGroupsParams = {
  allServiceGroups: GCPCloudRunServiceGroup[];
  searchText: string | null;
  serviceGroupFilters: GCPCloudRunServiceGroupFilters;
};

function getFilteredServiceGroups(
  params: GetFilteredServiceGroupsParams
): GCPCloudRunServiceGroup[] {
  const searchText = (params.searchText ?? "").toLowerCase().trim();

  return params.allServiceGroups.filter((serviceGroup) => {
    if (
      !isServiceGroupPassingFilters(serviceGroup, params.serviceGroupFilters)
    ) {
      return false;
    }

    if (!isSearchTextInServiceGroup(serviceGroup, searchText)) {
      return false;
    }

    return true;
  });
}
