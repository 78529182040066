import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconTimes(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 13 14">
        <path d="M1.17.334a.832.832 0 0 0-.58 1.428l5.234 5.236-5.235 5.236a.831.831 0 1 0 1.176 1.177L7 8.175l5.235 5.236a.832.832 0 1 0 1.176-1.177L8.176 6.998l5.235-5.236A.833.833 0 1 0 12.235.586L7 5.822 1.765.586A.832.832 0 0 0 1.17.334Z" />
      </svg>
    </Icon>
  );
}
