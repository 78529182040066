import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconMagnifyingGlassChart(
  props: IconProps
): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.2101 2.5562e-08C12.7445 2.5562e-08 16.4198 3.67572 16.4198 8.2101C16.4197 10.1217 15.7662 11.8808 14.6705 13.2761L17.888 16.4933C18.0373 16.6441 18.0373 16.8869 17.888 17.0373L17.0374 17.8871C16.8874 18.0372 16.6439 18.0372 16.4934 17.8871L13.2767 14.67C11.8812 15.7661 10.1216 16.4198 8.20939 16.4198C3.67501 16.4195 -0.000353919 12.7438 2.5562e-08 8.20939C2.5562e-08 3.67501 3.67572 -0.000353919 8.2101 2.5562e-08ZM8.2101 1.97997C4.76976 1.97997 1.98067 4.76905 1.98067 8.20939C1.98067 11.6501 4.76976 14.4392 8.2101 14.4392C11.6508 14.4392 14.4399 11.6501 14.4395 8.2101C14.4395 4.76905 11.6504 1.97997 8.2101 1.97997ZM8.93827 5.74158V12.623C8.42541 12.7157 7.89909 12.7122 7.38729 12.612V5.74158H8.93827ZM5.86373 7.8929V12.0287C5.2238 11.6373 4.69076 11.0929 4.3131 10.4445V7.8929H5.86373ZM12.1558 8.95353V10.3201C11.7891 10.9979 11.255 11.5706 10.6048 11.984V8.95353H12.1558Z"
          fill="#1E2328"
        />
      </svg>
    </Icon>
  );
}
