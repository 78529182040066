import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type LabelManagementGates = ReturnType<typeof labelManagementGates>;

export default function labelManagementGates(params: GateGetterParameters) {
  const canCreateCustomLabel = params._permissionsIncludeAllOf([
    Permission.CREATE_CUSTOM_LABEL,
  ]);

  const canCreateLabelGroupingRule = params._permissionsIncludeAllOf([
    Permission.CREATE_LABEL_GROUPING_RULE,
  ]);

  const canDeleteCustomLabel = params._permissionsIncludeAllOf([
    Permission.DELETE_CUSTOM_LABEL,
  ]);

  const canDeleteLabelGroupingRule = params._permissionsIncludeAllOf([
    Permission.DELETE_LABEL_GROUPING_RULE,
  ]);

  const canListCustomLabels = params._permissionsIncludeAllOf([
    Permission.READ_CUSTOM_LABELS,
  ]);

  const canListLabelGroupingRules = params._permissionsIncludeAllOf([
    Permission.READ_LABEL_GROUPING_RULES,
  ]);

  const canUpdateCustomLabel = params._permissionsIncludeAllOf([
    Permission.UPDATE_CUSTOM_LABEL,
  ]);

  const canUpdateLabelGroupingRule = params._permissionsIncludeAllOf([
    Permission.UPDATE_LABEL_GROUPING_RULE,
  ]);

  const labelManagementGates = {
    canCreateCustomLabel,
    canCreateLabelGroupingRule,
    canDeleteCustomLabel,
    canDeleteLabelGroupingRule,
    canListCustomLabels,
    canListLabelGroupingRules,
    canUpdateCustomLabel,
    canUpdateLabelGroupingRule,
  };

  const canViewLabelManagement = Object.values(labelManagementGates).every(
    (gate) => gate === true
  );

  return {
    ...labelManagementGates,
    canViewLabelManagement,
    isConfiguredCustomLabels: params.integrations.length > 0,
  };
}
