import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  LabelMap,
  Order,
  QueryFilter,
  RawData,
} from "@ternary/api-lib/analytics/types";
import { getLabelMappedData } from "@ternary/api-lib/analytics/utils";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { LabelMapsEntity } from "@ternary/api-lib/core/types";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import { buildCubeQuery } from "./utils";

export interface Params {
  dateRange: Date[];
  dataSource: DataSource;
  dimensions?: string[];
  granularity?: TimeGranularity;
  limit?: number;
  measures: string[];
  order?: Order;
  queryFilters?: QueryFilter[];
  queryKey?: string[];
}

export default function useGetRawData(
  params: Params,
  options?: UseQueryOptions<RawData[], UError>
): UseQueryResult<RawData[], UError> {
  const client = useAnalyticsApiClient();

  const queryClient = useQueryClient();

  const { queryKey, ...restParams } = params;

  // NOTE: to prevent refetches based on dimension order change when available in cache (not safe for measures)
  restParams.dimensions = restParams.dimensions?.sort();

  return useQuery({
    queryKey: ["rawData", ...(queryKey ? queryKey : []), restParams],
    queryFn: async () => {
      const result = await client.load(buildCubeQuery(params));

      const labelMaps = queryClient.getQueryData<LabelMapsEntity>([
        "labelMaps",
      ]);

      if (!labelMaps) {
        throw new Error("ERROR_MAPS_UNDEFINED");
      }

      const labelMap: LabelMap = labelMaps[params.dataSource] ?? {};

      const reversedLabelMap = Object.entries(labelMap).reduce(
        (accum: { [key: string]: string }, [key, value]) => ({
          ...accum,
          [value]: key,
        }),
        {}
      );

      return getLabelMappedData(result, reversedLabelMap);
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
