import { useQuery } from "@tanstack/react-query";
import { FindAlertRuleEventsQueryResult } from "@ternary/api-lib/core/queries/FindAlertRuleEventsQuery";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetAlertRuleEventsByAlertRuleID(
  alertRuleID: string,
  options?: UseQueryOptions<
    FindAlertRuleEventsQueryResult["events"],
    CoreAPIClientError
  >
): UseQueryResult<
  FindAlertRuleEventsQueryResult["events"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.alertRuleEvents(alertRuleID),
    queryFn: () => client.getAlertRuleEventsByAlertRuleID(alertRuleID),
    enabled: gatekeeper.canListAlertRuleEvents,
    ...options,
  });
}
