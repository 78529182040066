import { ActivityType, CaseStatus } from "@ternary/api-lib/constants/enums";
import {
  CaseEventEntity,
  CaseUpdatedEventEntity,
} from "@ternary/api-lib/core/types";

type BaseActivity = {
  id: string;
  createdAt: string;
  userID: string;
};

export type AssigneeChangeActivity = BaseActivity & {
  type: typeof ActivityType.ASSIGNEE_CHANGE;
  newAssigneeIDs: string[];
  prevAssigneeIDs: string[];
};

export type CommentActivity = BaseActivity & {
  type: typeof ActivityType.COMMENT;
  reportID: string | null;
  text: string;
};

export type StatusChangeActivity = BaseActivity & {
  type: typeof ActivityType.STATUS_CHANGE;
  newStatus: CaseStatus;
  prevStatus: CaseStatus;
};

export type CaseActivity =
  | AssigneeChangeActivity
  | CommentActivity
  | StatusChangeActivity;

export const CaseCategory = {
  ALL: "ALL",
  CLOSED: "CLOSED",
  PERSONAL: "PERSONAL",
} as const;

export type CaseCategory = (typeof CaseCategory)[keyof typeof CaseCategory];

export type RecommendationDetail = {
  key: string;
  value: string;
};

export type User = {
  id: string;
  avatarSrc: string;
  email: string;
  firstName: string;
  lastName: string;
};

export function isCaseUpdatedEvent(
  event: CaseEventEntity
): event is CaseUpdatedEventEntity {
  return event.type === "CaseUpdatedEvent";
}
