import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateUserGroupConfigParameters } from "@/api/core/types";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { userGroupConfigID: string } & UpdateUserGroupConfigParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { userGroupConfigID: string } & UpdateUserGroupConfigParameters
>;

export default function useUpdateUserGroupConfig(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ userGroupConfigID, ...params }) =>
      client.updateUserGroupConfig(userGroupConfigID, params),
    ...options,
  });
}
