import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { CreateRuleSetParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  CreateRuleSetParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  CreateRuleSetParams
>;

export default function useCreateRuleSet(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.createRuleSet(params),
    ...options,
  });
}
