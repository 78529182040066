import { useTheme } from "@emotion/react";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Icon from "@ternary/web-ui-lib/components/Icon";
import React from "react";

// TODO: Support different types of spinners.

export default function LoadingSpinner(props: {
  color?: string;
  size?: SizeProp;
}): JSX.Element {
  const theme = useTheme();

  const color = props.color ?? theme.feedback_neutral;

  return <Icon color={color} icon={faSpinner} size={props.size} spin />;
}
