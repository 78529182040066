import paths, { locationStateKeyedByPath } from "@/constants/paths";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { useMatchPath } from "@/lib/react-router";
import { FilterDataJSONParam } from "@/lib/use-query-params";
import IconMagnifyingGlassChart from "@/ui-lib/icons/IconMagnifyingGlassChart";
import IconTimes from "@/ui-lib/icons/IconTimes";
import { storage } from "@/utils/window";
import { useTheme } from "@emotion/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  CaseEntity,
  DashboardEntity,
  ReportEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { useCaseManagementStore } from "@ternary/api-lib/ui-lib/context/CaseManagementStoreProvider";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Link from "@ternary/web-ui-lib/components/Link";
import Text from "@ternary/web-ui-lib/components/Text";
import { Dictionary, keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";
import copyText from "../common.copyText";
import { navOptionsForType } from "../constants/navSearch";
import useGetBudgetsByTenantID from "../features/budget-management/hooks/useGetBudgetsByTenantID";
import useGetCasesByTenantID from "../features/case-management/hooks/useGetCasesByTenantID";
import useGetDashboardsByTenantID from "../features/reporting-engine/hooks/useGetDashboardsByTenantID";
import useGetReportsByTenantID from "../features/reporting-engine/hooks/useGetReportsByTenantID";
import scopedViewKeys from "../features/user-settings/hooks/queryKeys";
import useGatekeeper from "../hooks/useGatekeeper";
import Footer from "./Footer";
import { NavSearchModal } from "./NavSearchModal";
import SideNav, { config as sideNavConfig } from "./SideNav";
import TopNav from "./TopNav";

const SIDE_NAV_EXPANDED_STORAGE_KEY = "side_nav_expanded";
const DEFAULT_DOC_TITLE = "Ternary";

type Interaction = SideNav.Interaction;

const defaultCases = [];
const defaultDashboards = [];
const defaultReports = [];
const defaultBudgets = [];

export default function Layout(props: {
  reportBuilderLayout: boolean;
}): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const locationState = locationStateKeyedByPath[currentPath];

  //
  // Search Params
  //

  const [searchParamState] = useQueryParams({
    filter_data: FilterDataJSONParam,
    tab: StringParam,
  });

  //
  // State
  //

  const { isResourceSelectionMode } = useCaseManagementStore();

  const [showSideNav, setShowSideNav] = useState(getSideNavState());
  const [showModal, setShowModal] = useState(false);

  //
  // Quries
  //

  const { data: reports = defaultReports, isFetching: isLoadingReports } =
    useGetReportsByTenantID(authenticatedUser.tenant.id);

  const {
    data: dashboards = defaultDashboards,
    isFetching: isLoadingDashboards,
  } = useGetDashboardsByTenantID(authenticatedUser.tenant.id, {
    enabled: gatekeeper.canListDashboards,
  });

  const { data: budgets = defaultBudgets, isFetching: isLoadingBudgets } =
    useGetBudgetsByTenantID(authenticatedUser.tenant.fsDocID, {
      enabled: gatekeeper.canListBudgets,
    });

  const { data: cases = defaultCases, isFetching: isLoadingCases } =
    useGetCasesByTenantID(authenticatedUser.tenant.id);

  //
  // Side Effect
  //

  const reportsKeyedByID = keyBy(reports, "id");
  const dashboardsKeyedByID = keyBy(dashboards, "id");
  const casesKeyedByID = keyBy(cases, "id");

  useEffect(() => {
    document.title = getDocumentTitleFromPage({
      currentPath,
      casesKeyedByID,
      dashboardsKeyedByID,
      reportsKeyedByID,
    });
  }, [casesKeyedByID, currentPath, dashboardsKeyedByID, reportsKeyedByID]);

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case SideNav.INTERACTION_CLOSE_BUTTON_CLICKED: {
        storage.setItem(SIDE_NAV_EXPANDED_STORAGE_KEY, JSON.stringify(false));
        setShowSideNav(false);
        return;
      }
      case SideNav.INTERACTION_OPEN_BUTTON_CLICKED: {
        storage.setItem(SIDE_NAV_EXPANDED_STORAGE_KEY, JSON.stringify(true));
        setShowSideNav(true);
        return;
      }
      case SideNav.INTERACTION_OPEN_MODAL_BUTTON_CLICKED: {
        setShowModal((showModal) => !showModal);
        return;
      }
    }
  }

  //
  // Render
  //

  const isSharedView =
    !!searchParamState.filter_data?.auth_id &&
    searchParamState.filter_data.auth_id !== authenticatedUser.id;

  const mainContentWrapperStyles = {
    direction: "column" as const,
    height: "100vh",
    marginLeft:
      !isSharedView && !isResourceSelectionMode
        ? showSideNav
          ? sideNavConfig.WIDTH_OPEN
          : sideNavConfig.WIDTH_CLOSED
        : "unset",
    width:
      !isSharedView && !isResourceSelectionMode
        ? showSideNav
          ? `calc(100% - ${sideNavConfig.WIDTH_OPEN})`
          : `calc(100% - ${sideNavConfig.WIDTH_CLOSED})`
        : "100%",
  };

  const enableGlobalFiltering = locationState?.enableGlobalFiltering ?? false;

  let enableCaseManagement = false;

  if (typeof locationState?.enableCaseManagement === "boolean") {
    enableCaseManagement = locationState.enableCaseManagement;
  }

  if (typeof locationState?.enableCaseManagement === "object") {
    enableCaseManagement = locationState.enableCaseManagement.enabledForTab
      ? locationState.enableCaseManagement.enabledForTab ===
        searchParamState.tab
      : true;
  }

  if (!gatekeeper.canCreateCase && !gatekeeper.canCreateCaseComment) {
    enableCaseManagement = false;
  }

  return (
    <Flex height="100vh" width="100%">
      {isResourceSelectionMode && <ResourceSelectionModeBanner />}
      <NavSearchModal
        budgets={budgets}
        dashboards={dashboards}
        isLoading={
          isLoadingCases ||
          isLoadingReports ||
          isLoadingBudgets ||
          isLoadingDashboards
        }
        reports={reports}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
      {!isSharedView && !isResourceSelectionMode && (
        <Flex height="100vh" position="fixed" zIndex={theme.zIndex_100}>
          <SideNav
            isOpen={showSideNav}
            tenant={authenticatedUser.tenant}
            onInteraction={handleInteraction}
          />
        </Flex>
      )}
      <Flex {...mainContentWrapperStyles}>
        <TopNav
          authenticatedUser={authenticatedUser}
          enableCaseManagement={enableCaseManagement}
          enableGlobalFiltering={enableGlobalFiltering}
          isSharedView={isSharedView}
        />
        <Flex
          direction="column"
          height="100%"
          overflowY="auto"
          padding={props.reportBuilderLayout ? undefined : theme.space_xl}
        >
          {locationState && locationState.title && (
            <Text appearance="h2" marginBottom={theme.space_md}>
              {locationState.title}
            </Text>
          )}
          <Box flex="1 0 0">
            <Outlet />
          </Box>
          {!props.reportBuilderLayout && <Footer />}
        </Flex>
        {isSharedView && <SharedViewBanner />}
      </Flex>
    </Flex>
  );
}

export function getSideNavState(): boolean {
  const isExpanded = storage.getItem(SIDE_NAV_EXPANDED_STORAGE_KEY);

  return isExpanded === null ? true : JSON.parse(isExpanded);
}

function SharedViewBanner() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.tag_button_background_color_warning_hover}
      bottom={0}
      flexWrap="nowrap"
      height={64}
      justifyContent="center"
      padding={`${theme.space_md} ${theme.space_xl}`}
      position="fixed"
      width="100%"
    >
      <Flex alignItems="center">
        <IconMagnifyingGlassChart
          height={theme.space_lg}
          width={theme.space_lg}
        />
        <Text marginHorizontal={theme.space_md}>
          {copyText.sharedViewMessage}
        </Text>
      </Flex>
      <Link to={location.pathname}>
        <Button
          iconStart={<IconTimes />}
          secondary
          size="small"
          type="button"
          onClick={() =>
            queryClient.resetQueries({ queryKey: scopedViewKeys.all })
          }
        >
          {copyText.sharedViewButtonLabel}
        </Button>
      </Link>
    </Flex>
  );
}

function ResourceSelectionModeBanner(): JSX.Element {
  const caseManagementStore = useCaseManagementStore();
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.primary_color_background_inverse}
      height={64}
      justifyContent="space-between"
      padding={theme.space_md}
      position="fixed"
      top={0}
      width="100%"
      zIndex={theme.zIndex_800}
    >
      <Box />
      <Text
        align="center"
        color={theme.text_color_inverse}
        fontSize={theme.h4_fontSize}
      >
        {copyText.selectResourceBannerText}
      </Text>
      <Button
        iconStart={<Icon color={theme.text_color_inverse} icon={faClose} />}
        secondary
        size="small"
        onClick={() =>
          caseManagementStore.set({ isResourceSelectionMode: false })
        }
      />
    </Flex>
  );
}

function getDocumentTitleFromPage(params: {
  currentPath: string;
  casesKeyedByID: Dictionary<CaseEntity>;
  dashboardsKeyedByID: Dictionary<DashboardEntity>;
  reportsKeyedByID: Dictionary<ReportEntity>;
}) {
  const pathName = new URL(window.location.href).pathname;
  const title = "%PAGE% - Ternary";
  let resourceName = "";

  switch (params.currentPath) {
    case paths._case: {
      const resourceID = pathName
        .slice(pathName.lastIndexOf("/"))
        .replace("/", "");

      resourceName = params.casesKeyedByID[resourceID]
        ? params.casesKeyedByID[resourceID].name
        : "Case";
      break;
    }
    case paths._dashboard: {
      const resourceID = pathName
        .slice(pathName.lastIndexOf("/"))
        .replace("/", "");
      resourceName = params.dashboardsKeyedByID[resourceID]
        ? params.dashboardsKeyedByID[resourceID].name
        : "Dashboard";
      break;
    }
    case paths._reportBuilder: {
      const resourceID = pathName
        .slice(pathName.lastIndexOf("/"))
        .replace("/", "");

      resourceName = params.reportsKeyedByID[resourceID]
        ? params.reportsKeyedByID[resourceID].name
        : "Report";
      break;
    }
  }

  if (resourceName) {
    return title.replace("%PAGE%", resourceName);
  }

  const matchingLocation = navOptionsForType.find(
    (option) => option.href === params.currentPath
  );

  return matchingLocation
    ? title.replace("%PAGE%", matchingLocation?.name)
    : DEFAULT_DOC_TITLE;
}
