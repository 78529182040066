import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSRedshiftResourcesEntity,
  awsRedshiftDimensions,
  awsRedshiftMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters: QueryFilter[];
  isCSV?: boolean;
}

export default function useGetAWSRedshiftResources(
  params: Params,
  options?: UseQueryOptions<AWSRedshiftResourcesEntity[], UError>
): UseQueryResult<AWSRedshiftResourcesEntity[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    awsRedshiftDimensions.clusterArn,
    awsRedshiftDimensions.clusterId,
    awsRedshiftDimensions.productFamily,
    awsRedshiftDimensions.instanceMemory,
    awsRedshiftDimensions.instanceType,
    awsRedshiftDimensions.instanceVcpu,
    awsRedshiftDimensions.io,
    awsRedshiftDimensions.payerAccountId,
    awsRedshiftDimensions.region,
    awsRedshiftDimensions.storage,
    awsRedshiftDimensions.usageAccountId,
  ];

  const measures = [
    awsRedshiftMeasures.cost,
    awsRedshiftMeasures.dataScanCost,
    awsRedshiftMeasures.nodeCost,
    awsRedshiftMeasures.storageCost,
    awsRedshiftMeasures.serverlessComputeCost,
    awsRedshiftMeasures.backupStorageCost,
    awsRedshiftMeasures.nodeUsage,
    awsRedshiftMeasures.dataScanUsage,
    awsRedshiftMeasures.storageUsage,
    awsRedshiftMeasures.serverlessComputeUsage,
    awsRedshiftMeasures.backupStorageUsage,
  ];

  const [tenantID, query] = useBuildDataQuery({
    ...params,
    preAggFilters: params.queryFilters,
    dataSource: DataSource.AWS_REDSHIFT_VISIBILITY,
    measures,
    dimensions,
  });

  return useQuery({
    queryKey: [
      params.isCSV ? "AWSRedshiftResources" : "AWSRedshiftResourcesCSV",
      {
        dateRange: params.dateRange,
        preAggFilters: params.queryFilters,
      },
    ],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const resources = result.response.map((datum) => {
        return {
          // DIMENSIONS
          clusterArn: datum.clusterArn ?? "",
          clusterId: datum.clusterId ?? "",
          productFamily: datum.productFamily ?? "",
          instanceMemory: datum.instanceMemory ?? "",
          instanceType: datum.instanceType ?? "",
          instanceVcpu: datum.instanceVcpu ?? "",
          io: datum.io ?? "",
          payerAccountId: datum.payerAccountId ?? "",
          region: datum.region ?? "",
          storage: datum.storage ?? "",
          usageAccountId: datum.usageAccountId ?? "",

          // MEASURES
          cost: datum.cost ?? 0,
          dataScanCost: datum.dataScanCost ?? 0,
          nodeCost: datum.nodeCost ?? 0,
          storageCost: datum.storageCost ?? 0,
          serverlessComputeCost: datum.serverlessComputeCost ?? 0,
          backupStorageCost: datum.backupStorageCost ?? 0,
          nodeUsage: datum.nodeUsage ?? 0,
          dataScanUsage: datum.dataScanUsage ?? 0,
          storageUsage: datum.storageUsage ?? 0,
          serverlessComputeUsage: datum.serverlessComputeUsage ?? 0,
          backupStorageUsage: datum.backupStorageUsage ?? 0,
        } as AWSRedshiftResourcesEntity;
      });

      return resources;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
