import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  faClose,
  faPlay,
  faPlus,
  faSearch,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { partition } from "lodash";
import React, { useRef, useState } from "react";
import TextInput from "../ui-lib/components/TextInput";
import copyText from "../ui-lib/copyText";
import { getFullName } from "../utils/UserUtils";

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type Props = {
  selectedIDs: string[];
  users: User[];
  onChange: (selectedUserIDs: string[]) => void;
};

// TODO: Deprecate for generic DualListBox
export default function UserDualListbox(props: Props): JSX.Element {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState("");
  const [selectedUserIDs, setSelectedUserIDs] = useState(props.selectedIDs);

  const filteredOptions = props.users.filter((user) => {
    if (searchText.length === 0) return true;
    const label = `${getFullName(user)} - ${user.email}`;
    return label.toLowerCase().includes(searchText.toLowerCase());
  });

  const filteredUserIDs = new Set(filteredOptions.map(({ id }) => id));

  const [selectedUsersOptions, unselectedUsersOptions] = partition(
    props.users,
    (option) => props.selectedIDs.includes(option.id)
  );

  function handleSelect(userID: string) {
    const nextSelectedUserIDs = [...selectedUserIDs, userID];

    setSelectedUserIDs(nextSelectedUserIDs);
    props.onChange(nextSelectedUserIDs);
    inputRef.current?.focus();
  }

  function handleUnselect(userID: string) {
    const nextSelectedUserIDs = selectedUserIDs.filter((id) => id !== userID);

    setSelectedUserIDs(nextSelectedUserIDs);
    props.onChange(nextSelectedUserIDs);
    inputRef.current?.focus();
  }

  function handleSelectAll() {
    const allUserIDs = props.users.reduce((accum: string[], users) => {
      if (filteredUserIDs.has(users.id) || selectedUserIDs.includes(users.id)) {
        accum.push(users.id);
      }
      return accum;
    }, []);

    setSelectedUserIDs(allUserIDs);
    props.onChange(allUserIDs);
    inputRef.current?.focus();
  }

  function handleReset() {
    setSelectedUserIDs([]);
    props.onChange([]);
    inputRef.current?.focus();
  }

  function getButtonCopyText(
    filteredOptionsLength: number,
    optionsLength: number
  ) {
    if (filteredOptionsLength === 0) {
      return copyText.addButtonLabel;
    } else if (filteredOptionsLength === optionsLength) {
      return copyText.addAllButtonLabel;
    } else if (filteredOptionsLength === 1) {
      return copyText.addOneUserButtonLabel;
    } else {
      return copyText.addManyUsersButtonLabel.replace(
        "%count%",
        `${filteredOptionsLength}`
      );
    }
  }

  const unselectedFilteredOptions = unselectedUsersOptions.filter(({ id }) =>
    filteredUserIDs.has(id)
  );

  return (
    <Box height={"100%"} width={"100%"}>
      {/* Search Input */}
      <Box marginBottom={theme.space_sm} width={"47%"}>
        <TextInput
          iconEnd={
            <Icon
              color={theme.text_color_secondary}
              clickable
              icon={searchText.length > 0 ? faClose : faSearch}
              onClick={() => {
                setSearchText("");
                inputRef.current?.focus();
              }}
            />
          }
          inputRef={inputRef}
          placeholder={copyText.searchInputPlaceholder}
          size="small"
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleSelectAll();
              setSearchText("");
            }
          }}
        />
      </Box>

      <Flex direction="row" height={"90%"} justifyContent="space-between">
        <Flex direction="column" width={"47%"}>
          {/* Add All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={unselectedFilteredOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleSelectAll()}
            >
              {getButtonCopyText(
                unselectedFilteredOptions.length,
                unselectedUsersOptions.length
              )}
            </Button>
          </Box>

          {/* Can Add List */}
          <Box
            border={`2px solid ${theme.secondary_color_border}`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            overflowY="auto"
            padding={theme.space_xxs}
          >
            {unselectedFilteredOptions.map((option) => (
              <Option
                key={option.id}
                isSelected={false}
                user={{
                  label: `${getFullName(option)} ${
                    getFullName(option) ? "-" : ""
                  } ${option.email}`,
                }}
                onClick={() => handleSelect(option.id)}
              />
            ))}
          </Box>
        </Flex>

        {/* Arrow Right */}
        <Flex
          alignItems="center"
          justifyContent="center"
          paddingTop={theme.space_sm}
          width={"5%"}
        >
          <Icon color={theme.text_color_secondary} icon={faPlay} />
        </Flex>

        <Flex direction="column" width={"47%"}>
          {/* Remove All Button */}
          <Box marginBottom={theme.space_xs}>
            <Button
              disabled={selectedUsersOptions.length === 0}
              secondary
              size="tiny"
              type="button"
              onClick={() => handleReset()}
            >
              {copyText.removeAllButtonLabel}
            </Button>
          </Box>

          {/* Can Remove List */}
          <Box
            border={`2px solid ${theme.secondary_color_border}`}
            borderRadius={theme.borderRadius_2}
            height={"100%"}
            overflowY="auto"
            padding={theme.space_xxs}
          >
            {selectedUsersOptions.map((option) => (
              <Option
                key={option.id}
                isSelected
                user={{ label: `${getFullName(option)} - ${option.email}` }}
                onClick={() => handleUnselect(option.id)}
              />
            ))}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}

type OptionProps = {
  user: { label: string };
  isSelected: boolean;
  onClick: () => void;
};

const StyledButton = styled(Button)(() => ({
  "& > span": { justifyContent: "space-between" },
  "& > span > span": { minWidth: 0 },
}));

function Option(props: OptionProps) {
  return (
    <StyledButton
      fullWidth
      iconEnd={
        <Box flex="0 0 auto">
          <Icon icon={props.isSelected ? faXmark : faPlus} />
        </Box>
      }
      size="small"
      type="button"
      onClick={props.onClick}
    >
      <Text truncate>{props.user.label}</Text>
    </StyledButton>
  );
}
