import { z } from "zod";

const TERNARY_ENV_CONFIG_KEY = "__env__";

const configSchema = z.object({
  ENV: z.string(),
  ANALYTICS_API_BASE_URL: z.string(),
  AUTH_BASE_URL: z.string(),
  CORE_API_BASE_URL: z.string(),
  INTERNAL_API_BASE_URL: z.string(),
  JIRA_API_BASE_URL: z.string(),
  MIXPANEL_TOKEN: z.optional(z.string()),
  SENTRY_DSN: z.optional(z.string()),
  SENTRY_ENV: z.optional(z.string()),
  SENTRY_RELEASE_HASH: z.optional(z.string()),
  SLACK_API_BASE_URL: z.string(),
  STATUSPAGE_URL: z.optional(z.string()),
  ZENDESK_URL: z.optional(z.string()),
});

export type Config = z.infer<typeof configSchema>;

if (!window[TERNARY_ENV_CONFIG_KEY]) {
  throw new Error(
    `config object ${TERNARY_ENV_CONFIG_KEY} is missing from window object; ensure we have config.js loaded`
  );
}

export const config = configSchema.parse(window[TERNARY_ENV_CONFIG_KEY]);

delete window[TERNARY_ENV_CONFIG_KEY];
