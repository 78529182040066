import styled from "@emotion/styled";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import { noop } from "lodash";
import noScroll from "no-scroll";
import React, { MouseEvent, useEffect } from "react";
import ReactDOM from "react-dom";

const KEYBOARD_CODE_ESCAPE = "Escape";

const Root = styled("div")<{ theme?: Theme; usePortal?: boolean }>(
  ({ theme, usePortal }) => ({
    backgroundColor: theme.overlay_background_color,
    bottom: 0,
    display: "flex",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    ...(usePortal && { zIndex: theme.zIndex_800 }),
  })
);

interface Props {
  closeOnClickOutside?: boolean;
  usePortal?: boolean;
  onClose?: (e?) => void;
}

export default function Overlay({
  closeOnClickOutside = true,
  usePortal,
  onClose = noop,
}: Props): JSX.Element {
  useEffect(() => {
    noScroll.on();
    return () => noScroll.off();
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => document.removeEventListener("keydown", onKeyDown, false);
  }, []);

  function onKeyDown(e: KeyboardEvent): void {
    if (e.code !== KEYBOARD_CODE_ESCAPE) return;
    onClose(e);
  }

  function handleClickOverlay(e: MouseEvent): void {
    if (e.target !== e.currentTarget || !closeOnClickOutside) return;
    onClose(e);
  }

  const content = <Root usePortal={usePortal} onClick={handleClickOverlay} />;

  return usePortal
    ? ReactDOM.createPortal(content, document.getElementsByTagName("body")[0])
    : content;
}
