import styled from "@emotion/styled";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import React, { DetailedHTMLProps, TextareaHTMLAttributes } from "react";

export interface Props
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  resizeable?: boolean;
}

interface PropsWithTheme extends Props {
  theme?: Theme;
}

const Input = styled("textarea")<PropsWithTheme>(
  ({ disabled, readOnly, resizeable, theme }) => ({
    backgroundColor:
      disabled || readOnly
        ? theme.background_color_disabled
        : theme.input_background_color,
    border: `1px solid ${theme.secondary_color_border}`,
    borderRadius: theme.borderRadius_2,
    color: (() => {
      if (disabled) return theme.text_color_disabled;
      if (readOnly) return theme.text_color_disabled;
      return theme.text_color;
    })(),
    fontSize: theme.fontSize_ui,
    outline: 0,
    paddingBottom: theme.space_xs,
    paddingLeft: theme.space_md,
    paddingRight: theme.space_md,
    paddingTop: theme.space_xs,
    resize: resizeable ? "vertical" : "none",
    width: "100%",

    "&::placeholder": {
      color: theme.text_color_placeholder,
    },

    "&:hover": {
      borderColor: theme.primary_color_border,
    },

    "&:focus": {
      borderColor: "transparent",
      boxShadow: `0 0 0 2px ${theme.primary_color_focus}`,
    },
  })
);

export default function TextArea(props: Props): JSX.Element {
  return <Input {...props} />;
}
