import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";
import { UpdateRecommendationParameters } from "../types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { recommendationID: string } & UpdateRecommendationParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { recommendationID: string } & UpdateRecommendationParameters
>;

export default function useUpdateRecommendation(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ recommendationID, ...params }) =>
      client.updateRecommendation(recommendationID, params),
    ...options,
  });
}
