import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import copyText from "../copyText";
import ApiKeyManagementContainer from "./ApiKeyManagementContainer";
import ScopedViewManagementContainer from "./ScopedViewManagementContainer";
import UserSettingsManagementContainer from "./UserSettingsManagementContainer";

export default function UserSettingsManagementPage(): JSX.Element {
  const [searchParams, setSearchParams] = useQueryParams({
    tab: withDefault(StringParam, "general"),
  });

  const containers = [
    {
      component: <UserSettingsManagementContainer />,
      label: copyText.generalTabLabel,
      value: "general",
    },
    {
      component: <ScopedViewManagementContainer isAdmin={false} />,
      label: copyText.scopedViewsTabLabel,
      value: "scopedViews",
    },
    {
      component: <ApiKeyManagementContainer />,
      label: copyText.apiManagementTabLabel,
      value: "apiKeys",
    },
  ];

  return (
    <ErrorBoundary boundaryName="UserSettingsManagementPage">
      <Tabs
        activeValue={searchParams.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParams({ tab })}
      />
    </ErrorBoundary>
  );
}
