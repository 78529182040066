import { SortRule } from "@/types";
import Dropdown from "@/ui-lib/components/Dropdown";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { DataSource } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../../copyText";
import { GCPKubernetesResourceType } from "../types";

type TableData = {
  id: string;
  cpuUtilization: number;
  memoryUtilization: number;
  name: string;
  projectID: string;
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  usedBytes: number;
  usedCores: number;
  waste: number;
};

interface Props {
  dataSource:
    | typeof DataSource.KUBERNETES_CLUSTER_USAGE
    | typeof DataSource.KUBERNETES_CONTAINER_USAGE
    | typeof DataSource.KUBERNETES_NODE_USAGE;
  dayCount: string;
  initialGrouping: GCPKubernetesResourceType;
  initialSortBy: SortRule | null;
  isLoading: boolean;
  isUpdating: boolean;
  resources: TableData[];
  resourceType: GCPKubernetesResourceType;
  selectedProjectID: string;
  selectedResourceID: string;
  onInteraction: (interaction: GCPKubernetesResourceTable.Interaction) => void;
}

function GCPKubernetesResourceTable(props: Props): JSX.Element {
  const theme = useTheme();

  const tooltipText = getTooltipText(
    props.dataSource,
    props.initialGrouping,
    props.resourceType
  );

  const hiddenColumns = [
    (props.initialGrouping === GCPKubernetesResourceType.CLUSTER &&
      props.resourceType !== GCPKubernetesResourceType.CLUSTER) ||
    (props.initialGrouping === GCPKubernetesResourceType.NAMESPACE &&
      props.resourceType !== GCPKubernetesResourceType.NAMESPACE)
      ? "totalBytes"
      : "usedOverRequestedBytes",
    (props.initialGrouping === GCPKubernetesResourceType.CLUSTER &&
      props.resourceType !== GCPKubernetesResourceType.CLUSTER) ||
    (props.initialGrouping === GCPKubernetesResourceType.NAMESPACE &&
      props.resourceType !== GCPKubernetesResourceType.NAMESPACE)
      ? "totalCores"
      : "usedOverRequestedCores",

    props.resourceType === GCPKubernetesResourceType.CLUSTER ||
    props.dataSource === DataSource.KUBERNETES_NODE_USAGE
      ? ""
      : "projectID",
    props.selectedProjectID ? "projectID" : "",
  ];

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "drillDown",
        NoAccessorCell: function renderButton({ row }) {
          const drillDownOptions = [
            ...(props.resources.length === 1
              ? []
              : [
                  {
                    label: tooltipText.detail,
                    onClick: () => {
                      props.onInteraction({
                        type: GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED,
                        name: row.original.name,
                        projectID: row.original.projectID,
                      });
                    },
                  },
                ]),
            ...(props.resourceType === GCPKubernetesResourceType.WORKLOAD
              ? []
              : [
                  {
                    label: tooltipText.drilldown,
                    onClick: () => {
                      props.onInteraction({
                        type: GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED,
                        name: row.original.name,
                        projectID: row.original.projectID,
                      });
                    },
                  },
                ]),
          ];

          return (
            <Flex alignItems="center">
              {
                <Dropdown options={drillDownOptions} placement="bottom-start">
                  <Button
                    iconStart={<Icon icon={faEllipsisV} />}
                    primary
                    size="tiny"
                  />
                </Dropdown>
              }
            </Flex>
          );
        },
        disableSortBy: true,
        Header: () =>
          props.isUpdating ? (
            <LoadingSpinner color={theme.table_header_text_color} size="lg" />
          ) : null,
        width: 40,
      },
      {
        accessor: "name",
        Header: copyText.gkeResourceListHeaderName,
        truncate: true,
        width: 200,
      },
      {
        accessor: "projectID",
        Header: copyText.gkeResourceListHeaderProjectID,
        width: 200,
      },
      {
        accessor: "totalCoresCost",
        Header: copyText.gkeResourceListHeaderCPUCost,
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        sortType: "number",
        width: 120,
        sortDescFirst: true,
      },
      {
        accessor: "cpuUtilization",
        Cell: ({ value }) => (
          <>
            {value > 0
              ? `${value.toFixed(2)}%`
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        Header: copyText.gkeResourceListHeaderCPUUtilization,
        sortType: "number",
        sortDescFirst: true,
        width: 110,
      },
      {
        id: "usedOverRequestedCores",
        NoAccessorCell: ({ row }) => (
          <>{`${row.original.usedCores.toFixed(
            2
          )} / ${row.original.totalCores.toFixed(2)}`}</>
        ),
        Header: copyText.gkeResourceListHeaderUsedOverRequestedCores,
        sortDescFirst: true,
        sortType: (rowA, rowB) =>
          rowA.original.totalCores > rowB.original.totalCores ? 1 : -1,
        width: 200,
      },
      {
        accessor: "totalCores",
        Cell: ({ value }) => <>{value.toFixed(2)}</>,
        Header:
          props.resourceType === GCPKubernetesResourceType.CLUSTER
            ? copyText.gkeResourceListHeaderTotalCores
            : copyText.gkeResourceListHeaderTotalRequestedCores,
        sortDescFirst: true,
        width: 120,
      },
      {
        accessor: "totalBytesCost",
        Header: copyText.gkeResourceListHeaderMemoryCost,
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        sortType: "number",
        sortDescFirst: true,
        width: 140,
      },
      {
        accessor: "memoryUtilization",
        Cell: ({ value }) => (
          <>
            {value > 0
              ? `${value.toFixed(2)}%`
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        Header: copyText.gkeResourceListHeaderMemoryUtilization,
        sortType: "number",
        sortDescFirst: true,
        width: 130,
      },
      {
        id: "usedOverRequestedBytes",
        NoAccessorCell: ({ row }) => (
          <>{`${prettyBytes(row.original.usedBytes)} / ${prettyBytes(
            row.original.totalBytes
          )}`}</>
        ),
        Header: copyText.gkeResourceListHeaderUsedOverRequestedBytes,
        sortDescFirst: true,
        sortType: (rowA, rowB) =>
          rowA.original.totalBytes > rowB.original.totalBytes ? 1 : -1,
        width: 200,
      },
      {
        accessor: "totalBytes",
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        Header:
          props.resourceType === GCPKubernetesResourceType.CLUSTER
            ? copyText.gkeResourceListHeaderTotalBytes
            : copyText.gkeResourceListHeaderTotalRequestedBytes,
        sortDescFirst: true,
        width: 110,
      },
      {
        accessor: "waste",
        Cell: ({ value }) => (
          <>
            {!isNaN(value)
              ? formatCurrency({ number: value })
              : copyText.gkeResourceListLabelNotAvailable}
          </>
        ),
        Header: copyText.gkeResourceListHeaderWaste,
        sortDescFirst: true,
        sortType: (rowA, rowB, key, desc) => {
          const max = desc
            ? Number.NEGATIVE_INFINITY
            : Number.POSITIVE_INFINITY;

          const wasteA = isNaN(rowA.original.waste) ? max : rowA.original.waste;
          const wasteB = isNaN(rowB.original.waste) ? max : rowB.original.waste;

          return wasteA > wasteB ? 1 : -1;
        },
        width: 142,
      },
    ],
    [
      props.isUpdating,
      props.resourceType,
      props.initialGrouping,
      props.dataSource,
      props.resources,
    ]
  );

  return (
    <TableLegacy
      columns={columns}
      data={props.resources}
      initialState={{
        sortBy: [props.initialSortBy ?? { id: "totalCoresCost", desc: true }],
        hiddenColumns: hiddenColumns,
      }}
      isLoading={
        props.isLoading || (props.isUpdating && !props.resources.length)
      }
      selectedRowID={props.selectedResourceID}
      showPagination
      sortable
      onChangeSortBy={(sortRules) =>
        props.onInteraction({
          type: GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY,
          sortRule: sortRules[0] ?? null,
        })
      }
    />
  );
}

function getTooltipText(dataSource, initialGrouping, resourceType) {
  const tooltipText = {
    detail: "",
    drilldown: "",
  };

  if (dataSource === DataSource.KUBERNETES_NODE_USAGE) {
    tooltipText.detail = copyText.gkeResourceListTooltipLabelViewCluster;
    tooltipText.drilldown = copyText.gkeResourceListTooltipLabelViewNamespaces;
  } else {
    if (initialGrouping === GCPKubernetesResourceType.CLUSTER) {
      if (resourceType === GCPKubernetesResourceType.NAMESPACE) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewNamespace;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewWorkloads;
      } else {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewWorkloads;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewNamespaces;
      }
    } else {
      if (resourceType === GCPKubernetesResourceType.NAMESPACE) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewNamespace;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewClusters;
      } else {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewWorkload;
      }
      if (resourceType === GCPKubernetesResourceType.CLUSTER) {
        tooltipText.detail = copyText.gkeResourceListTooltipLabelViewCluster;
        tooltipText.drilldown =
          copyText.gkeResourceListTooltipLabelViewWorkloads;
      }
    }
  }
  return tooltipText;
}

GCPKubernetesResourceTable.TABLE_ROW_LIMIT = 10_000 as const;

GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY =
  "GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY" as const;
GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED =
  "GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED" as const;
GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED =
  "GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED" as const;

interface InteractionChangeSortBy {
  type: typeof GCPKubernetesResourceTable.INTERACTION_CHANGE_SORT_BY;
  sortRule: SortRule | null;
}

interface InteractionDrillDownClicked {
  type: typeof GCPKubernetesResourceTable.INTERACTION_DRILL_DOWN_CLICKED;
  name: string;
  projectID: string;
}

interface InteractionViewResourceClicked {
  type: typeof GCPKubernetesResourceTable.INTERACTION_VIEW_RESOURCE_CLICKED;
  name: string;
  projectID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPKubernetesResourceTable {
  export type Interaction =
    | InteractionChangeSortBy
    | InteractionDrillDownClicked
    | InteractionViewResourceClicked;
}

export default GCPKubernetesResourceTable;
