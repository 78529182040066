import { useQuery } from "@tanstack/react-query";
import { FindAwsReservedInstancesRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAwsReservedInstancesRecommendationsQuery";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

export default function useGetAWSReservedInstanceRecommendations(
  tenantID: string,
  options?: UseQueryOptions<
    FindAwsReservedInstancesRecommendationsQueryResult["reservedInstanceRecommendations"],
    CoreAPIClientError
  >
): UseQueryResult<
  FindAwsReservedInstancesRecommendationsQueryResult["reservedInstanceRecommendations"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const enabled =
    options?.enabled === undefined
      ? gatekeeper.canListRecommendations
      : options.enabled && gatekeeper.canListRecommendations;

  return useQuery({
    queryKey: ["awsReservedInstanceRecommendations"],
    queryFn: () => client.getAWSReservedInstanceRecommendations(tenantID),
    enabled,
    ...options,
  });
}
