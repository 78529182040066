export const azureVMComputeMeasures = {
  absoluteCredits: "absoluteCredits",
  cost: "cost",
  cpuUtilizationAvg: "cpuUtilizationAvg",
  cpuUtilizationMax: "cpuUtilizationMax",
  cpuUtilizationMin: "cpuUtilizationMin",
  diskReadBytes: "diskReadBytes",
  diskWriteBytes: "diskWriteBytes",
  instanceCount: "instanceCount",
  instanceCountWithCPUMetrics: "instanceCountWithCPUMetrics",
  instanceCountWithDiskMetrics: "instanceCountWithDiskMetrics",
  instanceCountWithMemoryMetrics: "instanceCountWithMemoryMetrics",
  instanceCountWithNetworkMetrics: "instanceCountWithNetworkMetrics",
  productMemoryBytes: "productMemoryBytes",
  productVCPU: "productVCPU",
  ramAvailableBytesAvg: "ramAvailableBytesAvg",
  ramAvailableBytesMax: "ramAvailableBytesMax",
  ramAvailableBytesMin: "ramAvailableBytesMin",
  receivedBytes: "receivedBytes",
  sentBytes: "sentBytes",
  usageAmount: "usageAmount",
} as const;

export const azureVMComputeDimensions = {
  blockStorageClass: "blockStorageClass",
  blockStorageRedundancy: "blockStorageRedundancy",
  blockStorageType: "blockStorageType",
  computeCategory: "computeCategory",
  family: "family",
  instanceId: "instanceId",
  instanceName: "instanceName",
  instanceType: "instanceType",
  location: "location",
  operatingSystem: "operatingSystem",
  projectId: "projectId",
} as const;

export type AzureVMComputeSpendSummaryEntity = {
  totalCost: number;
};

export type AzureComputeInstanceGroupFilters = {
  blockStorageClass: string | null;
  blockStorageType: string | null;
  family: string | null;
  instanceType: string | null;
  location: string | null;
  operatingSystem: string | null;
  projectId: string | null;
};

export type AzureVMComputeInstance = {
  blockStorageClass: string;
  blockStorageType: string;
  family: string;
  instanceId: string;
  instanceName: string;
  instanceType: string;
  location: string;
  operatingSystem: string;
  projectId: string;
  cost: number;
  diskReadBytes: number;
  diskWriteBytes: number;
  productMemoryBytes: number;
  productVCPU: number;
  receivedBytes: number;
  sentBytes: number;
  usageAmount: number;
  cpuUtilizationAvg: number;
  cpuUtilizationMax: number;
  cpuUtilizationMin: number;
  ramAvailableBytesAvg: number;
  ramAvailableBytesMax: number;
  ramAvailableBytesMin: number;
};

export type AzureVMComputeInstanceGroup = {
  blockStorageClass: string;
  blockStorageType: string;
  cost: number;
  cpuUtilizationAvg: number;
  cpuUtilizationMax: number;
  cpuUtilizationMin: number;
  diskReadBytes: number;
  diskWriteBytes: number;
  family: string;
  instanceCount: number;
  instanceType: string;
  location: string;
  operatingSystem: string;
  productMemoryBytes: number;
  productVCPU: number;
  projectId: string;
  ramAvailableBytesAvg: number;
  ramAvailableBytesMax: number;
  ramAvailableBytesMin: number;
  receivedBytes: number;
  sentBytes: number;
  usageAmount: number;
};
