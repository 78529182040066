import { TenantEntity } from "@ternary/api-lib/core/types";
import paths from "../constants/paths";
import { TENANT_ID_KEY } from "../constants/searchParams";

const DATE_FORMAT_LOCALE_QUERY_PARAM = "date_format_locale";
const DATE_FORMAT_LOCALE_STORAGE_KEY = "date_format_locale";
const TENANT_ID_STORAGE_KEY = "tenantID";

export function clearFieldBySubstring(substring: string): void {
  for (let i = 0; i < window.localStorage.length; ++i) {
    const key = window.localStorage.key(i);

    if (!key) continue;

    if (key.includes(substring)) {
      window.localStorage.removeItem(key);
    }
  }
}

export function getDateFormatLocale(): string | null {
  return storage.getItem(DATE_FORMAT_LOCALE_STORAGE_KEY);
}

export function getQueryParams(): string {
  return window.location.search;
}

export function getTenantID(): string | undefined {
  const params = new URLSearchParams(window.location.search);
  const tenantID = params.get(TENANT_ID_KEY);

  return tenantID ?? undefined;
}

export function getTenant(tenants: TenantEntity[]): TenantEntity {
  const params = new URLSearchParams(window.location.search);

  const localStorageTenantID = storage.getItem(TENANT_ID_STORAGE_KEY);

  const tenantID = params.get(TENANT_ID_KEY) ?? localStorageTenantID;

  let tenant: TenantEntity | undefined;

  // Attempt to locate the desired, prior tenant in the permissioned tenants list for the user,
  // if one was specified.
  if (tenantID) {
    tenant = tenants.find(
      (tenant) => tenant.id === tenantID || tenant.fsDocID === tenantID
    );
  }

  // Whether this was because a tenant ID was not memorized or because it was
  // not found in the list, choose the first permissioned tenant as a fall back.
  if (!tenant) {
    tenant = tenants[0];
  }

  if (!tenant) {
    throw new Error("NO_ACCESS_TO_TENANTS");
  }

  if (!tenantID || localStorageTenantID !== tenant.id) {
    storage.setItem(TENANT_ID_STORAGE_KEY, tenant.id);
  }

  return tenant;
}

export function setQueryParams(queryParams: string): void {
  window.location.search = queryParams;
}

export function setDateFormatLocale(): void {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has(DATE_FORMAT_LOCALE_QUERY_PARAM)) {
    const dateFormat = urlParams.get(DATE_FORMAT_LOCALE_QUERY_PARAM);

    if (!dateFormat) return;

    storage.setItem(DATE_FORMAT_LOCALE_STORAGE_KEY, dateFormat);
  }
}

export function updateTenantID(tenantID: string): void {
  const query = new URLSearchParams();

  query.set(TENANT_ID_KEY, tenantID);

  const before = new URL(window.location.href);

  let pathname: string;

  switch (true) {
    case before.pathname.includes("/cases/"):
      pathname = paths._cases;
      break;
    case before.pathname.includes("/dashboards/"):
      pathname = paths._dashboards;
      break;
    case before.pathname.includes("/report-builder/"):
      pathname = paths._reportBuilderNew;
      break;
    default:
      pathname = before.pathname;
  }

  const after = before.origin + pathname + "?" + query.toString();

  window.localStorage.setItem("tenantID", tenantID);
  window.history.pushState({}, document.title, after);
  window.location.reload();
}

export const storage = {
  getItem: (key: string): string | null => window.localStorage.getItem(key),
  setItem: (key: string, value: string): void =>
    window.localStorage.setItem(key, value),
  remove: (key: string) => window.localStorage.removeItem(key),
};

export type Storage = typeof storage;
