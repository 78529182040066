import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import TextInput from "@/ui-lib/components/TextInput";
import IconExport from "@/ui-lib/icons/IconExport";
import { useTheme } from "@emotion/react";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import copyText from "../../copyText";
import { AWSDatabaseInstanceGroupFilters } from "../types";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  debouncedSearchText: string;
  instanceGroupFilters: AWSDatabaseInstanceGroupFilters;
  searchText: string;
  onInteraction: (
    interaction: AWSDatabaseInstanceGroupTableControls.Interaction
  ) => void;
};

function AWSDatabaseInstanceGroupTableControls(props: Props) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {props.instanceGroupFilters.family !== null ? (
          <Chip
            label={copyText.awsDatabaseTableInstanceGroupHeader_family}
            text={props.instanceGroupFilters.family}
            onClick={() =>
              props.onInteraction({
                type: AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "family",
              })
            }
          />
        ) : null}

        {props.instanceGroupFilters.region !== null ? (
          <Chip
            label={copyText.awsDatabaseTableInstanceGroupHeader_region}
            text={props.instanceGroupFilters.region}
            onClick={() =>
              props.onInteraction({
                type: AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "region",
              })
            }
          />
        ) : null}

        {props.instanceGroupFilters.lineItemUsageAccountId !== null ? (
          <Chip
            label={
              copyText.awsDatabaseTableInstanceGroupHeader_lineItemUsageAccountId
            }
            text={props.instanceGroupFilters.lineItemUsageAccountId}
            onClick={() =>
              props.onInteraction({
                type: AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "lineItemUsageAccountId",
              })
            }
          />
        ) : null}

        {props.instanceGroupFilters.databaseType !== null ? (
          <Chip
            label={copyText.awsDatabaseTableInstanceGroupHeader_databaseType}
            text={props.instanceGroupFilters.databaseType}
            onClick={() =>
              props.onInteraction({
                type: AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "databaseType",
              })
            }
          />
        ) : null}

        {props.instanceGroupFilters.familyType !== null ? (
          <Chip
            label={copyText.awsDatabaseTableInstanceGroupHeader_familyType}
            text={props.instanceGroupFilters.familyType}
            onClick={() =>
              props.onInteraction({
                type: AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED,
                filterKey: "familyType",
              })
            }
          />
        ) : null}
      </Flex>

      <Box marginHorizontal={theme.space_md}>
        <TextInput
          iconEnd={
            props.searchText === props.debouncedSearchText ||
            props.searchText === "" ? (
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            ) : (
              <LoadingSpinner />
            )
          }
          placeholder={copyText.searchInputPlaceholder}
          size="large"
          value={props.searchText}
          onChange={(e) =>
            props.onInteraction({
              type: AWSDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED,
              searchText: e.target.value,
            })
          }
        />
      </Box>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`AWS-compute-instance-groups-${format(
            new Date(),
            "MM-dd-yyyy"
          )}`}
        >
          <Button iconStart={<IconExport />} secondary size="small">
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}

type ChipProps = {
  text: string;
  label: string;
  onClick: () => void;
};

function Chip(props: ChipProps) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.primary_color_background}
      backgroundColorOnHover={theme.tag_button_background_color_hover}
      borderRadius={theme.borderRadius_3}
      cursor="pointer"
      marginRight={theme.space_sm}
      paddingHorizontal={theme.space_sm}
      paddingRight={theme.space_sm}
      paddingVertical={theme.space_xxs}
      position="relative"
      onClick={props.onClick}
    >
      <Box>
        <Box height={8}>
          <Text
            bold
            color={theme.text_color_inverse}
            fontSize={theme.fontSize_xs}
          >
            {props.label}:
          </Text>
        </Box>

        <Box paddingLeft={theme.space_xxs}>
          <Text color={theme.text_color_inverse}>
            {props.text || copyText.awsDatabaseTableNull}
          </Text>
        </Box>
      </Box>

      <Box paddingLeft={theme.space_sm}>
        <Icon color={theme.text_color_inverse} icon={faTimes} size="xs" />
      </Box>
    </Flex>
  );
}

AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED =
  `AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED` as const;

AWSDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED =
  `AWSDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED` as const;

interface InteractionRemoveFilterClicked {
  type: typeof AWSDatabaseInstanceGroupTableControls.INTERACTION_REMOVE_FILTER_CLICKED;
  filterKey: keyof AWSDatabaseInstanceGroupFilters;
}

interface InteractionSearchTextUpdated {
  type: typeof AWSDatabaseInstanceGroupTableControls.INTERACTION_SEARCH_TEXT_UPDATED;
  searchText: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSDatabaseInstanceGroupTableControls {
  export type Interaction =
    | InteractionRemoveFilterClicked
    | InteractionSearchTextUpdated;
}
export default AWSDatabaseInstanceGroupTableControls;
