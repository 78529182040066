import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGCPCloudSQL(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 128 128">
        <g>
          <path
            d="M126.47,58.12l-26.3-45.74A11.56,11.56,0,0,0,90.31,6.5H37.7a11.55,11.55,0,0,0-9.86,5.88L1.53,58a11.48,11.48,0,0,0,0,11.44l26.3,46a11.77,11.77,0,0,0,9.86,6.09H90.3a11.73,11.73,0,0,0,9.87-6.06l26.3-45.74A11.73,11.73,0,0,0,126.47,58.12Z"
            fill="#4285f4"
          />
          <path
            d="M74,38.86l-3.79,5.83L61,35.48l-2.2,8.92L54,49l9.8,9.8.14,9.13-8.57,2.32L46,68.53l-8.91,8.24,4.85,4.85L40.09,82,79.62,121.5H90.3a11.73,11.73,0,0,0,9.87-6.06l18.41-32Z"
            opacity="0.070000000298023;isolation: isolate"
          />
          <polygon
            points="83.49 72.61 90.8 76.83 91.38 69.08 82.6 64.01 76.9 67.71 67.87 62.5 67.87 52.12 74 48.99 74 38.85 67 35.48 67 43.92 64 45.61 61 43.92 61 35.48 54 38.85 54 48.99 60.06 52.08 60.06 62.5 51.06 67.69 45.29 63.95 36.51 69.02 37.09 76.77 44.4 72.55 47.36 74.3 47.4 77.74 40.09 81.97 46.51 86.34 55.29 81.27 54.94 74.48 63.96 69.27 72.96 74.46 72.6 81.33 81.38 86.4 87.8 82.03 80.49 77.81 80.53 74.36 83.49 72.61"
            fill="#fff"
          />
        </g>
      </svg>
    </Icon>
  );
}
