import { useTheme } from "@emotion/react";
import React from "react";
import { Text } from "recharts";

type Props = {
  value: string;
};

type RechartsProps = {
  offset?: {
    bottom: number;
    height: number;
    top: number;
  };
};

export default function YAxisLabel(props: Props & RechartsProps) {
  const theme = useTheme();
  const offset = props.offset;

  if (!offset) return null;

  const y = offset.height / 2 + offset.top;

  return (
    <Text
      angle={-90}
      fontFamily={"Ternary"}
      fontSize={theme.fontSize_ui}
      fontWeight={theme.fontWeight_thin}
      letterSpacing={1.125}
      stroke={theme.text_color_secondary}
      textAnchor="middle"
      verticalAnchor="start"
      x={2}
      y={y}
    >
      {props.value}
    </Text>
  );
}
