import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { UpdateRuleSetParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { ruleSetID: string } & UpdateRuleSetParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { ruleSetID: string } & UpdateRuleSetParams
>;

export default function useUpdateRuleSet(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ ruleSetID, ...params }) =>
      client.updateRuleSet(ruleSetID, params),
    ...options,
  });
}
