export const bigQueryCostMeasures = {
  computeCost: "computeCost",
  storageCost: "storageCost",
  egressCost: "egressCost",
  streamingInsertCost: "streamingInsertCost",
} as const;

export const bigQueryCostDimensions = {
  projectID: "projectId",
} as const;

export const bigQueryReservationUsageMeasures = {
  baselineSlots: "baselineSlots",
  jobCount: "jobCount",
  maxSlots: "maxSlots",
  potentialSlotS: "potentialSlotS",
  usedSlotS: "usedSlotS",
  utilization: "utilization",
} as const;

export const bigQueryReservationUsageDimensions = {
  adminProjectId: "adminProjectId",
  adminProjectNumber: "adminProjectNumber",
  datasetId: "datasetId",
  datasetProjectId: "datasetProjectId",
  edition: "edition",
  jobProjectId: "jobProjectId",
  region: "region",
  reservationId: "reservationId",
  reservationName: "reservationName",
} as const;

export const bigQueryStorageMeasures = {
  activeLogicalB: "activeLogicalB",
  longTermLogicalB: "longTermLogicalB",
  activePhysicalB: "activePhysicalB",
  longTermPhysicalB: "longTermPhysicalB",
} as const;

export const bigQueryUsageDimensions = {
  datasetID: "datasetId",
  datasetProjectID: "datasetProjectId",
  jobType: "jobType",
  priority: "priority",
  projectID: "projectId",
  region: "region",
  reservationID: "reservationId",
  statementType: "statementType",
  tableID: "tableId",
  userEmail: "userEmail",
} as const;

export const bigQueryUsageMeasures = {
  avgJobDuration: "avgJobDuration",
  jobCount: "jobCount",
  jobDuration: "jobDuration",
  maxJobSlots: "maxJobSlots",
  onDemandCost: "onDemandCost",
  totalBytesBilled: "totalBytesBilled",
  totalSlotMs: "totalSlotMs",
  totalSlotS: "totalSlotS",
  reservationCount: "reservationCount",
} as const;
