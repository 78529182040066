import React from "react";
import { LegendProps } from "recharts";
import ChartDataManager from "../utils/ChartDataManager";
import LegendSimple from "./LegendSimple";
import LegendTable from "./LegendTable";

type Props = {
  align?: "center" | "left";
  dataManager: ChartDataManager;
  height?: number;
  isServer?: boolean;
  readableKeys?: { [key: string]: string };
  onInteraction: (
    interaction: LegendTable.Interaction | LegendSimple.Interaction
  ) => void;
} & LegendProps;

export default function Legend(props: Props) {
  if (!props.payload) return null;

  const measureCount = props.dataManager.measures.length;
  const dimensionCount = props.dataManager.dimensions.length;

  const columnCount = measureCount + dimensionCount;

  if (columnCount < 4 || dimensionCount <= 1) {
    return <LegendSimple {...props} />;
  }

  return <LegendTable {...props} />;
}
