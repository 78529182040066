import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";
import { UpdateCustomLabelParams } from "../types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { customLabelID: string } & UpdateCustomLabelParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { customLabelID: string } & UpdateCustomLabelParams
>;

export default function useUpdateCustomLabel(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: async ({ customLabelID, ...params }) =>
      client.updateCustomLabel(customLabelID, params),
    ...options,
  });
}
