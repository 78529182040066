import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useState } from "react";
import isEmail from "validator/lib/isEmail";
import externalLinks from "../../../constants/externalLinks";
import { Input } from "../../../types";
import { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import TextInput from "../../../ui-lib/components/TextInput";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";

const INPUT_EMAIL = "email";
const INPUT_API_TOKEN = "apiToken";
const INPUT_ISSUE_TRANSITION_ID = "issueTransitionID";

type Integration = {
  apiTokenPreview: string;
  email: string;
  issueTransitionID: string | null;
};

interface Props {
  integration: Integration;
  isProcessing: boolean;
  onInteraction: (
    interaction: UpdateJiraIntegrationModalForm.Interaction
  ) => void;
}

interface State {
  apiTokenInput: Input<string>;
  emailInput: Input<string>;
  issueTransitionIDInput: Input<string>;
}

function UpdateJiraIntegrationModalForm(props: Props) {
  const [state, setState] = useState<State>({
    apiTokenInput: { value: "", isValid: true, hasChanged: false },
    emailInput: {
      value: props.integration.email,
      isValid: true,
      hasChanged: false,
    },
    issueTransitionIDInput: {
      value: props.integration.issueTransitionID ?? "",
      isValid: true,
      hasChanged: false,
    },
  });

  const mergeState = getMergeState(setState);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let isValid = false;
    let hasChanged = true;

    switch (name) {
      case INPUT_API_TOKEN: {
        isValid = value.length > 0;
        break;
      }
      case INPUT_EMAIL: {
        isValid = isEmail(value);
        hasChanged = !isEqual(value, props.integration.email);
        break;
      }
      case INPUT_ISSUE_TRANSITION_ID: {
        isValid = true;
        hasChanged = !isEqual(value, props.integration.issueTransitionID);
        break;
      }
    }

    mergeState({ [`${name}Input`]: { value, isValid, hasChanged } });
  }

  function handleSubmit() {
    props.onInteraction({
      type: UpdateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      ...(state.apiTokenInput.hasChanged
        ? { apiToken: state.apiTokenInput.value }
        : {}),
      ...(state.emailInput.hasChanged ? { email: state.emailInput.value } : {}),
      ...(state.issueTransitionIDInput.hasChanged
        ? {
            issueTransitionID:
              state.issueTransitionIDInput.value.length === 0
                ? null
                : state.issueTransitionIDInput.value,
          }
        : {}),
    });
  }

  //
  // Render
  //

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  // TODO: Get docs for this
  const externalLinkCaption = (
    <Flex alignItems="center">
      <Text whiteSpace="pre">{copyText.issueTransitionIDInputCaption}</Text>
      {/* <a
        href={externalLinks.readmeJiraIntegrationDocumentation}
        rel="noreferrer"
        target="_blank"
      >
        {copyText.issueTransitionIDInputCaptionLink}
      </a> */}
    </Flex>
  );

  return (
    <Modal
      isOpen
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Text appearance="h3">{copyText.jiraIntegrationTitle}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          name={INPUT_EMAIL}
          autoFocus
          input={TextInput}
          label={copyText.emailInputLabel}
          value={state.emailInput.value}
          onChange={handleChange}
        />
        <FormField
          name={INPUT_API_TOKEN}
          input={TextInput}
          label={copyText.apiTokenInputLabel}
          placeholder={props.integration.apiTokenPreview}
          value={state.apiTokenInput.value}
          onChange={handleChange}
        />
        <FormField
          name={INPUT_ISSUE_TRANSITION_ID}
          caption={externalLinkCaption}
          input={TextInput}
          label={copyText.issueTransitionIDInputLabel}
          value={state.issueTransitionIDInput.value}
          onChange={handleChange}
        />
        <Flex alignItems="center">
          <Text whiteSpace="pre">{copyText.jiraDocumentationCaption}</Text>
          <a
            href={externalLinks.readmeJiraIntegrationDocumentation}
            rel="noreferrer"
            target="_blank"
          >
            {"here."}
          </a>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          secondary
          width={100}
          onClick={() =>
            props.onInteraction({
              type: UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED,
            })
          }
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? (
            <LoadingSpinner />
          ) : (
            copyText.configureButtonLabel
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED =
  "UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
UpdateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED =
  "UpdateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof UpdateJiraIntegrationModalForm.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionSubmitButtonClicked = {
  type: typeof UpdateJiraIntegrationModalForm.INTERACTION_SUBMIT_BUTTON_CLICKED;
  apiToken?: string;
  email?: string;
  issueTransitionID?: string | null;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace UpdateJiraIntegrationModalForm {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionSubmitButtonClicked;
}

export default UpdateJiraIntegrationModalForm;
