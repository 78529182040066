import SuperiorSelect from "@/components/SuperiorSelect";
import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useState } from "react";
import copyText from "../../copyText";
import {
  BILLING_ACCOUNT_ID_REGEX,
  BQ_FIELDS_REGEX,
  GCP_PROJECT_ID_REGEX,
  getErrorMessageIfPresent,
  validBigQueryLocations,
} from "./GCPIntegrationForm";
import { BillingExportSource, GCPIntegration } from "./types";

interface Props {
  loading: boolean;
  integration?: GCPIntegration;
  onUpdate: (
    billingAccountId: string,
    cudSharingEnabled: boolean,
    table: BillingExportSource
  ) => void;
}

interface State {
  inputBillingAccountId: string;
  inputCommitmentsSharingEnabled: boolean;
  inputExportDatasetId: string;
  inputExportProjectId: string;
  inputExportTableId: string;
  inputExportLocation: string;

  // Used only to handle partial text input for SuperiorSelect and not passed anywhere
  inputExportLocationText: string;
}

const initialState: State = {
  inputBillingAccountId: "",
  inputCommitmentsSharingEnabled: false,
  inputExportDatasetId: "",
  inputExportProjectId: "",
  inputExportTableId: "",
  inputExportLocation: "US",
  inputExportLocationText: "",
};

export default function GCPCloudFormBilling(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (!props.integration) {
      mergeState(initialState);
      return;
    }

    const exportSource = props.integration.config.billingExportSource;

    mergeState({
      inputBillingAccountId: props.integration.config.billingAccountID,
      inputCommitmentsSharingEnabled:
        props.integration.config.cudSharingEnabled,
      inputExportDatasetId: exportSource ? exportSource.datasetID : "",
      inputExportProjectId: exportSource ? exportSource.projectID : "",
      inputExportTableId: exportSource ? exportSource.tableID : "",
      inputExportLocation: exportSource ? exportSource.location : "",
    });
  }, [props.integration]);

  useEffect(() => {
    // Update parent's state with higher order values
    props.onUpdate(
      state.inputBillingAccountId,
      state.inputCommitmentsSharingEnabled,
      toBigQueryTable(state)
    );
  }, [
    state.inputBillingAccountId,
    state.inputCommitmentsSharingEnabled,
    state.inputExportProjectId,
    state.inputExportDatasetId,
    state.inputExportTableId,
    state.inputExportLocation,
  ]);

  let billAccessError: string | undefined;

  if (props.integration) {
    billAccessError = getErrorMessageIfPresent(
      props.integration,
      CloudCapability.BILL_DATA
    );
  }

  return (
    <Box height={416} padding={theme.space_xs} scrollable>
      {billAccessError && (
        <Box
          backgroundColor={theme.feedback_warn_background}
          borderRadius={theme.borderRadius_2}
          marginBottom={theme.space_sm}
          padding={theme.space_sm}
        >
          <Text>{billAccessError}</Text>
        </Box>
      )}
      <FormField
        input={TextInput}
        caption={copyText.cloudHelpBillingAccountID}
        label={copyText.cloudAttributeBillingAccountId}
        placeholder={copyText.cloudPlaceholderBillingAccountId}
        value={state.inputBillingAccountId}
        variant={
          BILLING_ACCOUNT_ID_REGEX.test(state.inputBillingAccountId)
            ? "success"
            : "danger"
        }
        onChange={(e) => mergeState({ inputBillingAccountId: e.target.value })}
      />
      <FormField
        input={TextInput}
        caption={copyText.cloudHelpExportProjectId}
        label={copyText.cloudAttributeExportProjectId}
        value={state.inputExportProjectId}
        variant={
          billAccessError === undefined &&
          GCP_PROJECT_ID_REGEX.test(state.inputExportProjectId)
            ? "success"
            : "danger"
        }
        onChange={(e) => mergeState({ inputExportProjectId: e.target.value })}
      />
      <FormField
        input={TextInput}
        caption={copyText.cloudHelpExportDatasetId}
        label={copyText.cloudAttributeExportDatasetId}
        value={state.inputExportDatasetId}
        variant={
          billAccessError === undefined &&
          BQ_FIELDS_REGEX.test(state.inputExportDatasetId)
            ? "success"
            : "danger"
        }
        onChange={(e) => mergeState({ inputExportDatasetId: e.target.value })}
      />
      <FormField
        input={TextInput}
        caption={copyText.cloudHelpExportTableId}
        label={copyText.cloudAttributeExportTableId}
        value={state.inputExportTableId}
        variant={
          billAccessError === undefined &&
          BQ_FIELDS_REGEX.test(state.inputExportTableId)
            ? "success"
            : "danger"
        }
        onChange={(e) => mergeState({ inputExportTableId: e.target.value })}
      />
      <FormField
        caption={copyText.cloudHelpExportLocation}
        label={copyText.cloudAttributeExportLocation}
      >
        <SuperiorSelect
          inputValue={state.inputExportLocationText}
          isCreateable
          placeholder={copyText.cloudAttributeLabelConfigurationPlaceholder}
          suggestions={validBigQueryLocations}
          value={state.inputExportLocation ? [state.inputExportLocation] : []}
          onChange={(val: string[]) =>
            mergeState({ inputExportLocation: val ? val[0] : "" })
          }
          onInputChange={(val: string) =>
            mergeState({ inputExportLocationText: val })
          }
        />
      </FormField>
      <FormField caption={copyText.cloudHelpCommitmentsSharing} label="">
        <Flex alignItems="center" justifyContent="space-between">
          <Text>{copyText.cloudAttributeCommitmentsSharing}</Text>
          <input
            type="checkbox"
            checked={state.inputCommitmentsSharingEnabled === true}
            style={{ flexGrow: "unset" }}
            onChange={(e) =>
              mergeState({ inputCommitmentsSharingEnabled: e.target.checked })
            }
          />
        </Flex>
      </FormField>
    </Box>
  );
}

function toBigQueryTable(state: State): BillingExportSource {
  return {
    projectID: state.inputExportProjectId,
    datasetID: state.inputExportDatasetId,
    tableID: state.inputExportTableId,
    location: state.inputExportLocation,
  };
}
