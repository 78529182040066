import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import MspTenantSettingsContainer from "../../msp-management/components/MspTenantSettingsContainer";
import copyText from "../copyText";
import GlobalGrantManagementContainer from "./GlobalGrantManagementContainer";
import TenantManagementContainer from "./TenantManagementContainer";

export function MspAdminPage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  //
  // Search Params
  //

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "tenants"),
  });

  //
  // Render
  //

  if (!gatekeeper.canAccessMspAdmin) {
    return <Navigate to={paths._home} replace />;
  }

  const containers = [
    {
      component: <TenantManagementContainer />,
      label: copyText.tenantsTabLabel,
      value: "tenants",
    },
    {
      component: <GlobalGrantManagementContainer />,
      label: copyText.usersTabLabel,
      value: "users",
    },
    {
      component: <MspTenantSettingsContainer />,
      label: copyText.settingsTabLabel,
      value: "settings",
    },
  ];

  return (
    <ErrorBoundary boundaryName="MspAdminPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

export default MspAdminPage;
