import { FeatureFlag } from "@ternary/api-lib/constants/feature-flags";
import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type MspBillingInfoGates = ReturnType<typeof mspBillingInfoGates>;

export default function mspBillingInfoGates(params: GateGetterParameters) {
  const canApplyMspBillingInfo = params._featureFlagsIncludeAllOf([
    FeatureFlag.MSP_INVOICING,
  ]);
  const canReadMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.READ_MSP_BILLING_INFO,
  ]);
  const canCreateMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.CREATE_MSP_BILLING_INFO,
  ]);
  const canDeleteMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.DELETE_MSP_BILLING_INFO,
  ]);
  const canUpdateMspBillingInfo = params._permissionsIncludeAllOf([
    Permission.UPDATE_MSP_BILLING_INFO,
  ]);

  const billingManagementGates = {
    canApplyMspBillingInfo,
    canCreateMspBillingInfo,
    canDeleteMspBillingInfo,
    canReadMspBillingInfo,
    canUpdateMspBillingInfo,
  };

  const canAccessBillingInfoManagement = Object.values(
    billingManagementGates
  ).every((gate) => gate === true);

  return {
    ...billingManagementGates,
    canAccessBillingInfoManagement,
  };
}
