import { useTheme } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React, { useEffect, useState } from "react";
import { UpdateMspTenantSettingsParams } from "../../../api/core/types";
import { useMspStore } from "../../../lib/zustand";
import { Input } from "../../../types";
import Form from "../../../ui-lib/components/Form";
import Grid from "../../../ui-lib/components/Grid";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Switch from "../../../ui-lib/components/Switch";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useGetMspTenantSettingsByTenantID from "../hooks/useGetMspTenantSettingsByTenantID";
import useUpdateMspTenantSettings from "../hooks/useUpdateMspTenantSettings";

export function MspTenantSettingsContainer() {
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const mspStore = useMspStore();

  //
  // Queries
  //

  const { data: settings, isLoading: isLoadingSettings } =
    useGetMspTenantSettingsByTenantID(
      mspStore.selectedParentTenantID as string,
      { enabled: !!mspStore.selectedParentTenantID }
    );

  //
  // Mutations
  //

  const {
    isPending: isUpdatingMspTenantSettings,
    mutate: updateMspTenantSettings,
  } = useUpdateMspTenantSettings({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingMspTenantSettingsMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      if (mspStore.selectedParentTenantID) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.mspTenantSettings(
            mspStore.selectedParentTenantID
          ),
        });
      }

      postAlert({
        message: copyText.successUpdatingMspTenantSettingsMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Interaction Handlers
  //

  function handleUpdateMspTenantSettings(
    params: UpdateMspTenantSettingsParams
  ) {
    if (!settings) return;

    updateMspTenantSettings({
      settingsID: settings.id,
      allowAwsRateRecs: params.allowAwsRateRecs,
      allowAwsUsageRecs: params.allowAwsUsageRecs,
      allowAzureRateRecs: params.allowAzureRateRecs,
      allowAzureUsageRecs: params.allowAzureUsageRecs,
      allowCloudConfiguration: params.allowCloudConfiguration,
      allowGcpRateRecs: params.allowGcpRateRecs,
      allowGcpUsageRecs: params.allowGcpUsageRecs,
    });
  }

  return (
    <Flex justifyContent="center" marginTop={theme.space_xl}>
      {!settings || isLoadingSettings ? (
        <EmptyPlaceholder loading={isLoadingSettings} width={625} />
      ) : (
        <MspTenantSettingsForm
          isProcessing={isUpdatingMspTenantSettings}
          settings={settings}
          onSubmit={handleUpdateMspTenantSettings}
        />
      )}
    </Flex>
  );
}

type Settings = {
  allowAwsRateRecs: boolean;
  allowAwsUsageRecs: boolean;
  allowAzureRateRecs: boolean;
  allowAzureUsageRecs: boolean;
  allowCloudConfiguration: boolean;
  allowGcpRateRecs: boolean;
  allowGcpUsageRecs: boolean;
};

type State = {
  allowAwsRateRecsInput: Input<boolean>;
  allowAwsUsageRecsInput: Input<boolean>;
  allowAzureRateRecsInput: Input<boolean>;
  allowAzureUsageRecsInput: Input<boolean>;
  allowCloudConfigurationInput: Input<boolean>;
  allowGcpRateRecsInput: Input<boolean>;
  allowGcpUsageRecsInput: Input<boolean>;
};

interface FormProps {
  isProcessing: boolean;
  settings: Settings;
  onSubmit: (params: UpdateMspTenantSettingsParams) => void;
}

function MspTenantSettingsForm(props: FormProps) {
  const theme = useTheme();

  const initialState = getStateFromSettings(props.settings);

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  function handleChangeSwitch(name: string, value: boolean) {
    const hasChanged = props.settings[name] !== value;

    mergeState({ [`${name}Input`]: { value, isValid: true, hasChanged } });
  }

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    props.onSubmit({
      ...(state.allowAwsRateRecsInput.hasChanged
        ? { allowAwsRateRecs: state.allowAwsRateRecsInput.value }
        : {}),
      ...(state.allowAwsUsageRecsInput.hasChanged
        ? { allowAwsUsageRecs: state.allowAwsUsageRecsInput.value }
        : {}),
      ...(state.allowAzureRateRecsInput.hasChanged
        ? { allowAzureRateRecs: state.allowAzureRateRecsInput.value }
        : {}),
      ...(state.allowAzureUsageRecsInput.hasChanged
        ? { allowAzureUsageRecs: state.allowAzureUsageRecsInput.value }
        : {}),
      ...(state.allowCloudConfigurationInput.hasChanged
        ? { allowCloudConfiguration: state.allowCloudConfigurationInput.value }
        : {}),
      ...(state.allowGcpRateRecsInput.hasChanged
        ? { allowGcpRateRecs: state.allowGcpRateRecsInput.value }
        : {}),
      ...(state.allowGcpUsageRecsInput.hasChanged
        ? { allowGcpUsageRecs: state.allowGcpUsageRecsInput.value }
        : {}),
    });
  }

  // This is to be able to reset button canSubmit state after a successful update.
  useEffect(() => {
    if (!props.settings) return;

    const state = getStateFromSettings(props.settings);

    mergeState(state);
  }, [props.settings]);

  const canSubmit = Object.values(state).some((input) => input.hasChanged);

  return (
    <Form width={625}>
      <Flex alignItems="center" marginBottom={theme.space_md}>
        <Text as="h3" marginRight={theme.space_xs}>
          {copyText.headerLabelClouds}
        </Text>
        <Tooltip content={copyText.cloudsTooltipContent} width="250px">
          <Icon color={theme.text_color} icon={faInfoCircle} />
        </Tooltip>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.switchLabelAllowConfiguration}
        </Text>
        <Switch
          checked={state.allowCloudConfigurationInput.value}
          onChange={(checked) =>
            handleChangeSwitch("allowCloudConfiguration", checked)
          }
        />
      </Flex>
      <Divider color={theme.panel_backgroundColor} />
      <Flex alignItems="center" marginBottom={theme.space_md}>
        <Text as="h3" marginRight={theme.space_xs}>
          {copyText.headerLabelOptimizations}
        </Text>
        <Tooltip content={copyText.optimizationsTooltipContent} width="250px">
          <Icon color={theme.text_color} icon={faInfoCircle} />
        </Tooltip>
      </Flex>
      <Grid
        gridTemplateColumns="50% 25% 25%"
        gridTemplateRows="repeat(3, 60px)"
      >
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.formLabelAWS}
        </Text>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelRate}
          </Text>
          <Switch
            checked={state.allowAwsRateRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowAwsRateRecs", checked)
            }
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelUsage}
          </Text>
          <Switch
            checked={state.allowAwsUsageRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowAwsUsageRecs", checked)
            }
          />
        </Flex>
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.formLabelAzure}
        </Text>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelRate}
          </Text>
          <Switch
            checked={state.allowAzureRateRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowAzureRateRecs", checked)
            }
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelUsage}
          </Text>
          <Switch
            checked={state.allowAzureUsageRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowAzureUsageRecs", checked)
            }
          />
        </Flex>
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.formLabelGCP}
        </Text>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelRate}
          </Text>
          <Switch
            checked={state.allowGcpRateRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowGcpRateRecs", checked)
            }
          />
        </Flex>
        <Flex justifyContent="flex-end">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            marginRight={theme.space_xs}
          >
            {copyText.switchLabelUsage}
          </Text>
          <Switch
            checked={state.allowGcpUsageRecsInput.value}
            onChange={(checked) =>
              handleChangeSwitch("allowGcpUsageRecs", checked)
            }
          />
        </Flex>
      </Grid>
      <Flex justifyContent="flex-end" marginTop={theme.space_sm}>
        <Button
          marginRight={theme.space_md}
          secondary
          type="button"
          width={100}
          onClick={() => mergeState(initialState)}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

function getStateFromSettings(settings: Settings) {
  return {
    allowAwsRateRecsInput: {
      value: settings.allowAwsRateRecs,
      isValid: true,
      hasChanged: false,
    },
    allowAwsUsageRecsInput: {
      value: settings.allowAwsUsageRecs,
      isValid: true,
      hasChanged: false,
    },
    allowAzureRateRecsInput: {
      value: settings.allowAzureRateRecs,
      isValid: true,
      hasChanged: false,
    },
    allowAzureUsageRecsInput: {
      value: settings.allowAzureUsageRecs,
      isValid: true,
      hasChanged: false,
    },
    allowCloudConfigurationInput: {
      value: settings.allowCloudConfiguration,
      isValid: true,
      hasChanged: false,
    },
    allowGcpRateRecsInput: {
      value: settings.allowGcpRateRecs,
      isValid: true,
      hasChanged: false,
    },
    allowGcpUsageRecsInput: {
      value: settings.allowGcpUsageRecs,
      isValid: true,
      hasChanged: false,
    },
  };
}

export default MspTenantSettingsContainer;
