import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { FindRecommendationTypeQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationTypeQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type Options = UseQueryOptions<
  FindRecommendationTypeQueryResult["recommendationType"],
  CoreAPIClientError
>;

type Result = UseQueryResult<
  FindRecommendationTypeQueryResult["recommendationType"],
  CoreAPIClientError
>;

export default function useGetRecommendationTypeByID(
  recommendationTypeID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["recommendations", "types", recommendationTypeID],
    queryFn: () => client.getRecommendationTypeByID(recommendationTypeID),
    ...options,
  });
}
