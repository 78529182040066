import { useTheme } from "@emotion/react";
import {
  faChevronLeft,
  faEllipsis,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  ActivityType,
  CaseStatus,
  CaseType,
  ResourceType,
} from "@ternary/api-lib/constants/enums";
import systemUser from "@ternary/api-lib/constants/system";
import {
  CaseCreatedEventEntity,
  CaseEntity,
  CaseEntityUnion,
  UserEntity,
} from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import BarLoader from "@ternary/web-ui-lib/components/EmptyPlaceholder/BarLoader";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDistance } from "date-fns";
import { keyBy, noop, sortBy } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import paths from "../../../constants/paths";
import { useActivityTracker } from "../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "../../../lib/react-router";
import { Input } from "../../../types";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import MarkdownWrapper from "../../../ui-lib/components/MarkdownWrapper";
import Select from "../../../ui-lib/components/Select";
import TextArea from "../../../ui-lib/components/TextArea";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import getMergeState from "../../../utils/getMergeState";
import useGetJiraIntegrationByTenantID from "../../admin/hooks/useGetJiraIntegrationByTenantID";
import useGetReportsByIDs from "../../reporting-engine/hooks/useGetReportsByIDs";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useCreateCaseComment from "../hooks/useCreateCaseComment";
import useGetCaseByID from "../hooks/useGetCaseByID";
import useUpdateCase from "../hooks/useUpdateCase";
import useUploadImage from "../hooks/useUploadImage";
import { CaseActivity, isCaseUpdatedEvent } from "../types";
import AssigneeModal from "./AssigneeModal";
import BudgetContentContainer from "./BudgetContentContainer";
import CaseActivityList from "./CaseActivityList";
import CaseAssigneeList from "./CaseAssigneeList";
import CaseFollowersList from "./CaseFollowersList";
import CostAlertContentContainer from "./CostAlertContentContainer";
import CreateCaseCommentForm from "./CreateCaseCommentForm";
import RampPlanContentContainer from "./RampPlanContentContainer";
import RecommendationContentContainer from "./RecommendationContentContainer";
import ReportContentContainer from "./ReportContentContainer";

// NOTE: Using this to give the effect of "real time" chat.
// Can tune higher or lower if needed.
const REFETCH_INTERVAL = 15 * 1000; // 15 Seconds

const INPUT_DESCRIPTION = "description";
const INPUT_NAME = "name";
const INPUT_TYPE = "type";

const UPDATE_KEY_MUTE = "MUTE";
const UPDATE_KEY_STATUS = "STATUS";

type UpdateKey = typeof UPDATE_KEY_MUTE | typeof UPDATE_KEY_STATUS;

interface State {
  descriptionInput: Input<string>;
  isDropdownOpen: boolean;
  isEditMode: boolean;
  isModalOpen: boolean;
  nameInput: Input<string>;
  pastedImageID: string | null;
  typeInput: Input<string>;
  updateKey: UpdateKey | null;
}

const initialState: State = {
  isDropdownOpen: false,
  descriptionInput: { value: "", isValid: true, hasChanged: false },
  isEditMode: false,
  isModalOpen: false,
  nameInput: { value: "", isValid: true, hasChanged: false },
  pastedImageID: null,
  typeInput: { value: "", isValid: true, hasChanged: false },
  updateKey: null,
};

type Interaction =
  | AssigneeModal.Interaction
  | CaseFollowersList.Interaction
  | CreateCaseCommentForm.Interaction;

export default function CaseViewContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { caseID = "" } = useParams();

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: _case_,
    isLoading: isLoadingCase,
    refetch: refetchCase,
  } = useGetCaseByID(caseID, { refetchInterval: REFETCH_INTERVAL });

  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersByTenantID(
    authenticatedUser.tenant.id
  );

  const usersKeyedByID = keyBy(users, "id");

  function populateCase(_case: CaseEntity) {
    const activity = getCaseActivity(_case);

    const assignees = _case.assigneeIDs.reduce((accum: UserEntity[], id) => {
      const user = usersKeyedByID[id];

      if (!user) {
        activityTracker.captureMessage(
          `Data integrity issue detected for case ID: ${_case.id} & userID: ${id}`
        );

        return accum;
      } else {
        return [...accum, user];
      }
    }, []);

    const followers = _case.followerIDs.reduce((accum: UserEntity[], id) => {
      const user = usersKeyedByID[id];

      if (!user) {
        activityTracker.captureMessage(
          `Data integrity issue detected for case ID: ${_case.id} & userID: ${id}`
        );

        return accum;
      } else {
        return [...accum, user];
      }
    }, []);

    return { ..._case, activity, assignees, followers };
  }

  const _case = _case_ ? populateCase(_case_) : undefined;

  const reportIDs =
    _case && _case.comments
      ? _case.comments.reduce((accum: string[], comment) => {
          if (comment.reportID) {
            accum.push(comment.reportID);
          }

          return accum;
        }, [])
      : [];

  const { data: reports = [], isLoading: isLoadingReports } =
    useGetReportsByIDs(reportIDs);

  const { data: jiraIntegration, isLoading: isLoadingJiraIntegration } =
    useGetJiraIntegrationByTenantID(authenticatedUser.tenant.fsDocID);

  //
  // Mutations
  //

  const { isPending: isUpdatingCase, mutate: updateCase } = useUpdateCase({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingCaseMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPDATE_CASE);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.cases });

      postAlert({
        message: copyText.successUpdatingCaseMessage,
        type: AlertType.SUCCESS,
      });

      refetchCase();

      mergeState({
        isDropdownOpen: false,
        isEditMode: false,
        isModalOpen: false,
      });
    },
  });

  const { isPending: isCreatingCaseActivity, mutate: createCaseComment } =
    useCreateCaseComment({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingCaseCommentMessage,
          type: AlertType.ERROR,
        });
      },
      onMutate: () => {
        activityTracker.captureAction(actions.CREATE_CASE_ACTIVITY);
      },
      onSuccess: () => {
        refetchCase();
      },
    });

  const { mutate: uploadImage } = useUploadImage({
    onError: () => {
      postAlert({
        message: copyText.errorUploadingImageMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: () => {
      activityTracker.captureAction(actions.UPLOAD_IMAGE);
    },
    onSuccess: (fileID) => {
      mergeState({ pastedImageID: fileID });
    },
  });

  //
  // Side Effects
  //

  // NOTE: This is for setting case data into state inputs
  useEffect(() => {
    if (!_case_) return;

    mergeState(getInputStateFromCase(_case_));
  }, [_case_]);

  //
  // Handlers
  //

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;

    let isValid = true;
    let hasChanged = false;

    switch (name) {
      case INPUT_DESCRIPTION: {
        isValid = value.length > 0;
        hasChanged = value !== _case?.description;
        break;
      }
      case INPUT_NAME: {
        isValid = value.length > 0;
        hasChanged = value !== _case?.name;
        break;
      }
      case INPUT_TYPE: {
        hasChanged = value !== _case?.type;
        break;
      }
    }

    mergeState({ [`${name}Input`]: { value, isValid, hasChanged } });
  }

  function handleClickEditButton() {
    if (!_case) return;

    mergeState({ isEditMode: !state.isEditMode });
  }

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case AssigneeModal.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({ isModalOpen: false });
        return;
      }
      case AssigneeModal.INTERACTION_UPDATE_BUTTON_CLICKED: {
        if (!_case) return;

        updateCase({ caseID: _case.id, assigneeIDs: interaction.assigneeIDs });
        return;
      }
      case CaseFollowersList.INTERACTION_FOLLOW_BUTTON_CLICKED: {
        if (!_case) return;

        updateCase({
          caseID: _case.id,
          followerIDs: [..._case.followerIDs, authenticatedUser.id],
        });
        return;
      }
      case CaseFollowersList.INTERACTION_UNFOLLOW_BUTTON_CLICKED: {
        if (!_case) return;

        updateCase({
          caseID: _case.id,
          followerIDs: _case.followerIDs.filter(
            (id) => id !== authenticatedUser.id
          ),
        });
        return;
      }
      case CreateCaseCommentForm.INTERACTION_IMAGE_LINK_SET: {
        mergeState({ pastedImageID: null });
        return;
      }
      case CreateCaseCommentForm.INTERACTION_IMAGE_PASTED: {
        uploadImage({
          tenantID: authenticatedUser.tenant.id,
          file: interaction.file,
        });
        return;
      }
      case CreateCaseCommentForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (!_case) return;

        createCaseComment({
          caseID: _case.id,
          text: interaction.comment,
        });

        return;
      }
    }
  }

  function handleSubmitEditCase() {
    if (!_case) return;

    updateCase({
      caseID: _case.id,
      ...(state.descriptionInput.hasChanged
        ? { description: state.descriptionInput.value }
        : {}),
      ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
      ...(state.typeInput.hasChanged
        ? { type: state.typeInput.value as CaseType }
        : {}),
    });
  }

  function handleCloseCase(): void {
    if (!_case) return;

    mergeState({ isDropdownOpen: true, updateKey: UPDATE_KEY_STATUS });

    updateCase({
      caseID: _case.id,
      status:
        _case.status === CaseStatus.OPEN ? CaseStatus.CLOSED : CaseStatus.OPEN,
    });
  }

  function handleMuteCase() {
    if (!_case) return;

    mergeState({ isDropdownOpen: true, updateKey: UPDATE_KEY_MUTE });

    updateCase({
      caseID: _case.id,
      mutedIDs: _case.mutedIDs.includes(authenticatedUser.id)
        ? _case.mutedIDs.filter((userID) => userID !== authenticatedUser.id)
        : [..._case.mutedIDs, authenticatedUser.id],
    });
  }

  //
  // Render
  //

  function renderTitle() {
    if (!_case || isLoadingCase) {
      return <BarLoader height={50} width={400} />;
    }

    const distance = formatDistance(new Date(_case.createdAt), new Date());

    return (
      <Flex direction="column">
        {state.isEditMode ? (
          <Box marginBottom={theme.space_xxs} width={300}>
            <TextInput
              name={INPUT_NAME}
              placeholder={copyText.caseViewFormFieldUntitledPlaceholder}
              size="medium"
              value={state.nameInput.value}
              onChange={handleChange}
            />
          </Box>
        ) : (
          <Text appearance="h3">{state.nameInput.value}</Text>
        )}
        <Text appearance="h5" color={theme.text_color_caption}>
          {copyText.caseViewCreatedAtSubTitle.replace("%distance%", distance)}
        </Text>
      </Flex>
    );
  }

  function renderDescription() {
    if (!_case || isLoadingCase) {
      return <BarLoader height={50} width={400} />;
    }

    return state.isEditMode ? (
      <FormField
        name={INPUT_DESCRIPTION}
        input={TextArea}
        marginBottom={0}
        placeholder={copyText.caseViewFormFieldDescriptionPlaceholder}
        resizeable
        rows={4}
        value={state.descriptionInput.value}
        onChange={handleChange}
      />
    ) : (
      <MarkdownWrapper>{state.descriptionInput.value}</MarkdownWrapper>
    );
  }

  function renderContentSection() {
    if (!_case || isLoadingCase) {
      return <EmptyPlaceholder height={500} loading={isLoadingCase} />;
    }

    const caseUnion = _case as CaseEntityUnion;

    switch (caseUnion.resourceType) {
      case ResourceType.BUDGET: {
        return (
          <BudgetContentContainer
            budgetID={caseUnion.resourceID}
            budgetForecast={caseUnion.context.forecast}
            createdDate={caseUnion.createdAt}
          />
        );
      }
      case ResourceType.COST_ALERT: {
        return <CostAlertContentContainer costAlertID={caseUnion.resourceID} />;
      }
      case ResourceType.RAMP_PLAN: {
        return <RampPlanContentContainer rampPlanID={caseUnion.resourceID} />;
      }
      case ResourceType.RECOMMENDATION: {
        return (
          <RecommendationContentContainer
            recommendationID={caseUnion.resourceID}
          />
        );
      }
      case ResourceType.REPORT: {
        return (
          <ReportContentContainer
            createdDate={caseUnion.createdAt}
            reportID={caseUnion.resourceID}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  const caseTypeOptions = [
    {
      label: copyText.caseTypeInvestigation,
      value: CaseType.INVESTIGATION,
    },
    { label: copyText.caseTypeOptimization, value: CaseType.OPTIMIZATION },
    { label: copyText.caseTypeTask, value: CaseType.TASK },
  ];

  const selectedType = caseTypeOptions.find(
    (caseTypeOption) => caseTypeOption.value === state.typeInput.value
  );

  const isValid = Object.entries(state).every(([key, value]) =>
    key.endsWith("Input") ? value.isValid : true
  );

  const hasChanged = Object.entries(state).some(([key, value]) =>
    key.endsWith("Input") ? value.hasChanged : false
  );

  const canSubmit = isValid && hasChanged;

  const issueLink = `${jiraIntegration?.instanceURL}/browse/${_case?.externalIssueID}`;

  const caseUpdateOptions = [
    {
      label: (
        <Flex alignItems="center">
          {state.updateKey === UPDATE_KEY_MUTE && isUpdatingCase && (
            <Box marginRight={theme.space_xxs}>
              <LoadingSpinner />
            </Box>
          )}
          <Text>
            {_case?.mutedIDs.includes(authenticatedUser.id)
              ? copyText.dropdownLabelUnmuteCase
              : copyText.dropdownLabelMuteCase}
          </Text>
        </Flex>
      ),
      onClick: isUpdatingCase ? noop : handleMuteCase,
    },
    {
      label: (
        <Flex alignItems="center">
          {state.updateKey === UPDATE_KEY_STATUS && isUpdatingCase && (
            <Box marginRight={theme.space_xxs}>
              <LoadingSpinner />
            </Box>
          )}
          <Text>
            {_case?.status === CaseStatus.OPEN
              ? copyText.closeButtonLabel
              : copyText.openButtonLabel}
          </Text>
        </Flex>
      ),
      locked: !gatekeeper.canUpdateCase,
      onClick: handleCloseCase,
    },
  ];

  return (
    <Flex direction="column" height={"100%"}>
      {state.isModalOpen && (
        <AssigneeModal
          assigneeIDs={_case?.assigneeIDs ?? []}
          isOpen
          isProcessing={isUpdatingCase}
          users={users}
          onInteraction={handleInteraction}
        />
      )}
      <Flex justifyContent="space-between">
        <Flex alignItems="center" height={60}>
          <Button
            iconStart={<Icon icon={faChevronLeft} />}
            secondary
            marginTop={theme.space_xxs}
            marginRight={theme.space_md}
            size="small"
            onClick={() => navigate(paths._cases)}
          />
          {renderTitle()}
        </Flex>
        {/* Buttons */}
        <Flex alignItems="center">
          {_case?.externalIssueID && (
            <Button
              as="a"
              disabled={isLoadingCase || isLoadingJiraIntegration}
              href={issueLink}
              iconEnd={<Icon icon={faExternalLink} />}
              marginRight={theme.space_sm}
              secondary
              size="small"
              target="_blank"
              width={140}
              onClick={() =>
                mergeState({
                  isEditMode: false,
                  ...getInputStateFromCase(_case),
                })
              }
            >
              {copyText.externalLinkButtonLabel}
            </Button>
          )}
          {state.isEditMode ? (
            <Box>
              <Button
                marginRight={theme.space_sm}
                secondary
                size="small"
                width={100}
                onClick={() =>
                  mergeState({
                    isEditMode: false,
                    ...getInputStateFromCase(_case),
                  })
                }
              >
                {copyText.cancelButtonLabel}
              </Button>
              <Button
                disabled={!canSubmit || isUpdatingCase}
                primary
                size="small"
                width={100}
                onClick={handleSubmitEditCase}
              >
                {isUpdatingCase ? <LoadingSpinner /> : copyText.saveButtonLabel}
              </Button>
            </Box>
          ) : (
            <>
              <Button
                disabled={!_case}
                locked={!gatekeeper.canUpdateCase}
                marginRight={theme.space_sm}
                secondary
                size="small"
                width={100}
                onClick={handleClickEditButton}
              >
                {copyText.caseViewEditButtonLabel}
              </Button>
              <Dropdown
                disabled={!_case || isUpdatingCase}
                isOpen={isUpdatingCase ? isUpdatingCase : undefined}
                options={caseUpdateOptions}
                placement="bottom-end"
              >
                <Button
                  iconStart={<Icon icon={faEllipsis} />}
                  size="small"
                  secondary
                />
              </Dropdown>
            </>
          )}
        </Flex>
      </Flex>
      <Box>
        {/* Content */}
        <Flex
          height={1000}
          justifyContent="space-between"
          marginTop={theme.space_md}
        >
          <Flex
            direction="column"
            minWidth={300}
            marginRight={theme.space_sm}
            width="50%"
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              marginBottom={theme.space_md}
              padding={theme.space_lg}
            >
              <Flex direction="column">
                <Flex justifyContent="space-between" width="100%">
                  <Text
                    fontWeight={theme.fontWeight_bold}
                    marginBottom={theme.space_sm}
                  >
                    {copyText.caseViewDescriptionSectionTitle}
                  </Text>
                  <Flex justifyContent="flex-end" width={150}>
                    {state.isEditMode ? (
                      <Select
                        compact
                        isDisabled={isUpdatingCase}
                        options={caseTypeOptions}
                        value={selectedType}
                        onChange={(option) => {
                          handleChange({
                            target: {
                              name: INPUT_TYPE,
                              value: option?.value ?? "",
                            },
                          } as ChangeEvent<HTMLInputElement>);
                        }}
                      />
                    ) : (
                      <Text>{state.typeInput.value}</Text>
                    )}
                  </Flex>
                </Flex>
                <Box height={85} minHeight={85} overflowY="auto">
                  {renderDescription()}
                </Box>
              </Flex>
            </Box>
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flexGrow={1}
              marginBottom={theme.space_md}
              minHeight={500}
              overflow="auto"
              padding={theme.space_lg}
            >
              {renderContentSection()}
            </Box>
            <Flex justifyContent="space-between">
              <CaseAssigneeList
                case={_case}
                isLoading={isLoadingCase || isLoadingUsers}
                isProcessing={isUpdatingCase}
                onChange={() => mergeState({ isModalOpen: true })}
              />
              <CaseFollowersList
                case={_case}
                isLoading={isLoadingCase || isLoadingUsers}
                isProcessing={isUpdatingCase}
                onInteraction={handleInteraction}
              />
            </Flex>
          </Flex>
          <Flex
            backgroundColor={theme.panel_backgroundColor}
            borderRadius={theme.borderRadius_2}
            direction="column"
            height={1000}
            justifyContent="space-between"
            marginLeft={theme.space_sm}
            minWidth={300}
            padding={theme.space_lg}
            width="50%"
          >
            <Box>
              <Text fontWeight={theme.fontWeight_bold}>
                {copyText.caseViewActivitySectionTitle}
              </Text>
              <CaseActivityList
                activity={_case?.activity ?? []}
                isLoading={isLoadingCase || isLoadingReports || isLoadingUsers}
                reports={reports}
                users={[...users, systemUser]}
              />
            </Box>
            <CreateCaseCommentForm
              imageID={state.pastedImageID}
              isProcessing={isCreatingCaseActivity}
              onInteraction={handleInteraction}
            />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

type Case = ReturnType<typeof useGetCaseByID>["data"];

function getInputStateFromCase(_case?: Case) {
  if (!_case) return {};

  return {
    descriptionInput: {
      value: _case.description,
      isValid: true,
      hasChanged: false,
    },
    nameInput: {
      value: _case.name,
      isValid: true,
      hasChanged: false,
    },
    typeInput: {
      value: _case.type,
      isValid: true,
      hasChanged: false,
    },
  };
}

function getCaseActivity(_case: CaseEntity) {
  const events = _case.events ? sortBy(_case.events) : [];

  const createdEvent = events[0] as CaseCreatedEventEntity;

  const updatedEvents = events.filter(isCaseUpdatedEvent);

  const eventActivity: CaseActivity[] = [];

  // Get Activity from Case Events
  if (createdEvent && updatedEvents.length > 0) {
    let prevAssigneeIDs = createdEvent.data.assigneeIDs ?? [];
    let prevStatus: CaseStatus = CaseStatus.OPEN;

    for (let i = 0; i < updatedEvents.length; i++) {
      const event = updatedEvents[i];

      const baseActivity = {
        id: event.id,
        createdAt: event.createdAt,
        userID: event.invokerID,
      };

      if (event.data.assigneeIDs) {
        eventActivity.push({
          ...baseActivity,
          type: ActivityType.ASSIGNEE_CHANGE,
          prevAssigneeIDs: prevAssigneeIDs,
          newAssigneeIDs: event.data.assigneeIDs,
        });

        prevAssigneeIDs = event.data.assigneeIDs;
      } else if (event.data.status) {
        eventActivity.push({
          ...baseActivity,
          type: ActivityType.STATUS_CHANGE,
          prevStatus: prevStatus,
          newStatus: event.data.status,
        });

        prevStatus = event.data.status;
      }
    }
  }

  const comments = _case.comments ?? [];

  // Get Activity from Comments
  const commentActivity = comments.map((comment) => ({
    id: comment.id,
    createdAt: comment.createdAt,
    userID: comment.createdByID,
    type: ActivityType.COMMENT,
    reportID: comment.reportID,
    text: comment.text,
  }));

  // Merge and sort chronologically
  const sortedActivity = sortBy(
    [...commentActivity, ...eventActivity],
    "createdAt"
  );

  return sortedActivity;
}
