import { FiscalCalendar } from "@/api/core/types";
import { AzureExportType } from "@ternary/api-lib/constants/enums";

export type CostAndUsageReportInputs = {
  excludePathPrefixesInput?: string[];
  nameInput: string;
  regionInput: string | null;
  reportPathPrefixInput: string;
  s3BucketNameInput: string;
};

export type AzureBillingFields = {
  dateColumnInput: string;
  endDateInput: string;
  exportTypeInput: AzureExportType;
  serviceURLInput: string;
  startDateInput: string;
  storageContainerInput: string;
  storagePrefixInput: string;
  strictStoragePrefixInput: string;
};

//
// Fiscal Years
//

export type EditedFiscalCalendar = Pick<FiscalCalendar, "endDate" | "periods">;

export type Periods = FiscalCalendar["periods"];

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

export const DayOfWeek = {
  SUNDAY: "SUNDAY",
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
} as const;

export const DayOfWeekMap: readonly DayOfWeek[] = Object.freeze([
  DayOfWeek.SUNDAY,
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
  DayOfWeek.SATURDAY,
]);

export enum QuarterPattern {
  FIVE_FOUR_FOUR = "5-4-4",
  FOUR_FIVE_FOUR = "4-5-4",
  FOUR_FOUR_FIVE = "4-4-5",
  OTHER = "OTHER",
}

export type FiscalYear = {
  endDate: string;
  isExactWeeks: boolean;
  Q1: FiscalQuarter;
  Q2: FiscalQuarter;
  Q3: FiscalQuarter;
  Q4: FiscalQuarter;
  quarterPattern: QuarterPattern;
  startDate: string;
  startDayOfWeek: DayOfWeek;
  weekCount: number;
  yearNumber: string;
};

export type FiscalQuarter = {
  endDate: string;
  firstWeekNumber: number;
  isExactWeeks: boolean;
  lastWeekNumber: number;
  months: [FiscalMonth, FiscalMonth, FiscalMonth];
  quarterNumber: string;
  quarterPattern: QuarterPattern;
  startDate: string;
  weekCount: number;
};

export type FiscalMonth = {
  endDate: string;
  firstWeekNumber: number;
  isExactWeeks: boolean;
  lastWeekNumber: number;
  monthNumber: string;
  startDate: string;
  weekCount: number;
};

export type FiscalYearValidationResult = {
  canSave: boolean;
  errorCount: number;
  details: FiscalYearValidationDetails;
};

export type FiscalYearValidationDetails = {
  is52or53weeks: boolean;
  is52or53weeksPrev: boolean | null;
  isNotOverlappingWithPrev: boolean | null;
  isQuarterPatternSameAsNext: boolean | null;
  isQuarterPatternSameAsPrev: boolean | null;
  isStartDayOfWeekSameAsNext: boolean | null;
  isStartDayOfWeekSameAsPrev: boolean | null;
  isValidStartAndEndDayOfWeek: boolean;
  isValidStartAndEndDayOfWeekNext: boolean | null;
  isValidStartAndEndDayOfWeekPrev: boolean | null;
};
