import styled from "@emotion/styled";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React, {
  Children,
  cloneElement,
  createElement,
  DetailedHTMLProps,
  FormHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import { isReactElement } from "../utils/children";
import TextArea, { Props as TextAreaProps } from "./TextArea";
import TextInput, { Props as TextInputProps } from "./TextInput";

export interface Props
  extends DetailedHTMLProps<
    FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  height?: string | number;
  margin?: string | number;
  width?: string | number;
  autoComplete?: string;
}

const _Form = styled("form")<Props>(
  ({ height = "100%", margin = 0, width = "100%" }) => ({
    height,
    margin,
    width,
  })
);

export default function Form(props: Props): JSX.Element {
  return <_Form {...props} />;
}

const Root = styled("div")<{
  marginBottom?: number | string;
  marginLeft?: string | number;
}>(({ marginBottom, marginLeft, theme }) => ({
  marginBottom: marginBottom ?? theme.space_lg,
  marginLeft: marginLeft,
}));

const Label = styled("label")(({ theme }) => ({
  color: theme.text_color,
  display: "flex",
  fontSize: theme.fontSize_ui,
  fontWeight: "normal",
  marginBottom: theme.space_xxs,
}));

const RequiredText = styled("span")(({ theme }) => ({
  color: theme.feedback_negative,
}));

const Caption = styled("div")(({ theme }) => ({
  color: theme.text_color_secondary,
  fontSize: theme.h6_fontSize,
  marginTop: "2px",

  p: {
    color: theme.text_color_secondary,
    fontSize: theme.h6_fontSize,
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
}));

type GetInputProps<T> = T extends typeof TextArea
  ? TextAreaProps
  : object & T extends typeof TextInput
    ? TextInputProps
    : object;

export type FormFieldProps<T> = PropsWithChildren<{
  caption?: string | ReactElement;
  input?: T;
  label?: string | ReactElement;
  marginBottom?: number | string;
  marginLeft?: number | string;
  required?: boolean;
  icon?: JSX.Element;
}> &
  GetInputProps<T>;

export function FormField<T>(props: FormFieldProps<T>): JSX.Element {
  const {
    caption,
    children,
    input,
    label,
    marginBottom,
    marginLeft,
    icon,
    ...controlProps
  } = props;

  let control: ReactNode = undefined;

  if (input) {
    control = createElement<T>(input, controlProps);
  } else if (children) {
    const child = Children.only(children);
    if (isReactElement(child)) {
      control = cloneElement(child, child.props);
    }
  }

  return (
    <Root marginBottom={marginBottom} marginLeft={marginLeft}>
      <Flex>
        <Label>
          {label}
          {props.required && <RequiredText>*</RequiredText>}
        </Label>
        {icon}
      </Flex>
      {control}
      {caption && <Caption>{caption}</Caption>}
    </Root>
  );
}
