import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQueries } from "@tanstack/react-query";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

type NetCostDatum = {
  customNetCost: number;
  netCost: number;
  timestamp: string;
};

type NetCostsByDate = {
  customNetCost: number;
  date: string;
  netCost: number;
};

type NetCostConfig = {
  billingAccountIDs: string[];
  dateRange: Date[];
};

export default function useGetNetCostByBillingAccountIDs(
  netCostConfigs: NetCostConfig[],
  options?: UseQueryOptions<NetCostsByDate[], UError>
): UseQueryResult<NetCostsByDate[], UError>[] {
  const client = useAnalyticsApiClient();

  return useQueries({
    queries: netCostConfigs.map((config) => ({
      queryKey: ["actualSpend", config],
      queryFn: async () => {
        const result = (await client.load(
          buildCubeQuery({
            dateRange: config.dateRange,
            dataSource: DataSource.BILLING,
            granularity: TimeGranularity.MONTH,
            measures: ["customNetCost", "netCost"],
            order: { ["timestamp"]: "asc" },
            queryFilters: [
              {
                name: "billingAccountId",
                operator: Operator.EQUALS,
                values: config.billingAccountIDs,
              },
              {
                name: "serviceId",
                operator: Operator.NOT_EQUALS,
                values: [
                  "1934-AA85-20F9", // Maps API
                  "B7D9-FDCB-15D8", // Directions API
                  "0AC2-4B25-C429", // Geocoding API
                  "CF79-2786-ADFC", // Maps Static API
                  "213C-9623-1402", // Places API
                ],
              },
            ],
          })
        )) as NetCostDatum[];

        return result.map((datum) => {
          return {
            customNetCost: datum.customNetCost,
            date: datum.timestamp,
            netCost: datum.netCost,
          };
        });
      },
      ...options,
    })),
  });
}
