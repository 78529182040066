import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type TenantEntity = Awaited<ReturnType<CoreAPIClient["getTenants"]>>[number];

export default function useGetTenants(
  options?: UseQueryOptions<TenantEntity[], CoreAPIClientError>
): UseQueryResult<TenantEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["tenants"],
    queryFn: () => client.getTenants(),
    ...options,
  });
}
