import React, { createContext, PropsWithChildren, useContext } from "react";
import AuthHandler from "./AuthHandler";

const context = createContext<AuthHandler | undefined>(undefined);

export interface Props {
  handler: AuthHandler;
}

export default function AuthHandlerProvider({
  children,
  handler,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={handler}>{children}</context.Provider>;
}

export function useAuthHandler(): AuthHandler {
  const handler = useContext(context);

  if (!handler) {
    throw new Error("useAuthHandler must be wrapped by AuthHandlerProvider");
  }

  return handler;
}
