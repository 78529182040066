import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import UError from "unilib-error";
import copyText from "../../../common.copyText";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { AnomalyDetail } from "../types";
import { buildCubeQuery } from "../utils";

type AnomalyDetailDatum = {
  cost?: number | null;
  onDemandCost?: number | null;
  timestamp: string;
};

export interface Params {
  dataSource: DataSource;
  dateRange: Date[];
  granularity?: TimeGranularity;
  measures?: string[];
  queryFilters?: QueryFilter[];
}

export default function useGetAnomalyDetail(
  params: Params,
  options?: UseQueryOptions<AnomalyDetail[], UError>
): UseQueryResult<AnomalyDetail[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryFn: async () => {
      const dateRange: [string, string] = [
        format(
          toZonedTime(params.dateRange[0], "UTC"),
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
        format(
          toZonedTime(params.dateRange[1], "UTC"),
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
      ];

      const result = (await client.load(
        buildCubeQuery({
          ...params,
          dateRange,
          granularity: params.granularity ?? TimeGranularity.HOUR,
          measures: params.measures ? params.measures : ["cost"],
        })
      )) as AnomalyDetailDatum[];

      const data = result.map((datum) => {
        return {
          amount: datum.cost ?? datum.onDemandCost ?? 0,
          time: datum.timestamp,
        };
      });

      return data;
    },
    queryKey: ["anomalyDetail", params],
    gcTime: ANALYTICS_QUERY_GC_TIME,
    meta: { errorMessage: copyText.errorLoadingAnomalyDataMessage },
    ...options,
  });
}
