import ErrorBoundary from "@/components/ErrorBoundary";
import NotConfiguredPlaceholder from "@/components/NotConfiguredPlaceholder";
import useGatekeeper from "@/hooks/useGatekeeper";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import {
  BooleanParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import paths from "../../../../constants/paths";
import { storage } from "../../../../utils/window";
import copyText from "../../copyText";
import CUDOptimizerContainer from "./CUDOptimizerContainer";
import CudVisibilityContainer from "./CudVisibilityContainer";

export function CommittedUsePage(): JSX.Element {
  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "inventory"),
    enhanced_view: withDefault(BooleanParam, getIsEnhancedView()),
  });

  const gatekeeper = useGatekeeper();

  if (!gatekeeper.canViewCommittedUsePage) {
    return <Navigate to={paths._home} replace={true} />;
  }

  if (!gatekeeper.isCommittedUseConfigured) {
    return <NotConfiguredPlaceholder />;
  }

  const containers = [
    {
      component: <CudVisibilityContainer />,
      label: copyText.inventoryTabLabel,
      value: "inventory",
    },
    {
      component: <CUDOptimizerContainer />,
      label: copyText.optimizationsTabLabel,
      value: "optimizations",
    },
  ];

  return (
    <ErrorBoundary boundaryName="CommittedUsePage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}

function getIsEnhancedView(): boolean {
  const localStorageIsCUDEnhancedView = storage.getItem("cud_enhanced_view");

  return localStorageIsCUDEnhancedView === "false";
}
