import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../../ui-lib/components/Form";
import TextInput from "../../../../ui-lib/components/TextInput";
import getMergeState from "../../../../utils/getMergeState";
import copyText from "../../copyText";

interface Props {
  isLoading: boolean;
  organizationName?: string;
  integrationName?: string;
  onChange: (orgName: string, integrationName: string) => void;
}

interface State {
  integrationNameInput: string;
  organizationNameInput: string;
}

const initialState: State = {
  integrationNameInput: "",
  organizationNameInput: "",
};

export default function SnowflakeIntegrationFormBasic(
  props: Props
): JSX.Element {
  const [state, setState] = useState(
    props.integrationName && props.organizationName
      ? {
          integrationNameInput: props.integrationName,
          organizationNameInput: props.organizationName,
        }
      : initialState
  );

  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (
      state.integrationNameInput.trim().length > 0 &&
      state.organizationNameInput.trim().length > 0
    ) {
      props.onChange(state.organizationNameInput, state.integrationNameInput);
    }
  }, [state]);

  return (
    <Flex height={450} minWidth={400} scrollable>
      <Box padding={"0.5rem"} width={"100%"}>
        <FormField
          name="name"
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeCloudName}
          required
          type="text"
          value={state.integrationNameInput}
          variant={
            state.integrationNameInput.trim().length > 0 ? "success" : "danger"
          }
          onChange={(event) =>
            mergeState({ integrationNameInput: event?.target.value })
          }
        />
        <FormField
          name="organizationName"
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeOrganizationName}
          required
          type="text"
          value={state.organizationNameInput}
          variant={
            state.organizationNameInput.trim().length > 0 ? "success" : "danger"
          }
          onChange={(event) =>
            mergeState({ organizationNameInput: event.target.value })
          }
        />
      </Box>
    </Flex>
  );
}
