import {
  Infer,
  boolean,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const StorageBucketCountStruct = {
  totalDistinctBuckets: number(),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const StorageCostStruct = {
  cost: number(),
  costKind: string(),
  lowCarbon: boolean(),
  projectId: nullable(string()),
  region: nullable(string()),
  storageClass: nullable(string()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const StorageUsageStruct = {
  totalDistinctBuckets: nullable(number()),
  totalStoredByteSeconds: nullable(number()),
  totalStoredObjectDays: nullable(number()),
  totalSentBytes: nullable(number()),
  totalReceivedBytes: nullable(number()),
  totalRequestCount: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const StorageUsageTableStruct = {
  bucketId: nullable(string()),
  projectId: nullable(string()),
  lifecycleRules: string(),
  location: nullable(string()),
  storageClass: nullable(string()),
  totalDistinctBuckets: nullable(number()),
  totalStoredByteSeconds: nullable(number()),
  totalStoredObjectDays: nullable(number()),
  totalSentBytes: nullable(number()),
  totalReceivedBytes: nullable(number()),
  totalRequestCount: nullable(number()),
};

export type Bucket = {
  bucketId: string;
  lifecycleRules: LifeCycle[];
  location: string;
  projectId: string;
  recommendationCount: number;
  storageClass: string;
  totalStoredByteSeconds: number;
  totalStoredObjectDays: number;
  totalReceivedBytes: number;
  totalSentBytes: number;
  totalRequestCount: number;
  totalDistinctBuckets: number;
};

export type LifeCycle = {
  action: {
    type: string;
  };
  condition: {
    age: number;
    matchesPrefix: string[];
    matchesStorageClass: string[];
    matchesSuffix: string[];
  };
};

export type StorageCostDatum = NotNullable<Infer<typeof StorageCostStruct>>;

export type StorageUsageDatum = NotNullable<Infer<typeof StorageUsageStruct>>;

export type StorageTableUsageDatum = NotNullable<
  Infer<typeof StorageUsageTableStruct>
>;

export type StorageTableData = {
  buckets: Bucket[];
  bucketCount: number;
  egress: number;
  ingress: number;
  lowCarbon: boolean;
  operations: number;
  projectID: string;
  region: string;
  network: number;
  processing: number;
  storage: number;
  other: number;
  recommendation: string[];
};

export const StorageResourceType = {
  STORAGE: "STORAGE",
  DATA_TRANSFER: "DATA_TRANSFER",
  OPERATIONS: "OPERATIONS",
} as const;

export type StorageResourceType =
  (typeof StorageResourceType)[keyof typeof StorageResourceType];
