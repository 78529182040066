import React, { PropsWithChildren, createContext, useContext } from "react";
import { config } from "../config";

type Config = typeof config;

const context = createContext<Config>(config);

export default function ConfigProvider(props: PropsWithChildren<unknown>) {
  return <context.Provider value={config}>{props.children}</context.Provider>;
}

export function useConfig(): Config {
  const config = useContext(context);

  if (!config) {
    throw new Error("useConfig must be wrapped by ConfigProvider");
  }

  return config;
}
