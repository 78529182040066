import copyText from "@/common.copyText";
import EventReporter from "@/utils/EventReporter";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { ErrorInfo, ReactNode } from "react";
import registry from "unilib-registry/instance";

type Props = React.PropsWithChildren<{
  boundaryName: string;
}>;

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  public readonly _eventReporter: EventReporter;

  constructor(props: Props) {
    super(props);
    this._eventReporter = registry.get<EventReporter>(EventReporter.name);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error, errorInfo);
    this._eventReporter.reportError(error);
  }

  render(): JSX.Element | ReactNode {
    if (this.state.hasError) {
      return (
        <Flex
          alignItems="center"
          direction="column"
          justifyContent="center"
          margin-top="4rem"
          height="100%"
          width="100%"
        >
          <Text align="center" appearance="h1">
            {copyText.errorBoundaryTitle}
          </Text>
          <Text align="center">{copyText.errorBoundaryContent}</Text>
        </Flex>
      );
    }

    return this.props.children;
  }
}
