import React, { createContext, PropsWithChildren, useContext } from "react";
import CoreAPIClient from "./CoreAPIClient";

const context = createContext<CoreAPIClient | undefined>(undefined);

export interface Props {
  client: CoreAPIClient;
}

export default function CoreAPIClientProvider({
  children,
  client,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={client}>{children}</context.Provider>;
}

export function useCoreAPIClient(): CoreAPIClient {
  const client = useContext(context);

  if (!client) {
    throw new Error(
      "useCoreAPIClient must be wrapped by CoreAPIClientProvider"
    );
  }

  return client;
}
