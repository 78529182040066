import { LinkWithSearchParams } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../constants/copyText";
import paths from "../constants/paths";
import Card from "./Card";

export default function NotConfiguredPlaceholder(): JSX.Element {
  const theme = useTheme();

  // Div wrapper is to allow this to conform to the layout interface, which targets the top level div for 100% width
  return (
    <div>
      <Card
        margin={theme.space_lg}
        paddingHorizontal={theme.space_md}
        paddingVertical={theme.space_jumbo}
        width={"25rem"}
      >
        <Text
          as="h2"
          fontSize={theme.h2_fontSize}
          fontWeight={theme.h2_fontWeight}
          marginBottom={theme.space_jumbo}
        >
          {copyText.notConfiguredPlaceholderTitle}
        </Text>
        <Text
          fontSize={theme.h5_fontSize}
          fontWeight={theme.h5_fontWeight}
          marginBottom={theme.space_lg}
        >
          {copyText.notConfiguredPlaceholderText}
        </Text>
        <Text fontSize={theme.h5_fontSize} fontWeight={theme.h5_fontWeight}>
          <LinkWithSearchParams to={paths._admin}>
            {copyText.notConfiguredPlaceholderAdminLink}
          </LinkWithSearchParams>
        </Text>
      </Card>
    </div>
  );
}
