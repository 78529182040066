import Meter from "@/components/Meter";
import { useTheme } from "@emotion/react";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import {
  formatCurrency,
  formatNumber,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React from "react";
import copyText from "../../copyText";

interface Props {
  cpuTotal: number;
  cpuUsed: number;
  isLoading: boolean;
  memoryTotal: number;
  memoryUsed: number;
  totalCost: number;
}

export default function AzureKubernetesMeters(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Flex>
      <Box flex="1 0 100px">
        <Meter
          header={copyText.awsKubernetesMetersLabelTotalCost}
          hideFooter
          loading={props.isLoading}
          value={formatCurrency({ number: props.totalCost })}
          width="100%"
        />
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Box flex="1 0 100px">
        <Meter
          header={copyText.awsKubernetesMetersLabelCores}
          hideFooter
          loading={props.isLoading}
          value={`${formatNumber(props.cpuUsed)}/${formatNumber(
            props.cpuTotal
          )}`}
          width="100%"
        />
      </Box>
      <Box
        borderRight={`1px solid ${theme.border_color}`}
        marginHorizontal={theme.space_lg}
      />
      <Box flex="1 0 100px">
        <Meter
          header={copyText.awsKubernetesMetersLabelBytes}
          hideFooter
          loading={props.isLoading}
          value={`${prettyBytes(props.memoryUsed, {
            binary: true,
          })}/${prettyBytes(props.memoryTotal, { binary: true })}`}
          width="100%"
        />
      </Box>
    </Flex>
  );
}
