import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconGcpKubernetes(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <defs>
          <clipPath id="IconGcpKubernetes_B">
            <path d="m27.791 115.22l-26.25-45.47c-2.054-3.558-2.054-7.941 0-11.499l26.25-45.47c2.054-3.558 5.85-5.75 9.959-5.75h52.5c4.108 0 7.904 2.192 9.959 5.75l26.25 45.47c2.054 3.558 2.054 7.941 0 11.499l-26.25 45.47c-2.054 3.558-5.85 5.749-9.959 5.749h-52.5c-4.108 0-7.904-2.192-9.958-5.749" />
          </clipPath>
          <linearGradient
            id="IconGcpKubernetes_A"
            gradientUnits="userSpaceOnUse"
            y1="7.03"
            x2="0"
            y2="120.79"
          >
            <stop stopColor="#4387fd" />
            <stop offset="1" stopColor="#4683ea" />
          </linearGradient>
        </defs>
        <path
          d="m27.791 115.22l-26.25-45.47c-2.054-3.558-2.054-7.941 0-11.499l26.25-45.47c2.054-3.558 5.85-5.75 9.959-5.75h52.5c4.108 0 7.904 2.192 9.959 5.75l26.25 45.47c2.054 3.558 2.054 7.941 0 11.499l-26.25 45.47c-2.054 3.558-5.85 5.749-9.959 5.749h-52.5c-4.108 0-7.904-2.192-9.958-5.749"
          fill="url(#IconGcpKubernetes_A)"
        />
        <path
          opacity=".07"
          clipPath="url(#IconGcpKubernetes_B)"
          d="M124.05 82.86 89.63 48.44 64 37 39.5 50.31 38.473 79.65 81.02 122.19 97.67 122.04z"
        />
        <path
          d="m89.03 47.993l-23.676-13.67c-.835-.482-1.864-.482-2.699 0l-23.676 13.67c-.835.482-1.349 1.373-1.349 2.337v27.339c0 .964.514 1.855 1.349 2.337l23.676 13.67c.835.482 1.864.482 2.699 0l23.676-13.67c.835-.482 1.349-1.373 1.349-2.337v-27.339c0-.964-.514-1.855-1.349-2.337m-3.248 27.06l-8.26-4.769v-12.563l-12.198 7.04v14.09l10.879-6.281 8.26 4.769-20.459 11.812-20.459-11.812 8.26-4.769 10.88 6.281v-14.09l-12.198-7.04v12.563l-8.26 4.769v-23.624l20.459-11.812v9.538l-10.88 6.281 12.198 7.04 12.198-7.04-10.879-6.281v-9.538l20.459 11.812v23.624"
          fill="#ffffff"
        />
      </svg>
    </Icon>
  );
}
