import { createColumnHelper } from "@tanstack/react-table";
import { AzureRateRecommendationEntity } from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import useRefFn from "@ternary/api-lib/ui-lib/hooks/useRefFn";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import copyText from "../../copyText";

type TableData = {
  id: string;
  commitmentCost: number;
  coverageType: string;
  estimatedOnDemandCost: number;
  estimatedTotalSavings: number;
  estimatedUtilizationPercentage: number;
  instanceType: string;
  location: string;
  overageCost: number;
  savingsPercentage: number;
  sourceRecommendation: AzureRateRecommendationEntity;
  totalCost: number;
};

type Props = {
  buttonIcon: JSX.Element;
  isLoading: boolean;
  reservedInstanceRecommendations: AzureRateRecommendationEntity[];
  onSelectRecommendation: (rec: AzureRateRecommendationEntity) => void;
};

const columnHelper = createColumnHelper<TableData>();

export default function AzureReservedInstanceRecommendationTable(props: Props) {
  const data = useMemo(
    () => getTableData(props.reservedInstanceRecommendations),
    [props.reservedInstanceRecommendations]
  );

  const onSelectRecommendation = useRefFn(props.onSelectRecommendation);

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "id",
        size: 48,
        cell: ({ row }) => (
          <Button
            iconStart={props.buttonIcon}
            primary
            size="tiny"
            onClick={() =>
              onSelectRecommendation(row.original.sourceRecommendation)
            }
          />
        ),
      }),
      columnHelper.accessor("commitmentCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.azureRITableHeader_commitmentCost,
        meta: { align: "right" },
      }),
      columnHelper.accessor("coverageType", {
        header: copyText.azureRITableHeader_coverageType,
        meta: { align: "center" },
      }),
      columnHelper.accessor("estimatedTotalSavings", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.azureRITableHeader_estimatedTotalSavings,
        meta: { align: "center" },
      }),
      columnHelper.accessor("estimatedOnDemandCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.azureRITableHeader_estimatedOnDemandCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("savingsPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.azureRITableHeader_savingsPercentage,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("estimatedUtilizationPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.azureRITableHeader_estimatedUtilizationPercentage,
        meta: { align: "center" },
      }),
      columnHelper.accessor("overageCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.azureRITableHeader_overageCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.azureRITableHeader_totalCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("instanceType", {
        header: copyText.azureRITableHeader_instanceType,
        cell: ({ getValue }) => {
          return (
            <Text truncate>
              <Tooltip content={getValue()}>{getValue()}</Tooltip>
            </Text>
          );
        },
        meta: { align: "center" },
        size: 200,
      }),
      columnHelper.accessor("location", {
        header: copyText.azureRITableHeader_location,
        meta: { align: "center" },
        size: 100,
      }),
    ];
  }, [props.reservedInstanceRecommendations]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "estimatedMonthlySavingsAmount", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getTableData(
  reservedInstanceRecommendations: AzureRateRecommendationEntity[]
) {
  const data: TableData[] = [];

  reservedInstanceRecommendations.forEach((rec) => {
    data.push({
      id: rec.id,
      commitmentCost: rec.commitmentCost,
      coverageType: rec.coverageType,
      estimatedOnDemandCost: rec.estimatedOnDemandCost,
      estimatedTotalSavings: rec.estimatedTotalSavings,
      estimatedUtilizationPercentage: rec.estimatedUtilizationPercentage
        ? rec.estimatedUtilizationPercentage / 100
        : 0,
      instanceType: rec.instanceType ?? "NA",
      location: rec.location ?? "NA",
      overageCost: rec.overageCost,
      savingsPercentage: rec.savingsPercentage / 100,
      sourceRecommendation: rec,
      totalCost: rec.totalCost,
    });
  });

  return data;
}
