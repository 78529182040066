import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateScopedViewParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { UserConfig } from "@ternary/api-lib/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { scopedViewID: string } & UpdateScopedViewParameters,
  { previousUserConfigs: UserConfig[] }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { scopedViewID: string } & UpdateScopedViewParameters
>;

export default function useUpdateScopedView(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ scopedViewID, ...params }) =>
      client.updateScopedView(scopedViewID, params),
    ...options,
  });
}
