import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import {
  add,
  differenceInCalendarMonths,
  isAfter,
  isBefore,
  isSameMonth,
} from "date-fns";
import { isEqual, keyBy } from "lodash";
import { DateHelper } from "../../../lib/dates";
import { getUTCMonthString } from "../../../utils/dates";
import {
  CommitmentPeriod,
  NetCostByDate,
  NetCostByDateDimensional,
  RampPlanBreakpoint,
  RampPlanBreakpointVersions,
} from "../types";

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

export function getDeltaBetweenRampPlans(
  existingRampPlan: RampPlan,
  updatedRampPlan: RampPlan
): {
  hasAnythingChanged: boolean;
  breakpointsToAdd: RampPlanBreakpoint[] | undefined;
  breakpointsToUpdate: RampPlanBreakpoint[] | undefined;
  breakpointsWithVersionsToAdd: RampPlanBreakpoint[] | undefined;
  rampPlanToUpdate: RampPlan | undefined;
} {
  let breakpointsToAdd: RampPlanBreakpoint[] | undefined = undefined;
  let breakpointsToUpdate: RampPlanBreakpoint[] | undefined = undefined;
  let breakpointsWithVersionsToAdd: RampPlanBreakpoint[] | undefined =
    undefined;
  let rampPlanToUpdate: RampPlan | undefined = undefined;

  // Look for changes to RampPlan
  let hasChangedEnumeratedValues =
    existingRampPlan.enumeratedValues.length !==
    updatedRampPlan.enumeratedValues.length;

  existingRampPlan.enumeratedValues.forEach((enumeratedValue) => {
    if (!updatedRampPlan.enumeratedValues.includes(enumeratedValue)) {
      hasChangedEnumeratedValues = true;
    }
  });

  let haveChangedCommitments =
    existingRampPlan.commitments.length !== updatedRampPlan.commitments.length;

  if (!haveChangedCommitments) {
    existingRampPlan.commitments.forEach((existingCommitment) => {
      const updated = updatedRampPlan.commitments.find(
        (updatedCommitment) => updatedCommitment._id === existingCommitment._id
      );

      if (!updated) {
        haveChangedCommitments = true;
        return;
      }

      if (
        existingCommitment.amount !== updated.amount ||
        existingCommitment.end !== updated.end ||
        existingCommitment.start !== updated.start
      ) {
        haveChangedCommitments = true;
      }
    });
  }

  const haveChangedOffsets =
    !isEqual(
      existingRampPlan.nonExportOffsetByMonth,
      updatedRampPlan.nonExportOffsetByMonth
    ) ||
    !isEqual(
      existingRampPlan.nonExportOffsetRecurring,
      updatedRampPlan.nonExportOffsetRecurring
    );

  if (
    existingRampPlan.name !== updatedRampPlan.name ||
    haveChangedOffsets ||
    existingRampPlan.billingAccountIDs !== updatedRampPlan.billingAccountIDs ||
    hasChangedEnumeratedValues ||
    haveChangedCommitments
  ) {
    rampPlanToUpdate = updatedRampPlan;
  }

  // Look for new breakpoints
  const newBreakpoints = updatedRampPlan.breakpoints.filter(
    (breakpoint) => !breakpoint.id
  );

  if (newBreakpoints.length > 0) {
    breakpointsToAdd = newBreakpoints;
  }

  // Look for breakpoints to update (month or defunct)
  const updatedBreakPoints: RampPlanBreakpoint[] = existingRampPlan.breakpoints
    .filter((existingBreakpoint) => {
      const updated = updatedRampPlan.breakpoints.find(
        (updatedBreakpoint) => updatedBreakpoint.id === existingBreakpoint.id
      );

      if (!updated || existingBreakpoint.month !== updated.month) {
        return true;
      }
    })
    .map((existingBreakpoint) => {
      const updated = updatedRampPlan.breakpoints.find(
        (updatedBreakpoint) => updatedBreakpoint.id === existingBreakpoint.id
      );

      if (!updated) {
        return { ...existingBreakpoint, defunct: true };
      }

      return updated;
    });

  if (updatedBreakPoints.length > 0) {
    breakpointsToUpdate = updatedBreakPoints;
  }

  // Look for breakpoints versions to add
  const breakpointIDsWithNewVersions = existingRampPlan.breakpoints
    .filter((existingBreakpoint) => {
      const latestExistingVersion = getLatestVersion(existingBreakpoint);
      const newVersion = updatedRampPlan.breakpoints.find(
        (breakpoint) => breakpoint.id === existingBreakpoint.id
      )?.versions[0];

      if (!newVersion) return false;

      if (newVersion.otherSpend !== latestExistingVersion.otherSpend)
        return true;

      if (newVersion.reason !== latestExistingVersion.reason) return true;

      if (
        Object.keys(latestExistingVersion.enumeratedValuesSpend).length !==
        Object.keys(newVersion.enumeratedValuesSpend).length
      ) {
        return true;
      }

      let enumeratedValuesHaveChanged = false;

      Object.keys(latestExistingVersion.enumeratedValuesSpend).forEach(
        (existingKey) => {
          if (
            newVersion.enumeratedValuesSpend[existingKey] === undefined ||
            newVersion.enumeratedValuesSpend[existingKey] !==
              latestExistingVersion.enumeratedValuesSpend[existingKey]
          ) {
            enumeratedValuesHaveChanged = true;
          }
        }
      );

      if (enumeratedValuesHaveChanged) {
        return true;
      }
    })
    .map((breakpoint) => breakpoint.id);

  if (breakpointIDsWithNewVersions.length > 0) {
    breakpointsWithVersionsToAdd = updatedRampPlan.breakpoints.filter(
      (breakpoint) => breakpointIDsWithNewVersions.includes(breakpoint.id)
    );
  }

  const hasAnythingChanged =
    breakpointsToAdd !== undefined ||
    breakpointsToUpdate !== undefined ||
    breakpointsWithVersionsToAdd !== undefined ||
    rampPlanToUpdate !== undefined;

  return {
    hasAnythingChanged,
    breakpointsToAdd,
    breakpointsToUpdate,
    breakpointsWithVersionsToAdd,
    rampPlanToUpdate,
  };
}

export function getProjectionsForRampPlan(
  rampPlan: RampPlan,
  mostRecentYearActualNetCost: NetCostByDate[]
): { projectedVariancePercentage: number; projectedVarianceAbsolute: number } {
  const now = new DateHelper();
  const currentMonth = formatDate(now.date, "yyyy-MM");
  const currentCommitment = rampPlan.commitments.find((commitment) => {
    if (
      isAfter(
        new Date(getUTCMonthString(commitment.end)),
        new Date(currentMonth)
      ) &&
      isBefore(
        new Date(getUTCMonthString(commitment.start)),
        new Date(currentMonth)
      )
    ) {
      return true;
    }
  });

  const currentIndex = rampPlan.commitments.findIndex(
    (commmitment) => commmitment._id === currentCommitment?._id
  );

  if (!currentCommitment || !rampPlan || !mostRecentYearActualNetCost) {
    return { projectedVariancePercentage: 0, projectedVarianceAbsolute: 0 };
  }
  const commitmentStartDate = new Date(currentCommitment.start);

  // NOTE: Prevents counting the same month twice in overlapping commitment periods
  const commitmentStartMonth =
    currentIndex === 0
      ? getUTCMonthString(currentCommitment.start)
      : formatDate(commitmentStartDate, "MM-dd-yyyy");
  const commitmentEndMonth = getUTCMonthString(currentCommitment.end);

  const allRampPlanMonths = getRampPlanMonths(rampPlan);
  const applicableActualSpend = mostRecentYearActualNetCost.filter((entry) =>
    getApplicableEntries(
      entry,
      commitmentStartMonth,
      commitmentEndMonth,
      "date"
    )
  );

  // Current month and NOT already covered by actuals
  const applicableRampPlanMonths = allRampPlanMonths.filter(
    (rampPlanMonth) =>
      getApplicableEntries(
        rampPlanMonth,
        commitmentStartMonth,
        commitmentEndMonth,
        "date"
      ) &&
      applicableActualSpend.find(
        (actual) => getUTCMonthString(actual.date) === rampPlanMonth.date
      ) === undefined &&
      !isBefore(
        new Date(rampPlanMonth.date),
        new Date(applicableActualSpend[0]?.date)
      )
  );

  const totalActuals = applicableActualSpend.reduce(
    (accum, actualSpend) => accum + actualSpend.netCost,
    0
  );
  const totalProjected = applicableRampPlanMonths.reduce(
    (accum, actualSpend) =>
      accum +
      actualSpend.dimensions.reduce(
        (total, dimension) => total + dimension.netCost,
        0
      ),
    0
  );

  const derivedProjectedSpend = totalActuals + totalProjected;
  const projectedVarianceAbsolute =
    derivedProjectedSpend - currentCommitment.amount;
  const projectedVariancePercentage =
    projectedVarianceAbsolute / currentCommitment.amount;

  return {
    projectedVariancePercentage: projectedVariancePercentage,
    projectedVarianceAbsolute,
  };
}

export function getApplicableEntries(
  entity:
    | NetCostByDate
    | (RampPlanBreakpointVersions & { month: string })
    | NetCostByDateDimensional,
  commitmentStartMonth: string,
  commitmentEndMonth: string,
  key: "date" | "month"
): boolean {
  if (
    isAfter(
      new Date(getUTCMonthString(entity[key])),
      new Date(commitmentEndMonth)
    ) ||
    isBefore(
      new Date(getUTCMonthString(entity[key])),
      new Date(commitmentStartMonth)
    )
  ) {
    return false;
  }
  return true;
}

//
// Reusable Helpers
//

export function getMostRecentRampPlanVersions(
  rampPlan: RampPlan
): { month: string; version: RampPlanBreakpointVersions }[] {
  const mostRecentVersions: {
    month: string;
    version: RampPlanBreakpointVersions;
  }[] = rampPlan.breakpoints
    .map((bp) => {
      const version = getLatestVersion(bp);
      return {
        month: bp.month,
        version,
      };
    })
    .sort((a, b) => {
      if (isBefore(new Date(b.month), new Date(a.month))) {
        return 1;
      }
      if (isBefore(new Date(a.month), new Date(b.month))) {
        return -1;
      }
      return 0;
    });

  return mostRecentVersions;
}

export function getStartAndEndMonth(
  mostRecentVersions: {
    month: string;
    version: RampPlanBreakpointVersions;
  }[]
): string[] {
  return mostRecentVersions.reduce(
    (accum: string[], monthWithVersion) => {
      if (accum[0] === "" && accum[1] === "") {
        return [
          getUTCMonthString(monthWithVersion.month),
          getUTCMonthString(monthWithVersion.month),
        ];
      }

      if (
        isBefore(
          new Date(getUTCMonthString(monthWithVersion.month)),
          new Date(accum[0])
        )
      ) {
        return [getUTCMonthString(monthWithVersion.month), accum[1]];
      }

      if (
        isAfter(
          new Date(getUTCMonthString(monthWithVersion.month)),
          new Date(accum[1])
        )
      ) {
        return [accum[0], getUTCMonthString(monthWithVersion.month)];
      }

      return accum;
    },
    ["", ""]
  );
}

export function getRampPlanMonths(
  rampPlan: RampPlan
): NetCostByDateDimensional[] {
  const mostRecentVersions = getMostRecentRampPlanVersions(rampPlan);

  const [startMonth, endMonth] = getStartAndEndMonth(mostRecentVersions);
  const monthsArray = [startMonth];

  let currentMonthString = startMonth;
  while (isBefore(new Date(currentMonthString), new Date(endMonth))) {
    const currentMonth = add(new Date(currentMonthString), { weeks: 6 });
    currentMonthString = getUTCMonthString(currentMonth.toISOString());
    monthsArray.push(currentMonthString);
  }

  // useMemo here and elsewhere
  const monthsWithProjectedCosts = monthsArray.map((monthString) => {
    return getMonthAmountForGivenMonth(monthString, mostRecentVersions);
  });

  return monthsWithProjectedCosts;
}

export function getLatestVersion(
  breakPoint: RampPlanBreakpoint
): RampPlanBreakpointVersions & { month: string } {
  let output: RampPlanBreakpointVersions = breakPoint.versions[0];

  breakPoint.versions.forEach((bpv) => {
    if (isBefore(new Date(output.createdAt), new Date(bpv.createdAt))) {
      output = bpv;
    }
  });

  return { ...output, month: breakPoint.month };
}

export function getCumulativeActualSpend(
  applicableActualSpend: NetCostByDate[],
  startingOverflowValue: number
): { actualNetCost: number; date: string }[] {
  const cumulative = applicableActualSpend.reduce(
    (accum: { actualNetCost: number; date: string }[], thisMonth) => {
      const cumulativeThisMonthNetCost =
        accum.length > 0
          ? accum[accum.length - 1].actualNetCost + thisMonth.netCost
          : startingOverflowValue + thisMonth.netCost;

      const formattedDate = getUTCMonthString(thisMonth.date);

      return [
        ...accum,
        {
          date: formattedDate,
          actualNetCost: cumulativeThisMonthNetCost,
        },
      ];
    },
    []
  );

  return cumulative;
}

export function getCumulativeRampPlanMonths(
  applicableMonths: NetCostByDateDimensional[],
  startingOverflowValue: number
): NetCostByDateDimensional[] {
  return applicableMonths.reduce((accum: NetCostByDateDimensional[], month) => {
    if (accum.length === 0) {
      return [
        {
          ...month,
          dimensions: [
            ...month.dimensions,
            { dimension: "overflow", netCost: startingOverflowValue },
          ],
        },
      ];
    }

    const previousEntry = accum[accum.length - 1];

    return [
      ...accum,
      {
        ...month,
        dimensions: month.dimensions.map((d, index) => {
          const previousDimension = previousEntry.dimensions.find(
            (dim) => dim.dimension === d.dimension
          );

          const overflow =
            index === 0
              ? (previousEntry.dimensions.find(
                  (dim) => dim.dimension === "overflow"
                )?.netCost ?? 0)
              : 0;

          return {
            ...d,
            netCost:
              d.netCost +
              (previousDimension ? previousDimension.netCost + overflow : 0),
          };
        }),
      },
    ];
  }, []);
}

export function getOverflowSpendForPeriod(
  actualSpend: NetCostByDate[],
  commitmentStartMonth: string,
  commitmentPeriods: CommitmentPeriod[]
): number {
  let overflow = 0;

  const previousCommitmentPeriods = commitmentPeriods
    .filter(
      (commitment) => new Date(commitment.end) < new Date(commitmentStartMonth)
    )
    .sort((a, b) => (new Date(a.start) < new Date(b.start) ? -1 : 1));

  const actualSpendKeyedByDate = keyBy(actualSpend, "date");

  const previousSpendByCommitmentPeriodByID = actualSpend.reduce(
    (accum, datum) => {
      previousCommitmentPeriods.forEach((commitmentPeriod, commitmentIndex) => {
        if (datum.date === getUTCMonthString(commitmentPeriod.end)) {
          if (commitmentIndex === 0) {
            accum[commitmentPeriod._id] = datum.netCost;
          } else {
            const previousCommitmentEndDate = getUTCMonthString(
              previousCommitmentPeriods[commitmentIndex - 1].end
            );

            const previousCommitmentTotalSpend =
              actualSpendKeyedByDate[previousCommitmentEndDate].netCost;

            accum[commitmentPeriod._id] =
              datum.netCost - previousCommitmentTotalSpend;
          }
        }
      });

      return accum;
    },
    {}
  );

  previousCommitmentPeriods.forEach((commitmentPeriod, index) => {
    if (
      previousSpendByCommitmentPeriodByID[commitmentPeriod._id] -
        commitmentPeriod.amount >
      0
    ) {
      overflow =
        previousSpendByCommitmentPeriodByID[commitmentPeriod._id] -
        commitmentPeriod.amount;

      previousSpendByCommitmentPeriodByID[
        previousCommitmentPeriods[index + 1]?._id
      ] += overflow;
    } else {
      overflow = 0;
    }
  });

  return overflow;
}

export function getStartOfRampPlan(commitments: CommitmentPeriod[]): string {
  const sortedCommitmentPeriods = commitments.sort((a, b) => {
    if (isBefore(new Date(a.start as string), new Date(b.start as string))) {
      return -1;
    }
    return 1;
  });

  return sortedCommitmentPeriods[0].start;
}

export function getEndOfRampPlan(commitments: CommitmentPeriod[]): string {
  const sortedCommitmentPeriods = commitments.sort((a, b) => {
    if (isBefore(new Date(a.start as string), new Date(b.start as string))) {
      return 1;
    }
    return -1;
  });

  return sortedCommitmentPeriods[0].end;
}

function getMonthAmountForGivenMonth(
  monthString: string,
  sortedMostRecentVersions: {
    month: string;
    version: RampPlanBreakpointVersions;
  }[]
): NetCostByDateDimensional {
  const versionsKeyedByMonth = keyBy(sortedMostRecentVersions, "month");

  if (versionsKeyedByMonth[monthString]) {
    const spendObject =
      versionsKeyedByMonth[monthString].version.enumeratedValuesSpend;

    const dimensions = Object.keys(spendObject).map((key) => ({
      dimension: key,
      netCost: spendObject[key],
    }));

    dimensions.push({
      dimension: "other",
      netCost: versionsKeyedByMonth[monthString].version.otherSpend,
    });

    return {
      date: monthString,
      dimensions,
    };
  }

  const breakpointOnThisMonthIndex = sortedMostRecentVersions.findIndex(
    (element) =>
      isSameMonth(
        new Date(getUTCMonthString(element.month)),
        new Date(monthString)
      )
  );

  //
  // Branch 1: Deal with being one one breakpoint exactly, and early exit
  //

  if (breakpointOnThisMonthIndex !== -1) {
    const matchedBreakpoint =
      sortedMostRecentVersions[breakpointOnThisMonthIndex];
    const dimensions: NetCostByDateDimensional["dimensions"] = [];

    dimensions.push({
      dimension: "other", // TODO: make into a const /  copyText
      netCost: matchedBreakpoint.version.otherSpend,
    });

    Object.keys(matchedBreakpoint.version.enumeratedValuesSpend).forEach(
      (key) => {
        dimensions.push({
          dimension: key,
          netCost: matchedBreakpoint.version.enumeratedValuesSpend[key],
        });
      }
    );

    return { date: monthString, dimensions };
  }

  //
  // Branch 2: Deal with being between two breakpoints
  //

  const followingBreakpointVersionIndex = sortedMostRecentVersions.findIndex(
    (element) =>
      isAfter(new Date(getUTCMonthString(element.month)), new Date(monthString))
  );

  const before = sortedMostRecentVersions[followingBreakpointVersionIndex - 1];
  const after = sortedMostRecentVersions[followingBreakpointVersionIndex];
  const dimensions: NetCostByDateDimensional["dimensions"] = [];

  // One week adds are to avoid TZ collisions on month borders
  const monthsBetweenBreakpoints = differenceInCalendarMonths(
    new Date(getUTCMonthString(before.month)),
    new Date(getUTCMonthString(after.month))
  );
  const distanceFromPreviousBreakpoint = differenceInCalendarMonths(
    new Date(getUTCMonthString(before.month)),
    new Date(getUTCMonthString(monthString))
  );

  dimensions.push({
    dimension: "other",
    netCost:
      before.version.otherSpend +
      ((after.version.otherSpend - before.version.otherSpend) /
        monthsBetweenBreakpoints) *
        distanceFromPreviousBreakpoint,
  });

  Object.keys(before.version.enumeratedValuesSpend).forEach((key) => {
    dimensions.push({
      dimension: key,
      netCost:
        before.version.enumeratedValuesSpend[key] +
        ((after.version.enumeratedValuesSpend[key] -
          before.version.enumeratedValuesSpend[key]) /
          monthsBetweenBreakpoints) *
          distanceFromPreviousBreakpoint,
    });
  });

  return { date: monthString, dimensions };
}

type RampPlanCSVDictionary = {
  amount: string;
  billingAccountIDs: string;
  commitmentEnd: string;
  commitmentPeriod: number;
  commitmentStart: string;
  date: string;
  grouping: string;
  type: string;
};

export function getCSVFromRampPlan(
  rampPlan: RampPlan,
  actuals: NetCostByDateDimensional[]
): RampPlanCSVDictionary[] {
  const output: RampPlanCSVDictionary[] = [];
  const allMonths = getRampPlanMonths(rampPlan);

  const billingAccountIDs = rampPlan.billingAccountIDs.toString();
  const latestBreakpointVersions = rampPlan.breakpoints.map(getLatestVersion);

  allMonths.forEach((month) => {
    const thisCommitmentIndex = rampPlan.commitments.findIndex((commitment) => {
      if (
        !isBefore(
          new Date(getUTCMonthString(commitment.end)),
          new Date(month.date)
        ) &&
        !isAfter(
          new Date(getUTCMonthString(commitment.start)),
          new Date(month.date)
        )
      ) {
        return true;
      }
    });

    const thisCommitment = rampPlan.commitments[thisCommitmentIndex];

    const commitmentStart = thisCommitment
      ? getUTCMonthString(thisCommitment.start)
      : "";
    const commitmentEnd = thisCommitment
      ? getUTCMonthString(thisCommitment.end)
      : "";

    if (rampPlan.nonExportOffsetRecurring) {
      Object.keys(rampPlan.nonExportOffsetRecurring).forEach((reason) => {
        output.push({
          amount: formatCurrencyRounded({
            number: rampPlan.nonExportOffsetRecurring[reason],
          }),
          billingAccountIDs,
          commitmentEnd,
          commitmentPeriod: thisCommitmentIndex + 1,
          commitmentStart,
          date: month.date,
          grouping: reason.toLowerCase(),
          type: "Recurring Offset",
        });
      });
    }

    if (
      rampPlan.nonExportOffsetByMonth &&
      rampPlan.nonExportOffsetByMonth[month.date]
    ) {
      Object.keys(rampPlan.nonExportOffsetByMonth[month.date]).forEach(
        (reason) => {
          output.push({
            amount: formatCurrencyRounded({
              number: rampPlan.nonExportOffsetByMonth[month.date][reason],
            }),
            billingAccountIDs,
            commitmentEnd,
            commitmentPeriod: thisCommitmentIndex + 1,
            commitmentStart,
            date: month.date,
            grouping: reason.toLowerCase(),
            type: "Month-Specific Offset",
          });
        }
      );
    }

    const rolledUpActuals = rollupDimensionalActualsBasedOnEnumeratedValues(
      actuals,
      rampPlan
    );

    const actualEntity = rolledUpActuals.find((spendEntity) =>
      isSameMonth(
        new Date(getUTCMonthString(spendEntity.date)),
        new Date(getUTCMonthString(month.date))
      )
    );

    if (actualEntity) {
      actualEntity.dimensions.forEach((actualDimension) => {
        output.push({
          amount: formatCurrencyRounded({
            number: actualDimension.netCost,
          }),
          billingAccountIDs,
          commitmentEnd,
          commitmentPeriod: thisCommitmentIndex + 1,
          commitmentStart,
          date: month.date,
          grouping: actualDimension.dimension.toLowerCase(),
          type: "Actual Spend",
        });
      });
    }

    const breakpointFoundForMonth = latestBreakpointVersions.find(
      (version) => version.month === month.date
    );

    if (breakpointFoundForMonth) {
      Object.keys(breakpointFoundForMonth.enumeratedValuesSpend).forEach(
        (dimension) => {
          output.push({
            amount: formatCurrencyRounded({
              number: breakpointFoundForMonth.enumeratedValuesSpend[dimension],
            }),
            billingAccountIDs,
            commitmentEnd,
            commitmentPeriod: thisCommitmentIndex + 1,
            commitmentStart,
            date: month.date,
            grouping: dimension.toLowerCase(),
            type: "Breakpoint", // TODO: copyText
          });
        }
      );

      output.push({
        amount: formatCurrencyRounded({
          number: breakpointFoundForMonth.otherSpend ?? 0,
        }),
        billingAccountIDs,
        commitmentEnd,
        commitmentPeriod: thisCommitmentIndex + 1,
        commitmentStart,
        date: month.date,
        grouping: "other", // TODO: copytext
        type: "Breakpoint", // TODO: copyText
      });
    }

    month.dimensions.forEach((dimensionEntity) => {
      const amount = dimensionEntity.netCost;

      output.push({
        amount: formatCurrencyRounded({
          number: amount,
        }),
        billingAccountIDs,
        commitmentEnd,
        commitmentPeriod: thisCommitmentIndex + 1,
        commitmentStart,
        date: month.date,
        grouping: dimensionEntity.dimension.toLowerCase(),
        type: "Ramp Plan", // TODO: copyText
      });
    });
  });

  return output;
}

export const OTHER_DIMENSION_NAME = "other";

export function rollupDimensionalActualsBasedOnEnumeratedValues(
  actuals: NetCostByDateDimensional[],
  rampPlan: RampPlan
): NetCostByDateDimensional[] {
  return actuals.reduce(
    (accum: NetCostByDateDimensional[], actualSpendEntity) => {
      const reducedDimensions = actualSpendEntity.dimensions.reduce(
        (
          reducedDimensions: {
            dimension: string;
            netCost: number;
          }[],
          dimension
        ) => {
          // Is Enuemrated
          if (rampPlan.enumeratedValues.includes(dimension.dimension)) {
            reducedDimensions.push({
              dimension: dimension.dimension,
              netCost: dimension.netCost,
            });
            // Other Category
          } else {
            const otherIndex = reducedDimensions.findIndex(
              (e) => e.dimension === OTHER_DIMENSION_NAME
            );
            // We already have an Other category
            if (otherIndex !== -1) {
              reducedDimensions[otherIndex].netCost += dimension.netCost;
              // We do not
            } else {
              reducedDimensions.push({
                dimension: OTHER_DIMENSION_NAME,
                netCost: dimension.netCost,
              });
            }
          }

          return reducedDimensions;
        },
        []
      );
      accum.push({
        date: actualSpendEntity.date,
        dimensions: reducedDimensions,
      });
      return accum;
    },
    []
  );
}

export function getComparisonStringForRampPlans(rampPlans: RampPlan[]): string {
  const output: string[] = [];

  rampPlans.forEach((rp) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const idempotentResult: any = { ...rp };
    idempotentResult.commitments = idempotentResult.commitments.map((c) => {
      const copy = { ...c };
      delete copy._id;
      return copy;
    });
    output.push(JSON.stringify(idempotentResult));
  });

  return output.join("");
}

export function applyOffetsToActualSpend(
  actualSpend: NetCostByDate[] = [],
  nonExportOffsetByMonth: RampPlan["nonExportOffsetByMonth"],
  nonExportOffsetRecurring: RampPlan["nonExportOffsetRecurring"]
): NetCostByDate[] {
  const recurringOffset = Object.values(nonExportOffsetRecurring).reduce(
    (total, value) => total + value,
    0
  );
  const modifiedActualSpend = actualSpend.map((spendEntity) => {
    const newEntity = { ...spendEntity };
    newEntity.netCost += recurringOffset;

    const monthString = getUTCMonthString(spendEntity.date);

    const thisMonthOffsets = nonExportOffsetByMonth[monthString] ?? {};
    const thisMonthOffset = Object.values(thisMonthOffsets).reduce(
      (total, value) => total + value,
      0
    );
    newEntity.netCost += thisMonthOffset;

    return newEntity;
  });

  return modifiedActualSpend;
}

export function getPercentChange(
  previousValue: number,
  currentValue: number
): number {
  const value = Math.round(
    100 * ((currentValue - previousValue) / Math.abs(previousValue))
  );
  return !isFinite(value) || isNaN(value) ? 0 : value;
}

export function getAbsoluteAmount(
  previousValue: number,
  percentChange: number
): number {
  return Math.round((percentChange / 100) * previousValue + previousValue);
}
