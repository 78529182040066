// TODO: Consolidate this module and lib/dates.ts
import { TimeGranularity } from "@ternary/api-lib/constants/enums";
import { differenceInDays, differenceInHours } from "date-fns";
import { format, toZonedTime } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import { DateHelper } from "../lib/dates";

export type DateRange = Date[];

export function getPreciseDateRange(dateRange: DateRange): [string, string] {
  return [dateRange[0].toISOString(), dateRange[1].toISOString()];
}

export function getLastInvoiceMonth(): string {
  const dateHelper = new DateHelper();
  return getInvoiceMonth(dateHelper.firstOfLastMonthNoTimezone());
}

// Returns invoice month as recorded by GCP
export function getInvoiceMonth(dateInMonth: Date): string {
  return format(dateInMonth, "yyyyMM");
}

export function roundDate(d: Date): Date {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function roundDay(date: Date): Date {
  date.setDate(0);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function roundMonth(d: Date): Date {
  d.setMonth(0);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

// Using 1-index month numbers
export function getMonthNameForMonthNumber(
  monthNumber: number,
  fullName?: boolean
): string {
  const options = fullName
    ? {}
    : {
        width: "abbreviated",
      };
  return enUS.localize?.month(monthNumber - 1, options);
}

const monthNumbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export function getMonthOptions() {
  return monthNumbers.map((monthNumber) => ({
    label: getMonthNameForMonthNumber(Number(monthNumber)),
    value: monthNumber,
  }));
}

export function getYearOptions() {
  const now = new DateHelper();
  const thisYear = now.date.getFullYear();

  return [
    {
      label: thisYear - 1,
      value: thisYear - 1,
    },
    {
      label: thisYear,
      value: thisYear,
    },
    {
      label: thisYear + 1,
      value: thisYear + 1,
    },
  ];
}

// Deals with getting accurate Month Strings when dates are in UTC (default behavior would be previous month, due to timezone differences)
export function getUTCMonthString(dateString: string): string {
  return format(toZonedTime(new Date(dateString), "UTC"), "yyyy-MM", {
    timeZone: "UTC",
  });
}

export function getGranularityForRange(
  dateRange: Date[],
  extraGranular?: boolean
): TimeGranularity {
  const durationDays = Math.abs(differenceInDays(dateRange[0], dateRange[1]));
  const durationHours = Math.abs(differenceInHours(dateRange[0], dateRange[1]));
  let granularity: TimeGranularity;

  if (extraGranular && durationHours <= 1) {
    granularity = TimeGranularity.SECOND;
  } else if (extraGranular && durationHours <= 12) {
    granularity = TimeGranularity.MINUTE;
  } else if (durationHours <= 24 && extraGranular) {
    granularity = TimeGranularity.HOUR;
  } else if (extraGranular && durationDays <= 31) {
    granularity = TimeGranularity.HOUR;
  } else if (durationDays < 60) {
    granularity = TimeGranularity.DAY;
  } else if (durationDays < 120) {
    granularity = extraGranular ? TimeGranularity.DAY : TimeGranularity.WEEK;
  } else {
    granularity = extraGranular ? TimeGranularity.WEEK : TimeGranularity.MONTH;
  }

  return granularity;
}
