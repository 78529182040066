import { format, isBefore } from "date-fns";
import { DateHelper } from "../../lib/dates";
import { BudgetThreshold } from "./types";

export type MonthSummary = {
  latestID: string | null;
  hasHighlighted: boolean;
  latest: number | null;
  month: string;
  versions: number;
};

type BudgetPeriodVersion = {
  id: string;
  amount: number;
  amountOrigin: string;
  author: string;
  creationTime: string;
  highlighted: boolean;
  period: {
    month: string;
  };
};

type Budget = {
  amount: {
    specifiedAmount: { amount: number } | null;
    variableAmount: Record<string, never> | null;
  };
  periodVersions: BudgetPeriodVersion[];
};

export function getAmountFromBudget(budget: Budget): number | null {
  if (budget.amount.variableAmount) {
    if (!budget.periodVersions) return null;

    const now = new DateHelper();
    const thisMonthString = format(now.date, "yyyy-MM");

    const thisMonthsVersions = budget.periodVersions.filter(
      (version) => version.period.month === thisMonthString
    );

    const amount = getLatestAmountForMonth(thisMonthsVersions);
    return amount;
  }

  return budget.amount.specifiedAmount
    ? budget.amount.specifiedAmount.amount
    : null;
}

export function createGetBudgetAmountAtMonth(
  budget: Budget | null
): (monthKey: string) => number | null {
  if (!budget) {
    return () => null;
  }

  const latestVersionKeyedByMonth = getLatestVersionsKeyedByMonth(budget);

  return (monthKey) => {
    // monthKey format: yyyy-MM
    if (budget.amount.specifiedAmount) {
      return budget.amount.specifiedAmount.amount;
    }

    const periodVersion = latestVersionKeyedByMonth[monthKey];

    if (!periodVersion) return null;

    return periodVersion.amount;
  };
}

export function getLatestVersionForMonth(
  versions: BudgetPeriodVersion[]
): BudgetPeriodVersion | null {
  if (!versions || versions.length === 0) return null;

  const sorted = versions.sort((a, b) => {
    if (
      isBefore(
        new Date(a.creationTime as string),
        new Date(b.creationTime as string)
      )
    ) {
      return 1;
    }
    return -1;
  });

  return sorted[0];
}

export function getLatestAmountForMonth(
  versions: BudgetPeriodVersion[]
): number | null {
  if (!versions || versions.length === 0) return null;

  return getLatestVersionForMonth(versions)?.amount ?? null;
}

export function getLatestVersionsKeyedByMonth(budget: Budget): {
  [month: string]: BudgetPeriodVersion | null;
} {
  const allGrouped = getPeriodVersionsGroupedByMonth(budget);

  const output: { [month: string]: BudgetPeriodVersion | null } = {};

  Object.keys(allGrouped).forEach((month) => {
    output[month] = getLatestVersionForMonth(allGrouped[month]);
  });

  return output;
}

export function getSortedMonthsFromPeriodVersions(
  budget: Budget,
  ascending: boolean
): string[] {
  const periodVersionsGroupedByMonth = getPeriodVersionsGroupedByMonth(budget);

  return Object.keys(periodVersionsGroupedByMonth).sort((a, b) => {
    if (isBefore(new Date(b as string), new Date(a as string))) {
      return ascending ? 1 : -1;
    }
    return ascending ? -1 : 1;
  });
}

export function getPeriodVersionsGroupedByMonth(budget: Budget): {
  [month: string]: BudgetPeriodVersion[];
} {
  const periodVersionsGroupedByMonth: {
    [month: string]: BudgetPeriodVersion[];
  } = budget.periodVersions
    ? budget.periodVersions.reduce((accum, pv) => {
        const month = pv.period.month;
        if (month && accum[month]) {
          accum[month].push(pv);
        } else if (month) {
          accum[month] = [pv];
        }

        return accum;
      }, {})
    : {};

  return periodVersionsGroupedByMonth;
}

export function getAllMonthSummariesFromBudget(budget: Budget): MonthSummary[] {
  const periodVersionsGroupedByMonth = getPeriodVersionsGroupedByMonth(budget);

  const allMonthSummaries: MonthSummary[] = Object.keys(
    periodVersionsGroupedByMonth
  )
    .map((month) => {
      const thisMonthVersions = periodVersionsGroupedByMonth[month];
      const latestVersion = getLatestVersionForMonth(thisMonthVersions);
      return {
        latestID: latestVersion?.id ?? null,
        hasHighlighted:
          thisMonthVersions.find((v) => v.highlighted) !== undefined,
        latest: getLatestAmountForMonth(thisMonthVersions),
        month,
        versions: thisMonthVersions.length,
      };
    })
    .sort((a, b) => {
      if (isBefore(new Date(a.month as string), new Date(b.month as string))) {
        return -1;
      }
      return 1;
    });

  return allMonthSummaries;
}

export function getHasHighlightKeyedByMonth(budget: Budget): {
  [month: string]: boolean;
} {
  const periodVersionsGroupedByMonth = getPeriodVersionsGroupedByMonth(budget);

  const output: { [month: string]: boolean } = {};

  Object.keys(periodVersionsGroupedByMonth).forEach((month) => {
    output[month] =
      periodVersionsGroupedByMonth[month].find((v) => v.highlighted) !==
      undefined;
  });

  return output;
}

export function getSortedBudgetPeriodVersions(
  versions: BudgetPeriodVersion[],
  ascending: boolean
): BudgetPeriodVersion[] {
  const output = [...versions];

  output.sort((a, b) => {
    if (
      isBefore(
        new Date(a.creationTime as string),
        new Date(b.creationTime as string)
      )
    ) {
      return ascending ? 1 : -1;
    }
    return ascending ? -1 : 1;
  });

  return output;
}

export function getSortedThresholds(
  thresholds: BudgetThreshold[]
): BudgetThreshold[] {
  return thresholds
    ? thresholds.sort((a, b) => {
        if (a.percent > b.percent) return 1;
        return -1;
      })
    : [];
}

export function getYearsFromBudget(budget: Budget): number[] {
  const sortedMonths = getSortedMonthsFromPeriodVersions(budget, true);
  return Object.keys(
    sortedMonths.reduce((accum, monthString) => {
      return { ...accum, [monthString.split("-")[0]]: true };
    }, {})
  ).map((str) => Number(str));
}
