import { useTheme } from "@emotion/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  differenceInCalendarMonths,
  isAfter,
  isBefore,
  startOfHour,
} from "date-fns";
import { noop } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import useGetNetCostByBillingAccountIDs from "../../../api/analytics/hooks/useGetNetCostByBillingAccountIDs";
import paths from "../../../constants/paths";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import Divider from "../../../ui-lib/components/Divider";
import Grid from "../../../ui-lib/components/Grid";
import RampPlanChartSection from "../../ramp-plans/components/RampPlanChartSection";
import { renderVariance } from "../../ramp-plans/components/RampPlansTable";
import useGetRampPlansByTenantID from "../../ramp-plans/hooks/useGetRampPlansByTenantID";
import { NetCostByRampPlanID } from "../../ramp-plans/types";
import {
  applyOffetsToActualSpend,
  getProjectionsForRampPlan,
  getStartOfRampPlan,
} from "../../ramp-plans/utils/rampPlans";
import copyText from "../copyText";

interface Props {
  rampPlanID: string;
}

export default function RampPlanContentContainer(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();

  const theme = useTheme();
  const now = new DateHelper();

  const selectedPricingDataKey = "netCost";

  const { data: rampPlanData = [], isLoading: isLoadingRampPlans } =
    useGetRampPlansByTenantID(authenticatedUser.tenant.fsDocID);

  const rampPlans = rampPlanData?.map((rampPlan) => {
    return {
      ...rampPlan,
      commitments:
        rampPlan.commitments.map((commitment) => ({
          ...commitment,
          _id: uuidv4(),
        })) || [],
    };
  });

  const rampPlan = rampPlans?.filter(
    (rampPlan) => rampPlan.id === props.rampPlanID
  )[0];

  const [
    { data: actualsForSelected = [], isLoading: isLoadingActualsForSelected },
  ] = useGetNetCostByBillingAccountIDs(
    [
      {
        billingAccountIDs: rampPlan?.billingAccountIDs ?? [],
        dateRange: [
          ...(rampPlan
            ? [new Date(getStartOfRampPlan(rampPlan.commitments))]
            : []),
          startOfHour(now.lastDayLastMonth()),
        ],
      },
    ],
    { enabled: rampPlan !== undefined && gatekeeper.canListRampPlans }
  );

  const previousNetCostQueryResultList = useGetNetCostByBillingAccountIDs(
    rampPlans
      ? rampPlans.map((rampPlan) => ({
          billingAccountIDs: rampPlan?.billingAccountIDs,
          dateRange: [
            new Date(getStartOfRampPlan(rampPlan.commitments)),
            startOfHour(now.lastDayLastMonth()),
          ],
        }))
      : [],
    {
      enabled: rampPlans !== undefined && gatekeeper.canListRampPlans,
    }
  );

  if (isLoadingRampPlans || !rampPlan) {
    return (
      <EmptyPlaceholder
        loading={true}
        icon={faList}
        text={
          !gatekeeper.canListRampPlans
            ? copyText.emptyPlaceholderInsufficientPermission
            : undefined
        }
      />
    );
  }

  const isLoadingPreviousNetCostByRampPlan =
    previousNetCostQueryResultList.some((query) => query.isLoading);

  // Used to figure out all derived projections in the table
  const previousNetCostByRampPlanID = rampPlans
    ? previousNetCostQueryResultList.reduce(
        (accum: NetCostByRampPlanID, res, i) => {
          const rampPlanID = rampPlans[i]?.id;

          res.data?.forEach((entry) => {
            const netCost = entry[selectedPricingDataKey];

            const date = entry.date;

            if (accum[rampPlanID]) {
              accum[rampPlanID].push({ date, netCost });
            } else {
              accum[rampPlanID] = [{ date, netCost }];
            }
          });
          return accum;
        },
        {}
      )
    : {};

  const totalPeriods = rampPlan.commitments.length;
  const currentPeriodIndex = rampPlan.commitments.findIndex((commitment) => {
    return (
      !isAfter(new Date(commitment.start), new Date()) &&
      !isBefore(new Date(commitment.end), new Date())
    );
  });

  const isNotCurrent = currentPeriodIndex === -1;

  const commitmentPeriodPosition = isNotCurrent
    ? copyText.notAvailable
    : `${currentPeriodIndex + 1} of ${totalPeriods}`;

  const currentCommitmentAmount = isNotCurrent
    ? copyText.notAvailable
    : rampPlan.commitments[currentPeriodIndex].amount;

  const monthsRemainingInCurrentPeriod = isNotCurrent
    ? copyText.notAvailable
    : differenceInCalendarMonths(
        new Date(),
        new Date(rampPlan.commitments[currentPeriodIndex].end)
      ).toString() +
      " " +
      copyText.unitMonthLabel;

  const { projectedVarianceAbsolute, projectedVariancePercentage } =
    isLoadingPreviousNetCostByRampPlan
      ? { projectedVarianceAbsolute: 0, projectedVariancePercentage: 0 }
      : isNotCurrent
        ? {
            projectedVarianceAbsolute: copyText.notAvailable,
            projectedVariancePercentage: copyText.notAvailable,
          }
        : getProjectionsForRampPlan(
            rampPlan,
            applyOffetsToActualSpend(
              previousNetCostByRampPlanID[rampPlan.id],
              rampPlan.nonExportOffsetByMonth,
              rampPlan.nonExportOffsetRecurring
            )
          );

  const chartSectionLoading =
    isLoadingPreviousNetCostByRampPlan ||
    isLoadingRampPlans ||
    isLoadingActualsForSelected;

  return (
    <Flex direction="column" height="100%" minHeight={500} width="100%">
      <Box
        paddingBottom={theme.space_md}
        marginTop={theme.space_sm}
        overflow="auto"
      >
        <Text fontSize={theme.h4_fontSize}>{rampPlan.name}</Text>
        <RampPlanChartSection
          actualSpend={actualsForSelected.map((entry) => ({
            date: entry.date,
            netCost: entry[selectedPricingDataKey],
          }))}
          hideDimensionalAnalysis
          hideTopMeter
          loading={chartSectionLoading}
          loadingDimensional={false}
          previousNetCostByRampPlanID={previousNetCostByRampPlanID}
          rampPlan={rampPlan}
          onInteraction={noop}
        />
        <Divider margin={0} />
        <Grid
          gridTemplateColumns={"repeat(auto-fit, minmax(12rem, 1fr))"}
          gridColumnGap="1rem"
          gridRowGap="1rem"
          marginLeft="3rem"
          padding={theme.space_md}
        >
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.rampPlanCurrentCommitmentAmount}
            </Text>
            <Text>
              {typeof currentCommitmentAmount === "string"
                ? `${currentCommitmentAmount}`
                : formatCurrencyRounded({ number: currentCommitmentAmount })}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.rampPlanProjectedVarianceAbsolute}
            </Text>
            <Text>
              {typeof projectedVarianceAbsolute === "string"
                ? `${projectedVarianceAbsolute}`
                : renderVariance({
                    formattedValue: formatCurrencyRounded({
                      accounting: true,
                      number: projectedVarianceAbsolute,
                    }),
                    theme,
                    value: projectedVarianceAbsolute,
                  })}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.rampPlanProjectedVariancePercentage}
            </Text>
            <Text>
              {typeof projectedVariancePercentage === "string"
                ? `${projectedVariancePercentage}`
                : renderVariance({
                    formattedValue: formatPercentage(
                      projectedVariancePercentage
                    ),
                    theme,
                    value: projectedVariancePercentage,
                  })}
            </Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.rampPlanCurrentPeriod}
            </Text>
            <Text>{`${commitmentPeriodPosition}`}</Text>
          </Flex>
          <Flex direction="column">
            <Text fontSize={theme.fontSize_ui} fontWeight="bold">
              {copyText.rampPlanRemainingMonths}
            </Text>
            <Text>{`${monthsRemainingInCurrentPeriod}`}</Text>
          </Flex>
        </Grid>
      </Box>
      <Flex justifyContent="flex-end" marginTop={theme.space_xs}>
        <Link
          to={{
            pathname: paths._costAssistRampPlans,
            search: `?selectedRampPlanIDToDisplay=${rampPlan.id}`,
          }}
        >
          {copyText.goToResourceLinkCaption}
        </Link>
      </Flex>
    </Flex>
  );
}
