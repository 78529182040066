import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable, Unwrap } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const S3BucketStruct = {
  accountID: nullable(string()),
  bucketName: nullable(string()),
  bytesDownloaded: nullable(number()),
  bytesUploaded: nullable(number()),
  cost: nullable(number()),
  credits: nullable(number()),
  lineItemUsageAccountID: nullable(string()),
  networkCost: nullable(number()),
  numberOfObjects: nullable(number()),
  operationsCost: nullable(number()),
  region: nullable(string()),
  requestCount: nullable(number()),
  storageClass: nullable(string()),
  storageCost: nullable(number()),
  storageUsedBytes: nullable(number()),
};

export type S3Bucket = NotNullable<Infer<typeof S3BucketStruct>>;

export type S3BucketGroup = Unwrap<
  Pick<
    S3Bucket,
    | "accountID"
    | "cost"
    | "credits"
    | "region"
    | "networkCost"
    | "operationsCost"
    | "storageCost"
  > & {
    groupID: string;
    buckets: S3Bucket[];
  }
>;

export type S3BucketGroupFilters = {
  accountID: string | null;
  region: string | null;
};
