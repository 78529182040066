import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useState } from "react";
import copyText from "../../copyText";
import { ORG_ID_REGEX, getErrorMessageIfPresent } from "./GCPIntegrationForm";
import { GCPIntegration } from "./types";

interface Props {
  loading: boolean;
  integration?: GCPIntegration;
  onUpdate: (name: string, rootElement: string) => void;
}

interface State {
  inputName: string;
  inputOrganizationID: string;
}

const initialState: State = {
  inputName: "",
  inputOrganizationID: "",
};

export default function GCPCloudFormBasic(props: Props): JSX.Element {
  const theme = useTheme();

  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (!props.integration) {
      mergeState(initialState);
      return;
    }

    mergeState({
      inputName: props.integration.name,
      inputOrganizationID: props.integration.config.rootElement || "",
    });
  }, [props.integration]);

  useEffect(() => {
    props.onUpdate(state.inputName, state.inputOrganizationID);
  }, [state.inputName, state.inputOrganizationID]);

  const assetExportError = getErrorMessageIfPresent(
    props.integration,
    CloudCapability.INVENTORY_EXPORT
  );

  return (
    <Box height={416} padding={theme.space_xs} scrollable>
      {assetExportError && (
        <Box
          backgroundColor={theme.feedback_warn_background}
          borderRadius={theme.borderRadius_2}
          marginBottom={theme.space_sm}
          padding={theme.space_sm}
        >
          <Text>{assetExportError}</Text>
        </Box>
      )}
      <FormField
        input={TextInput}
        label={copyText.cloudAttributeName}
        required
        value={state.inputName}
        variant={state.inputName.trim().length > 0 ? "success" : "danger"}
        onChange={(e) => mergeState({ inputName: e.target.value })}
      />
      <FormField
        input={TextInput}
        caption={copyText.cloudHelpRootElement}
        label={copyText.cloudAttributeRootElement}
        placeholder={copyText.cloudPlaceholderRootElement}
        value={state.inputOrganizationID}
        variant={
          assetExportError === undefined &&
          ORG_ID_REGEX.test(state.inputOrganizationID)
            ? "success"
            : "danger"
        }
        onChange={(e) => mergeState({ inputOrganizationID: e.target.value })}
      />
    </Box>
  );
}
