import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import React from "react";
import Chip from "../../../../ui-lib/components/Chip";
import copyText from "../../copyText";

type Props = {
  filters: Record<string, string>;
  onChangeFilter: (key: string, value: string | null) => void;
};

export default function AWSAllocationFilterControls(props: Props) {
  const keys = Object.keys(props.filters);

  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {keys.map((key) => (
          <Chip
            key={key}
            label={labels[key] ?? key}
            text={props.filters[key]}
            onClick={() => props.onChangeFilter(key, null)}
          />
        ))}
      </Flex>
    </Flex>
  );
}

const labels = {
  commitmentARN: copyText.awsAllocation_commitmentARN,
  commitmentType: copyText.awsAllocation_commitmentType,
  coverageType: copyText.awsAllocation_coverageType,
  region: copyText.awsAllocation_region,
  billPayerAccountId: copyText.awsAllocation_billPayerAccountId,
  lineItemUsageAccountId: copyText.awsAllocation_lineItemUsageAccountId,
  offeringType: copyText.awsAllocation_offeringType,
};
