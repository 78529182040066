import registry from "unilib-registry/instance";

type AuthenticatedUserEntity = {
  tenant: { currencyCode: string };
};

const currencyLocale =
  typeof window !== "undefined" ? navigator?.language : "en-US";

function getCurrencyCode(currencyCode?: string): string {
  if (typeof window !== "undefined") {
    const authenticatedUser =
      registry.get<AuthenticatedUserEntity>("authenticatedUser");
    return authenticatedUser.tenant.currencyCode;
  }

  return currencyCode ?? "USD";
}

export const formatNumberRounded = (num: number): string => {
  return new Intl.NumberFormat(currencyLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(num);
};

export const formatNumber = (num: number, round?: number): string => {
  return new Intl.NumberFormat(currencyLocale, {
    maximumFractionDigits: round ?? 3,
  }).format(num);
};

export const formatCurrency = (options: {
  accounting?: boolean;
  currencyCode?: string;
  number: number;
}): string => {
  const currencyCode = getCurrencyCode(options.currencyCode);

  if (Math.abs(options.number) < 0.001) {
    options.number = 0;
  }

  return new Intl.NumberFormat(currencyLocale, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    ...(options.accounting ? { currencySign: "accounting" } : {}),
  }).format(options.number);
};

export const formatCurrencyRounded = (options: {
  accounting?: boolean;
  currencyCode?: string;
  number: number;
}): string => {
  const currencyCode = getCurrencyCode(options.currencyCode);

  if (Math.abs(options.number) >= 10000000) {
    return new Intl.NumberFormat(currencyLocale, {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 2,
      notation: "compact",
      compactDisplay: "long",
      ...(options.accounting ? { currencySign: "accounting" } : {}),
    }).format(options.number);
  } else {
    return new Intl.NumberFormat(currencyLocale, {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...(options.accounting ? { currencySign: "accounting" } : {}),
    }).format(options.number);
  }
};

export const formatKilograms = (num: number, isAxis?: boolean) => {
  const kilogram = new Intl.NumberFormat(currencyLocale, {
    style: "unit",
    unit: "kilogram",
    minimumFractionDigits: isAxis ? 0 : 2,
    notation: "compact",
    compactDisplay: "long",
  }).format(num);

  if (isAxis) {
    return `${kilogram}`;
  }

  const superScript = String.fromCharCode(0x2082);
  return `${kilogram} CO${superScript}`;
};

export const formatPercentage = (
  num: number,
  maximumFractionDigits?: number
): string => {
  if (Number.isNaN(num)) {
    return "-- %";
  }

  if (!Number.isFinite(num)) {
    return "0 %";
  }

  return new Intl.NumberFormat(currencyLocale, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits ?? 1,
  }).format(num);
};

export const numericFromFormatted = (str: string): number => {
  return Number(str.split(",").join(""));
};
