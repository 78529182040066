import useGetRecommendationsByTenantID from "@/api/core/hooks/useGetRecommendationsByTenantID";
import paths from "@/constants/paths";
import useGetAWSReservedInstanceRecommendations from "@/features/committed-use/aws/hooks/useGetAWSReservedInstanceRecommendations";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useMatchPath } from "@/lib/react-router";
import { keyframes, useTheme } from "@emotion/react";
import {
  faGripHorizontal,
  faInfoCircle,
  faList,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  AWSRateAccountScope,
  CloudProviderType,
  GcpCommitmentDurationType,
  GcpCommitmentServiceType,
  RecommendationCategory,
  SavingsOpportunityType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { FindRecommendationQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationQuery";
import {
  AWSRateReservedInstanceRecommendationEntity,
  AWSRateSavingsPlanRecommendationEntity,
  CudRecommendationEntity,
} from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import {
  formatCurrency,
  formatCurrencyRounded,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useEffect, useMemo, useState } from "react";
import useGetCudRecommendationsByTenantID from "../../../features/committed-use/gcp/hooks/useGetCudRecommendationsByTenantID";
import useGetAWSSavingsPlanRecommendations from "../../committed-use/aws/hooks/useGetAWSSavingsPlanRecommendations";
import {
  AWSCommittedUseFilter,
  AWSCommittedUseLookbackPeriod,
  AWSCommittedUseOfferingClass,
  AWSCommittedUsePaymentOption,
  AWSCommittedUseSavingsPlanType,
  AWSCommittedUseTerm,
} from "../../committed-use/aws/types";
import { getPayerAccountIDs } from "../../committed-use/aws/utils";
import copyText from "../copyText";

const customFontSize = "2.5rem";
const defaultRecommendations = [];
const defaultGcpCudRecommendations = [];
const defaultSavingsPlanRecommendations = [];
const defaultReservedInstanceRecommendations = [];

type SpendSummary = {
  lastMonthSpend: number;
  lastMTDSpend: number;
  potentialRecSavings: number;
  recommendationTotalsKeyedByCategory: {
    [category: string]: number;
  };
  thisMTDSpend: number;
};

export type SavingsOpportunityFilter = {
  id: string;
  tenantID: string;
  category: RecommendationCategory | null;
  cloudProviderType: CloudProviderType | null;
  createdAt: string;
  createdByID: string;
  name: string;
  savingsType: SavingsOpportunityType;
  serviceType: ServiceType | null;
  updatedAt: string | null;
  updatedByID: string | null;
};

interface Props {
  dashboardID: string;
  index: number;
  isEditLayout?: boolean;
  savingsOpportunity: SavingsOpportunityFilter;
  onInteraction: (
    interaction: SavingsOpportunityViewContainer.Interaction
  ) => void;
}

export function SavingsOpportunityViewContainer(props: Props): JSX.Element {
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();
  const authenticatedUser = useAuthenticatedUser();
  const canSaveGlobalSavingOpp = gatekeeper.canEditGlobalSavingsOpp;

  const showGripIcon = currentPath === paths._dashboard && props.isEditLayout;
  const showDeleteButton = currentPath === paths._dashboard;
  const isGlobalSavingOpp =
    props.savingsOpportunity.tenantID === SYSTEM_TENANT_ID;

  const cudMultiplier =
    authenticatedUser.settings.cudRecCommitmentLength ===
    GcpCommitmentDurationType.TWELVE_MONTH
      ? 12
      : 36;

  //
  // Queries
  //

  // Check if MSP settings have turned off viewing Rate recs.
  let canViewSavingsOpp = true;

  // If this is undefined it means that all CSPs are included.
  if (!props.savingsOpportunity.cloudProviderType) {
    canViewSavingsOpp =
      gatekeeper.canViewAwsRateRecs &&
      gatekeeper.canViewAzureRateRecs &&
      gatekeeper.canViewGcpRateRecs;
  } else if (
    props.savingsOpportunity.cloudProviderType === CloudProviderType.AWS
  ) {
    canViewSavingsOpp = gatekeeper.canViewAwsRateRecs;
  } else if (
    props.savingsOpportunity.cloudProviderType === CloudProviderType.AZURE
  ) {
    canViewSavingsOpp = gatekeeper.canViewAzureRateRecs;
  } else if (
    props.savingsOpportunity.cloudProviderType === CloudProviderType.GCP
  ) {
    canViewSavingsOpp = gatekeeper.canViewGcpRateRecs;
  }

  const {
    data: recommendations = defaultRecommendations,
    isLoading: isLoadingRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    category: props.savingsOpportunity.category ?? undefined,
    cloudProviderType: props.savingsOpportunity.cloudProviderType ?? undefined,
    serviceType: props.savingsOpportunity.serviceType ?? undefined,
    enabled: gatekeeper.canListRecommendations && canViewSavingsOpp,
  });

  const {
    data: gcpCudRecommendations = defaultGcpCudRecommendations,
    isLoading: isLoadingGcpCudRecommendations,
  } = useGetCudRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: gatekeeper.canListRecommendations && canViewSavingsOpp,
  });

  const {
    data: savingsPlanRecommendations = defaultSavingsPlanRecommendations,
    isLoading: isLoadingAwsSavingsPlanRecommendations,
  } = useGetAWSSavingsPlanRecommendations(authenticatedUser.tenant.id, {
    enabled: canViewSavingsOpp,
  });

  const {
    data: reservedInstanceRecommendations = defaultReservedInstanceRecommendations,
    isLoading: isLoadingReservedInstanceRecommendations,
  } = useGetAWSReservedInstanceRecommendations(authenticatedUser.tenant.id, {
    enabled: canViewSavingsOpp,
  });

  const payerAccountIDs = getPayerAccountIDs(reservedInstanceRecommendations);
  // Reservied Instance
  const filteredRIs = useMemo(
    () => getFilteredRIs(reservedInstanceRecommendations, payerAccountIDs[0]),
    [reservedInstanceRecommendations]
  );

  // SAVINGS PLANS
  const filteredSPs = useMemo(
    () => getFilteredSPs(savingsPlanRecommendations, payerAccountIDs[0]),
    [savingsPlanRecommendations]
  );

  const recommendationsSavingsEstimate = recommendations.reduce(
    (
      accum: { [category: string]: number },
      rec: FindRecommendationQueryResult["recommendation"]
    ) => {
      if (!rec.applicable || !["new", "accepted"].includes(rec.state)) {
        return accum;
      }

      if (accum[rec.serviceType]) {
        accum[rec.serviceType] = accum[rec.serviceType] + rec.estimateValue;
      } else {
        accum[rec.serviceType] = rec.estimateValue;
      }

      return accum;
    },
    {}
  );

  const cudRecommendationsSavingsEstimate =
    props.savingsOpportunity.cloudProviderType === CloudProviderType.AWS
      ? {}
      : gcpCudRecommendations.reduce(
          (
            accum: { [category: string]: number },
            rec: CudRecommendationEntity
          ) => {
            if (!rec.isApplicable || rec.isHidden) {
              return accum;
            }

            if (accum[rec.serviceType]) {
              accum[rec.serviceType] =
                accum[rec.serviceType] +
                rec.estimatedMonthlySavings * cudMultiplier;
            } else {
              accum[rec.serviceType] =
                rec.estimatedMonthlySavings * cudMultiplier;
            }

            return accum;
          },
          {}
        );

  const awsReservedInstanceRecommendationsSavingsEstimate =
    props.savingsOpportunity.cloudProviderType === CloudProviderType.GCP
      ? {}
      : filteredRIs.reduce(
          (
            accum: { [term: string]: number },
            rec: AWSRateReservedInstanceRecommendationEntity
          ) => {
            if (accum[`awsReservedInstance`]) {
              accum[`awsReservedInstance`] =
                accum[`awsReservedInstance`] +
                rec.estimatedMonthlySavingsAmount;
            } else {
              accum[`awsReservedInstance`] = rec.estimatedMonthlySavingsAmount;
            }

            return accum;
          },
          {}
        );

  const awsSavingsPlanRecommendationsSavingsEstimate =
    props.savingsOpportunity.cloudProviderType === CloudProviderType.GCP
      ? {}
      : filteredSPs.reduce(
          (
            accum: { [term: string]: number },
            rec: AWSRateSavingsPlanRecommendationEntity
          ) => {
            if (accum[`awsSavingsPlan`]) {
              accum[`awsSavingsPlan`] =
                accum[`awsSavingsPlan`] + rec.estimatedMonthlySavingsAmount;
            } else {
              accum[`awsSavingsPlan`] = rec.estimatedMonthlySavingsAmount;
            }

            return accum;
          },
          {}
        );

  let recommendationsTotalsKeyedByCategory = {
    ...recommendationsSavingsEstimate,
  };
  if (
    props.savingsOpportunity.savingsType ===
    SavingsOpportunityType.RATE_AND_USAGE
  ) {
    recommendationsTotalsKeyedByCategory = {
      ...cudRecommendationsSavingsEstimate,
      ...recommendationsSavingsEstimate,
      ...awsReservedInstanceRecommendationsSavingsEstimate,
      ...awsSavingsPlanRecommendationsSavingsEstimate,
    };
  } else if (
    props.savingsOpportunity.savingsType === SavingsOpportunityType.RATE
  ) {
    recommendationsTotalsKeyedByCategory = {
      ...cudRecommendationsSavingsEstimate,
      ...awsReservedInstanceRecommendationsSavingsEstimate,
      ...awsSavingsPlanRecommendationsSavingsEstimate,
    };
  }

  const potentialRecSavings = Object.values(
    recommendationsTotalsKeyedByCategory
  ).reduce((accum, val) => accum + val, 0);

  //
  // Render
  //

  const isLoadingRecs =
    isLoadingRecommendations ||
    isLoadingGcpCudRecommendations ||
    isLoadingReservedInstanceRecommendations ||
    isLoadingAwsSavingsPlanRecommendations;

  return (
    <Box height="100%">
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height="100%"
        paddingBottom={theme.space_md}
        paddingHorizontal={theme.space_md}
        width="100%"
      >
        {showGripIcon && (
          <Flex
            alignItems="center"
            justifyContent="center"
            paddingTop={theme.space_xxs}
          >
            <Flex marginBottom={`-${theme.space_sm}`}>
              <Icon
                color={theme.report_display_grip_color}
                draggable
                icon={faGripHorizontal}
                size="lg"
              />
              <Box marginLeft={1}>
                <Icon
                  color={theme.report_display_grip_color}
                  draggable
                  icon={faGripHorizontal}
                  size="lg"
                />
              </Box>
            </Flex>
          </Flex>
        )}
        <Box height="100%" width="100%">
          <Box paddingVertical={theme.space_xs}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box
                maxHeight={`calc(${theme.h4_fontSize} * 1.5)`}
                overflow="hidden"
              >
                <Tooltip
                  content={`${copyText.nameInputLabel}: ${props.savingsOpportunity.name}`}
                  delayHide={250}
                  width={"250px"}
                >
                  {isGlobalSavingOpp && !canSaveGlobalSavingOpp ? (
                    <Text fontSize={theme.h4_fontSize}>
                      {props.savingsOpportunity.name}
                    </Text>
                  ) : (
                    <Text
                      appearance="link"
                      fontSize={theme.h4_fontSize}
                      onClick={() =>
                        props.onInteraction({
                          type: SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED,
                          savingsOpportunityFilterID:
                            props.savingsOpportunity.id,
                          index: props.index,
                        })
                      }
                    >
                      {props.savingsOpportunity.name}
                    </Text>
                  )}
                </Tooltip>
              </Box>

              <Flex alignItems="center">
                <Tooltip
                  content={renderTooltip(
                    potentialRecSavings,
                    recommendationsTotalsKeyedByCategory
                  )}
                  placement="bottom"
                >
                  <Flex alignItems="center">
                    <Text
                      color={theme.text_color_secondary}
                      whiteSpace="nowrap"
                      marginRight={theme.space_xs}
                    >
                      {copyText.widgetTitleSavingsOpportunities}
                    </Text>

                    <Icon
                      icon={faInfoCircle}
                      color={theme.text_color_secondary}
                    />
                  </Flex>
                </Tooltip>
                {showDeleteButton && (
                  <Button
                    disabled={!gatekeeper.canUpdateDashboard(props.dashboardID)}
                    iconStart={<Icon icon={faTrashAlt} />}
                    marginLeft={theme.space_xs}
                    size="small"
                    onClick={() =>
                      props.onInteraction({
                        type: SavingsOpportunityViewContainer.INTERACTION_DELETE_BUTTON_CLICKED,
                        index: props.index,
                      })
                    }
                  />
                )}
              </Flex>
            </Flex>
          </Box>
          <Flex
            alignItems="center"
            direction="column"
            height="85%"
            justifyContent="center"
            width="100%"
          >
            {!canViewSavingsOpp || isLoadingRecs ? (
              <Box maxHeight={300} maxWidth={600}>
                <EmptyPlaceholder
                  height="100%"
                  icon={faList}
                  loading={isLoadingRecs}
                  skeletonVariant="meter"
                  small
                  text={copyText.disabledSavingsOppsPlaceholderText}
                  width="100%"
                />
              </Box>
            ) : (
              <>
                <Flex direction="column" alignItems="center">
                  <Flex alignItems="center">
                    <Text
                      as="div"
                      bold
                      fontSize={theme.fontSize_ui}
                      color={theme.secondary_color}
                    >
                      {getSavingTitleFromKeys(props.savingsOpportunity)}
                    </Text>
                  </Flex>

                  <Text as="div" bold fontSize={customFontSize}>
                    <AnimatedText durationMS={200} delayMS={50}>
                      {formatCurrency({ number: potentialRecSavings })}
                    </AnimatedText>
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

const pathMap = {
  [ServiceType.BIGQUERY]: paths._insightsBigQuery,
  [ServiceType.EBS]: paths._insightsStorageEBS,
  [ServiceType.EC2]: paths._insightsComputeEC2,
  [ServiceType.EKS]: paths._insightsKubernetesEKS,
  [ServiceType.GCE]: paths._insightsComputeGCE,
  [ServiceType.GCS]: paths._insightsStorageGCS,
  [ServiceType.GKE]: paths._insightsKubernetesGKE,
  [ServiceType.RDS]: paths._insightsDatabaseRDS,
  [ServiceType.S3]: paths._insightsStorageS3,
  [ServiceType.SQL]: paths._insightsDatabaseCloudSQL,
};

function getPathFromServiceType(serviceType: string) {
  if (
    serviceType === "awsSavingsPlan" ||
    serviceType === "awsReservedInstance"
  ) {
    return paths._commitmentDiscountAWS;
  }

  if (Object.keys(GcpCommitmentServiceType).includes(serviceType)) {
    return paths._commitmentDiscountGCP;
  }

  return pathMap[serviceType];
}

function getQueryFromServiceType(serviceType: string) {
  if (Object.keys(GcpCommitmentServiceType).includes(serviceType)) {
    return "?enhanced_view=1";
  } else if (serviceType === "awsReservedInstance") {
    return "?tab=optimizations&type=RI";
  }
  return "?tab=optimizations";
}

function getReadableKey(key: string): string {
  // will change this when go to remove meters from home page
  const copyTextKey = `widgetTooltip_${key}`;

  if (copyText[copyTextKey]) {
    return copyText[copyTextKey];
  } else {
    return key;
  }
}

function getSavingTitleFromKeys(savingsOpportunity: SavingsOpportunityFilter) {
  if (
    savingsOpportunity.savingsType === SavingsOpportunityType.RATE_AND_USAGE
  ) {
    return copyText.savingOpportunityWidgetTotalSavingsWithCudsTitle;
  } else if (savingsOpportunity.savingsType === SavingsOpportunityType.RATE) {
    return copyText.savingOpportunityWidgetCUDTotalSavingsTitle;
  } else if (savingsOpportunity.serviceType) {
    return getReadableKey(savingsOpportunity.serviceType);
  } else if (
    savingsOpportunity.cloudProviderType &&
    savingsOpportunity.category
  ) {
    return `${savingsOpportunity.cloudProviderType} - ${savingsOpportunity.category}`;
  } else if (
    savingsOpportunity.cloudProviderType &&
    !savingsOpportunity.category
  ) {
    return savingsOpportunity.cloudProviderType;
  } else if (savingsOpportunity.category) {
    return savingsOpportunity.category;
  }
  return copyText.savingOpportunityWidgetTotalSavingsTitle;
}

function renderTooltip(
  potentialRecSavings: SpendSummary["potentialRecSavings"],
  recommendationTotalsKeyedByCategory: SpendSummary["recommendationTotalsKeyedByCategory"]
): JSX.Element {
  const theme = useTheme();

  const entries = Object.entries(recommendationTotalsKeyedByCategory);

  return (
    <table>
      <thead>
        <th>{copyText.widgetTooltipHeaderCategory}</th>
        <th>{copyText.widgetTooltipHeaderTotal}</th>
      </thead>
      <tbody>
        {entries
          .sort(([_ignoreA, a], [_ignoreB, b]) => (a > b ? -1 : 1))
          .map(([key, value], index) => (
            <tr key={`${key}-${index}`}>
              <td align="left">
                {
                  <Link
                    to={{
                      pathname: getPathFromServiceType(key),
                      search: getQueryFromServiceType(key),
                    }}
                  >
                    {getReadableKey(key)}
                  </Link>
                }
              </td>
              <td align="right">{formatCurrencyRounded({ number: value })}</td>
            </tr>
          ))}
        <tr>
          <td colSpan={2}>
            <Divider color={theme.tooltip_text_color} margin={theme.space_xs} />
          </td>
        </tr>
        <tr>
          <td colSpan={1}>
            <Text
              align="right"
              color={theme.tooltip_text_color}
              fontSize={theme.fontSize_small}
            >
              {copyText.widgetTooltipTotalLabel}
            </Text>
          </td>

          <td colSpan={1}>
            <Text align="right" color={theme.tooltip_text_color}>
              {formatCurrencyRounded({
                number: potentialRecSavings,
              })}
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const slideIn = keyframes({
  from: {
    transform: "translateY(-10px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

type AnimatedTextProps = {
  children: string;
  delayMS?: number;
  durationMS?: number;
};

function AnimatedText(props: AnimatedTextProps) {
  const [updateID, setUpdateID] = useState(0);

  useEffect(() => {
    setUpdateID((updateID) => updateID + 1);
  }, [props.children]);

  return (
    <>
      {props.children.split("").map((char, index) => {
        const delay = (props.delayMS ?? 0) * index;
        return (
          <Box
            animation={`${slideIn} ${props.durationMS}ms ${delay}ms forwards`}
            display="inline-block"
            key={`${char}-${index}-${updateID}`}
            opacity={0}
            position="relative"
          >
            {char}
          </Box>
        );
      })}
    </>
  );
}

const defaultFilters: AWSCommittedUseFilter = {
  linkedAccountIDs: [],
  lookbackPeriod: AWSCommittedUseLookbackPeriod.THIRTY_DAYS,
  minSavings: 0,
  minUtil: 0,
  payerAccountID: "",
  offeringClass: AWSCommittedUseOfferingClass.STANDARD,
  paymentOption: AWSCommittedUsePaymentOption.NO_UPFRONT,
  accountScope: AWSRateAccountScope.PAYER,
  savingsPlanType: AWSCommittedUseSavingsPlanType.COMPUTE_SP,
  serviceType: ServiceType.EC2,
  term: AWSCommittedUseTerm.ONE_YEAR,
};

function getFilteredRIs(
  ris: AWSRateReservedInstanceRecommendationEntity[],
  payerAccountID: string
) {
  return ris.filter((ri) => {
    if (ri.cloudID !== payerAccountID) {
      return false;
    }

    if (
      ri.offeringClass !== null &&
      defaultFilters.offeringClass.toUpperCase() !==
        ri.offeringClass.toUpperCase()
    ) {
      return false;
    }

    if (
      defaultFilters.paymentOption.toUpperCase() !==
      ri.paymentOption.toUpperCase()
    ) {
      return false;
    }

    if (
      defaultFilters.accountScope.toUpperCase() !==
      ri.accountScope.toUpperCase()
    ) {
      return false;
    }

    if (defaultFilters.term.toUpperCase() !== ri.term.toUpperCase()) {
      return false;
    }

    if (
      defaultFilters.lookbackPeriod.toUpperCase() !==
      ri.lookbackPeriod.toUpperCase()
    ) {
      return false;
    }

    return true;
  });
}

function getFilteredSPs(
  sps: AWSRateSavingsPlanRecommendationEntity[],
  payerAccountID: string
) {
  return sps.filter((sp) => {
    if (sp.cloudID !== payerAccountID) {
      return false;
    }

    if (
      defaultFilters.paymentOption.toUpperCase() !==
      sp.paymentOption.toUpperCase()
    ) {
      return false;
    }

    if (
      defaultFilters.accountScope.toUpperCase() !==
      sp.accountScope.toUpperCase()
    ) {
      return false;
    }

    if (
      defaultFilters.savingsPlanType.toUpperCase() !==
      sp.savingsPlanType.toUpperCase()
    ) {
      return false;
    }

    if (defaultFilters.term.toUpperCase() !== sp.term.toUpperCase()) {
      return false;
    }

    if (
      defaultFilters.lookbackPeriod.toUpperCase() !==
      sp.lookbackPeriod.toUpperCase()
    ) {
      return false;
    }

    return true;
  });
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SavingsOpportunityViewContainer {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `SavingsOpportunityViewContainer.INTERACTION_DELETE_BUTTON_CLICKED`;
  export const INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED = `SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED`;

  interface InteractionDeleteButtonClicked {
    type: typeof SavingsOpportunityViewContainer.INTERACTION_DELETE_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionSavingsOpportunityFilterNameClicked {
    type: typeof SavingsOpportunityViewContainer.INTERACTION_SAVINGS_OPPORTUNIITY_FILTER_NAME_CLICKED;
    savingsOpportunityFilterID: string;
    index: number;
  }

  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionSavingsOpportunityFilterNameClicked;
}

export default SavingsOpportunityViewContainer;
