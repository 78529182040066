import useUpdateUser from "@/api/core/hooks/useUpdateUser";
import useUpdateUserSettings from "@/api/core/useUpdateUserTenantSetting";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import Divider from "@/ui-lib/components/Divider";
import { useTheme } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import React from "react";
import useGetDimensionValuesByDataSource from "../../../api/analytics/useGetDimensionValuesByDataSource";
import { AuthenticatedUserEntity } from "../../../api/core/types";
import { DateHelper } from "../../../lib/dates";
import { AlertType, postAlert } from "../../../utils/alerts";
import copyText from "../copyText";
import EditAppearanceSettingsForm from "./EditAppearanceSettingsForm";
import EditCalendarSettingsForm from "./EditCalendarSettingsForm";
import EditUserNotificationSettingsForm from "./EditUserNotificationSettingsForm";

type Interaction =
  | EditUserNotificationSettingsForm.Interaction
  | EditAppearanceSettingsForm.Interaction
  | EditCalendarSettingsForm.Interaction;

const now = new DateHelper();

export default function UserSettingsManagementContainer(): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const settings = authenticatedUser.settings;

  //
  // Query
  //

  const {
    data: dimensionValuesMap = {},
    isLoading: isLoadingDimensionValuesMap,
  } = useGetDimensionValuesByDataSource({
    dataSource: DataSource.BILLING,
    dateRange: [now.nDaysAgo(365), now.date],
    dimensions: ["projectId"],
    measure: "cost",
  });

  //
  // Mutations
  //

  const { isPending: isUpdatingUser, mutate: updateUser } = useUpdateUser({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingUserSettings_message,
      });
    },
    onSuccess: (_, { themeMode }) => {
      queryClient.setQueryData<AuthenticatedUserEntity | undefined>(
        ["authenticatedUser"],
        (authenticatedUser) => {
          if (!authenticatedUser) return;

          return { ...authenticatedUser, themeMode };
        }
      );
    },
  });

  const { isPending: isUpdatingSettings, mutate: updateSettings } =
    useUpdateUserSettings({
      onError: () => {
        postAlert({
          type: AlertType.ERROR,
          message: copyText.errorUpdatingUserSettings_message,
        });
      },
      onSuccess: (_, params) => {
        postAlert({
          type: AlertType.SUCCESS,
          message: copyText.successUpdatingUserSettings_message,
        });

        queryClient.setQueryData<AuthenticatedUserEntity | undefined>(
          ["authenticatedUser"],
          (authenticatedUser) => {
            if (!authenticatedUser) return;

            return {
              ...authenticatedUser,
              settings: { ...authenticatedUser.settings, ...params },
            };
          }
        );
      },
    });

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case EditAppearanceSettingsForm.INTERACTION_THEME_SWITCH_CLICKED: {
        const { themeMode } = interaction;

        activityTracker.captureAction(actions.CHANGE_THEME);

        updateUser({ userID: authenticatedUser.id, themeMode });
        return;
      }
      case EditCalendarSettingsForm.INTERACTION_FISCAL_SWITCH_CLICKED: {
        if (!settings) return;

        activityTracker.captureAction(actions.CHANGE_FISCAL_MODE);

        updateSettings({
          settingsID: authenticatedUser.settings.id,
          fiscalMode: interaction.fiscalMode,
        });

        return;
      }
      case EditUserNotificationSettingsForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        activityTracker.captureAction(actions.UPDATE_NOTIFICATION_PREFERENCES);
        activityTracker.captureAction(
          actions.UPDATE_SETTINGS_NOTIFICATION_PREFERENCES
        );

        updateSettings({
          settingsID: authenticatedUser.settings.id,
          fiscalMode: settings.fiscalMode,
          notifyAlerts: interaction.enableAlerts,
          notifyBudgets: interaction.enableBudgets,
          notifyCaseUpdates: interaction.enableCaseNotifications,
          notifyRecs: interaction.enableRecs,
          notifyRecsDaily: interaction.enableRecsDaily,
          notifyRecsMonthly: interaction.enableRecsMonthly,
          notifyRecsWeekly: interaction.enableRecsWeekly,
          notifyReportsDaily: interaction.enableReportsDaily,
          notifyReportsMonthly: interaction.enableReportsMonthly,
          notifyReportsWeekly: interaction.enableReportsWeekly,
          recFilters: interaction.recFilters,
        });

        return;
      }
    }
  }

  return (
    <Flex justifyContent="center">
      <Box width={625}>
        <EditAppearanceSettingsForm
          isProcessing={isUpdatingUser}
          themeMode={authenticatedUser.themeMode}
          onInteraction={handleInteraction}
        />
        {!!authenticatedUser.tenant.fiscalCalendar && (
          <>
            <Divider
              color={theme.panel_backgroundColor}
              direction="horizontal"
              margin={theme.space_jumbo}
            />
            <EditCalendarSettingsForm
              isProcessing={isUpdatingSettings}
              fiscalMode={settings.fiscalMode}
              onInteraction={handleInteraction}
            />
          </>
        )}
        <Divider
          color={theme.panel_backgroundColor}
          direction="horizontal"
          margin={theme.space_jumbo}
        />
        <EditUserNotificationSettingsForm
          dimensionValuesMap={dimensionValuesMap}
          isLoadingDimensionValueMap={isLoadingDimensionValuesMap}
          isProcessing={isUpdatingSettings}
          settings={authenticatedUser.settings}
          onInteraction={handleInteraction}
        />
      </Box>
    </Flex>
  );
}
