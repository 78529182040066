import {
  AuthenticatedUserEntity,
  UpdateDashboardParameters,
} from "@/api/core/types";
import useUpdateUserSettings from "@/api/core/useUpdateUserTenantSetting";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useNavigateWithSearchParams } from "@/lib/react-router";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import TextInput from "@/ui-lib/components/TextInput";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faLock, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  DashboardScope,
  DashboardType,
} from "@ternary/api-lib/constants/enums";
import systemUser, {
  SYSTEM_TENANT_ID,
} from "@ternary/api-lib/constants/system";
import { DashboardEntity } from "@ternary/api-lib/core/types";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import useReferenceIfEqual from "@ternary/api-lib/ui-lib/hooks/useReferenceIfEqual";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { keyBy, sortBy, uniq, uniqBy } from "lodash";
import React, { useMemo, useState } from "react";
import {
  ArrayParam,
  BooleanParam,
  DecodedValueMap,
  StringParam,
  useQueryParams,
} from "use-query-params";
import useGetUsersByTenantID from "../../../api/core/hooks/useGetUsersByTenantID";
import paths from "../../../constants/paths";
import { useDistributeSystemDashboard } from "../../global-admin/hooks/useDistributeSystemDashboard";
import { useRemoveSystemDashboard } from "../../global-admin/hooks/useRemoveSystemDashboard";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useCreateDashboard from "../hooks/useCreateDashboard";
import useDeleteDashboard from "../hooks/useDeleteDashboard";
import useGetDashboardsByTenantID from "../hooks/useGetDashboardsByTenantID";
import useUpdateDashboard from "../hooks/useUpdateDashboard";
import useUpdateDashboards from "../hooks/useUpdateDashboards";
import DashboardFormModal from "./DashboardFormModal";
import DashboardTable from "./DashboardTable";
import DashboardTagsEditForm from "./DashboardTagsEditForm";

const MODAL_CREATE = "CREATE";
const MODAL_DELETE = "DELETE";
const MODAL_DISTRIBUTE_WARNING = "DISTRIBUTE_WARNING";
const MODAL_REMOVE_WARNING = "REMOVE_WARNING";
const MODAL_UPDATE = "UPDATE";
const MODAL_TAGS = "TAGS";
const MODAL_UPDATE_CONFIRMATION_MULTIPLE = "UPDATE_CONFIRMATION_MULTIPLE";
const MODAL_UPDATE_CONFIRMATION_SINGLE = "UPDATE_CONFIRMATION_SINGLE";
const MODAL_UPDATE_GLOBAL_DASHBOARD = "MODAL_UPDATE_GLOBAL_DASHBOARD";

const defaultUsers = [];

type Interaction =
  | DashboardTable.Interaction
  | DashboardTagsEditForm.Interaction;

type SearchParamState = {
  filterCreatedBy: string[];
  filterFavorites: boolean;
  filterTags: string[];
  filterText: string;
};

const queryParamConfigMap = {
  created_by: ArrayParam,
  favorites: BooleanParam,
  tags: ArrayParam,
  text: StringParam,
};

interface State {
  modalKey: string;
  selectedDashboardIDs: string[];
  selectedDashboardParams: (UpdateDashboardParameters & {
    dashboardID: string;
  })[];
  showMultiSelect: boolean;
}

const initialState: State = {
  modalKey: "",
  selectedDashboardIDs: [],
  selectedDashboardParams: [],
  showMultiSelect: false,
};

export function DashboardManagementContainer(props: {
  isInternal?: boolean;
}): JSX.Element {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const gatekeeper = useGatekeeper();
  const navigate = useNavigateWithSearchParams();

  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const [queryParams, setQueryParams] = useQueryParams(queryParamConfigMap);
  const queryParamState = useReferenceIfEqual(getQueryParamsState(queryParams));

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const tenantID = props.isInternal
    ? SYSTEM_TENANT_ID
    : authenticatedUser.tenant.id;

  const enabled = props.isInternal
    ? gatekeeper.canListSystemDashboards
    : gatekeeper.canListDashboards;

  const { data: _dashboards = [], isLoading: isLoadingDashboards } =
    useGetDashboardsByTenantID(tenantID, { enabled });

  const { data: users = defaultUsers, isLoading: isLoadingUsers } =
    useGetUsersByTenantID(authenticatedUser.tenant.id);

  //
  // Mutations
  //

  const { isPending: isCreatingDashboard, mutate: createDashboard } =
    useCreateDashboard({
      onError: () => {
        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.errorCreatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.tenantDashboards(tenantID),
        });

        mergeState({ modalKey: "" });

        postAlert({
          message: copyText.SUCCESS_DASHBOARD_CREATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isDeletingDashboard, mutate: deleteDashboard } =
    useDeleteDashboard({
      onError: () => {
        mergeState({ modalKey: "", selectedDashboardIDs: [] });

        postAlert({
          message: copyText.errorDeletingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.tenantDashboards(tenantID),
        });

        mergeState({ modalKey: "", selectedDashboardIDs: [] });

        postAlert({
          message: copyText.SUCCESS_DASHBOARD_DELETED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { isPending: isUpdatingDashboard, mutate: updateDashboard } =
    useUpdateDashboard({
      onMutate: async (params) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
          queryKey: queryKeys.tenantDashboards(tenantID),
        });

        const previousDashboards: DashboardEntity[] =
          queryClient.getQueryData(queryKeys.tenantDashboards(tenantID)) ?? [];

        // Optimistically update to the new value.
        // Currently only using this for favoriting.
        if (params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantDashboards(tenantID),
            (currentDashboards: DashboardEntity[] | undefined) => {
              return (currentDashboards ?? []).map((dashboard) =>
                dashboard.id === params.dashboardID
                  ? {
                      ...dashboard,
                      favoritedUserIDs: params.favoritedUserIDs ?? [],
                    }
                  : dashboard
              );
            }
          );
        }

        return { previousDashboards };
      },
      onError: (_, params, context) => {
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        if (context && params.favoritedUserIDs) {
          queryClient.setQueryData(
            queryKeys.tenantDashboards(tenantID),
            context.previousDashboards
          );
        }

        mergeState({ modalKey: "", selectedDashboardIDs: [] });

        postAlert({
          message: copyText.errorUpdatingDashboardMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (_, params) => {
        if (params.favoritedUserIDs) return;

        setQueryParams({ tags: null });

        mergeState({ modalKey: "", selectedDashboardIDs: [] });
        postAlert({
          message: copyText.SUCCESS_DASHBOARD_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.tenantDashboards(tenantID),
        });
      },
    });

  const { isPending: isUpdatingDashboards, mutate: updateDashboards } =
    useUpdateDashboards({
      onError: () => {
        mergeState({ modalKey: "", selectedDashboardIDs: [] });
        postAlert({
          message: copyText.errorUpdatingDashboardsMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.tenantDashboards(tenantID),
        });

        mergeState({
          modalKey: "",
          selectedDashboardIDs: [],
          showMultiSelect: false,
        });
        postAlert({
          message: copyText.SUCCESS_DASHBOARD_UPDATED_message,
          type: AlertType.SUCCESS,
        });
      },
    });

  const { mutate: updateSettings } = useUpdateUserSettings({
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.errorUpdatingPreferredDashboardMessage,
      });
    },
    onSuccess: (_, params) => {
      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingPreferredDashboardMessage,
      });

      queryClient.setQueryData<AuthenticatedUserEntity | undefined>(
        ["authenticatedUser"],
        (authenticatedUser) => {
          if (!authenticatedUser) return;

          return {
            ...authenticatedUser,
            settings: { ...authenticatedUser.settings, ...params },
          };
        }
      );
    },
  });

  const {
    isPending: isDistributingSystemDashboard,
    mutate: distributeSystemDashboard,
  } = useDistributeSystemDashboard({
    onError: () => {
      postAlert({
        message: copyText.errorDistributingSystemDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedDashboardIDs: [] });

      queryClient.invalidateQueries({ queryKey: queryKeys.allDashboards });

      postAlert({
        message: copyText.successDistributingSystemDashboardMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  const {
    isPending: isRemovingSystemDashboard,
    mutate: removeSystemDashboard,
  } = useRemoveSystemDashboard({
    onError: () => {
      postAlert({
        message: copyText.errorRemovingSystemDashboardMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: () => {
      mergeState({ modalKey: "", selectedDashboardIDs: [] });

      queryClient.invalidateQueries({ queryKey: queryKeys.allDashboards });

      postAlert({
        message: copyText.successRemovingSystemDashboardMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Computed Values
  //

  const usersKeyedByID = keyBy([...users, systemUser], "id");

  const dashboards = _dashboards.map((dashboard) => {
    const createdByUser = usersKeyedByID[dashboard.createdByID];
    const updatedByUser = usersKeyedByID[dashboard.updatedByID ?? ""];

    return {
      ...dashboard,
      createdByEmail: createdByUser ? createdByUser.email : null,
      updatedByEmail: updatedByUser ? updatedByUser.email : null,
    };
  });

  const filteredDashboards = useMemo(() => {
    let filteredDashboards = dashboards;

    if (queryParamState.filterCreatedBy.length > 0) {
      filteredDashboards = filteredDashboards.filter((dashboard) => {
        return queryParamState.filterCreatedBy.includes(dashboard.createdByID);
      });
    }

    if (queryParamState.filterFavorites) {
      filteredDashboards = filteredDashboards.filter((report) => {
        return report.favoritedUserIDs.includes(authenticatedUser.id);
      });
    }

    if (queryParamState.filterText.length > 0) {
      const searchStr = queryParamState.filterText.toLowerCase();
      filteredDashboards = filteredDashboards.filter((dashboard) => {
        const lowerCasedTags = dashboard.tags.map((tag) => tag.toLowerCase());
        return (
          dashboard.createdByID.toLowerCase().includes(searchStr) ||
          dashboard.name.toLowerCase().includes(searchStr) ||
          lowerCasedTags.some((str) => str.includes(searchStr))
        );
      });
    }

    if (queryParamState.filterTags.length > 0) {
      filteredDashboards = filteredDashboards.filter((dashboard) =>
        queryParamState.filterTags.every((tag) => dashboard.tags.includes(tag))
      );
    }
    return filteredDashboards;
  }, [
    dashboards,
    queryParamState.filterCreatedBy,
    queryParamState.filterFavorites,
    queryParamState.filterText,
  ]);

  const dashboardsKeyedByID = keyBy(_dashboards, "id");

  const selectedDashboards = useMemo(() => {
    if (state.modalKey !== MODAL_TAGS) return [];

    return state.selectedDashboardIDs
      .filter((id) => dashboardsKeyedByID[id])
      .map((id) => dashboardsKeyedByID[id]);
  }, [filteredDashboards, state.modalKey, state.selectedDashboardIDs]);

  const existingTags = useMemo(() => {
    return filteredDashboards.reduce((accum: string[], dashboard) => {
      dashboard.tags.forEach((tag) => {
        if (!accum.includes(tag)) {
          accum.push(tag);
        }
      });
      return accum;
    }, []);
  }, [filteredDashboards]);

  const highlightedTags = useMemo(
    () =>
      uniq([
        ...queryParamState.filterTags,
        ...existingTags.filter((tag) =>
          queryParamState.filterText.length > 0
            ? tag
                .toLowerCase()
                .includes(queryParamState.filterText.toLowerCase())
            : false
        ),
      ]),
    [existingTags, queryParamState]
  );

  const createdByFilterOptions: Option[] = uniqBy(dashboards, "createdByEmail")
    .reduce((accum: Option[], dashboard) => {
      if (!dashboard.createdByEmail) return accum;

      return [
        ...accum,
        {
          label: dashboard.createdByEmail,
          value: dashboard.createdByID,
        },
      ];
    }, [])
    .sort((a, b) =>
      a.value.toLowerCase() < b.value.toLowerCase()
        ? -1
        : a.value.toLowerCase() > b.value.toLowerCase()
          ? 1
          : 0
    );

  //
  // Interaction Handlers
  //

  function handleInteraction(interaction: Interaction): void {
    switch (interaction.type) {
      case DashboardTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE,
          selectedDashboardIDs: [interaction.dashboardID],
        });
        return;
      }
      case DashboardTable.INTERACTION_EDIT_MULTI_SELECT_CLICKED: {
        mergeState({ modalKey: MODAL_TAGS });
        return;
      }
      case DashboardTable.INTERACTION_EDIT_MUTLIPLE_TAGS_CLICKED: {
        mergeState({ selectedDashboardIDs: interaction.dashboardIDs });
        return;
      }
      case DashboardTable.INTERACTION_EDIT_SINGLE_TAGS_CLICKED: {
        mergeState({
          modalKey: MODAL_TAGS,
          selectedDashboardIDs: [interaction.dashboardID],
        });
        return;
      }
      case DashboardTable.INTERACTION_FILTER_FAVORITES_CLICKED: {
        setQueryParams({ favorites: !queryParamState.filterFavorites || null });
        return;
      }
      case DashboardTable.INTERACTION_FAVORITE_DASHBOARD_CLICKED: {
        const favoritedUserIDs =
          dashboardsKeyedByID[interaction.dashboardID].favoritedUserIDs;

        if (!favoritedUserIDs.includes(authenticatedUser.id)) {
          updateDashboard({
            dashboardID: interaction.dashboardID,
            favoritedUserIDs: [...favoritedUserIDs, authenticatedUser.id],
          });
        } else {
          updateDashboard({
            dashboardID: interaction.dashboardID,
            favoritedUserIDs: favoritedUserIDs.filter(
              (id) => id !== authenticatedUser.id
            ),
          });
        }

        return;
      }
      case DashboardTable.INTERACTION_PREFERRED_DASHBOARD_CLICKED: {
        const { dashboardID } = interaction;

        updateSettings({
          settingsID: authenticatedUser.settings.id,
          preferredDashboardID: dashboardID,
        });
        return;
      }
      case DashboardTable.INTERACTION_ROW_CLICKED: {
        const { dashboardID } = interaction;

        navigate(paths._dashboard.replace(":dashboardID", dashboardID));
        return;
      }
      case DashboardTable.INTERACTION_TAG_CLICKED: {
        if (queryParamState.filterTags.includes(interaction.tag)) {
          setQueryParams({
            tags: queryParamState.filterTags.filter(
              (otherTag) => otherTag !== interaction.tag
            ),
          });
        } else {
          setQueryParams({
            tags: [...queryParamState.filterTags, interaction.tag],
          });
        }
        return;
      }
      case DashboardTable.INTERACTION_DISPLAY_MULTI_SELECT_CLICKED: {
        mergeState({
          showMultiSelect: interaction.display,
        });
        return;
      }
      case DashboardTable.INTERACTION_DISTRIBUTE_DASHBOARD_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DISTRIBUTE_WARNING,
          selectedDashboardIDs: [interaction.dashboardID],
        });
        return;
      }
      case DashboardTable.INTERACTION_REMOVE_DASHBOARD_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_REMOVE_WARNING,
          selectedDashboardIDs: [interaction.dashboardID],
        });
        return;
      }
      case DashboardTable.INTERACTION_UPDATE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_UPDATE,
          selectedDashboardIDs: [interaction.dashboardID],
        });
        return;
      }
      case DashboardTable.INTERACTION_VIEW_BUTTON_CLICKED: {
        const { dashboardID } = interaction;

        navigate(dashboardID);
        return;
      }
      case DashboardTagsEditForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ modalKey: "", selectedDashboardIDs: [] });
        return;
      }
      case DashboardTagsEditForm.INTERACTION_MULTIPLE_SUBMIT_BUTTON_CLICKED: {
        if (props.isInternal) {
          mergeState({
            modalKey: MODAL_UPDATE_CONFIRMATION_MULTIPLE,
            selectedDashboardParams: interaction.params,
          });
          return;
        }

        updateDashboards({
          tenantID: authenticatedUser.tenant.id,
          paramsArray: interaction.params,
        });
        return;
      }
      case DashboardTagsEditForm.INTERACTION_SINGLE_SUBMIT_BUTTON_CLICKED: {
        if (props.isInternal) {
          mergeState({
            modalKey: MODAL_UPDATE_CONFIRMATION_SINGLE,
            selectedDashboardParams: [
              { dashboardID: interaction.dashboardID, ...interaction.params },
            ],
          });
          return;
        }

        updateDashboard({
          dashboardID: interaction.dashboardID,
          ...interaction.params,
        });
        return;
      }
    }
  }

  function handleCreateDashboard(params: {
    name: string;
    scope: DashboardScope;
    tags: string[];
  }): void {
    activityTracker.captureAction(actions.CREATE_TRE_DASHBOARD);

    createDashboard({
      tenantID:
        params.scope === DashboardScope.GLOBAL
          ? SYSTEM_TENANT_ID
          : authenticatedUser.tenant.id,
      name: params.name,
      scope: params.scope,
      tags: params.tags,
      type:
        params.scope === DashboardScope.GLOBAL
          ? DashboardType.SYSTEM
          : DashboardType.DEFAULT,
      widgetSpecs: [],
    });
  }

  function handleDeleteDashboard(): void {
    activityTracker.captureAction(actions.DELETE_TRE_DASHBOARD);

    deleteDashboard({ dashboardID: state.selectedDashboardIDs[0] });
  }

  function handleUpdateDashboard(params: {
    name: string;
    tags: string[];
  }): void {
    activityTracker.captureAction(actions.UPDATE_TRE_DASHBOARD);

    updateDashboard({
      dashboardID: state.selectedDashboardIDs[0],
      name: params.name,
      tags: params.tags,
    });
  }

  //
  // Render
  //

  if (!gatekeeper.canListDashboards) {
    return (
      <Flex alignItems="center" justifyContent="center" minHeight="50vh">
        <EmptyPlaceholder
          icon={faLock}
          loading={false}
          text={copyText.emptyPlaceholderInsufficientPermission}
        />
      </Flex>
    );
  }

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_CREATE: {
        return (
          <DashboardFormModal
            isCreateModal
            isProcessing={isCreatingDashboard}
            existingTags={existingTags}
            title={copyText.modalTitleCreateDashboard}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleCreateDashboard}
          />
        );
      }
      case MODAL_DELETE: {
        return (
          <ConfirmationModal
            isLoading={isDeletingDashboard}
            message={copyText.deleteDashboardConfirmationMessage}
            title={copyText.deleteDashboardConfirmationTitle}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteDashboard}
          />
        );
      }
      case MODAL_UPDATE: {
        const dashboard = dashboardsKeyedByID[state.selectedDashboardIDs[0]];

        return (
          <DashboardFormModal
            existingTags={existingTags}
            isGlobalDashboard={dashboard.scope === DashboardScope.GLOBAL}
            isProcessing={isUpdatingDashboard}
            name={dashboard.name}
            tags={dashboard.tags}
            title={copyText.modalTitleEditDashboard}
            onClose={() => mergeState({ modalKey: "" })}
            onSubmit={handleUpdateDashboard}
          />
        );
      }
      case MODAL_UPDATE_GLOBAL_DASHBOARD: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingDashboard}
            message={copyText.updateGlobalDashboardConfirmationMessage}
            title={copyText.modalTitleEditSystemDashboard}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "" })}
            onConfirm={handleDeleteDashboard}
          />
        );
      }
      case MODAL_TAGS: {
        return (
          <DashboardTagsEditForm
            existingTags={existingTags}
            isOpen
            loadingSubmit={isUpdatingDashboards}
            selectedDashboards={selectedDashboards}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_UPDATE_CONFIRMATION_MULTIPLE: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingDashboards}
            message={copyText.modalMessageUpdateSystemDashboards}
            title={copyText.modalTitleEditSystemDashboards}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedDashboardParams: [],
              })
            }
            onConfirm={() =>
              updateDashboards({
                tenantID: authenticatedUser.tenant.id,
                paramsArray: state.selectedDashboardParams,
              })
            }
          />
        );
      }
      case MODAL_UPDATE_CONFIRMATION_SINGLE: {
        return (
          <ConfirmationModal
            isLoading={isUpdatingDashboard}
            message={copyText.modalMessageUpdateSystemDashboard}
            title={copyText.modalTitleEditSystemDashboard}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedDashboardParams: [],
              })
            }
            onConfirm={() => updateDashboard(state.selectedDashboardParams[0])}
          />
        );
      }
      case MODAL_DISTRIBUTE_WARNING: {
        return (
          <ConfirmationModal
            isLoading={isDistributingSystemDashboard}
            message={copyText.modalMessageDistributeDashboard}
            title={copyText.modalTitleDistributeDashboard}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedDashboardIDs: [],
                selectedDashboardParams: [],
              })
            }
            onConfirm={() =>
              distributeSystemDashboard(state.selectedDashboardIDs[0])
            }
          />
        );
      }
      case MODAL_REMOVE_WARNING: {
        return (
          <ConfirmationModal
            isLoading={isRemovingSystemDashboard}
            message={copyText.modalMessageRemoveSystemDashboard}
            title={copyText.modalTitleRemoveDashboard}
            variant="danger"
            onCancel={() =>
              mergeState({
                modalKey: "",
                selectedDashboardIDs: [],
                selectedDashboardParams: [],
              })
            }
            onConfirm={() =>
              removeSystemDashboard(state.selectedDashboardIDs[0])
            }
          />
        );
      }
    }

    return null;
  }

  const existingTagOptions: Option[] = sortBy(
    existingTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
    (option) => option.value.toLowerCase()
  );

  return (
    <>
      {renderModal()}
      <Flex justifyContent="space-between" marginBottom={theme.space_md}>
        <Box>
          <Text appearance="h2">{copyText.dashboardManagementPageTitle}</Text>
        </Box>

        <Flex alignItems="flex-end">
          <Flex>
            <Flex width="12rem">
              <TextInput
                height="100%"
                iconStart={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchReportsDashboardsPlaceholder}
                value={queryParamState.filterText}
                onChange={(event) =>
                  setQueryParams({
                    text: event.target.value ? event.target.value : null,
                  })
                }
              />
            </Flex>

            {existingTagOptions.length > 0 && (
              <Flex marginLeft={theme.space_md}>
                <SelectCheckbox
                  height="100%"
                  options={existingTagOptions}
                  placeholder={copyText.filterDashboardsByTag}
                  selectedValues={queryParamState.filterTags}
                  width="12rem"
                  onChange={(event) =>
                    setQueryParams({ tags: event.length !== 0 ? event : null })
                  }
                />
              </Flex>
            )}

            <Flex marginHorizontal={theme.space_md}>
              <SelectCheckbox
                height="100%"
                options={createdByFilterOptions}
                placeholder={copyText.filterReportsDashboardsPlaceholder}
                selectedValues={queryParamState.filterCreatedBy}
                width="12rem"
                onChange={(event) =>
                  setQueryParams({
                    created_by: event.length !== 0 ? event : null,
                  })
                }
              />
            </Flex>

            <Box>
              <Button
                iconStart={<Icon icon={faPlus} />}
                locked={!gatekeeper.canCreateDashboards}
                primary
                onClick={() => mergeState({ modalKey: MODAL_CREATE })}
              >
                {copyText.createDashboardButtonLabel}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <DashboardTable
        dashboards={filteredDashboards}
        existingTags={existingTags}
        highlightedTags={highlightedTags}
        isInternal={props.isInternal}
        isLoading={isLoadingDashboards || isLoadingUsers}
        preferredDashboardID={authenticatedUser.settings.preferredDashboardID}
        selectedDashboardIDs={state.selectedDashboardIDs}
        showMultiSelect={state.showMultiSelect}
        onInteraction={handleInteraction}
      />
    </>
  );
}

function getQueryParamsState(
  queryParams: DecodedValueMap<typeof queryParamConfigMap>
): SearchParamState {
  return {
    filterCreatedBy: removeNull(queryParams.created_by ?? []),
    filterFavorites: queryParams.favorites ?? false,
    filterTags: removeNull(queryParams.tags ?? []),
    filterText: queryParams.text ?? "",
  };
}

function removeNull(withNull: (string | null)[]): string[] {
  const withoutNull: string[] = [];

  withNull.forEach((tag) => {
    if (tag !== null) withoutNull.push(tag);
  });

  return withoutNull;
}

export default DashboardManagementContainer;
