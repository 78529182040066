import { useTheme } from "@emotion/react";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import { getFullName } from "../../../utils/UserUtils";
import copyText from "../copyText";
import { User } from "../types";

type Case = {
  id: string;
  followers: User[];
};

interface Props {
  case?: Case;
  isLoading: boolean;
  isProcessing: boolean;
  onInteraction: (interaction: CaseFollowersList.Interaction) => void;
}

export function CaseFollowersList(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  if (!props.case || props.isLoading) {
    return (
      <Flex
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        direction="column"
        height={250}
        justifyContent="space-between"
        padding={theme.space_lg}
        width="50%"
      >
        <EmptyPlaceholder loading={props.isLoading} />
      </Flex>
    );
  }

  const followers = props.case.followers;

  const remainingFollowers = followers.slice(5, followers.length);

  const isFollowing = followers.some(
    (follower) => follower.id === authenticatedUser.id
  );

  const submitButtonText = isFollowing
    ? copyText.unfollowCaseButtonLabel
    : copyText.followCaseButtonLabel;

  const content = (
    <Flex direction="column" alignItems="flex-start">
      {remainingFollowers.map((follower, i) => (
        <Text key={follower ? follower.id : i} color={theme.text_color_inverse}>
          {getFullName(follower)} - {follower.email ? follower.email : ""}
        </Text>
      ))}
    </Flex>
  );

  return (
    <Flex
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_2}
      direction="column"
      height={250}
      justifyContent="space-between"
      marginLeft={theme.space_xs}
      minWidth={270}
      padding={theme.space_lg}
      width="50%"
    >
      <Box>
        <Text fontWeight={theme.fontWeight_bold} marginBottom={theme.space_xs}>
          {`${copyText.caseViewFollowingSectionTitle} (${props.case.followers.length})`}
        </Text>
        {followers.slice(0, 5).map((follower) => (
          <Text key={follower.id} truncate>
            {getFullName(follower)} - {follower.email}
          </Text>
        ))}
        {remainingFollowers.length > 0 ? (
          <Tooltip content={content} placement="top-start">
            <Text fontWeight={theme.fontWeight_semiBold}>
              {copyText.remainingCountCaption.replace(
                "%count%",
                String(remainingFollowers.length)
              )}
            </Text>
          </Tooltip>
        ) : null}
      </Box>
      <Flex justifyContent="flex-end" marginTop={theme.space_md}>
        <Button
          disabled={props.isProcessing}
          iconStart={
            props.isProcessing ? undefined : (
              <Icon icon={isFollowing ? faEyeSlash : faEye} />
            )
          }
          primary
          size="small"
          width={100}
          onClick={() =>
            props.onInteraction({
              type: isFollowing
                ? CaseFollowersList.INTERACTION_UNFOLLOW_BUTTON_CLICKED
                : CaseFollowersList.INTERACTION_FOLLOW_BUTTON_CLICKED,
              caseID: props.case?.id ?? "",
            })
          }
        >
          {props.isProcessing ? <LoadingSpinner /> : submitButtonText}
        </Button>
      </Flex>
    </Flex>
  );
}

CaseFollowersList.INTERACTION_FOLLOW_BUTTON_CLICKED =
  `CaseFollowersList.INTERACTION_FOLLOW_BUTTON_CLICKED` as const;

CaseFollowersList.INTERACTION_UNFOLLOW_BUTTON_CLICKED =
  `CaseFollowersList.INTERACTION_UNFOLLOW_BUTTON_CLICKED` as const;

interface InteractionFollowButtonClicked {
  type: typeof CaseFollowersList.INTERACTION_FOLLOW_BUTTON_CLICKED;
  caseID: string;
}

interface InteractionUnfollowButtonClicked {
  type: typeof CaseFollowersList.INTERACTION_UNFOLLOW_BUTTON_CLICKED;
  caseID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CaseFollowersList {
  export type Interaction =
    | InteractionFollowButtonClicked
    | InteractionUnfollowButtonClicked;
}

export default CaseFollowersList;
