import { faList } from "@fortawesome/free-solid-svg-icons";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import { Column } from "react-table";
import { TableLegacy } from "../../../ui-lib/components/Table";
import copyText from "../copyText";

type SpendSummary = {
  credits: number;
  grossCost: number;
  name: string;
  netCost: number;
};

type TableData = {
  credits: number;
  grossCost: number;
  name: string;
  netCost: number;
};

interface Props {
  loading: boolean;
  spendSummaries: SpendSummary[];
}

export default function NetCostTable(props: Props): JSX.Element {
  if (props.spendSummaries.length === 0 || props.loading) {
    return (
      <EmptyPlaceholder
        icon={faList}
        loading={props.loading}
        skeletonVariant="rows"
        text={copyText.netCostTableEmptyPlaceholderText}
      />
    );
  }

  // TODO: Replace this with totals from datalligator
  const aggregatedTotals = props.spendSummaries.reduce(
    (accum, datum) => {
      accum["credits"] += datum.credits;
      accum["grossCost"] += datum.grossCost;
      accum["netCost"] += datum.netCost;

      return accum;
    },
    { credits: 0, grossCost: 0, netCost: 0 }
  );

  const columns: Column<TableData>[] = [
    {
      accessor: "name",
      align: "left",
      Header: copyText.tableHeaderValue,
      width: 100,
    },
    {
      accessor: "grossCost",
      align: "center",
      Cell: ({ value }) => (
        <>
          {formatCurrencyRounded({
            accounting: true,
            number: value,
          })}
        </>
      ),
      Footer: formatCurrencyRounded({
        accounting: true,
        number: aggregatedTotals.grossCost,
      }),
      Header: copyText.accountingGrossCost,
      width: 70,
    },
    {
      accessor: "credits",
      align: "center",
      Cell: ({ value }) => (
        <>
          {formatCurrencyRounded({
            accounting: true,
            number: value,
          })}
        </>
      ),
      Footer: formatCurrencyRounded({
        accounting: true,
        number: aggregatedTotals.credits,
      }),
      Header: copyText.accountingCredits,
      width: 70,
    },
    {
      accessor: "netCost",
      align: "center",
      Cell: ({ value }) => (
        <>
          {formatCurrencyRounded({
            accounting: true,
            number: value,
          })}
        </>
      ),
      Footer: formatCurrencyRounded({
        accounting: true,
        number: aggregatedTotals.netCost,
      }),
      Header: copyText.accountingNetCost,
      width: 70,
    },
  ];

  return (
    <TableLegacy
      columns={columns}
      data={props.spendSummaries}
      compact
      footer
      showPagination
      sortable
    />
  );
}
