import { useTheme } from "@emotion/react";
import { TimeGranularity } from "@ternary/api-lib/constants/enums";
import { BudgetsCurrentDailyGraph } from "@ternary/api-lib/ui-lib/charts/BudgetsCurrentDailyGraph";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { format, subMonths } from "date-fns";
import React from "react";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { DateHelper } from "../../../lib/dates";
import BudgetsHistoricalMonthlyGraph from "../../budget-management/components/BudgetsHistoricalMonthlyGraph";
import useGetBudgetSpendByGranularity from "../../budget-management/hooks/useGetBudgetSpendByGranularity";
import useGetBudgetsByTenantID from "../../budget-management/hooks/useGetBudgetsByTenantID";
import copyText from "../copyText";

interface Props {
  budgetID: string;
  budgetForecast: number;
  createdDate: string;
}

export default function BudgetContentContainer(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const theme = useTheme();

  const now = new DateHelper();
  const CHART_HEIGHT = 350;

  // TODO: Create FindBudgetByIDQuery
  const { data: budgets = [], isLoading: isLoadingBudget } =
    useGetBudgetsByTenantID(authenticatedUser.tenant.fsDocID);

  const budget = budgets.find((budget) => budget.id === props.budgetID);

  const firstOfSelectedYear = subMonths(new Date(props.createdDate), 7);

  const { data: dailySpend = [], isLoading: isLoadingMonthlyBudgetSpend } =
    useGetBudgetSpendByGranularity(
      {
        budget,
        dateRange: [firstOfSelectedYear, now.date],
        granularity: TimeGranularity.DAY,
      },
      { enabled: !!budget }
    );

  if (!budget || isLoadingBudget) {
    return <EmptyPlaceholder loading={isLoadingBudget} />;
  }

  return (
    <Box>
      <Text appearance="h4" marginBottom={theme.space_sm}>
        {budget.name}
      </Text>
      <Flex justifyContent="space-between" width="100%">
        <Flex>
          <Text bold marginRight={theme.space_sm}>
            {copyText.budgetsMeasureLabel}
          </Text>
          <Text>{budget.measure}</Text>
        </Flex>
        {budget.scopes.map((scope) => (
          <Flex
            key={`${scope.key}${scope.values.join(",")}`}
            marginLeft={theme.space_sm}
          >
            <Text bold marginRight={theme.space_sm}>
              {scope.key}:
            </Text>
            <Text>{scope.values.join(", ")}</Text>
          </Flex>
        ))}
        <Flex>
          <Text bold marginRight={theme.space_sm}>
            {copyText.budgetsDateCapturedLabel}
          </Text>
          <Text>{format(new Date(props.createdDate), "yyyy-MM-dd")}</Text>
        </Flex>
      </Flex>
      <Flex marginTop={theme.space_lg}>
        <BudgetsCurrentDailyGraph
          budget={budget || null}
          data={dailySpend}
          height={CHART_HEIGHT}
          loading={isLoadingMonthlyBudgetSpend}
        />
        <BudgetsHistoricalMonthlyGraph
          budget={budget || null}
          dailySpend={dailySpend}
          height={CHART_HEIGHT}
          loading={isLoadingMonthlyBudgetSpend}
        />
      </Flex>
    </Box>
  );
}
