import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { noop } from "lodash";
import React from "react";
import SelectDropdown from "../../../../ui-lib/components/SelectDropdown";
import copyText from "../../copyText";
import { AWSCommittedUseLookbackPeriod, AWSCommittedUseType } from "../types";

type Props = {
  allCloudIDs: string[];
  cloudNamesKeyedByCloudID: Record<string, string>;
  committedUseType: AWSCommittedUseType | null;
  isLoading: boolean;
  lookbackPeriod: string;
  cloudID: string | null;
  onInteraction: (
    interaction: AWSCommittedUseVisibilityControls.Interaction
  ) => void;
};

type Option = { label: string; value: string };

const typeOptions: Option[] = [
  { label: "All", value: "ALL" },
  { label: "Savings Plan", value: AWSCommittedUseType.SP },
  { label: "Reserved Instance", value: AWSCommittedUseType.RI },
];

const lookbackPeriodOptions: Option[] = [
  { label: "7D", value: AWSCommittedUseLookbackPeriod.SEVEN_DAYS },
  { label: "14D", value: AWSCommittedUseLookbackPeriod.FOURTEEN_DAYS },
  { label: "30D", value: AWSCommittedUseLookbackPeriod.THIRTY_DAYS },
  { label: "60D", value: AWSCommittedUseLookbackPeriod.SIXTY_DAYS },
];

function findMatchingOption(options: Option[], value: string) {
  const found = options.find((option) =>
    value === null ? option.value === "null" : value === option.value
  );

  return found ?? options[0];
}

function AWSCommittedUseVisibilityControls(props: Props) {
  const theme = useTheme();

  const selectedTypeOption = findMatchingOption(
    typeOptions,
    props.committedUseType ?? "ALL"
  );
  const selectedLookbackPeriodOption = findMatchingOption(
    lookbackPeriodOptions,
    props.lookbackPeriod
  );

  // CLOUD IDS

  const allCloudOptions: Option[] = [
    { label: "All", value: "ALL" },
    ...props.allCloudIDs.map((cloudID) => ({
      label: props.cloudNamesKeyedByCloudID[cloudID] ?? cloudID,
      value: cloudID,
    })),
  ];

  const selectedCloudID = props.cloudID;
  const selectedCloudName = selectedCloudID
    ? (props.cloudNamesKeyedByCloudID[selectedCloudID] ?? selectedCloudID)
    : "All";

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      paddingVertical={theme.space_sm}
    >
      <Flex paddingHorizontal={theme.space_md} justifyContent="space-between">
        <Flex>
          <SelectDropdown
            hideSelectedOptions={false}
            options={typeOptions}
            placement="bottom-start"
            selectedValues={[selectedTypeOption.value]}
            onChange={(value: string) =>
              value === AWSCommittedUseType.RI ||
              value === AWSCommittedUseType.SP ||
              value === "ALL"
                ? props.onInteraction({
                    type: AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_TYPE,
                    committedUseType: value === "ALL" ? null : value,
                  })
                : noop()
            }
          >
            <Button marginRight={theme.space_md} secondary size="small">
              {copyText.awsFiltersType.replace(
                "%VALUE%",
                selectedTypeOption.label
              )}
            </Button>
          </SelectDropdown>

          <SelectDropdown
            hideSelectedOptions={false}
            options={allCloudOptions}
            placement="bottom-start"
            selectedValues={[selectedCloudID ?? "ALL"]}
            onChange={(value: string) =>
              props.onInteraction({
                type: AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_CLOUD_ID,
                cloudID: value === "ALL" ? null : value,
              })
            }
          >
            <Button marginRight={theme.space_md} secondary size="small">
              {getCloudCopyText(copyText.awsFiltersCloudIDsTypePayer, [
                selectedCloudName,
              ])}
            </Button>
          </SelectDropdown>
        </Flex>

        <Box>
          {lookbackPeriodOptions.map((option) => (
            <Button
              key={option.value}
              marginLeft={theme.space_xs}
              primary={option.value === selectedLookbackPeriodOption.value}
              secondary={option.value !== selectedLookbackPeriodOption.value}
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_LOOKBACK_PERIOD,
                  lookbackPeriod: option.value,
                })
              }
            >
              {option.label}
            </Button>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

function getCloudCopyText(type: string, items: string[]) {
  return (
    items.length === 0
      ? copyText.awsFiltersCloudIDsAll
      : items.length === 1
        ? copyText.awsFiltersCloudID
        : copyText.awsFiltersCloudIDs
  )
    .replace("%TYPE%", type)
    .replace("%VALUE%", items[0])
    .replace("%MORE%", String(items.length - 1));
}

AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_LOOKBACK_PERIOD =
  `AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_LOOKBACK_PERIOD` as const;
AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_CLOUD_ID =
  `AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_CLOUD_ID` as const;
AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_TYPE =
  `AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_TYPE` as const;

interface InteractionChangeLookbackPeriod {
  type: typeof AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_LOOKBACK_PERIOD;
  lookbackPeriod: string;
}

interface InteractionChangePayerCloudID {
  type: typeof AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_CLOUD_ID;
  cloudID: string | null;
}

interface InteractionChangeType {
  type: typeof AWSCommittedUseVisibilityControls.INTERACTION_CHANGE_TYPE;
  committedUseType: AWSCommittedUseType | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSCommittedUseVisibilityControls {
  export type Interaction =
    | InteractionChangeLookbackPeriod
    | InteractionChangePayerCloudID
    | InteractionChangeType;
}

export default AWSCommittedUseVisibilityControls;
