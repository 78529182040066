import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import {
  GcpCommitmentDurationType,
  GcpCommitmentServiceType,
} from "@ternary/api-lib/constants/enums";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import {
  formatCurrency,
  formatNumberRounded,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../../copyText";
import { CudInventoryCommitmentDatum, GcpCommitmentStatusType } from "../types";
import {
  getReadableGcpCommitmentLengthString,
  getReadableGcpCommitmentServiceTypeStrings,
} from "../utils";

type TableData = {
  commitmentEndTimestamp: string | null;
  commitmentId: string | null;
  commitmentLength: GcpCommitmentDurationType;
  hourlyCommittedAmount: number;
  owner: string | null;
  reservedMemoryGiB: number;
  reservedVCPU: number;
  reservedSlots: number;
  service: GcpCommitmentServiceType | null;
  status: GcpCommitmentStatusType | null;
};

interface Props {
  commitments: CudInventoryCommitmentDatum[];
  isLoading: boolean;
}

export function CudInventoryCommitmentTable(props: Props): JSX.Element {
  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "commitmentId",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_commitmentId,
        width: 150,
        truncate: true,
        Cell: ({ value }) => {
          if (value === null) {
            return copyText.cudInventoryCommitmentTableNotAvailable;
          }
          return value;
        },
      },
      {
        accessor: "owner",
        align: "center",
        Header: (
          <Tooltip content={copyText.cudInventoryCommitmentTableOwnerTooltip}>
            {copyText.cudInventoryCommitmentTableHeader_owner}
          </Tooltip>
        ),
        width: 150,
      },
      {
        accessor: "service",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_service,
        width: 150,
        Cell: ({ value }) => getReadableGcpCommitmentServiceTypeStrings(value),
      },
      {
        accessor: "hourlyCommittedAmount",
        align: "center",
        Header:
          copyText.cudInventoryCommitmentTableHeader_hourlyCommittedAmount,
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
      },
      {
        accessor: "reservedVCPU",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_reservedVCPU,
        Cell: ({ value }) => formatNumberRounded(value),
      },
      {
        accessor: "reservedMemoryGiB",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_reservedMemoryGiB,
        Cell: ({ value }) => prettyBytes(value),
      },
      {
        accessor: "reservedSlots",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_reservedSlots,
        Cell: ({ value }) => formatNumberRounded(value),
      },
      {
        accessor: "commitmentLength",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_commitmentLength,
        Cell: ({ value }) => getReadableGcpCommitmentLengthString(value),
      },
      {
        accessor: "commitmentEndTimestamp",
        align: "center",
        Header:
          copyText.cudInventoryCommitmentTableHeader_commitmentEndTimestamp,
        Cell: ({ value }) => {
          if (value === null) {
            return copyText.cudInventoryCommitmentTableNotAvailable;
          }
          return getCommitmentEndDateString(value);
        },
      },
      {
        accessor: "status",
        align: "center",
        Header: copyText.cudInventoryCommitmentTableHeader_status,
        Cell: ({ value }) => getReadableGcpCommitmentStatusString(value),
      },
    ],
    []
  );

  const data = useMemo(() => {
    return getTableDataFromEntrys(props.commitments);
  }, [props.commitments]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{ sortBy: [{ id: "amountSaved", desc: true }] }}
      isLoading={props.isLoading}
      showEmptyTable
      showPagination
      sortable
    />
  );
}

function getCommitmentEndDateString(commitmentEndDate: string) {
  const theme = useTheme();

  const numberOfDaysBetweenNowAndEnd =
    (new Date(commitmentEndDate).getTime() - new Date().getTime()) /
    (24 * 60 * 60 * 1000);

  if (numberOfDaysBetweenNowAndEnd >= 0 && numberOfDaysBetweenNowAndEnd < 30) {
    return (
      <Text color={theme.feedback_negative}>
        {formatDate(new Date(commitmentEndDate ?? 0), "dd-MMM-yyyy")}
      </Text>
    );
  }
  return (
    <Text>{formatDate(new Date(commitmentEndDate ?? 0), "dd-MMM-yyyy")}</Text>
  );
}

function getTableDataFromEntrys(instances: CudInventoryCommitmentDatum[]) {
  return instances.map((node): TableData => {
    return {
      commitmentEndTimestamp: node.commitmentEndTimestamp,
      commitmentId: node.commitmentId,
      commitmentLength: node.commitmentLength,
      hourlyCommittedAmount: node.hourlyCommittedAmount ?? 0,
      owner: node.owner,
      reservedMemoryGiB: node.reservedMemoryGiB ?? 0,
      reservedVCPU: node.reservedVCPU ?? 0,
      reservedSlots: node.reservedSlots ?? 0,
      service: node.service,
      status: node.status,
    };
  });
}

export function getReadableGcpCommitmentStatusString(
  commitmentStatus: GcpCommitmentStatusType | null
): JSX.Element {
  const theme = useTheme();
  switch (commitmentStatus) {
    case GcpCommitmentStatusType.ACTIVE:
      return (
        <Text color={theme.feedback_positive}>
          {copyText.cudVisInventoryCommitmentStatusActive}
        </Text>
      );
    case GcpCommitmentStatusType.EXPIRED:
      return (
        <Text color={theme.feedback_negative}>
          {copyText.cudVisInventoryCommitmentStatusExpired}
        </Text>
      );
    case GcpCommitmentStatusType.CANCELLED:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudVisInventoryCommitmentStatusCancelled}
        </Text>
      );
    case GcpCommitmentStatusType.NOT_YET_ACTIVE:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudVisInventoryCommitmentStatusNotYetActive}
        </Text>
      );
    default:
      return (
        <Text color={theme.feedback_neutral}>
          {copyText.cudInventoryTableNotAvailable}
        </Text>
      );
  }
}
