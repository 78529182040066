import commonCopyText from "../../common.copyText";

export default {
  ...commonCopyText,
  chartTitleTotalSpend: "Total Spend",
  dropdownLabelGroupings: "Groupings:",
  dropdownLabelLookbackRange: "Lookback Range",
  dropdownLabelMeasure: "Measure:",
  dropdownLabelVendor: "Vendor:",
  groupingsDropdownPlaceholder: "Select a grouping...",
  forecastingPageTitle: "Forecasting",
  forecastingChartOptionCurrentMonth: "Current Month",
  forecastingChartOptionLookbackRange: "Lookback Range",
  lookbackRangeLabel_default: "Default",
  lookBackRangeLabel_NINETY_DAYS: "90 days",
  lookBackRangeLabel_ONE_HUNDRED_EIGHTY_DAYS: "180 days",
  lookBackRangeLabel_ONE_HUNDRED_FIFTY_DAYS: "150 days",
  lookBackRangeLabel_ONE_HUNDRED_TWENTY_DAYS: "120 days",
  lookBackRangeLabel_SIXTY_DAYS: "60 days",
  lookBackRangeLabel_THIRTY_DAYS: "30 days",
  lookbackToggleToolTipMessage: "show lookback period cost",
  measureLabel_amortizedCost: "Amortized Cost",
  measureLabel_cost: "Cost",
  measureLabel_customNetCost: "Custom Net Cost",
  measureLabel_indirectCost: "Indirect Cost",
  measureLabel_netAmortizedCost: "Net Amortized Cost",
  measureLabel_netCost: "Net Cost",
  meterLabelCurrentMtdSpend: "Current MTD Spend",
  meterLabelEstimatedDeltaSpend: "Estimated Delta Spend",
  meterLabelEstimatedMonthlyTotalSpend: "Estimated Monthly Total Spend",
  meterLabelPreviousMonthTotal: "Previous Month Total",
  meterLabelPreviousMtdSpend: "Previous MTD Spend",
  meterLabelPrevious3MonthAverageSpend: "Previous 3 Mo. Avg Spend",
  tableHeaderCurrentSpend: "Current Month Spend",
  tableHeaderEstimatedDeltaSpend: "Estimated Delta Spend",
  tableHeaderEstimatedTotalSpend: "Estimated Total Spend",
  tableHeaderPrevMonthTotal: "Previous Month Total",
  tableHeaderServiceDescription: "Service Description",
  vendorLabel_ALL: "All",
  vendorLabel_AWS: "AWS",
  vendorLabel_AZURE: "Azure",
  vendorLabel_GCP: "GCP",
};
