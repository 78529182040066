import useGatekeeper from "@/hooks/useGatekeeper";
import Dropdown from "@/ui-lib/components/Dropdown";
import { ActionMenuButton, TableLegacy } from "@/ui-lib/components/Table";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo } from "react";
import { Column } from "react-table";
import copyText from "../copyText";

type Bill = {
  id: string;
  cloudProviderType: CloudProviderType;
  createdAt: string;
  createdBy: string;
  name: string;
};

type TableData = {
  id: string;
  cloudProviderType: CloudProviderType;
  createdAt: string;
  createdBy: string;
  name: string;
};

interface Props {
  bills: Bill[];
  isLoading: boolean;
  onInteraction: (interaction: BillTable.Interaction) => void;
}

export function BillTable(props: Props): JSX.Element {
  const gatekeeper = useGatekeeper();

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "actionMenu",
        align: "center",
        Header: "",
        Cell: function renderButton({ row }) {
          return (
            <BillDropdown
              billID={row.original.id}
              onInteraction={props.onInteraction}
            />
          );
        },
        disableSortBy: true,
        width: 50,
      },
      {
        accessor: "name",
        Header: copyText.billTableHeaderBillName,
        width: 200,
      },
      {
        accessor: "cloudProviderType",
        Header: copyText.billTableHeaderSource,
        width: 200,
      },
      {
        accessor: "createdBy",
        Header: copyText.billTableHeaderCreatedBy,
        width: 200,
      },
      {
        accessor: "createdAt",
        Cell: ({ value }) => (
          <>{formatDate(new Date(value ? value : 0), "MM/dd/yyyy hh:mm a")}</>
        ),
        Header: copyText.billTableHeaderCreatedAt,
        width: 200,
      },
    ],
    []
  );

  return (
    <TableLegacy
      columns={columns}
      data={props.bills}
      initialState={{ sortBy: [{ id: "displayName", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );

  interface BillDropdownProps {
    billID: string;
    onInteraction: (interaction: BillTable.Interaction) => void;
  }

  function BillDropdown(dropdownProps: BillDropdownProps): JSX.Element {
    const options = [
      {
        label: copyText.actionMenuItemGenerateReport,
        locked: !gatekeeper.canGenerateTakeoutReport,
        onClick: () =>
          dropdownProps.onInteraction({
            type: BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED,
            billID: dropdownProps.billID,
          }),
      },
      {
        label: copyText.actionMenuItemDeleteBill,
        locked: !gatekeeper.getCanDeleteSpecificBill(
          props.bills.find((bill) => bill.id === dropdownProps.billID)
            ?.createdBy ?? ""
        ),
        onClick: () =>
          dropdownProps.onInteraction({
            type: BillTable.INTERACTION_DELETE_BUTTON_CLICKED,
            billID: dropdownProps.billID,
          }),
      },
    ];

    return (
      <Dropdown options={options} placement="bottom-start">
        <ActionMenuButton />
      </Dropdown>
    );
  }
}

BillTable.INTERACTION_DELETE_BUTTON_CLICKED = `BillTable.INTERACTION_DELETE_BUTTON_CLICKED`;
BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED = `BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED`;

interface InteractionDeleteButtonClicked {
  type: typeof BillTable.INTERACTION_DELETE_BUTTON_CLICKED;
  billID: string;
}

interface InteractionGenerateTakeoutButtonClicked {
  type: typeof BillTable.INTERACTION_GENERATE_TAKEOUT_BUTTON_CLICKED;
  billID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BillTable {
  export type Interaction =
    | InteractionDeleteButtonClicked
    | InteractionGenerateTakeoutButtonClicked;
}

export default BillTable;
