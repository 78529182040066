import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAwsEBS(props: IconProps): JSX.Element {
  return (
    <Icon {...props}>
      <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Icon-Architecture/48/Arch_Amazon-Elastic-Block-Store_48</title>
        <g
          id="Icon-Architecture/48/Arch_Amazon-Elastic-Block-Store_48"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Icon-Architecture-BG/48/Storage" fill="#7AA116">
            <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
          </g>
          <path
            d="M52,46 L54,46 L54,54 L46,54 L46,52 L50.796,52 L45.247,45.659 L46.753,44.341 L52,50.338 L52,46 Z M19.753,45.659 L14.204,52 L19,52 L19,54 L11,54 L11,46 L13,46 L13,50.338 L18.247,44.341 L19.753,45.659 Z M54,10 L54,18 L52,18 L52,13.662 L46.753,19.659 L45.247,18.341 L50.796,12 L46,12 L46,10 L54,10 Z M14.204,12 L19.753,18.341 L18.247,19.659 L13,13.662 L13,18 L11,18 L11,10 L19,10 L19,12 L14.204,12 Z M32.5,24 C26.493,24 24,22.697 24,22 C24,21.227 26.308,20 32.5,20 C38.363,20 41,21.169 41,22 C41,22.697 38.507,24 32.5,24 L32.5,24 Z M32.586,43 C26.664,43 24,41.831 24,41 L24,24.622 C26.406,25.82 30.387,26 32.5,26 C34.613,26 38.594,25.82 41,24.622 L41,41 C41,41.831 38.39,43 32.586,43 L32.586,43 Z M32.5,18 C27.71,18 22,18.694 22,22 L22,41 C22,44.306 27.757,45 32.586,45 C36.476,45 43,44.48 43,41 L43,22 C43,18.52 36.422,18 32.5,18 L32.5,18 Z"
            id="Amazon-Elastic-Block-Store_Icon_48_Squid"
            fill="#FFFFFF"
          ></path>
        </g>
      </svg>
    </Icon>
  );
}
