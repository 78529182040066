import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";
import Flex from "../Flex";

export function CUDsLoader() {
  const theme = useTheme();

  const GetSide = () => (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 500 500"
      preserveAspectRatio="none"
      style={{
        height: "100%",
        width: "49%",
      }}
    >
      {/* Slider Labels  */}
      <rect
        x="0"
        y="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="100"
        height="30"
      />
      <rect
        x="0"
        y="75"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="100"
        height="30"
      />
      <rect
        x="380"
        y="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="90"
        height="30"
      />
      <rect
        x="380"
        y="75"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="90"
        height="30"
      />

      {/* Slider Bars  */}
      <rect
        x="125"
        y="20"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="220"
        height="15"
      />
      <rect
        x="125"
        y="80"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="220"
        height="15"
      />

      {/* Slider Handles  */}
      <circle cx={Math.random() * 100 + 200} cy="27.5" r="15" />
      <circle cx={Math.random() * 100 + 200} cy="87.5" r="15" />

      {/* Total Savings */}
      <rect
        x="300"
        y="130"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="100"
        height="40"
      />

      {/* Bar Chart */}
      {/* Axis */}
      <rect x="50" y="130" width="7" height="130" />
      <rect x="50" y="260" width="150" height="9" />

      {/* Y-Axis Labels */}
      <rect
        x="15"
        y="145"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="15"
        y="185"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="15"
        y="225"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />

      {/* Cartesian Grid Lines */}
      <rect x="70" y="150" width="125" height="4" />
      <rect x="70" y="190" width="125" height="4" />
      <rect x="70" y="230" width="125" height="4" />

      {/* Mini Table */}
      <rect
        x="320"
        y="190"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="150"
        height="15"
      />
      <rect
        x="320"
        y="220"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="150"
        height="15"
      />
      <rect
        x="320"
        y="250"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="150"
        height="15"
      />

      {/* Usage Chart */}
      {/* Axis */}
      <rect x="50" y="300" width="7" height="140" />
      <rect x="50" y="440" width="425" height="9" />

      {/* Cartesian Grid Lines */}
      <rect x="70" y="340" width="380" height="5" />
      <rect x="70" y="370" width="380" height="5" />
      <rect x="70" y="400" width="380" height="5" />

      {/* Y-Axis Labels */}
      <rect
        x="15"
        y="335"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="15"
        y="365"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="15"
        y="395"
        width="25"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />

      {/* X-Axis Labels */}
      <rect
        x="120"
        y="460"
        width="50"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="240"
        y="460"
        width="50"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
      <rect
        x="360"
        y="460"
        width="50"
        height="15"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
      />
    </ContentLoader>
  );
  const Divider = (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 10 500"
      preserveAspectRatio="none"
      style={{
        height: "100%",
        width: "1%",
      }}
    >
      <rect x="0" y="5" width="5" height="495" />
    </ContentLoader>
  );

  return (
    <Flex justifyContent="space-between" width="100%" height="100%">
      {GetSide()}
      {Divider}
      {GetSide()}
    </Flex>
  );
}
