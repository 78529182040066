import { useTheme } from "@emotion/react";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import TimeSeriesChartTooltip from "@ternary/web-ui-lib/charts/TimeSeriesChartTooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/web-ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import {
  formatCurrency,
  formatCurrencyRounded,
} from "@ternary/web-ui-lib/utils/formatNumber";
import { isBefore } from "date-fns";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DateHelper } from "../../../lib/dates";
import { getUTCMonthString } from "../../../utils/dates";
import copyText from "../copyText";
import {
  CommitmentPeriod,
  NetCostByDate,
  NetCostByDateDimensional,
  RampPlanBreakpoint,
  RampPlanBreakpointVersions,
} from "../types";
import {
  applyOffetsToActualSpend,
  getApplicableEntries,
  getCumulativeActualSpend,
  getCumulativeRampPlanMonths,
} from "../utils/rampPlans";

type RampPlanNonExportOffset = {
  [key: string]: number;
};

type RampPlanNonExportOffsetByMonth = {
  [month: string]: RampPlanNonExportOffset;
};

type RampPlan = {
  id: string;
  billingAccountIDs: string[];
  breakpoints: RampPlanBreakpoint[];
  commitments: CommitmentPeriod[];
  name: string;
  enumeratedValues: string[];
  key: string;
  nonExportOffsetByMonth: RampPlanNonExportOffsetByMonth;
  nonExportOffsetRecurring: RampPlanNonExportOffset;
};

interface Props {
  actualSpend: NetCostByDate[];
  allRampPlanMonths: NetCostByDateDimensional[];
  commitment: CommitmentPeriod;
  commitments: CommitmentPeriod[];
  commitmentCount: number;
  index: number;
  largeView: boolean;
  latestBreakpointVersions: (RampPlanBreakpointVersions & {
    month: string;
  })[];
  loading: boolean;
  nonExportOffsetRecurring: RampPlan["nonExportOffsetRecurring"];
  nonExportOffsetByMonth: RampPlan["nonExportOffsetByMonth"];
  overflow: number;
}

export default function RampPlanCommitmentChart(props: Props): JSX.Element {
  const theme = useTheme();

  const now = new DateHelper();
  const currentMonth = formatDate(now.date, "yyyy-MM");

  const previousCommitmentEndDate =
    props.commitments[
      props.commitments.findIndex((commitment) => {
        return commitment._id === props.commitment._id;
      }) - 1
    ]?.end;

  const commitmentStartDate = new Date(props.commitment.start);

  // NOTE: Prevents counting the same month twice in overlapping commitment periods
  const commitmentStartMonth = getUTCMonthString(
    props.index === 0 ||
      getUTCMonthString(props.commitment.start) !==
        getUTCMonthString(previousCommitmentEndDate)
      ? props.commitment.start
      : formatDate(
          commitmentStartDate.setMonth(commitmentStartDate.getMonth() + 1),
          "yyyy-MM"
        )
  );

  const commitmentEndMonth = getUTCMonthString(props.commitment.end);

  // NOTE: modifying actual spend here to reflect offsets - don't use props' value
  const modifiedActualSpend = applyOffetsToActualSpend(
    props.actualSpend,
    props.nonExportOffsetByMonth,
    props.nonExportOffsetRecurring
  );

  const applicableActualSpend = modifiedActualSpend.filter((entry) =>
    getApplicableEntries(
      entry,
      commitmentStartMonth,
      commitmentEndMonth,
      "date"
    )
  );

  const applicableLatestBreakpoints = props.latestBreakpointVersions.filter(
    (entry) =>
      getApplicableEntries(
        entry,
        commitmentStartMonth,
        commitmentEndMonth,
        "month"
      )
  );

  const cumulativeActualSpend: {
    date: string;
    actualNetCost: number;
  }[] = useMemo(
    () => getCumulativeActualSpend(applicableActualSpend, props.overflow),
    [modifiedActualSpend]
  );

  const applicableMonths = props.allRampPlanMonths.filter((entry) =>
    getApplicableEntries(
      entry,
      commitmentStartMonth,
      commitmentEndMonth,
      "date"
    )
  );

  const cumulativeActualSpendEndMonth =
    cumulativeActualSpend[cumulativeActualSpend.length - 1]?.date;

  const fullCumulativeActualSpend = useMemo(() => {
    const cumulativeApplicableMonths = applicableMonths.filter(
      (month) =>
        isBefore(
          new Date(month.date),
          new Date(cumulativeActualSpendEndMonth)
        ) || month.date === cumulativeActualSpendEndMonth
    );

    return cumulativeApplicableMonths.map((month) => {
      let actualSpend = cumulativeActualSpend.find((actual) => {
        return getUTCMonthString(actual.date) === month.date;
      });

      if (
        actualSpend === undefined &&
        isBefore(new Date(month.date), new Date(cumulativeActualSpendEndMonth))
      ) {
        actualSpend = { date: month.date, actualNetCost: 0 };
      }

      return {
        date: month.date,
        actualNetCost: actualSpend ? actualSpend.actualNetCost : 0,
      };
    });
  }, [applicableMonths, cumulativeActualSpend]);

  const cumulativeRampPlanMonths = useMemo(
    () => getCumulativeRampPlanMonths(applicableMonths, props.overflow),
    [applicableMonths]
  );

  // Monthly spend data
  const runRateChartData: {
    date: string;
    actualNetCost: number | null;
    isBreakpoint: boolean;
    rampPlanNetCost: number;
    reason: string;
  }[] = useMemo(
    () =>
      applicableMonths.map((month) => {
        const netCost = month.dimensions.reduce((accum, dimension) => {
          return accum + dimension.netCost;
        }, 0);

        let actualSpend = applicableActualSpend.find(
          (actual) => getUTCMonthString(actual.date) === month.date
        );

        if (
          actualSpend === undefined &&
          isBefore(
            new Date(month.date),
            new Date(cumulativeActualSpendEndMonth)
          )
        ) {
          actualSpend = { date: month.date, netCost: 0 };
        }

        const breakpoint = applicableLatestBreakpoints.find(
          (bp) => bp.month === month.date
        );

        return {
          date: month.date,
          actualNetCost: actualSpend?.netCost ?? null,
          rampPlanNetCost: netCost,
          isBreakpoint: !!breakpoint,
          reason: breakpoint?.reason ?? "",
        };
      }),
    // More dependencies
    [applicableMonths, applicableActualSpend, cumulativeActualSpendEndMonth]
  );

  const runRateDataKeyedByDate = useMemo(
    () => {
      return keyBy(runRateChartData, "date");
    },
    // More dependencies
    [applicableMonths]
  );

  // Cumulative spend chart "Ramp Plan"
  const unadjustedRampPlanChartData = useMemo(() => {
    return cumulativeRampPlanMonths.map((entry) => {
      return {
        date: entry.date,
        rampPlanNetCost: entry.dimensions.reduce(
          (total, dimension) => dimension.netCost + total,
          0
        ),
      };
    });
  }, [applicableMonths]);

  const projectedRampPlanBasedOnActuals = useMemo(() => {
    const startingPoint =
      fullCumulativeActualSpend[fullCumulativeActualSpend.length - 1];

    if (!startingPoint) {
      // implies that there is no spend in this period
      return [];
    }

    const remainingMonths = applicableMonths.filter((month) =>
      isBefore(new Date(startingPoint.date), new Date(month.date))
    );

    // In the case of a comitment period that's fully in the past, we don't need this line
    if (remainingMonths.length <= 1) {
      return [];
    }

    const output = [
      {
        date: startingPoint.date,
        derivedProjection: startingPoint.actualNetCost,
      },
    ];
    remainingMonths.forEach((month) => {
      output.push({
        date: month.date,
        derivedProjection:
          month.dimensions.reduce(
            (total, dimension) => dimension.netCost + total,
            0
          ) + output[output.length - 1].derivedProjection,
      });
    });

    return output;
  }, [applicableMonths, modifiedActualSpend]);

  const [finalAmountForVariance, relativeTime] = useMemo(() => {
    // current period -> show ramp plan adjusted by actuals
    if (projectedRampPlanBasedOnActuals.length > 0) {
      return [
        projectedRampPlanBasedOnActuals[
          projectedRampPlanBasedOnActuals.length - 1
        ].derivedProjection,
        0,
      ];
    }

    // future period -> show unadjusted ramp plan
    if (fullCumulativeActualSpend.length === 0) {
      return [
        unadjustedRampPlanChartData[unadjustedRampPlanChartData.length - 1]
          .rampPlanNetCost,
        1,
      ];
    }

    // past period -> show actual
    return [
      fullCumulativeActualSpend[fullCumulativeActualSpend.length - 1]
        .actualNetCost,
      -1,
    ];
  }, [props.commitment, modifiedActualSpend, props.latestBreakpointVersions]);
  const finalVariance = finalAmountForVariance - props.commitment.amount;

  let varianceText = "";
  let titleText = "";

  switch (relativeTime) {
    case -1:
      varianceText = copyText.commitmentChartLabelActualSpend;
      titleText = copyText.commitmentChartCommitmentCompleted;
      break;
    case 0:
      varianceText = copyText.commitmentChartLabelDerivedProjection;
      titleText = copyText.commitmentChartCommitmentInProgress;
      break;
    case 1:
      varianceText = copyText.commitmentChartLabelProjected;
      titleText = copyText.commitmentChartCommitmentFuture;
      break;
  }

  const headerFontSize = props.largeView
    ? theme.h4_fontSize
    : theme.h5_fontSize;
  const headerFontWeight = props.largeView
    ? theme.h3_fontWeight
    : theme.h4_fontWeight;

  const barLabelsRenderedYPositionKeyedByIndex: { [index: string]: number } =
    {};

  function conditionallyRenderBarLabels(props) {
    const datum = runRateChartData[props.index];

    if (!datum.isBreakpoint) return null;

    const { x, y, width } = props;

    const labelText = formatCurrencyRounded({
      number: datum.rampPlanNetCost,
    });

    // To prevent sequential long label strings from overlapping
    let recentPriorLabels = false;
    const countOfPriorIndexesToBlockOn = Math.ceil(
      (labelText.length * 5) / runRateChartData.length
    );

    for (let i = 0; i < countOfPriorIndexesToBlockOn; i++) {
      // Checking horizontal and vertical overlap potential
      if (
        barLabelsRenderedYPositionKeyedByIndex[props.index - i] &&
        Math.abs(y - barLabelsRenderedYPositionKeyedByIndex[props.index - i]) <
          20
      ) {
        recentPriorLabels = true;
      }
    }

    if (datum.isBreakpoint && !recentPriorLabels) {
      barLabelsRenderedYPositionKeyedByIndex[props.index] = y;

      return (
        <g>
          <text
            fontSize={theme.h6_fontSize}
            x={x + width / 2}
            y={y - 10}
            fill={theme.text_color}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {labelText}
          </text>
        </g>
      );
    }
  }

  const cumulativeChartDataKeyedByDate = useMemo(() => {
    return unadjustedRampPlanChartData.reduce((accum, rpd) => {
      const projected = projectedRampPlanBasedOnActuals.find(
        (entity) => entity.date === rpd.date
      );
      const actual = fullCumulativeActualSpend.find(
        (entity) => entity.date === rpd.date
      );

      return {
        ...accum,
        [rpd.date]: {
          date: rpd.date,
          actualNetCost: actual ? actual.actualNetCost : 0,
          derivedProjection: projected ? projected.derivedProjection : 0,
          rampPlanNetCost: rpd.rampPlanNetCost,
        },
      };
    }, {});
  }, [
    fullCumulativeActualSpend,
    projectedRampPlanBasedOnActuals,
    unadjustedRampPlanChartData,
  ]);

  if (props.loading) {
    return (
      <EmptyPlaceholder
        loading={props.loading}
        icon={faChartLine}
        skeletonVariant="cartesian"
        height={"350px"}
      />
    );
  }

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      height="110%"
      width="100%"
      marginBottom={`-${theme.space_md}`}
    >
      <Flex justifyContent="space-between">
        <div>
          <Text
            as="h4"
            fontSize={headerFontSize}
            fontWeight={headerFontWeight}
          >{`${copyText.rampPlanConceptCommitmentPeriod} ${props.index + 1} / ${
            props.commitmentCount
          }`}</Text>
          <Text
            fontSize={theme.h5_fontSize}
            fontWeight={theme.h5_fontWeight}
            marginTop={"-0.5rem"}
          >
            {titleText}
          </Text>
        </div>
        <div>
          <Text
            as="h4"
            fontSize={headerFontSize}
            fontWeight={headerFontWeight}
            color={
              finalVariance >= 0
                ? theme.feedback_positive
                : theme.feedback_negative
            }
          >{`${
            !isNaN(Number(finalVariance))
              ? formatCurrency({
                  accounting: true,
                  number: finalVariance,
                })
              : "--"
          } ${copyText.rampPlanConceptVariance}`}</Text>
          <Text
            align="right"
            fontSize={theme.h5_fontSize}
            fontWeight={theme.h5_fontWeight}
            marginTop={"-0.5rem"}
          >
            {varianceText}
          </Text>
        </div>
      </Flex>
      <Box
        backgroundColor={theme.secondary_color_background}
        marginVertical={theme.space_xs}
        padding="1px"
        width="100%"
      />
      <Text as="h4" fontSize={headerFontSize} fontWeight={headerFontWeight}>
        {copyText.commitmentChartMonthSpend}
      </Text>
      <ResponsiveContainer width={"100%"} height={"35%"}>
        <ComposedChart
          data={runRateChartData}
          margin={{
            bottom: 0,
            left: 30,
            right: 20,
            top: 20,
          }}
        >
          <CartesianGrid stroke={theme.chart_cartesian_grid_lines} />
          <Bar
            dataKey={"rampPlanNetCost"}
            isAnimationActive={false}
            barSize={props.largeView ? 20 : 10}
          >
            {props.largeView && (
              <LabelList
                content={conditionallyRenderBarLabels}
                position="top"
                strokeWidth={0}
              />
            )}
            {runRateChartData.map((entry) => {
              const isBreakpoint = applicableLatestBreakpoints.find(
                (bp) => bp.month === entry.date
              );

              return (
                <Cell
                  key={entry.date}
                  stroke={theme.ramp_plans_projected_stroke}
                  fill={theme.ramp_plans_projected}
                  strokeWidth={isBreakpoint ? "3px" : "0"}
                />
              );
            })}
          </Bar>

          <Line
            dataKey={"actualNetCost"}
            dot={false}
            isAnimationActive={false}
            stroke={theme.ramp_plans_actuals}
            strokeWidth="3px"
            type="monotone"
          />
          <XAxis
            angle={props.largeView ? 0 : 45}
            dataKey={"date"}
            padding={
              props.largeView
                ? { left: 30, right: 30 }
                : { left: 10, right: 10 }
            }
            scale="point"
            stroke={theme.chart_axis_text}
            tick={{
              fontWeight: 100,
              fontSize: props.largeView ? "0.8rem" : "0.7rem",
            }}
            tickCount={props.largeView ? 6 : 4}
            tickFormatter={(str) => {
              const [year, month] = str.split("-");
              return `${month}/${year.slice(2)}`;
            }}
            tickMargin={10}
          />
          <YAxis
            stroke={theme.chart_axis_text}
            tick={{
              fontWeight: 100,
              fontSize: props.largeView ? "0.8rem" : "0.7rem",
            }}
            tickFormatter={(number) =>
              formatCurrencyRounded({
                number,
              })
            }
            tickMargin={10}
          />
          <Tooltip
            content={(e) => {
              const reason = runRateDataKeyedByDate[e.label]?.reason;
              return (
                <TimeSeriesChartTooltip
                  customColors={[
                    theme.ramp_plans_projected,
                    theme.ramp_plans_actuals,
                  ]}
                  dateFormat={"MM/yyyy"}
                  entry={{
                    date: e.label,
                    ...(reason
                      ? {
                          [reason]:
                            runRateDataKeyedByDate[e.label]?.rampPlanNetCost,
                        }
                      : {
                          [copyText.commitmentChartLabelProjected]:
                            runRateDataKeyedByDate[e.label]?.rampPlanNetCost,
                        }),
                    [copyText.commitmentChartLabelActualSpend]:
                      runRateDataKeyedByDate[e.label]?.actualNetCost ?? 0,
                  }}
                  excludedGroupings={[]}
                  hideTotal
                  label={e.label}
                  reverseSortedGroupings={[
                    copyText.commitmentChartLabelActualSpend,
                    ...(reason
                      ? [reason]
                      : [copyText.commitmentChartLabelProjected]),
                  ]}
                  unitType={UnitType.CURRENCY}
                />
              );
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <Text as="h4" fontSize={headerFontSize} fontWeight={headerFontWeight}>
        {copyText.commitmentChartCumulativeSpend}
      </Text>
      <ResponsiveContainer width={"100%"} height={"65%"}>
        <LineChart
          margin={{
            bottom: 20,
            left: 30,
            right: 20,
            top: 15,
          }}
        >
          <ReferenceLine
            y={props.commitment.amount}
            ifOverflow="extendDomain"
            stroke={theme.feedback_negative}
            strokeWidth="2px"
            strokeDasharray="8 8"
          />
          <ReferenceLine
            x={currentMonth}
            stroke={theme.text_color_secondary}
            strokeWidth="2px"
            strokeDasharray="2 2"
          />
          <CartesianGrid stroke={theme.chart_cartesian_grid_lines} />
          <Line
            data={unadjustedRampPlanChartData}
            dataKey={"rampPlanNetCost"}
            dot={false}
            isAnimationActive={false}
            stroke={theme.ramp_plans_projected}
            strokeWidth="2px"
            type="monotone"
          />
          <Line
            data={projectedRampPlanBasedOnActuals}
            dataKey={"derivedProjection"}
            dot={false}
            isAnimationActive={false}
            stroke={theme.ramp_plans_derived_projection}
            strokeWidth="4px"
            type="monotone"
          />
          <Line
            data={fullCumulativeActualSpend}
            dataKey={"actualNetCost"}
            dot={false}
            isAnimationActive={false}
            stroke={theme.ramp_plans_actuals}
            strokeWidth="4px"
            type="monotone"
          />
          {applicableLatestBreakpoints.map((v, index) => {
            const month = cumulativeRampPlanMonths.find(
              (month) => month.date === v.month
            );

            const total = !month
              ? 0
              : month.dimensions.reduce((total, dim) => total + dim.netCost, 0);
            return (
              <ReferenceDot
                key={`${v.month} - ${index}`}
                x={v.month}
                y={total}
                stroke={theme.ramp_plans_projected}
                strokeWidth={4}
                r={4}
                fill={theme.panel_backgroundColor}
              />
            );
          })}
          <XAxis
            angle={props.largeView ? 0 : 45}
            tickCount={props.largeView ? 6 : 4}
            allowDuplicatedCategory={false}
            dataKey="date"
            padding={
              props.largeView
                ? { left: 30, right: 30 }
                : { left: 10, right: 10 }
            }
            stroke={theme.chart_axis_text}
            tick={{
              fontWeight: 100,
              fontSize: props.largeView ? "0.8rem" : "0.7rem",
            }}
            tickFormatter={(str) => {
              const [year, month] = str.split("-");
              return `${month}/${year.slice(2)}`;
            }}
            tickMargin={10}
          />
          <YAxis
            stroke={theme.chart_axis_text}
            tick={{
              fontWeight: 100,
              fontSize: props.largeView ? "0.8rem" : "0.7rem",
            }}
            tickFormatter={(value) =>
              formatCurrencyRounded({
                number: value,
              })
            }
            tickMargin={10}
          />
          <Tooltip
            content={(e) => {
              const entry = {
                ...cumulativeChartDataKeyedByDate[e.label],
                [copyText.commitmentChartLabelActualSpend]:
                  cumulativeChartDataKeyedByDate[e.label]?.actualNetCost,
                [copyText.commitmentChartLabelDerivedProjection]:
                  cumulativeChartDataKeyedByDate[e.label]?.derivedProjection,
                Commitment: props.commitment.amount,
                [copyText.commitmentChartLabelProjected]:
                  cumulativeChartDataKeyedByDate[e.label]?.rampPlanNetCost,
              };

              return (
                <TimeSeriesChartTooltip
                  customColors={[
                    theme.feedback_negative,
                    theme.ramp_plans_projected,
                    ...(entry[copyText.commitmentChartLabelDerivedProjection]
                      ? [theme.ramp_plans_derived_projection]
                      : []),
                    ...(entry[copyText.commitmentChartLabelActualSpend]
                      ? [theme.ramp_plans_actuals]
                      : []),
                  ]}
                  dateFormat={"MM/yyyy"}
                  entry={entry}
                  excludedGroupings={[]}
                  hideTotal={true}
                  label={e.label}
                  reverseSortedGroupings={[
                    ...(entry[copyText.commitmentChartLabelActualSpend]
                      ? [copyText.commitmentChartLabelActualSpend]
                      : []),
                    ...(entry[copyText.commitmentChartLabelDerivedProjection]
                      ? [copyText.commitmentChartLabelDerivedProjection]
                      : []),
                    copyText.commitmentChartLabelProjected,
                    copyText.commitmentChartCommitmentCommitment,
                  ]}
                  unitType={UnitType.CURRENCY}
                />
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Flex>
  );
}
