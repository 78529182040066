import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { repeat } from "lodash";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../../ui-lib/components/Form";
import TextInput from "../../../../ui-lib/components/TextInput";
import getMergeState from "../../../../utils/getMergeState";
import copyText from "../../copyText";

export type MemberAccount = {
  id: string | null;
  name: string;
  passwordPreview: string;
  role: string;
  username: string;
  warehouse: string;
};

export type SnowflakeMemberAccount = {
  account: {
    id: string | null;
    name: string;
    passwordPreview: string;
    role: string;
    username: string;
    warehouse: string;
  };
  orgName: string;
};

interface Props {
  tabNumber: number;
  isLoading: boolean;
  integration?: SnowflakeMemberAccount;
  errorMessage?: string;
  onChange: (tabNumber: number, account: MemberAccount) => void;
}

interface State {
  initialID: string | null;
  accountNameInput: string;
  accountRoleInput: string;
  passwordInput: string;
  usernameInput: string;
  warehouseInput: string;
}

const initialState: State = {
  initialID: null,
  accountNameInput: "",
  accountRoleInput: "",
  passwordInput: "",
  usernameInput: "",
  warehouseInput: "",
};

export default function SnowflakeIntegrationFormAccount(
  props: Props
): JSX.Element {
  const theme = useTheme();
  const integration = props.integration;

  const [state, setState] = useState(
    integration
      ? {
          ...initialState,
          initialID: integration.account.id,
          accountNameInput: integration.account.name,
          accountRoleInput: integration.account.role,
          nameInput: integration.account.name,
          passwordInput: integration.account.passwordPreview,
          usernameInput: integration.account.username,
          warehouseInput: integration.account.warehouse,
        }
      : initialState
  );

  const mergeState = getMergeState(setState);

  useEffect(() => {
    if (
      state.accountNameInput.length > 0 &&
      state.accountRoleInput.length > 0 &&
      state.passwordInput.length > 0 &&
      state.usernameInput.length > 0 &&
      state.warehouseInput.length > 0
    ) {
      const account = {
        id: state.initialID,
        name: state.accountNameInput,
        passwordPreview: state.passwordInput,
        role: state.accountRoleInput,
        username: state.usernameInput,
        warehouse: state.warehouseInput,
      };
      props.onChange(props.tabNumber, account);
    }
  }, [state]);

  return (
    <Flex height={450} minWidth={400} scrollable>
      <Box padding={"0.5rem"} width={"100%"}>
        {props.errorMessage && (
          <Box
            backgroundColor={theme.feedback_warn_background}
            borderRadius={theme.borderRadius_2}
            marginBottom={theme.space_sm}
            padding={theme.space_sm}
            width={"100%"}
          >
            <Text>{props.errorMessage}</Text>
          </Box>
        )}
        <FormField
          name={`accountName-${props.tabNumber}`}
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeAccountName}
          required
          type="text"
          value={state.accountNameInput}
          variant={
            state.accountNameInput.trim().length > 0 ? "success" : "danger"
          }
          onChange={(e) => mergeState({ accountNameInput: e.target.value })}
        />
        <FormField
          name={`accountRole-${props.tabNumber}`}
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeRole}
          required
          type="text"
          value={state.accountRoleInput}
          variant={
            state.accountRoleInput.trim().length > 0 ? "success" : "danger"
          }
          onChange={(e) => mergeState({ accountRoleInput: e.target.value })}
        />
        <FormField
          name={`"warehouse"-${props.tabNumber}`}
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeWarehouse}
          required
          type="text"
          value={state.warehouseInput}
          variant={
            state.warehouseInput.trim().length > 0 ? "success" : "danger"
          }
          onChange={(e) => mergeState({ warehouseInput: e.target.value })}
        />
        <FormField
          name={`username-${props.tabNumber}`}
          input={TextInput}
          label={copyText.integrationSnowflakeAttributeUsername}
          required
          type="text"
          value={state.usernameInput}
          variant={state.usernameInput.trim().length > 0 ? "success" : "danger"}
          onChange={(e) => mergeState({ usernameInput: e.target.value })}
        />
        <FormField
          name={`password-${props.tabNumber}`}
          input={TextInput}
          label={copyText.integrationSnowflakeAttributePassword}
          required
          type="text"
          value={
            props.integration?.account.passwordPreview === state.passwordInput
              ? showPasswordPreview(state.passwordInput)
              : state.passwordInput
          }
          variant={state.passwordInput.trim().length > 0 ? "success" : "danger"}
          onChange={(e) => {
            mergeState({
              passwordInput: e.target.value,
            });
          }}
        />
      </Box>
    </Flex>
  );
}

function showPasswordPreview(secret: string): string {
  const splitSecret = secret.split(":");

  const count = Number(splitSecret[1]);
  const asterisks = repeat("*", count > 16 ? 16 : count);

  return splitSecret[0].concat(asterisks, splitSecret[2]);
}
