import Permission from "@ternary/api-lib/constants/permissions";
import { Role } from "@ternary/api-lib/constants/roles";
import { SYSTEM_TENANT_ID } from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type GlobalAdminGates = ReturnType<typeof globalAdminGates>;

export default function globalAdminGates(params: GateGetterParameters) {
  const globalGrant = params.authenticatedUser.grants.find(
    (grant) => grant.tenantDocID === SYSTEM_TENANT_ID
  );

  const canAccessInternalAdmin =
    globalGrant && globalGrant.roles.includes(Role.SYSTEM_ADMIN);

  const canAccessMspAdmin =
    params._permissionsIncludeAllOf([
      Permission.CREATE_TENANT,
      Permission.GRANT_SELF_TENANT_ACCESS,
      Permission.READ_GRANTS_PARTNER,
      Permission.READ_TENANTS_PARTNER,
    ]) && params.authenticatedUser.parentTenantID;

  const canCreateMspBillingRuleSet = params._permissionsIncludeAllOf([
    Permission.CREATE_MSP_BILLING_RULE_SET,
  ]);

  const canDeleteMspBillingRuleSet = params._permissionsIncludeAllOf([
    Permission.DELETE_MSP_BILLING_RULE_SET,
  ]);

  const canReadGrantsPartner = params._permissionsIncludeAllOf([
    Permission.READ_TENANTS_PARTNER,
  ]);

  const canReadGrantsSystem = params._permissionsIncludeAllOf([
    Permission.READ_TENANTS_SYSTEM,
  ]);

  const canReadMspBillingRuleSets = params._permissionsIncludeAllOf([
    Permission.READ_MSP_BILLING_RULE_SETS,
  ]);

  const canReadTenantsSystem = params._permissionsIncludeAllOf([
    Permission.READ_TENANTS_SYSTEM,
  ]);

  const canReadUsersPartner = params._permissionsIncludeAllOf([
    Permission.READ_USERS_PARTNER,
  ]);

  const canReadUsersSystem = params._permissionsIncludeAllOf([
    Permission.READ_USERS_SYSTEM,
  ]);

  const canUpdateMspBillingRuleSet = params._permissionsIncludeAllOf([
    Permission.UPDATE_MSP_BILLING_RULE_SET,
  ]);

  return {
    canAccessInternalAdmin,
    canAccessMspAdmin,
    canCreateMspBillingRuleSet,
    canDeleteMspBillingRuleSet,
    canReadGrantsPartner,
    canReadGrantsSystem,
    canReadMspBillingRuleSets,
    canReadTenantsSystem,
    canReadUsersPartner,
    canReadUsersSystem,
    canUpdateMspBillingRuleSet,
  };
}
