import { Input } from "@/types";
import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { ChangeEvent } from "react";
import copyText from "../../copyText";
import {
  BQ_FIELDS_REGEX,
  GCPLocationSelect,
  GCP_PROJECT_ID_REGEX,
  getErrorMessageIfPresent,
} from "./GCPIntegrationForm";
import { GCPIntegration } from "./types";

interface Props {
  integration?: GCPIntegration;
  datasetIDInput: Input<string>;
  isLoading: boolean;
  locationInput: Input<string>;
  projectIDInput: Input<string>;
  tableIDInput: Input<string>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function GCPCloudFormPricingExport(props: Props): JSX.Element {
  const theme = useTheme();

  let errorMessage: string | undefined;

  if (props.integration) {
    errorMessage = getErrorMessageIfPresent(
      props.integration,
      CloudCapability.PRICING_EXPORT
    );
  }

  return (
    <Box height={416} scrollable padding={theme.space_xs}>
      <FormField
        name="pricingProjectID"
        input={TextInput}
        caption={copyText.cloudHelpExportPricingProjectId}
        label={copyText.cloudAttributePricingExportProjectId}
        value={props.projectIDInput.value}
        variant={
          errorMessage === undefined &&
          GCP_PROJECT_ID_REGEX.test(props.projectIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        name="pricingDatasetID"
        input={TextInput}
        caption={copyText.cloudHelpExportPricingDatasetId}
        label={copyText.cloudAttributePricingExportDatasetId}
        value={props.datasetIDInput.value}
        variant={
          errorMessage === undefined &&
          BQ_FIELDS_REGEX.test(props.datasetIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        name="pricingTableID"
        input={TextInput}
        caption={copyText.cloudHelpExportPricingTableId}
        label={copyText.cloudAttributePricingExportTableId}
        value={props.tableIDInput.value}
        variant={
          errorMessage === undefined &&
          BQ_FIELDS_REGEX.test(props.tableIDInput.value)
            ? "success"
            : "danger"
        }
        onChange={props.onChange}
      />
      <FormField
        caption={copyText.cloudHelpExportPricingLocation}
        label={copyText.cloudAttributePricingExportLocation}
      >
        <GCPLocationSelect
          locationInput={props.locationInput}
          targetName="pricingLocation"
          onChange={props.onChange}
        />
      </FormField>
    </Box>
  );
}
