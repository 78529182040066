import {
  CloudProviderType,
  DataSource,
  Operator,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useGetChangePercent from "../../../hooks/useGetChangePercent";
import IconAwsEBS from "../../../ui-lib/icons/IconAwsEBS";
import IconAwsS3 from "../../../ui-lib/icons/IconAwsS3";
import IconAzureBB from "../../../ui-lib/icons/IconAzureBB";
import IconGCPStroage from "../../../ui-lib/icons/IconGCPStorage";
import copyText from "../copyText";
import useGetStorageSpendSummaries from "../hooks/useGetStorageSpendSummaries";
import NotConfiguredSectionBadge from "./NotConfiguredSectionBadge";
import ServiceSectionBadge from "./ServiceSectionBadge";

export default function StorageInsightsLandingPage() {
  const gatekeeper = useGatekeeper();

  //
  // Queries
  //

  const awsS3SpendSummaries = useGetStorageSpendSummaries({
    dataSource: DataSource.AWS_STORAGE_VISIBILITY,
  });

  const awsEbsSpendSummaries = useGetStorageSpendSummaries({
    dataSource: DataSource.AWS_EBS_VISIBILITY,
  });

  const { data: currentAwsMtdSpend, isLoading: isLoadingCurrentAwsS3MtdSpend } =
    awsS3SpendSummaries[0];

  const { data: lastAwsMtdSpend, isLoading: isLoadingLastAwsS3MtdSpend } =
    awsS3SpendSummaries[2];

  const awsS3MtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAwsMtdSpend?.totalCost ?? 0,
    value: currentAwsMtdSpend?.totalCost ?? 0,
  });

  const {
    data: currentAwsEBSMtdSpend,
    isLoading: isLoadingCurrentAwsEbsMtdSpend,
  } = awsEbsSpendSummaries[0];

  const { data: lastEBSAwsMtdSpend, isLoading: isLoadingLastAwsEbsMtdSpend } =
    awsEbsSpendSummaries[2];

  const awsEbsMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastEBSAwsMtdSpend?.totalCost ?? 0,
    value: currentAwsEBSMtdSpend?.totalCost ?? 0,
  });

  const azureStorageSpendSummaries = useGetStorageSpendSummaries({
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
  });

  const {
    data: currentAzureMtdSpend,
    isLoading: isLoadingCurrentAzureMtdSpend,
  } = azureStorageSpendSummaries[0];

  const { data: lastAzureMtdSpend, isLoading: isLoadingLastAzureMtdSpend } =
    azureStorageSpendSummaries[2];

  const azureMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastAzureMtdSpend?.totalCost ?? 0,
    value: currentAzureMtdSpend?.totalCost ?? 0,
  });

  const gcpStorageSpendSummaries = useGetStorageSpendSummaries({
    dataSource: DataSource.BILLING,
    queryFilters: [
      {
        name: "serviceDescription",
        operator: Operator.EQUALS,
        values: ["Cloud Storage"],
      },
    ],
  });

  const { data: currentGcpMtdSpend, isLoading: isLoadingCurrentGcpMtdSpend } =
    gcpStorageSpendSummaries[0];

  const { data: lastGcpMtdSpend, isLoading: isLoadingLastGcpMtdSpend } =
    gcpStorageSpendSummaries[2];

  const gcpMtdSpendComparison = useGetChangePercent({
    biggerIsBetter: false,
    comparison: lastGcpMtdSpend?.totalCost ?? 0,
    value: currentGcpMtdSpend?.totalCost ?? 0,
  });

  //
  // Render
  //

  const isLoadingAwsS3Spend =
    isLoadingCurrentAwsS3MtdSpend || isLoadingLastAwsS3MtdSpend;

  const isLoadingAwsEbsSpend =
    isLoadingCurrentAwsEbsMtdSpend || isLoadingLastAwsEbsMtdSpend;

  const isLoadingAzureSpend =
    isLoadingCurrentAzureMtdSpend || isLoadingLastAzureMtdSpend;

  const isLoadingGCPSpend =
    isLoadingCurrentGcpMtdSpend || isLoadingLastGcpMtdSpend;

  return (
    <Box>
      {gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">{copyText.awsServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAwsMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAwsS3Spend}
            mtdSpendComparison={awsS3MtdSpendComparison}
            path={paths._insightsStorageS3}
            serviceIcon={<IconAwsS3 size={50} />}
            serviceName={copyText.simpleStorageServiceCloudTitle}
          />
          <ServiceSectionBadge
            currentMTDSpend={currentAwsEBSMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAwsEbsSpend}
            mtdSpendComparison={awsEbsMtdSpendComparison}
            path={paths._insightsStorageEBS}
            serviceIcon={<IconAwsEBS size={50} />}
            serviceName={copyText.elasticBlockStoreCloudTitle}
          />
        </>
      )}
      {gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">{copyText.azureServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentAzureMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingAzureSpend}
            mtdSpendComparison={azureMtdSpendComparison}
            path={paths._insightsStorageBB}
            serviceIcon={<IconAzureBB size={50} />}
            serviceName={copyText.azureStorageBBTitle}
          />
        </>
      )}
      {gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">{copyText.gcpServicesListTitle}</Text>
          <ServiceSectionBadge
            currentMTDSpend={currentGcpMtdSpend?.totalCost ?? 0}
            isLoading={isLoadingGCPSpend}
            mtdSpendComparison={gcpMtdSpendComparison}
            path={paths._insightsStorageGCS}
            serviceIcon={<IconGCPStroage size={50} />}
            serviceName={copyText.gcpCloudStorageTitle}
          />
        </>
      )}
      {!gatekeeper.hasAWSIntegration && (
        <>
          <Text appearance="h3">
            {copyText.awsServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsS3 size={50} />}
            serviceName={copyText.simpleStorageServiceCloudTitle}
          />
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AWS}
            serviceIcon={<IconAwsEBS size={50} />}
            serviceName={copyText.elasticBlockStoreCloudTitle}
          />
        </>
      )}
      {!gatekeeper.hasAzureIntegration && (
        <>
          <Text appearance="h3">
            {copyText.azureServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.AZURE}
            serviceIcon={<IconAzureBB size={50} />}
            serviceName={copyText.azureStorageBBTitle}
          />
        </>
      )}
      {!gatekeeper.hasGCPIntegration && (
        <>
          <Text appearance="h3">
            {copyText.gcpServicesListTitleNotConfigured}
          </Text>
          <NotConfiguredSectionBadge
            cloudProviderType={CloudProviderType.GCP}
            serviceIcon={<IconGCPStroage size={50} />}
            serviceName={copyText.gcpCloudStorageTitle}
          />
        </>
      )}
    </Box>
  );
}
