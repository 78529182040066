import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateReallocationParameters } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { reallocationID: string } & UpdateReallocationParameters
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { reallocationID: string } & UpdateReallocationParameters
>;

export default function useUpdateReallocation(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ reallocationID, ...params }) =>
      client.updateReallocation(reallocationID, params),
    ...options,
  });
}
