import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Switch from "@/ui-lib/components/Switch";
import { useTheme } from "@emotion/react";
import { ThemeMode } from "@ternary/api-lib/constants/enums";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../copyText";

interface Props {
  isProcessing: boolean;
  themeMode: ThemeMode;
  onInteraction: (interaction: EditAppearanceSettingsForm.Interaction) => void;
}

export function EditAppearanceSettingsForm(props: Props): JSX.Element {
  const theme = useTheme();

  function handleToggleDarkMode(checked: boolean) {
    props.onInteraction({
      type: EditAppearanceSettingsForm.INTERACTION_THEME_SWITCH_CLICKED,
      themeMode: checked ? ThemeMode.DARK : ThemeMode.LIGHT,
    });
  }

  return (
    <Form height={85}>
      <Text
        fontSize={theme.h3_fontSize}
        fontWeight={theme.h3_fontWeight}
        marginVertical={theme.space_md}
        color={theme.text_color}
      >
        {copyText.subTitleAppearancePreferences}
      </Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
        >
          {copyText.appearanceOption_darkMode}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          marginHorizontal={theme.space_md}
          minHeight={theme.space_lg}
          minWidth={theme.space_lg}
          position="relative"
        >
          {props.isProcessing ? (
            <LoadingSpinner />
          ) : (
            <Switch
              checked={props.themeMode === ThemeMode.DARK}
              onChange={(checked) => handleToggleDarkMode(checked)}
            />
          )}
        </Flex>
      </Flex>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditAppearanceSettingsForm {
  export const INTERACTION_THEME_SWITCH_CLICKED = `EditAppearanceSettingsForm.INTERACTION_THEME_SWITCH_CLICKED`;

  interface InteractionThemeSwitchClicked {
    type: typeof INTERACTION_THEME_SWITCH_CLICKED;
    themeMode: ThemeMode;
  }

  export type Interaction = InteractionThemeSwitchClicked;
}

export default EditAppearanceSettingsForm;
