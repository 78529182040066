import Modal from "@/ui-lib/components/Modal";
import IconExport from "@/ui-lib/icons/IconExport";
import { useTheme } from "@emotion/react";
import { faList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatNumber } from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import Grid from "../../../../ui-lib/components/Grid";
import { TableLegacy } from "../../../../ui-lib/components/Table";
import copyText from "../../copyText";
import { millisecondsToHMS } from "./SnowflakeWarehouseResourceTable";

type Tags = {
  [key: string]: string;
};

type TableData = {
  id: number;
  name: string;
  queryCount: number;
  tags: Tags[];
  totalCreditsUsed: number;
  totalRunTime: number;
};

type WarehouseCSVData = {
  id: number;
  name: string;
  queryCount: number;
  tags: string;
  totalCreditsUsed: number;
  totalRunTime: number;
};

const order = [
  "id",
  "queryCount",
  "totalRunTime",
  "computeCredits",
  "cloudServicesCredits",
  "totalCreditsUsed",
];

interface Props {
  warehouse: TableData;
  onClose: () => void;
}

export default function SnowflakeWarehouseSubTable(props: Props): JSX.Element {
  const theme = useTheme();

  const tagColumns = useMemo(
    (): Column<Tags>[] => [
      {
        accessor: "key",
        Header: "Key",
        width: 100,
      },
      {
        accessor: "value",
        Header: "Value",
        width: 100,
      },
    ],
    [props.warehouse.tags]
  );

  const warehouseInfo = Object.keys(props.warehouse).reduce(
    (accum: { label: string; value: string }[], key) => {
      if (key !== "tags" && key !== "name") {
        if (key === "totalRunTime") {
          accum.push({
            label: key,
            value: millisecondsToHMS(props.warehouse[key]),
          });
        } else if (key === "id") {
          accum.push({
            label: key,
            value: props.warehouse[key].toString(),
          });
        } else {
          accum.push({
            label: key,
            value: formatNumber(props.warehouse[key]),
          });
        }
      }

      return accum;
    },
    []
  );

  warehouseInfo.sort((a, b) => {
    return order.indexOf(a.label) - order.indexOf(b.label);
  });

  return (
    <Modal
      isOpen
      showCloseButton
      closeOnClickOutside={false}
      onClose={props.onClose}
      minWidth={900}
    >
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>
            {`Warehouse - ${props.warehouse.name}`}
          </Text>

          <Flex alignItems="center">
            <CSVLink
              data={flattenDataToCSV([{ ...props.warehouse }])}
              filename={`${props.warehouse.name}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<IconExport />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Flex justifyContent="space-between">
          <Box maxWidth={"43%"} marginLeft={theme.space_sm}>
            <Flex
              alignItems="center"
              justifyContent="center"
              fontSize={theme.h6_fontSize}
              height={"40px"}
            >
              <Text fontSize={theme.h5_fontSize} bold>
                {"Warehouse"}
              </Text>
            </Flex>

            <Grid
              gridGap={theme.space_lg}
              gridTemplateColumns="repeat(3, 1fr)"
              gridTemplateRows="repeat(2, 1fr)"
              marginBottom={theme.space_jumbo}
              maxWidth={"40%"}
            >
              {warehouseInfo.map((item) => (
                <Flex key={item.label} direction="column" alignItems="center">
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor={theme.table_header_background_color}
                    borderRadius={theme.borderRadius_2}
                    fontSize={theme.h6_fontSize}
                    height={"40px"}
                    width={"150px"}
                  >
                    <Text color={theme.text_color_inverse}>{item.label}</Text>
                  </Flex>
                  <Box marginTop={"1rem"}>
                    <Text fontSize={theme.h5_fontSize}>{item.value}</Text>
                  </Box>
                </Flex>
              ))}
            </Grid>
          </Box>

          <Box width={props.warehouse.tags.length ? 100 : 20}></Box>

          <Box marginLeft={theme.space_sm} minWidth={400} maxWidth={480}>
            <Flex
              alignItems="center"
              justifyContent="center"
              fontSize={theme.h6_fontSize}
              height={"40px"}
            >
              <Text fontSize={theme.h5_fontSize} bold>
                {"Warehouse Tags"}
              </Text>
            </Flex>

            {props.warehouse.tags.length ? (
              <TableLegacy
                columns={tagColumns}
                data={props.warehouse.tags}
                showPagination={props.warehouse.tags.length >= 5}
                initialState={{ pageSize: 5 }}
                truncateRows
              />
            ) : (
              <EmptyPlaceholder
                height={"260px"}
                icon={faList}
                loading={false}
                text={copyText.noDataPlaceholderMessage}
              />
            )}
            <Box></Box>
          </Box>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

function flattenDataToCSV(data: TableData[]): WarehouseCSVData[] {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => {
    let tagStr = "";
    datum.tags.forEach((tag) => {
      tagStr += `${tag.key} - ${tag.value} ,`;
    });

    return {
      id: datum.id,
      name: datum.name,
      queryCount: datum.queryCount,
      totalCreditsUsed: datum.totalCreditsUsed,
      totalRunTime: datum.totalRunTime,
      tags: tagStr,
    };
  });
}
