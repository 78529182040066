import { createColumnHelper } from "@tanstack/react-table";
import {
  AWSRateAccountScope,
  AWSRateOfferingClass,
  AWSRateRecommendationTerm,
  AWSRateTenancy,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { AWSRateReservedInstanceRecommendationEntity } from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import useRefFn from "../../../../hooks/useRefFn";
import copyText from "../../copyText";
import { AWSAccountLevel } from "../types";

type TableData = {
  id: string;
  availabilityZone: string | null;
  averageUtilization: number;
  ec2InstanceType: string | null;
  estimatedBreakEvenInMonths: number;
  estimatedMonthlySavingsAmount: number;
  estimatedMonthlySavingsPercentage: number;
  family: string | null;
  instanceClass: string | null;
  instanceSize: string | null;
  linkedAccountID: string | null;
  nodeType: string | null;
  normalizedUnitsToPurchase: number;
  offeringClass: AWSRateOfferingClass | null;
  platform: string | null;
  productDescription: string | null;
  recommendedNumberOfInstancesToPurchase: number;
  recurringStandardMonthlyCost: number;
  region: string | null;
  sourceRecommendation: AWSRateReservedInstanceRecommendationEntity;
  tenancy: AWSRateTenancy | null;
  totalCost: number;
  upfrontCost: number;
};

type Props = {
  buttonIcon: JSX.Element;
  isLoading: boolean;
  accountScope: AWSRateAccountScope;
  serviceType: ServiceType;
  reservedInstanceRecommendations: AWSRateReservedInstanceRecommendationEntity[];
  onSelectRecommendation: (
    rec: AWSRateReservedInstanceRecommendationEntity
  ) => void;
};

const columnHelper = createColumnHelper<TableData>();

export default function AWSReservedInstanceRecommendationTable(props: Props) {
  const data = useMemo(
    () => getTableData(props.reservedInstanceRecommendations),
    [props.reservedInstanceRecommendations]
  );

  const onSelectRecommendation = useRefFn(props.onSelectRecommendation);

  const ec2Columns = [
    ["ec2InstanceType", 100],
    ["family", 100],
    ["region", 100],
    ["tenancy", 100],
    ["platform", 100],
  ] as const;

  const openSearchColumns = [
    ["region", 100],
    ["instanceClass", 100],
    ["instanceSize", 100],
    ["tenancy", 100],
  ] as const;

  const elasticacheColumns = [
    ["family", 100],
    ["region", 100],
    ["nodeType", 100],
    ["productDescription", 100],
  ] as const;

  const redshiftColumns = [
    ["family", 100],
    ["region", 100],
    ["nodeType", 100],
  ] as const;

  const rdsColumns = [
    ["family", 100],
    ["region", 100],
  ] as const;

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: "select",
        size: 48,
        cell: ({ row }) => (
          <Button
            iconStart={props.buttonIcon}
            primary
            size="tiny"
            onClick={() =>
              onSelectRecommendation(row.original.sourceRecommendation)
            }
          />
        ),
      }),
      columnHelper.accessor("estimatedMonthlySavingsAmount", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsRITableHeader_estimatedMonthlySavingsAmount,
        meta: { align: "center" },
      }),
      columnHelper.accessor("estimatedMonthlySavingsPercentage", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsRITableHeader_estimatedMonthlySavingsPercentage,
        meta: { align: "center" },
        size: 100,
      }),
      columnHelper.accessor("upfrontCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsRITableHeader_upfrontCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("recurringStandardMonthlyCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsRITableHeader_recurringStandardMonthlyCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("totalCost", {
        cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
        header: copyText.awsRITableHeader_totalCost,
        meta: { align: "center" },
      }),
      columnHelper.accessor("averageUtilization", {
        cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
        header: copyText.awsRITableHeader_averageUtilization,
        meta: { align: "center" },
      }),
      columnHelper.accessor("recommendedNumberOfInstancesToPurchase", {
        header:
          copyText.awsRITableHeader_recommendedNumberOfInstancesToPurchase,
        meta: { align: "center" },
      }),
      columnHelper.accessor("normalizedUnitsToPurchase", {
        header: copyText.awsRITableHeader_normalizedUnitsToPurchase,
        meta: { align: "center" },
      }),
      ...(props.accountScope === AWSAccountLevel.LINKED
        ? [
            columnHelper.accessor("linkedAccountID", {
              header: copyText.awsRITableHeader_linkedAccountID,
            }),
          ]
        : []),
      ...(props.serviceType === ServiceType.EC2
        ? ec2Columns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `awsRITableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text>{getValue() || copyText.azureTableNull}</Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      ...(props.serviceType === ServiceType.OPEN_SEARCH
        ? openSearchColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `awsRITableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text>{getValue() || copyText.azureTableNull}</Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      ...(props.serviceType === ServiceType.ELASTICACHE
        ? elasticacheColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `awsRITableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text>{getValue() || copyText.azureTableNull}</Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      ...(props.serviceType === ServiceType.REDSHIFT
        ? redshiftColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `awsRITableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text>{getValue() || copyText.azureTableNull}</Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      ...(props.serviceType === ServiceType.RDS
        ? rdsColumns.map(([dimensionKey, size]) => {
            const headerKey: keyof typeof copyText = `awsRITableHeader_${dimensionKey}`;

            return columnHelper.accessor(dimensionKey, {
              cell: ({ getValue }) => (
                <Text>{getValue() || copyText.azureTableNull}</Text>
              ),
              header: copyText[headerKey],
              meta: { align: "center" },
              size,
            });
          })
        : []),
      columnHelper.accessor("estimatedBreakEvenInMonths", {
        cell: ({ getValue }) => (
          <>{(Math.ceil(getValue() * 10) / 10).toFixed(1)}</>
        ),
        header: copyText.awsRITableHeader_estimatedBreakEvenInMonths,
        meta: { align: "center" },
      }),
    ];
  }, [props.accountScope, props.serviceType]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{
        sorting: [{ id: "estimatedMonthlySavingsAmount", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

function getTableData(
  reservedInstanceRecommendations: AWSRateReservedInstanceRecommendationEntity[]
) {
  const data: TableData[] = [];

  reservedInstanceRecommendations.forEach((rec) => {
    const termMultiplyer =
      rec.term === AWSRateRecommendationTerm.ONE_YEAR ? 12 : 36;

    const estSavingsPercentage =
      rec.estimatedMonthlySavingsAmount / rec.estimatedOnDemandCost;

    data.push({
      id: rec.id,
      availabilityZone: rec.availabilityZone,
      averageUtilization: rec.averageUtilization / 100,
      ec2InstanceType: rec.ec2InstanceType,
      estimatedBreakEvenInMonths: rec.estimatedBreakEvenInMonths,
      estimatedMonthlySavingsAmount: rec.estimatedMonthlySavingsAmount,
      estimatedMonthlySavingsPercentage: estSavingsPercentage,
      family: rec.family,
      instanceClass: rec.instanceClass,
      instanceSize: rec.instanceSize,
      linkedAccountID: rec.accountID,
      nodeType: rec.nodeType,
      normalizedUnitsToPurchase: rec.normalizedUnitsToPurchase,
      offeringClass: rec.offeringClass,
      platform: rec.platform,
      productDescription: rec.productDescription,
      recommendedNumberOfInstancesToPurchase:
        rec.recommendedNumberOfInstancesToPurchase,
      recurringStandardMonthlyCost: rec.recurringStandardMonthlyCost,
      region: rec.region,
      sourceRecommendation: rec,
      tenancy: rec.tenancy,
      totalCost:
        (rec.recurringStandardMonthlyCost + rec.upfrontCost) * termMultiplyer,
      upfrontCost: rec.upfrontCost,
    });
  });

  return data;
}
