import { createStructParam } from "@/lib/use-query-params";
import Modal from "@/ui-lib/components/Modal";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import IconExport from "@/ui-lib/icons/IconAzure";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import {
  formatNumber,
  formatNumberRounded,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { numberSort, stringSort } from "../../utils";
import { GCPCloudRunService } from "../types";

type TableData = {
  cpuAllocationTime: number;
  memoryAllocationTime: number;
  peakInstanceCount: number;
  projectId: string;
  region: string;
  requestCount: number;
  revisionName: string;
  serviceName: string;
};

type Props = {
  isLoading: boolean;
  services: GCPCloudRunService[];
  onClose: () => void;
};

const sortRuleStruct = z.object({
  id: z.string(),
  desc: z.boolean(),
});

export default function GCPCloudRunServiceTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "service_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "cpuAllocationTime",
    })
  );

  const [searchText, setSearchText] = useState("");

  const tableData = useMemo(
    () => getTableData(props.services),
    [props.services]
  );

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return tableData;

    return tableData.filter((datum) => {
      const str = searchText.toLowerCase();

      const serviceName = datum.serviceName.toLowerCase();
      const revisonName = datum.revisionName.toLowerCase();

      return serviceName.includes(str) || revisonName.includes(str);
    });
  }, [tableData, searchText]);

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "serviceName",
        align: "left",
        Cell: ({ row }) => (
          <>{`${row.original.serviceName} / ${row.original.projectId}`}</>
        ),
        Header: copyText.cloudRunTableServiceHeader_serviceName,
        sortType: stringSort,
        width: 220,
      },
      {
        accessor: "revisionName",
        align: "left",
        Header: copyText.cloudRunTableServiceHeader_revisionName,
        sortType: stringSort,
        width: 220,
      },
      {
        accessor: "cpuAllocationTime",
        Cell: ({ value }) => (
          <>{`${formatNumberRounded(value)} vCPU-seconds`}</>
        ),
        Header: copyText.cloudRunTableServiceHeader_cpuAllocationTime,
        sortType: numberSort,
        width: 220,
      },
      {
        accessor: "memoryAllocationTime",
        Cell: ({ value }) => (
          <>{`${prettyBytes(value, { binary: true })}-seconds`}</>
        ),
        Header: copyText.cloudRunTableServiceHeader_memoryAllocationTime,
        sortType: numberSort,
        width: 220,
      },
      {
        accessor: "peakInstanceCount",
        align: "left",
        Header: copyText.cloudRunTableServiceHeader_peakInstanceCount,
        sortType: numberSort,
      },
      {
        accessor: "requestCount",
        align: "left",
        Cell: ({ value }) => <>{formatNumber(value)}</>,
        Header: copyText.cloudRunTableServiceHeader_requestCount,
        sortType: numberSort,
      },
    ],
    []
  );

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>
            {copyText.cloudRunTableServicesTitle}
          </Text>
          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.services.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={flattenDataToCSV(filteredData)}
              filename={`Cloud-Run-Services-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<IconExport />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <TableLegacy
          columns={columns}
          data={filteredData}
          initialState={{ sortBy: [sortRule] }}
          isLoading={props.isLoading}
          showPagination
          sortable
          onChangeSortBy={([sortRule]) => setSortRule(sortRule, "replaceIn")}
        />
      </Modal.Body>
    </Modal>
  );
}

function getTableData(services: GCPCloudRunService[]): TableData[] {
  return services.map((services) => ({
    cpuAllocationTime: services.cpuAllocationTime ?? 0,
    memoryAllocationTime: services.memoryAllocationTime ?? 0,
    peakInstanceCount: services.peakInstanceCount ?? 0,
    projectId: services.projectId ?? copyText.cloudRunTableNull,
    region: services.region ?? copyText.cloudRunTableNull,
    requestCount: services.requestCount ?? 0,
    revisionName: services.revisionName ?? copyText.cloudRunTableNull,
    serviceName: services.serviceName ?? copyText.cloudRunTableNull,
  }));
}

function flattenDataToCSV(data: TableData[]) {
  if (!data.length) {
    return [];
  }

  return data.map((datum) => ({
    projectId: datum.projectId,
    region: datum.region,
    serviceName: datum.serviceName,
    revisionName: datum.revisionName,
    cpuAllocationTime: datum.cpuAllocationTime,
    memoryAllocationTime: datum.memoryAllocationTime,
    peakInstanceCount: datum.peakInstanceCount,
    requestCount: datum.requestCount,
  }));
}
