import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { buildCubeQuery } from "../../../../api/analytics/utils";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { StorageBucketCountStruct } from "../types";

export default function usageGetStorageUsageBuckets(
  options?: UseQueryOptions<number, UError>
): UseQueryResult<number, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryFn: async () => {
      const result = await client.load(
        buildCubeQuery({
          dataSource: DataSource.STORAGE_USAGE,
          dateRange: "yesterday",
          measures: ["totalDistinctBuckets"],
        })
      );

      const validatedBuckets = validateBuckets(result[0]);

      return validatedBuckets.totalDistinctBuckets;
    },
    queryKey: ["buckets"],
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}

function validateBuckets(data) {
  const [error, datum] = validate(data, StorageBucketCountStruct);

  if (error) {
    const uError = new UError("INVALID_BUCKETS", {
      context: {
        data,
        error,
      },
    });
    throw uError;
  }

  return datum;
}
