import Modal from "@/ui-lib/components/Modal";
import { TableLegacy } from "@/ui-lib/components/Table";
import TextInput from "@/ui-lib/components/TextInput";
import IconExport from "@/ui-lib/icons/IconExport";
import { DateRange } from "@/utils/dates";
import { useTheme } from "@emotion/react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { Column } from "react-table";
import copyText from "../../copyText";
import { EBStore } from "../types";

interface Props {
  volumes: EBStore[];
  date: DateRange;
  isLoading: boolean;
  onClose: () => void;
}

type TableData = {
  lineItemUsageAccountId: string;
  maxThroughputVolumeBytes: number;
  operationsCost: number;
  storageCost: number;
  storageUsageBytes: number;
  totalCost: number;
  totalReadTime: number;
  totalWriteTime: number;
  type: string;
  volumeId: string;
  volumeReadOps: number;
  volumeWriteOps: number;
};

export default function AWSStorageEBSVolumesTable(props: Props): JSX.Element {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");

  const subTableTitle =
    props.volumes.length > 0
      ? `Volumes ${props.volumes[0].billPayerAccountId} / ${props.volumes[0].region}`
      : null;

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "volumeId",
        align: "left",
        Header: copyText.ebsSubTableVolumeGroupHeader_volumeId,
        truncate: true,
        width: 220,
      },
      {
        accessor: "lineItemUsageAccountId",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_lineItemUsageAccountId,
        truncate: true,
        width: 125,
      },
      {
        accessor: "type",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_type,
        width: 100,
      },
      {
        accessor: "storageUsageBytes",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_storageUsageBytes,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "totalReadTime",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_totalReadTime,
        Cell: ({ value }) => (value ? <>{value.toFixed(2)}</> : <>---</>),
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "totalWriteTime",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_totalWriteTime,
        Cell: ({ value }) => (value ? <>{value.toFixed(2)}</> : <>---</>),
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "volumeReadOps",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_volumeReadOps,
        Cell: ({ value }) => (value ? <>{value}</> : <>---</>),
        width: 100,
      },
      {
        accessor: "volumeWriteOps",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_volumeWriteOps,
        Cell: ({ value }) => (value ? <>{value}</> : <>---</>),
        width: 100,
      },
      {
        accessor: "maxThroughputVolumeBytes",
        align: "center",
        Header: copyText.ebsSubTableVolumeGroupHeader_maxThroughputVolumeBytes,
        Cell: ({ value }) => <>{prettyBytes(value)}</>,
        width: 100,
      },
      {
        accessor: "storageCost",
        align: "center",
        Cell: ({ value: storageCost }) => (
          <>{formatCurrency({ number: storageCost })}</>
        ),
        Header: copyText.ebsSubTableVolumeGroupHeader_storageCost,
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "operationsCost",
        align: "center",
        Cell: ({ value: operationsCost }) => (
          <>{formatCurrency({ number: operationsCost })}</>
        ),
        Header: copyText.ebsSubTableVolumeGroupHeader_operationsCost,
        sortDescFirst: false,
        width: 100,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.ebsSubTableVolumeGroupHeader_totalCost,
        sortDescFirst: false,
        width: 100,
      },
    ],
    [props.volumes]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.volumes.map((volume) => {
      return {
        lineItemUsageAccountId: volume.lineItemUsageAccountId,
        maxThroughputVolumeBytes: volume.maxThroughputVolumeBytes,
        operationsCost: volume.operationsCost,
        snapshotCost: volume.snapshotCost,
        storageCost: volume.storageCost,
        storageUsageBytes: volume.storageUsageBytes,
        totalCost: volume.totalCost,
        totalReadTime: volume.totalReadTime,
        totalWriteTime: volume.totalWriteTime,
        type: volume.type,
        volumeId: volume.volumeId,
        volumeReadOps: volume.volumeReadOps,
        volumeWriteOps: volume.volumeWriteOps,
      };
    });
    return tableData;
  }, [props.volumes]);

  const filteredData = useMemo(() => {
    if (searchText.length === 0) return data;

    return data.filter((volume) => {
      const str = searchText.toLowerCase();

      const volumeName = volume.volumeId.toLowerCase();
      const volumeType = volume.type.toLowerCase();
      const volumeLineItemID = volume.lineItemUsageAccountId.toLowerCase();

      return (
        volumeName.includes(str) ||
        volumeType.includes(str) ||
        volumeLineItemID.includes(str)
      );
    });
  }, [data, searchText]);

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} minWidth={1100}>
      <Modal.Header>
        <Flex
          justifyContent="space-between"
          marginLeft={theme.space_sm}
          width="100%"
        >
          <Text fontSize={theme.h4_fontSize}>{subTableTitle}</Text>
          <Flex alignItems="center">
            <Box width={250} marginRight={theme.space_lg}>
              <TextInput
                disabled={!props.volumes.length}
                iconEnd={
                  <Icon color={theme.text_color_secondary} icon={faSearch} />
                }
                placeholder={copyText.searchInputPlaceholder}
                size="medium"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Box>

            <CSVLink
              data={getCSVData(filteredData).rows}
              headers={getCSVData(filteredData).headers}
              filename={`${subTableTitle}-${formatDate(
                new Date(),
                "MM-dd-yyyy"
              )}`}
            >
              <Button
                iconStart={<IconExport />}
                primary
                size="small"
                marginRight={theme.space_md}
              >
                {copyText.exportButtonLabel}
              </Button>
            </CSVLink>
          </Flex>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box width="100%" overflowX="auto">
          <TableLegacy
            columns={columns}
            data={filteredData}
            isLoading={props.isLoading}
            initialState={{ sortBy: [{ id: "totalCost", desc: true }] }}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

const csvAccessors = [
  "volumeId",
  "lineItemUsageAccountId",
  "volumeReadOps",
  "volumeWriteOps",
  "maxThroughputVolumeBytes",
  "operationsCost",
  "storageCost",
  "storageUsageBytes",
  "totalCost",
  "totalReadTime",
  "totalWriteTime",
  "type",
] as const;

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

function getCSVData(data: TableData[]): CSVData {
  if (!data.length) {
    return { headers: [], rows: [] };
  }

  const rows = data.map((datum) => ({
    volumeId: datum.volumeId,
    lineItemUsageAccountId: datum.lineItemUsageAccountId,
    volumeReadOps: datum.volumeReadOps,
    volumeWriteOps: datum.volumeWriteOps,
    maxThroughputVolumeBytes: datum.maxThroughputVolumeBytes,
    operationsCost: datum.operationsCost,
    storageCost: datum.storageCost,
    storageUsageBytes: datum.storageUsageBytes,
    totalCost: datum.totalCost,
    totalReadTime: datum.totalReadTime,
    totalWriteTime: datum.totalWriteTime,
    type: datum.type,
  }));

  const headers = csvAccessors.map((csvAccessor) => {
    // ensure rows has a value for each accessor
    const key: keyof (typeof rows)[number] = csvAccessor;

    // ensure copyText has a value for each accessor
    const copyTextKey: keyof typeof copyText = `ebsSubTableVolumeGroupHeader_${csvAccessor}`;
    const label = copyText[copyTextKey];

    return { key, label };
  });

  return { headers, rows };
}
