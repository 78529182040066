import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CaseGates = ReturnType<typeof caseGates>;

export default function caseGates(params: GateGetterParameters) {
  return {
    canCreateCaseComment: params.permissions.includes(
      Permission.CREATE_CASE_COMMENT
    ),
    canCreateCase: params.permissions.includes(Permission.CREATE_CASE),
    canUpdateCase: params.permissions.includes(Permission.UPDATE_CASE),
  };
}
