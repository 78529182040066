import { useTheme } from "@emotion/react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { LinkWithSearchParams } from "../../../../lib/react-router";
import Tabs from "../../../../ui-lib/components/Tabs";
import copyText from "../../copyText";
import AWSOpenSearchContainer from "./AWSOpenSearchContainer";

export default function AWSOpenSearchInsightsPage(): JSX.Element {
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "visibility"),
  });

  if (!gatekeeper.hasAWSIntegration) {
    return <Navigate to={paths._insightsDatabase} replace={true} />;
  }

  const containers = [
    {
      component: <AWSOpenSearchContainer />,
      label: copyText.visibilityTabLabel,
      value: "visibility",
    },
  ];

  return (
    <ErrorBoundary boundaryName="AWSOpenSearchInsightsPage">
      <Flex alignItems="center" marginBottom={theme.space_lg}>
        <Text
          as={LinkWithSearchParams}
          color={theme.text_color_secondary}
          marginRight={theme.space_sm}
          to={paths._insightsDatabase}
        >
          {copyText.databaseInsightsPageTitle}
        </Text>
        <Icon
          color={theme.text_color_secondary}
          icon={faChevronRight}
          size="xs"
        />
        <Text appearance="h2" marginLeft={theme.space_sm}>
          {copyText.openSearchInsightsPageTitle}
        </Text>
      </Flex>
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}
