import { ReallocationRebuildType } from "@ternary/api-lib/constants/enums";
import copyText from "./copyText";

export function getStringifiedRebuildType(
  type: ReallocationRebuildType
): string {
  switch (type) {
    case ReallocationRebuildType.ONEOFF: {
      return copyText.reallocationRebuildTypeOneoff;
    }
    case ReallocationRebuildType.SCHEDULED: {
      return copyText.reallocationRebuildTypeScheduled;
    }
  }
}

const projectBundle = [
  "ancestryIds",
  "ancestryNames",
  "parentId",
  "parentName",
  "projectId",
  "projectName",
  "projectNumber",
];

const serviceBundle = ["serviceId", "serviceDescription"];

const skuBundle = ["skuId", "skuDescription"];

export const sourceMatchKeyBundles = {
  ancestryIds: projectBundle,
  ancestryNames: projectBundle,
  parentId: projectBundle,
  parentName: projectBundle,
  projectId: projectBundle,
  projectName: projectBundle,
  projectNumber: projectBundle,
  serviceDescription: serviceBundle,
  serviceId: serviceBundle,
  skuDescription: skuBundle,
  skuId: skuBundle,
};
