import { UnitType } from "@ternary/api-lib/constants/analytics";
import { Operator } from "@ternary/api-lib/constants/enums";
import copyText from "../common.copyText";

export const ANALYTICS_QUERY_GC_TIME = 60_000 * 5; // 5 Minutes

export type MeasureWithUnit = {
  name: string;
  unit: UnitType;
};

export const operatorOptions = [
  {
    label: copyText.operatorOptionEqualsLabel,
    value: Operator.EQUALS,
  },
  {
    label: copyText.operatorOptionNotEqualsLabel,
    value: Operator.NOT_EQUALS,
  },
  {
    label: copyText.operatorOptionContainsLabel,
    value: Operator.CONTAINS,
  },
  {
    label: copyText.operatorOptionNotContainsLabel,
    value: Operator.NOT_CONTAINS,
  },
  {
    label: copyText.operatorOptionSetLabel,
    value: Operator.SET,
  },
  {
    label: copyText.operatorOptionNotSetLabel,
    value: Operator.NOT_SET,
  },
];
