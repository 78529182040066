import { Operator } from "@ternary/api-lib/constants/enums";

export type Input<T = unknown> = {
  value: T;
  hasChanged?: boolean;
  isValid: boolean;
};

export const RECOMMENDATION_KIND_ANOMALY = "anomaly";
export const RECOMMENDATION_KIND_DEFAULT = "recommendation";

export type QueryFilter = {
  name: string;
  operator: Operator;
  values: string[] | null;
};

export type SortRule = {
  id: string;
  desc: boolean;
};
