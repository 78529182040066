import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { AzureCommitmentLookbackPeriod } from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import React from "react";
import Dropdown from "../../../../ui-lib/components/Dropdown";
import copyText from "../../copyText";
import { AzureCommitmentInventoryDatum, AzureCommitmentType } from "../types";

type Props = {
  isLoading: boolean;
  lookbackPeriod: string;
  commitmentType: AzureCommitmentType | "All";
  coverageType: string;
  coverageList: AzureCommitmentInventoryDatum[];
  onInteraction: (
    interaction: AzureCommittedUseVisibilityControls.Interaction
  ) => void;
};

type Option = { label: string; value: string };

const lookbackPeriodOptions = [
  { label: "7D", value: AzureCommitmentLookbackPeriod.SEVEN_DAYS },
  { label: "14D", value: AzureCommitmentLookbackPeriod.FOURTEEN_DAYS },
  { label: "30D", value: AzureCommitmentLookbackPeriod.THIRTY_DAYS },
  { label: "60D", value: AzureCommitmentLookbackPeriod.SIXTY_DAYS },
] as const;

const commitmentTypeOptions = [
  {
    label: copyText.azureCommitmentTypeLabelAll,
    value: "All",
  },
  {
    label: copyText.azureCommitmentTypeLabelReservation,
    value: AzureCommitmentType.Reservation,
  },
  {
    label: copyText.azureCommitmentTypeLabelSavingsPlan,
    value: AzureCommitmentType.SavingsPlan,
  },
] as const;

const riCoverageTypeOptions: Option[] = [
  {
    label: copyText.azureCommitmentCoverageTypeLabel_AppService,
    value: copyText.azureCommitmentCoverageType_AppService,
  },
  {
    label: copyText.azureCommitmentCoverageTypeLabel_BlockBlob,
    value: copyText.azureCommitmentCoverageType_BlockBlob,
  },
  {
    label: copyText.azureCommitmentCoverageTypeLabel_RedisCache,
    value: copyText.azureCommitmentCoverageType_RedisCache,
  },
  {
    label: copyText.azureCommitmentCoverageTypeLabel_MySQL,
    value: copyText.azureCommitmentCoverageType_MySQL,
  },
  {
    label: copyText.azureCommitmentCoverageTypeLabel_PostgreSql,
    value: copyText.azureCommitmentCoverageType_PostgreSql,
  },
  {
    label: copyText.azureCommitmentCoverageTypeLabel_VirtualMachines,
    value: copyText.azureCommitmentCoverageType_VirtualMachines,
  },
];

const spCoverageTypeOptions: Option[] = [
  {
    label: copyText.azureCommitmentCoverageTypeLabel_Compute_Savings_Plan,
    value: copyText.azureCommitmentCoverageType_Compute_Savings_Plan,
  },
];

function AzureCommittedUseVisibilityControls(props: Props) {
  const theme = useTheme();

  const selectedCommitmentTypeOption =
    commitmentTypeOptions.find(
      (option) => option.value === props.commitmentType
    ) ?? commitmentTypeOptions[0];

  const coverageTypeOptions: Option[] = [
    {
      label: copyText.azureCommitmentTypeLabelAll,
      value: "All",
    },
    ...(props.commitmentType === AzureCommitmentType.Reservation ||
    props.commitmentType === "All"
      ? riCoverageTypeOptions
      : []),
    ...(props.commitmentType === AzureCommitmentType.SavingsPlan ||
    props.commitmentType === "All"
      ? spCoverageTypeOptions
      : []),
  ].sort((a, b) => a.label.localeCompare(b.label));

  const selectedCoverageTypeOption =
    coverageTypeOptions.find((option) => option.value === props.coverageType) ??
    coverageTypeOptions[0];

  return (
    <Box
      backgroundColor={theme.panel_backgroundColor}
      borderRadius={theme.borderRadius_1}
      paddingVertical={theme.space_sm}
    >
      <Flex paddingHorizontal={theme.space_md} justifyContent="space-between">
        <Flex>
          <Box paddingRight={theme.space_sm}>
            <Dropdown
              options={commitmentTypeOptions.map((option) => ({
                ...option,
                onClick: () =>
                  props.onInteraction({
                    type: AzureCommittedUseVisibilityControls.INTERACTION_COMMITMENT_TYPE_CLICKED,
                    commitmentType: option.value,
                  }),
              }))}
              placement="bottom-start"
              selectedOption={selectedCommitmentTypeOption}
            >
              <Button
                iconEnd={<Icon icon={faChevronDown} />}
                secondary
                size="small"
              >
                {copyText.azureFiltersType.replace(
                  "%VALUE%",
                  selectedCommitmentTypeOption.label
                )}
              </Button>
            </Dropdown>
          </Box>
          <Dropdown
            options={coverageTypeOptions.map((option) => ({
              ...option,
              onClick: () =>
                props.onInteraction({
                  type: AzureCommittedUseVisibilityControls.INTERACTION_COVERAGE_TYPE_CLICKED,
                  coverageType: option.value ?? "All",
                }),
            }))}
            placement="bottom-start"
            selectedOption={selectedCoverageTypeOption}
          >
            <Button
              iconEnd={<Icon icon={faChevronDown} />}
              secondary
              size="small"
            >
              {copyText.azureFiltersCoverageType.replace(
                "%VALUE%",
                selectedCoverageTypeOption.label
              )}
            </Button>
          </Dropdown>
        </Flex>

        <Box>
          {lookbackPeriodOptions.map((option) => (
            <Button
              key={option.value}
              marginLeft={theme.space_xs}
              primary={option.value === props.lookbackPeriod}
              secondary={option.value !== props.lookbackPeriod}
              size="small"
              onClick={() =>
                props.onInteraction({
                  type: AzureCommittedUseVisibilityControls.INTERACTION_LOOKBACK_PERIOD_CLICKED,
                  lookbackPeriod: option.value,
                })
              }
            >
              {option.label}
            </Button>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

AzureCommittedUseVisibilityControls.INTERACTION_COMMITMENT_TYPE_CLICKED =
  `AzureCommittedUseVisibilityControls.INTERACTION_COMMITMENT_TYPE_CLICKED` as const;

AzureCommittedUseVisibilityControls.INTERACTION_COVERAGE_TYPE_CLICKED =
  `AzureCommittedUseVisibilityControls.INTERACTION_COVERAGE_TYPE_CLICKED` as const;

AzureCommittedUseVisibilityControls.INTERACTION_LOOKBACK_PERIOD_CLICKED =
  `AzureCommittedUseVisibilityControls.INTERACTION_LOOKBACK_PERIOD_CLICKED` as const;

interface InteractionCoverageTypeClicked {
  type: typeof AzureCommittedUseVisibilityControls.INTERACTION_COVERAGE_TYPE_CLICKED;
  coverageType: string | "All";
}

interface InteractionCommitmentTypeClicked {
  type: typeof AzureCommittedUseVisibilityControls.INTERACTION_COMMITMENT_TYPE_CLICKED;
  commitmentType: AzureCommitmentType | "All";
}

interface InteractionChangeLookbackPeriod {
  type: typeof AzureCommittedUseVisibilityControls.INTERACTION_LOOKBACK_PERIOD_CLICKED;
  lookbackPeriod: AzureCommitmentLookbackPeriod;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AzureCommittedUseVisibilityControls {
  export type Interaction =
    | InteractionCommitmentTypeClicked
    | InteractionCoverageTypeClicked
    | InteractionChangeLookbackPeriod;
}

export default AzureCommittedUseVisibilityControls;
