import { useQuery } from "@tanstack/react-query";
import { FindAzureRateRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindAzureRateRecommendationsQuery";
import CoreAPIClientError from "../../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";

export default function useGetAzureCommittedUseRecommendations(
  tenantID: string,
  options?: UseQueryOptions<
    FindAzureRateRecommendationsQueryResult["recommendations"],
    CoreAPIClientError
  >
): UseQueryResult<
  FindAzureRateRecommendationsQueryResult["recommendations"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["azureCommittedUseRecommendations"],
    queryFn: () => client.getAzureCommittedUseRecommendations(tenantID),
    ...options,
  });
}
