import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import {
  nullable,
  number,
  validate,
} from "../../../../api/analytics/utils/Cubestruct";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CustomPricingEntity } from "../types";

type Params = {
  queryFilters: QueryFilter[];
};
export const CustomPricingStruct = {
  oneYearDiscount: nullable(number()),
  threeYearDiscount: nullable(number()),
};

export default function useGetGcpCustomPricing(
  params: Params,
  options?: UseQueryOptions<CustomPricingEntity, UError>
): UseQueryResult<CustomPricingEntity, UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["gcpCustomPricing", params],
    queryFn: async () => {
      const now = new DateHelper();

      const customPricingData = await client.load(
        buildCubeQuery({
          dataSource: DataSource.GCP_CUSTOM_PRICING,
          dateRange: [now.nMonthsAgo(36), now.date],
          measures: ["oneYearDiscount", "threeYearDiscount"],
          queryFilters: params.queryFilters,
        })
      );

      const [error, validData] = validate(
        customPricingData[0],
        CustomPricingStruct
      );

      if (error) {
        throw new UError("INVALID_GCP_CUSTOM_PRICING_DATA", {
          context: { error, result: customPricingData[0] },
        });
      }

      return {
        oneYearDiscount: validData.oneYearDiscount,
        threeYearDiscount: validData.threeYearDiscount,
      };
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
