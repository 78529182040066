import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

export default function MeterLoader() {
  const theme = useTheme();

  return (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 300 100"
      preserveAspectRatio="none"
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <rect
        x="0"
        y="0"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="200"
        height="12.5"
      />
      <rect
        x="0"
        y="17.5"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="250"
        height="25"
      />
      <rect
        x="0"
        y="60"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="250"
        height="20"
      />
      <rect
        x="0"
        y="85"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="200"
        height="12.5"
      />
    </ContentLoader>
  );
}
