import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import {
  AWSDatabaseElastiCacheGroup,
  awsDatabaseElastiCacheDimensions,
  awsDatabaseElastiCacheMeasures,
} from "../types";

export interface Params {
  dateRange: Date[];
  filters?: QueryFilter[];
}

export default function useGetAWSDatabaseElastiCacheGroups(
  params: Params,
  options?: UseQueryOptions<AWSDatabaseElastiCacheGroup[], UError>
): UseQueryResult<AWSDatabaseElastiCacheGroup[], UError> {
  const client = useAnalyticsApiClient();

  const dimensions = [
    awsDatabaseElastiCacheDimensions.payerAccountId,
    awsDatabaseElastiCacheDimensions.usageAccountId,
    awsDatabaseElastiCacheDimensions.cacheEngine,
    awsDatabaseElastiCacheDimensions.clusterId,
    awsDatabaseElastiCacheDimensions.instanceType,
    awsDatabaseElastiCacheDimensions.instanceMemory,
    awsDatabaseElastiCacheDimensions.instanceVcpu,
    awsDatabaseElastiCacheDimensions.region,
  ];

  const measures = [
    awsDatabaseElastiCacheMeasures.cost,
    awsDatabaseElastiCacheMeasures.backupStorageCost,
    awsDatabaseElastiCacheMeasures.nodeCost,
    awsDatabaseElastiCacheMeasures.backupStorageUsage,
    awsDatabaseElastiCacheMeasures.nodeUsage,
  ];

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AWS_DATABASE_ELASTICACHE,
    dateRange: params.dateRange,
    dimensions,
    measures,
    preAggFilters: params.filters,
  });

  return useQuery({
    queryKey: ["awsDatabaseElastiCacheGroups", query],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      const instanceGroups = result.response.map((datum) => {
        return {
          clusterId: datum.clusterId ?? "",
          payerAccountId: datum.payerAccountId ?? "",
          usageAccountId: datum.usageAccountId ?? "",
          cacheEngine: datum.cacheEngine ?? "",
          instanceFamily: datum.instanceFamily ?? "",
          instanceType: datum.instanceType ?? "",
          instanceMemory: datum.instanceMemory ?? "",
          instanceVcpu: datum.instanceVcpu ?? "",
          instanceNetworkPerformance: datum.instanceNetworkPerformance ?? "",
          region: datum.region ?? "",

          cost: datum.cost ?? 0,
          backupStorageCost: datum.backupStorageCost ?? 0,
          nodeCost: datum.nodeCost ?? 0,
          backupStorageUsage: datum.backupStorageUsage ?? 0,
          nodeUsage: datum.nodeUsage ?? 0,
        } as AWSDatabaseElastiCacheGroup;
      });

      return instanceGroups;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
