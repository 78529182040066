import { useTheme } from "@emotion/react";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatTimestamp } from "@ternary/web-ui-lib/charts/utils";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { differenceInHours, formatDistance } from "date-fns";
import React from "react";
import copyText from "../copyText";

interface Props {
  latestRefresh: string | null;
  latestUpstream: string | null;
}

export default function DataStatusTooltip(props: Props) {
  const theme = useTheme();

  // NOTE: Refresh - by Ternary. Upstream - by cloud provider.
  // "2022-09-17T10:00:00Z"

  function getDataStatusElements(params: {
    latestRefresh: string | null;
    latestUpstream: string | null;
  }) {
    if (
      !params.latestRefresh ||
      params.latestRefresh.startsWith("0") ||
      !params.latestUpstream ||
      params.latestUpstream.startsWith("0")
    ) {
      return {
        color: theme.feedback_neutral,
        tooltipText: copyText.initialDataRefreshNotCompletedText,
      };
    }

    const now = new Date();
    const absoluteRefresh = new Date(params.latestRefresh);
    const absoluteUpstream = new Date(params.latestUpstream);
    const relativeRefresh = formatDistance(now, absoluteRefresh);
    const refreshDifference = differenceInHours(now, absoluteRefresh);
    const upstreamDifference = differenceInHours(now, absoluteUpstream);
    const upstreamRefresh = formatDistance(now, absoluteUpstream);

    let color = theme.feedback_negative;
    if (refreshDifference <= 8 && upstreamDifference <= 24) {
      color = theme.feedback_positive;
    } else if (refreshDifference <= 24 && upstreamDifference <= 72) {
      color = theme.cloud_status_warning;
    }

    const tooltipText = copyText.dataRefreshStatus
      .replace("%refresh%", relativeRefresh)
      .replace("%upstream%", upstreamRefresh)
      .replace(
        "%upstreamtimestamp%",
        formatTimestamp(params.latestUpstream, "MM/dd/yyyy hh:mm a")
      );

    return { color, tooltipText };
  }

  const { color, tooltipText } = getDataStatusElements({
    latestRefresh: props.latestRefresh,
    latestUpstream: props.latestUpstream,
  });

  return (
    <Flex marginLeft={theme.size_tiny}>
      <Tooltip content={tooltipText} width="200px">
        <Icon icon={faCloud} color={color} size="1x" />
      </Tooltip>
    </Flex>
  );
}
