import {
  Infer,
  nullable,
  number,
  string,
} from "../../../api/analytics/utils/Cubestruct";
import { NotNullable } from "../types";

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const SnowflakeDatabaseUsageBytesStruct = {
  databaseBytes: nullable(number()),
  failsafeBytes: nullable(number()),
};

/**  @deprecated Cast DA results to a type unless there's a good reason not to */
export const SnowflakeDatabaseUsageStruct = {
  accountName: nullable(string()),
  averagePerDatabaseDailyDatabaseBytes: nullable(number()),
  averagePerDatabaseDailyFailsafeBytes: nullable(number()),
  comment: nullable(string()),
  databaseID: nullable(number()),
  databaseName: nullable(string()),
  databaseOwner: nullable(string()),
  deletionTime: nullable(string()),
  lastAlteredTime: nullable(string()),
  retentionDays: nullable(number()),
};

export type SnowflakeDatabaseUsageBytesDatum = NotNullable<
  Infer<typeof SnowflakeDatabaseUsageBytesStruct>
>;

export type SnowflakeDatabaseUsageDatum = NotNullable<
  Infer<typeof SnowflakeDatabaseUsageStruct>
>;
