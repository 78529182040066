import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { UpdateMspTenantSettingsParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { settingsID: string } & UpdateMspTenantSettingsParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { settingsID: string } & UpdateMspTenantSettingsParams
>;

export default function useUpdateMspTenantSettings(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ settingsID, ...params }) =>
      client.updateMspTenantSettings(settingsID, params),
    ...options,
  });
}
