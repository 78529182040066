import ActivityTrackerImpl from "@/utils/ActivityTracker";
import React, { createContext, PropsWithChildren, useContext } from "react";

const context = createContext<ActivityTrackerImpl | undefined>(undefined);

export interface Props {
  tracker: ActivityTrackerImpl;
}

export default function ActivityTrackerProvider({
  children,
  tracker,
}: PropsWithChildren<Props>): JSX.Element {
  return <context.Provider value={tracker}>{children}</context.Provider>;
}

export function useActivityTracker(): ActivityTrackerImpl {
  const tracker = useContext(context);

  if (!tracker) {
    throw new Error(
      "useActivityTracker must be wrapped by ActivityTrackerProvider"
    );
  }

  return tracker;
}
