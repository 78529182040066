import { useTheme } from "@emotion/react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { ReactElement } from "react";
import { LinkWithSearchParams } from "../../../lib/react-router";
import Divider from "../../../ui-lib/components/Divider";
import copyText from "../copyText";

interface Props {
  currentMTDSpend: number;
  isLoading: boolean;
  mtdSpendComparison: {
    icon: IconDefinition;
    color: string;
    textValue: string;
  };
  path: string;
  serviceIcon: ReactElement;
  serviceName: string;
}

export default function ServiceSectionBadge(props: Props) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      as={LinkWithSearchParams}
      backgroundColor={theme.panel_backgroundColor}
      borderColorOnHover={theme.secondary_color_background_hover}
      borderRadius={theme.borderRadius_2}
      cursor="pointer"
      height={120}
      justifyContent="space-between"
      marginBottom={theme.space_xl}
      padding={theme.space_xl}
      to={props.path}
      width="100%"
    >
      <Flex alignItems="center">
        <Flex alignItems="center" height={75} width={75}>
          {props.serviceIcon}
        </Flex>
        <Box>
          <Text appearance="h4">{props.serviceName}</Text>
        </Box>
      </Flex>
      <Flex>
        <Box>
          <Text align="end">{copyText.meterMTDSpendComparisonTitle}</Text>
          {props.isLoading ? (
            <EmptyPlaceholder
              height={45}
              loading={props.isLoading}
              skeletonVariant="bar"
            />
          ) : (
            <Text appearance="h2">
              {formatCurrency({ number: props.currentMTDSpend })}
            </Text>
          )}
        </Box>
        <Divider direction="vertical" minHeight={50} />
        <Box>
          {props.isLoading ? (
            <EmptyPlaceholder
              height={45}
              loading={props.isLoading}
              skeletonVariant="bar"
            />
          ) : (
            <Flex alignItems="center">
              <Icon
                color={props.mtdSpendComparison.color}
                icon={props.mtdSpendComparison.icon}
                size="2x"
              />
              <Text
                appearance="h2"
                color={props.mtdSpendComparison.color}
                marginLeft={theme.space_xs}
              >
                {props.mtdSpendComparison.textValue}
              </Text>
            </Flex>
          )}
          <Text>{copyText.monthlySpendComparisonCaption}</Text>
        </Box>
      </Flex>
    </Flex>
  );
}
