import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { FindRecommendationQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type RecommendationEntity = FindRecommendationQueryResult["recommendation"];

export default function useGetRecommendationByID(
  recommendationID: string,
  options?: UseQueryOptions<RecommendationEntity, CoreAPIClientError>
): UseQueryResult<RecommendationEntity, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["recommendations", recommendationID],
    queryFn: () => client.getRecommendationByID(recommendationID),
    ...options,
  });
}
