import styled from "@emotion/styled";
import Box, { Props as BoxProps } from "@ternary/web-ui-lib/components/Box";
import React from "react";

type StyleProps = {
  deg?: number;
  colorFrom?: string;
  colorTo?: string;
};

const StyledBox = styled(Box)<StyleProps>((props) => {
  const deg = `${props.deg ?? 0}deg`;
  const transparent = "rgba(255, 255, 255, 0)";
  const colorFrom = `${props.colorFrom ?? transparent} 0%`;
  const colorTo = `${props.colorTo ?? transparent} 100%`;
  return { background: `linear-gradient(${deg}, ${colorFrom}, ${colorTo});` };
});

type Props<T> = BoxProps<T> & StyleProps;

export default function LinearGradientBox<T>(props: Props<T>): JSX.Element {
  return <StyledBox {...props}>{props.children}</StyledBox>;
}
