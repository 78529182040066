import copyText from "@/common.copyText";
import externalLinks from "@/constants/externalLinks";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import { isDataSource } from "@/utils/typeGuards";
import { useTheme } from "@emotion/react";
import { faCheck, faHourglass2 } from "@fortawesome/free-solid-svg-icons";
import { DataSource, DataStatusType } from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import useGetDataIntegrationsByTenantID from "../features/admin/hooks/useGetDataIntegrationsByTenantID";

const REFETCH_INTERVAL = 60_000; // 1 Minute

interface Props {
  isSideNavOpen: boolean;
}

export default function DataStatusIndicatorContainer(
  props: Props
): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();

  const theme = useTheme();

  //
  // State
  //

  const [isOpenStatusModal, setIsOpenStatusModal] = useState(false);

  //
  // Queries
  //

  const { data: integrations = [], isLoading: isLoadingIntegrations } =
    useGetDataIntegrationsByTenantID(authenticatedUser.tenant.id, {
      refetchInterval: REFETCH_INTERVAL,
    });

  const isAllOptimized = integrations.every((integration) =>
    Object.values(integration.dataStatus).every(
      (status) => status.performanceMode !== DataStatusType.DEFAULT
    )
  );

  return (
    <>
      {isOpenStatusModal && (
        <Modal
          closeOnClickOutside
          isOpen={true}
          showCloseButton
          onClose={() => setIsOpenStatusModal((isOpen) => !isOpen)}
        >
          <Modal.Header>
            <Flex alignItems="center">
              <Tooltip
                content={
                  isAllOptimized
                    ? copyText.dataStatusFastPathToolTip
                    : copyText.dataStatusSlowPathToolTip
                }
                width="225px"
              >
                <Icon
                  color={
                    isAllOptimized
                      ? theme.feedback_positive
                      : theme.text_color_secondary
                  }
                  icon={isAllOptimized ? faCheck : faHourglass2}
                />
              </Tooltip>
              <Text fontSize={theme.h4_fontSize} marginLeft={theme.space_sm}>
                {isAllOptimized
                  ? copyText.dataStatusOptimizedPerformance
                  : copyText.dataStatusOptimizingPerformance}
              </Text>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            {integrations.map((integration) => {
              return (
                <Box key={integration.id} marginBottom={theme.space_md}>
                  <Text
                    marginBottom={theme.space_xxs}
                    bold
                  >{`${copyText.dataStatusCloudHeaderPrefix} ${integration.name}`}</Text>
                  {Object.entries(integration.dataStatus).map(
                    ([dataSource, status], index) => {
                      if (!isDataSource(dataSource)) return;

                      const isFastPath =
                        status.performanceMode === DataStatusType.OPTIMIZED;

                      return (
                        <Flex key={index}>
                          <Tooltip
                            content={
                              isFastPath
                                ? copyText.dataStatusFastPathToolTip
                                : copyText.dataStatusSlowPathToolTip
                            }
                            width="225px"
                          >
                            <Icon
                              color={
                                isFastPath
                                  ? theme.feedback_positive
                                  : theme.text_color_secondary
                              }
                              icon={isFastPath ? faCheck : faHourglass2}
                            />
                          </Tooltip>
                          <Flex width="100%" justifyContent="space-between">
                            <Text marginLeft={theme.space_sm}>
                              {getLabelFromDataSource(dataSource)}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    }
                  )}
                </Box>
              );
            })}
            <Text marginBottom={theme.space_lg}>
              <a
                href={
                  externalLinks.zendeskBillingDataPerformanceModeDocumentation
                }
                rel="noreferrer"
                target="_blank"
              >
                {copyText.dataStatusLearnMore}
              </a>
            </Text>
          </Modal.Body>
        </Modal>
      )}
      {integrations.length > 0 && (
        <Box width="100%">
          <Tooltip
            content={
              isAllOptimized
                ? copyText.dataStatusFastPathToolTip
                : copyText.dataStatusSlowPathToolTip
            }
            width="225px"
          >
            <Button
              iconStart={
                isLoadingIntegrations ? (
                  <LoadingSpinner />
                ) : (
                  <Icon
                    color={
                      isAllOptimized
                        ? theme.feedback_positive
                        : theme.text_color_secondary
                    }
                    icon={isAllOptimized ? faCheck : faHourglass2}
                  />
                )
              }
              secondary
              size="small"
              onClick={() => setIsOpenStatusModal((isOpen) => !isOpen)}
              width="100%"
            >
              {props.isSideNavOpen
                ? isAllOptimized
                  ? copyText.dataStatusOptimizedPerformance
                  : copyText.dataStatusOptimizingPerformance
                : ""}
            </Button>
          </Tooltip>
        </Box>
      )}
    </>
  );
}

function getLabelFromDataSource(dataSource: DataSource): string {
  switch (dataSource) {
    case DataSource.BILLING: {
      return copyText.dataSource_BILLING_export_label;
    }
    default: {
      return dataSource.toString();
    }
  }
}
