import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UpdateLabelGroupingRuleParams } from "../../../api/core/types";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { labelGroupingRuleID: string } & UpdateLabelGroupingRuleParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { labelGroupingRuleID: string } & UpdateLabelGroupingRuleParams
>;

export default function useUpdateLabelGroupingRule(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: async ({ labelGroupingRuleID, ...params }) =>
      client.updateLabelGroupingRule(labelGroupingRuleID, params),
    ...options,
  });
}
