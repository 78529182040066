import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CudInventoryDatum, CudInventoryEntity } from "../types";

export default function useGetCudInventoryData(
  options?: UseQueryOptions<CudInventoryDatum[], UError>
): UseQueryResult<CudInventoryDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudInventoryData"],
    queryFn: async () => {
      const now = new DateHelper();

      const result = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_COMBINED_CUD_UTILIZATION,
          dimensions: ["instanceFamily", "region", "service"],
          measures: [
            "amountSaved",
            "hourlyCommittedAmount",
            "reservedMemoryGiB",
            "reservedSlots",
            "reservedVCPU",
            "utilizationPercent",
          ],
        })
      )) as CudInventoryEntity[];

      const data: CudInventoryDatum[] = result.map((datum) => {
        return {
          amountSaved: datum.amountSaved,
          family: datum.instanceFamily ?? null,
          hourlyCommittedAmount: datum.hourlyCommittedAmount,
          region: datum.region,
          reservedSlots: datum.reservedSlots,
          reservedMemoryGiB: (datum.reservedMemoryGiB ?? 0) * 1073741824,
          reservedVCPU: datum.reservedVCPU,
          service: datum.service ?? null,
          utilizationPercent: datum.utilizationPercent,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
