import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type DimensionPreferenceEntity = Awaited<
  ReturnType<CoreAPIClient["getDimensionPreferencesByTenantID"]>
>[0];

type Options = UseQueryOptions<DimensionPreferenceEntity[], CoreAPIClientError>;
type Result = UseQueryResult<DimensionPreferenceEntity[], CoreAPIClientError>;

export default function useGetDimensionPreferencesByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["dimensionPreferences"],
    queryFn: () => client.getDimensionPreferencesByTenantID(tenantID),
    ...options,
  });
}
