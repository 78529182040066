import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

export default function RowsLoader(props: { small?: boolean }) {
  const theme = useTheme();

  const COUNT = props.small ? 5 : 10;
  const BODY_HEIGHT = props.small ? 150 : 600;
  const ROW_GAP = props.small ? 5 : 15;
  const ROW_HEIGHT = BODY_HEIGHT / COUNT - ROW_GAP;

  return (
    <>
      {!props.small && (
        <ContentLoader
          backgroundColor={theme.loading_skeleton_bg_color}
          foregroundColor={theme.loading_skeleton_fg_color}
          speed={timing.skeletonAnimationTime}
          viewBox="0 0 1000 25"
          preserveAspectRatio="none"
          style={{
            width: "100%",
            height: `${ROW_HEIGHT * 1.2}px`,
          }}
        >
          <rect
            x="0"
            y={0}
            rx={theme.skeletonLoaderBorderRadius}
            ry={theme.skeletonLoaderBorderRadius}
            width="100%"
            height={15}
          />
        </ContentLoader>
      )}
      <ContentLoader
        backgroundColor={theme.loading_skeleton_bg_color}
        foregroundColor={theme.loading_skeleton_fg_color}
        height={BODY_HEIGHT}
        viewBox={`0 0 1000 ${BODY_HEIGHT}`}
        preserveAspectRatio="none"
        style={{
          width: "100%",
          height: `${BODY_HEIGHT - ROW_GAP * COUNT}px`,
        }}
      >
        {new Array(COUNT - 1).fill("").map((_, i) => (
          <rect
            key={i}
            x="0"
            y={i * ROW_HEIGHT + i * ROW_GAP * 1.25}
            rx={theme.skeletonLoaderBorderRadius}
            ry={theme.skeletonLoaderBorderRadius}
            width="100%"
            height={ROW_HEIGHT - ROW_GAP}
          />
        ))}
      </ContentLoader>
    </>
  );
}
