import { isAfter, isBefore } from "date-fns";
import { RawData, RawValue } from "../../analytics/types";
import { DEFAULT_X_AXIS_KEY, NOT_SHOWN_KEY } from "../charts/utils";
import { createGetGrouping, totalRawData } from "./ChartDataManager";
import { isFiscalDate } from "./dates";

type RawDataCompare = (a: RawData, b: RawData) => -1 | 0 | 1;
type Order = "asc" | "desc";
type SortRawDataParams = {
  data: RawData[];
  groupingKeys?: string[];
  order?: { xAxis?: Order; yAxis?: Order };
  xAxisKey?: string;
  yAxisKeys: string[];
};

export function sortRawData(params: SortRawDataParams): RawData[] {
  const compare = getRawDataCompare(params);

  params.data.sort(compare);

  return params.data;
}

function getRawDataCompare(params: SortRawDataParams): RawDataCompare {
  const order = {
    xAxis: params.order?.xAxis ?? "asc",
    yAxis: params.order?.yAxis ?? "asc",
  };
  const tie: RawDataCompare = () => 0;
  let xAxisCompare = tie;
  let yAxisCompare = tie;

  // X AXIS COMPARE
  if (params.xAxisKey === DEFAULT_X_AXIS_KEY) {
    xAxisCompare = compareDataByTimestamp;
  } else if (params.xAxisKey === "invoiceMonth") {
    xAxisCompare = compareDataByInvoiceMonth;
  } else if (typeof params.xAxisKey === "string") {
    const compareXAxisByYAxisTotals = createCompareXAxisValuesByYAxisTotals({
      data: params.data,
      xAxisKey: params.xAxisKey,
      yAxisKey: params.yAxisKeys,
    });

    xAxisCompare = useNextCompareIfTie(
      createOtherCompare(params.groupingKeys ?? []),
      compareXAxisByYAxisTotals
    );
  }

  // Y AXIS COMPARE
  if (!params.groupingKeys || params.groupingKeys.length === 0) {
    const comparesByYAxisValue = params.yAxisKeys.map((yAxisKey) =>
      createCompareByValueAt(yAxisKey)
    );

    yAxisCompare = useNextCompareIfTie(...comparesByYAxisValue);
  } else {
    const compareByGroupingTotals = createCompareByGroupingTotals({
      data: params.data,
      groupingKeys: params.groupingKeys,
      sumKeys: params.yAxisKeys,
    });

    yAxisCompare = useNextCompareIfTie(
      createOtherCompare(params.groupingKeys),
      compareByGroupingTotals
    );
  }

  // ASC / DESC
  if (order.xAxis === "desc") {
    xAxisCompare = invert(xAxisCompare);
  }
  if (order.yAxis === "desc") {
    yAxisCompare = invert(yAxisCompare);
  }

  // FINAL RESULT
  return useNextCompareIfTie(
    xAxisCompare,
    yAxisCompare,
    createIndexCompare(params.data) // stable sort
  );
}

export function compareRawValues(a?: RawValue, b?: RawValue) {
  const aVal = a ?? null;
  const bVal = b ?? null;

  if (aVal === null && bVal === null) return 0;
  if (aVal === null) return -1;
  if (bVal === null) return 1;

  if (aVal < bVal) return -1;
  if (aVal > bVal) return 1;

  return 0;
}

export function addRawValues(value: RawValue, add: RawValue) {
  if (typeof add !== "number") {
    return value;
  }

  if (typeof value !== "number") {
    return add;
  }

  return value + add;
}

//
// COMPARE FUNCTIONS
//

function compareDataByTimestamp(a: RawData, b: RawData) {
  if (typeof a.timestamp !== "string" || typeof b.timestamp !== "string") {
    return 0;
  }
  if (isFiscalDate(a.timestamp) && isFiscalDate(b.timestamp)) {
    return compareFiscalDates(a, b);
  }
  if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
    return -1;
  }
  if (isAfter(new Date(a.timestamp), new Date(b.timestamp))) {
    return 1;
  }
  return 0;
}

function compareDataByInvoiceMonth(a: RawData, b: RawData) {
  if (
    typeof a.invoiceMonth !== "string" ||
    typeof b.invoiceMonth !== "string"
  ) {
    return 0;
  }
  if (isBefore(new Date(a.invoiceMonth), new Date(b.invoiceMonth))) {
    return -1;
  }
  if (isAfter(new Date(a.invoiceMonth), new Date(b.invoiceMonth))) {
    return 1;
  }

  return 0;
}

function getFiscalDateNumber(fiscalTimestamp: string): number {
  if (!isFiscalDate(fiscalTimestamp)) return -1;

  const fyMatch = fiscalTimestamp.match(/fy\d+/i);
  const fy = fyMatch ? fyMatch[0].slice(2) : "0000";

  const qMatch = fiscalTimestamp.match(/q\d+/i);
  const q = qMatch ? qMatch[0].slice(1) : "0";

  const mMatch = fiscalTimestamp.match(/m\d+/i);
  const m = mMatch ? mMatch[0].slice(1).padStart(2, "0") : "00";

  const wMatch = fiscalTimestamp.match(/w\d+/i);
  const w = wMatch ? wMatch[0].slice(1).padStart(2, "0") : "00";

  return Number(fy + q + m + w);
}

export function compareFiscalDates(a: RawData, b: RawData) {
  const aDateNumber =
    typeof a.timestamp === "string" ? getFiscalDateNumber(a.timestamp) : -1;
  const bDateNumber =
    typeof b.timestamp === "string" ? getFiscalDateNumber(b.timestamp) : -1;

  if (aDateNumber < bDateNumber) return -1;
  if (aDateNumber > bDateNumber) return 1;
  return 0;
}

//
// HIGHER ORDER COMPARE FUNCTIONS
//

function useNextCompareIfTie(...compareFns: RawDataCompare[]): RawDataCompare {
  return (a, b) => {
    for (const compare of compareFns) {
      const result = compare(a, b);

      if (result !== 0) return result;
    }

    return 0;
  };
}

function invert(compare: RawDataCompare): RawDataCompare {
  return (a, b) => {
    const result = compare(a, b);
    if (result < 0) return 1;
    if (result > 0) return -1;
    return 0;
  };
}

function createCompareByGroupingTotals(params: {
  data: RawData[];
  groupingKeys: string[];
  sumKeys: string[];
}): RawDataCompare {
  const totals = totalRawData(params);
  const getGroupingKey = createGetGrouping(params.groupingKeys);
  const getTotalKey = (datum: RawData, sumKey: string) =>
    getGroupingKey(datum) + "---" + sumKey;

  const allTotalsObject: { [valueKey: string]: RawValue } = {};

  totals.forEach((totalDatum) => {
    params.sumKeys.forEach((sumKey) => {
      allTotalsObject[getTotalKey(totalDatum, sumKey)] =
        totalDatum[sumKey] ?? null;
    });
  });

  return useNextCompareIfTie(
    ...params.sumKeys.map(
      (sumKey): RawDataCompare =>
        (a, b) => {
          const aTotalKey = getTotalKey(a, sumKey);
          const bTotalKey = getTotalKey(b, sumKey);

          const aTotal = allTotalsObject[aTotalKey];
          const bTotal = allTotalsObject[bTotalKey];

          return compareRawValues(aTotal, bTotal);
        }
    )
  );
}

function createCompareByValueAt(key: string): RawDataCompare {
  return (a, b) => compareRawValues(a[key], b[key]);
}

/**
 * creates function that compares (a: RawData, b: RawData) by:
 * yAxisKeys index: `i` starts at 0
 * - get `aTotal[i]`: total of all `yAxis[i]` values for all data with the
 * same xAxisValue as `a`
 * - get `bTotal[i]`: total of all `yAxis[i]` values for all data with the
 * same xAxisValue as `b`
 * - if `aTotal[i] === bTotal[i]` start over with `i++`
 * - else return `aTotal[i] - bTotal[i]`
 */
function createCompareXAxisValuesByYAxisTotals(params: {
  data: RawData[];
  xAxisKey: string;
  yAxisKey: string | string[];
}): RawDataCompare {
  const yAxes =
    typeof params.yAxisKey === "string" ? [params.yAxisKey] : params.yAxisKey;

  const getTotalKey = (datum: RawData, yAxisKey: string) =>
    `${String(datum[params.xAxisKey])} - ${String(yAxisKey)}`;

  const totalsKeyedByXYAxis: { [xValueYKey: string]: RawValue } = {};

  params.data.forEach((datum) => {
    yAxes.forEach((yAxisKey) => {
      const totalKey = getTotalKey(datum, yAxisKey);

      const total = totalsKeyedByXYAxis[totalKey];

      totalsKeyedByXYAxis[totalKey] = addRawValues(total, datum[yAxisKey]);
    });
  });

  const compareYAxisTotalsInOrder = useNextCompareIfTie(
    ...yAxes.map(
      (yAxis): RawDataCompare =>
        (a, b) => {
          const aVal = totalsKeyedByXYAxis[getTotalKey(a, yAxis)];
          const bVal = totalsKeyedByXYAxis[getTotalKey(b, yAxis)];

          return compareRawValues(aVal, bVal);
        }
    )
  );

  return (a, b) => compareYAxisTotalsInOrder(a, b);
}

function createIndexCompare(data: RawData[]): RawDataCompare {
  const indexMap = new Map<RawData, number>();

  data.forEach((datum, index) => {
    indexMap.set(datum, index);
  });

  return (a, b) => {
    const aIndex = indexMap.get(a) ?? 0;
    const bIndex = indexMap.get(b) ?? 0;

    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
  };
}

function createOtherCompare(groupingKeys: string[]): RawDataCompare {
  return (a, b) => {
    const aIsOther = groupingKeys.every(
      (groupingKey) => a[groupingKey] === NOT_SHOWN_KEY
    );
    const bIsOther = groupingKeys.every(
      (groupingKey) => b[groupingKey] === NOT_SHOWN_KEY
    );

    if (aIsOther !== bIsOther) {
      return aIsOther ? -1 : 1;
    }

    return 0;
  };
}
