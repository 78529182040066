import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import {
  AWSKubernetesInstanceGroupEntity,
  AWSKubernetesInstanceGroupFilters,
} from "../types";

type TableData = {
  accountID: string;
  coveredCost: number;
  cpuTotal: number;
  cpuUsed: number;
  memoryTotal: number;
  memoryUsed: number;
  name: string;
  onDemandCost: number;
  totalCost: number;
};

type Props = {
  instanceGroups: AWSKubernetesInstanceGroupEntity[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: AWSKubernetesInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function AWSKubernetesInstanceGroupTable(props: Props) {
  const theme = useTheme();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        accountID: instanceGroup.accountID,
        coveredCost: instanceGroup.coveredCost,
        cpuTotal: instanceGroup.cpuTotal,
        cpuUsed: instanceGroup.cpuTotal
          ? instanceGroup.cpuUsed / instanceGroup.cpuTotal
          : 0,
        memoryTotal: instanceGroup.memoryTotal,
        memoryUsed: instanceGroup.memoryTotal
          ? instanceGroup.memoryUsed / instanceGroup.memoryTotal
          : 0,
        name: instanceGroup.name,
        onDemandCost: instanceGroup.onDemandCost,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "viewInstances",
        NoAccessorCell: ({ row }) => (
          <Tooltip content={copyText.awsKubernetesInstanceGroupHeaderTooltip}>
            <LinkWithSearchParams
              searchParams={{
                selected_group: getSelectedGroupJSON(row.original),
              }}
            >
              <Button
                iconStart={<Icon icon={faTableList} />}
                primary
                size="tiny"
              />
            </LinkWithSearchParams>
          </Tooltip>
        ),
        disableSortBy: true,
        Header: "",
        width: 50,
      },
      {
        accessor: "name",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "name",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsKubernetesTableNull}
          </Text>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_name,
        sortType: stringSort,
        width: 120,
      },
      {
        accessor: "accountID",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "accountID",
                filterValue: value,
              })
            }
          >
            {value || copyText.awsKubernetesTableNull}
          </Text>
        ),
        Header: (
          <Tooltip
            content={
              <Box width={200}>
                <Text color={theme.text_color_inverse}>
                  {copyText.awsIAMListPermissionTooltipMessage}
                </Text>
              </Box>
            }
          >
            {copyText.awsKubernetesInstanceGroupHeader_accountID}
          </Tooltip>
        ),
        sortType: stringSort,
        width: 150,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 120,
      },
      {
        accessor: "onDemandCost",
        align: "right",
        Cell: ({ value: onDemandCost }) => (
          <>{formatCurrency({ number: onDemandCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_onDemandCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 145,
      },
      {
        accessor: "coveredCost",
        align: "right",
        Cell: ({ value: coveredCost }) => (
          <>{formatCurrency({ number: coveredCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_coveredCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 130,
      },
      {
        accessor: "cpuUsed",
        align: "right",
        Cell: ({ value: cpuUsed }) => <>{formatPercentage(cpuUsed)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_cpuUsed,
        sortDescFirst: true,
        sortType: numberSort,
        width: 135,
      },
      {
        accessor: "cpuTotal",
        align: "right",
        Cell: ({ value: cpuTotal }) => <>{formatNumber(cpuTotal)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_cpuTotal,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
      {
        accessor: "memoryUsed",
        align: "right",
        Cell: ({ value: memoryUsed }) => <>{formatPercentage(memoryUsed)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_memoryUsed,
        sortDescFirst: true,
        sortType: numberSort,
        width: 155,
      },
      {
        accessor: "memoryTotal",
        align: "right",
        Cell: ({ value: memoryTotal }) => (
          <>{prettyBytes(memoryTotal, { binary: true })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_memoryTotal,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
    ],
    [props.instanceGroups]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      name: datum.name,
      accountID: datum.accountID,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return "";
  }
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKeys: (keyof TableData)[] = [
    "accountID",
    "coveredCost",
    "cpuTotal",
    "cpuUsed",
    "memoryTotal",
    "memoryUsed",
    "name",
    "onDemandCost",
    "totalCost",
  ];

  return tableDataKeys.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};

AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof AWSKubernetesInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof AWSKubernetesInstanceGroupFilters;
  filterValue: AWSKubernetesInstanceGroupFilters[keyof AWSKubernetesInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSKubernetesInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default AWSKubernetesInstanceGroupTable;
