import { useTheme } from "@emotion/react";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import {
  formatCurrencyRounded,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import copyText from "../copyText";

type CoverageSummary = {
  sourceCostConverted: number;
  sourceCostNormalized: number;
  sourceCostUnconverted: number;
  totalSourceCost: number;
  totalTargetCost: number;
};

interface Props {
  billName: string;
  coverageSummary: CoverageSummary;
}

export default function SavingsBreakdownMeter(props: Props): JSX.Element {
  const theme = useTheme();

  const estimatedMonthlySavings =
    props.coverageSummary.totalSourceCost -
    (props.coverageSummary.sourceCostUnconverted +
      props.coverageSummary.totalTargetCost);

  const estimatedMonthlySavingsFeedbackColor =
    estimatedMonthlySavings > 0
      ? theme.feedback_positive
      : theme.feedback_negative;

  return (
    <>
      <Text color={theme.text_color_secondary} fontSize={theme.h3_fontSize}>
        {props.billName}
      </Text>
      <Text color={theme.text_color_secondary}>
        {copyText.estimatedMontlySavingsHeader}
      </Text>
      <Flex
        alignItems="center"
        justifyContent="center"
        marginBottom={theme.space_sm}
        marginTop={theme.space_lg}
      >
        <Text
          color={estimatedMonthlySavingsFeedbackColor}
          bold
          marginRight={theme.space_sm}
          fontSize={theme.h2_fontSize}
        >
          {formatCurrencyRounded({
            number: estimatedMonthlySavings,
          })}
        </Text>
        <Text
          color={estimatedMonthlySavingsFeedbackColor}
          fontSize={theme.h3_fontSize}
        >
          {`(${formatPercentage(
            estimatedMonthlySavings / props.coverageSummary.totalSourceCost
          )})`}
        </Text>
      </Flex>
      <Flex justifyContent="space-around" marginBottom={theme.space_lg}>
        <Flex alignItems="center">
          <Text color={theme.text_color_secondary} marginRight={theme.space_xs}>
            {copyText.cloudProviderAWSHeader}
          </Text>
          <Text fontSize={theme.h4_fontSize}>
            {formatCurrencyRounded({
              number: props.coverageSummary.totalSourceCost,
            })}
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Text color={theme.text_color_secondary} marginRight={theme.space_xs}>
            {copyText.cloudProviderGCPHeader}
          </Text>
          <Text
            color={estimatedMonthlySavingsFeedbackColor}
            fontSize={theme.h4_fontSize}
          >
            {formatCurrencyRounded({
              number:
                props.coverageSummary.sourceCostUnconverted +
                props.coverageSummary.totalTargetCost,
            })}
          </Text>
        </Flex>
      </Flex>
    </>
  );
}
