export type GCPKubernetesResourceEntity = {
  id: string;
  name: string;
  projectID: string;
  totalCoresCost: number;
  cpuUtilization: number;
  totalBytesCost: number;
  memoryUtilization: number;
  totalBytes: number;
  totalCores: number;
  usedBytes: number;
  usedCores: number;
  waste: number;
};

export const GCPKubernetesResourceType = {
  CLUSTER: "CLUSTER",
  NAMESPACE: "NAMESPACE",
  WORKLOAD: "WORKLOAD",
};

export type GCPKubernetesResourceType =
  (typeof GCPKubernetesResourceType)[keyof typeof GCPKubernetesResourceType];

export type GCPKubernetesUsageSummaryEntity = {
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  totalCost: number;
  usedBytes: number;
  usedCores: number;
};

export const GCPKubernetesUsageType = {
  CPU: "CPU",
  MEMORY: "MEMORY",
};

export type GCPKubernetesUsageType =
  (typeof GCPKubernetesUsageType)[keyof typeof GCPKubernetesUsageType];
