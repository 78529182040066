import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import TextInput from "@/ui-lib/components/TextInput";
import { AlertType, postAlert } from "@/utils/alerts";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo, useState } from "react";
import copyText from "../copyText";
import useCreateApiKey from "../hooks/useCreateApiKey";
import useDeleteApiKey from "../hooks/useDeleteApiKey";
import useGetApiKeys from "../hooks/useGetApiKeys";
import ApiKeyTable from "./ApiKeyTable";
import CreateApiKeyFormModal from "./CreateApiKeyFormModal";
import GeneratedApiKeyModal from "./GeneratedApiKeyModal";

export const MODAL_CREATE = "CREATE";
export const MODAL_DELETE = "DELETE";
export const MODAL_SUCCESS = "SUCCESS";

type Interaction =
  | ApiKeyTable.Interaction
  | CreateApiKeyFormModal.Interaction
  | GeneratedApiKeyModal.Interaction;

export type CreatedApiKey = {
  expirationDate: Date | null;
  name: string;
  secret: string;
};

type State = {
  createdKey: CreatedApiKey | null;
  modalKey: string;
  selectedID: string;
  searchText: string;
};

const initialState: State = {
  createdKey: null,
  modalKey: "",
  selectedID: "",
  searchText: "",
};

export default function ApiKeyManagementContainer(): JSX.Element {
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState(initialState);
  const mergeState = getMergeState(setState);

  //
  // Queries
  //

  const {
    data: apiKeys = [],
    isLoading: isLoadingApiKeys,
    refetch: refetchApiKeys,
  } = useGetApiKeys();

  //
  // Mutations
  //

  const { isPending: isCreatingApiKey, mutate: createApiKey } = useCreateApiKey(
    {
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorCreatingApiKeyMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (result, params) => {
        const createdApiKey = {
          name: params.name,
          secret: result.secret,
          expirationDate: params.expiresAt ? new Date(params.expiresAt) : null,
        };

        mergeState({
          createdKey: createdApiKey,
          modalKey: MODAL_SUCCESS,
        });

        refetchApiKeys();

        postAlert({
          message: copyText.successApiKeyCreatedMessage,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  const { isPending: isDeletingApiKey, mutate: deleteApiKey } = useDeleteApiKey(
    {
      onError: () => {
        mergeState({ modalKey: "" });
        postAlert({
          message: copyText.errorDeletingApiKeyMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: () => {
        refetchApiKeys();
        mergeState({ modalKey: "", selectedID: "" });
        postAlert({
          message: copyText.successApiKeyDeletedMessage,
          type: AlertType.SUCCESS,
        });
      },
    }
  );

  //
  // Handlers
  //

  function handleInteraction(interaction: Interaction) {
    switch (interaction.type) {
      case ApiKeyTable.INTERACTION_DELETE_BUTTON_CLICKED: {
        mergeState({
          modalKey: MODAL_DELETE,
          selectedID: interaction.keyID,
        });
        return;
      }
      case CreateApiKeyFormModal.INTERACTION_CANCEL_BUTTON_CLICKED: {
        mergeState({ modalKey: "" });
        return;
      }
      case CreateApiKeyFormModal.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createApiKey({
          name: interaction.name,
          ...(interaction.expiresOn
            ? { expiresAt: interaction.expiresOn.toISOString() }
            : {}),
        });
        return;
      }
      case GeneratedApiKeyModal.INTERACTION_CLOSE_BUTTON_CLICKED: {
        mergeState({
          createdKey: undefined,
          modalKey: "",
        });
      }
    }
  }

  function handleDeleteApiKey(Id: string): void {
    deleteApiKey({
      apiKeyID: Id,
    });
  }

  const filteredApiKeys = useMemo(() => {
    if (state.searchText.length === 0) return apiKeys;

    return apiKeys.filter((key) => {
      const str = state.searchText.toLowerCase();

      const name = key.name.toLowerCase();
      const id = key.id.toLowerCase();

      return name.includes(str) || id.includes(str);
    });
  }, [state.searchText, apiKeys]);

  //
  // Render
  //

  function renderModal(): JSX.Element | null {
    switch (state.modalKey) {
      case MODAL_CREATE: {
        return (
          <CreateApiKeyFormModal
            isProcessing={isCreatingApiKey}
            onInteraction={handleInteraction}
          />
        );
      }
      case MODAL_DELETE: {
        return (
          <ConfirmationModal
            isLoading={isDeletingApiKey}
            message={copyText.deleteApiKeyWarningText}
            title={copyText.deleteApiKeyLabel}
            variant="danger"
            onCancel={() => mergeState({ modalKey: "", selectedID: "" })}
            onConfirm={() => handleDeleteApiKey(state.selectedID)}
          />
        );
      }
      case MODAL_SUCCESS: {
        if (!state.createdKey) return null;

        return (
          <GeneratedApiKeyModal
            apiKey={state.createdKey}
            onInteraction={handleInteraction}
          />
        );
      }
    }

    return null;
  }

  return (
    <Box marginBottom={theme.size_large}>
      {renderModal()}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginBottom={theme.space_sm}
      >
        <Text
          fontSize={theme.h3_fontSize}
          fontWeight={theme.h3_fontWeight}
          marginVertical={theme.space_md}
          color={theme.text_color}
        >
          {copyText.apiKeyManagementTabHeader}
        </Text>
        <Flex alignItems="center" justifyContent="space-evenly">
          <Box marginRight={theme.space_md} width={250}>
            <TextInput
              disabled={!apiKeys.length}
              iconEnd={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchInputPlaceholder}
              size="large"
              value={state.searchText}
              onChange={(e) => mergeState({ searchText: e.target.value })}
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            primary
            onClick={() =>
              mergeState({
                modalKey: MODAL_CREATE,
              })
            }
          >
            {copyText.addNewKeyLabel}
          </Button>
        </Flex>
      </Flex>
      <ApiKeyTable
        apiKeys={filteredApiKeys}
        isLoading={isLoadingApiKeys}
        onInteraction={handleInteraction}
      />
    </Box>
  );
}
