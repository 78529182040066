import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { FindRecommendationsQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationsQuery";
import copyText from "../../../common.copyText";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { GetRecommendationsParams } from "../types";

type Options = UseQueryOptions<
  FindRecommendationsQueryResult["recommendations"],
  CoreAPIClientError
> &
  GetRecommendationsParams;

type Result = UseQueryResult<
  FindRecommendationsQueryResult["recommendations"],
  CoreAPIClientError
>;

export default function useGetRecommendationsByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const params = {
    category: options?.category,
    cloudProviderType: options?.cloudProviderType,
    serviceType: options?.serviceType,
  };

  return useQuery({
    queryKey: ["recommendations", params],
    queryFn: () => client.getRecommendationsByTenantID(tenantID, params),
    enabled: gatekeeper.canListRecommendations,
    meta: { errorMessage: copyText.errorLoadingRecommendationsMessage },
    ...options,
  });
}
