import styled from "@emotion/styled";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Link from "@ternary/web-ui-lib/components/Link";
import React from "react";
import copyText from "../constants/copyText";
import paths from "../constants/paths";

const StyledFlex = styled(Flex)`
  height: 100vh;
  width: 100vw;

  img {
    width: 10rem;
    margin-bottom: 3rem;
  }

  * {
    text-align: center;
  }
`;

export default function NotFound(): JSX.Element {
  return (
    <StyledFlex alignItems="center" direction="column" justifyContent="center">
      <img alt="ternary" src="/assets/img/logo-standard.svg" />
      <h1>404</h1>
      <div>
        <p>{copyText.notFoundHeader}</p>
        <p>
          <Link to={paths._home}>{copyText.notFoundLinkLabel}</Link>
        </p>
      </div>
    </StyledFlex>
  );
}
