import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../CoreAPIClientError";
import { useCoreAPIClient } from "../CoreAPIClientProvider";
import { UpdateRecommendationsParameters } from "../types";

type Options = UseMutationOptions<
  string[],
  CoreAPIClientError,
  UpdateRecommendationsParameters
>;

type Result = UseMutationResult<
  string[],
  CoreAPIClientError,
  UpdateRecommendationsParameters
>;

export default function useUpdateRecommendations(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.updateRecommendations(params),
    ...options,
  });
}
