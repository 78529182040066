import { CustomLabelOperator } from "@ternary/api-lib/constants/enums";
import { useMemo } from "react";
import copyText from "../copyText";

type CustomLabel = {
  id: string;
  matchers: {
    key: string;
    operator: CustomLabelOperator;
    values: string[];
  }[];
  outputs: {
    key: string;
    value: string;
  }[];
};

export function useCustomLabelsCsvData(customLabels: CustomLabel[]) {
  return useMemo(() => {
    const arrayOfLen = (n: number) => Array.from(Array(Math.abs(n)));
    const padZeros = (n: number) => `${n}`.padStart(4, "0");

    let maxMatchValuesCount = 0;

    for (const label of customLabels) {
      if (
        label.matchers[0] &&
        label.matchers[0].values.length > maxMatchValuesCount
      )
        maxMatchValuesCount = label.matchers[0].values.length;
    }

    if (maxMatchValuesCount === 0) return [];

    const header = [
      copyText.customLabelCSVHeaderOutputKey,
      copyText.customLabelCSVHeaderOutputValue,
      copyText.customLabelCSVHeaderMatchKey,
      ...arrayOfLen(maxMatchValuesCount).map(
        (v, i) =>
          `${copyText.customLabelCSVHeaderMatchValue} ${padZeros(i + 1)}`
      ),
    ];

    const rows: string[][] = [];
    for (const label of customLabels) {
      if (label.matchers.length === 0) continue;

      for (const output of label.outputs) {
        const { key: matchKey, values: matchValues } = label.matchers[0];
        const emptyColumnCount = maxMatchValuesCount - matchValues.length;

        const newRow = [
          output.key,
          output.value,
          matchKey ?? "null",
          ...matchValues,
          ...arrayOfLen(emptyColumnCount).map(() => ""),
        ];

        rows.push(newRow);
      }
    }

    const csvData = [header, ...rows];

    return csvData;
  }, [customLabels]);
}
