import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconAzureSQL(props: IconProps) {
  return (
    <Icon {...props}>
      <svg
        id="a96792b7-ce28-4ca3-9767-4e065ef4820f"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="ef16bf9d-a8b6-4181-b6cd-66fc5203f956"
            x1="2.59"
            y1="10.16"
            x2="15.41"
            y2="10.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#005ba1" />
            <stop offset="0.07" stopColor="#0060a9" />
            <stop offset="0.36" stopColor="#0071c8" />
            <stop offset="0.52" stopColor="#0078d4" />
            <stop offset="0.64" stopColor="#0074cd" />
            <stop offset="0.82" stopColor="#006abb" />
            <stop offset="1" stopColor="#005ba1" />
          </linearGradient>
          <radialGradient
            id="bf3846c3-4d74-4743-ab9a-f334c248bd92"
            cx="9.36"
            cy="10.57"
            r="7.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f2f2f2" />
            <stop offset="0.58" stopColor="#eee" />
            <stop offset="1" stopColor="#e6e6e6" />
          </radialGradient>
        </defs>
        <title>Azure SQL Databases</title>
        <path
          d="M9,5.14c-3.54,0-6.41-1-6.41-2.32V15.18c0,1.27,2.82,2.3,6.32,2.32H9c3.54,0,6.41-1,6.41-2.32V2.82C15.41,4.11,12.54,5.14,9,5.14Z"
          fill="url(#ef16bf9d-a8b6-4181-b6cd-66fc5203f956)"
        />
        <path
          d="M15.41,2.82c0,1.29-2.87,2.32-6.41,2.32s-6.41-1-6.41-2.32S5.46.5,9,.5s6.41,1,6.41,2.32"
          fill="#e8e8e8"
        />
        <path
          d="M13.92,2.63c0,.82-2.21,1.48-4.92,1.48S4.08,3.45,4.08,2.63,6.29,1.16,9,1.16s4.92.66,4.92,1.47"
          fill="#50e6ff"
        />
        <path
          d="M9,3a11.55,11.55,0,0,0-3.89.57A11.42,11.42,0,0,0,9,4.11a11.15,11.15,0,0,0,3.89-.58A11.84,11.84,0,0,0,9,3Z"
          fill="#198ab3"
        />
        <path
          d="M12.9,11.4V8H12v4.13h2.46V11.4ZM5.76,9.73a1.83,1.83,0,0,1-.51-.31.44.44,0,0,1-.12-.32.34.34,0,0,1,.15-.3.68.68,0,0,1,.42-.12,1.62,1.62,0,0,1,1,.29V8.11a2.58,2.58,0,0,0-1-.16,1.64,1.64,0,0,0-1.09.34,1.08,1.08,0,0,0-.42.89c0,.51.32.91,1,1.21a2.88,2.88,0,0,1,.62.36.42.42,0,0,1,.15.32.38.38,0,0,1-.16.31.81.81,0,0,1-.45.11,1.66,1.66,0,0,1-1.09-.42V12a2.17,2.17,0,0,0,1.07.24,1.88,1.88,0,0,0,1.18-.33A1.08,1.08,0,0,0,6.84,11a1.05,1.05,0,0,0-.25-.7A2.42,2.42,0,0,0,5.76,9.73ZM11,11.32a2.34,2.34,0,0,0,.33-1.26A2.32,2.32,0,0,0,11,9a1.81,1.81,0,0,0-.7-.75,2,2,0,0,0-1-.26,2.11,2.11,0,0,0-1.08.27A1.86,1.86,0,0,0,7.49,9a2.46,2.46,0,0,0-.26,1.14,2.26,2.26,0,0,0,.24,1,1.76,1.76,0,0,0,.69.74,2.06,2.06,0,0,0,1,.3l.86,1h1.21L10,12.08A1.79,1.79,0,0,0,11,11.32ZM10,11.07a.94.94,0,0,1-.76.35.92.92,0,0,1-.76-.36,1.52,1.52,0,0,1-.29-1,1.53,1.53,0,0,1,.29-1,1,1,0,0,1,.78-.37.87.87,0,0,1,.75.37,1.62,1.62,0,0,1,.27,1A1.46,1.46,0,0,1,10,11.07Z"
          fill="url(#bf3846c3-4d74-4743-ab9a-f334c248bd92)"
        />
      </svg>
    </Icon>
  );
}
