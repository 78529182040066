import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import getMergeState from "@/utils/getMergeState";
import { useTheme } from "@emotion/react";
import {
  faEllipsisV,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/table-core";
import { JobStatus } from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import React, { useMemo, useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import copyText from "../copyText";

type CustomMetric = {
  id: string;
  name: string;
  createdAt: string;
  createdBy: string;
  errorMessage: string | null;
  status: JobStatus;
  updatedAt: string | null;
};

interface Props {
  isLoading: boolean;
  customMetrics: CustomMetric[];
  onClose: () => void;
  onInteraction: (interaction: CustomMetricListModal.Interaction) => void;
}

interface State {
  searchText: string;
}

type TableData = {
  id: string;
  createdBy: string;
  errorMessage: string | null;
  name: string;
  status: JobStatus;
  timeLastModified: string;
};

const initialState: State = {
  searchText: "",
};

const columnHelper = createColumnHelper<TableData>();

export function CustomMetricListModal(props: Props): JSX.Element {
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.tableHeaderName,
        size: 250,
      }),
      columnHelper.accessor("status", {
        cell: (context) => {
          return (
            <>
              {renderStatusDot(
                context.getValue(),
                context.row.original.errorMessage,
                theme
              )}
            </>
          );
        },
        header: copyText.tableHeaderStatus,
        size: 250,
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.tableHeaderCreatedBy,
        size: 250,
      }),
      columnHelper.accessor("timeLastModified", {
        cell: (context) => (
          <>{formatDate(new Date(context.getValue()), "MM/dd/yyyy hh:mm a")}</>
        ),
        header: copyText.tableHeaderTimeLastModified,
        size: 160,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const dropdownOptions = [
            {
              disabled: !gatekeeper.canUpdateCustomMetrics,
              label: copyText.actionEdit,
              onClick: () =>
                props.onInteraction({
                  type: CustomMetricListModal.INTERACTION_EDIT_CLICKED,
                  customMetricID: row.original.id,
                }),
            },
            {
              disabled: !gatekeeper.canDeleteCustomMetrics,
              label: copyText.actionDelete,
              onClick: () =>
                props.onInteraction({
                  type: CustomMetricListModal.INTERACTION_DELETE_CLICKED,
                  customMetricID: row.original.id,
                }),
            },
          ];

          return (
            <Dropdown options={dropdownOptions} placement="bottom">
              <Button
                iconStart={<Icon icon={faEllipsisV} />}
                primary
                size="tiny"
              />
            </Dropdown>
          );
        },
        size: 30,
      }),
    ],
    [props.customMetrics]
  );

  const data = useMemo(() => {
    let filteredData = props.customMetrics
      .map((customMetric) => ({
        id: customMetric.id,
        createdBy: customMetric.createdBy,
        errorMessage: customMetric.errorMessage,
        name: customMetric.name,
        status: customMetric.status,
        timeLastModified: customMetric.updatedAt ?? customMetric.createdAt,
      }))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : 0
      );

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((report) => {
        return report.name
          .toLowerCase()
          .includes(state.searchText.toLowerCase());
      });
    }
    return filteredData;
  }, [props.customMetrics, state.searchText]);

  return (
    <Modal isOpen={true} showCloseButton width={1000} onClose={props.onClose}>
      <Modal.Header>
        <Text appearance="h4">{copyText.modalTitleCustomMetrics}</Text>
      </Modal.Header>
      <Modal.Body>
        <Flex
          alignItems="center"
          justifyContent="right"
          marginBottom={theme.space_md}
          marginTop={-45}
        >
          <Box width="12rem" marginRight={theme.space_md}>
            <TextInput
              iconStart={
                <Icon color={theme.text_color_secondary} icon={faSearch} />
              }
              placeholder={copyText.searchReportsDashboardsPlaceholder}
              onChange={(event) =>
                mergeState({ searchText: event.target.value })
              }
            />
          </Box>
          <Button
            iconStart={<Icon icon={faPlus} />}
            secondary
            size="small"
            onClick={() =>
              props.onInteraction({
                type: CustomMetricListModal.INTERACTION_CREATE_CLICKED,
              })
            }
          >
            {copyText.createCustomMetricButtonLabel}
          </Button>
        </Flex>
        <Box height={500}>
          <Table
            columns={columns}
            data={data}
            initialState={{
              pagination: { pageSize: 7 },
              sorting: [{ id: "name", desc: false }],
            }}
            isLoading={props.isLoading}
            showPagination
            sortable
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}

function renderStatusDot(
  status: JobStatus,
  errorMessage: string | null,
  theme: Theme
) {
  let color;
  let tooltipText;
  let statusText;

  switch (status) {
    case JobStatus.FAILURE: {
      color = theme.feedback_negative;
      statusText = copyText.customMetricTableFailure;
      tooltipText = errorMessage;
      break;
    }
    case JobStatus.IN_PROGRESS: {
      color = theme.feedback_neutral;
      statusText = copyText.customMetricTableInProgress;
      tooltipText = copyText.customMetricTableInProgressTooltip;
      break;
    }
    case JobStatus.SUCCESS: {
      color = theme.feedback_positive;
      statusText = copyText.customMetricTableSuccess;
      tooltipText = copyText.customMetricTableSuccessTooltip;
      break;
    }
    default: {
      tooltipText = null;
    }
  }

  return (
    <Flex>
      <Tooltip content={tooltipText} width="250px">
        <Box
          padding="0.35rem"
          backgroundColor={color}
          borderRadius="50%"
          margin="0.3rem"
        />
      </Tooltip>
      <Text>{statusText}</Text>
    </Flex>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CustomMetricListModal {
  export const INTERACTION_CREATE_CLICKED = `CustomMetricListModal.INTERACTION_CREATE_CLICKED`;
  export const INTERACTION_DELETE_CLICKED = `CustomMetricListModal.INTERACTION_DELETE_CLICKED`;
  export const INTERACTION_EDIT_CLICKED = `CustomMetricListModal.INTERACTION_EDIT_CLICKED`;

  interface InteractionCreateClicked {
    type: typeof CustomMetricListModal.INTERACTION_CREATE_CLICKED;
  }

  interface InteractionDeleteClicked {
    type: typeof CustomMetricListModal.INTERACTION_DELETE_CLICKED;
    customMetricID: string;
  }

  interface InteractionEditClicked {
    type: typeof CustomMetricListModal.INTERACTION_EDIT_CLICKED;
    customMetricID: string;
  }

  export type Interaction =
    | InteractionCreateClicked
    | InteractionDeleteClicked
    | InteractionEditClicked;
}

export default CustomMetricListModal;
