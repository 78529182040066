import { useTheme } from "@emotion/react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Link from "@ternary/web-ui-lib/components/Link";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import copyText from "../../common.copyText";

interface AlertProps {
  message: string;
  shortcut?: string;
  title: string;
}

export function Alert(props: AlertProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box>
      <Text bold fontSize={theme.fontSize_base}>
        {props.title}
      </Text>
      <Text>{props.message}</Text>
      {props.shortcut && (
        <Flex justifyContent="flex-end">
          <Link color={theme.text_color} to={props.shortcut}>
            {copyText.goToResourceLinkCaption}
          </Link>
          <Box marginLeft={theme.space_xs}>
            <Icon color={theme.text_color} icon={faArrowRight} />
          </Box>
        </Flex>
      )}
    </Box>
  );
}

export default function AlertContainer() {
  const [showButton, setShowButton] = useState(false);

  const dismissAll = () => {
    toast.dismiss();
    setShowButton(false);
  };

  // On Change is the only way to tell if any toast have mounted or not.
  toast.onChange(() => {
    // Using query Selector to check if any child divs are inside parent.
    const childElements = window.document.querySelector(
      ".Toastify__toast-container"
    )?.children.length;
    if (childElements && childElements > 1) {
      setShowButton(true);
    } else if (
      // condition to check when to not show button. this isn't zero because the button becomes a child once shown.
      (showButton && childElements && childElements === 1) ||
      childElements === undefined
    ) {
      setShowButton(false);
    }
  });

  return (
    <>
      {showButton && (
        <Box
          position="fixed"
          top="1rem"
          zIndex={2000000}
          left={"calc(50% + 165px)"}
        >
          <Button size="tiny" primary onClick={dismissAll}>
            {copyText.actionDismissAll}
          </Button>
        </Box>
      )}
      <ToastContainer
        closeButton={false}
        closeOnClick
        style={{ top: "3rem", width: "500px" }}
        position="top-center"
      />
    </>
  );
}
