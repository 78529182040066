import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { customMetricID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { customMetricID: string }
>;

export default function useDeleteCustomMetric(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ customMetricID }) =>
      client.deleteCustomMetric(customMetricID),
    ...options,
  });
}
