import { useQuery } from "@tanstack/react-query";
import { FindReallocationsQueryResult } from "@ternary/api-lib/core/queries/FindReallocationsQuery";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetReallocationsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<
    FindReallocationsQueryResult["reallocations"],
    CoreAPIClientError
  >
): UseQueryResult<
  FindReallocationsQueryResult["reallocations"],
  CoreAPIClientError
> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.reallocations,
    queryFn: () => client.getReallocationsByTenantID(tenantID),
    enabled: gatekeeper.canListReallocations,
    ...options,
  });
}
