import paths from "@/constants/paths";
import useGatekeeper from "@/hooks/useGatekeeper";
import { useMatchPath } from "@/lib/react-router";
import { keyframes, useTheme } from "@emotion/react";
import {
  faGripHorizontal,
  faInfoCircle,
  faList,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  GcpCommitmentServiceType,
  ServiceType,
} from "@ternary/api-lib/constants/enums";
import { FindRecommendationQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationQuery";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Divider from "@ternary/api-lib/ui-lib/components/Divider";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Link from "@ternary/api-lib/ui-lib/components/Link";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import {
  formatCurrency,
  formatCurrencyRounded,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { sub } from "date-fns";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import useGetRecommendationsByTenantID from "../../../api/core/hooks/useGetRecommendationsByTenantID";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import { roundDate } from "../../../utils/dates";
import useGetAWSCommitmentInventory from "../../committed-use/aws/hooks/useGetAWSCommitmentInventory";
import {
  AWSCommitmentInventoryDatum,
  AWSCommittedUseType,
} from "../../committed-use/aws/types";
import useGetCudInventoryData from "../../committed-use/gcp/hooks/useGetCudInventoryData";
import { CudInventoryDatum } from "../../committed-use/gcp/types";
import copyText from "../copyText";

interface Props {
  dashboardID: string;
  index: number;
  isEditLayout?: boolean;
  onInteraction: (
    interaction: RealizedCommitmentSavingsContainer.Interaction
  ) => void;
}

const now = roundDate(new Date());

const defaultAwsCommitmentInventoryData = [];
const defaultGcpCommitmentInventoryData = [];
const defaultRecommendations = [];

export function RealizedCommitmentSavingsContainer(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const currentPath = useMatchPath();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  const showGripIcon = currentPath === paths._dashboard && props.isEditLayout;
  const showDeleteButton = currentPath === paths._dashboard;

  //
  // Queries
  //

  const {
    data: awsCommitmentInventoryData = defaultAwsCommitmentInventoryData,
    isLoading: isLoadingAwsCommitmentInventoryData,
  } = useGetAWSCommitmentInventory({
    dateRange: [sub(now, { days: 30 }), now],
  });

  const {
    data: gcpCommitmentInventoryData = defaultGcpCommitmentInventoryData,
    isLoading: isLoadingGcpCommitmentInventoryData,
  } = useGetCudInventoryData();

  const {
    data: recommendations = defaultRecommendations,
    isLoading: isLoadingRecommendations,
  } = useGetRecommendationsByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: gatekeeper.canListRecommendations,
  });

  //
  // Render
  //

  const isLoadingInventoryData =
    isLoadingAwsCommitmentInventoryData ||
    isLoadingGcpCommitmentInventoryData ||
    isLoadingRecommendations;

  const realizedSavingsAws = awsCommitmentInventoryData.reduce(
    (
      accum: { [category: string]: number },
      commitment: AWSCommitmentInventoryDatum
    ) => {
      const typeString =
        commitment.type === AWSCommittedUseType.RI
          ? `awsReservedInstance`
          : `awsSavingsPlan`;
      if (accum[typeString]) {
        accum[typeString] = accum[typeString] + commitment.realizedSavings;
      } else {
        accum[typeString] = commitment.realizedSavings;
      }

      return accum;
    },
    {}
  );

  const realizedSavingsGcp = gcpCommitmentInventoryData.reduce(
    (accum: { [category: string]: number }, commitment: CudInventoryDatum) => {
      if (isNil(commitment.service)) return accum;

      if (accum[commitment.service]) {
        accum[commitment.service] =
          accum[commitment.service] + commitment.amountSaved / 3;
      } else {
        accum[commitment.service] = commitment.amountSaved / 3;
      }

      return accum;
    },
    {}
  );

  const realizedSavingsRightsizing = recommendations.reduce(
    (
      accum: { [category: string]: number },
      rec: FindRecommendationQueryResult["recommendation"]
    ) => {
      if (!rec.applicable && rec.state === "implemented") {
        if (accum[rec.serviceType]) {
          accum[rec.serviceType] = accum[rec.serviceType] + rec.estimateValue;
        } else {
          accum[rec.serviceType] = rec.estimateValue;
        }
      }

      return accum;
    },
    {}
  );

  const realizedSavingsKeyedByCategory = {
    ...realizedSavingsAws,
    ...realizedSavingsGcp,
    ...realizedSavingsRightsizing,
  };

  const totalSavings = Object.values(realizedSavingsKeyedByCategory).reduce(
    (accum, val) => accum + val,
    0
  );

  return (
    <Box height="100%">
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        height="100%"
        paddingBottom={theme.space_md}
        paddingHorizontal={theme.space_md}
        width="100%"
      >
        {showGripIcon && (
          <Flex
            alignItems="center"
            justifyContent="center"
            paddingTop={theme.space_xxs}
          >
            <Flex marginBottom={`-${theme.space_sm}`}>
              <Icon
                color={theme.report_display_grip_color}
                draggable
                icon={faGripHorizontal}
                size="lg"
              />
              <Box marginLeft={1}>
                <Icon
                  color={theme.report_display_grip_color}
                  draggable
                  icon={faGripHorizontal}
                  size="lg"
                />
              </Box>
            </Flex>
          </Flex>
        )}
        <Box height="100%" width="100%">
          <Box paddingVertical={theme.space_xs}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box
                maxHeight={`calc(${theme.h4_fontSize} * 1.5)`}
                overflow="hidden"
              >
                <Tooltip
                  content={copyText.widgetTitleRealizedSavingsLast30Days}
                  delayHide={250}
                >
                  <Text fontSize={theme.h4_fontSize}>
                    {copyText.widgetTitleRealizedSavingsLast30Days}
                  </Text>
                </Tooltip>
              </Box>

              <Flex alignItems="center">
                <Tooltip
                  content={renderTooltip(
                    totalSavings,
                    realizedSavingsKeyedByCategory
                  )}
                  placement="bottom"
                >
                  <Flex alignItems="center">
                    <Text
                      color={theme.text_color_secondary}
                      whiteSpace="nowrap"
                      marginRight={theme.space_xs}
                    >
                      {copyText.widgetTitleRealizedSavings}
                    </Text>

                    <Icon
                      icon={faInfoCircle}
                      color={theme.text_color_secondary}
                    />
                  </Flex>
                </Tooltip>
                {showDeleteButton && (
                  <Button
                    disabled={!gatekeeper.canUpdateDashboard(props.dashboardID)}
                    iconStart={<Icon icon={faTrashAlt} />}
                    marginLeft={theme.space_xs}
                    size="small"
                    onClick={() =>
                      props.onInteraction({
                        type: RealizedCommitmentSavingsContainer.INTERACTION_DELETE_BUTTON_CLICKED,
                        index: props.index,
                      })
                    }
                  />
                )}
              </Flex>
            </Flex>
          </Box>
          <Flex
            alignItems="center"
            direction="column"
            height="85%"
            justifyContent="center"
            width="100%"
          >
            {isLoadingInventoryData ? (
              <Box maxHeight={300} maxWidth={600}>
                <EmptyPlaceholder
                  height="100%"
                  icon={faList}
                  loading={isLoadingInventoryData}
                  skeletonVariant="meter"
                  small
                  width="100%"
                />
              </Box>
            ) : (
              <>
                <Flex direction="column" alignItems="center">
                  <Flex alignItems="center">
                    <Text
                      as="div"
                      bold
                      fontSize={theme.fontSize_ui}
                      color={theme.secondary_color}
                    >
                      {`${copyText.widgetTitleRealizedSavings}`}
                    </Text>
                  </Flex>

                  <Text as="div" bold fontSize={theme.size_medium}>
                    <AnimatedText durationMS={200} delayMS={50}>
                      {formatCurrency({ number: totalSavings })}
                    </AnimatedText>
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
}

const pathMap = {
  [ServiceType.BIGQUERY]: paths._insightsBigQuery,
  [ServiceType.EBS]: paths._insightsStorageEBS,
  [ServiceType.EC2]: paths._insightsComputeEC2,
  [ServiceType.EKS]: paths._insightsKubernetesEKS,
  [ServiceType.GCE]: paths._insightsComputeGCE,
  [ServiceType.GCS]: paths._insightsStorageGCS,
  [ServiceType.GKE]: paths._insightsKubernetesGKE,
  [ServiceType.RDS]: paths._insightsDatabaseRDS,
  [ServiceType.S3]: paths._insightsStorageS3,
  [ServiceType.SQL]: paths._insightsDatabaseCloudSQL,
};

function getPathFromServiceType(serviceType: string) {
  if (
    serviceType === "awsSavingsPlan" ||
    serviceType === "awsReservedInstance"
  ) {
    return paths._commitmentDiscountAWS;
  }

  if (Object.keys(GcpCommitmentServiceType).includes(serviceType)) {
    return paths._commitmentDiscountGCP;
  }

  return pathMap[serviceType];
}

function getReadableKey(key: string): string {
  // will change this when go to remove meters from home page
  const copyTextKey = `widgetTooltip_${key}`;

  if (copyText[copyTextKey]) {
    return copyText[copyTextKey];
  } else {
    return key;
  }
}

function renderTooltip(
  potentialRecSavings: number,
  realizedSavingsKeyedByCategory: { [key: string]: number }
): JSX.Element {
  const theme = useTheme();

  const entries = Object.entries(realizedSavingsKeyedByCategory);

  return (
    <table>
      <thead>
        <th>{copyText.widgetTooltipHeaderCategory}</th>
        <th>{copyText.widgetTooltipHeaderTotal}</th>
      </thead>
      <tbody>
        {entries
          .sort(([_ignoreA, a], [_ignoreB, b]) => (a > b ? -1 : 1))
          .map(([key, value], index) => (
            <tr key={`${key}-${index}`}>
              <td align="left">
                {
                  <Link
                    to={{
                      pathname: getPathFromServiceType(key),
                    }}
                  >
                    {getReadableKey(key)}
                  </Link>
                }
              </td>
              <td align="right">{formatCurrencyRounded({ number: value })}</td>
            </tr>
          ))}
        <tr>
          <td colSpan={2}>
            <Divider color={theme.tooltip_text_color} margin={theme.space_xs} />
          </td>
        </tr>
        <tr>
          <td colSpan={1}>
            <Text
              align="right"
              color={theme.tooltip_text_color}
              fontSize={theme.fontSize_small}
            >
              {copyText.widgetTooltipTotalLabel}
            </Text>
          </td>

          <td colSpan={1}>
            <Text align="right" color={theme.tooltip_text_color}>
              {formatCurrencyRounded({
                number: potentialRecSavings,
              })}
            </Text>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

const slideIn = keyframes({
  from: {
    transform: "translateY(-10px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

type AnimatedTextProps = {
  children: string;
  delayMS?: number;
  durationMS?: number;
};

function AnimatedText(props: AnimatedTextProps) {
  const [updateID, setUpdateID] = useState(0);

  useEffect(() => {
    setUpdateID((updateID) => updateID + 1);
  }, [props.children]);

  return (
    <>
      {props.children.split("").map((char, index) => {
        const delay = (props.delayMS ?? 0) * index;

        return (
          <Box
            animation={`${slideIn} ${props.durationMS}ms ${delay}ms forwards`}
            display="inline-block"
            key={`${char}-${index}-${updateID}`}
            opacity={0}
            position="relative"
          >
            {char}
          </Box>
        );
      })}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RealizedCommitmentSavingsContainer {
  export const INTERACTION_DELETE_BUTTON_CLICKED = `RealizedCommitmentSavingsContainer.INTERACTION_DELETE_BUTTON_CLICKED`;
  interface InteractionDeleteButtonClicked {
    type: typeof RealizedCommitmentSavingsContainer.INTERACTION_DELETE_BUTTON_CLICKED;
    index: number;
  }

  export type Interaction = InteractionDeleteButtonClicked;
}

export default RealizedCommitmentSavingsContainer;
