import { useQuery } from "@tanstack/react-query";
import { FindLabelMapsQueryResult } from "@ternary/api-lib/core/queries/FindLabelMapsQuery";
import commonCopyText from "../../common.copyText";
import { UseQueryOptions, UseQueryResult } from "../../lib/react-query";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";

const REFETCH_INTERVAL = 60_000 * 5; // 5 Minutes

type Options = UseQueryOptions<
  FindLabelMapsQueryResult["labelMaps"],
  CoreAPIClientError
>;

type Result = UseQueryResult<
  FindLabelMapsQueryResult["labelMaps"],
  CoreAPIClientError
>;

export default function useGetLabelMapsByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["labelMaps"],
    queryFn: () => client.getLabelMapsByTenantID(tenantID),
    meta: { errorMessage: commonCopyText.errorLoadingLabelMapsMessage },
    refetchInterval: REFETCH_INTERVAL,
    ...options,
  });
}
