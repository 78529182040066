const queryKeys = {
  rules: ["alertRules"] as const,
  rule: (alertRuleID: string) => [...queryKeys.rules, alertRuleID] as const,
  costAlert: (costAlertID) => [...queryKeys.costAlerts, costAlertID] as const,
  costAlerts: ["allCostAlerts"] as const,
  ruleAlerts: (alertRuleID: string) =>
    [...queryKeys.costAlerts, alertRuleID] as const,
  alertRuleEvents: (alertRuleID: string) =>
    ["alertRuleEvents", alertRuleID] as const,
};

export default queryKeys;
