import { useQuery } from "@tanstack/react-query";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import useBuildDataQuery from "../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { mspRollupDimensions } from "../mspRollupSchema";

type MspDimensionValueRow = {
  billingAccountId: string;
  provider: string;
  region: string;
  serviceName: string;
};

interface Params {
  dateRange: Date[];
  parentTenantID: string;
}

type DimensionMap = {
  [mspRollupDimensions.billingAccountID]: string[];
  [mspRollupDimensions.provider]: string[];
  [mspRollupDimensions.region]: string[];
  [mspRollupDimensions.serviceName]: string[];
  [mspRollupDimensions.tenantID]: string[];
};

export default function useGetMspDimensionValues(
  params: Params,
  options?: UseQueryOptions<DimensionMap, UError>
): UseQueryResult<DimensionMap, UError> {
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const { parentTenantID, ...restParams } = params;

  const [_, query] = useBuildDataQuery({
    ...restParams,
    dataSource: DataSource.MSP_ROLLUP,
    dimensions: [
      mspRollupDimensions.billingAccountID,
      mspRollupDimensions.provider,
      mspRollupDimensions.region,
      mspRollupDimensions.serviceName,
      mspRollupDimensions.tenantID,
    ],
  });

  return useQuery({
    queryKey: ["mspDimensionValues", params],
    queryFn: async () => {
      const result = await client.loadData<MspDimensionValueRow>(
        parentTenantID,
        query
      );

      return result.response.reduce(
        (accum, datum) => {
          Object.entries(datum).forEach(([key, value]) => {
            if (accum[key].includes(value)) return;

            accum[key].push(value);
          });

          return accum;
        },
        {
          [mspRollupDimensions.billingAccountID]: [],
          [mspRollupDimensions.provider]: [],
          [mspRollupDimensions.region]: [],
          [mspRollupDimensions.serviceName]: [],
          [mspRollupDimensions.tenantID]: [],
        }
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.canViewCostReports,
    ...options,
  });
}
