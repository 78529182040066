import { formatTimestamp } from "@ternary/api-lib/ui-lib/charts/utils";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { TableLegacy } from "../../../../ui-lib/components/Table";
import copyText from "../../copyText";

type TableData = {
  accountName: string;
  averagePerDatabaseDailyDatabaseBytes: number;
  averagePerDatabaseDailyFailsafeBytes: number;
  comment: string;
  databaseID: number;
  databaseName: string;
  databaseOwner: string;
  deletionTime: string;
  lastAlteredTime: string;
  retentionDays: number;
};

interface Props {
  isLoading: boolean;
  databases: TableData[];
}

export function SnowflakeDatabaseUsageTable(props: Props): JSX.Element {
  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "accountName",
        Header: copyText.snowflakeDatabaseTableHeader_accountName,
        width: 25,
      },
      {
        accessor: "databaseName",
        Cell: ({ value }) => value.replace(/_/g, " "),
        Header: copyText.snowflakeDatabaseTableHeader_databaseName,
        width: 50,
      },
      {
        accessor: "databaseID",
        Header: copyText.snowflakeDatabaseTableHeader_databaseID,
        width: 20,
      },
      {
        accessor: "databaseOwner",
        Header: copyText.snowflakeDatabaseTableHeader_databaseOwner,
        width: 30,
      },
      {
        accessor: "lastAlteredTime",
        Header: copyText.snowflakeDatabaseTableHeader_lastAltered,
        Cell: ({ value }) => formatTimestamp(value, "MM/dd/yyyy"),
        width: 25,
      },
      {
        accessor: "deletionTime",
        Header: copyText.snowflakeDatabaseTableHeader_deletionTime,
        Cell: ({ value }) => (value.length ? value : "--"),
        width: 25,
      },
      {
        accessor: "retentionDays",
        Header: copyText.snowflakeDatabaseTableHeader_retentionTime,
        width: 25,
      },
      {
        accessor: "averagePerDatabaseDailyDatabaseBytes",
        Header: copyText.snowflakeDatabaseTableHeader_avgBytes,
        Cell: ({ value }) => prettyBytes(value),
        width: 25,
      },
      {
        accessor: "averagePerDatabaseDailyFailsafeBytes",
        Header: copyText.snowflakeDatabaseTableHeader_avgFailsafeBytes,
        Cell: ({ value }) => prettyBytes(value),
        width: 25,
      },
      {
        accessor: "comment",
        Header: copyText.snowflakeDatabaseTableHeader_comment,
        Cell: ({ value }) => {
          return (
            <Text overflowWrap="break-word">{value.length ? value : "--"}</Text>
          );
        },
        width: 25,
      },
    ],
    [props.databases]
  );

  return (
    <TableLegacy
      columns={columns}
      data={props.databases}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}
