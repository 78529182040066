import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type ReportEntity = Awaited<ReturnType<CoreAPIClient["getReportsByIDs"]>>[0];

type Options = UseQueryOptions<ReportEntity[], CoreAPIClientError>;
type Result = UseQueryResult<ReportEntity[], CoreAPIClientError>;

export function useGetReportsByIDs(
  reportIDs: string[],
  options?: Options
): Result {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: ["reports", reportIDs],
    queryFn: () => client.getReportsByIDs(reportIDs),
    enabled: gatekeeper.canListReports,
    meta: { errorMessage: copyText.errorLoadingReportsMessage },
    ...options,
  });
}

export default useGetReportsByIDs;
