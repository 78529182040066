import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import NotConfiguredPlaceholder from "../../../../components/NotConfiguredPlaceholder";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import copyText from "../../copyText";
import AWSAllocationContainer from "./AWSAllocationContainer";
import AWSCommittedUseRecommendationsContainer from "./AWSCommittedUseRecommendationsContainer";
import AWSCommittedUseVisibilityContainer from "./AWSCommittedUseVisibilityContainer";

export function AWSCommittedUsePage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "inventory"),
  });

  if (!gatekeeper.canViewCommittedUsePage) {
    return <Navigate to={paths._home} replace={true} />;
  }

  if (!gatekeeper.isAWSCommittedUseConfigured) {
    return <NotConfiguredPlaceholder />;
  }

  const containers = [
    {
      component: <AWSCommittedUseVisibilityContainer />,
      label: copyText.awsTabLabelInventory,
      value: "inventory",
    },
    ...(gatekeeper.canViewAwsRateRecs
      ? [
          {
            component: <AWSCommittedUseRecommendationsContainer />,
            label: copyText.awsTabLabelRecommendations,
            value: "optimizations",
          },
        ]
      : []),
    {
      component: <AWSAllocationContainer />,
      label: copyText.awsTabLabelAllocation,
      value: "allocation",
    },
  ];

  return (
    <ErrorBoundary boundaryName="AWSCommittedUsePage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}
