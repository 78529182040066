import { useCallback, useRef } from "react";

type AnyFunction = (...args: never[]) => unknown;

export default function useRefFn<T extends AnyFunction>(fn: T): T {
  const refFn = useRef(fn);
  refFn.current = fn;

  const callback = useCallback<AnyFunction>((...params) => {
    return refFn.current(...params);
  }, []) as T;

  return callback;
}
