import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import getCn from "../../../utils/getCn";

const COMPONENT_NAME = "BudgetRatioBar";
const cn = getCn(COMPONENT_NAME);

interface StyledDivProps {
  color: string;
  percentageInt: number;
}

const StyledDiv = styled.div<StyledDivProps>`
  display: flex;
  height: 12px;
  width: 100%;

  .${cn("container")} {
    display: flex;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.secondary_color_background};
    border-radius: 0.25rem;
  }

  .${cn("done")} {
    border-radius: 0.25rem;
    background-color: ${(props) => props.color};
    width: ${(props) => props.percentageInt}%;
    opacity: 0.6;
  }

  .${cn("text")} {
    color: ${(props) => props.color};
    margin-left: 0.5rem;
    font-weight: bold;
    min-width: 2rem;
  }
`;

interface Props {
  values: { done: number; total: number };
}

export default function BudgetRatioBar(props: Props): JSX.Element {
  const theme = useTheme();

  const percentageInt = 100 * (props.values.done / props.values.total);

  return (
    <StyledDiv
      color={theme.primary_color_background}
      percentageInt={!isNaN(Number(percentageInt)) ? percentageInt : 0}
    >
      <div className={cn("container")}>
        <div className={cn("done")}></div>
      </div>
      <div className={cn("text")}>
        {formatCurrencyRounded({
          number: props.values.done,
        })}
      </div>
    </StyledDiv>
  );
}
