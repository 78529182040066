export const azureDatabaseSQLMeasures = {
  cost: "cost",
  absoluteCredits: "absoluteCredits",
  usageAmount: "usageAmount",
  cpuPercentMin: "cpuPercentMin",
  cpuPercentMax: "cpuPercentMax",
  cpuPercentAvg: "cpuPercentAvg",
  memoryPercentMin: "memoryPercentMin",
  memoryPercentMax: "memoryPercentMax",
  memoryPercentAvg: "memoryPercentAvg",
  cores: "cores",
  coreUsage: "coreUsage",
  memoryGB: "memoryGB",
  memoryBytesUsage: "memoryBytesUsage",
  storageCapacityGB: "storageCapacityGB",
  connections: "connections",
  storagePercent: "storagePercent",
  resourceCount: "resourceCount",
} as const;

export const azureDatabaseSQLDimensions = {
  projectId: "projectId",
  resourceId: "resourceId",
  instanceName: "instanceName",
  location: "location",
  skuName: "skuName",
  skuTier: "skuTier",
  databaseEngine: "databaseEngine",
  billingMethod: "billingMethod",
  computeTier: "computeTier",
  backupStorageRedundancy: "backupStorageRedundancy",
  backupRetentionPeriodDays: "backupRetentionPeriodDays",
  highAvailabilityEnabled: "highAvailabilityEnabled",
} as const;

export type AzureDatabaseInstanceGroupEntity = {
  projectId: string;
  backupRetentionPeriodDays: number;
  connections: number;
  cores: number;
  coreUsage: number;
  cost: number;
  cpuPercentAvg: number;
  cpuPercentMax: number;
  cpuPercentMin: number;
  databaseEngine: string;
  location: string;
  memoryGB: number;
  memoryBytesUsage: number;
  memoryPercentAvg: number;
  memoryPercentMax: number;
  memoryPercentMin: number;
  resourceCount: number;
  storageCapacityGB: number;
  storagePercent: number;
  usageAmount: number;
};

export type AzureDatabaseInstanceEntity = {
  projectId: string;
  absoluteCredits: number;
  backupRetentionPeriodDays: number;
  backupStorageRedundancy: string;
  billingMethod: string;
  computeTier: string;
  connections: number;
  cores: number;
  coreUsage: number;
  cost: number;
  cpuPercentAvg: number;
  cpuPercentMax: number;
  cpuPercentMin: number;
  databaseEngine: string;
  highAvailabilityEnabled: boolean;
  instanceName: string;
  location: string;
  memoryGB: number;
  memoryBytesUsage: number;
  memoryPercentAvg: number;
  memoryPercentMax: number;
  memoryPercentMin: number;
  resourceCount: number;
  resourceId: string;
  skuName: string;
  skuTier: string;
  storageCapacityGB: number;
  storagePercent: number;
  usageAmount: number;
};

export type AzureDatabaseUsageDatum = {
  memoryBytesUsage: number;
  memoryGB: number;
};

export type AzureDatabaseInstanceGroupFilters = {
  databaseEngine: string | null;
  location: string | null;
  projectId: string | null;
};
