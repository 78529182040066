import DatePicker from "@/ui-lib/components/DatePicker";
import Divider from "@/ui-lib/components/Divider";
import Select from "@/ui-lib/components/Select";
import getCn from "@/utils/getCn";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Box from "@ternary/web-ui-lib/components/Box";
import Text from "@ternary/web-ui-lib/components/Text";
import { add, format, isAfter, isBefore, sub } from "date-fns";
import { uniq } from "lodash";
import React from "react";
import copyText from "../../copyText";
import useFiscalYearsData, {
  fiscalDateFns,
} from "../../hooks/useFiscalYearsData";
import { createUpdatedFiscalCalendar } from "../../hooks/useUpdateFiscalCalendar";
import {
  DayOfWeek,
  DayOfWeekMap,
  EditedFiscalCalendar,
  QuarterPattern,
} from "../../types";

type Props = {
  calendar: EditedFiscalCalendar | null;
  fiscalYearNumber: string;
  onInteraction: (interaction: FiscalCalendarYearForm.Interaction) => void;
};

const COMPONENT_NAME = "FiscalCalendarYearForm";
const cn = getCn(COMPONENT_NAME);

const FormWrapper = styled("div")(({ theme }) => ({
  [`& .${cn("ideal-day")}`]: {
    color: `${theme.feedback_positive} !important`,
  },

  [`& .${cn("valid-day")}`]: {
    color: `${theme.feedback_neutral} !important`,
  },

  [`& .${cn("invalid-day")}`]: {
    color: `${theme.text_color_disabled} !important`,
  },
}));

function FiscalCalendarYearForm(props: Props) {
  const theme = useTheme();

  const fiscalYearsData = useFiscalYearsData(props.calendar);
  const selectedYearData = fiscalYearsData.yearMap[props.fiscalYearNumber]
    ? fiscalYearsData.yearMap[props.fiscalYearNumber]
    : { startDate: null, endDate: null, quarterPattern: null };

  const yearDate = new Date(Number(props.fiscalYearNumber), 0);

  let everyStartDayOfWeek = uniq(
    fiscalYearsData.yearKeys.map(
      (yearNumber) => fiscalYearsData.yearMap[yearNumber].startDayOfWeek
    )
  );

  if (everyStartDayOfWeek.length === 0 && selectedYearData.endDate) {
    everyStartDayOfWeek = [
      fiscalDateFns.getDayOfWeek(
        fiscalDateFns.dayAfter(selectedYearData.endDate)
      ),
    ];
  }

  let everyEndDayOfWeek = everyStartDayOfWeek.map((startDayOfWeek) =>
    fiscalDateFns.getRelativeDayOfWeek(startDayOfWeek, -1)
  );

  if (everyEndDayOfWeek.length === 0 && selectedYearData.startDate) {
    everyEndDayOfWeek = [
      fiscalDateFns.getDayOfWeek(
        fiscalDateFns.dayBefore(selectedYearData.startDate)
      ),
    ];
  }

  const autofillQuarterPattern = fiscalYearsData.yearMap[
    fiscalYearsData.yearKeys[0]
  ]
    ? fiscalYearsData.yearMap[fiscalYearsData.yearKeys[0]].quarterPattern
    : QuarterPattern.FIVE_FOUR_FOUR;

  const prevYearNumber =
    [...fiscalYearsData.yearKeys]
      .reverse()
      .find((otherKey) => Number(otherKey) < Number(props.fiscalYearNumber)) ??
    null;

  const prevYear = prevYearNumber
    ? fiscalYearsData.yearMap[prevYearNumber]
    : null;

  const nextYearNumber =
    fiscalYearsData.yearKeys.find(
      (otherKey) => Number(otherKey) > Number(props.fiscalYearNumber)
    ) ?? null;

  const nextYear = nextYearNumber
    ? fiscalYearsData.yearMap[nextYearNumber]
    : null;

  const lockedEndDate = nextYear
    ? fiscalDateFns.stringToDate(fiscalDateFns.dayBefore(nextYear.startDate))
    : null;

  function selectStartDate(startDate: Date | null) {
    const startDateString = startDate && fiscalDateFns.dateToString(startDate);
    const endDateString =
      startDate && !selectedYearData.endDate
        ? fiscalDateFns.dateToString(add(startDate, { weeks: 51, days: 6 }))
        : selectedYearData.endDate;

    if (!startDateString || !endDateString) return;

    const updatedCalendar = createUpdatedFiscalCalendar({
      endDate: endDateString,
      fiscalYearNumber: props.fiscalYearNumber,
      prevCalendar: props.calendar,
      quarterPattern: autofillQuarterPattern,
      startDate: startDateString,
    });

    props.onInteraction({
      type: FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR,
      calendar: updatedCalendar,
    });
  }

  function selectEndDate(endDate: Date | null) {
    if (lockedEndDate) return;

    const endDateString = endDate && fiscalDateFns.dateToString(endDate);
    const startDateString =
      !selectedYearData.startDate && endDate
        ? fiscalDateFns.dateToString(sub(endDate, { weeks: 51, days: 6 }))
        : selectedYearData.startDate;

    if (!startDateString || !endDateString) return;

    const updatedCalendar = createUpdatedFiscalCalendar({
      endDate: endDateString,
      fiscalYearNumber: props.fiscalYearNumber,
      prevCalendar: props.calendar,
      quarterPattern: autofillQuarterPattern,
      startDate: startDateString,
    });

    props.onInteraction({
      type: FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR,
      calendar: updatedCalendar,
    });
  }

  function selectQuarterPattern(quarterPattern: QuarterPattern) {
    if (!selectedYearData.startDate || !selectedYearData.endDate) return;

    const updatedCalendar = createUpdatedFiscalCalendar({
      endDate: selectedYearData.endDate,
      fiscalYearNumber: props.fiscalYearNumber,
      prevCalendar: props.calendar,
      quarterPattern,
      startDate: selectedYearData.startDate,
    });

    props.onInteraction({
      type: FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR,
      calendar: updatedCalendar,
    });
  }

  const earliestValidStartDay = prevYear
    ? fiscalDateFns.add(prevYear.startDate, { weeks: 52 })
    : nextYear
      ? fiscalDateFns.sub(nextYear.startDate, { weeks: 53 })
      : selectedYearData.endDate
        ? fiscalDateFns.sub(selectedYearData.endDate, { weeks: 52, days: 6 })
        : null;

  const latestValidStartDay = nextYear
    ? fiscalDateFns.sub(nextYear.startDate, { weeks: 52 })
    : prevYear
      ? fiscalDateFns.add(prevYear.startDate, { weeks: 53 })
      : selectedYearData.endDate
        ? fiscalDateFns.sub(selectedYearData.endDate, { weeks: 51, days: 6 })
        : null;

  const earliestValidEndDay = selectedYearData.startDate
    ? fiscalDateFns.add(selectedYearData.startDate, { weeks: 51, days: 6 })
    : prevYear
      ? fiscalDateFns.add(prevYear.startDate, { weeks: 103, days: 6 })
      : null;

  const latestValidEndDay = selectedYearData.startDate
    ? fiscalDateFns.add(selectedYearData.startDate, { weeks: 52, days: 6 })
    : prevYear
      ? fiscalDateFns.add(prevYear.startDate, { weeks: 104, days: 6 })
      : null;

  function getStartDateOpenTo() {
    if (selectedYearData.startDate) {
      return fiscalDateFns.stringToDate(selectedYearData.startDate);
    }
    if (prevYear) {
      return fiscalDateFns.stringToDate(
        fiscalDateFns.dayAfter(prevYear.endDate)
      );
    }
    if (nextYear) {
      return sub(fiscalDateFns.stringToDate(nextYear.startDate), {
        weeks: 52,
      });
    }
    return yearDate;
  }

  function getEndDateOpenTo() {
    if (selectedYearData.endDate)
      return fiscalDateFns.stringToDate(selectedYearData.endDate);
    return add(getStartDateOpenTo(), { weeks: 51, days: 6 });
  }

  return (
    <FormWrapper>
      <Box marginHorizontal={theme.space_sm} marginBottom={theme.space_sm}>
        <Text
          fontSize={theme.h4_fontSize}
          fontWeight={theme.h4_fontWeight}
          align="center"
        >
          {copyText.fiscalCalendarFormSelectedYear}
        </Text>
        <Text align="center">{format(yearDate, "yyyy")}</Text>
      </Box>

      <Divider margin={0} />

      <Text
        marginTop={theme.space_sm}
        fontSize={theme.fontSize_small}
        fontWeight={theme.fontWeight_bold}
      >
        {copyText.fiscalCalendarFormLabelStartDate}
      </Text>
      <Box marginHorizontal={theme.space_sm} marginBottom={theme.space_sm}>
        <DatePicker
          dateFormat="MM/dd/yy"
          dayClassName={getDateClassName(
            earliestValidStartDay,
            latestValidStartDay,
            everyStartDayOfWeek
          )}
          disabled={!yearDate}
          onChange={selectStartDate}
          openToDate={getStartDateOpenTo()}
          popperPlacement="top"
          selected={
            selectedYearData.startDate
              ? fiscalDateFns.stringToDate(selectedYearData.startDate)
              : null
          }
        />
      </Box>

      <Text fontSize={theme.fontSize_small} fontWeight={theme.fontWeight_bold}>
        {copyText.fiscalCalendarFormLabelEndDate}
      </Text>
      <Box marginHorizontal={theme.space_sm} marginBottom={theme.space_sm}>
        <DatePicker
          dateFormat="MM/dd/yy"
          dayClassName={getDateClassName(
            earliestValidEndDay,
            latestValidEndDay,
            everyEndDayOfWeek
          )}
          disabled={!!lockedEndDate}
          onChange={selectEndDate}
          openToDate={getEndDateOpenTo()}
          popperPlacement="top"
          selected={
            lockedEndDate
              ? lockedEndDate
              : selectedYearData.endDate
                ? fiscalDateFns.stringToDate(selectedYearData.endDate)
                : null
          }
        />
      </Box>

      <Text fontSize={theme.fontSize_small} fontWeight={theme.fontWeight_bold}>
        {copyText.fiscalCalendarFormLabelQuarterPattern}
      </Text>
      <Box marginHorizontal={theme.space_sm}>
        <Select
          disabled={!selectedYearData.quarterPattern}
          options={[
            {
              value: QuarterPattern.FIVE_FOUR_FOUR,
              label: QuarterPattern.FIVE_FOUR_FOUR,
            },
            {
              value: QuarterPattern.FOUR_FIVE_FOUR,
              label: QuarterPattern.FOUR_FIVE_FOUR,
            },
            {
              value: QuarterPattern.FOUR_FOUR_FIVE,
              label: QuarterPattern.FOUR_FOUR_FIVE,
            },
          ]}
          value={
            selectedYearData.quarterPattern
              ? {
                  value: selectedYearData.quarterPattern,
                  label: selectedYearData.quarterPattern,
                }
              : {
                  value: autofillQuarterPattern,
                  label: autofillQuarterPattern,
                }
          }
          onChange={(option) => {
            if (option) {
              selectQuarterPattern(option.value);
            }
          }}
        />
      </Box>
    </FormWrapper>
  );
}

function getDateClassName(
  validRangeStart: string | null,
  validRangeEnd: string | null,
  validDaysOfWeek: DayOfWeek[]
) {
  const validRangeStartDate = validRangeStart
    ? fiscalDateFns.stringToDate(validRangeStart)
    : null;
  const validRangeEndDate = validRangeEnd
    ? fiscalDateFns.stringToDate(validRangeEnd)
    : null;

  return (date: Date) => {
    if (validDaysOfWeek.length === 0) {
      // creating first year in calendar
      return cn("valid-day");
    }

    if (!validDaysOfWeek.includes(DayOfWeekMap[date.getDay()])) {
      return cn("invalid-day");
    }

    const isInIdealWindow =
      (validRangeStartDate
        ? isAfter(date, sub(validRangeStartDate, { days: 1 }))
        : true) &&
      (validRangeEndDate
        ? isBefore(date, add(validRangeEndDate, { days: 1 }))
        : true);

    return isInIdealWindow ? cn("ideal-day") : cn("valid-day");
  };
}

FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR =
  "FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR" as const;

interface InteractionUpdateCalendar {
  type: typeof FiscalCalendarYearForm.INTERACTION_UPDATE_CALENDAR;
  calendar: EditedFiscalCalendar | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FiscalCalendarYearForm {
  export type Interaction = InteractionUpdateCalendar;
}

export default FiscalCalendarYearForm;
