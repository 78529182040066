import { useQueryClient } from "@tanstack/react-query";
import { GateKeeper } from "../api/core/useGetGatekeeper";

export default function useGatekeeper(): GateKeeper {
  const queryClient = useQueryClient();

  const gatekeeper = queryClient.getQueryData<GateKeeper>(["gatekeeper"]);

  if (!gatekeeper) {
    throw new Error("ERROR_UNEXPECTED");
  }

  return gatekeeper;
}
