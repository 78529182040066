import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import getCn from "../../utils/getCn";
import Flex from "../Flex";
import Icon from "../Icon";
import Text from "../Text";
import BarLoader from "./BarLoader";
import { CUDsLoader } from "./CUDsLoader";
import { CardsLoader } from "./CardsLoader";
import { CardsLoaderLarge } from "./CardsLoaderLarge";
import { CartesianLoader } from "./CartesianLoader";
import DataProcessingLoader from "./DataProcessingLoader";
import MeterLoader from "./MeterLoader";
import PieLoader from "./PieLoader";
import RowsLoader from "./RowsLoader";

export type skeletonVariant =
  | "bar"
  | "cards"
  | "cards_large"
  | "cartesian"
  | "cuds"
  | "data_processing"
  | "meter"
  | "pie"
  | "rows";

const COMPONENT_NAME = "EmptyPlaceholder";
const cn = getCn(COMPONENT_NAME);

interface StyledFlexProps {
  color?: string;
  small?: boolean;
  height?: number | string;
  width?: number | string;
}

const StyledFlex = styled(Flex)<StyledFlexProps>`
  height: ${(props) =>
    props.height ? props.height : props.small ? "100px" : "400px"};

  .${cn("icon")} {
    color: ${(props) => props.theme.text_color_secondary};
    font-size: ${(props) => (props.small ? "4rem" : "6rem")};
    margin-bottom: ${(props) => props.theme.space_md};
  }

  h3 {
    color: ${(props) => props.theme.text_color_secondary};
    font-size: ${(props) =>
      props.small ? props.theme.h3_fontSize : props.theme.h3_fontSize};
    max-width: 20rem;
    text-align: center;
    font-weight: ${(props) => props.theme.h3_fontWeight};
  }

  * {
    color: ${(props) => props.color};
    background-color: ${(props) => props.color};
  }
`;

interface Props {
  color?: string;
  icon?: IconDefinition;
  loading: boolean;
  height?: number | string;
  width?: number | string;
  text?: string;
  small?: boolean;
  skeletonVariant?: skeletonVariant;
  textAppearance?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

export default function EmptyPlaceholder(props: Props): JSX.Element {
  const theme = useTheme();

  return (
    <StyledFlex
      color={props.color}
      alignItems="center"
      direction="column"
      height={props.height || "100%"}
      justifyContent="center"
      small={props.small}
      width={props.width || "100%"}
    >
      {renderContent(props)}
    </StyledFlex>
  );

  function renderContent(props: Props) {
    if (props.loading) {
      switch (props.skeletonVariant) {
        case "bar":
          return <BarLoader height={props.height} />;
        case "cards":
          return <CardsLoader />;
        case "cards_large":
          return <CardsLoaderLarge />;
        case "cartesian":
          return <CartesianLoader />;
        case "cuds":
          return <CUDsLoader />;
        case "data_processing":
          return <DataProcessingLoader />;
        case "meter":
          return <MeterLoader />;
        case "pie":
          return <PieLoader />;
        case "rows":
        default:
          return <RowsLoader small={props.small} />;
      }
    } else {
      return (
        <>
          <Icon className={cn("icon")} icon={props.icon} />
          <Text
            align="center"
            appearance={props.textAppearance ?? "h3"}
            color={theme.text_color_secondary}
          >
            {props.text}
          </Text>
        </>
      );
    }
  }
}
