import { TenantType } from "@ternary/api-lib/constants/enums";
import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type MspAdminGates = ReturnType<typeof mspAdminGates>;

export default function mspAdminGates(params: GateGetterParameters) {
  const canReadTenantsPartner = params._permissionsIncludeAllOf([
    Permission.READ_TENANTS_PARTNER,
  ]);

  const canViewAwsRateRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowAwsRateRecs
      : true;

  const canViewAwsUsageRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowAwsUsageRecs
      : true;

  const canViewAzureRateRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowAzureRateRecs
      : true;

  const canViewAzureUsageRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowAzureUsageRecs
      : true;

  const canViewGcpRateRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowGcpRateRecs
      : true;

  const canViewGcpUsageRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowGcpUsageRecs
      : true;

  const canViewSnowflakeUsageRecs =
    params.mspTenantSettings && !canReadTenantsPartner
      ? params.mspTenantSettings.allowSnowflakeUsageRecs
      : true;

  return {
    canReadTenantsPartner,
    canViewAwsRateRecs,
    canViewAwsUsageRecs,
    canViewAzureRateRecs,
    canViewAzureUsageRecs,
    canViewGcpRateRecs,
    canViewGcpUsageRecs,
    canViewSnowflakeUsageRecs,
    isMspParentTenant:
      params.authenticatedUser.tenant.type === TenantType.MSP_PARENT,
  };
}
