import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

export default function DataProcessingLoader(): JSX.Element {
  const theme = useTheme();
  const BG_COLOR = theme.panel_backgroundColor;
  const LINE_COLOR = theme.loading_outline_line_color;
  const MASKED_COLOR = theme.loading_outline_line_color_bold;

  return (
    <StyledWrapper>
      <svg
        id="datesflowSvg"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="301.77"
        viewBox="0 0 1000 301.77"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="196.48"
            y1="123.87"
            x2="433.39"
            y2="123.87"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" />
            <stop offset="0.02" stopColor="#1d1d1d" />
            <stop offset="0.07" stopColor="#515151" />
            <stop offset="0.12" stopColor="gray" />
            <stop offset="0.17" stopColor="#a7a7a7" />
            <stop offset="0.23" stopColor="#c7c7c7" />
            <stop offset="0.28" stopColor="#e0e0e0" />
            <stop offset="0.34" stopColor="#f1f1f1" />
            <stop offset="0.4" stopColor="#fcfcfc" />
            <stop offset="0.47" stopColor="#fff" />
            <stop offset="0.52" stopColor="#fafafa" />
            <stop offset="0.58" stopColor="#eaeaea" />
            <stop offset="0.66" stopColor="#d0d0d0" />
            <stop offset="0.74" stopColor="#ababab" />
            <stop offset="0.82" stopColor="#7d7d7d" />
            <stop offset="0.91" stopColor="#444" />
            <stop offset="1" stopColor="#020202" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="600.58"
            y1="147.61"
            x2="766.52"
            y2="147.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" />
            <stop offset="0" stopColor="#020202" />
            <stop offset="0.02" stopColor="#313131" />
            <stop offset="0.05" stopColor="#5c5c5c" />
            <stop offset="0.08" stopColor="#838383" />
            <stop offset="0.11" stopColor="#a5a5a5" />
            <stop offset="0.14" stopColor="#c1c1c1" />
            <stop offset="0.18" stopColor="#d8d8d8" />
            <stop offset="0.22" stopColor="#eaeaea" />
            <stop offset="0.27" stopColor="#f6f6f6" />
            <stop offset="0.34" stopColor="#fdfdfd" />
            <stop offset="0.51" stopColor="#fff" />
            <stop offset="0.67" stopColor="#fdfdfd" />
            <stop offset="0.73" stopColor="#f6f6f6" />
            <stop offset="0.78" stopColor="#e9e9e9" />
            <stop offset="0.83" stopColor="#d8d8d8" />
            <stop offset="0.86" stopColor="#c1c1c1" />
            <stop offset="0.89" stopColor="#a4a4a4" />
            <stop offset="0.92" stopColor="#828282" />
            <stop offset="0.95" stopColor="#5b5b5b" />
            <stop offset="0.98" stopColor="#303030" />
            <stop offset="1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="569.65"
            y1="149.03"
            x2="762.81"
            y2="149.03"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" />
            <stop offset="0.01" stopColor="#1a1a1a" />
            <stop offset="0.05" stopColor="#494949" />
            <stop offset="0.08" stopColor="#757575" />
            <stop offset="0.11" stopColor="#9a9a9a" />
            <stop offset="0.15" stopColor="#bababa" />
            <stop offset="0.19" stopColor="#d3d3d3" />
            <stop offset="0.24" stopColor="#e7e7e7" />
            <stop offset="0.29" stopColor="#f5f5f5" />
            <stop offset="0.35" stopColor="#fdfdfd" />
            <stop offset="0.47" stopColor="#fff" />
            <stop offset="0.58" stopColor="#fcfcfc" />
            <stop offset="0.65" stopColor="#f4f4f4" />
            <stop offset="0.71" stopColor="#e5e5e5" />
            <stop offset="0.77" stopColor="#d0d0d0" />
            <stop offset="0.82" stopColor="#b6b6b6" />
            <stop offset="0.86" stopColor="#959595" />
            <stop offset="0.91" stopColor="#6d6d6d" />
            <stop offset="0.95" stopColor="#404040" />
            <stop offset="0.99" stopColor="#0e0e0e" />
            <stop offset="1" />
          </linearGradient>
          <mask
            id="mask"
            x="75.01"
            y="37.22"
            width="168.81"
            height="240.66"
            maskUnits="userSpaceOnUse"
          >
            <g id="gFile">
              <path
                d="M243.31,110.87V130h-18V110.87Zm-34.06,74.2h17V165.92h-17Zm0-110.59h-17V93.63h17Zm-17,166.25h17V221.58h-17ZM176.52,74.48h-19V93.63h19ZM175.18,258h-15.7v19.14h32.73V258h-17Zm-15.7-128.43V110.87h-16.2V92.37h-17v19.15h16.21v55.66h17v36.38h17V148h-17V129.54Zm-16.2-54.41V56H109.21V75.13h34.07ZM109.21,185.07h17V165.92h-17Zm.36,36.51V203.09h-17V184.42h-17v19.14h17v37.17h50.74V221.58H109.57Zm0-72.9V129.54H75.51v19.14h34.06Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M226.53,184.69V222.5h-17V184.69Zm-18,74.2h18V239.74h-18Zm1-110.58h-18v19.14h18Zm-17-92.05h-17V75.4h17Zm-17,147.1V222.5h17V184.69h-17Zm0,55.53h17V239.74h-17ZM142.7,185.34h17V166.19h-17Zm.83-147.62h-17V56.86h17Zm0,92.09h-18V149h18Zm0,109.93h-18v19.15H142.7v18.49h17V258.24h-16.2Zm-17-55.05h-17v20.14h17ZM75.76,56.86h17V37.72h-17Zm0,73.43h17V111.14h-17Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M226,239.74h17v37.64H209V258.24h17Zm1-183.48H209V75.4h18ZM109,277.38h17V258.24H109ZM226,92.65H192v19.14h17v18.5h17v-18.5h17V92.65Zm0,74.8h17V148.31H226ZM159.23,56.86h17V37.72h-17Zm-17,35.79v18.49H109v19.15H143v-18.5h16.2v-37h-17Zm0,110.71H126V222.5h33.24V203.83l1-.47V184.69h-18ZM109.32,56.86H127V37.72H92.29V56.86h17Zm0,110.59H143V148.31H109v17.88H92.29v19.15h17Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M243.31,37.72V56.86h-17V37.72Zm-17,147.62h17V166.19h-17Zm0,55.66h17V221.85h-17ZM192.21,149h17V129.81h-17ZM176.52,75.4V56.26H142.45V75.4h34.07Zm0,35.74h-17v20.15h17Zm-84,92.69h17V184.69h-17v18.67h-17V222.5h17ZM209.25,74.76V93.9h34.06V74.76H209.25Zm-17,91.43h-17v19.15h17Zm0,55.66H159.48v17.89h-17v18.5H126.24v-18.5H75.51v19.15h49.73v18.49h18V258.89h33.24V241h15.69v17.89h17V239.74h-17ZM92.54,93.9h17V74.76h-17V56.26h-17V75.4h17Zm0,54.41v19.14h17V149h16.67V129.81H109.57V111.14h-17v19.15h16.67v18Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M243.31,56.8V75.94h-17V56.8ZM210.25,204.37V185.23h-18V223h17v18.49h17V222.39h-16Zm-18-166.11h-17V57.4h17Zm0,92.08H159.48v19.15h32.73Zm-33.73-18h33.73V93.18H158.48Zm1-74.06h-17V57.4h17ZM126.24,94.44h17V74.29h-17Zm0,109.45h-17V223h17V204.37h17V185.23h-17ZM109.57,93.18H75.51v19.14h34.06Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M243.31,203.62v19.14h-17V203.62Zm-17-54.4V130.07h-17v36.39h-17V148.57h-17v19.14h17V185.6h17V167.71h17ZM192.21,111.4h-17v19.15h17ZM158.48,222.12h-16v19.14h17v-18.5h17V202.62h-18ZM92.54,75.67h17V56.52h-17Zm-17,109.93h17V166.46h-17Zm0,55.66h17V222.12h-17Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
              <path
                d="M243.31,129.81V149h-17V129.81Zm-17,74h17V184.69h-17Zm0-166.11H192.21v37h-17V93.9h17V75.4h17V56.86h17Zm-34.07,92.57h17V111.14h-17Zm0,147.09h17V258.24h-17Zm-66-183.48V74.76h-17v37h17Zm-33.7,54.41h-17v19.14h17Zm33.7,37h17V166.19h-17ZM92.54,74.76h-17V93.9h17Zm-17,183.48v19.14h34.06V258.24H75.51Z"
                fill="#fff"
                stroke="#fff"
                strokeMiterlimit="10"
              />
            </g>
          </mask>
          <mask
            id="mask-2"
            x="1.39"
            y="-45.84"
            width="611.22"
            height="325.94"
            maskUnits="userSpaceOnUse"
          >
            <g>
              <rect
                x="196.48"
                y="18.06"
                width="236.9"
                height="211.61"
                fill="url(#linear-gradient)"
              />
              <rect x="431.24" y="-45.84" width="181.37" height="325.94" />
            </g>
          </mask>
          <mask
            id="mask-3"
            x="461.18"
            y="35.97"
            width="447.01"
            height="230.19"
            maskUnits="userSpaceOnUse"
          >
            <g>
              <rect x="764.19" y="38.55" width="144" height="227.61" />
              <rect
                x="600.58"
                y="47.69"
                width="165.94"
                height="199.83"
                fill="url(#linear-gradient-2)"
              />
            </g>
          </mask>
          <mask
            id="mask-4"
            x="413.65"
            y="80.05"
            width="349.16"
            height="137.96"
            maskUnits="userSpaceOnUse"
          >
            <rect
              x="569.65"
              y="80.05"
              width="193.16"
              height="137.96"
              fill="url(#linear-gradient-3)"
            />
          </mask>
          <mask
            id="mask-5"
            x="756.3"
            y="37.28"
            width="168.86"
            height="240.48"
            maskUnits="userSpaceOnUse"
          >
            <g id="gDocW">
              <g id="gDoc">
                <path
                  d="M924.78,110.87V130h-17V110.87Zm-34.07,74.2h17V165.92h-17Zm0-110.59h-17V93.63h17Zm-17,166.25h17V221.58h-17ZM858,74.48H841V93.63h17ZM856.64,258H841v19.14h32.73V258h-17ZM841,129.54V110.87H824.74V92.37h-17v19.15h16.21v55.66h17v36.38h17V148H841V129.54ZM824.74,75.13V56H790.67V75.13h34.07ZM790.67,185.07h17V165.92h-17Zm.37,36.51V203.09H774V184.42H757v19.14h17v37.17h50.73V221.58H791Zm0-72.9V129.54H757v19.14H791Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M908,184.69V222.5H891V184.69Zm-17,74.2h17V239.74H891Zm0-110.58h-17v19.14h17Zm-17-92.05h-17V75.4h17Zm-17,147.1V222.5h17V184.69h-17Zm0,55.53h17V239.74h-17Zm-32.72-73.55h17V166.19h-17ZM825,37.72H807V56.86h18Zm0,92.09H808V149h17Zm0,109.93H808v19.15h16.21v18.49h17V258.24H825Zm-17-55.05h-17v19.14h17ZM757.22,56.86h17V37.72h-17Zm0,73.43h17V111.14h-17Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M907.6,239.74h17v37.64H890.56V258.24h17Zm0-183.48h-17V75.4h17ZM790.52,277.38h17V258.24h-17ZM907.6,92.65H872.53v19.14h18v18.5h17v-18.5h17V92.65Zm0,74.8h17V148.31h-17ZM840.8,56.86h17V37.72h-17Zm-17,35.79v18.49H790.52v19.15h34.07v-18.5H841.8v-37h-18Zm0,110.71H807.26V222.5H841.8V184.69h-18ZM790.89,56.86h16.67V37.72h-33.7V56.86h17Zm0,110.59h33.7V148.31H790.52v17.88H773.86v19.15h17Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M924.78,37.72V56.86h-17V37.72Zm-17,147.62h17V166.19h-17Zm0,55.66h17V221.85h-17ZM873.68,149h17V129.81h-17ZM858,75.4V56.26H823.92V75.4H858Zm0,35.74H841v19.15h17Zm-84,92.69h17V184.69H774v18.67H757V222.5h17ZM890.71,74.76V93.9h34.07V74.76H890.71Zm-17,91.43h-17v19.15h17Zm0,55.66H841v17.89h-17v18.5H808.71v-18.5H757v19.15h49.74v18.49h18V258.89H858V241h15.7v17.89h17.43V239.74H873.68ZM774,93.9h17V74.76H774V56.26H757V75.4h17Zm0,54.41v19.14h17V149h17.67V129.81H791V111.14H774v19.15h16.66v18Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M924.48,56.6V75.74h-17V56.6ZM891,204.17V185H873.38v37.81H890l.4,18.49h17.34V222.19H891ZM874.38,37.66h-18V57.2h18Zm0,92.48H840.65v19.15h33.73Zm-34.73-18h33.73V93H839.65Zm1.2-74.46H823.62V57.2h17.23ZM807.41,94.24h17V75.09h-17Zm0,109.45h-17v19.15h17V204.17h17V185h-17ZM790.74,93H756.67v19.14h34.07Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M924.78,203.62v19.14h-17V203.62Zm-17-54.4V130.07h-17v36.39h-16V148.57h-18v19.14h17V185.6h17V167.71h17ZM873.68,111.4h-17v19.15h17ZM841,222.12h-17v19.14h17v-18.5h17V203.62H841ZM774,75.67h17V56.52H774ZM757,185.6h17V166.46H757Zm0,55.66h17V222.12H757Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
                <path
                  d="M924.78,129.81V149h-17V129.81Zm-17,74h17V184.69h-17Zm0-166.11H873.68v37h-17V93.9h17V75.4h17V56.86h17Zm-34.07,92.57h17V111.14h-17Zm0,147.09h17V258.24h-17Zm-66-183.48V74.76h-17v37h17ZM774,148.31H757v19.14h17Zm33.7,37h17V166.19h-17ZM774,74.76H757V93.9h17ZM757,258.24v19.14H791V258.24H757Z"
                  fill={LINE_COLOR}
                  stroke={LINE_COLOR}
                  strokeMiterlimit="10"
                  strokeWidth="0.75"
                />
              </g>
            </g>
          </mask>
        </defs>
        <g>
          {/*
            
            ///

            CLOUD
            
            ///

            */}
          <g id="gCloud">
            <path
              d="M656.08,241.83H266.27l.3-1.75c1.17-7,4.28-17.1,12.48-25.53,13.31-13.7,35.57-17.94,56.73-10.82l-1,2.84c-20.06-6.75-41.11-2.8-53.62,10.07a44.35,44.35,0,0,0-11.35,22.19H656.08Z"
              fill={LINE_COLOR}
            />
            <path
              d="M621.55,160.4a101.48,101.48,0,0,0-8-31.16c-12.37-28.37-43.92-62.67-92.11-66-59.9-4.15-95.34,45.07-99.19,50.7a112.35,112.35,0,0,0-16.78,39l-2.93-.65a115.11,115.11,0,0,1,17.24-40c3.94-5.77,40.29-56.26,101.87-52C571.2,63.67,603.62,98.9,616.33,128a104.46,104.46,0,0,1,8.21,32.08Z"
              fill={LINE_COLOR}
            />
            <path
              d="M333.86,205.58c-6-20.3,1.75-42.47,18.91-53.92,15.45-10.3,35.89-10.43,52.06-.32l-1.59,2.54c-15.18-9.48-34.33-9.38-48.8.28-16.08,10.72-23.36,31.51-17.7,50.56Z"
              fill={LINE_COLOR}
            />
            <path
              d="M681.65,233.39,679,231.9c9.49-16.56,9.38-36.3-.29-51.5-8.92-14-25.29-23-40.77-22.2a40.29,40.29,0,0,0-14.33,3.43l-1.21-2.75a43.76,43.76,0,0,1,15.4-3.68c16.74-.83,33.81,8.44,43.44,23.59C691.55,194.94,691.69,215.86,681.65,233.39Z"
              fill={LINE_COLOR}
            />
            {/*
            
            ///

            GEARS
            
            ///
            
            */}
            <g id="gCloudWheel">
              <g>
                <g>
                  <path
                    d="M571.14,175,561.58,172l3.23-10.12,9.56,3Zm-5.79-5,3.84,1.23,1.41-4.41-3.84-1.23Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M553.4,230.57l-9.56-3,3.24-10.12,9.55,3Zm-5.79-5,3.85,1.22,1.4-4.41L549,221.17Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M534.87,193.74l-10.12-3.23,3-9.56,10.12,3.23Zm-6.35-5.18,4.41,1.41,1.22-3.84-4.41-1.41Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M590.41,211.48l-10.12-3.24,3.05-9.55,10.12,3.23Zm-6.35-5.18,4.41,1.41,1.23-3.85-4.41-1.4Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M543.72,177.33l-4.88-9.45,8.92-4.6,4.87,9.45Zm-.83-8.16,2.12,4.12,3.58-1.85-2.12-4.12Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M570.45,229.14l-4.87-9.44,8.91-4.6,4.88,9.44Zm-.83-8.15,2.12,4.11,3.59-1.85-2.13-4.11Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M530.78,216.48l-4.6-8.92,9.44-4.87,4.6,8.91Zm-.56-7.63,1.85,3.58,4.11-2.12-1.85-3.58Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M582.59,189.74l-4.6-8.91,9.45-4.88,4.6,8.92Zm-.56-7.62,1.85,3.58,4.12-2.12L586.14,180Z"
                    fill={LINE_COLOR}
                  />
                  <circle cx="559.45" cy="196.8" r="27.56" fill={BG_COLOR} />
                  <path
                    d="M559.43,225.86a29.07,29.07,0,1,1,27.7-20.22h0A29.1,29.1,0,0,1,559.43,225.86Zm0-55.12a26.06,26.06,0,1,0,24.81,34h0a26.08,26.08,0,0,0-24.81-34Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M559.47,211.45a14.65,14.65,0,1,1,6.69-1.63A14.59,14.59,0,0,1,559.47,211.45Zm0-26.3a11.69,11.69,0,1,0,3.55.56A11.7,11.7,0,0,0,559.44,185.15Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <circle cx="559.45" cy="196.8" r="6.65" fill={LINE_COLOR} />
                <circle cx="559.45" cy="196.8" r="38.84" fill="none" />
              </g>
              <g>
                <g>
                  <path
                    d="M463.94,185.2l-8.13-.22.23-8.56,8.13.21Zm-5.05-3.14,2.13.06.07-2.57L459,179.5Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M462.83,227.72l-8.13-.21.23-8.57,8.12.22Zm-5.05-3.13,2.13,0,.07-2.56-2.13-.06Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M442.35,205.69l-8.56-.22.21-8.13,8.56.22Zm-5.49-3.14,2.57.06.05-2.13-2.56-.06Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M484.87,206.8l-8.56-.22.21-8.13,8.56.23Zm-5.48-3.14,2.56.07.06-2.13-2.57-.07Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M444.79,192.55l-5.89-6.22,5.89-5.59,5.9,6.21Zm-1.65-6.1,1.76,1.86,1.55-1.47L444.68,185Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M474.07,223.4l-5.89-6.21,5.9-5.6,5.89,6.22Zm-1.65-6.1,1.77,1.86,1.54-1.46L474,215.83Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M443.7,222.61l-5.6-5.9,6.21-5.89,5.6,5.89Zm-1.36-5.79,1.47,1.55,1.86-1.77-1.47-1.54Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M474.55,193.33l-5.59-5.9,6.21-5.9,5.6,5.9Zm-1.35-5.79,1.47,1.55,1.86-1.77-1.47-1.55Z"
                    fill={LINE_COLOR}
                  />
                  <circle cx="459.8" cy="202.41" r="20.11" fill={BG_COLOR} />
                  <path
                    d="M459.8,224h-.57a21.51,21.51,0,1,1,.57,0Zm0-40.21a18.38,18.38,0,1,0,.49,0Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M459.8,213.5h-.29a11.09,11.09,0,1,1,11.37-10.8h0A11.1,11.1,0,0,1,459.8,213.5Zm0-19.18a8.09,8.09,0,0,0-.21,16.18h.22a8.09,8.09,0,0,0,.21-16.17Z"
                    fill={LINE_COLOR}
                  />
                  <circle cx="459.8" cy="202.41" r="4.85" fill={LINE_COLOR} />
                </g>
                <circle cx="459.8" cy="202.41" r="30.98" fill="none" />
              </g>
              <g>
                <g>
                  <path
                    d="M505.69,103.33h-13.3V89.16h13.3Zm-10.3-3h7.3V92.16h-7.3Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M505.69,188.69h-13.3V174.53h13.3Zm-10.3-3h7.3v-8.16h-7.3Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M463.45,145.58H449.28v-13.3h14.17Zm-11.17-3h8.17v-7.3h-8.17Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M548.81,145.58H534.64v-13.3h14.17Zm-11.17-3h8.17v-7.3h-8.17Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M469.17,118.46l-10-10,9.4-9.4,10,10Zm-5.78-10,5.78,5.78,5.16-5.16-5.78-5.78Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M529.53,178.82l-10-10,9.4-9.4,10,10Zm-5.78-10,5.78,5.78,5.16-5.16-5.78-5.78Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M468.55,178.82l-9.4-9.4,10-10,9.4,9.4Zm-5.16-9.4,5.16,5.16,5.78-5.78-5.16-5.16Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M528.91,118.46l-9.4-9.4,10-10,9.4,9.4Zm-5.16-9.4,5.16,5.16,5.78-5.78-5.16-5.16Z"
                    fill={LINE_COLOR}
                  />
                  <circle cx="499.78" cy="139.6" r="40.35" fill={BG_COLOR} />
                  <path
                    d="M499.78,181.45a41.85,41.85,0,1,1,41.85-41.85A41.91,41.91,0,0,1,499.78,181.45Zm0-80.7a38.85,38.85,0,1,0,38.85,38.85A38.89,38.89,0,0,0,499.78,100.75Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M499.78,160.34a20.74,20.74,0,1,1,20.74-20.74A20.77,20.77,0,0,1,499.78,160.34Zm0-38.48a17.74,17.74,0,1,0,17.74,17.74A17.76,17.76,0,0,0,499.78,121.86Z"
                    fill={LINE_COLOR}
                  />
                  <circle cx="499.78" cy="139.6" r="9.73" fill={LINE_COLOR} />
                </g>
                <circle cx="499.78" cy="139.6" r="56.85" fill="none" />
              </g>
            </g>
          </g>
          {/*
            
            ///

            File On Left
            
            ///
            
            */}
          <g mask="url(#mask)">
            <g id="gFileM">
              <g>
                <g>
                  <path
                    d="M125.37,246.26l94-34.88L181,110.23,88,147.68Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M125.37,247.76a1.51,1.51,0,0,1-1.4-1L86.55,148.21a1.51,1.51,0,0,1,.85-1.93l93-37.44a1.51,1.51,0,0,1,1.16,0,1.49,1.49,0,0,1,.8.85l38.35,101.15a1.53,1.53,0,0,1,0,1.15,1.48,1.48,0,0,1-.85.78l-94,34.89A1.54,1.54,0,0,1,125.37,247.76ZM89.88,148.52l36.37,95.82,91.14-33.84-37.26-98.31Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <path
                    d="M92.61,125.41l50.87-20.12c14.71-5.82,32.39-12.84,47.09-18.67q17.91,55.74,35.83,111.49l-94.52,37.51Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M131,237.59l-40.27-113,1.35-.53,50.87-20.12L190,85.22l1.49-.59L228.26,199ZM94.51,126.28l38.26,107.38,91.77-36.42L189.63,88.6c-14.4,5.72-31.38,12.45-45.6,18.08C127.93,113.06,110.68,119.89,94.51,126.28Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <path
                    d="M90.48,139.43l110.76-41.8Q217.7,148.81,234.16,200l-102,39.47Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M131.36,241.4,88.49,138.57l113.7-42.9L236,200.9ZM92.46,140.29,133,237.55l99.3-38.43-32-99.53Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <path
                    d="M162.47,85.38l-66,20.18,4.1,14.48,66.36-20.18Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M99.5,121.92l-4.92-17.37,68.89-21,5.29,17.35Zm-1.24-15.36,3.29,11.6L165,98.86l-3.54-11.6Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <polygon
                    points="110.57 129.32 196.11 104.12 228.72 197.19 137.66 231.97 110.57 129.32"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M136.64,234,108.74,128.3l88.31-26,33.57,95.79ZM112.39,130.35,138.68,230l88.14-33.66L195.16,106Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <path
                    d="M143.5,100.31c.42-.17-20.76-12-20.76-12l-10.51,24.55,31.27-12.53"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M109.42,115.58,122,86.17l1.46.81C145.09,99.07,145.09,99.15,145,100.4l-.06.94-.88.36Zm14-25.17L115,110.1,140.26,100C136.54,97.79,129.07,93.56,123.47,90.41Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <polygon
                    points="89.08 122.11 87.96 147.68 125.37 246.26 129.47 229.8 89.08 122.11"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M125.37,247.76a1.49,1.49,0,0,1-1.4-1L86.55,148.21a1.54,1.54,0,0,1-.09-.6L87.58,122a1.5,1.5,0,0,1,2.9-.46l40.39,107.69a1.5,1.5,0,0,1,0,.89l-4.09,16.46a1.5,1.5,0,0,1-1.37,1.14ZM89.47,147.43l35.6,93.82,2.83-11.36L90.26,129.52Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
                <g>
                  <polygon
                    points="89.08 122.11 160.49 93.5 157.46 83.69 177.71 76.4 221.38 194.42 129.47 229.8 89.08 122.11"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M129.46,231.3a1.63,1.63,0,0,1-.61-.13,1.53,1.53,0,0,1-.79-.85L87.68,122.64a1.5,1.5,0,0,1,.84-1.92l70.13-28.1L156,84.13a1.49,1.49,0,0,1,.93-1.85L177.2,75a1.51,1.51,0,0,1,1.92.89l43.67,118a1.51,1.51,0,0,1-.87,1.92L130,231.2A1.62,1.62,0,0,1,129.46,231.3ZM91,123l39.34,104.89,89.12-34.3L176.82,78.31l-17.5,6.31,2.6,8.44a1.51,1.51,0,0,1-.87,1.84Z"
                    fill={BG_COLOR}
                    stroke={MASKED_COLOR}
                    strokeMiterlimit="10"
                  />
                </g>
              </g>
            </g>
          </g>
          {/*
            
            ///

            1s, 0s, Dollar Signs
            
            ///
            
            */}

          <g mask="url(#mask-2)">
            <g id="gInfo">
              <g>
                <path
                  d="M243.37,109.76c-2-.23-.62-15.05-1-16.26-.13-4,14.3-3.59,15.72-1.08.68.7.63,16,0,16.72C256.7,111.7,245.41,111.34,243.37,109.76Zm4.19-.86c1.74-.35,7.53,1.15,7.86-1.45V94.12c0-1.25-1.38-1.43-2.54-1.47-1.58.31-7.61-1-7.48,1.58v13C245.4,108.48,246.34,108.78,247.56,108.9Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M311.45,162.8c-2-.23-.63-15.05-1-16.26-.12-4,14.3-3.59,15.73-1.08.67.7.62,16,0,16.72C324.78,164.74,313.48,164.37,311.45,162.8Zm4.19-.87c1.73-.34,7.52,1.15,7.85-1.44V147.16c-.05-1.26-1.37-1.43-2.54-1.47-1.58.31-7.6-1-7.47,1.58v13C313.47,161.52,314.41,161.81,315.64,161.93Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M368.76,66.93c-2-.23-.63-15.05-1-16.26-.12-4,14.3-3.6,15.72-1.08.68.7.63,16,0,16.72C382.08,68.87,370.79,68.5,368.76,66.93Zm4.18-.87c1.74-.34,7.53,1.15,7.86-1.44V51.29c-.05-1.26-1.38-1.43-2.54-1.47-1.58.3-7.61-1-7.47,1.58v13C370.78,65.65,371.72,65.94,372.94,66.06Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M31.76,109.76c-2-.23-.63-15.05-1-16.26-.12-4,14.3-3.59,15.73-1.08.67.7.63,16,0,16.72C45.09,111.7,33.8,111.34,31.76,109.76ZM36,108.9c1.73-.35,7.53,1.15,7.85-1.45V94.12c0-1.25-1.37-1.43-2.54-1.47-1.57.31-7.6-1-7.47,1.58v13C33.78,108.48,34.73,108.78,36,108.9Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M99.83,162.8c-2-.23-.62-15.05-1-16.26-.13-4,14.3-3.59,15.72-1.08.68.7.63,16,0,16.72C113.16,164.74,101.87,164.37,99.83,162.8Zm4.19-.87c1.74-.34,7.53,1.15,7.86-1.44V147.16c0-1.26-1.38-1.43-2.54-1.47-1.58.31-7.61-1-7.47,1.58v13C101.86,161.52,102.8,161.81,104,161.93Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M157.14,66.93c-2-.23-.62-15.05-1-16.26-.13-4,14.3-3.6,15.72-1.08.67.7.63,16,0,16.72C170.47,68.87,159.18,68.5,157.14,66.93Zm4.19-.87c1.74-.34,7.53,1.15,7.86-1.44V51.29c-.06-1.26-1.38-1.43-2.54-1.47-1.58.3-7.61-1-7.48,1.58v13C159.17,65.65,160.11,65.94,161.33,66.06Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="1.39"
                  y="6.55"
                  width="423.23"
                  height="229.1"
                  fill={"none"}
                />
              </g>
              <g>
                <path
                  d="M224.74,71.26c.64-.42,2-.31,2.14-1.26V57.15c-.11-.93-1.51-.81-2.14-1.24-1.59-1.94,3.24-4,4.75-2.87a1,1,0,0,1,.47.73v16.1c.09,1.46,2.47.63,2.61,2C232.89,73.39,222.13,73.36,224.74,71.26Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M274.24,125.27c.64-.41,2.05-.3,2.14-1.25V111.17c-.11-.93-1.51-.81-2.14-1.25-1.59-1.94,3.24-4,4.75-2.87a1,1,0,0,1,.47.73v16.1c.09,1.46,2.47.64,2.61,2C282.39,127.4,271.63,127.37,274.24,125.27Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M393.82,105.18c.64-.42,2.06-.31,2.14-1.26V91.07c-.1-.93-1.51-.81-2.14-1.24-1.58-1.94,3.24-4,4.75-2.87a.94.94,0,0,1,.48.73v16.09c.08,1.47,2.47.64,2.61,2C402,107.31,391.22,107.28,393.82,105.18Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M13.12,71.26c.65-.42,2.06-.31,2.14-1.26V57.15c-.1-.93-1.5-.81-2.14-1.24-1.58-1.94,3.25-4,4.76-2.87a1,1,0,0,1,.47.73v16.1c.09,1.46,2.47.63,2.61,2C21.28,73.39,10.52,73.36,13.12,71.26Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M62.62,125.27c.65-.41,2.06-.3,2.14-1.25V111.17c-.1-.93-1.51-.81-2.14-1.25-1.58-1.94,3.25-4,4.76-2.87a1,1,0,0,1,.47.73v16.1c.09,1.46,2.47.64,2.61,2C70.78,127.4,60,127.37,62.62,125.27Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M182.21,105.18c.64-.42,2-.31,2.14-1.26V91.07c-.11-.93-1.51-.81-2.14-1.24-1.58-1.94,3.24-4,4.75-2.87a1,1,0,0,1,.47.73v16.09c.09,1.47,2.47.64,2.61,2C190.36,107.31,179.6,107.28,182.21,105.18Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="1.39"
                  y="6.55"
                  width="423.23"
                  height="229.1"
                  fill={"none"}
                />
              </g>
              <g>
                <path
                  d="M305.77,97.93c1-.87,2.89-.93,2.83-2.65v-27c-.18-2.67-7.86-2.63.1-8.9,1.63-1,4-.29,4,1.78V95c0,2.23,2,1.89,3.08,3,2.3,4.55-6.27,2.93-8.31,3.2C305.86,101.27,304.41,99.05,305.77,97.93Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M355.61,130.69c1-.87,2.88-.93,2.83-2.65V101c-.18-2.67-7.86-2.63.09-8.9,1.63-1,4-.29,4,1.78v33.87c0,2.23,2,1.89,3.07,3,2.3,4.55-6.27,2.93-8.3,3.2C355.7,134,354.24,131.81,355.61,130.69Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M94.16,97.93c1-.87,2.89-.93,2.83-2.65v-27c-.18-2.67-7.86-2.63.1-8.9,1.62-1,4-.29,4,1.78V95c0,2.23,2,1.89,3.07,3,2.31,4.55-6.27,2.93-8.3,3.2C94.25,101.27,92.79,99.05,94.16,97.93Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M144,130.69c1-.87,2.89-.93,2.84-2.65V101c-.19-2.67-7.87-2.63.09-8.9,1.63-1,4-.29,4,1.78v33.87c0,2.23,2,1.89,3.07,3,2.3,4.55-6.27,2.93-8.31,3.2C144.08,134,142.63,131.81,144,130.69Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="1.39"
                  y="6.55"
                  width="423.23"
                  height="229.1"
                  fill={"none"}
                />
              </g>
              <g>
                <path
                  d="M260.29,196.38a4.89,4.89,0,0,1-1.35-3.46c.33-.93-.66-33.11.51-32.91,1.8-5.4,11-3.26,15.09-3.66a6.16,6.16,0,0,1,5.72,5.59v31.12a6,6,0,0,1-5.57,5.58C271.34,198.26,262.56,199.93,260.29,196.38Zm5.54-1.82c2.74-.37,10.68,1.79,10.39-3-.43-1.35,1-30.23-.93-30.4-1.33-1.39-7.23-.5-9-.74-2.24,0-3.36,1.1-3.36,3.32V191.1C262.94,193.12,263.75,194.3,265.83,194.56Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M377.44,204a4.93,4.93,0,0,1-1.34-3.46c.32-.93-.66-33.12.5-32.91,1.81-5.4,11-3.27,15.1-3.66a6.15,6.15,0,0,1,5.71,5.58v31.13a6,6,0,0,1-5.57,5.58C388.5,205.88,379.72,207.56,377.44,204Zm5.55-1.82c2.74-.37,10.67,1.78,10.39-3-.43-1.35,1.05-30.23-.94-30.4-1.32-1.39-7.22-.51-8.95-.75q-3.36,0-3.36,3.32v27.39C380.1,200.75,380.91,201.93,383,202.19Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M48.67,196.38a4.93,4.93,0,0,1-1.34-3.46c.33-.93-.66-33.11.5-32.91,1.81-5.4,11-3.26,15.1-3.66a6.14,6.14,0,0,1,5.71,5.59v31.12a6,6,0,0,1-5.57,5.58C59.73,198.26,51,199.93,48.67,196.38Zm5.55-1.82c2.74-.37,10.67,1.79,10.39-3-.43-1.35,1-30.23-.93-30.4-1.33-1.39-7.23-.5-9-.74-2.24,0-3.36,1.1-3.36,3.32V191.1C51.33,193.12,52.14,194.3,54.22,194.56Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M165.83,204a4.88,4.88,0,0,1-1.34-3.46c.32-.93-.67-33.12.5-32.91,1.81-5.4,11-3.27,15.1-3.66a6.16,6.16,0,0,1,5.71,5.58v31.13a6,6,0,0,1-5.57,5.58C176.88,205.88,168.11,207.56,165.83,204Zm5.54-1.82c2.74-.37,10.68,1.78,10.4-3-.44-1.35,1-30.23-.94-30.4-1.32-1.39-7.23-.51-9-.75q-3.36,0-3.36,3.32v27.39C168.49,200.75,169.3,201.93,171.37,202.19Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="1.39"
                  y="6.55"
                  width="423.23"
                  height="229.1"
                  fill={"none"}
                />
              </g>
            </g>
          </g>
          <g mask="url(#mask-3)">
            <g id="gMoney">
              <g>
                <path
                  d="M673.12,209v-3.76a14.74,14.74,0,0,1-6.77-1.64l1.06-2.5a13.37,13.37,0,0,0,6.38,1.61c3.14,0,5.27-1.53,5.27-3.65s-1.73-3.32-5-4.44c-4.51-1.49-7.3-3.2-7.3-6.44s2.61-5.44,6.68-6v-3.76h2.75v3.61a13.48,13.48,0,0,1,5.75,1.31l-1.1,2.46a12.2,12.2,0,0,0-5.58-1.27c-3.41,0-4.7,1.71-4.7,3.2,0,1.94,1.64,2.91,5.5,4.25,4.56,1.56,6.86,3.5,6.86,6.82,0,2.94-2.44,5.69-7,6.33V209Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M523.76,209v-3.76a14.74,14.74,0,0,1-6.77-1.64l1.06-2.5a13.37,13.37,0,0,0,6.38,1.61c3.14,0,5.27-1.53,5.27-3.65s-1.73-3.32-5-4.44c-4.51-1.49-7.3-3.2-7.3-6.44s2.61-5.44,6.68-6v-3.76h2.75v3.61a13.48,13.48,0,0,1,5.75,1.31l-1.1,2.46a12.2,12.2,0,0,0-5.58-1.27c-3.41,0-4.7,1.71-4.7,3.2,0,1.94,1.64,2.91,5.49,4.25,4.57,1.56,6.87,3.5,6.87,6.82,0,2.94-2.44,5.69-7,6.33V209Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="461.18"
                  y="35.97"
                  width="299.14"
                  height="198.12"
                  fill={"none"}
                />
              </g>
              <g>
                <path
                  d="M643.22,124v-3.76a14.74,14.74,0,0,1-6.77-1.64l1.06-2.5a13.37,13.37,0,0,0,6.38,1.6c3.14,0,5.27-1.52,5.27-3.65s-1.73-3.31-5-4.43c-4.51-1.49-7.3-3.2-7.3-6.44s2.61-5.44,6.68-6V93.46h2.75v3.61A13.48,13.48,0,0,1,652,98.38l-1.1,2.45a12.36,12.36,0,0,0-5.58-1.26c-3.41,0-4.7,1.71-4.7,3.2,0,1.94,1.64,2.91,5.5,4.25,4.56,1.56,6.86,3.5,6.86,6.81,0,3-2.44,5.7-7,6.34V124Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M718.91,146.23v-3.76a14.78,14.78,0,0,1-6.77-1.64l1.06-2.49a13.52,13.52,0,0,0,6.38,1.6c3.14,0,5.27-1.53,5.27-3.65s-1.73-3.32-5-4.44c-4.51-1.49-7.3-3.2-7.3-6.44,0-3.09,2.61-5.44,6.68-6v-3.76H722v3.62a13.55,13.55,0,0,1,5.76,1.3L726.62,123a12.2,12.2,0,0,0-5.58-1.27c-3.41,0-4.69,1.71-4.69,3.2,0,1.94,1.63,2.91,5.49,4.25,4.56,1.56,6.86,3.5,6.86,6.82,0,2.94-2.44,5.69-7,6.33v3.87Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M493.86,124v-3.76a14.74,14.74,0,0,1-6.77-1.64l1.06-2.5a13.37,13.37,0,0,0,6.38,1.6c3.14,0,5.27-1.52,5.27-3.65s-1.73-3.31-5-4.43c-4.51-1.49-7.3-3.2-7.3-6.44s2.61-5.44,6.68-6V93.46h2.75v3.61a13.48,13.48,0,0,1,5.75,1.31l-1.1,2.45A12.36,12.36,0,0,0,496,99.57c-3.41,0-4.7,1.71-4.7,3.2,0,1.94,1.64,2.91,5.5,4.25,4.56,1.56,6.86,3.5,6.86,6.81,0,3-2.44,5.7-7,6.34V124Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M569.55,146.23v-3.76a14.78,14.78,0,0,1-6.77-1.64l1.06-2.49a13.52,13.52,0,0,0,6.38,1.6c3.14,0,5.27-1.53,5.27-3.65s-1.73-3.32-5-4.44c-4.51-1.49-7.3-3.2-7.3-6.44,0-3.09,2.61-5.44,6.68-6v-3.76h2.75v3.62a13.55,13.55,0,0,1,5.76,1.3L577.26,123a12.2,12.2,0,0,0-5.58-1.27c-3.41,0-4.69,1.71-4.69,3.2,0,1.94,1.63,2.91,5.49,4.25,4.56,1.56,6.86,3.5,6.86,6.82,0,2.94-2.44,5.69-7,6.33v3.87Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="461.18"
                  y="35.97"
                  width="299.14"
                  height="198.12"
                  fill="none"
                />
              </g>
              <g>
                <path
                  d="M682.66,116.76v-7.65a17,17,0,0,1-9.93-3.34l1.56-5.08a15.57,15.57,0,0,0,9.34,3.26c4.6,0,7.72-3.1,7.72-7.42s-2.53-6.75-7.33-9c-6.62-3-10.7-6.52-10.7-13.11,0-6.3,3.82-11.07,9.79-12.21V54.54h4v7.35a15.45,15.45,0,0,1,8.43,2.65l-1.62,5A14.05,14.05,0,0,0,685.77,67c-5,0-6.88,3.48-6.88,6.52,0,3.94,2.4,5.91,8.05,8.64,6.68,3.18,10,7.12,10,13.87,0,6-3.57,11.59-10.25,12.88v7.88Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M533.3,116.76v-7.65a17,17,0,0,1-9.93-3.34l1.56-5.08a15.57,15.57,0,0,0,9.34,3.26c4.6,0,7.72-3.1,7.72-7.42s-2.53-6.75-7.33-9C528,84.48,524,81,524,74.4c0-6.3,3.82-11.07,9.79-12.21V54.54h4v7.35a15.45,15.45,0,0,1,8.43,2.65l-1.62,5A14.05,14.05,0,0,0,536.41,67c-5,0-6.88,3.48-6.88,6.52,0,3.94,2.4,5.91,8.05,8.64,6.68,3.18,10,7.12,10,13.87,0,6-3.57,11.59-10.25,12.88v7.88Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="461.18"
                  y="35.97"
                  width="299.14"
                  height="198.12"
                  fill="none"
                />
              </g>
              <g>
                <path
                  d="M638.82,194.49v-7.62a16.82,16.82,0,0,1-9.83-3.32l1.54-5.06a15.31,15.31,0,0,0,9.25,3.25c4.56,0,7.64-3.09,7.64-7.39,0-4.15-2.5-6.72-7.26-9-6.55-3-10.59-6.48-10.59-13.05,0-6.26,3.79-11,9.69-12.14v-7.62h4v7.32a15.33,15.33,0,0,1,8.35,2.64l-1.61,5a13.84,13.84,0,0,0-8.09-2.57c-4.95,0-6.81,3.47-6.81,6.49,0,3.92,2.38,5.88,8,8.6,6.62,3.16,10,7.09,10,13.8,0,6-3.53,11.54-10.15,12.82v7.85Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M489.46,194.49v-7.62a16.82,16.82,0,0,1-9.83-3.32l1.54-5.06a15.31,15.31,0,0,0,9.25,3.25c4.56,0,7.64-3.09,7.64-7.39,0-4.15-2.5-6.72-7.26-9-6.55-3-10.59-6.48-10.59-13.05,0-6.26,3.79-11,9.69-12.14v-7.62h4v7.32a15.33,15.33,0,0,1,8.35,2.64l-1.61,5a13.84,13.84,0,0,0-8.09-2.57c-5,0-6.81,3.47-6.81,6.49,0,3.92,2.38,5.88,8,8.6,6.62,3.16,10,7.09,10,13.8,0,6-3.53,11.54-10.15,12.82v7.85Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="461.18"
                  y="35.97"
                  width="299.14"
                  height="198.12"
                  fill="none"
                />
              </g>
              <g>
                <path
                  d="M734.55,209.29v-7.66a17,17,0,0,1-9.92-3.33l1.55-5.08a15.59,15.59,0,0,0,9.34,3.26c4.61,0,7.72-3.11,7.72-7.43s-2.53-6.74-7.33-9c-6.61-3-10.7-6.51-10.7-13.11,0-6.29,3.83-11.06,9.79-12.2v-7.66h4v7.36a15.45,15.45,0,0,1,8.43,2.65l-1.62,5a14.06,14.06,0,0,0-8.18-2.58c-5,0-6.87,3.49-6.87,6.52,0,3.94,2.4,5.91,8,8.64,6.68,3.19,10.06,7.13,10.06,13.87,0,6-3.57,11.6-10.25,12.89v7.88Z"
                  fill={LINE_COLOR}
                />
                <path
                  d="M585.19,209.29v-7.66a17,17,0,0,1-9.92-3.33l1.55-5.08a15.59,15.59,0,0,0,9.34,3.26c4.61,0,7.72-3.11,7.72-7.43s-2.53-6.74-7.33-9c-6.61-3-10.7-6.51-10.7-13.11,0-6.29,3.83-11.06,9.79-12.2v-7.66h4v7.36a15.45,15.45,0,0,1,8.43,2.65l-1.62,5a14.06,14.06,0,0,0-8.18-2.58c-5,0-6.87,3.49-6.87,6.52,0,3.94,2.4,5.91,8,8.64,6.68,3.19,10.06,7.13,10.06,13.87,0,6-3.57,11.6-10.25,12.89v7.88Z"
                  fill={LINE_COLOR}
                />
                <rect
                  x="461.18"
                  y="35.97"
                  width="299.14"
                  height="198.12"
                  fill="none"
                />
              </g>
            </g>
          </g>
          <g mask="url(#mask-4)">
            <g id="gKilk">
              <g>
                <g>
                  <path
                    d="M674.66,138.54a12.2,12.2,0,0,1-7.83-3.09A13.2,13.2,0,0,1,662,125.12h0a13.28,13.28,0,0,1,4.71-9.43,11.36,11.36,0,0,1,8.76-2.84c6.49.79,10.45,7.4,10.39,13.08-.06,5.2-3.54,11.27-9.28,12.42A10.33,10.33,0,0,1,674.66,138.54ZM663,125.16a12.21,12.21,0,0,0,4.48,9.53,10.72,10.72,0,0,0,8.91,2.68c5.24-1.05,8.42-6.65,8.47-11.45.06-5.25-3.57-11.36-9.51-12.08a10.35,10.35,0,0,0-8,2.61,12.29,12.29,0,0,0-4.36,8.71Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="676.48"
                    cy="125.91"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(505.33 792.38) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M676.38,139.35l-.81,0c-7-.47-12.22-6.87-11.72-14.26h0c.5-7.4,6.57-13,13.53-12.56a12.16,12.16,0,0,1,8.69,4.55,13.8,13.8,0,0,1,3,9.71C688.63,133.87,683,139.35,676.38,139.35Zm-10.54-14.16c-.42,6.3,4,11.74,9.87,12.14s11-4.41,11.41-10.7a11.81,11.81,0,0,0-2.6-8.31,10.21,10.21,0,0,0-7.27-3.83c-5.88-.39-11,4.41-11.41,10.7Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M674.58,126.4l3.57-3.43-4.27-1.77q.15,5.14,0,10.29c-.06,3.22,4.94,3.22,5,0q.11-5.15,0-10.29a2.53,2.53,0,0,0-4.27-1.77L671,122.87c-2.32,2.23,1.21,5.76,3.54,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <g>
                  <path
                    d="M722.27,184a12.66,12.66,0,0,1-8.15-3.2,13.68,13.68,0,0,1-5-10.72h0a13.83,13.83,0,0,1,4.88-9.8,11.88,11.88,0,0,1,9.15-3c6.77.82,10.89,7.69,10.83,13.58-.06,5.4-3.69,11.71-9.68,12.91A10.48,10.48,0,0,1,722.27,184Zm-11.16-13.85a11.69,11.69,0,0,0,4.3,9.13,10.15,10.15,0,0,0,8.49,2.56c5-1,8-6.35,8.07-11,0-5-3.4-10.88-9.07-11.57a9.82,9.82,0,0,0-7.61,2.49,11.82,11.82,0,0,0-4.18,8.35Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="724.09"
                    cy="170.84"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(504.9 881.79) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M724,184.28c-.28,0-.55,0-.82,0-7-.47-12.23-6.87-11.73-14.27h0c.5-7.39,6.58-13,13.53-12.56a12.21,12.21,0,0,1,8.69,4.56,14,14,0,0,1-1.27,18.92A12.29,12.29,0,0,1,724,184.28Zm-10.56-14.16h0c-.42,6.29,4,11.74,9.87,12.13a10.25,10.25,0,0,0,7.72-2.81,12,12,0,0,0,1.1-16.19,10.25,10.25,0,0,0-7.28-3.83C719,159,713.88,163.82,713.45,170.12Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M720.42,169.56l3.57-3.43q.16,5.15,0,10.29"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M722.19,171.33l3.57-3.43-4.27-1.77c.11,3.43.12,6.86,0,10.29-.06,3.22,4.94,3.22,5,0,.07-3.43.06-6.86,0-10.29a2.53,2.53,0,0,0-4.26-1.77l-3.58,3.44c-2.32,2.23,1.22,5.76,3.54,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <g>
                  <path
                    d="M610.79,171.07a12.67,12.67,0,0,1-8.15-3.21,13.71,13.71,0,0,1-5-10.72h0a13.85,13.85,0,0,1,4.88-9.8,11.88,11.88,0,0,1,9.15-3c6.77.83,10.89,7.69,10.83,13.58-.06,5.41-3.69,11.71-9.68,12.91A10.48,10.48,0,0,1,610.79,171.07Zm-11.16-13.86a11.69,11.69,0,0,0,4.3,9.13,10.2,10.2,0,0,0,8.49,2.57c5-1,8-6.36,8.07-11,0-5-3.4-10.88-9.07-11.57a9.86,9.86,0,0,0-7.61,2.49,11.82,11.82,0,0,0-4.18,8.35Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="612.61"
                    cy="157.94"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(413.8 758.53) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M612.53,171.38c-.28,0-.55,0-.82,0A12.2,12.2,0,0,1,603,166.8a13.8,13.8,0,0,1-3-9.71h0c.5-7.4,6.58-13,13.54-12.56a12.2,12.2,0,0,1,8.68,4.55A14,14,0,0,1,620.93,168,12.23,12.23,0,0,1,612.53,171.38ZM602,157.22a11.82,11.82,0,0,0,2.6,8.31,10.13,10.13,0,0,0,15,1,12,12,0,0,0,1.1-16.19,10.25,10.25,0,0,0-7.28-3.83c-5.88-.39-11,4.41-11.41,10.7Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M608.94,156.67l3.57-3.44q.16,5.15,0,10.29"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M610.71,158.43l3.57-3.43L610,153.23c.11,3.43.12,6.86,0,10.29-.06,3.22,4.94,3.22,5,0,.07-3.43.06-6.86,0-10.29a2.52,2.52,0,0,0-4.26-1.76l-3.58,3.43c-2.32,2.23,1.22,5.77,3.54,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <rect
                  x="582.03"
                  y="101.25"
                  width="168.39"
                  height="95.55"
                  fill="none"
                />
              </g>
              <g>
                <g>
                  <path
                    d="M506.27,138.54a12.2,12.2,0,0,1-7.82-3.09,13.17,13.17,0,0,1-4.83-10.33h0a13.31,13.31,0,0,1,4.7-9.43,11.38,11.38,0,0,1,8.77-2.84c6.49.79,10.45,7.4,10.39,13.08-.06,5.2-3.54,11.27-9.28,12.42A10.35,10.35,0,0,1,506.27,138.54Zm-11.65-13.38a12.2,12.2,0,0,0,4.47,9.53,10.73,10.73,0,0,0,8.91,2.68c5.24-1.05,8.43-6.65,8.48-11.45,0-5.25-3.57-11.36-9.52-12.08a10.37,10.37,0,0,0-8,2.61,12.31,12.31,0,0,0-4.35,8.71Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="508.1"
                    cy="125.91"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(348.28 624.38) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M508,139.35q-.4,0-.81,0c-7-.47-12.22-6.87-11.73-14.26h0c.5-7.4,6.58-13,13.54-12.56a12.18,12.18,0,0,1,8.69,4.55,13.8,13.8,0,0,1,3,9.71C520.25,133.87,514.62,139.35,508,139.35Zm-10.54-14.16c-.43,6.3,4,11.74,9.87,12.14s11-4.41,11.4-10.7a11.75,11.75,0,0,0-2.59-8.31,10.21,10.21,0,0,0-7.27-3.83c-5.88-.39-11,4.41-11.41,10.7Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M506.19,126.4l3.58-3.43-4.27-1.77q.15,5.14.05,10.29c-.06,3.22,4.94,3.22,5,0q.1-5.15-.05-10.29a2.53,2.53,0,0,0-4.27-1.77l-3.57,3.44c-2.33,2.23,1.21,5.76,3.53,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <g>
                  <path
                    d="M553.88,184a12.7,12.7,0,0,1-8.15-3.2,13.7,13.7,0,0,1-5-10.72h0a13.83,13.83,0,0,1,4.88-9.8,11.88,11.88,0,0,1,9.15-3c6.77.82,10.89,7.69,10.83,13.58-.06,5.4-3.7,11.71-9.68,12.91A10.65,10.65,0,0,1,553.88,184Zm-11.15-13.85a11.69,11.69,0,0,0,4.3,9.13,10.15,10.15,0,0,0,8.49,2.56c5-1,8-6.35,8.07-11,0-5-3.4-10.88-9.07-11.57a9.81,9.81,0,0,0-7.61,2.49,11.82,11.82,0,0,0-4.18,8.35Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="555.71"
                    cy="170.84"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(347.85 713.79) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M555.62,184.28l-.82,0c-7-.47-12.22-6.87-11.73-14.27h0c.5-7.39,6.58-13,13.54-12.56A12.23,12.23,0,0,1,565.3,162,14,14,0,0,1,564,180.9,12.25,12.25,0,0,1,555.62,184.28Zm-10.55-14.16h0c-.42,6.29,4,11.74,9.87,12.13a10.25,10.25,0,0,0,7.72-2.81,12,12,0,0,0,1.09-16.19,10.21,10.21,0,0,0-7.27-3.83C550.59,159,545.5,163.82,545.07,170.12Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M552,169.56l3.57-3.43q.17,5.15,0,10.29"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M553.8,171.33l3.58-3.43-4.27-1.77q.15,5.15,0,10.29c-.06,3.22,4.94,3.22,5,0q.11-5.14,0-10.29a2.53,2.53,0,0,0-4.27-1.77l-3.57,3.44c-2.33,2.23,1.21,5.76,3.53,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <g>
                  <path
                    d="M442.4,171.07a12.71,12.71,0,0,1-8.15-3.21,13.7,13.7,0,0,1-5-10.72h0a13.85,13.85,0,0,1,4.88-9.8,11.87,11.87,0,0,1,9.15-3c6.77.83,10.89,7.69,10.83,13.58-.06,5.41-3.7,11.71-9.68,12.91A10.59,10.59,0,0,1,442.4,171.07Zm-11.15-13.86a11.69,11.69,0,0,0,4.3,9.13,10.19,10.19,0,0,0,8.49,2.57c5-1,8-6.36,8.07-11,.05-5-3.4-10.88-9.07-11.57a9.85,9.85,0,0,0-7.61,2.49,11.82,11.82,0,0,0-4.18,8.35Z"
                    fill={LINE_COLOR}
                  />
                  <ellipse
                    cx="444.23"
                    cy="157.94"
                    rx="12.44"
                    ry="11.66"
                    transform="translate(256.75 590.53) rotate(-86.14)"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M444.14,171.38l-.82,0a12.2,12.2,0,0,1-8.68-4.55,13.71,13.71,0,0,1-3-9.71h0c.5-7.4,6.58-13,13.53-12.56a12.22,12.22,0,0,1,8.69,4.55A14,14,0,0,1,452.54,168,12.21,12.21,0,0,1,444.14,171.38Zm-10.55-14.16a11.82,11.82,0,0,0,2.59,8.31,10.14,10.14,0,0,0,15,1,12,12,0,0,0,1.09-16.19,10.21,10.21,0,0,0-7.27-3.83c-5.88-.39-11,4.41-11.41,10.7Z"
                    fill={LINE_COLOR}
                  />
                  <path
                    d="M440.56,156.67l3.57-3.44q.17,5.15.05,10.29"
                    fill={BG_COLOR}
                  />
                  <path
                    d="M442.32,158.43,445.9,155l-4.27-1.77q.15,5.15.05,10.29c-.06,3.22,4.94,3.22,5,0q.1-5.15-.05-10.29a2.53,2.53,0,0,0-4.27-1.76l-3.57,3.43c-2.33,2.23,1.21,5.77,3.53,3.53Z"
                    fill={LINE_COLOR}
                  />
                </g>
                <rect
                  x="413.65"
                  y="101.25"
                  width="168.39"
                  height="95.55"
                  fill="none"
                />
              </g>
            </g>
          </g>
          {/*
            
            ///

            Docs on the right
            
            ///
            
            */}
          <g mask="url(#mask-5)">
            <g id="gDocM">
              <g>
                <polygon
                  points="770.6 203.63 825.01 238.89 914.21 215.8 857.96 181.15 770.6 203.63"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,240.51,766.92,203l91.28-23.5L918,216.37Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 198.83 825.01 234.09 914.21 211 857.96 176.34 770.6 198.83"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,235.7l-57.83-37.47,91.28-23.5L918,211.57Zm-50.46-36.27,51,33,85.15-22L857.71,178Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 193.67 825.01 228.93 914.21 205.84 857.96 171.18 770.6 193.67"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,230.54l-57.83-37.47,91.28-23.5L918,206.41Zm-50.46-36.27,51,33,85.15-22-52.71-32.48Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 189.06 825.01 224.31 914.21 201.23 857.96 166.57 770.6 189.06"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,225.93l-57.83-37.48L858.2,165,918,201.8Zm-50.46-36.27,51,33,85.15-22-52.71-32.48Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 184.34 825.01 219.6 914.21 196.51 857.96 161.86 770.6 184.34"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,221.22l-57.83-37.48,91.28-23.49L918,197.08Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 178.91 825.14 214.17 914.34 191.08 858.09 156.43 770.73 178.91"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,215.79l-57.83-37.48,91.28-23.5,59.79,36.84Zm-50.46-36.28,51,33,85.15-22L857.84,158Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 173.75 825.14 209.01 914.34 185.92 858.09 151.27 770.73 173.75"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,210.63l-57.83-37.48,91.28-23.5,59.79,36.84Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 168.27 825.14 203.53 914.34 180.44 858.09 145.78 770.73 168.27"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,205.14l-57.83-37.47,91.28-23.5L918.12,181Zm-50.46-36.27,51,33,85.15-22-52.71-32.48Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 163.11 825.14 198.37 914.34 175.28 858.09 140.62 770.73 163.11"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,200l-57.83-37.47L858.33,139l59.79,36.84Zm-50.46-36.27,51,33,85.15-22-52.71-32.48Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 157.14 825.01 192.4 914.21 169.31 857.96 134.66 770.6 157.14"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,194l-57.83-37.48L858.2,133,918,169.88Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 151.98 825.01 187.24 914.21 164.15 857.96 129.5 770.6 151.98"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,188.86l-57.83-37.48,91.28-23.5L918,164.72Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 146.7 825.01 181.96 914.21 158.87 857.96 124.22 770.6 146.7"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,183.58,766.92,146.1l91.28-23.49L918,159.44ZM774.29,147.3l51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 141.54 825.01 176.8 914.21 153.71 857.96 119.06 770.6 141.54"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,178.42l-57.83-37.48,91.28-23.49L918,154.28Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 136.47 825.14 171.73 914.34 148.64 858.09 113.99 770.73 136.47"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,173.35l-57.83-37.48,91.28-23.49,59.79,36.83Zm-50.46-36.28,51,33,85.15-22L857.84,115.6Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.73 131.31 825.14 166.57 914.34 143.48 858.09 108.83 770.73 131.31"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.88,168.19l-57.83-37.48,91.28-23.49,59.79,36.83Zm-50.46-36.28,51,33,85.15-22-52.71-32.47Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 125.34 825.01 160.6 914.21 137.52 857.96 102.86 770.6 125.34"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,162.22l-57.83-37.48,91.28-23.49L918,138.09ZM774.29,126l51,33,85.15-22-52.71-32.48Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 120.19 825.01 155.44 914.21 132.36 857.96 97.7 770.6 120.19"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,157.06l-57.83-37.47,91.28-23.5L918,132.93Zm-50.46-36.27,51,33,85.15-22L857.71,99.31Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 114.91 825.01 150.16 914.21 127.08 857.96 92.42 770.6 114.91"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,151.78l-57.83-37.47,91.28-23.5L918,127.65Zm-50.46-36.27,51,33,85.15-22L857.71,94Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="770.6 109.75 825.01 145 914.21 121.92 857.96 87.26 770.6 109.75"
                  fill={BG_COLOR}
                />
                <path
                  d="M824.75,146.62l-57.83-37.47,91.28-23.5L918,122.49Zm-50.46-36.27,51,33,85.15-22L857.71,88.87Z"
                  fill={MASKED_COLOR}
                />
                <path
                  d="M865.35,177a61.64,61.64,0,0,0,11.51,3.72A67,67,0,0,0,896.19,182l-1.37-12.47Z"
                  fill={BG_COLOR}
                />
                <path
                  d="M890.89,183.66a67.37,67.37,0,0,1-14.35-1.53,62.23,62.23,0,0,1-11.79-3.81l-4-1.76,35.38-8.94,1.73,15.72-1.55.11C894.49,183.59,892.69,183.66,890.89,183.66Zm-20.64-6.4a59.65,59.65,0,0,0,6.93,1.94,65.12,65.12,0,0,0,17.34,1.37l-1-9.2Z"
                  fill={MASKED_COLOR}
                />
                <polygon
                  points="790.02 175.93 788.64 186.5 809.42 188.18 790.02 175.93"
                  fill={BG_COLOR}
                />
                <path
                  d="M815.37,190.16,787,187.87l1.88-14.47Zm-25-5,13.13,1.06-12.26-7.74Z"
                  fill={MASKED_COLOR}
                />
                <path
                  d="M840.88,226.52c-.16,0,15.9,4.21,15.9,4.21l12.42-11.94Z"
                  fill={BG_COLOR}
                />
                <path
                  d="M857.21,232.39l-.81-.21-7.95-2.07L843,228.66c-3.31-.89-3.58-1-3.59-2.12l0-1.16,35.12-9.59Zm-10.63-5.88c3,.79,6.69,1.76,9.76,2.56l7.58-7.29Z"
                  fill={MASKED_COLOR}
                />
                <path
                  d="M780.39,131.69S777,139.81,777.32,143a52.06,52.06,0,0,0,1.31,7l13.94-10.33Z"
                  fill={BG_COLOR}
                />
                <path
                  d="M777.75,152.44l-.57-2.14a52.69,52.69,0,0,1-1.35-7.21c-.32-3.41,2.81-11.11,3.17-12l.69-1.66L795.2,139.5Zm3.36-18.5c-1.15,3.07-2.45,7.19-2.29,8.87a38.73,38.73,0,0,0,.75,4.54l10.37-7.69Z"
                  fill={MASKED_COLOR}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  #gCloudWheel > *:nth-of-type(1) {
    -webkit-animation: 2s elemRot1_0 forwards infinite;
    animation: 2s elemRot1_0 forwards infinite;
  }

  @-webkit-keyframes elemRot1_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 559.45px 196.8px;
      transform-origin: 559.45px 196.8px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(360deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 559.45px 196.8px;
      transform-origin: 559.45px 196.8px;
    }
  }

  @keyframes elemRot1_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 559.45px 196.8px;
      transform-origin: 559.45px 196.8px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(360deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 559.45px 196.8px;
      transform-origin: 559.45px 196.8px;
    }
  }

  #gCloudWheel > *:nth-of-type(2) {
    -webkit-animation: 1s elemRot2_0 forwards infinite;
    animation: 1s elemRot2_0 forwards infinite;
  }

  @-webkit-keyframes elemRot2_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 459.8px 202.41px;
      transform-origin: 459.8px 202.41px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(360deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 459.8px 202.41px;
      transform-origin: 459.8px 202.41px;
    }
  }

  @keyframes elemRot2_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 459.8px 202.41px;
      transform-origin: 459.8px 202.41px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(360deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 459.8px 202.41px;
      transform-origin: 459.8px 202.41px;
    }
  }

  #gCloudWheel > *:nth-of-type(3) {
    -webkit-animation: 3s elemRot3_0 forwards infinite;
    animation: 3s elemRot3_0 forwards infinite;
  }

  @-webkit-keyframes elemRot3_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 499.78px 139.6px;
      transform-origin: 499.78px 139.6px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(-360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(-360deg) scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 499.78px 139.6px;
      transform-origin: 499.78px 139.6px;
    }
  }

  @keyframes elemRot3_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 499.78px 139.6px;
      transform-origin: 499.78px 139.6px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(-360deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(-360deg) scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 499.78px 139.6px;
      transform-origin: 499.78px 139.6px;
    }
  }

  #gCloud {
    -webkit-animation: 1s elemMove_0 forwards infinite alternate -1s;
    animation: 1s elemMove_0 forwards infinite alternate -1s;
  }

  #gDocM,
  #gFileM,
  #gDocW,
  #gFile {
    -webkit-animation: 1s elemMove_0 forwards infinite alternate;
    animation: 1s elemMove_0 forwards infinite alternate;
  }

  @-webkit-keyframes elemMove_0 {
    0% {
      -webkit-animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 78px 41.24px;
      transform-origin: 78px 41.24px;
    }

    100% {
      -webkit-animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      -webkit-transform: translate(0px, 10px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 10px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 78px 41.24px;
      transform-origin: 78px 41.24px;
    }
  }

  @keyframes elemMove_0 {
    0% {
      -webkit-animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 78px 41.24px;
      transform-origin: 78px 41.24px;
    }

    100% {
      -webkit-animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      animation-timing-function: cubic-bezier(0.354, 0, 0.65, 1);
      -webkit-transform: translate(0px, 10px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 10px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 78px 41.24px;
      transform-origin: 78px 41.24px;
    }
  }

  #gInfo > *:nth-of-type(1) {
    -webkit-animation: 1.5s gInfo_0 forwards infinite;
    animation: 1.5s gInfo_0 forwards infinite;
  }

  #gInfo > *:nth-of-type(2) {
    -webkit-animation: 1.4s gInfo_0 forwards infinite;
    animation: 1.4s gInfo_0 forwards infinite;
  }

  #gInfo > *:nth-of-type(3) {
    -webkit-animation: 1.6s gInfo_0 forwards infinite;
    animation: 1.6s gInfo_0 forwards infinite;
  }

  #gInfo > *:nth-of-type(4) {
    -webkit-animation: 1.5s gInfo_0 forwards infinite;
    animation: 1.5s gInfo_0 forwards infinite;
  }

  @-webkit-keyframes gInfo_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 1.39px 6.55px;
      transform-origin: 1.39px 6.55px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(212px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(212px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 1.39px 6.55px;
      transform-origin: 1.39px 6.55px;
    }
  }

  @keyframes gInfo_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 1.39px 6.55px;
      transform-origin: 1.39px 6.55px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(212px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(212px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 1.39px 6.55px;
      transform-origin: 1.39px 6.55px;
    }
  }

  #gKilk {
    -webkit-animation: 1.5s gKilk_0 forwards infinite;
    animation: 1.5s gKilk_0 forwards infinite;
  }

  @-webkit-keyframes gKilk_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 413.65px 101.25px;
      transform-origin: 413.65px 101.25px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(168px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(168px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 413.65px 101.25px;
      transform-origin: 413.65px 101.25px;
    }
  }

  @keyframes gKilk_0 {
    0% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 413.65px 101.25px;
      transform-origin: 413.65px 101.25px;
    }

    100% {
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-transform: translate(168px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(168px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
      -webkit-transform-origin: 413.65px 101.25px;
      transform-origin: 413.65px 101.25px;
    }
  }

  #gMoney > *:nth-of-type(1) {
    -webkit-animation: 1.8s gMoney_0 forwards infinite linear -2s;
    animation: 1.8s gMoney_0 forwards infinite linear -2s;
  }

  #gMoney > *:nth-of-type(2) {
    -webkit-animation: 2s gMoney_0 forwards infinite linear -1.5s;
    animation: 2s gMoney_0 forwards infinite linear -1.5s;
  }

  #gMoney > *:nth-of-type(3) {
    -webkit-animation: 2.2s gMoney_0 forwards infinite linear -1s;
    animation: 2.2s gMoney_0 forwards infinite linear -1s;
  }

  #gMoney > *:nth-of-type(4) {
    -webkit-animation: 2.1s gMoney_0 forwards infinite linear -0.5s;
    animation: 2.1s gMoney_0 forwards infinite linear -0.5s;
  }

  #gMoney > *:nth-of-type(5) {
    -webkit-animation: 1.9s gMoney_0 forwards infinite linear 0s;
    animation: 1.9s gMoney_0 forwards infinite linear 0s;
  }

  @-webkit-keyframes gMoney_0 {
    0% {
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
    }

    100% {
      -webkit-transform: translate(149px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(149px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
    }
  }

  @keyframes gMoney_0 {
    0% {
      -webkit-transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
    }

    100% {
      -webkit-transform: translate(149px, 0px) rotateX(0deg) rotateY(0deg)
        rotateZ(0deg) scale(1, 1) skew(0deg, 0deg);
      transform: translate(149px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
        scale(1, 1) skew(0deg, 0deg);
    }
  }

  /***************/
  #gDoc > * {
    opacity: 0.5;
  }

  #gDoc > *:nth-of-type(1) {
    -webkit-animation: 3.1s animDoc_0 forwards infinite;
    animation: 3.1s animDoc_0 forwards infinite;
  }

  @-webkit-keyframes animDoc_0 {
    0% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    9.677%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_0 {
    0% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    9.677%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(2) {
    -webkit-animation: 3.1s animDoc_1 forwards infinite;
    animation: 3.1s animDoc_1 forwards infinite;
  }

  @-webkit-keyframes animDoc_1 {
    0%,
    9.677% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    19.355%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_1 {
    0%,
    9.677% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    19.355%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(3) {
    -webkit-animation: 3.1s animDoc_2 forwards infinite;
    animation: 3.1s animDoc_2 forwards infinite;
  }

  @-webkit-keyframes animDoc_2 {
    0%,
    19.355% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    29.032%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_2 {
    0%,
    19.355% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    29.032%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(4) {
    -webkit-animation: 3.1s animDoc_3 forwards infinite;
    animation: 3.1s animDoc_3 forwards infinite;
  }

  @-webkit-keyframes animDoc_3 {
    0%,
    29.032% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    38.71%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_3 {
    0%,
    29.032% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    38.71%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(5) {
    -webkit-animation: 3.1s animDoc_4 forwards infinite;
    animation: 3.1s animDoc_4 forwards infinite;
  }

  @-webkit-keyframes animDoc_4 {
    0%,
    38.71% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    48.387%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_4 {
    0%,
    38.71% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    48.387%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(6) {
    -webkit-animation: 3.1s animDoc_5 forwards infinite;
    animation: 3.1s animDoc_5 forwards infinite;
  }

  @-webkit-keyframes animDoc_5 {
    0%,
    48.387% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    58.065%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_5 {
    0%,
    48.387% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    58.065%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc > *:nth-of-type(7) {
    -webkit-animation: 3.1s animDoc_6 forwards infinite;
    animation: 3.1s animDoc_6 forwards infinite;
  }

  @-webkit-keyframes animDoc_6 {
    0%,
    58.065% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    67.742%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_6 {
    0%,
    58.065% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    67.742%,
    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gDoc {
    -webkit-animation: 3.1s animDoc_7 forwards infinite;
    animation: 3.1s animDoc_7 forwards infinite;
  }

  @-webkit-keyframes animDoc_7 {
    0%,
    83.871% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    100% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animDoc_7 {
    0%,
    83.871% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    100% {
      opacity: 0.5;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(1) {
    -webkit-animation: 3.6s animFile_0 forwards infinite;
    animation: 3.6s animFile_0 forwards infinite;
  }

  @-webkit-keyframes animFile_0 {
    0%,
    13.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    22.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_0 {
    0%,
    13.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    22.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(2) {
    -webkit-animation: 3.6s animFile_1 forwards infinite;
    animation: 3.6s animFile_1 forwards infinite;
  }

  @-webkit-keyframes animFile_1 {
    0%,
    22.222% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    30.556%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_1 {
    0%,
    22.222% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    30.556%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(3) {
    -webkit-animation: 3.6s animFile_2 forwards infinite;
    animation: 3.6s animFile_2 forwards infinite;
  }

  @-webkit-keyframes animFile_2 {
    0%,
    30.556% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    38.889%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_2 {
    0%,
    30.556% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    38.889%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(4) {
    -webkit-animation: 3.6s animFile_3 forwards infinite;
    animation: 3.6s animFile_3 forwards infinite;
  }

  @-webkit-keyframes animFile_3 {
    0%,
    38.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    47.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_3 {
    0%,
    38.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    47.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(5) {
    -webkit-animation: 3.6s animFile_4 forwards infinite;
    animation: 3.6s animFile_4 forwards infinite;
  }

  @-webkit-keyframes animFile_4 {
    0%,
    47.222% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    55.556%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_4 {
    0%,
    47.222% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    55.556%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(6) {
    -webkit-animation: 3.6s animFile_5 forwards infinite;
    animation: 3.6s animFile_5 forwards infinite;
  }

  @-webkit-keyframes animFile_5 {
    0%,
    55.556% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    63.889%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_5 {
    0%,
    55.556% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    63.889%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > *:nth-of-type(7) {
    -webkit-animation: 3.6s animFile_6 forwards infinite;
    animation: 3.6s animFile_6 forwards infinite;
  }

  @-webkit-keyframes animFile_6 {
    0%,
    63.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    72.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_6 {
    0%,
    63.889% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    72.222%,
    100% {
      opacity: 0.2;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  #gFile > * {
    -webkit-animation: 3.6s animFile_7 forwards infinite;
    animation: 3.6s animFile_7 forwards infinite;
  }

  @-webkit-keyframes animFile_7 {
    0%,
    86.111% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  @keyframes animFile_7 {
    0%,
    86.111% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }

    100% {
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }
`;
