export const billingMeasures = {
  absoluteCredits: "absoluteCredits",
  amortizedCost: "amortizedCost",
  cost: "cost",
  customNetCost: "customNetCost",
  indirectCost: "indirectCost",
  netAmortizedCost: "netAmortizedCost",
  netCost: "netCost",
  usageAmount: "usageAmount",
  usageAmountInPricingUnits: "usageAmountInPricingUnits",
} as const;

export const billingDimensions = {
  ancestryIDs: "ancestryIds",
  ancestryNames: "ancestryNames",
  billingAccountID: "billingAccountId",
  billingAccountName: "billingAccountName",
  category: "category",
  cloudID: "cloudId",
  cloudName: "cloudName",
  commitmentPaymentModel: "commitmentPaymentModel",
  customReason: "customReason",
  databaseEngine: "databaseEngine",
  dataSource: "dataSource",
  invoiceMonth: "invoiceMonth",
  location: "location",
  operatingSystem: "operatingSystem",
  parentID: "parentId",
  parentName: "parentName",
  pricingModel: "pricingModel",
  pricingUnit: "pricingUnit",
  projectID: "projectId",
  projectName: "projectName",
  projectNumber: "projectNumber",
  region: "region",
  serviceDescription: "serviceDescription",
  serviceID: "serviceId",
  skuDescription: "skuDescription",
  skuID: "skuId",
  tenancy: "tenancy",
  usageUnit: "usageUnit",
  vendor: "vendor",
  zone: "zone",
} as const;
