import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import {
  DataSource,
  DurationType,
  Operator,
} from "@ternary/api-lib/constants/enums";
import useGetAWSComputeSpendSummaries from "../../../features/insights/aws-compute/hooks/useGetAWSComputeSpendSummaries";
import useGetAWSDatabaseUsageSummary from "../../../features/insights/aws-database/hooks/useGetAWSDatabaseUsageSummary";
import useGetComputeSpendSummary from "../../../features/insights/hooks/useGetComputeSpendSummary";
import useGetCostSpendSummaries from "../../../features/insights/hooks/useGetCostSpendSummaries";
import useGetDatabaseSpendSummaries from "../../../features/insights/hooks/useGetDatabaseSpendSummaries";
import useGetKubernetesSpendSummaries from "../../../features/insights/hooks/useGetKubernetesSpendSummaries";
import useGetStorageSpendSummaries from "../../../features/insights/hooks/useGetStorageSpendSummaries";
import useGatekeeper from "../../../hooks/useGatekeeper";
import useGetRawData from "../../analytics/useGetRawData";
import useGetSpendSummaries from "../../analytics/useGetSpendSummaries";

const CACHE_TIME = 1000 * 60 * 60; // 1 hour

export default function usePrefetchHomeContainer() {
  const gatekeeper = useGatekeeper();

  const defaultDateRange = getCubeDateRangeFromDurationType(
    DurationType.LAST_THIRTY_DAYS
  );

  //
  //   ComputeInsightsLandingPage
  //

  useGetComputeSpendSummary(
    { dataSource: DataSource.GCP_COMPUTE_VISIBILITY },
    { gcTime: CACHE_TIME }
  );

  useGetComputeSpendSummary(
    { dataSource: DataSource.AWS_COMPUTE_VISIBILITY },
    { gcTime: CACHE_TIME }
  );

  useGetComputeSpendSummary(
    { dataSource: DataSource.AZURE_COMPUTE_VISIBILITY },
    { gcTime: CACHE_TIME }
  );

  //
  // AWS Compute Container
  //

  useGetRawData(
    {
      dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
      dateRange: defaultDateRange,
      dimensions: ["instanceType"],
      granularity: "DAY",
      measures: ["cost"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasAWSIntegration }
  );

  useGetAWSComputeSpendSummaries({}, { gcTime: CACHE_TIME });

  useGetRawData(
    {
      dataSource: DataSource.AWS_COMPUTE_VISIBILITY,
      dateRange: defaultDateRange,
      granularity: "DAY",
      measures: ["productVCPUChart"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasAWSIntegration }
  );

  //
  // GCP Compute Container
  //

  useGetRawData(
    {
      dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
      dateRange: defaultDateRange,
      dimensions: ["category"],
      queryFilters: [
        {
          name: "category",
          operator: Operator.NOT_EQUALS,
          values: ["null"],
        },
      ],
      granularity: "DAY",
      measures: ["cost"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasGCPIntegration }
  );

  useGetComputeSpendSummary({}, { gcTime: CACHE_TIME });

  useGetRawData(
    {
      dataSource: DataSource.GCP_COMPUTE_VISIBILITY,
      dateRange: defaultDateRange,
      granularity: "DAY",
      measures: ["productVCPUChart"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasGCPIntegration }
  );

  useGetCostSpendSummaries(
    { dataSource: DataSource.CLOUD_RUN },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasGCPIntegration }
  );

  //
  //   DatabaseInsightsLandingPage
  //

  useGetDatabaseSpendSummaries(
    { dataSource: DataSource.CLOUD_SQL_COST },
    { gcTime: CACHE_TIME }
  );

  useGetDatabaseSpendSummaries(
    { dataSource: DataSource.AWS_DATABASE_VISIBILITY },
    { gcTime: CACHE_TIME }
  );

  useGetDatabaseSpendSummaries(
    { dataSource: DataSource.AZURE_SQL_VISIBILITY },
    { gcTime: CACHE_TIME }
  );

  //
  // AWSDatabaseVisibilityContainer
  //

  useGetAWSDatabaseUsageSummary();

  useGetRawData(
    {
      dataSource: DataSource.AWS_DATABASE_VISIBILITY,
      dateRange: defaultDateRange,
      dimensions: ["lineItemUsageAccountId"],
      granularity: "DAY",
      measures: ["cost"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasAWSIntegration }
  );

  useGetRawData(
    {
      dataSource: DataSource.AWS_DATABASE_VISIBILITY,
      dateRange: defaultDateRange,
      granularity: "DAY",
      measures: ["usedVCPUChart", "productVCPUChart"],
    },
    { gcTime: CACHE_TIME, enabled: gatekeeper.hasAWSIntegration }
  );

  //
  // DataWarehouseInsightsLandingPage
  //
  useGetSpendSummaries(
    {
      dataSource: DataSource.BILLING,
      queryFilters: [
        {
          name: "serviceDescription",
          operator: Operator.EQUALS,
          values: ["BigQuery", "BigQuery Reservation API"],
        },
      ],
    },
    { gcTime: CACHE_TIME }
  );

  useGetSpendSummaries(
    {
      dataSource: DataSource.BILLING,
      queryFilters: [
        {
          name: "vendor",
          operator: Operator.EQUALS,
          values: ["Snowflake"],
        },
        {
          name: "skuDescription",
          operator: Operator.CONTAINS,
          values: ["compute", "cloud services"],
        },
      ],
    },
    { gcTime: CACHE_TIME }
  );

  //
  // KubernetesInsightsLandingPage
  //

  useGetKubernetesSpendSummaries(
    { dataSource: DataSource.KUBERNETES_CLUSTER_USAGE },
    { gcTime: CACHE_TIME }
  );

  useGetKubernetesSpendSummaries(
    { dataSource: DataSource.AWS_KUBERNETES_NODE_COST },
    { gcTime: CACHE_TIME }
  );

  //
  // StorageInsightsLandingPage
  //

  useGetStorageSpendSummaries(
    {
      dataSource: DataSource.BILLING,
      queryFilters: [
        {
          name: "serviceDescription",
          operator: Operator.EQUALS,
          values: ["Cloud Storage"],
        },
      ],
      measures: ["cost"],
    },
    { gcTime: CACHE_TIME }
  );

  useGetStorageSpendSummaries(
    {
      dataSource: DataSource.AWS_STORAGE_VISIBILITY,
    },
    { gcTime: CACHE_TIME }
  );

  useGetStorageSpendSummaries(
    {
      dataSource: DataSource.AWS_EBS_VISIBILITY,
    },
    { gcTime: CACHE_TIME }
  );
}
