import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { BinaryFilter, UnaryFilter } from "@ternary/api-lib/analytics/types";
import {
  DataSource,
  GcpCommitmentServiceType,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  CudInventoryCommitmentDatum,
  CudResourceInventoryCommitmentDatum,
  CudSpendInventoryCommitmentDatum,
  CudBigQueryInventoryCommitmentDatum,
} from "../types";

export interface Params {
  queryFilters?: (UnaryFilter | BinaryFilter)[];
}

export default function useGetCudInventoryCommitmentData(
  params: Params,
  options?: UseQueryOptions<CudInventoryCommitmentDatum[], UError>
): UseQueryResult<CudInventoryCommitmentDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudInventoryCommitmentData", params],
    queryFn: async () => {
      const now = new DateHelper();

      const spendCommitmentFilters = params.queryFilters?.filter((filter) => {
        return filter.name !== "family";
      });

      const resourceCommitmentFilters = params.queryFilters?.filter(
        (filter) => {
          return filter.name !== "service";
        }
      );

      const spendResult = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_COMPUTE_CUD_SPEND_INVENTORY,
          dimensions: ["billingAccountId", "plan", "service"],
          measures: ["hourlyCommittedAmount"],
          queryFilters: spendCommitmentFilters,
        })
      )) as CudSpendInventoryCommitmentDatum[];

      const spendData: CudInventoryCommitmentDatum[] = spendResult.map(
        (datum) => {
          return {
            commitmentEndTimestamp: null,
            commitmentId: null,
            commitmentLength: datum.plan,
            family: null,
            hourlyCommittedAmount: datum.hourlyCommittedAmount,
            owner: datum.billingAccountId,
            region: datum.region,
            reservedMemoryGiB: null,
            reservedVCPU: null,
            reservedSlots: null,
            service: datum.service,
            status: null,
          };
        }
      );

      const resourceResult = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_COMPUTE_CUD_INVENTORY,
          dimensions: [
            "commitmentEndTimestamp",
            "commitmentId",
            "commitmentPlan",
            "commitmentStatus",
            "family",
            "projectId",
            "region",
          ],
          measures: ["resourceAmountRAM", "resourceAmountVCPU"],
          queryFilters: resourceCommitmentFilters,
        })
      )) as CudResourceInventoryCommitmentDatum[];

      const resourceData: CudInventoryCommitmentDatum[] = resourceResult.map(
        (datum) => {
          return {
            commitmentEndTimestamp: datum.commitmentEndTimestamp,
            commitmentId: datum.commitmentId,
            commitmentLength: datum.commitmentPlan,
            family: datum.family,
            hourlyCommittedAmount: null,
            owner: datum.projectId,
            region: datum.region,
            reservedMemoryGiB: datum.resourceAmountRAM,
            reservedVCPU: datum.resourceAmountVCPU,
            reservedSlots: null,
            service: GcpCommitmentServiceType.COMPUTE_ENGINE, // All resource-based CUDs are compute engine. Dimension is not in big query.
            status: datum.commitmentStatus,
          };
        }
      );

      const bigqueryCommitmentResult = (await client.load(
        buildCubeQuery({
          dateRange: [now.nMonthsAgo(36), now.date],
          dataSource: DataSource.GCP_BIGQUERY_COMMITMENT_INVENTORY,
          dimensions: [
            "commitmentLength",
            "commitmentEndTime",
            "capacityCommitmentId",
            "state",
            "adminProjectId",
            "region",
            "edition",
          ],
          measures: ["slotCount"],
        })
      )) as CudBigQueryInventoryCommitmentDatum[];

      const bigqueryCommitmentData: CudInventoryCommitmentDatum[] =
        bigqueryCommitmentResult.map((datum) => {
          return {
            commitmentEndTimestamp: datum.commitmentEndTime,
            commitmentId: datum.capacityCommitmentId,
            commitmentLength: datum.commitmentLength,
            family: null,
            hourlyCommittedAmount: null,
            owner: datum.adminProjectId,
            region: datum.region,
            reservedMemoryGiB: null,
            reservedVCPU: null,
            reservedSlots: datum.slotCount,
            service: datum.edition.includes("PLUS")
              ? GcpCommitmentServiceType.BIGQUERY_ENTERPRISE_PLUS
              : GcpCommitmentServiceType.BIGQUERY_ENTERPRISE,
            status: datum.state,
          };
        });

      return [...spendData, ...resourceData, ...bigqueryCommitmentData];
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
