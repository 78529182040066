import { buildCubeQuery } from "@/api/analytics/utils";
import { validate } from "@/api/analytics/utils/Cubestruct";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQueries } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, DurationType } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  SnowflakeCreditSummaryEntity,
  SnowflakeSpendSummaryStruct,
} from "../types";

export interface Params {
  dataSource: DataSource;
  queryFilters?: QueryFilter[];
}

type UseQueriesOptions = [
  [SnowflakeCreditSummaryEntity, UError],
  [SnowflakeCreditSummaryEntity, UError],
];

export default function useGetSnowflakeCreditSummaries(
  params: Params,
  options?: UseQueryOptions<SnowflakeCreditSummaryEntity, UError>
): UseQueryResult<SnowflakeCreditSummaryEntity, UError>[] {
  const client = useAnalyticsApiClient();
  const dateHelper = new DateHelper();

  const measures = ["totalCreditsUsed"];

  return useQueries<UseQueriesOptions>({
    queries: [
      // This MTD
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: "This month",
              durationType: DurationType.MONTH_TO_DATE,
              measures,
            })
          );

          const [error, data] = validate(
            result[0],
            SnowflakeSpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_SNOWFLAKE_COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCreditsUsed: data.totalCreditsUsed ?? 0,
          };
        },
        queryKey: ["snowflakeSpendSummary", "currentMTD", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
      // Last Month Full
      {
        queryFn: async () => {
          const result = await client.load(
            buildCubeQuery({
              ...params,
              dataSource: params.dataSource,
              dateRange: [
                dateHelper.firstOfLastMonth(),
                dateHelper.lastDayLastMonth(),
              ],
              measures,
            })
          );

          const [error, data] = validate(
            result[0],
            SnowflakeSpendSummaryStruct
          );

          if (error) {
            throw new UError("INVALID_SNOWFLAKE__COST_SUMMARY", {
              context: { error, result: data },
            });
          }

          return {
            totalCreditsUsed: data.totalCreditsUsed ?? 0,
          };
        },
        queryKey: ["snowflakeSpendSummary", "lastMonthFull", params],
        gcTime: ANALYTICS_QUERY_GC_TIME,
        ...options,
      },
    ],
  });
}
