import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { budgetID: string }
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { budgetID: string }
>;

export default function useDeleteBudget(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ budgetID }) => client.deleteBudget(budgetID),
    ...options,
  });
}
