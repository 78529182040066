import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import {
  DataSource,
  GcpCommitmentDurationType,
} from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { CUDInventoryEntity, GcpCommitmentStatusType } from "../types";

type CudInventoryDatum = {
  commitmentId: string;
  commitmentEndTimestamp: string;
  commitmentPlan: GcpCommitmentDurationType;
  commitmentStartTimestamp: string;
  commitmentStatus: GcpCommitmentStatusType;
  projectId: string;
  resourceAmountVCPU: number | null;
  resourceAmountRAM: number | null;
};

const GCPComputeCUDInventoryDimensions = [
  "commitmentId",
  "commitmentEndTimestamp",
  "commitmentPlan",
  "commitmentStartTimestamp",
  "commitmentStatus",
  "projectId",
];

const GCPComputeCUDInventoryMeasures = [
  "resourceAmountVCPU",
  "resourceAmountRAM",
];

export interface Params {
  queryFilters?: QueryFilter[];
}

export default function useGetCUDLegacyInventoryData(
  params: Params,
  options?: UseQueryOptions<CUDInventoryEntity[], UError>
): UseQueryResult<CUDInventoryEntity[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["cudLegacyInventoryData", params],
    queryFn: async () => {
      const now = new DateHelper();

      const result = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.GCP_COMPUTE_CUD_INVENTORY,
          dateRange: [now.nMonthsAgo(36), now.date],
          measures: GCPComputeCUDInventoryMeasures,
          dimensions: GCPComputeCUDInventoryDimensions,
          queryFilters: params.queryFilters,
        })
      )) as CudInventoryDatum[];

      const data = result.map((datum) => {
        return {
          commitmentId: datum.commitmentId,
          commitmentEndTimestamp: datum.commitmentEndTimestamp,
          commitmentPlan: datum.commitmentPlan,
          commitmentStartTimestamp: datum.commitmentStartTimestamp,
          commitmentStatus: datum.commitmentStatus,
          projectId: datum.projectId,
          resourceAmountVCPU: datum.resourceAmountVCPU ?? 0,
          resourceAmountRAM: datum.resourceAmountRAM ?? 0,
        };
      });

      return data;
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
