import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

export default function PieLoader() {
  const theme = useTheme();

  return (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 100 100"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <circle cx="50" cy="40" r="35" />
    </ContentLoader>
  );
}
