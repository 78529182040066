import { RawData } from "@ternary/api-lib/analytics/types";
import { groupBy } from "lodash";
import { useMemo } from "react";
import UError from "unilib-error";
import {
  nullable,
  number,
  optional,
  string,
  validateAll,
} from "../../../../api/analytics/utils/Cubestruct";
import {
  GCPKubernetesResourceEntity,
  GCPKubernetesResourceType,
} from "../types";

const KubernetesResourceStruct = {
  clusterName: optional(nullable(string())),
  namespace: optional(nullable(string())),
  projectId: optional(nullable(string())),
  CPUCoreUsageTime: optional(nullable(number())),
  requestCPUCores: optional(nullable(number())),
  requestCPUCost: optional(nullable(number())),
  requestMemoryBytes: optional(nullable(number())),
  requestMemoryCost: optional(nullable(number())),
  totalCost: optional(nullable(number())),
  totalCPUCores: optional(nullable(number())),
  totalCPUCost: optional(nullable(number())),
  totalMemoryBytes: optional(nullable(number())),
  totalMemoryCost: optional(nullable(number())),
  totalRequestCost: optional(nullable(number())),
  usedCPUCores: optional(nullable(number())),
  usedMemoryBytes: optional(nullable(number())),
  workload: optional(nullable(string())),
  timestamp: optional(nullable(string())),
};

interface KubernetesResourceData {
  clusterName?: string;
  namespace?: string;
  projectID?: string;
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  usedBytes: number;
  usedCores: number;
  workload?: string;
}

export interface Options {
  resourceType: string;
  dayCount: number;
}

export default function useGetGCPKubernetesResourcesFromRawData(
  rawData: RawData[] | undefined,
  options: Options
): GCPKubernetesResourceEntity[] {
  return useMemo(() => {
    if (!rawData) return [];

    const [error, validatedData] = validateAll(
      rawData,
      KubernetesResourceStruct
    );

    if (error) {
      console.error(
        new UError("INVALID_KUBERNETES_RESOURCE", { context: error.context })
      );

      return [];
    }

    const transformedData: KubernetesResourceData[] = validatedData.map(
      (resource) => ({
        ...(resource.clusterName ? { clusterName: resource.clusterName } : {}),
        ...(resource.namespace ? { namespace: resource.namespace } : {}),
        ...(resource.projectId ? { projectID: resource.projectId } : {}),
        totalBytes:
          resource.requestMemoryBytes ?? resource.totalMemoryBytes ?? 0,
        totalBytesCost:
          resource.requestMemoryCost ?? resource.totalMemoryCost ?? 0,
        totalCores: resource.requestCPUCores ?? resource.totalCPUCores ?? 0,
        totalCoresCost: resource.requestCPUCost ?? resource.totalCPUCost ?? 0,
        usedBytes: resource.usedMemoryBytes ?? 0,
        usedCores: resource.usedCPUCores ?? resource.CPUCoreUsageTime ?? 0,
        ...(resource.workload ? { workload: resource.workload } : {}),
      })
    );

    const resources: GCPKubernetesResourceEntity[] = getResources(
      transformedData,
      options.dayCount,
      options.resourceType
    );

    return resources;
  }, [rawData]);
}

interface Resource {
  id: string;
  clusterName?: string;
  name: string;
  namespace?: string;
  projectID: string;
  totalBytes: number;
  totalBytesCost: number;
  totalCores: number;
  totalCoresCost: number;
  usedBytes: number;
  usedCores: number;
}

function getResources(
  data: KubernetesResourceData[],
  dayCount: number,
  resourceType: string
) {
  const resources = getResourcesFromData(data, dayCount, resourceType);

  return resources.map((resource) => {
    const cpuUtil = (resource.usedCores / resource.totalCores) * 100;
    const memoryUtil = (resource.usedBytes / resource.totalBytes) * 100;

    const waste = getTotalWaste({
      item: resource.name,
      cpuUtil,
      coresCost: resource.totalCoresCost,
      memoryUtil,
      bytesCost: resource.totalBytesCost,
    });

    return {
      id: resource.id,
      name: resource.name,
      projectID: resource.projectID,
      cpuUtilization: isFinite(cpuUtil) ? cpuUtil : 0,
      memoryUtilization: isFinite(memoryUtil) ? memoryUtil : 0,
      totalBytes: resource.totalBytes,
      totalBytesCost: resource.totalBytesCost,
      totalCores: resource.totalCores,
      totalCoresCost: resource.totalCoresCost,
      usedBytes: resource.usedBytes,
      usedCores: resource.usedCores,
      waste: waste,
    };
  });
}

function getResourcesFromData(
  data: KubernetesResourceData[],
  dayCount: number,
  resourceType: string
): Resource[] {
  const resourceIDOrder = data.map((datum) => getResourceID(datum));
  const groupedByID = groupBy(data, (datum) => getResourceID(datum));

  const resources = resourceIDOrder.map((resourceID) => {
    const resourceIDGroup = groupedByID[resourceID];
    const mappedResources: Resource[] = resourceIDGroup.map((datum) => ({
      id: resourceID,
      name:
        (resourceType === GCPKubernetesResourceType.CLUSTER
          ? datum.clusterName
          : resourceType === GCPKubernetesResourceType.NAMESPACE
            ? datum.namespace
            : datum.workload) ?? "",
      projectID: datum.projectID ?? "",
      totalBytes: datum.totalBytes ?? 0,
      totalBytesCost: datum.totalBytesCost ?? 0,
      totalCores: datum.totalCores ?? 0,
      totalCoresCost: datum.totalCoresCost ?? 0,
      usedBytes: datum.usedBytes ?? 0,
      usedCores: datum.usedCores ?? 0,
    }));

    let mergedGroup = mappedResources[0];

    for (let i = 1; i < mappedResources.length; i++) {
      const otherResource = mappedResources[i];
      mergedGroup = {
        ...mergedGroup,
        totalBytes: mergedGroup.totalBytes + otherResource.totalBytes,
        totalBytesCost:
          mergedGroup.totalBytesCost + otherResource.totalBytesCost,
        totalCores: mergedGroup.totalCores + otherResource.totalCores,
        totalCoresCost:
          mergedGroup.totalCoresCost + otherResource.totalCoresCost,
        usedBytes: mergedGroup.usedBytes + otherResource.usedBytes,
        usedCores: mergedGroup.usedCores + otherResource.usedCores,
      };
    }

    return mergedGroup;
  });

  return resources.map((resource) => ({
    id: resource.id,
    name: resource.name,
    projectID: resource.projectID,
    totalBytes: resource.totalBytes / dayCount,
    totalBytesCost: resource.totalBytesCost,
    totalCores: resource.totalCores / dayCount,
    totalCoresCost: resource.totalCoresCost,
    usedCores: resource.usedCores / dayCount,
    usedBytes: resource.usedBytes / dayCount,
  }));
}

// Helper functions

function getResourceID(resource: KubernetesResourceData): string {
  return `${resource.workload ?? ""}/${resource.namespace ?? ""}/${
    resource.clusterName ?? ""
  }/${resource.projectID ?? ""}`;
}

function getTotalWaste(usageCost: {
  item: string;
  cpuUtil: number;
  coresCost: number;
  memoryUtil: number;
  bytesCost: number;
}): number {
  const { coresCost, bytesCost } = usageCost;
  let { cpuUtil, memoryUtil } = usageCost;
  cpuUtil = cpuUtil / 100;
  memoryUtil = memoryUtil / 100;
  let cpuWaste = 0;
  let memoryWaste = 0;

  if (cpuUtil <= 1) {
    cpuWaste = (1 - cpuUtil) * coresCost;
  } else if (cpuUtil > 1) {
    cpuWaste = -(cpuUtil - 1) * coresCost;
  }

  if (memoryUtil <= 1) {
    memoryWaste = (1 - memoryUtil) * bytesCost;
  } else if (memoryUtil > 1) {
    memoryWaste = -(memoryUtil - 1) * bytesCost;
  }
  return cpuWaste + memoryWaste;
}
