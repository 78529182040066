import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { SortingFn, createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { noop } from "lodash";
import React, { useMemo } from "react";
import useRefFn from "../../../../hooks/useRefFn";
import useRefIfEqual from "../../../../hooks/useRefIfEqual";
import copyText from "../../copyText";
import { AWSCommitmentAllocationDatum } from "../types";

type Props = {
  allocations: AWSCommitmentAllocationDatum[];
  isLoading: boolean;
  visibleColumns: string[];
  pageSize?: number;
  onAddFilter?: (
    filterKey: keyof AWSCommitmentAllocationDatum,
    value: string | null
  ) => void;
  onSelectAllocation?: (allocation: AWSCommitmentAllocationDatum) => void;
  onChangePage?: (allocations: AWSCommitmentAllocationDatum[]) => void;
};

type TableData = AWSCommitmentAllocationDatum & {
  percentOfCommitments: number;
};

export function getTableData(
  inventory: AWSCommitmentAllocationDatum[]
): TableData[] {
  let totalEffectiveCosts = 0;

  inventory.forEach((datum) => {
    totalEffectiveCosts += datum.effectiveCost;
  });

  return inventory.map((datum) => ({
    ...datum,
    percentOfCommitments: totalEffectiveCosts
      ? datum.effectiveCost / totalEffectiveCosts
      : totalEffectiveCosts,
  }));
}

const columnHelper = createColumnHelper<TableData>();

export default function AWSAllocationTable(props: Props) {
  const theme = useTheme();
  const tableData = useMemo(
    () => getTableData(props.allocations),
    [props.allocations]
  );
  const handleAddFilter = useRefFn(props.onAddFilter ?? noop);
  const handleSelectAllocation = useRefFn(props.onSelectAllocation ?? noop);
  const visibleColumns = useRefIfEqual(props.visibleColumns);
  const canFilter = !!props.onAddFilter;

  const columns = useMemo(
    () =>
      [
        columnHelper.display({
          id: "select",
          cell: ({ row }) => (
            <Button
              iconStart={<Icon icon={faTableList} />}
              primary
              size="tiny"
              onClick={() => handleSelectAllocation(row.original)}
            />
          ),
          size: 48,
        }),
        columnHelper.accessor("commitmentARN", {
          id: "commitmentARN",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("commitmentARN", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.commitmentARN,
          size: 240,
        }),
        columnHelper.accessor("commitmentType", {
          id: "commitmentType",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("commitmentType", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.commitmentType,
          size: 210,
        }),
        columnHelper.accessor("instanceId", {
          id: "instanceId",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("instanceId", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.instanceId,
          size: 210,
        }),
        columnHelper.accessor("billPayerAccountId", {
          id: "billPayerAccountId",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("billPayerAccountId", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.billPayerAccountId,
          size: 210,
        }),
        columnHelper.accessor("lineItemUsageAccountId", {
          id: "lineItemUsageAccountId",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("lineItemUsageAccountId", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.lineItemUsageAccountId,
          size: 210,
        }),
        columnHelper.accessor("countDistinctARN", {
          id: "countDistinctARN",
          cell: ({ getValue }) => <>{formatNumber(getValue())}</>,
          header: columnLabels.countDistinctARN,
          meta: { align: "center" },
          size: 130,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("coverageType", {
          id: "coverageType",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("coverageType", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.coverageType,
          size: 210,
        }),
        columnHelper.accessor("region", {
          id: "region",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() => handleAddFilter("region", getValue() || null)}
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.region,
          size: 210,
        }),
        columnHelper.accessor("offeringType", {
          id: "offeringType",
          cell: ({ getValue }) =>
            getValue() ? (
              <Text
                color={canFilter ? theme.primary_color_text : theme.text_color}
                cursor={canFilter ? "pointer" : "default"}
                onClick={() =>
                  handleAddFilter("offeringType", getValue() || null)
                }
              >
                {" "}
                {getValue()}{" "}
              </Text>
            ) : (
              <>---</>
            ),
          header: columnLabels.offeringType,
          size: 210,
        }),
        columnHelper.accessor("coveredUsageHours", {
          id: "coveredUsageHours",
          cell: ({ getValue }) => (
            <>{getValue() ? formatNumber(getValue()) : "---"}</>
          ),
          header: columnLabels.coveredUsageHours,
          meta: { align: "right" },
          size: 170,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("percentOfCommitments", {
          id: "percentOfCommitments",
          cell: ({ getValue }) => <>{formatPercentage(getValue())}</>,
          header: columnLabels.percentOfCommitments,
          meta: { align: "right" },
          size: 200,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("lineItemUnblendedCost", {
          id: "lineItemUnblendedCost",
          cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
          header: columnLabels.lineItemUnblendedCost,
          meta: { align: "right" },
          size: 210,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("effectiveCost", {
          id: "effectiveCost",
          cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
          header: columnLabels.effectiveCost,
          meta: { align: "right" },
          size: 210,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("netEffectiveCost", {
          id: "netEffectiveCost",
          cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
          header: columnLabels.netEffectiveCost,
          meta: { align: "right" },
          size: 210,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("amortizedCost", {
          id: "amortizedCost",
          cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
          header: columnLabels.amortizedCost,
          meta: { align: "right" },
          size: 210,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor("riNetAmortizedUpfrontCostForUsage", {
          id: "riNetAmortizedUpfrontCostForUsage",
          cell: ({ getValue }) => <>{formatCurrency({ number: getValue() })}</>,
          header: columnLabels.riNetAmortizedUpfrontCostForUsage,
          meta: { align: "right" },
          size: 210,
          sortDescFirst: true,
          sortingFn: numberSort,
        }),
        columnHelper.accessor(
          "spNetAmortizedUpfrontCommitmentForBillingPeriod",
          {
            id: "spNetAmortizedUpfrontCommitmentForBillingPeriod",
            cell: ({ getValue }) => (
              <>{formatCurrency({ number: getValue() })}</>
            ),
            header:
              columnLabels.spNetAmortizedUpfrontCommitmentForBillingPeriod,
            meta: { align: "right" },
            size: 210,
            sortDescFirst: true,
            sortingFn: numberSort,
          }
        ),
      ].filter((column) => {
        if (!column.id) return false;
        return visibleColumns.includes(column.id);
      }),
    [visibleColumns]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      initialState={{
        sorting: [{ id: "effectiveCost", desc: true }],
        pagination: { pageSize: props.pageSize ?? 10 },
      }}
      isLoading={props.isLoading}
      showPagination
      sortable
      onChangePage={(pageContent) => props.onChangePage?.(pageContent)}
    />
  );
}

const numberSort: SortingFn<TableData> = (rowA, rowB, columnID) => {
  const a = rowA.getValue(columnID);
  const b = rowB.getValue(columnID);

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return 1;
  if (typeof b !== "number") return -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

export const columnKeys = [
  "amortizedCost",
  "billPayerAccountId",
  "commitmentARN",
  "commitmentType",
  "countDistinctARN",
  "coverageType",
  "coveredUsageHours",
  "effectiveCost",
  "instanceId",
  "lineItemUnblendedCost",
  "lineItemUsageAccountId",
  "netEffectiveCost",
  "offeringType",
  "percentOfCommitments",
  "region",
  "riNetAmortizedUpfrontCostForUsage",
  "spNetAmortizedUpfrontCommitmentForBillingPeriod",
];

/* prettier-ignore */
export const columnLabels = {
  amortizedCost: copyText.awsAllocation_amortizedCost,
  billPayerAccountId: copyText.awsAllocation_billPayerAccountId,
  commitmentARN: copyText.awsAllocation_commitmentARN,
  commitmentType: copyText.awsAllocation_commitmentType,
  countDistinctARN: copyText.awsAllocation_countDistinctARN,
  coverageType: copyText.awsAllocation_coverageType,
  coveredUsageHours: copyText.awsAllocation_coveredUsageHours,
  effectiveCost: copyText.awsAllocation_effectiveCost,
  instanceId: copyText.awsAllocation_instanceId,
  lineItemUnblendedCost: copyText.awsAllocation_lineItemUnblendedCost,
  lineItemUsageAccountId: copyText.awsAllocation_lineItemUsageAccountId,
  netEffectiveCost: copyText.awsAllocation_netEffectiveCost,
  offeringType: copyText.awsAllocation_offeringType,
  percentOfCommitments: copyText.awsAllocation_percentOfCommitments,
  region: copyText.awsAllocation_region,
  riNetAmortizedUpfrontCostForUsage: copyText.awsAllocation_riNetAmortizedUpfrontCostForUsage,
  spNetAmortizedUpfrontCommitmentForBillingPeriod: copyText.awsAllocation_spNetAmortizedUpfrontCommitmentForBillingPeriod,
};
