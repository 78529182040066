import { nullable, number } from "@/api/analytics/utils/Cubestruct";

export const CostAlertStruct = {
  positiveDelta: nullable(number()),
  negativeDelta: nullable(number()),
  totalDelta: nullable(number()),
  totalAlertEvents: nullable(number()),
};

export const CostStruct = {
  cost: number(),
};
