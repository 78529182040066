import { useTheme } from "@emotion/react";
import {
  faCircleCheck,
  faSquareXmark,
} from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";
import { FiscalYearsData, fiscalDateFns } from "../../hooks/useFiscalYearsData";
import {
  FiscalYearValidationDetails,
  FiscalYearValidationResult,
} from "../../types";

type Props = {
  hideSuccess?: boolean;
  hideNoIssues?: boolean;
  fiscalYearsData: FiscalYearsData;
  yearNumber: string;
};

export default function FiscalYearValidations(props: Props) {
  const validationResult = validateFiscalYear(
    props.yearNumber,
    props.fiscalYearsData
  );

  const currentYear = props.fiscalYearsData.yearMap[props.yearNumber];

  if (!validationResult || !currentYear) return null;

  const { details: validationDetails, errorCount } = validationResult;

  const nextYearNumber = String(Number(props.yearNumber) + 1);
  const nextYear = props.fiscalYearsData.yearMap[nextYearNumber]
    ? props.fiscalYearsData.yearMap[nextYearNumber]
    : null;

  const prevYearNumber = String(Number(props.yearNumber) - 1);
  const prevYear = props.fiscalYearsData.yearMap[prevYearNumber]
    ? props.fiscalYearsData.yearMap[prevYearNumber]
    : null;

  function filterSuccess(
    isSuccess: boolean | null,
    content: JSX.Element | boolean | null
  ) {
    if (isSuccess === null || (isSuccess && props.hideSuccess)) return null;
    return content;
  }

  return (
    <>
      {errorCount === 0 && !props.hideNoIssues && (
        <Message
          isSuccess
          text={copyText.fiscalCalendarValidationNoIssuesDetected}
        />
      )}

      {filterSuccess(
        validationDetails.is52or53weeks,
        <Message
          isSuccess={!!validationDetails.is52or53weeks}
          text={
            currentYear.weekCount === 1
              ? copyText.fiscalCalendarValidationHasWeek
              : copyText.fiscalCalendarValidationHasWeeks.replace(
                  "%WEEKS%",
                  String(currentYear.weekCount)
                )
          }
        />
      )}

      {filterSuccess(
        validationDetails.isNotOverlappingWithPrev,
        validationDetails.isNotOverlappingWithPrev === false && (
          <Message
            isSuccess={false}
            text={copyText.fiscalCalendarValidationIsOverlappingPrev}
          />
        )
      )}

      {filterSuccess(
        validationDetails.is52or53weeksPrev,
        prevYear && (
          <Message
            isSuccess={!!validationDetails.is52or53weeksPrev}
            text={
              prevYear.weekCount === 1
                ? copyText.fiscalCalendarValidationHasWeekPrev
                : copyText.fiscalCalendarValidationHasWeeksPrev.replace(
                    "%WEEKS%",
                    String(prevYear.weekCount)
                  )
            }
          />
        )
      )}

      {filterSuccess(
        validationDetails.isQuarterPatternSameAsPrev,
        <Message
          isSuccess={!!validationDetails.isQuarterPatternSameAsPrev}
          text={
            validationDetails.isQuarterPatternSameAsPrev
              ? copyText.fiscalCalendarValidationQuarterPatternSamePrev
              : copyText.fiscalCalendarValidationQuarterPatternDiffPrev
          }
        />
      )}

      {filterSuccess(
        validationDetails.isStartDayOfWeekSameAsPrev,
        validationDetails.isStartDayOfWeekSameAsPrev === false && prevYear && (
          <Message
            isSuccess={!!validationDetails.isStartDayOfWeekSameAsPrev}
            text={copyText.fiscalCalendarValidationDayOfWeekDiffPrev.replace(
              "%DOW%",
              fiscalDateFns.getDayOfWeekCopyText(prevYear.startDayOfWeek)
            )}
          />
        )
      )}

      {filterSuccess(
        validationDetails.isQuarterPatternSameAsNext,
        <Message
          isSuccess={!!validationDetails.isQuarterPatternSameAsNext}
          text={
            validationDetails.isQuarterPatternSameAsNext
              ? copyText.fiscalCalendarValidationQuarterPatternSameNext
              : copyText.fiscalCalendarValidationQuarterPatternDiffNext
          }
        />
      )}

      {filterSuccess(
        validationDetails.isStartDayOfWeekSameAsNext,
        validationDetails.isStartDayOfWeekSameAsNext === false && nextYear && (
          <Message
            isSuccess={false}
            text={copyText.fiscalCalendarValidationDayOfWeekDiffNext.replace(
              "%DOW%",
              fiscalDateFns.getDayOfWeekCopyText(nextYear.startDayOfWeek)
            )}
          />
        )
      )}

      {filterSuccess(
        validationDetails.isValidStartAndEndDayOfWeek,
        !validationDetails.isValidStartAndEndDayOfWeek &&
          (!nextYear || !prevYear) &&
          validationDetails.isValidStartAndEndDayOfWeekNext !== false &&
          validationDetails.isValidStartAndEndDayOfWeekPrev !== false && (
            <Message
              isSuccess={false}
              text={
                nextYear
                  ? copyText.fiscalCalendarValidationDayOfWeekShouldStart.replace(
                      "%DOW%",
                      fiscalDateFns.getDayOfWeekCopyText(
                        nextYear.startDayOfWeek
                      )
                    )
                  : copyText.fiscalCalendarValidationDayOfWeekShouldEnd.replace(
                      "%DOW%",
                      fiscalDateFns.getDayOfWeekCopyText(
                        fiscalDateFns.getRelativeDayOfWeek(
                          currentYear.startDayOfWeek,
                          -1
                        )
                      )
                    )
              }
            />
          )
      )}
    </>
  );
}

type MessageProps = {
  isSuccess: boolean;
  text: string | null;
};

function Message(props: MessageProps) {
  const theme = useTheme();
  if (props.isSuccess === null) return null;

  return (
    <Box>
      <Flex paddingBottom={theme.space_xxs}>
        <Text lineHeight={1.4}>
          {props.isSuccess ? (
            <Icon icon={faCircleCheck} color={theme.feedback_positive} />
          ) : (
            <Icon icon={faSquareXmark} color={theme.feedback_negative} />
          )}
        </Text>
        <Box paddingLeft={theme.space_xs}>
          <Text lineHeight={1.4}>{props.text}</Text>
        </Box>
      </Flex>
    </Box>
  );
}

export function validateFiscalYear(
  yearNumber: string,
  fiscalYearsData: FiscalYearsData
): FiscalYearValidationResult | null {
  const currentYear = fiscalYearsData.yearMap[yearNumber];
  if (!currentYear) return null;

  const nextYearNumber = String(Number(yearNumber) + 1);
  const nextYear = fiscalYearsData.yearMap[nextYearNumber]
    ? fiscalYearsData.yearMap[nextYearNumber]
    : null;

  const prevYearNumber = String(Number(yearNumber) - 1);
  const prevYear = fiscalYearsData.yearMap[prevYearNumber]
    ? fiscalYearsData.yearMap[prevYearNumber]
    : null;

  const is52or53weeks =
    currentYear.weekCount === 52 || currentYear.weekCount === 53;

  const is52or53weeksPrev = prevYear
    ? prevYear.weekCount === 52 || prevYear.weekCount === 53
    : null;

  const isNotOverlappingWithPrev = prevYear
    ? fiscalDateFns.diffDays(
        currentYear.startDate,
        prevYear.Q4.months[2].startDate
      ) > 0
    : null;

  const isQuarterPatternSameAsNext = nextYear
    ? currentYear.quarterPattern === nextYear.quarterPattern
    : null;

  const isQuarterPatternSameAsPrev = prevYear
    ? currentYear.quarterPattern === prevYear.quarterPattern
    : null;

  const isStartDayOfWeekSameAsNext = nextYear
    ? currentYear.startDayOfWeek === nextYear.startDayOfWeek
    : null;

  const isStartDayOfWeekSameAsPrev = prevYear
    ? currentYear.startDayOfWeek === prevYear.startDayOfWeek
    : null;

  const isValidStartAndEndDayOfWeek = currentYear.isExactWeeks;

  const isValidStartAndEndDayOfWeekNext = nextYear
    ? nextYear.isExactWeeks
    : null;

  const isValidStartAndEndDayOfWeekPrev = prevYear
    ? prevYear.isExactWeeks
    : null;

  const details: FiscalYearValidationDetails = {
    is52or53weeks,
    is52or53weeksPrev,
    isNotOverlappingWithPrev,
    isQuarterPatternSameAsNext,
    isQuarterPatternSameAsPrev,
    isStartDayOfWeekSameAsNext,
    isStartDayOfWeekSameAsPrev,
    isValidStartAndEndDayOfWeek,
    isValidStartAndEndDayOfWeekNext,
    isValidStartAndEndDayOfWeekPrev,
  };

  const errorCount = Object.values(details).filter(
    (value) => value === false
  ).length;

  const canSave =
    isNotOverlappingWithPrev !== false &&
    isStartDayOfWeekSameAsNext !== false &&
    isStartDayOfWeekSameAsPrev !== false &&
    isValidStartAndEndDayOfWeek !== false;

  return {
    canSave,
    details,
    errorCount,
  };
}
