import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { DateHelper } from "@/lib/dates";
import { useQuery } from "@tanstack/react-query";
import { DataSource, Operator } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import { buildCubeQuery } from "../utils";

type FamilyDatum = {
  family: string;
  resourceCost: number;
};

type Family = {
  cost: number;
  name: string;
};

interface Params {
  cloudID: string;
  region: string;
}

export default function useGetCUDFamilies(
  params: Params,
  options?: UseQueryOptions<Family[], UError>
): UseQueryResult<Family[], UError> {
  const client = useAnalyticsApiClient();

  const dateHelper = new DateHelper();
  const startDate = dateHelper.firstOfLastMonth();
  const endDate = dateHelper.lastDayLastMonth();

  return useQuery({
    queryKey: ["families", params],
    queryFn: async () => {
      const result = (await client.load(
        buildCubeQuery({
          dataSource: DataSource.COMMITTED_USE,
          dateRange: [startDate, endDate],
          dimensions: ["family"],
          queryFilters: [
            {
              name: "cloudId",
              operator: Operator.EQUALS,
              values: [params.cloudID],
            },
            {
              name: "region",
              operator: Operator.EQUALS,
              values: [params.region],
            },
            {
              name: "family",
              operator: Operator.NOT_EQUALS,
              values: ["Not Commitment Eligible"],
            },
          ],
          measures: ["resourceCost"],
        })
      )) as FamilyDatum[];

      return result.map((datum) => {
        return {
          cost: datum.resourceCost ?? 0,
          name: datum.family,
        };
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
