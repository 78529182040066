import styled from "@emotion/styled";
import {
  formatCurrencyRounded,
  formatNumber,
  formatNumberRounded,
} from "@ternary/web-ui-lib/utils/formatNumber";
import React from "react";
import getCn from "../utils/getCn";

const COMPONENT_NAME = "TimeSeriesChartTooltip";
const cn = getCn(COMPONENT_NAME);

const StyledTooltip = styled.div`
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.7);

  .${cn("tooltipHeader")} {
    color: ${(props) => props.theme.text_color_inverse};

    span {
      margin-left: 3rem;
    }
  }

  .${cn("tooltipTotal")} {
    float: right;
    font-weight: bold;
  }
`;

interface Props {
  label: string;
  total: number;
  unit?: string;
}

export default function SimpleChartTooltip(props: Props): JSX.Element | null {
  if (props.label === undefined) {
    return null;
  }

  return (
    <StyledTooltip>
      <div className={cn("tooltipHeader")}>
        {props.label}
        <span className={cn("tooltipTotal")}>
          {roundAndFormatNumber(props.total, props.unit)}
        </span>
      </div>
    </StyledTooltip>
  );
}

function roundAndFormatNumber(n: number, unit: string | undefined) {
  if (unit === undefined) {
    if (n < 1 && n !== 0) {
      return "< $" + formatNumber(1);
    }
    return formatCurrencyRounded({
      number: n,
    });
  }

  if (n < 1 && n !== 0) {
    return "< " + formatNumber(1) + unit;
  }
  return formatNumberRounded(n) + unit;
}
