import ErrorBoundary from "@/components/ErrorBoundary";
import NotConfiguredPlaceholder from "@/components/NotConfiguredPlaceholder";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../../constants/paths";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import copyText from "../../copyText";
import AzureCommittedUseRecommendationsContainer from "./AzureCommittedUseRecommendationsContainer";
import AzureCommittedUseVisibilityContainer from "./AzureCommittedUseVisibilityContainer";

export function AzureCommittedUsePage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "inventory"),
  });

  if (!gatekeeper.canViewCommittedUsePage) {
    return <Navigate to={paths._home} replace={true} />;
  }

  if (!gatekeeper.isAzureCommittedUseConfigured) {
    return <NotConfiguredPlaceholder />;
  }

  const containers = [
    {
      component: <AzureCommittedUseVisibilityContainer />,
      label: copyText.inventoryTabLabel,
      value: "inventory",
    },
    ...(gatekeeper.canViewAzureRateRecs
      ? [
          {
            component: <AzureCommittedUseRecommendationsContainer />,
            label: copyText.azureTabLabelRecommendations,
            value: "optimizations",
          },
        ]
      : []),
  ];

  return (
    <ErrorBoundary boundaryName="AzureCommittedUsePage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}
