import { FormField } from "@/ui-lib/components/Form";
import TextInput from "@/ui-lib/components/TextInput";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { ChangeEvent } from "react";
import copyText from "../../copyText";

const AWS_ROLE_ARN_REGEX = /arn:aws:iam::[0-9]+:role\/.*/;
interface Props {
  monitoringRoleARNInput: string;
  nameInput: string;
  roleARNInput: string;
  onUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AWSFormBasic(props: Props): JSX.Element {
  return (
    <Box>
      <FormField
        name="name"
        input={TextInput}
        label={copyText.cloudAttributeName}
        required
        type="text"
        value={props.nameInput}
        variant={props.nameInput.trim().length > 0 ? "success" : "danger"}
        onChange={props.onUpdate}
      />
      <FormField
        name="roleARN"
        input={TextInput}
        label={copyText.roleARNLabel}
        placeholder={copyText.cloudPlaceholderRoleArn}
        required
        value={props.roleARNInput}
        variant={
          AWS_ROLE_ARN_REGEX.test(props.roleARNInput) &&
          props.roleARNInput !== copyText.cloudPlaceholderRoleArn
            ? "success"
            : "danger"
        }
        onChange={props.onUpdate}
      />
      <FormField
        name="monitoringRoleARN"
        input={TextInput}
        label={copyText.monitoringRoleARNLabel}
        placeholder={copyText.cloudPlaceholderRoleArn}
        value={props.monitoringRoleARNInput}
        variant={
          AWS_ROLE_ARN_REGEX.test(props.monitoringRoleARNInput) &&
          props.monitoringRoleARNInput !== copyText.cloudPlaceholderRoleArn
            ? "success"
            : "danger"
        }
        onChange={props.onUpdate}
      />
    </Box>
  );
}
