import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

type UserGroupConfigEntity = Awaited<
  ReturnType<CoreAPIClient["getUserGroupConfigsByTenantID"]>
>[0];

export default function useGetUserGroupConfigs(
  tenantID: string,
  options?: UseQueryOptions<UserGroupConfigEntity[], CoreAPIClientError>
): UseQueryResult<UserGroupConfigEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.userGroupConfigs,
    queryFn: () => client.getUserGroupConfigsByTenantID(tenantID),
    enabled: gatekeeper.canListUserGroupConfigs,
    ...options,
  });
}
