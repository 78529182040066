import { useQuery } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import { useAnalyticsApiClient } from "../../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import { DateHelper } from "../../../../lib/dates";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import { cloudSpannerInstanceMeasures } from "../types";

type CloudSpannerUsageSummary = {
  storageLimitB: number | null;
  storageUsedB: number | null;
};

type CloudSpannerUsageSummaryEntity = {
  [cloudSpannerInstanceMeasures.storageLimitB]: number;
  [cloudSpannerInstanceMeasures.storageUsedB]: number;
};

export default function useGetCloudSpannerUsageSummary(
  options?: UseQueryOptions<CloudSpannerUsageSummaryEntity, UError>
): UseQueryResult<CloudSpannerUsageSummaryEntity, UError> {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const now = new DateHelper();
  const gatekeeper = useGatekeeper();

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQuery({
    queryKey: ["cloudSpannerUsageSummary"],
    queryFn: async () => {
      const measures = [
        cloudSpannerInstanceMeasures.storageLimitB,
        cloudSpannerInstanceMeasures.storageUsedB,
      ];

      const query = new DataQuery({
        dataSource: DataSource.CLOUD_SPANNER_USAGE,
        dateRange: [now.firstOfMonth(), now.date],
        measures,
      });
      const result = await client.loadData<CloudSpannerUsageSummary>(
        tenantID,
        query
      );

      const summary = result.response[0];

      return {
        [cloudSpannerInstanceMeasures.storageLimitB]:
          summary.storageLimitB ?? 0,
        [cloudSpannerInstanceMeasures.storageUsedB]: summary.storageUsedB ?? 0,
      };
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    enabled: gatekeeper.hasGCPIntegration,
    ...options,
  });
}
