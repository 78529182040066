import { useTheme } from "@emotion/react";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";

export type ChipProps = {
  icon?: IconDefinition;
  label?: string;
  text: string;
  onClick: () => void;
};

export default function Chip(props: ChipProps) {
  const theme = useTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={theme.primary_color_background}
      backgroundColorOnHover={theme.tag_button_background_color_hover}
      borderRadius={theme.borderRadius_3}
      cursor="pointer"
      marginRight={theme.space_sm}
      paddingHorizontal={theme.space_sm}
      paddingRight={theme.space_sm}
      paddingVertical={theme.space_xxs}
      position="relative"
      onClick={props.onClick}
    >
      <Flex direction="column">
        {props.label ? (
          <Box height={8} marginBottom={theme.space_xxs}>
            <Text
              bold
              color={theme.text_color_inverse}
              fontSize={theme.fontSize_xs}
            >
              {props.label}:
            </Text>
          </Box>
        ) : null}

        <Box>
          <Text color={theme.text_color_inverse}>{props.text}</Text>
        </Box>
      </Flex>

      <Box paddingLeft={theme.space_sm}>
        <Icon
          color={theme.text_color_inverse}
          icon={props.icon ?? faTimes}
          size="xs"
        />
      </Box>
    </Flex>
  );
}
