import { useTheme } from "@emotion/react";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { noop } from "lodash";
import React from "react";
import SelectDropdown from "../../../../ui-lib/components/SelectDropdown";
import copyText from "../../copyText";
import { AWSCommittedUseAllocationType, AWSCommittedUseType } from "../types";

type Props = {
  allocationType: AWSCommittedUseAllocationType;
  committedUseType: AWSCommittedUseType | null;
  isLoading: boolean;
  onInteraction: (interaction: AWSAllocationControls.Interaction) => void;
};

type Option = { label: string; value: string };

const allocationOptions: Option[] = [
  { label: "Account", value: AWSCommittedUseAllocationType.ACCOUNT },
  { label: "Resource", value: AWSCommittedUseAllocationType.RESOURCE },
];

const typeOptions: Option[] = [
  { label: "All", value: "ALL" },
  { label: "Savings Plan", value: AWSCommittedUseType.SP },
  { label: "Reserved Instance", value: AWSCommittedUseType.RI },
];

function findMatchingOption(options: Option[], value: string) {
  const found = options.find((option) =>
    value === null ? option.value === "null" : value === option.value
  );

  return found ?? options[0];
}

function AWSAllocationControls(props: Props) {
  const theme = useTheme();

  const selectedTypeOption = findMatchingOption(
    typeOptions,
    props.committedUseType ?? "ALL"
  );

  return (
    <Flex>
      <SelectDropdown
        hideSelectedOptions={false}
        options={typeOptions}
        placement="bottom-start"
        selectedValues={[selectedTypeOption.value]}
        onChange={(value: string) =>
          value === AWSCommittedUseType.RI ||
          value === AWSCommittedUseType.SP ||
          value === "ALL"
            ? props.onInteraction({
                type: AWSAllocationControls.INTERACTION_CHANGE_TYPE,
                committedUseType: value === "ALL" ? null : value,
              })
            : noop()
        }
      >
        <Button marginRight={theme.space_md} secondary size="small">
          {copyText.awsFiltersType.replace("%VALUE%", selectedTypeOption.label)}
        </Button>
      </SelectDropdown>

      <SelectDropdown
        hideSelectedOptions={false}
        options={allocationOptions}
        placement="bottom-start"
        selectedValues={[props.allocationType]}
        onChange={(value: string) =>
          value === AWSCommittedUseAllocationType.ACCOUNT ||
          value === AWSCommittedUseAllocationType.RESOURCE
            ? props.onInteraction({
                type: AWSAllocationControls.INTERACTION_CHANGE_ALLOCATION_TYPE,
                allocationType: value,
              })
            : noop()
        }
      >
        <Button marginRight={theme.space_md} secondary size="small">
          {copyText[`awsFiltersAllocationType_${props.allocationType}`]}
        </Button>
      </SelectDropdown>
    </Flex>
  );
}

AWSAllocationControls.INTERACTION_CHANGE_ALLOCATION_TYPE =
  `AWSAllocationControls.INTERACTION_CHANGE_ALLOCATION_TYPE` as const;
AWSAllocationControls.INTERACTION_CHANGE_TYPE =
  `AWSAllocationControls.INTERACTION_CHANGE_TYPE` as const;

interface InteractionChangeAllocationType {
  type: typeof AWSAllocationControls.INTERACTION_CHANGE_ALLOCATION_TYPE;
  allocationType: AWSCommittedUseAllocationType;
}

interface InteractionChangeType {
  type: typeof AWSAllocationControls.INTERACTION_CHANGE_TYPE;
  committedUseType: AWSCommittedUseType | null;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace AWSAllocationControls {
  export type Interaction =
    | InteractionChangeAllocationType
    | InteractionChangeType;
}

export default AWSAllocationControls;
