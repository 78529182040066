import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type Resources = Awaited<
  ReturnType<CoreAPIClient["getMspChildResourcesByParentTenantID"]>
>;

export default function useGetMspChildResources(
  tenantID: string,
  options?: UseQueryOptions<Resources, CoreAPIClientError>
): UseQueryResult<Resources, CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["mspResources", tenantID],
    queryFn: () => client.getMspChildResourcesByParentTenantID(tenantID),
    ...options,
  });
}
