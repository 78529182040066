import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column, SortByFn } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import copyText from "../../copyText";
import { AWSKubernetesInstanceEntity } from "../types";

type TableData = {
  accountID: string;
  nodeName: string;
  instanceId: string;
  coveredCost: number;
  cpuTotal: number;
  cpuUsed: number;
  memoryTotal: number;
  memoryUsed: number;
  name: string;
  onDemandCost: number;
  totalCost: number;
};

type Props = {
  instances: AWSKubernetesInstanceEntity[];
  isLoadingInstances: boolean;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

export default function AWSKubernetesInstanceTable(props: Props) {
  const [sortRule, setSortRule] = useQueryParam(
    "instance_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const tableData = useMemo(
    (): TableData[] =>
      props.instances.map((instance) => ({
        accountID: instance.accountID,
        nodeName: instance.nodeName,
        instanceId: instance.instanceId,
        coveredCost: instance.coveredCost,
        cpuTotal: instance.cpuTotal,
        cpuUsed: instance.cpuTotal ? instance.cpuUsed / instance.cpuTotal : 0,
        memoryTotal: instance.memoryTotal,
        memoryUsed: instance.memoryTotal
          ? instance.memoryUsed / instance.memoryTotal
          : 0,
        name: instance.name,
        onDemandCost: instance.onDemandCost,
        totalCost: instance.totalCost,
      })),
    [props.instances]
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        accessor: "nodeName",
        Header: copyText.awsKubernetesInstanceHeader_nodeName,
        sortType: stringSort,
        width: 180,
      },
      {
        accessor: "instanceId",
        Header: copyText.awsKubernetesInstanceHeader_instanceId,
        sortType: stringSort,
        width: 180,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 120,
      },
      {
        accessor: "onDemandCost",
        align: "right",
        Cell: ({ value: onDemandCost }) => (
          <>{formatCurrency({ number: onDemandCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_onDemandCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 145,
      },
      {
        accessor: "coveredCost",
        align: "right",
        Cell: ({ value: coveredCost }) => (
          <>{formatCurrency({ number: coveredCost })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_coveredCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 130,
      },
      {
        accessor: "cpuUsed",
        align: "right",
        Cell: ({ value: cpuUsed }) => <>{formatPercentage(cpuUsed)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_cpuUsed,
        sortDescFirst: true,
        sortType: numberSort,
        width: 135,
      },
      {
        accessor: "cpuTotal",
        align: "right",
        Cell: ({ value: cpuTotal }) => <>{formatNumber(cpuTotal)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_cpuTotal,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
      {
        accessor: "memoryUsed",
        align: "right",
        Cell: ({ value: memoryUsed }) => <>{formatPercentage(memoryUsed)}</>,
        Header: copyText.awsKubernetesInstanceGroupHeader_memoryUsed,
        sortDescFirst: true,
        sortType: numberSort,
        width: 155,
      },
      {
        accessor: "memoryTotal",
        align: "right",
        Cell: ({ value: memoryTotal }) => (
          <>{prettyBytes(memoryTotal, { binary: true })}</>
        ),
        Header: copyText.awsKubernetesInstanceGroupHeader_memoryTotal,
        sortDescFirst: true,
        sortType: numberSort,
        width: 110,
      },
    ],
    [props.instances]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule] }}
      isLoading={props.isLoadingInstances}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function isTableDataKey(key: string): key is keyof TableData {
  const tableDataKeys: (keyof TableData)[] = [
    "accountID",
    "coveredCost",
    "cpuTotal",
    "cpuUsed",
    "memoryTotal",
    "memoryUsed",
    "name",
    "onDemandCost",
    "totalCost",
  ];

  return tableDataKeys.includes(key as keyof TableData);
}

const numberSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "number" && typeof b !== "number") return 0;
  if (typeof a !== "number") return desc ? -1 : 1;
  if (typeof b !== "number") return desc ? 1 : -1;

  return a === b ? 0 : a < b ? -1 : 1;
};

const stringSort: SortByFn<TableData> = (rowA, rowB, columnID, desc) => {
  if (!isTableDataKey(columnID)) return 0;

  const a = rowA.original[columnID];
  const b = rowB.original[columnID];

  if (typeof a !== "string" && typeof b !== "string") return 0;
  if (typeof a !== "string" || a === "") return desc ? -1 : 1;
  if (typeof b !== "string" || b === "") return desc ? 1 : -1;

  if (a.toLowerCase() === b.toLowerCase()) return 0;
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
};
