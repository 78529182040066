import { DateHelper } from "@/lib/dates";
import Breadcrumb from "@/ui-lib/components/Breadcrumb";
import DateRangeControls from "@/ui-lib/components/DateRangeControls";
import Dropdown from "@/ui-lib/components/Dropdown";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { QueryFilter, RawValue } from "@ternary/api-lib/analytics/types";
import { getCubeDateRangeFromDurationType } from "@ternary/api-lib/analytics/utils";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import {
  ChartType,
  DataSource,
  DurationType,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import AreaChart from "@ternary/web-ui-lib/charts/AreaChart";
import StackedBarChart from "@ternary/web-ui-lib/charts/StackedBarChart";
import { Measure } from "@ternary/web-ui-lib/charts/types";
import { formatTimestamp as format } from "@ternary/web-ui-lib/charts/utils";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { getFormatForGranularity } from "@ternary/web-ui-lib/utils/dates";
import { differenceInHours, endOfDay } from "date-fns";
import { keyBy } from "lodash";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import {
  DateParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import useGetRawData from "../../../../api/analytics/useGetRawData";
import InsightsSelector from "../../../../components/InsightsSelector";
import useAvailableGlobalDate from "../../../../hooks/useAvailableGlobalDate";
import { cloudSQLUsageMeasures } from "../../constants";
import copyText from "../../copyText";
import useGetDatabaseSpendSummaries from "../../hooks/useGetDatabaseSpendSummaries";
import useGetCloudSQLCostSummary from "../hooks/useGetCloudSQLCostSummary";
import useGetCloudSQLUsageSummary from "../hooks/useGetCloudSQLUsageSummary";
import {
  CloudSQLCostDatum,
  CloudSQLResource,
  CloudSQLResourceType,
  CloudSQLUsageDatum,
} from "../types";
import GCPDatabaseInstanceTable from "./GCPDatabaseInstanceTable";
import GCPDatabaseMeters from "./GCPDatabaseMeters";
import GCPDatabaseResourceTable from "./GCPDatabaseResourceTable";

enum CostGrouping {
  ALL = "_",
  PROJECT_ID = "projectId",
  DATABASE_TYPE = "databaseType",
  REGION = "region",
}

const classificationDimensions = ["projectId", "databaseType", "region"];

const defaultCloudSQLResource: CloudSQLResource = {
  avgCpuReservedCores: null,
  avgCpuUsedCoreHours: null,
  avgDiskSizeBytes: null,
  avgDiskUsedBytes: null,
  avgNetworkSentBytes: null,
  avgRamReservedBytes: null,
  avgRamUsedBytes: null,
  backupCost: null,
  classification: null,
  cost: null,
  cpuAndRamCost: null,
  cpuCost: null,
  databaseId: null,
  databaseType: null,
  idlingCost: null,
  latestProvisionedCpu: null,
  latestProvisionedDisk: null,
  latestProvisionedRam: null,
  latestUsedCpu: null,
  latestUsedDisk: null,
  latestUsedRam: null,
  maxCpuAverageUsedCores: null,
  maxCpuReservedCores: null,
  maxDiskSizeBytes: null,
  maxDiskUsedBytes: null,
  maxRamAverageUsedBytes: null,
  maxRamUsedBytesWithBuffer: null,
  networkCost: null,
  networkSentBytes: null,
  projectId: null,
  ramCost: null,
  ramReservedBytes: null,
  region: null,
  storageCost: null,
  totalCost: null,
  totalCpuReservedCores: null,
  totalCpuUsedCoreHours: null,
  totalDiskSizeBytes: null,
  totalDiskUsedBytes: null,
  totalNetworkSentBytes: null,
  totalRamReservedBytes: null,
  totalRamUsedBytes: null,
  totalRamUsedBytesWithBuffer: null,
};

const CostGroupingLabel: { [key in CostGrouping]: string } = {
  [CostGrouping.ALL]: copyText.cloudSQLDimensionGroupingLabelAll,
  [CostGrouping.PROJECT_ID]: copyText.cloudSQLDimensionGroupingLabelProjectID,
  [CostGrouping.DATABASE_TYPE]:
    copyText.cloudSQLDimensionGroupingLabelDatabaseType,
  [CostGrouping.REGION]: copyText.cloudSQLDimensionGroupingLabelRegion,
};

const UsageGroupingLabel: { [key in CloudSQLResourceType]: string } = {
  [CloudSQLResourceType.CPU]: copyText.cloudSQLResourceGroupingLabelCPU,
  [CloudSQLResourceType.DISK]: copyText.cloudSQLResourceGroupingLabelDisk,
  [CloudSQLResourceType.MEMORY]: copyText.cloudSQLResourceGroupingLabelMemory,
  [CloudSQLResourceType.NETWORK]: copyText.cloudSQLResourceGroupingLabelNetwork,
};

export function GCPDatabaseVisibilityContainer(): JSX.Element {
  const theme = useTheme();
  const globalDate = useAvailableGlobalDate();

  const costGroupingEnum = createEnumParam(Object.values(CostGrouping));
  const durationEnum = createEnumParam(Object.values(DurationType));
  const resourceTypeEnum = createEnumParam(Object.values(CloudSQLResourceType));

  const [searchParamState, setSearchParamState] = useQueryParams({
    cost_grouping: withDefault(costGroupingEnum, CostGrouping.PROJECT_ID),
    database_type: withDefault(StringParam, null),
    date_range_end: DateParam,
    date_range_start: DateParam,
    duration: withDefault(durationEnum, DurationType.LAST_THIRTY_DAYS),
    project_id: withDefault(StringParam, null),
    region: withDefault(StringParam, null),
    resource_type: withDefault(resourceTypeEnum, CloudSQLResourceType.MEMORY),
  });

  const now = useMemo(() => new DateHelper(), []);

  const earliestDate = now.nDaysAgo(30);

  const classification = useMemo(
    () =>
      validateClassification({
        databaseType: searchParamState.database_type,
        projectId: searchParamState.project_id,
        region: searchParamState.region,
      }),
    [
      searchParamState.database_type,
      searchParamState.project_id,
      searchParamState.region,
    ]
  );

  const queryFilters = createClassificationFilters(classification);

  const classificationCostMeasures = classification
    ? ["remainingCost", "cpuAndRamCost", "storageCost"]
    : ["cost"];

  //
  // Queries
  //

  const dateRange = useMemo(() => {
    const customDateRange =
      (searchParamState.duration === DurationType.CUSTOM ||
        searchParamState.duration === DurationType.INVOICE) &&
      searchParamState.date_range_start &&
      searchParamState.date_range_end
        ? [searchParamState.date_range_start, searchParamState.date_range_end]
        : null;

    if (globalDate.date) {
      return globalDate.date;
    }

    if (customDateRange) {
      return customDateRange.map((date) =>
        date < earliestDate ? new Date(earliestDate) : date
      );
    }

    return getCubeDateRangeFromDurationType(searchParamState.duration);
  }, [
    searchParamState.duration,
    searchParamState.date_range_start,
    searchParamState.date_range_end,
    globalDate,
  ]);

  const dateRangeDurationInHours =
    dateRange[0] && dateRange[1]
      ? differenceInHours(endOfDay(dateRange[1]), dateRange[0])
      : 0;

  const isSmallDateRange =
    dateRangeDurationInHours > 0 && dateRangeDurationInHours <= 48;

  const granularity = isSmallDateRange
    ? TimeGranularity.HOUR
    : TimeGranularity.DAY;

  const cloudSQLSpendSummaries = useGetDatabaseSpendSummaries({
    dataSource: DataSource.CLOUD_SQL_COST,
    queryFilters,
  });

  const [{ data: currentMTD }, { data: lastMonthFull }, { data: lastMTD }] =
    cloudSQLSpendSummaries;

  const isLoadingSpendSummaries = cloudSQLSpendSummaries.some(
    (summary) => summary.isFetching
  );

  const costDataDimension =
    searchParamState.cost_grouping === CostGrouping.ALL
      ? classificationDimensions
      : [searchParamState.cost_grouping];

  const usageDataMeasures = getUsageChartMeasures(
    searchParamState.resource_type
  );

  const { data: costData = [], isFetching: isLoadingCostData } = useGetRawData({
    dataSource: DataSource.CLOUD_SQL_COST,
    dateRange,
    dimensions: costDataDimension,
    granularity: granularity,
    measures: classificationCostMeasures,
    queryFilters,
  });

  const usageDataSource = isSmallDateRange
    ? DataSource.CLOUD_SQL_INSTANCE_USAGE
    : DataSource.CLOUD_SQL_INSTANCE_USAGE_DAILY;

  const { data: usageData = [], isFetching: isLoadingUsageData } =
    useGetRawData({
      dataSource: usageDataSource,
      dateRange,
      dimensions: [],
      granularity: granularity,
      measures: usageDataMeasures,
      queryFilters,
    });

  const { data: costTableData, isFetching: isLoadingCostTableData } =
    useGetCloudSQLCostSummary(
      {
        dateRange,
        dimensions: [...classificationDimensions, "classification"],
      },
      { enabled: !classification }
    );

  const { data: usageTableData, isFetching: isLoadingUsageTableData } =
    useGetCloudSQLUsageSummary({
      dateRange,
      dimensions: [...classificationDimensions, "databaseId"],
      queryFilters,
    });

  const { data: latestUsageResult, isFetching: isLoadingLatestUsage } =
    useGetCloudSQLUsageSummary({
      dimensions: [...classificationDimensions, "databaseId"],
      dateRange: [now.nDaysAgo(1), now.nDaysAgo(0)],
      queryFilters,
      latest: true,
    });

  const isLoadingUsageTableDataWithLatest =
    isLoadingUsageTableData || isLoadingLatestUsage;

  const usageTableDataWithLatest = useMemo(
    () =>
      usageTableData && latestUsageResult
        ? applyLatestProvisioned(usageTableData, latestUsageResult)
        : [],
    [usageTableData, latestUsageResult]
  );

  const isLoadingJoinedTableData =
    isLoadingCostTableData || isLoadingUsageTableDataWithLatest;

  const joinedTableData = useMemo(
    () => combineTableData(costTableData, usageTableDataWithLatest),
    [costTableData, usageTableDataWithLatest]
  );

  const combinedLatestUsageResult = useMemo(
    () =>
      latestUsageResult && latestUsageResult.length > 0
        ? combineDatabaseIDs(latestUsageResult).reduce((accum, usageDatum) =>
            addManyByKey(
              [
                "latestProvisionedCpu",
                "latestProvisionedDisk",
                "latestProvisionedRam",
                "latestUsedCpu",
                "latestUsedDisk",
                "latestUsedRam",
              ],
              accum,
              usageDatum
            )
          )
        : null,
    [latestUsageResult]
  );

  const resourceUsageKeys = {
    totalCpuReservedCores: copyText.cloudSQLResourceUsageProvisioned,
    totalCpuUsedCoreHours: copyText.cloudSQLResourceUsageUsed,
    totalDiskSizeBytes: copyText.cloudSQLResourceUsageProvisioned,
    totalDiskUsedBytes: copyText.cloudSQLResourceUsageUsed,
    totalNetworkSentBytes: copyText.cloudSQLResourceUsageUsed,
    totalRamReservedBytes: copyText.cloudSQLResourceUsageProvisioned,
    totalRamUsedBytes: copyText.cloudSQLResourceUsageUsed,
  };

  const costKeys = {
    totalCost: copyText.cloudSQLCostGroupingLabelTotalCost,
    remainingCost: copyText.cloudSQLCostGroupingLabelRemainingCost,
    storageCost: copyText.cloudSQLCostGroupingLabelStorageCost,
    cpuAndRamCost: copyText.cloudSQLCostGroupingLabelCpuAndRamCost,
    projectId: copyText.cloudSQLDimensionGroupingLabelProjectID,
    databaseType: copyText.cloudSQLDimensionGroupingLabelDatabaseType,
    region: copyText.cloudSQLDimensionGroupingLabelRegion,
  };

  const resourceUsageMeasures: Measure[] = useMemo(() => {
    switch (searchParamState.resource_type) {
      case CloudSQLResourceType.CPU:
        return [
          { name: "totalCpuUsedCoreHours" },
          { name: "totalCpuReservedCores" },
        ];
      case CloudSQLResourceType.DISK:
        return [{ name: "totalDiskSizeBytes" }, { name: "totalDiskUsedBytes" }];
      case CloudSQLResourceType.MEMORY:
        return [
          { name: "totalRamUsedBytes", unit: UnitType.BYTES },
          { name: "totalRamReservedBytes", unit: UnitType.BYTES },
        ];
      default:
        return [{ name: "totalNetworkSentBytes" }];
    }
  }, [searchParamState.resource_type]);

  //
  // Render
  //

  const costGroupingOptions = [
    CostGrouping.ALL,
    CostGrouping.PROJECT_ID,
    CostGrouping.DATABASE_TYPE,
    CostGrouping.REGION,
  ].map((costGrouping) => ({
    label: CostGroupingLabel[costGrouping],
    value: costGrouping,
    onClick: () => setSearchParamState({ cost_grouping: costGrouping }),
  }));

  const usageGroupingOptions = [
    CloudSQLResourceType.CPU,
    CloudSQLResourceType.DISK,
    CloudSQLResourceType.MEMORY,
    CloudSQLResourceType.NETWORK,
  ].map((usageGrouping) => ({
    label: UsageGroupingLabel[usageGrouping],
    value: usageGrouping,
    onClick: () => setSearchParamState({ resource_type: usageGrouping }),
  }));

  const defaultCostGroupingOption =
    costGroupingOptions.find(
      (option) => option.value === searchParamState.cost_grouping
    ) ?? costGroupingOptions[0];

  const defaultUsageGroupingOption =
    usageGroupingOptions.find(
      (option) => option.value === searchParamState.resource_type
    ) ?? usageGroupingOptions[2];

  function formatTimestamp(timestamp: string) {
    return format(
      timestamp,
      isSmallDateRange
        ? "hh:mm a"
        : getFormatForGranularity(TimeGranularity.DAY)
    );
  }

  const formatUsageValue =
    searchParamState.resource_type !== CloudSQLResourceType.CPU
      ? byteFormatter
      : undefined;

  function getStackedBarChartDimensions() {
    if (classification) return [];
    if (searchParamState.cost_grouping === CostGrouping.ALL) {
      return classificationDimensions.map((dimension) => {
        return { name: dimension };
      });
    }
    return [{ name: searchParamState.cost_grouping }];
  }

  const costChartReportSnapshot = {
    chartType: ChartType.STACKED_BAR,
    dateRange:
      searchParamState.duration === DurationType.CUSTOM || globalDate
        ? dateRange
        : null,
    dataSource: DataSource.CLOUD_SQL_COST,
    dimensions: costDataDimension,
    durationType: searchParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: granularity,
    measures: classificationCostMeasures,
    queryFilters: queryFilters,
    name: copyText.cloudSQLCostReportSnapshotName,
    xAxisKey: "timestamp",
  };

  const usageChartReportSnapshot = {
    chartType: ChartType.AREA,
    dateRange:
      searchParamState.duration === DurationType.CUSTOM || globalDate
        ? dateRange
        : null,
    dataSource: usageDataSource,
    dimensions: [],
    durationType: searchParamState.duration,
    isFiscalMode: false,
    fillMissingDates: true,
    fiscalPeriodMap: null,
    granularity: granularity,
    measures: usageDataMeasures,
    queryFilters: queryFilters,
    name: copyText.cloudSQLUsageReportSnapshotName,
    xAxisKey: "timestamp",
  };

  return (
    <Box paddingTop={theme.space_md}>
      <Box
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <GCPDatabaseMeters
          isLoading={isLoadingSpendSummaries || isLoadingLatestUsage}
          lastMonthSpend={lastMonthFull?.totalCost ?? 0}
          lastMTDSpend={lastMTD?.totalCost ?? 0}
          latestDBAllocated={
            combinedLatestUsageResult?.latestProvisionedDisk ?? 0
          }
          latestDBUsage={combinedLatestUsageResult?.latestUsedDisk ?? 0}
          thisMTDSpend={currentMTD?.totalCost ?? 0}
        />
      </Box>

      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        justifyContent="space-between"
        marginBottom={theme.space_lg}
        padding={theme.space_md}
      >
        <Text whiteSpace="nowrap" fontSize={theme.h4_fontSize}>
          {copyText.cloudSQLBreadcrubLabelTitle}
        </Text>
        <Box flex="1 0 auto" marginLeft={theme.space_sm}>
          <Breadcrumb
            items={[
              {
                clickable: !!classification,
                label: copyText.cloudSQLBreadcrubLabelAll,
                onClick: () =>
                  setSearchParamState({
                    database_type: null,
                    project_id: null,
                    region: null,
                  }),
              },
              {
                clickable: false,
                label: classification
                  ? `[${classification.region}/${classification.databaseType}] - ${classification.projectId}`
                  : "",
              },
            ]}
          />
        </Box>
        <DateRangeControls
          dateRange={dateRange}
          durationType={searchParamState.duration}
          hiddenOptions={[
            DurationType.LAST_NINETY_DAYS,
            DurationType.QUARTER_TO_DATE,
            DurationType.YEAR_TO_DATE,
          ]}
          maxDate={now.date}
          minDate={earliestDate}
          onChangeDateRange={(duration, newDateRange) => {
            setSearchParamState({
              duration,
              date_range_start: newDateRange?.[0] ?? null,
              date_range_end: newDateRange?.[1] ?? null,
            });
          }}
        />
      </Flex>
      <Flex height={500} marginBottom={theme.space_lg} width={"100%"}>
        <Box width="50%" marginRight={theme.space_lg}>
          <InsightsSelector
            resourceName={copyText.cloudSQLCostReportSnapshotName}
            reportSnapshot={costChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {classification
                    ? copyText.cloudSQLCostChartTitle
                    : copyText.cloudSQLCostAggregateChartTitle}
                </Text>

                {!classification && (
                  <Dropdown
                    defaultSelectedOption={defaultCostGroupingOption}
                    options={costGroupingOptions}
                    placement="bottom-end"
                  >
                    <Button
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      width={140}
                    >
                      {CostGroupingLabel[searchParamState.cost_grouping]}
                    </Button>
                  </Dropdown>
                )}
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <StackedBarChart
                  data={costData}
                  dimensions={getStackedBarChartDimensions()}
                  isLoading={isLoadingCostData}
                  measures={classificationCostMeasures.map((measure) => {
                    return { name: measure, unit: UnitType.CURRENCY };
                  })}
                  maxGroupings={7}
                  readableKeys={costKeys}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={granularity}
                  emptyPlaceholderText={
                    copyText.cloudSQLCostEmptyPlaceholderText
                  }
                  xAxisFormatter={formatTimestamp}
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
        <Box width="50%">
          <InsightsSelector
            resourceName={copyText.cloudSQLUsageReportSnapshotName}
            reportSnapshot={usageChartReportSnapshot}
          >
            <Box
              backgroundColor={theme.panel_backgroundColor}
              borderRadius={theme.borderRadius_2}
              flex={1}
              height="100%"
              padding={theme.space_md}
              width="100%"
            >
              <Flex width="100%" justifyContent="space-between">
                <Text fontSize={theme.h3_fontSize}>
                  {copyText.cloudSQLUsageChartTitle}
                </Text>
                <Flex>
                  <Dropdown
                    defaultSelectedOption={defaultUsageGroupingOption}
                    options={usageGroupingOptions}
                    placement="bottom-end"
                  >
                    <Button
                      iconEnd={<Icon icon={faChevronDown} />}
                      secondary
                      size="small"
                      width={140}
                    >
                      {UsageGroupingLabel[searchParamState.resource_type]}
                    </Button>
                  </Dropdown>
                </Flex>
              </Flex>
              <Box height={450} paddingVertical={theme.space_md}>
                <AreaChart
                  data={usageData}
                  hideTotal
                  isLoading={isLoadingUsageData}
                  measures={resourceUsageMeasures}
                  mergeMeasures
                  readableKeys={resourceUsageKeys}
                  showLegend
                  showTooltip
                  timeSeriesGranularity={
                    isSmallDateRange
                      ? TimeGranularity.HOUR
                      : TimeGranularity.DAY
                  }
                  tooltipFormatter={formatUsageValue}
                  xAxisFormatter={formatTimestamp}
                  yAxisFormatter={formatUsageValue}
                />
              </Box>
            </Box>
          </InsightsSelector>
        </Box>
      </Flex>
      <Box>
        <Flex paddingBottom={theme.space_md} alignItems={"baseline"}>
          <Text fontSize={theme.h2_fontSize}></Text>
        </Flex>
        <Box overflowX="auto">
          {classification && usageTableDataWithLatest ? (
            <GCPDatabaseInstanceTable
              instances={usageTableDataWithLatest}
              isLoading={isLoadingUsageTableDataWithLatest}
              resourceType={searchParamState.resource_type}
            />
          ) : (
            <GCPDatabaseResourceTable
              resources={joinedTableData}
              isLoading={isLoadingJoinedTableData}
              resourceType={searchParamState.resource_type}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

type Classification = ReturnType<typeof validateClassification>;

function validateClassification(classification: {
  databaseType: string | null;
  projectId: string | null;
  region: string | null;
}) {
  const { databaseType, projectId, region } = classification;

  if (databaseType && projectId && region)
    return { databaseType, projectId, region };

  return null;
}

function byteFormatter(value: unknown): string {
  if (typeof value !== "number" || !Number.isFinite(value)) {
    return "0";
  }

  return prettyBytes(value);
}

function applyLatestProvisioned(
  data: CloudSQLUsageDatum[],
  latest: CloudSQLUsageDatum[]
): CloudSQLUsageDatum[] {
  const latestKeyedByDatabaseId = keyBy(latest, "databaseId");

  return data.map((datum) => {
    if (!datum.databaseId) {
      return datum;
    }
    const latestDatum = latestKeyedByDatabaseId[datum.databaseId];

    if (!latestDatum) {
      return datum;
    }

    return {
      ...datum,
      latestProvisionedCpu: latestDatum.latestProvisionedCpu,
      latestProvisionedDisk: latestDatum.latestProvisionedDisk,
      latestProvisionedRam: latestDatum.latestProvisionedRam,
      latestUsedCpu: latestDatum.latestUsedCpu,
      latestUsedDisk: latestDatum.latestUsedDisk,
      latestUsedRam: latestDatum.latestUsedRam,
    };
  });
}

function combineDatabaseIDs(
  usageData: CloudSQLUsageDatum[]
): CloudSQLUsageDatum[] {
  const map = usageData.reduce(
    (accum, datum) => {
      const classification = getClassificationKey(datum);

      const latest = accum[classification];

      if (!latest) {
        return {
          ...accum,
          [classification]: datum,
        };
      }

      return {
        ...accum,
        [classification]: addManyByKey(
          [
            ...cloudSQLUsageMeasures,
            "avgCpuReservedCores",
            "avgCpuUsedCoreHours",
            "avgDiskSizeBytes",
            "avgDiskUsedBytes",
            "avgNetworkSentBytes",
            "avgRamReservedBytes",
            "avgRamUsedBytes",
            "latestProvisionedCpu",
            "latestProvisionedDisk",
            "latestProvisionedRam",
            "latestUsedCpu",
            "latestUsedDisk",
            "latestUsedRam",
          ],
          latest,
          datum
        ),
      };
    },
    {} as { [classification: string]: CloudSQLUsageDatum }
  );

  return Object.values(map);
}

function addByKey(
  key: keyof CloudSQLUsageDatum,
  mostRecentData: CloudSQLUsageDatum,
  newData: CloudSQLUsageDatum
) {
  const currentValue = mostRecentData[key];
  const newValueAtKey = newData[key];

  if (typeof currentValue !== "number" || typeof newValueAtKey !== "number")
    return newValueAtKey;

  return currentValue + newValueAtKey;
}

function addManyByKey(
  keyNames: (keyof CloudSQLUsageDatum)[],
  mostRecentData: CloudSQLUsageDatum,
  newData: CloudSQLUsageDatum
): CloudSQLUsageDatum {
  return keyNames.reduce((accum, key) => {
    return {
      ...accum,
      [key]: addByKey(key, mostRecentData, newData),
    };
  }, mostRecentData);
}

function combineTableData(
  costData?: CloudSQLCostDatum[],
  usageData?: CloudSQLUsageDatum[]
) {
  if (!usageData) usageData = [];
  if (!costData) costData = [];

  if (usageData.length + costData.length === 0) return [];

  const classificationSrcData = [
    ...combineDatabaseIDs(usageData),
    ...costData,
  ].reduce((accum: { [classification: string]: CloudSQLResource }, datum) => {
    const classification = getClassificationKey(datum);

    const found = accum[classification];
    if (!found) {
      accum[classification] = { ...defaultCloudSQLResource };
    }

    accum[classification] = {
      ...accum[classification],
      ...datum,
    };
    return accum;
  }, {});

  return Object.values(classificationSrcData);
}

function createClassificationFilters(
  classification: Classification
): QueryFilter[] {
  if (!classification) return [];

  const { databaseType, projectId, region } = classification;

  const makeFilter = (dimName: string, value: string): QueryFilter =>
    value === "null"
      ? {
          name: dimName,
          operator: Operator.NOT_SET,
          values: null,
        }
      : {
          name: dimName,
          operator: Operator.EQUALS,
          values: [value],
        };

  return [
    makeFilter("databaseType", databaseType),
    makeFilter("projectId", projectId),
    makeFilter("region", region),
  ];
}

function getClassificationKey(datum: {
  databaseType: string | null;
  region: string | null;
  projectId: string | null;
}) {
  return (
    nullString(datum.databaseType) +
    "/" +
    nullString(datum.region) +
    "/" +
    nullString(datum.projectId)
  );
}

function nullString(value?: RawValue) {
  if (value === undefined) value = null;
  return `${value}`;
}

function getUsageChartMeasures(resourceType): string[] {
  switch (resourceType) {
    case CloudSQLResourceType.MEMORY:
      return ["totalRamUsedBytes", "totalRamReservedBytes"];
    case CloudSQLResourceType.DISK:
      return ["totalDiskSizeBytes", "totalDiskUsedBytes"];
    case CloudSQLResourceType.NETWORK:
      return ["totalNetworkSentBytes"];
    default:
      return ["totalCpuUsedCoreHours", "totalCpuReservedCores"];
  }
}

export default GCPDatabaseVisibilityContainer;
