import { useNavigateWithSearchParams } from "@/lib/react-router";
import { useTheme } from "@emotion/react";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import React from "react";
import copyText from "../../copyText";
import { InsightRecommendation } from "../types";
import { getInsightsUrl } from "../utils";

interface Props {
  insightRecommendation?: InsightRecommendation;
}

export default function ContraryRecommendationWarning(
  props: Props
): JSX.Element {
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  if (!props.insightRecommendation) {
    return <></>;
  }

  return (
    <Tooltip
      content={copyText.cudInventoryTableRightsizeRecommendationTooltip}
      width="400px"
    >
      <Box marginHorizontal={theme.space_xxs}>
        <Icon
          icon={faWarning}
          color={theme.feedback_warn}
          clickable
          onClick={() => {
            if (props.insightRecommendation) {
              navigate(
                getInsightsUrl(props.insightRecommendation.serviceType),
                {
                  searchParams: {
                    tab: "optimizations",
                    id: props.insightRecommendation.number,
                  },
                }
              );
            }
          }}
        />
      </Box>
    </Tooltip>
  );
}
