import { AWSRateAccountScope } from "@ternary/api-lib/constants/enums";
import { AWSRateSavingsPlanRecommendationEntity } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import {
  formatCurrency,
  formatPercentage,
} from "@ternary/api-lib/ui-lib/utils/formatNumber";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { Column } from "react-table";
import useRefFn from "../../../../hooks/useRefFn";
import { TableLegacy } from "../../../../ui-lib/components/Table";
import copyText from "../../copyText";
import { AWSAccountLevel, AWSCommittedUseSavingsPlanType } from "../types";

type TableData = {
  id: string;
  currentAverageHourlyOnDemandSpend: number;
  estimatedAverageUtilizationPercentage: number;
  estimatedMonthlySavingsAmount: number;
  estimatedOnDemandCost: number;
  estimatedROI: number;
  estimatedSavingsPercentage: number;
  estimatedSavingsPlanCost: number;
  groupingID: string;
  hourlyCommitmentToPurchase: number;
  linkedAccountID: string | null;
  offeringID: string;
  sourceRecommendation: AWSRateSavingsPlanRecommendationEntity;
  type: string;
  uniqCount: number;
};

type Props = {
  buttonIcon: JSX.Element;
  isLoading: boolean;
  accountScope: AWSRateAccountScope;
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[];
  onSelectRecommendation: (rec: AWSRateSavingsPlanRecommendationEntity) => void;
};

export default function AWSSavingsPlanRecommendationTable(props: Props) {
  const data = useMemo(
    () => getTableData(props.savingsPlanRecommendations),
    [props.savingsPlanRecommendations]
  );

  const onSelectRecommendation = useRefFn(props.onSelectRecommendation);

  const columns = useMemo((): Column<TableData>[] => {
    return [
      {
        id: "select",
        width: 48,
        NoAccessorCell: ({ row }) => (
          <Button
            iconStart={props.buttonIcon}
            primary
            size="tiny"
            onClick={() =>
              onSelectRecommendation(row.original.sourceRecommendation)
            }
          />
        ),
      },
      {
        accessor: "estimatedMonthlySavingsAmount",
        align: "right",
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        Header: copyText.awsSPTableHeader_00_estimatedMonthlySavingsAmount,
        width: 210,
      },
      {
        accessor: "estimatedSavingsPercentage",
        align: "right",
        Cell: ({ value }) => <>{formatPercentage(value)}</>,
        Header: copyText.awsSPTableHeader_01_estimatedSavingsPercentage,
        width: 210,
      },
      {
        accessor: "hourlyCommitmentToPurchase",
        align: "right",
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        Header: copyText.awsSPTableHeader_02_hourlyCommitmentToPurchase,
        width: 230,
      },
      {
        accessor: "estimatedSavingsPlanCost",
        align: "right",
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        Header: copyText.awsSPTableHeader_03_estimatedSavingsPlanCost,
        width: 210,
      },
      {
        accessor: "estimatedOnDemandCost",
        align: "right",
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        Header: copyText.awsSPTableHeader_04_estimatedOnDemandCost,
        width: 210,
      },
      {
        accessor: "currentAverageHourlyOnDemandSpend",
        align: "right",
        Cell: ({ value }) => <>{formatCurrency({ number: value })}</>,
        Header: copyText.awsSPTableHeader_05_currentAverageHourlyOnDemandSpend,
        width: 260,
      },
      {
        accessor: "estimatedAverageUtilizationPercentage",
        align: "right",
        Cell: ({ value }) => <>{formatPercentage(value)}</>,
        Header:
          copyText.awsSPTableHeader_06_estimatedAverageUtilizationPercentage,
        width: 210,
      },
      {
        accessor: "estimatedROI",
        align: "right",
        Cell: ({ value }) => <>{formatPercentage(value / 100)}</>,
        Header: copyText.awsSPTableHeader_07_estimatedROI,
        width: 160,
      },
      {
        accessor: "type",
        align: "center",
        Header: copyText.awsSPTableHeader_08_type,
        Cell: ({ value }) => <>{getTypeCopyText(value)}</>,
        width: 210,
      },
      {
        accessor: "offeringID",
        align: "left",
        Header: copyText.awsSPTableHeader_09_offeringID,
        width: 210,
      },
      ...(props.accountScope === AWSAccountLevel.LINKED
        ? [
            {
              accessor: "linkedAccountID",
              Header: copyText.awsSPTableHeader_10_linkedAccountID,
              width: 160,
            } as const,
          ]
        : []),
    ];
  }, [props.accountScope]);

  return (
    <TableLegacy
      columns={columns}
      data={data}
      initialState={{
        sortBy: [{ id: "estimatedMonthlySavingsAmount", desc: true }],
      }}
      isLoading={props.isLoading}
      showPagination
      row
      sortable
    />
  );
}

function getTypeCopyText(type: string) {
  switch (type) {
    case AWSCommittedUseSavingsPlanType.COMPUTE_SP:
    case AWSCommittedUseSavingsPlanType.EC2_INSTANCE_SP:
    case AWSCommittedUseSavingsPlanType.SAGEMAKER_SP: {
      const key: keyof typeof copyText = `awsSPTableValueType_${type}`;
      return copyText[key];
    }
    default:
      return type;
  }
}

function getGroupingID(rec: AWSRateSavingsPlanRecommendationEntity) {
  const DELIMITER = ",";

  return [rec.offeringID, rec.accountID]
    .map((value) => value !== null && value.toUpperCase())
    .join(DELIMITER);
}

function getTableData(
  savingsPlanRecommendations: AWSRateSavingsPlanRecommendationEntity[]
) {
  const recsGroupedByRow = groupBy(savingsPlanRecommendations, getGroupingID);

  const data: TableData[] = [];

  Object.values(recsGroupedByRow).forEach((recGroup) => {
    const rec = recGroup[0];

    data.push({
      id: rec.id,
      currentAverageHourlyOnDemandSpend: rec.currentAverageHourlyOnDemandSpend,
      estimatedAverageUtilizationPercentage:
        rec.estimatedAverageUtilization / 100,
      estimatedMonthlySavingsAmount: rec.estimatedMonthlySavingsAmount,
      estimatedOnDemandCost: rec.estimatedOnDemandCost,
      estimatedROI: rec.estimatedROI,
      estimatedSavingsPercentage: rec.estimatedSavingsPercentage / 100,
      estimatedSavingsPlanCost: rec.estimatedSavingsPlanCost,
      groupingID: getGroupingID(rec),
      hourlyCommitmentToPurchase: rec.hourlyCommitmentToPurchase,
      linkedAccountID: rec.accountID,
      offeringID: rec.offeringID,
      type: rec.savingsPlanType,
      sourceRecommendation: rec,
      uniqCount: recGroup.length,
    });
  });

  return data;
}
