import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import { FindRecommendationTypesQueryResult } from "@ternary/api-lib/core/queries/FindRecommendationTypesQuery";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type Options = UseQueryOptions<
  FindRecommendationTypesQueryResult["recommendationTypes"],
  CoreAPIClientError
>;

type Result = UseQueryResult<
  FindRecommendationTypesQueryResult["recommendationTypes"],
  CoreAPIClientError
>;

export default function useGetRecommendationTypes(options?: Options): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["recommendations", "types"],
    queryFn: () => client.getRecommendationTypes(),
    ...options,
  });
}
