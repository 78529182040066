import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type BudgetGates = ReturnType<typeof budgetGates>;

export default function budgetGates(params: GateGetterParameters) {
  return {
    canCreateBudgets: params._permissionsIncludeAllOf([
      Permission.CREATE_BUDGET,
    ]),
    canListBudgets: params._permissionsIncludeAllOf([Permission.READ_BUDGETS]),
    canUpdateBudgets: params._permissionsIncludeAllOf([
      Permission.UPDATE_BUDGET,
    ]),
    getCanDeleteSpecificBudget: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_BUDGET]) ||
      Boolean(createdByID === params.authenticatedUser.id),
  };
}
