import { useTheme } from "@emotion/react";
import React from "react";
import ContentLoader from "react-content-loader";
import timing from "../../constants/timing";

export function CardsLoaderLarge() {
  const theme = useTheme();

  return (
    <ContentLoader
      backgroundColor={theme.loading_skeleton_bg_color}
      foregroundColor={theme.loading_skeleton_fg_color}
      speed={timing.skeletonAnimationTime}
      viewBox="0 0 1000 1000"
      preserveAspectRatio="none"
      style={{
        width: "100%",
        height: `50rem`,
      }}
    >
      <rect
        x="0"
        y="20"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="480"
        height="470"
      />
      <rect
        x="500"
        y="20"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="480"
        height="470"
      />
      <rect
        x="0"
        y="520"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="480"
        height="470"
      />
      <rect
        x="500"
        y="520"
        rx={theme.skeletonLoaderBorderRadius}
        ry={theme.skeletonLoaderBorderRadius}
        width="480"
        height="470"
      />
    </ContentLoader>
  );
}
