import { faTableList } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import copyText from "../../copyText";

type TableData = {
  projectID: string;
  backupCost: string;
  computeCost: string;
  egressCost: string;
  region: string;
  storageCost: string;
  totalCost: string;
};

type Resource = {
  projectId: string | null;
  region: string | null;
  cost: number;
  totalBackupCost: number;
  totalComputeCost: number;
  totalEgressCost: number;
  totalStorageCost: number;
};

interface Props {
  isLoading: boolean;
  resources: Resource[];
  onInteraction: (
    interaction: GCPCloudSpannerResourceTable.Interaction
  ) => void;
}

export function GCPCloudSpannerResourceTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "showInstances",
        cell: ({ row }) => {
          return (
            <Flex>
              <Box>
                <Button
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                  onClick={() =>
                    props.onInteraction({
                      type: GCPCloudSpannerResourceTable.INTERACTION_RESOURCE_CLICKED,
                      selectedProjectID: row.original.projectID,
                    })
                  }
                />
              </Box>
            </Flex>
          );
        },
        size: 50,
      }),
      columnHelper.accessor("projectID", {
        header: copyText.cloudSpannerResourceTableHeaderProject,
        size: 200,
      }),
      columnHelper.accessor("region", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderRegion,
      }),
      columnHelper.accessor("computeCost", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderComputeCost,
      }),
      columnHelper.accessor("storageCost", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderStorageCost,
      }),
      columnHelper.accessor("backupCost", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderBackupCost,
      }),
      columnHelper.accessor("egressCost", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderEgressCost,
      }),
      columnHelper.accessor("totalCost", {
        meta: { align: "center" },
        header: copyText.cloudSpannerResourceTableHeaderTotalCost,
      }),
    ],
    [props.resources]
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.resources.map((item) => {
      return {
        projectID: item.projectId ?? "--",
        region: item.region ?? "--",
        computeCost: formatCurrency({ number: item.totalComputeCost }),
        storageCost: formatCurrency({ number: item.totalStorageCost }),
        egressCost: formatCurrency({ number: item.totalEgressCost }),
        backupCost: formatCurrency({ number: item.totalBackupCost }),
        totalCost: formatCurrency({ number: item.cost }),
      };
    });
    return tableData;
  }, [props.resources]);

  return (
    <Box>
      <Table
        columns={columns}
        data={data}
        isLoading={props.isLoading}
        initialState={{ pagination: { pageSize: 8 } }}
        showPagination
        sortable
      />
    </Box>
  );
}

GCPCloudSpannerResourceTable.INTERACTION_RESOURCE_CLICKED =
  `GCPCloudSpannerResourceTable.INTERACTION_RESOURCE_CLICKED` as const;

interface InteractionResourceClicked {
  type: typeof GCPCloudSpannerResourceTable.INTERACTION_RESOURCE_CLICKED;
  selectedProjectID: string;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPCloudSpannerResourceTable {
  export type Interaction = InteractionResourceClicked;
}

export default GCPCloudSpannerResourceTable;
