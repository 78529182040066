import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import CoreAPIClientError from "./CoreAPIClientError";
import { useCoreAPIClient } from "./CoreAPIClientProvider";
import { UpdateUserTenantSettingParams } from "./types";

type Options = UseMutationOptions<
  { settingsID: string },
  CoreAPIClientError,
  UpdateUserTenantSettingParams
>;

type Result = UseMutationResult<
  { settingsID: string },
  CoreAPIClientError,
  UpdateUserTenantSettingParams
>;

export default function useUpdateUserTenanSettings(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: (params) => client.updateUserTenantSettings(params),
    ...options,
  });
}
