import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import { format } from "date-fns";
import React from "react";
import { CSVLink } from "react-csv";
import Chip from "../../../../ui-lib/components/Chip";
import IconExport from "../../../../ui-lib/icons/IconExport";
import copyText from "../../copyText";
import { AzureCommittedUseVisibilityTableFilters } from "./AzureCommittedUseVisibilityContainer";

type CSVData = {
  headers: { key: string; label: string }[];
  rows: Record<string, string | number>[];
};

type Props = {
  csvData: CSVData;
  filters: AzureCommittedUseVisibilityTableFilters;
  onRemoveFilter: (
    filterKey: keyof AzureCommittedUseVisibilityTableFilters
  ) => void;
};

export default function AzureCommittedUseVisibilityTableControls(props: Props) {
  return (
    <Flex alignItems="center" minHeight={30}>
      <Flex flex="1 0 0">
        {(
          [
            "type",
            "commitmentName",
            "skuName",
            "term",
            "region",
            "commitmentId",
          ] as const
        ).map((key) => {
          const copyTextKey: keyof typeof copyText = `azureTableHeader_${key}`;
          const value = props.filters[key];

          if (value === null) return null;

          return (
            <Chip
              key={key}
              label={copyText[copyTextKey]}
              text={value || copyText.azureTableNull}
              onClick={() => props.onRemoveFilter(key)}
            />
          );
        })}
      </Flex>

      <Box>
        <CSVLink
          data={props.csvData.rows}
          headers={props.csvData.headers}
          filename={`Azure-Commitment-Inventory-${format(
            new Date(),
            "MM-dd-yyyy"
          )}`}
        >
          <Button iconStart={<IconExport />} secondary size="small">
            {copyText.exportButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </Flex>
  );
}
