import { isEqual } from "lodash";
import { useRef } from "react";

/**
 * Stores and returns the same object reference as long as
 * `_.isEqual(previousValue, currentValue)`
 *
 * @example
 * function Example() {
 *   return <Doubled numbers={[1, 2, 3]} />;
 * }
 *
 * function Doubled(props: { numbers: number[] }) {
 *   const numbers = useReferenceIfEqual(props.numbers);
 *
 *   // doubledNumbers only gets calculated on the first render, despite
 *   // props.numbers having a different reference on each render
 *   const doubledNumbers = useMemo(() => numbers.map((n) => n * 2), [numbers]);
 *
 *   return (
 *     <Box>
 *       {doubledNumbers.map((n) => (
 *         <Text>{n}</Text>
 *       ))}
 *     </Box>
 *   );
 * }
 */
export default function useReferenceIfEqual<T>(value: T) {
  const ref = useRef(value);

  if (!isEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}
