import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import getMergeState from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { isEqual } from "lodash";
import React, { ChangeEvent, useState } from "react";
import { Input } from "../../../types";
import Dropdown from "../../../ui-lib/components/Dropdown";
import { FormField } from "../../../ui-lib/components/Form";
import Modal from "../../../ui-lib/components/Modal";
import TextInput from "../../../ui-lib/components/TextInput";
import copyText from "../copyText";

const RequiredText = styled("span")(({ theme }) => ({
  color: theme.feedback_negative,
}));

type BillingInfo = {
  id: string;
  accountID: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  billingID: string | null;
  city: string | null;
  createdAt: string;
  createdByID: string;
  paymentTerm: string | null;
  state: string | null;
  tenantID: string;
  updatedAt: string | null;
  updatedByID: string | null;
  zipCode: string | null;
};

interface Props {
  billingInfo?: BillingInfo;
  isLoading: boolean;
  tenantName?: string;
  onInteraction: (interaction: BillingInformationModal.Interaction) => void;
}

interface State {
  accountIDInput: Input<string>;
  addressLine1Input: Input<string>;
  addressLine2Input: Input<string>;
  billingIDInput: Input<string>;
  cityInput: Input<string>;
  paymentTermInput: Input<string>;
  stateInput: Input<string>;
  zipCodeInput: Input<string>;
}

const initialState: State = {
  accountIDInput: { value: "", isValid: false },
  addressLine1Input: { value: "", isValid: false },
  addressLine2Input: { value: "", isValid: false },
  billingIDInput: { value: "", isValid: false },
  cityInput: { value: "", isValid: false },
  paymentTermInput: { value: "", isValid: false },
  stateInput: { value: "Select state", isValid: false },
  zipCodeInput: { value: "", isValid: false },
};

export function BillingInformationModal(props: Props) {
  const theme = useTheme();
  const billingState = formatBillingInfo(initialState, props.billingInfo);
  const [state, setState] = useState<State>(billingState);
  const mergeState = getMergeState(setState);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    const isValid = value.length > 0;

    mergeState({ [`${name}Input`]: { value, isValid } });
  }

  function handleClose() {
    setState(initialState);
    props.onInteraction({
      type: BillingInformationModal.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  function handleSubmit() {
    const payload = {
      ...(state.accountIDInput.value
        ? { accountID: state.accountIDInput.value }
        : {}),
      ...(state.billingIDInput.value
        ? { billingID: state.billingIDInput.value }
        : {}),
      ...(state.addressLine1Input.value
        ? { addressLine1: state.addressLine1Input.value }
        : {}),
      ...(state.addressLine2Input.value
        ? { addressLine2: state.addressLine2Input.value }
        : {}),
      ...(state.cityInput.value ? { city: state.cityInput.value } : {}),
      ...(state.stateInput.value && state.stateInput.isValid
        ? { state: state.stateInput.value }
        : {}),
      ...(state.zipCodeInput.value
        ? { zipCode: state.zipCodeInput.value }
        : {}),
      ...(state.paymentTermInput.value
        ? { paymentTerm: state.paymentTermInput.value }
        : {}),
    };
    if (props.billingInfo) {
      props.onInteraction({
        type: BillingInformationModal.INTERACTION_UPDATE_BILLING_INFO_CLICKED,
        id: props.billingInfo.id,
        params: payload,
      });
    } else {
      props.onInteraction({
        type: BillingInformationModal.INTERACTION_SUBMIT_BILLING_INFO_CLICKED,
        params: payload,
      });
    }
  }

  const hasAddressInput =
    state.addressLine1Input.value.length > 0 ||
    state.addressLine2Input.value.length > 0 ||
    state.cityInput.value.length > 0 ||
    state.zipCodeInput.value.length > 0 ||
    state.stateInput.value !== "Select state";

  function canSubmit(): boolean {
    if (props.billingInfo) {
      const propBillingInfo = formatBillingInfo(
        initialState,
        props.billingInfo
      );
      return !isEqual(propBillingInfo, state);
    }
    const hasBaseInfo =
      state.accountIDInput.value.length > 0 ||
      state.billingIDInput.value.length > 0 ||
      state.paymentTermInput.value.length > 0;

    let hasAddressInputFilled = false;
    if (hasAddressInput) {
      hasAddressInputFilled =
        (state.addressLine1Input.value.length > 0 ||
          state.addressLine2Input.value.length > 0) &&
        state.cityInput.value.length > 0 &&
        state.zipCodeInput.value.length > 0 &&
        state.stateInput.value !== "Select state";
    }

    return hasBaseInfo && hasAddressInputFilled;
  }

  return (
    <Modal
      closeOnClickOutside={true}
      isOpen={true}
      showCloseButton
      width={550}
      onClose={handleClose}
    >
      <Modal.Header>
        <Text
          appearance="h4"
          marginRight={theme.space_xl}
        >{`${props.tenantName ?? ""} - ${copyText.billingInfoModalTitle}`}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box
          maxHeight={400}
          overflow="scroll"
          padding={theme.space_xxs}
          borderBottom={`1px solid ${theme.section_card_border}`}
        >
          <FormField
            name="accountID"
            input={TextInput}
            label={copyText.billingInfoAccountIDLabel}
            type="text"
            value={state.accountIDInput.value}
            onChange={handleChange}
          />
          <FormField
            name="billingID"
            input={TextInput}
            label={copyText.billingInfoBillingIDLabel}
            type="text"
            value={state.billingIDInput.value}
            onChange={handleChange}
          />
          <FormField
            name="paymentTerm"
            input={TextInput}
            label={copyText.billingInfoPaymentTermLabel}
            type="text"
            value={state.paymentTermInput.value}
            onChange={handleChange}
          />
          <FormField
            name="addressLine1"
            input={TextInput}
            label={copyText.billingInfoAddressLine1Label}
            required={hasAddressInput}
            type="text"
            value={state.addressLine1Input.value}
            onChange={handleChange}
          />
          <FormField
            name="addressLine2"
            input={TextInput}
            label={copyText.billingInfoAddressLine2Label}
            type="text"
            value={state.addressLine2Input.value}
            onChange={handleChange}
          />
          <FormField
            name="city"
            input={TextInput}
            label={copyText.billingInfoCityLabel}
            required={hasAddressInput}
            type="text"
            value={state.cityInput.value}
            onChange={handleChange}
          />
          <Flex>
            <Box width={"50%"}>
              <Flex>
                <Text>{copyText.billingInfoStateLabel}</Text>
                {hasAddressInput && <RequiredText>*</RequiredText>}
              </Flex>
              <Dropdown
                options={states.map((state) => ({
                  label: state,
                  value: state,
                  onClick: () =>
                    mergeState({ stateInput: { value: state, isValid: true } }),
                }))}
                defaultSelectedOption={{ label: states[0], value: "" }}
                placement="right"
              >
                <Button
                  iconEnd={<Icon icon={faChevronDown} />}
                  style={{
                    border: `1px solid ${theme.border_color}`,
                    height: "38px",
                  }}
                  size="medium"
                  width={230}
                >
                  <Text truncate marginRight={theme.space_sm}>
                    {state.stateInput.value}
                  </Text>
                </Button>
              </Dropdown>
            </Box>
            <Box width={"50%"}>
              <FormField
                name="zipCode"
                input={TextInput}
                label={copyText.billingInfoZipcodeLabel}
                required={hasAddressInput}
                type="text"
                value={state.zipCodeInput.value}
                onChange={handleChange}
              />
            </Box>
          </Flex>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Flex justifyContent="space-between" width={"100%"}>
          <Button
            disabled={props.billingInfo === undefined}
            secondary
            size="medium"
            onClick={() => {
              if (!props.billingInfo) return;

              props.onInteraction({
                type: BillingInformationModal.INTERACTION_REMOVE_BILLING_INFO_CLICKED,
                billingInfoID: props.billingInfo.id,
              });
            }}
          >
            {copyText.billingInfoModalRemoveButtonLabel}
          </Button>

          <Box>
            <Button
              secondary
              marginRight={theme.space_sm}
              onClick={handleClose}
            >
              {copyText.cancelButtonLabel}
            </Button>
            <Button disabled={!canSubmit()} primary onClick={handleSubmit}>
              {copyText.submitButtonLabel}
            </Button>
          </Box>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

function formatBillingInfo(
  initialState: State,
  billingInfo?: BillingInfo
): State {
  if (!billingInfo) return initialState;

  const state = { ...initialState };

  Object.keys(billingInfo).forEach((item) => {
    if (state[`${item}Input`]) {
      state[`${item}Input`] = {
        value: billingInfo[item] ?? "",
        isValid: false,
      };
    }
  });

  return state;
}

const states = [
  "Select state",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

BillingInformationModal.INTERACTION_CANCEL_BUTTON_CLICKED =
  "BillingInformationModal.INTERACTION_CANCEL_BUTTON_CLICKED" as const;

BillingInformationModal.INTERACTION_SUBMIT_BILLING_INFO_CLICKED =
  "BillingInformationModal.INTERACTION_SUBMIT_BILLING_INFO_CLICKED" as const;

BillingInformationModal.INTERACTION_REMOVE_BILLING_INFO_CLICKED =
  "BillingInformationModal.INTERACTION_REMOVE_BILLING_INFO_CLICKED" as const;

BillingInformationModal.INTERACTION_UPDATE_BILLING_INFO_CLICKED =
  "BillingInformationModal.INTERACTION_UPDATE_BILLING_INFO_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof BillingInformationModal.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionRemoveBillingInfoClicked = {
  type: typeof BillingInformationModal.INTERACTION_REMOVE_BILLING_INFO_CLICKED;
  billingInfoID: string;
};

type InteractionSubmitBillingInfoClicked = {
  type: typeof BillingInformationModal.INTERACTION_SUBMIT_BILLING_INFO_CLICKED;
  params: {
    accountID?: string;
    addressLine1?: string;
    addressLine2?: string;
    billingID?: string;
    city?: string;
    paymentTerm?: string;
    state?: string;
    zipCode?: string;
  };
};

type InteractionUpdateBillingInfoClicked = {
  type: typeof BillingInformationModal.INTERACTION_UPDATE_BILLING_INFO_CLICKED;
  id: string;
  params: {
    accountID?: string;
    addressLine1?: string;
    addressLine2?: string;
    billingID?: string;
    city?: string;
    paymentTerm?: string;
    state?: string;
    zipCode?: string;
  };
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BillingInformationModal {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionRemoveBillingInfoClicked
    | InteractionSubmitBillingInfoClicked
    | InteractionUpdateBillingInfoClicked;
}

export default BillingInformationModal;
