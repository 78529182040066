import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type AlertGates = ReturnType<typeof alertGates>;

// NOTE: Naming of Permissions reflects changes that will be coming with CCM.
export default function alertGates(params: GateGetterParameters) {
  return {
    canListAlertRuleEvents: params._permissionsIncludeAllOf([
      Permission.READ_ALERT_RULE_EVENTS,
    ]),
    canListAlertRules: params._permissionsIncludeAllOf([
      Permission.READ_ALERT_RULES,
    ]),
    canCreateAlertRules: params._permissionsIncludeAllOf([
      Permission.CREATE_ALERT_RULE,
    ]),
    canUpdateAlertRules: params._permissionsIncludeAllOf([
      Permission.UPDATE_ALERT_RULE,
    ]),
    canListCostAlerts: params._permissionsIncludeAllOf([
      Permission.READ_COST_ALERTS,
    ]),
  };
}
