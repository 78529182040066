import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type RampPlanEntity = Awaited<
  ReturnType<CoreAPIClient["getRampPlansByTenantID"]>
>[0];

type Options = UseQueryOptions<RampPlanEntity[], CoreAPIClientError>;
type Result = UseQueryResult<RampPlanEntity[], CoreAPIClientError>;

export default function useGetRampPlansByTenantID(
  tenantID: string,
  options?: Options
): Result {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["rampPlans"],
    queryFn: () => client.getRampPlansByTenantID(tenantID),
    ...options,
  });
}
