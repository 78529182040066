import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateTenantParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { tenantID: string } & UpdateTenantParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { tenantID: string } & UpdateTenantParams
>;

export default function useUpdateTenant(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ tenantID, ...params }) =>
      client.updateTenant(tenantID, params),
    ...options,
  });
}
