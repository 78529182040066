import styled from "@emotion/styled";
import Box, { Props as BoxProps } from "@ternary/web-ui-lib/components/Box";
import * as CSS from "csstype";
import React from "react";

type StyleProps = {
  gridAutoColumns?: CSS.Property.GridAutoColumns;
  gridAutoFlow?: CSS.Property.GridAutoFlow;
  gridAutoRows?: CSS.Property.GridAutoRows;
  gridColumn?: CSS.Property.GridColumn;
  gridColumnGap?: CSS.Property.GridColumnGap;
  gridGap?: CSS.Property.GridGap;
  gridRow?: CSS.Property.GridRow;
  gridRowGap?: CSS.Property.GridRowGap;
  gridTemplate?: CSS.Property.GridTemplate;
  gridTemplateAreas?: CSS.Property.GridTemplateAreas;
  gridTemplateColumns?: CSS.Property.GridTemplateColumns;
  gridTemplateRows?: CSS.Property.GridTemplateRows;
};

const _Grid = styled(Box)<StyleProps>(
  ({
    gridAutoColumns,
    gridAutoFlow,
    gridAutoRows,
    gridColumn,
    gridColumnGap,
    gridGap,
    gridRow,
    gridRowGap,
    gridTemplate,
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
  }) => ({
    display: "grid",
    ...(gridAutoColumns && { gridAutoColumns }),
    ...(gridAutoFlow && { gridAutoFlow }),
    ...(gridAutoRows && { gridAutoRows }),
    ...(gridColumn && { gridColumn }),
    ...(gridColumnGap && { gridColumnGap }),
    ...(gridGap && { gridGap }),
    ...(gridRow && { gridRow }),
    ...(gridRowGap && { gridRowGap }),
    ...(gridTemplate && { gridTemplate }),
    ...(gridTemplateAreas && { gridTemplateAreas }),
    ...(gridTemplateColumns && { gridTemplateColumns }),
    ...(gridTemplateRows && { gridTemplateRows }),
  })
);

type Props<T> = BoxProps<T> & StyleProps;

export default function Grid<T>(props: Props<T>): JSX.Element {
  return <_Grid {...props} />;
}
