import { LinkWithSearchParams } from "@/lib/react-router";
import { createStructParam } from "@/lib/use-query-params";
import { TableLegacy } from "@/ui-lib/components/Table";
import { useTheme } from "@emotion/react";
import { faTableList } from "@fortawesome/free-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatCurrency } from "@ternary/web-ui-lib/utils/formatNumber";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import { Column } from "react-table";
import { useQueryParam, withDefault } from "use-query-params";
import { z } from "zod";
import externalLinks from "../../../../constants/externalLinks";
import useGatekeeper from "../../../../hooks/useGatekeeper";
import copyText from "../../copyText";
import { numberSort, stringSort } from "../../utils";
import {
  GCPComputeInstanceGroup,
  GCPComputeInstanceGroupFilters,
} from "../types";

type TableData = {
  family: string;
  familyType: string;
  instanceCount: number;
  productMemoryBytes: number;
  productVCPU: number;
  projectId: string;
  provisionedDiskBytes: number;
  region: string;
  totalCost: number;
};

type Props = {
  instanceGroups: GCPComputeInstanceGroup[];
  isLoadingInstanceGroups: boolean;
  onInteraction: (
    interaction: GCPComputeInstanceGroupTable.Interaction
  ) => void;
};

const sortRuleStruct = z.object({
  desc: z.boolean(),
  id: z.string(),
});

function GCPComputeInstanceGroupTable(props: Props) {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  const [sortRule, setSortRule] = useQueryParam(
    "group_table_sort",
    withDefault(createStructParam(sortRuleStruct), {
      desc: true,
      id: "totalCost",
    })
  );

  const hiddenColumns = gatekeeper.isConfiguredResourceLevelBilling
    ? []
    : ["instanceCount", "provisionedDiskBytes"];

  const tooltipTextFragements =
    copyText.gcpComputeTableInstanceGroupConfigureBillingTooltip.split(
      "%link%"
    );

  const tableData = useMemo(
    (): TableData[] =>
      props.instanceGroups.map((instanceGroup) => ({
        family: instanceGroup.family,
        familyType: instanceGroup.familyType,
        instanceCount: instanceGroup.instanceIDCount,
        productMemoryBytes: instanceGroup.productMemoryBytes,
        productVCPU: instanceGroup.productVCPU,
        projectId: instanceGroup.projectId,
        provisionedDiskBytes: instanceGroup.provisionedDiskBytes,
        region: instanceGroup.region,
        totalCost: instanceGroup.totalCost,
      })),
    [props.instanceGroups]
  );

  const columns = useMemo(
    (): Column<TableData>[] => [
      {
        id: "viewInstances",
        NoAccessorCell: ({ row }) => {
          return (
            <Tooltip
              content={
                <Box maxWidth={250}>
                  {gatekeeper.isConfiguredResourceLevelBilling ? (
                    copyText.gcpComputeTableInstanceGroupViewInstancesTooltip
                  ) : (
                    <div>
                      {tooltipTextFragements[0]}
                      <a
                        href={externalLinks.readmeGCPOnboardingDocumentation}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {
                          copyText.gcpComputeTableInstanceGroupConfigureBillingTooltipLink
                        }
                      </a>
                      {tooltipTextFragements[1]}
                    </div>
                  )}
                </Box>
              }
            >
              <LinkWithSearchParams
                disabled={!gatekeeper.isConfiguredResourceLevelBilling}
                searchParams={{
                  selected_group: getSelectedGroupJSON(row.original),
                }}
              >
                <Button
                  disabled={!gatekeeper.isConfiguredResourceLevelBilling}
                  iconStart={<Icon icon={faTableList} />}
                  primary
                  size="tiny"
                />
              </LinkWithSearchParams>
            </Tooltip>
          );
        },
        disableSortBy: true,
        width: 50,
      },
      {
        accessor: "family",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "family",
                filterValue: value,
              })
            }
          >
            {value || copyText.gcpComputeTableNull}
          </Text>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_family,
        sortType: stringSort,
        width: 60,
      },
      {
        accessor: "projectId",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "projectId",
                filterValue: value,
              })
            }
          >
            {value || copyText.gcpComputeTableNull}
          </Text>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_projectId,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "region",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "region",
                filterValue: value,
              })
            }
          >
            {value || copyText.gcpComputeTableNull}
          </Text>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_region,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "familyType",
        align: "center",
        Cell: ({ value }) => (
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            onClick={() =>
              props.onInteraction({
                type: GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED,
                filterKey: "familyType",
                filterValue: value,
              })
            }
          >
            {value || copyText.gcpComputeTableNull}
          </Text>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_familyType,
        sortType: stringSort,
        width: 100,
      },
      {
        accessor: "instanceCount",
        align: "right",
        Header: copyText.gcpComputeTableInstanceGroupHeader_instanceCount,
        sortType: numberSort,
        width: 75,
      },
      {
        accessor: "totalCost",
        align: "right",
        Cell: ({ value: totalCost }) => (
          <>{formatCurrency({ number: totalCost })}</>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_totalCost,
        sortDescFirst: true,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productVCPU",
        align: "right",
        Header: copyText.gcpComputeTableInstanceGroupHeader_productVCPU,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "productMemoryBytes",
        align: "right",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header: copyText.gcpComputeTableInstanceGroupHeader_productMemoryBytes,
        sortType: numberSort,
        width: 100,
      },
      {
        accessor: "provisionedDiskBytes",
        align: "right",
        Cell: ({ value: productMemoryBytes }) => (
          <>{prettyBytes(productMemoryBytes, { binary: true })}</>
        ),
        Header:
          copyText.gcpComputeTableInstanceGroupHeader_provisionedDiskBytes,
        sortType: numberSort,
        width: 100,
      },
    ],
    [props.instanceGroups]
  );

  return (
    <TableLegacy
      columns={columns}
      data={tableData}
      initialState={{ sortBy: [sortRule], hiddenColumns }}
      isLoading={props.isLoadingInstanceGroups}
      showPagination
      sortable
      onChangeSortBy={([sortRule]) => setSortRule(sortRule)}
    />
  );
}

function getSelectedGroupJSON(datum: TableData) {
  try {
    return JSON.stringify({
      family: datum.family,
      familyType: datum.familyType,
      projectId: datum.projectId,
      region: datum.region,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return "";
  }
}

GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED =
  `GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED` as const;

interface InteractionFilterClicked {
  type: typeof GCPComputeInstanceGroupTable.INTERACTION_FILTER_CLICKED;
  filterKey: keyof GCPComputeInstanceGroupFilters;
  filterValue: GCPComputeInstanceGroupFilters[keyof GCPComputeInstanceGroupFilters];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace GCPComputeInstanceGroupTable {
  export type Interaction = InteractionFilterClicked;
}
export default GCPComputeInstanceGroupTable;
