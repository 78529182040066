import { useQueries } from "@tanstack/react-query";
import DataQuery from "@ternary/api-lib/analytics/DataQuery";
import DataQueryExperimental from "@ternary/api-lib/analytics/DataQueryExperimental";
import {
  DataSource,
  Operator,
  TimeGranularity,
} from "@ternary/api-lib/constants/enums";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { ANALYTICS_QUERY_GC_TIME } from "../../../constants";
import { useAnalyticsApiClient } from "../../../context/AnalyticsQueryLoaderProvider";
import useAuthenticatedUser from "../../../hooks/useAuthenticatedUser";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { DateHelper } from "../../../lib/dates";
import { getLastInvoiceMonth } from "../../../utils/dates";

export function useGetForecastingTotalsData(params: {
  lookBackDate: Date | null;
  measure: string;
  vendor: string | undefined;
}) {
  const authenticatedUser = useAuthenticatedUser();
  const client = useAnalyticsApiClient();
  const gatekeeper = useGatekeeper();

  const dateHelper = new DateHelper();

  const enabled = gatekeeper.hasConfiguredDataIntegration;

  const tenantID = authenticatedUser.tenant.fsDocID;

  return useQueries({
    queries: [
      {
        queryKey: ["forecastingTotals", "currentMTD", params],
        queryFn: async () => {
          const query = new DataQuery({
            dataSource: DataSource.BILLING,
            dateRange: [dateHelper.firstOfMonth(), dateHelper.date],
            measures: [params.measure],
            ...(params.vendor
              ? {
                  preAggFilters: [
                    {
                      name: "vendor",
                      operator: Operator.EQUALS,
                      values: [params.vendor],
                    },
                  ],
                }
              : {}),
          });

          const result = await client.loadData(tenantID, query);

          const summary = result.response[0];

          return Number(summary[params.measure] ?? 0);
        },
        enabled,
        gcTime: ANALYTICS_QUERY_GC_TIME,
      },
      {
        queryKey: ["forecastingTotals", "previousMTD", params],
        queryFn: async () => {
          const query = new DataQuery({
            dataSource: DataSource.BILLING,
            dateRange: [
              startOfMonth(dateHelper.sameDayLastMonth()),
              dateHelper.sameDayLastMonth(),
            ],
            measures: [params.measure],
            ...(params.vendor
              ? {
                  preAggFilters: [
                    {
                      name: "vendor",
                      operator: Operator.EQUALS,
                      values: [params.vendor],
                    },
                  ],
                }
              : {}),
          });

          const result = await client.loadData(tenantID, query);

          const summary = result.response[0];

          return Number(summary[params.measure] ?? 0);
        },
        enabled,
        gcTime: ANALYTICS_QUERY_GC_TIME,
      },
      {
        queryKey: ["forecastingTotals", "lastMonthTotal", params],
        queryFn: async () => {
          const query = new DataQuery({
            dataSource: DataSource.BILLING,
            dateRange: [
              dateHelper.firstOfLastMonthMinusThreeDays(),
              dateHelper.lastDayLastMonthPlusThreeDays(),
            ],
            measures: [params.measure],
            preAggFilters: [
              {
                name: "invoiceMonth",
                operator: Operator.EQUALS,
                values: [getLastInvoiceMonth()],
              },
              ...(params.vendor
                ? [
                    {
                      name: "vendor",
                      operator: Operator.EQUALS,
                      values: [params.vendor],
                    },
                  ]
                : []),
            ],
          });

          const result = await client.loadData(tenantID, query);

          const summary = result.response[0];

          return Number(summary[params.measure] ?? 0);
        },
        enabled,
        gcTime: ANALYTICS_QUERY_GC_TIME,
      },
      {
        queryKey: ["forecastingTotals", "last3MonthsAverage", params],
        queryFn: async () => {
          const startDate = startOfMonth(sub(dateHelper.date, { months: 3 }));
          const endDate = endOfMonth(sub(dateHelper.date, { months: 1 }));

          const query = new DataQuery({
            dataSource: DataSource.BILLING,
            dateRange: [startDate, endDate],
            granularity: TimeGranularity.MONTH,
            measures: [params.measure],
            ...(params.vendor
              ? {
                  preAggFilters: [
                    {
                      name: "vendor",
                      operator: Operator.EQUALS,
                      values: [params.vendor],
                    },
                  ],
                }
              : {}),
          });

          const result = await client.loadData(tenantID, query);

          const total = result.response.reduce(
            (accum, entry) => accum + Number(entry[params.measure]),
            0
          );

          return total > 0 ? total / 3 : 0;
        },
        enabled,
        gcTime: ANALYTICS_QUERY_GC_TIME,
      },
      {
        queryKey: ["forecastingTotals", "currentMtdForecastedSpend", params],
        queryFn: async () => {
          const query = new DataQueryExperimental({
            dataSource: DataSource.BILLING,
            dateRange: params.lookBackDate
              ? [params.lookBackDate, dateHelper.date]
              : [dateHelper.firstOfLastMonth(), dateHelper.date],
            measures: [params.measure],
            granularity: TimeGranularity.DAY,
            forecasting: {
              algorithm: "linear-regression",
              dateRange: [dateHelper.date, dateHelper.lastDayThisMonth()],
              measure: params.measure,
            },
            ...(params.vendor
              ? {
                  preAggFilters: [
                    {
                      name: "vendor",
                      operator: Operator.EQUALS,
                      values: [params.vendor],
                    },
                  ],
                }
              : {}),
          });

          const result = await client.loadDataExperimental(tenantID, query);

          const currentMtdForecastedSpend = result.forecast.reduce(
            (accum, entry) => accum + Number(entry[params.measure] ?? 0),
            0
          );

          return currentMtdForecastedSpend;
        },
        enabled,
        gcTime: ANALYTICS_QUERY_GC_TIME,
      },
    ],
  });
}
