import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateAlibabaIntegrationParams } from "@/api/core/types";
import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  { integrationID } & UpdateAlibabaIntegrationParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  { integrationID } & UpdateAlibabaIntegrationParams
>;

export default function useUpdateAlibabaIntegration(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ integrationID, ...params }) =>
      client.updateAlibabaIntegration(integrationID, params),
    ...options,
  });
}
