import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Params = {
  apiKeyID: string;
};

type Options = UseMutationOptions<string, CoreAPIClientError, Params>;

type Result = UseMutationResult<string, CoreAPIClientError, Params>;

export default function useDeleteApiKey(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ apiKeyID }) => client.deleteApiKey(apiKeyID),
    ...options,
  });
}
