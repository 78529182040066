import {
  isAWSIntegration,
  isAzureIntegration,
  isGCPIntegration,
} from "@/utils/typeGuards";
import Permission from "@ternary/api-lib/constants/permissions";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CommittedUseGates = ReturnType<typeof committedUseGates>;

export default function committedUseGates(params: GateGetterParameters) {
  return {
    canViewCommittedUsePage: params._permissionsIncludeAllOf([
      Permission.READ_COMMITTED_USE_DATA,
    ]),
    isAWSCommittedUseConfigured: (() => {
      const integrations = params.integrations.filter(isAWSIntegration);

      return integrations.find(
        (integration) =>
          integration.validations.find(
            // TODO: capture validations in enums, as we consume more of them
            (validation) =>
              validation.name === "List AWS Reserved Instances" ||
              validation.name === "List AWS Savings Plans"
          )?.success
      );
    })(),
    isAzureCommittedUseConfigured: (() => {
      const integrations = params.integrations.filter(isAzureIntegration);
      return integrations.find((integration) =>
        integration.validations.find(
          // TODO: capture validations in enums, as we consume more of them
          (validation) => {
            if (
              validation.success &&
              (validation.name === "List Azure Savings Plans" ||
                validation.name === "List Azure Reservations")
            ) {
              return validation;
            }
          }
        )
      );
    })(),
    isCommittedUseConfiguredOnAllGCPClouds: (() => {
      const integrations = params.integrations.filter(isGCPIntegration);

      if (!integrations.length) {
        return false;
      }

      return integrations.every(
        (integration) =>
          integration.validations.find(
            (validation) => validation.name === "List Compute Commitments"
          )?.success
      );
    })(),
    isCommittedUseConfigured: (() => {
      const integrations = params.integrations.filter(isGCPIntegration);

      return integrations.reduce((isConfigured: boolean, integration) => {
        const validationSuccess = integration.validations.find(
          // TODO: capture validations in enums, as we consume more of them
          (validation) => validation.name === "List Compute Commitments"
        )?.success;

        if (!validationSuccess) {
          return isConfigured;
        } else {
          return true;
        }
      }, false);
    })(),
  };
}
