import React from "react";
import { ResponsiveContainer } from "recharts";
import { useIsInHiddenTab } from "../context/IsInHiddenTabProvider";

export function ChartWrapper(props: {
  children: JSX.Element;
  height?: string | number;
  isServer?: boolean;
}) {
  const isInHiddenTab = useIsInHiddenTab();

  if (isInHiddenTab) return null;

  if (props.isServer) {
    return props.children;
  }

  return (
    <ResponsiveContainer
      debounce={1}
      height={props.height ?? "100%"}
      width="100%"
      minHeight={100}
      minWidth={100}
    >
      {props.children}
    </ResponsiveContainer>
  );
}
