import { buildCubeQuery } from "@/api/analytics/utils";
import { ANALYTICS_QUERY_GC_TIME } from "@/constants";
import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource } from "@ternary/api-lib/constants/enums";
import UError from "unilib-error";
import { validate } from "../../../../api/analytics/utils/Cubestruct";
import { UseQueryOptions, UseQueryResult } from "../../../../lib/react-query";
import {
  AWSCommitmentInventoryDatum,
  AWSCommitmentInventoryStruct,
} from "../types";

export interface Params {
  dateRange: Date[];
  queryFilters?: QueryFilter[];
  isTotals?: boolean;
}

export default function useGetAWSCommitmentInventory(
  params: Params,
  options?: UseQueryOptions<AWSCommitmentInventoryDatum[], UError>
): UseQueryResult<AWSCommitmentInventoryDatum[], UError> {
  const client = useAnalyticsApiClient();

  return useQuery({
    queryKey: ["awsCommitmentInventory", params],
    queryFn: async () => {
      const costUsageData = await client.load(
        buildCubeQuery({
          dataSource: DataSource.AWS_COMMITMENT_TABLE,
          dateRange: params.dateRange,
          measures: [
            "effectiveSavingsRate",
            "realizedSavings",
            "unrealizedSavings",
            "utilizationPercentage",
          ],
          dimensions: params.isTotals
            ? []
            : [
                "availabilityZone",
                "cloudId",
                "commitmentId",
                "expirationDate",
                "instance_count",
                "instance_type",
                "offeringClass",
                "offeringType",
                "operatingSystem",
                "purchaseDate",
                "region",
                "scope",
                "serviceDescription",
                "state",
                "term",
                "type",
                "upfrontCost",
                "usagePrice",
              ],
          queryFilters: params.queryFilters,
        })
      );

      return costUsageData.map<AWSCommitmentInventoryDatum>((datum) => {
        const [error, validData] = validate(
          datum,
          AWSCommitmentInventoryStruct
        );

        if (error) {
          throw new UError("INVALID_AWS_COMMITMENT_INVENTORY_DATUM", {
            context: { error, result: datum },
          });
        }

        const [family, instanceSize] = (validData.instance_type ?? ".").split(
          "."
        );

        return {
          availabilityZone: validData.availabilityZone ?? "",
          cloudId: validData.cloudId ?? "",
          commitmentId: validData.commitmentId ?? "",
          effectiveSavingsRate: validData.effectiveSavingsRate ?? 0,
          expirationDate: validData.expirationDate ?? "",
          family,
          instanceCount: validData.instance_count ?? 0,
          instanceSize,
          instanceType: validData.instance_type ?? "",
          offeringClass: validData.offeringClass ?? "",
          offeringType: validData.offeringType ?? "",
          operatingSystem: validData.operatingSystem ?? "",
          purchaseDate: validData.purchaseDate ?? "",
          realizedSavings: validData.realizedSavings ?? 0,
          region: validData.region ?? "",
          scope: validData.scope ?? "",
          serviceDescription: validData.serviceDescription ?? "",
          state: validData.state ?? "",
          term: validData.term ?? "",
          type: validData.type ?? "",
          unrealizedSavings: validData.unrealizedSavings ?? 0,
          upfrontCost: validData.upfrontCost ?? 0,
          usagePrice: validData.usagePrice ?? 0,
          utilizationPercentage: validData.utilizationPercentage ?? 0,
        };
      });
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
