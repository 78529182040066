import { isGCPIntegration } from "@/utils/typeGuards";
import { CloudCapability } from "@ternary/api-lib/constants/enums";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type CarbonFootprintGates = ReturnType<typeof carbonFootprintGates>;

export default function carbonFootprintGates(params: GateGetterParameters) {
  return {
    isCarbonFootprintConfigured: (() => {
      const integrations = params.integrations.filter(isGCPIntegration);

      return integrations.reduce((isConfigured: boolean, integration) => {
        const monitoring = integration.config.carbonFootprintSource;

        const validationSuccess = integration.validations.find(
          (validation) => validation.name === CloudCapability.CARBON_FOOTPRINT
        )?.success;

        if (
          !validationSuccess ||
          !monitoring ||
          !monitoring.projectID ||
          !monitoring.datasetID ||
          !monitoring.tableID
        ) {
          return isConfigured;
        } else {
          return true;
        }
      }, false);
    })(),
  };
}
