import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  actionMenuItemGenerateReport: "Generate Report",
  actionMenuItemDeleteBill: "Delete Bill",
  cloudProviderAWSHeader: "AWS:",
  cloudProviderGCPHeader: "GCP:",
  coverageHeader: "Coverage:",
  coverageReportHeader: "Coverage Report",
  csvNormalRecordsTitlePrefix: "normalRecords",
  csvSourceRecordsTitlePrefix: "sourceRecords",
  csvTargetRecordsTitlePrefix: "targetRecords",
  backButtonLabel: "Back to List",
  billTableHeaderBillName: "Bill Name",
  billTableHeaderCreatedAt: "Created At",
  billTableHeaderCreatedBy: "Created By",
  billTableHeaderSource: "Source",
  costComparePageTitle: "Cost Compare",
  createBillButtonLabel: "Upload New Bill (.csv)",
  createBillPlaceholder: "Enter Name for this Bill",
  csvNormalizedUsageButtonLabel: "Normalized Usage.csv",
  csvOriginalBillButtonLabel: "Original Bill.csv",
  csvTargetOutputButtonLabel: "Target Output.csv",
  dataTransparencyLearnMoreLink: "here",
  dataTransparencyLearnMorePrefix: "If you'd like to learn more about how to interpret this data, please click",
  dataTransparencyLearnMoreSuffix: " to read our technical documentation.",
  dataTransparencyIntroduction: "We believe that the only way to ensure real transparency is to show our work. You can download the data at various stages in our transformation pipeline to get double check anything you'd like, or to get additional information about how these results were calculated.",
  dataTransparencyHeader: "Data Transparency",
  deleteBillConfirmationTitle: "Delete Bill",
  deleteBillConfirmationMessage: "Are you sure you want to delete this bill? This operation cannot be undone.",
  emptyPlaceholderTitleGeneratingReport: "Generating Report",
  errorCreatingBillMessage: "An error occured while attempting to upload the bill. Please try again.",
  errorCreatingTakeoutReportMessage: "An error occured while attempting to create the takeout report. Please try again.",
  errorDeletingBillMessage: "An error occured while attempting to delete the bill. Please try again.",
  estimatedMontlySavingsHeader: "Estimated Monthly Savings:",
  exportAsCSVButtonLabel: "Export As CSV",
  linkLabelHere: "here",
  modalTitleCreateBill: "Upload New Bill",
  modalFooterLearnMore: "If you  would like to know more about the supported CSV format, please check out our documentation",
  modalFooterLearnMoreLink: "here",
  noBillsMessage: "No Bills to Display",
  savingsBreakdownTableHeaderAWS: "AWS",
  savingsBreakdownTableHeaderChange: "Change",
  savingsBreakdownTableHeaderDescription: "Description",
  savingsBreakdownTableHeaderGCP: "GCP",
  sourceCostConvertedHeader: "Source Cost Converted:",
  sourceCostNormalizedHeader: "Source Cost Normalized:",
  sourceCostUnconvertedHeader: "Source Cost Unconverted:",
  successCreatingBillMessage: "The bill has been successfully uploaded.",
  successCreatingTakeoutReport: "The takeout report has been successfully created.",
  successDeletingBillMessage: "The bill has been successfully deleted.",
  savingsBreakdownTableDescriptionSubtotal: "Subtotal",
  savingsBreakdownTableDescriptionUnconverted: "Unconverted",
  savingsBreakdownTableDescriptionTotal: "Total",
  unconvertedHeader: "Unconverted",
  
};
