import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import UError from "unilib-error";
import isURL from "validator/lib/isURL";
import { config } from "../config";

const ignoreCodes = ["ECONNABORTED", "ERR_NETWORK"];

type SendOptions<TBody> = {
  body?: AxiosRequestConfig<TBody>["data"];
  headers?: AxiosRequestConfig["headers"];
  method?: AxiosRequestConfig["method"];
};

interface Config {
  baseURL: string;
  redirectOnAuthError: () => Promise<void>;
}

export default class HTTPRequestSender {
  private readonly _className = "HTTPRequestSender";

  private readonly _httpClient: AxiosInstance;
  private readonly _redirectOnAuthError: () => Promise<void>;

  public constructor({ baseURL, redirectOnAuthError }: Config) {
    if (
      typeof baseURL !== "string" ||
      (baseURL = baseURL.trim()) === "" ||
      !isURL(baseURL, {
        protocols: ["http", "https"],
        require_protocol: true,
        require_tld: false,
      })
    ) {
      throw new UError(`${this._className}.constructor/INVALID_BASE_URL`, {
        context: baseURL,
      });
    }

    this._redirectOnAuthError = redirectOnAuthError;
    this._httpClient = axios.create({ baseURL, withCredentials: true });
  }

  public async send<TBody>(
    endpoint: string,
    options?: SendOptions<TBody>
  ): Promise<unknown> {
    try {
      if (typeof endpoint !== "string" || (endpoint = endpoint.trim()) === "") {
        throw new UError(`${this._className}.send/INVALID_ENDPOINT`, {
          context: { endpoint },
        });
      }

      const { data } = await this._httpClient.request({
        url: endpoint,
        ...(options?.body ? { data: options.body } : {}),
        ...(options?.headers ? { headers: options.headers } : {}),
        ...(options?.method ? { method: options.method } : {}),
      });

      return data;
    } catch (error) {
      if (!(error instanceof Error)) {
        throw new Error("INVALID_ERROR_TYPE");
      }

      if (error.message.startsWith(`${this._className}.send/`)) {
        throw error;
      }

      if (error instanceof AxiosError) {
        const response = error.response;

        if (!response || typeof response.status !== "number") {
          throw new UError(`${this._className}.send/ERROR_UNEXPECTED`, {
            context: { cause: error },
            tags: { ignore: error.code && ignoreCodes.includes(error.code) },
          });
        }

        if (response.status === 0) {
          throw new UError(`${this._className}.send/ERROR_NETWORK`, {
            context: { cause: error },
            tags: { ignore: true },
          });
        }

        if (response.status === 401) {
          await this._redirectOnAuthError();
          throw new UError(`${this._className}.send/ERROR_UNAUTHORIZED`, {
            tags: { ignore: true },
          });
        }

        // We can filter these out from Sentry since backend team has their
        // own way of monitoring these types of errors.
        if (
          response.status === 500 &&
          response.config.baseURL === config.ANALYTICS_API_BASE_URL
        ) {
          throw new UError(
            `${this._className}.send/ERROR_UNEXPECTED_ANALYTICS`,
            {
              context: { cause: error },
              tags: { ignore: true },
            }
          );
        }

        const responseMessage = getErrorMessageFromResponse(response);

        const message = responseMessage
          ? responseMessage
          : (error.message ?? "No Error Message");

        throw new UError(message, {
          context: {
            cause: error,
            status: response.status,
          },
          tags: {
            ignore: message.includes("PERMISSION_DENIED"),
          },
        });
      }

      throw new UError(`${this._className}.send/ERROR_UNEXPECTED`, {
        context: { cause: error },
      });
    }
  }
}

function getErrorMessageFromResponse(
  response: AxiosResponse
): string | undefined {
  if (!response.data) return undefined;

  if (typeof response.data === "string") {
    return response.data as string;
  }

  if (typeof response.data === "object") {
    if ("reason" in response.data && typeof response.data.reason === "string") {
      return response.data.reason as string;
    }

    if (
      "message" in response.data &&
      typeof response.data.message === "string"
    ) {
      return response.data.message as string;
    }
  }

  return undefined;
}
