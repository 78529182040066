export const awsDatabaseElastiCacheMeasures = {
  cost: "cost",
  backupStorageCost: "backupStorageCost",
  nodeCost: "nodeCost",
  backupStorageUsage: "backupStorageUsage",
  nodeUsage: "nodeUsage",
} as const;

export const awsDatabaseElastiCacheDimensions = {
  payerAccountId: "payerAccountId",
  usageAccountId: "usageAccountId",
  clusterArn: "clusterArn",
  clusterId: "clusterId",
  cacheEngine: "cacheEngine",
  instanceFamily: "instanceFamily",
  instanceType: "instanceType",
  instanceMemory: "instanceMemory",
  instanceVcpu: "instanceVcpu",
  instanceNetworkPerformance: "instanceNetworkPerformance",
  region: "region",
} as const;

export type AWSDatabaseElastiCacheSpendSummaryEntity = {
  totalCost: number;
};

export type AWSDatabaseElastiCacheInstanceGroupFilters = {
  cacheEngine: string | null;
  clusterId: string | null;
  instanceMemory: string | null;
  instanceType: string | null;
  instanceVcpu: string | null;
  payerAccountId: string | null;
  region: string | null;
  usageAccountId: string | null;
};

export type AWSDatabaseElastiCacheGroup = {
  backupStorageCost: number;
  backupStorageUsage: number;
  cacheEngine: string;
  clusterId: string;
  cost: number;
  instanceFamily: string;
  instanceMemory: string;
  instanceNetworkPerformance: string;
  instanceType: string;
  instanceVcpu: string;
  nodeCost: number;
  nodeUsage: number;
  payerAccountId: string;
  region: string;
  usageAccountId: string;
};
