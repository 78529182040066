import Icon, { Props as IconProps } from "@ternary/web-ui-lib/components/Icon";
import React from "react";

export default function IconSnowflake(props: IconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 395 382"
      >
        <path
          fill="#FEFFFF"
          opacity="1.000000"
          stroke="none"
          d=" M223.000000,383.000000   C150.000015,383.000000 77.499969,382.969116 5.000208,383.096344   C1.575756,383.102356 0.901768,382.423920 0.904877,378.999939   C1.018086,254.333405 1.018077,129.666733 0.904898,5.000194   C0.901789,1.576539 1.575890,0.901940 4.999974,0.904928   C133.999847,1.017481 262.999847,1.017471 391.999725,0.904967   C395.423218,0.901982 396.098267,1.575645 396.095154,4.999907   C395.981934,129.666458 395.981934,254.333130 396.095093,378.999664   C396.098206,382.423035 395.425537,383.105530 392.000000,383.097198   C335.833588,382.960297 279.666748,383.000000 223.000000,383.000000  z"
        />
        <path
          fill="#2BB6E8"
          opacity="1.000000"
          stroke="none"
          d=" M211.113892,56.010841   C211.116180,52.514900 211.078415,49.515781 211.127731,46.518105   C211.315491,35.105160 219.608856,26.469296 230.398941,26.417654   C240.840256,26.367680 249.438416,34.828938 249.664856,45.998051   C249.934891,59.319157 249.834686,72.650764 249.686951,85.975510   C249.642883,89.948952 250.483749,90.640823 253.992599,88.468872   C264.180847,82.162453 274.501709,76.069618 284.788696,69.923439   C294.987579,63.829922 306.145691,65.954422 311.959778,75.057648   C318.189209,84.811127 315.765564,97.327515 305.717957,103.499588   C283.880035,116.914230 261.929871,130.159012 239.761658,143.018219   C226.532745,150.691956 211.216324,141.129013 211.129181,125.472664   C211.001236,102.485237 211.107422,79.496498 211.113892,56.010841  z"
        />
        <path
          fill="#2BB6E8"
          opacity="1.000000"
          stroke="none"
          d=" M224.940582,348.565247   C215.792847,344.942017 211.198929,338.589142 211.172745,329.370483   C211.098480,303.219910 211.024094,277.068451 211.160309,250.918427   C211.245590,234.542892 227.093384,225.570374 241.089706,233.888382   C262.404999,246.556030 283.665466,259.316589 304.896637,272.124817   C312.931122,276.971802 316.408051,284.721008 314.968536,293.788483   C313.770844,301.332642 307.776459,307.610382 300.026764,309.492065   C294.648682,310.797882 289.827209,309.343964 285.255005,306.603607   C274.977966,300.444061 264.718018,294.255859 254.418930,288.133453   C253.184723,287.399811 251.929321,285.739929 250.509598,286.460541   C249.047302,287.202728 249.813446,289.147400 249.807602,290.547760   C249.756226,302.873413 249.806198,315.199432 249.769653,327.525177   C249.724167,342.871399 240.526138,350.824951 224.940582,348.565247  z"
        />
        <path
          fill="#2AB6E8"
          opacity="1.000000"
          stroke="none"
          d=" M38.954735,225.378494   C40.083931,218.052917 43.940685,213.162582 49.854839,209.647293   C61.542591,202.700302 73.179131,195.667130 85.487122,188.279282   C74.992065,181.973328 65.046577,176.001099 55.105335,170.021820   C52.682579,168.564636 50.203758,167.186996 47.870834,165.596588   C38.442947,159.169342 35.964661,146.921448 42.098415,137.403854   C47.893173,128.412292 58.908012,126.109360 68.857910,132.041992   C89.856377,144.562317 110.844055,157.102753 131.723892,169.819321   C146.830322,179.019638 146.827972,197.601410 131.714966,206.796951   C111.113609,219.331863 90.337440,231.579163 69.708282,244.068802   C62.768925,248.270157 55.779072,249.584503 48.551697,245.239334   C41.282501,240.869019 38.325024,234.175858 38.954735,225.378494  z"
        />
        <path
          fill="#2AB5E8"
          opacity="1.000000"
          stroke="none"
          d=" M348.486877,154.048828   C346.737183,160.043793 343.062622,163.923187 338.155029,166.840134   C327.858246,172.960220 317.581787,179.114838 307.326599,185.304367   C305.942230,186.139923 304.243622,186.623032 303.201263,188.589020   C314.994507,195.678207 326.801453,202.709259 338.540222,209.852448   C348.491119,215.907700 351.898254,226.503128 347.264008,236.518265   C342.575317,246.651184 330.915527,251.114029 321.289825,245.518112   C298.712128,232.392502 276.225739,219.083435 254.111908,205.196518   C241.387314,197.205811 241.785675,178.737030 254.615524,170.797546   C276.397858,157.318024 298.449768,144.270050 320.504395,131.237015   C327.231873,127.261444 334.344635,127.542572 340.767273,132.051239   C348.271423,137.319138 350.263519,144.908813 348.486877,154.048828  z"
        />
        <path
          fill="#2AB5E8"
          opacity="1.000000"
          stroke="none"
          d=" M81.745575,103.313660   C73.883011,97.551437 71.275116,89.986359 73.496643,81.112808   C75.591774,72.744141 81.284843,67.761726 89.984657,66.568253   C94.466782,65.953384 98.532219,67.240082 102.342705,69.522774   C112.905968,75.850716 123.458786,82.196289 134.050003,88.477104   C135.424820,89.292397 136.627701,90.583542 138.941803,90.593620   C138.941803,79.441757 138.937912,68.360733 138.943756,57.279709   C138.946045,52.949612 138.788437,48.609730 139.020996,44.291397   C139.563705,34.213451 148.063705,26.629169 158.441391,26.758301   C168.524307,26.883764 176.976868,34.559914 177.052734,44.506775   C177.261047,71.818542 177.369537,99.135796 177.039185,126.445091   C176.851776,141.938568 160.708817,150.536316 147.117661,142.538910   C125.319275,129.712173 103.718788,116.549118 81.745575,103.313660  z"
        />
        <path
          fill="#2AB5E8"
          opacity="1.000000"
          stroke="none"
          d=" M140.242981,337.374756   C138.815964,333.825043 138.933640,330.487305 138.937271,327.154846   C138.950882,314.662415 138.854187,302.169006 138.996017,289.678192   C139.037842,285.992767 138.397598,285.188354 134.969971,287.315033   C124.791885,293.630005 114.468491,299.711395 104.182083,305.850891   C92.954903,312.551941 81.786835,310.579132 75.845741,300.886261   C69.482857,290.505249 72.746376,278.528900 83.928452,271.780640   C104.588776,259.312378 125.341446,246.996719 145.967056,234.471603   C152.939682,230.237396 160.019745,229.166809 167.215057,233.197800   C174.400955,237.223557 177.274811,243.729218 177.226273,251.960434   C177.075043,277.610657 177.197968,303.262390 177.155304,328.913391   C177.138336,339.116150 171.714859,346.672943 163.056946,348.836945   C153.654861,351.186951 145.291763,347.094971 140.242981,337.374756  z"
        />
        <path
          fill="#2CB6E8"
          opacity="1.000000"
          stroke="none"
          d=" M213.827667,165.165649   C217.555435,169.007446 221.040802,172.589462 224.518829,176.178574   C232.441864,184.354614 232.403641,191.636917 224.420105,199.917297   C217.482803,207.112534 210.602768,214.363556 203.617096,221.511414   C198.562088,226.683807 189.917496,226.737778 184.924286,221.620178   C177.130096,213.631836 169.378403,205.600494 161.711197,197.490448   C156.706116,192.196289 156.683990,183.819153 161.664703,178.557220   C169.451416,170.330872 177.317444,162.178146 185.240356,154.082764   C189.806717,149.416977 198.549561,149.442047 203.211060,154.084641   C206.750641,157.609848 210.130844,161.295074 213.827667,165.165649  z"
        />
        <path
          fill="#FBFEFF"
          opacity="1.000000"
          stroke="none"
          d=" M191.314133,176.063461   C193.990112,174.311127 196.033737,175.106201 197.940811,176.756714   C198.441071,177.189682 198.883667,177.689178 199.353668,178.157211   C208.913132,187.676392 208.628418,191.261124 197.643677,199.495239   C196.432922,200.402817 195.150833,201.136703 193.531784,200.906662   C190.944748,200.539062 182.169647,191.329803 181.873596,188.656464   C181.657944,186.709045 182.294983,185.105026 183.685562,183.722031   C186.154312,181.266739 188.569656,178.757751 191.314133,176.063461  z"
        />
      </svg>
    </Icon>
  );
}
