import Modal from "@/ui-lib/components/Modal";
import { useTheme } from "@emotion/react";
import {
  GcpCommitmentDurationType,
  GcpCommitmentScope,
  GcpTargetCommitmentType,
} from "@ternary/api-lib/constants/enums";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import copyText from "../../copyText";
import { InsightRecommendation } from "../types";
import { getFormattedTargetCommitmentValue } from "../utils";
import ContraryRecommendationWarning from "./ContraryRecommendationWarning";

type CudRecommendation = {
  id: string;
  billingAccountID: string;
  estimatedCurrency: string;
  projectID: string;
  region: string;
  scope: GcpCommitmentScope;
  targetCommitmentInstanceFamily: string | null;
  targetCommitmentType: GcpTargetCommitmentType;
  targetCommitmentValue: number;
  term: GcpCommitmentDurationType;
};

interface Props {
  recommendation: CudRecommendation;
  contraryInsightRecommendation?: InsightRecommendation;
  title: string;
  onCancel: () => void;
  onInteraction: (interaction: CudRecommendationModal.Interaction) => void;
}

export function CudRecommendationModal(props: Props): JSX.Element {
  const theme = useTheme();

  const isSpendBased =
    props.recommendation.targetCommitmentType ===
    GcpTargetCommitmentType.CURRENCY;

  return (
    <Modal isOpen showCloseButton onClose={props.onCancel}>
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <Box minWidth={400}>
          <Flex>
            <Text bold>
              {isSpendBased
                ? copyText.cudRecommendationModalSpendBasedHeader
                : copyText.cudRecommendationModalResourceBasedHeader}
            </Text>
            <ContraryRecommendationWarning
              insightRecommendation={props.contraryInsightRecommendation}
            />
          </Flex>
          <Flex>
            <Text>{copyText.cudRecommendationModalTermLabel}</Text>
            <Text marginLeft={theme.space_xs}>
              {props.recommendation.term ===
              GcpCommitmentDurationType.THIRTY_SIX_MONTH
                ? copyText.commitmentLengthOption3YearLabel
                : copyText.commitmentLengthOption1YearLabel}
            </Text>
          </Flex>
          <Flex>
            <Text>
              {props.recommendation.targetCommitmentInstanceFamily
                ? getInstanceFamilyLabel(
                    props.recommendation.targetCommitmentInstanceFamily,
                    props.recommendation.targetCommitmentType
                  )
                : copyText.cudRecommendationModalTargetCoverageLabel}
            </Text>
            <Text marginLeft={theme.space_xs}>
              {getFormattedTargetCommitmentValue({
                estimatedCurrency: props.recommendation.estimatedCurrency,
                type: props.recommendation.targetCommitmentType,
                value: props.recommendation.targetCommitmentValue,
              })}
            </Text>
          </Flex>
          <Flex>
            <Text>
              {isSpendBased
                ? copyText.cudRecommendationModalAccountLabel
                : copyText.cudRecommendationModalProjectLabel}
            </Text>
            <Text marginLeft={theme.space_xs}>
              {isSpendBased
                ? props.recommendation.billingAccountID
                : props.recommendation.projectID}
            </Text>
          </Flex>
          <Flex>
            <Text>{copyText.cudRecommendationModalScopeLabel}</Text>
            <Text marginLeft={theme.space_xs}>
              {props.recommendation.scope === GcpCommitmentScope.BILLING_SHARED
                ? copyText.cudRecScopeBillingShared
                : copyText.cudRecScopeProject}
            </Text>
          </Flex>
          <Flex>
            <Text>{copyText.cudRecommendationModalRegionLabel}</Text>
            <Text marginLeft={theme.space_xs}>
              {props.recommendation.region}
            </Text>
          </Flex>
          <Text
            color={theme.primary_color_text}
            cursor="pointer"
            marginTop={theme.space_md}
            onClick={() =>
              props.onInteraction({
                type: CudRecommendationModal.INTERACTION_LINK_CLICKED,
                recommendationID: props.recommendation.id,
              })
            }
          >
            {copyText.cudRecModalTreLink}
          </Text>
        </Box>
      </Modal.Body>
    </Modal>
  );
}

function getInstanceFamilyLabel(
  instanceFamily: string,
  type: GcpTargetCommitmentType
) {
  if (type === GcpTargetCommitmentType.MEMORY) {
    return `${instanceFamily}${copyText.cudRecommendationModalRamLabel}`;
  }

  if (type === GcpTargetCommitmentType.VCPU) {
    return `${instanceFamily} ${copyText.cudRecommendationModalInstanceFamilyCpuLabel}`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CudRecommendationModal {
  export const INTERACTION_LINK_CLICKED = `CudRecommendationModal.INTERACTION_LINK_CLICKED`;

  interface InteractionLinkClicked {
    type: typeof CudRecommendationModal.INTERACTION_LINK_CLICKED;
    recommendationID: string;
  }

  export type Interaction = InteractionLinkClicked;
}

export default CudRecommendationModal;
