import Modal from "@/ui-lib/components/Modal";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";
import { CudInventoryCommitmentDatum } from "../types";
import { CudInventoryCommitmentTable } from "./CudInventoryCommitmentTable";

interface Props {
  instances: CudInventoryCommitmentDatum[];
  isLoading: boolean;
  title: string;
  onCancel: () => void;
}

export function CudInventoryModal(props: Props): JSX.Element {
  return (
    <Modal isOpen showCloseButton onClose={props.onCancel}>
      <Modal.Header>
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text appearance="h4">{props.title}</Text>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box scrollable>
          <CudInventoryCommitmentTable
            commitments={props.instances}
            isLoading={props.isLoading}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
}
