import { useAnalyticsApiClient } from "@/context/AnalyticsQueryLoaderProvider";
import { useQuery } from "@tanstack/react-query";
import { QueryFilter } from "@ternary/api-lib/analytics/types";
import { DataSource, TimeGranularity } from "@ternary/api-lib/constants/enums";
import { UseQueryOptions, UseQueryResult } from "src/lib/react-query";
import UError from "unilib-error";
import useBuildDataQuery from "../../../../api/analytics/utils/useDataQuery";
import { ANALYTICS_QUERY_GC_TIME } from "../../../../constants";
import {
  AzureStorageBBDatum,
  AzureStorageBBDimension,
  AzureStorageBBMeasure,
} from "../types";

export interface Params {
  dateRange: Date[];
  dimensions?: AzureStorageBBDimension[];
  filters?: QueryFilter[];
  granularity?: TimeGranularity;
  measures: AzureStorageBBMeasure[];
}

export default function useGetAzureStorageBBData(
  params: Params,
  options?: UseQueryOptions<AzureStorageBBDatum[], UError>
): UseQueryResult<AzureStorageBBDatum[], UError> {
  const client = useAnalyticsApiClient();

  const [tenantID, query] = useBuildDataQuery({
    dataSource: DataSource.AZURE_STORAGE_VISIBILITY,
    dateRange: params.dateRange,
    dimensions: params.dimensions,
    granularity: params.granularity,
    measures: params.measures,
    preAggFilters: params.filters,
  });

  return useQuery({
    queryKey: ["azureStorageBBData", query],
    queryFn: async () => {
      const result = await client.loadData(tenantID, query);

      return result.response.map(
        (datum) =>
          ({
            projectId: datum.projectId ?? "",
            accountId: datum.accountId ?? "",
            resourceId: datum.resourceId ?? "",
            storageAccount: datum.storageAccount ?? "",
            pricingModel: datum.pricingModel ?? "",
            skuDescription: datum.skuDescription ?? "",

            cost: datum.cost ?? 0,
            absoluteCredits: datum.absoluteCredits ?? 0,
            usageAmount: datum.usageAmount ?? 0,
            blobSizeBytes: datum.blobSizeBytes ?? 0,
            containerCount: datum.containerCount ?? 0,
            transactions: datum.transactions ?? 0,
            bytesDownloaded: datum.bytesDownloaded ?? 0,
            bytesUploaded: datum.bytesUploaded ?? 0,

            timestamp: datum.timestamp,
          }) as AzureStorageBBDatum
      );
    },
    gcTime: ANALYTICS_QUERY_GC_TIME,
    ...options,
  });
}
