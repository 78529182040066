// useful for dev work
export async function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve();
    }, ms);
  });
}

export default {
  chartAnimationDuration: 200,
  selectedDuration: 100,
  simulatedAPICallDuration: 500,
  skeletonAnimationTime: 1.5,
  wait,
};
