import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";

type MspChildDataIntegration = Awaited<
  ReturnType<CoreAPIClient["getMspChildDataIntegrationsByParentTenantID"]>
>[number];

export default function useGetMspChildDataIntegrations(
  tenantID: string,
  options?: UseQueryOptions<MspChildDataIntegration[], CoreAPIClientError>
): UseQueryResult<MspChildDataIntegration[], CoreAPIClientError> {
  const client = useCoreAPIClient();

  return useQuery({
    queryKey: ["mspChildDataIntegrations", tenantID],
    queryFn: () => client.getMspChildDataIntegrationsByParentTenantID(tenantID),
    ...options,
  });
}
