import { FormField } from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import Modal from "@/ui-lib/components/Modal";
import TextInput from "@/ui-lib/components/TextInput";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useState } from "react";
import copyText from "../constants/copyText";

interface NameInputModalProps {
  isProcessing?: boolean;
  name?: string;
  title: string;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

export default function NameInputModal(
  props: NameInputModalProps
): JSX.Element {
  const [nameInput, setNameInput] = useState(props.name ?? "");

  return (
    <Modal
      closeOnClickOutside={false}
      isOpen={true}
      showCloseButton
      width={500}
      onClose={props.onClose}
    >
      <Modal.Header>
        <Text appearance="h4">{props.title}</Text>
      </Modal.Header>
      <Modal.Body>
        <FormField
          autoFocus
          input={TextInput}
          label={copyText.nameInputLabel}
          value={nameInput}
          onChange={(event) => setNameInput(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={props.isProcessing}
          secondary
          width={70}
          onClick={props.onClose}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={nameInput.length === 0 || props.isProcessing}
          primary
          width={70}
          onClick={() => props.onSubmit(nameInput)}
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.saveButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
