import { useQuery } from "@tanstack/react-query";
import { FindAlertRulesQueryResult } from "@ternary/api-lib/core/queries/FindAlertRulesQuery";
import CoreAPIClientError from "../../../api/core/CoreAPIClientError";
import { useCoreAPIClient } from "../../../api/core/CoreAPIClientProvider";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import queryKeys from "./queryKeys";

export default function useGetAlertRulesByTenantID(
  tenantID: string,
  options?: UseQueryOptions<
    FindAlertRulesQueryResult["alertRules"],
    CoreAPIClientError
  >
): UseQueryResult<FindAlertRulesQueryResult["alertRules"], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  return useQuery({
    queryKey: queryKeys.rules,
    queryFn: () => client.getAlertRulesByTenantID(tenantID),
    enabled: gatekeeper.canListAlertRules,
    ...options,
  });
}
