import { RawData, RawValue } from "@ternary/api-lib/analytics/types";

//
// Error Types
//

type CubestructErrorContext = {
  datum: RawData;
  key: string;
};

export class CubestructError extends Error {
  context: CubestructErrorContext;

  constructor(message: string, context: CubestructErrorContext) {
    super(message);
    this.context = context;
    this.context.datum = { ...context.datum };
  }
}

class InternalTypeError extends Error {}

//
// Utility Types
//

export type Infer<Map extends ValidatorMap> = ValidatedType<Map>;

type Resolve<T> = T extends (...args: never[]) => unknown
  ? never
  : {
      [key in keyof T]: T[key];
    };

//
// Validator Types
//

type ValidatorFn = typeof boolean | typeof number | typeof string;

type ValidatorMap = {
  [key: string]: ReturnType<ValidatorFn | typeof nullable | typeof optional>;
};

type ValidatedType<Map extends ValidatorMap> = Resolve<{
  [key in keyof Map]: ReturnType<Map[key]>;
}>;

//
// Struct Types
//

export function boolean(): (rawValue: RawValue) => boolean {
  return (rawValue) => {
    const constValue = rawValue;

    if (typeof constValue !== "boolean") {
      throw new InternalTypeError();
    }

    return constValue;
  };
}

export function number(): (rawValue: RawValue) => number {
  return (rawValue) => {
    const constValue = rawValue;

    if (typeof constValue !== "number") {
      throw new InternalTypeError();
    }

    return constValue;
  };
}

export function string(): (rawValue: RawValue) => string {
  return (rawValue) => {
    const constValue = rawValue;

    if (typeof constValue !== "string") {
      throw new InternalTypeError();
    }

    return constValue;
  };
}

export function nullable<T extends ReturnType<ValidatorFn>>(
  validatorFn: T
): (rawValue: RawValue) => ReturnType<T> | null {
  return (rawValue) => {
    const constValue = rawValue;

    if (constValue === null) return null;

    return validatorFn(rawValue) as ReturnType<T>;
  };
}

export function optional<T extends ReturnType<ValidatorFn | typeof nullable>>(
  validatorFn: T
): (rawValue: RawValue) => ReturnType<T> | undefined {
  return (rawValue) => {
    const constValue = rawValue;

    if (constValue === undefined) return undefined;

    return validatorFn(rawValue) as ReturnType<T>;
  };
}

//
// Validator Functions
//

export function mask<Map extends ValidatorMap>(
  datum: RawData,
  map: Map
): ValidatedType<Map> {
  const mapKeys = Object.keys(map) as (keyof Map)[];
  const validated = {} as ValidatedType<Map>;

  for (const key of mapKeys) {
    try {
      validated[key] = map[key](datum[key]) as ValidatedType<Map>[keyof Map];
    } catch (error) {
      if (error instanceof InternalTypeError) {
        throw new CubestructError(
          `Invalid datum value at key ${key as string}`,
          { key: key as string, datum }
        );
      }

      throw error;
    }
  }

  return validated as ValidatedType<Map>;
}

export function maskAll<Map extends ValidatorMap>(
  data: RawData[],
  map: Map
): ValidatedType<Map>[] {
  const mapKeys = Object.keys(map) as (keyof Map)[];
  const validatedArray: ValidatedType<Map>[] = [];

  for (const datum of data) {
    const validatedDatum = {} as ValidatedType<Map>;

    for (const key of mapKeys) {
      try {
        validatedDatum[key] = map[key](
          datum[key]
        ) as ValidatedType<Map>[keyof Map];
      } catch (error) {
        if (error instanceof InternalTypeError) {
          throw new CubestructError(
            `Invalid datum value at key ${key as string}`,
            { key: key as string, datum }
          );
        }

        throw error;
      }
    }

    validatedArray.push(validatedDatum);
  }

  return validatedArray;
}

type ErrorOrType<T> = [undefined, T] | [CubestructError, undefined];

export function validate<Map extends ValidatorMap>(
  datum: RawData,
  map: Map
): ErrorOrType<ValidatedType<Map>> {
  try {
    const validated = mask(datum, map);
    return [undefined, validated];
  } catch (error) {
    if (error instanceof CubestructError) return [error, undefined];
    throw error;
  }
}

export function validateAll<Map extends ValidatorMap>(
  data: RawData[],
  map: Map
): ErrorOrType<ValidatedType<Map>[]> {
  try {
    const validated = maskAll(data, map);
    return [undefined, validated];
  } catch (error) {
    if (error instanceof CubestructError) return [error, undefined];
    throw error;
  }
}
